import { PublicClientApplication } from "@azure/msal-browser";
import FlightsEnum from "../../enum/FlightsEnum";
import ResourceEnum from "../../enum/ResourceEnum";
import IResourceAllocationDetails from "../../models/resource-allocation/IResourceAllocationDetails";
import IResourceAvailability from "../../models/resource-allocation/IResourceAvailability";
import ResourceAllocationService from "../../services/resource-allocation/resourceallocation.service";
import { APIHelper } from "../api/APIHelper";
import {
  RESOURCE_COLORS,
  RESOURCE_COMMON_FILTER_KEYS,
  RESOURCE_SPECIFIC_FILTERS,
  RESOURCE_TABS,
  RESOURCE_TAB_CAROUSEL_INDEX_KEY,
} from "../resource/ResourceConstants";

export abstract class ResourceHelper {
  public static async getResourceAllocation(
    msalInstance: PublicClientApplication,
    resourceType: string,
    airportCode: string,
    resourceSubType?: string
  ): Promise<any> {
    const resourceService = new ResourceAllocationService();
    const resourceSummaryMethod = (accessToken: string) => {
      return resourceService.getResourceAllocation(
        accessToken,
        resourceType,
        airportCode,
        resourceSubType
      );
    };
    return APIHelper.CallAPI(msalInstance, null, resourceSummaryMethod);
  }

  public static async getResourceAvailability(
    msalInstance: PublicClientApplication,
    resourceType: string,
    legType = "",
    airportCode: string,
    resourceSubType?: string
  ): Promise<any> {
    const resourceService = new ResourceAllocationService();
    const resourceAvailabilityMethod = (accessToken: string) => {
      return resourceService.getResourceAvailability(
        accessToken,
        resourceType,
        legType,
        airportCode,
        resourceSubType
      );
    };

    return APIHelper.CallAPI(msalInstance, null, resourceAvailabilityMethod);
  }

  public static async getResourceAllocationDetails(
    msalInstance: PublicClientApplication,
    airportCode: string,
    resourceType: string,
    group: string,
    legType = "",
    offset = 0,
    resourceSubType?: string
  ): Promise<any> {
    const resourceService = new ResourceAllocationService();
    const resourceAllocationDetailsMethod = (accessToken: string) => {
      return resourceService.getResourceAllocationDetails(
        accessToken,
        airportCode,
        resourceType,
        group,
        offset,
        legType,
        resourceSubType
      );
    };

    return APIHelper.CallAPI(
      msalInstance,
      null,
      resourceAllocationDetailsMethod
    );
  }

  public static getResourceData(resources: any[], key: string, total = false) {
    if (resources && resources.length > 0) {
      return resources.map((resource, index) => ({
        resourceColor: RESOURCE_COLORS.colors[index],
        resourceCount: (resource as { [k in string]: any })[key],
        resourceTitle: `${resource.group}`,
        resourceTotal: total ? this.getResourceTotal(resources, key) : 0,
      }));
    }
    return [];
  }

  public static getResourceTotal(resources: any[], key: string) {
    return resources.reduce(
      (a, b) => a + (b as { [k in string]: any })[key],
      0
    );
  }

  /* Start - Temporary code - To remove after PTE */
  public static getTerminalFiltersDEL(
    resourceAvailability?: IResourceAvailability[]
  ) {
    if (resourceAvailability && resourceAvailability.length > 0) {
      const availableResources = resourceAvailability
        .map((resource) => resource?.availability)
        .reduce((prev, current) => [...prev, ...current]);
      let terminals = availableResources.map((a) => a.group);
      terminals = terminals.filter(
        (item, index) => terminals.indexOf(item) === index
      );
      return terminals.map((t) => ({
        text: this.getTerminalTextDEL(resourceAvailability[0].groupBy, t),
        value: t,
      }));
    }
    return [];
  }

  public static getTerminalTextDEL(groupBy: string, terminal: string) {
    return groupBy === "terminal" ? `T${terminal}` : terminal;
  }

  /* End - Temporary code - To remove after PTE */

  public static getTerminalFilters(
    resourceAvailability?: IResourceAvailability[]
  ) {
    if (resourceAvailability && resourceAvailability.length > 0) {
      const availableResources = resourceAvailability
        .map((resource) => resource?.availability)
        .reduce((prev, current) => [...prev, ...current]);
      let terminals = availableResources.map((a) => a.group);
      terminals = terminals.filter(
        (item, index) => terminals.indexOf(item) === index
      );
      return terminals.map((t) => ({
        text: t,
        value: t,
      }));
    }
    return [];
  }

  public static getSelectedLegendValue(
    selectedLegend: string,
    resourceType: string
  ) {
    if (resourceType === ResourceEnum.ResourceType.Stand) {
      return { value: selectedLegend, key: "standType" };
    } else {
      return { value: selectedLegend.split(" ")[1], key: "terminal" };
    }
  }

  public static getLegendKey(resourceType: string) {
    return resourceType === ResourceEnum.ResourceType.Stand
      ? "standType"
      : "terminal";
  }

  public static getShowKPI(
    selectedResource: string,
    resourceKey: string,
    selectedLegType: string
  ) {
    if (selectedResource === ResourceEnum.Resource.Baggage) {
      if (
        selectedLegType === FlightsEnum.LegType.All ||
        (selectedLegType === FlightsEnum.LegType.Departure &&
          resourceKey === ResourceEnum.ResourceType.Chutes) ||
        (selectedLegType === FlightsEnum.LegType.Arrival &&
          resourceKey === ResourceEnum.ResourceType.Belts)
      ) {
        return true;
      }
      return false;
    }
    return true;
  }

  public static updateResourceFiltersDisplay(
    resourceFilters: any,
    legType: string,
    selectedResource: string
  ) {
    const resourceList = [
      ResourceEnum.ResourceType.Belts,
      ResourceEnum.ResourceType.Chutes,
    ];
    resourceFilters = this.getResourceSpecificFilters(
      selectedResource,
      resourceFilters
    );
    return resourceFilters.map((filter: any) => {
      return {
        ...filter,
        hide: resourceList.includes(filter.key)
          ? this.getFilterDisplay(legType, filter.key)
          : filter.hide,
      };
    });
  }

  public static getFilterDisplay(legType: string, key: string) {
    if (
      legType === FlightsEnum.LegType.All ||
      (key === ResourceEnum.ResourceType.Belts &&
        legType === FlightsEnum.LegType.Arrival) ||
      (key === ResourceEnum.ResourceType.Chutes &&
        legType === FlightsEnum.LegType.Departure)
    ) {
      return false;
    }
    return true;
  }

  public static removeResourceFilters(filters: any) {
    return filters.filter((f: any) =>
      RESOURCE_COMMON_FILTER_KEYS.includes(f.key)
    );
  }

  public static getResourceSpecificFilters(resource: string, filters: any) {
    let resourceFilters: any[] = filters;
    if (resource) {
      resourceFilters = this.removeResourceFilters(filters);
      const specificFilters = (
        RESOURCE_SPECIFIC_FILTERS as { [k in string]: any }
      )[resource];
      const concatArray = specificFilters.concat(
        resourceFilters.slice(resourceFilters.length - 2)
      );
      resourceFilters = resourceFilters
        .slice(0, resourceFilters.length - 2)
        .concat(concatArray);
    }
    return resourceFilters;
  }

  public static getResponseData(response: any) {
    return response?.data;
  }

  public static addTotalInResponse(responses: any[]) {
    return responses.map((res: any) => ({
      data: res.data,
      total: res.data.length,
    }));
  }

  public static updateAPIResponse(responses: any[], range = 0, offset = 25) {
    return responses.map((res: any) => ({
      data: res.data.slice(range, range + offset),
      range: this.getRangeArray(offset, res.total, offset),
    }));
  }

  public static getCarouselItems(carouselItems: any[]) {
    return carouselItems.map((item, index) => ({
      key: index,
      "aria-controls": item.id,
      "aria-label": item.id,
    }));
  }

  public static getRangeArray(start: number, end: number, interval: number) {
    const result: number[] = [];
    let rangeArray = [];
    if (end > interval) {
      for (let i = start; i <= end + (interval - 1); i += interval) {
        result.push(i);
      }
      if (result.length > 0) {
        rangeArray = this.getRangeByNumber(result);
      }
    }
    return rangeArray;
  }

  public static getRangeByNumber(inputArray: number[]) {
    const result: any[] = [];
    inputArray?.forEach((n: any, i: number) => {
      result.push({
        value: `${i > 0 ? inputArray[i - 1] : "0"}`,
        text: `${i > 0 ? inputArray[i - 1] : 0}-${n}`,
      });
    });
    return result;
  }

  public static getBarChartWidth(
    resourceDetails: IResourceAllocationDetails[]
  ) {
    return resourceDetails && resourceDetails.length > 12 ? 1200 : 500;
  }

  public static getResourceAllocationDefaultTab = () => {
    let selectedCarouselKey = RESOURCE_TABS.data[2].value;

    if (window.sessionStorage.getItem(RESOURCE_TAB_CAROUSEL_INDEX_KEY)) {
      selectedCarouselKey =
        window.sessionStorage.getItem(RESOURCE_TAB_CAROUSEL_INDEX_KEY) ??
        RESOURCE_TABS.data[2].value;
    }

    return selectedCarouselKey;
  };
}
