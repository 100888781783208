import { DefaultPalette, mergeStyleSets } from "@fluentui/react";

const inlineFlex = "inline-flex";

export const getNavBarStyles = () =>
  mergeStyleSets({
    header: {
      background: DefaultPalette.themePrimary,
      height: "60px",
      display: "flex",
      justifyContent: "space-between",
    },
    headerBrand: {
      display: inlineFlex,
      height: 60,
      background: "white",
    },
    headerBrandName: {
      display: inlineFlex,
      height: 60,
      alignItems: "center",
      fontSize: 18,
      fontWeight: 600,
      color: DefaultPalette.white,
      padding: "0 10px",
      selectors: {
        "& a": {
          color: DefaultPalette.white,
        },
        "& a:hover, &:active:hover": {
          color: DefaultPalette.whiteTranslucent40,
          textDecoration: "none",
        },
      },
    },
    headerNavItem: {
      display: inlineFlex,
      height: 60,
      alignItems: "center",
      fontSize: 18,
      fontWeight: 400,
      color: DefaultPalette.white,
      padding: "0 10px",
      selectors: {
        "& a": {
          color: DefaultPalette.white,
        },
        "& a:hover, & a:active:hover": {
          color: DefaultPalette.white,
          textDecoration: "none",
        },
      },
    },
    headerSubNavItem: {
      position: "fixed",
      right: 0,
      zIndex: 200,
      color: "#000",
      background: "#fff",
      boxShadow: "4px 4px 4px 0px #00000040",
      selectors: {
        "& a:hover, & a:active:hover": {
          color: "#000",
          backgroundColor: "#e6eff8",
          textDecoration: "none",
        },
      },
    },
  });
