import { PublicClientApplication } from "@azure/msal-browser";
import jwt_decode from "jwt-decode";
import { INotificationItemExtended } from "../../components-v2/shared/components/notifications/types";
import {
  MESSAGEBAR_INTENT_TYPE,
  MessageBarIntentType,
  MessageBarType,
} from "../../enum/NotificationPanelEnum";
import INotification, {
  INotificationItem,
} from "../../models/notification/INotification";
import NotificationService from "../../services/notification/notification.service";
import { APIHelper } from "../api/APIHelper";
import { getLoggedInUser } from "../common/CommonHelperFunctions";

export function getMessageBarType(notificationType: string) {
  switch (notificationType) {
    case MESSAGEBAR_INTENT_TYPE.CRITICAL:
      return {
        intent: MessageBarIntentType.ERROR,
        messageBarType: MessageBarType.error,
      };
    case MESSAGEBAR_INTENT_TYPE.MEDIUM:
      return {
        intent: MessageBarIntentType.WARNING,
        messageBarType: MessageBarType.warning,
      };
    default:
      return {
        intent: MessageBarIntentType.INFO,
        messageBarType: MessageBarType.info,
      };
  }
}

export function compareByIntent(
  a: { messageBarType: number },
  b: { messageBarType: number }
) {
  return a.messageBarType - b.messageBarType;
}

export function removeObjectWithId(arr: any[], id: string) {
  return arr.filter((obj) => obj.id !== id);
}

export function mappedNotificationsArray(
  mockNotificationItems: INotificationItem[]
): INotificationItemExtended[] {
  return mockNotificationItems?.map((notification: INotificationItem) => {
    const itemSeverity = notification.severity;
    const { intent, messageBarType } = getMessageBarType(itemSeverity);
    return {
      ...notification,
      intent,
      messageBarType,
    };
  });
}

export function sortNotifications(
  updatedNotificationsArray?: INotificationItemExtended[]
): INotificationItemExtended[] {
  if (!updatedNotificationsArray) {
    return [];
  }
  const sortedNotifications = updatedNotificationsArray.slice();
  sortedNotifications.sort(compareByIntent);
  return sortedNotifications;
}

export function numAlerts(
  sortedNotifications: INotificationItemExtended[]
): number {
  return sortedNotifications?.filter(
    (notification: INotificationItemExtended) =>
      notification.intent === MessageBarIntentType.ERROR
  )?.length;
}

export function numNotifications(
  sortedNotifications: INotificationItemExtended[]
): number {
  return sortedNotifications?.filter(
    (notification: INotificationItemExtended) =>
      notification.intent !== MessageBarIntentType.ERROR
  )?.length;
}

export function checkTokenExpiration(token: string) {
  const decoded: any = jwt_decode(token);
  if (decoded.exp) {
    const expirationTime = decoded.exp;
    const currentTime = Date.now() / 1000;
    return expirationTime - currentTime > 0;
  }
  return false;
}

export function getSockets(notificationsList: INotification[]) {
  const expiredHubList: string[] = [];
  const webSocket: WebSocket[] = [];
  notificationsList.forEach((notification) => {
    if (notification.clientAccessUrl) {
      //check clientAccessUrl expiration
      const accessToken =
        notification.clientAccessUrl.split("access_token=")[1];
      const isValidToken = checkTokenExpiration(accessToken);
      if (isValidToken) {
        webSocket.push(new WebSocket(notification.clientAccessUrl));
      } else {
        expiredHubList.push(notification.hub);
      }
    } else {
      expiredHubList.push(notification.hub);
    }
  });
  return { expiredHubList: expiredHubList, webSocket: webSocket };
}

export function getAllSavedNotificationItems(
  notificationsList: INotification[],
  hubs: string[]
): INotificationItem[] {
  const notificationListMap = notificationsList
    .filter((notification) => {
      return hubs.includes(notification.hub);
    })
    .map((notification) => notification?.notificationItems);

  return notificationListMap.length > 0
    ? notificationListMap.reduce((prev, current) => [...prev, ...current])
    : [];
}

export async function getClientAccessUrl(
  microsoftTeams: any,
  msalInstance: PublicClientApplication,
  hubList: string[],
  airportCode: string
): Promise<any> {
  const notificationService = new NotificationService();
  const payLoad = { hubList: hubList };
  const userEmail = (await getLoggedInUser(
    microsoftTeams,
    msalInstance
  )) as string;
  const getClientAccessUrlMethod = (accessToken: string, payLoad: any) => {
    return notificationService.getClientAccessUrl(
      accessToken,
      payLoad,
      userEmail,
      airportCode
    );
  };

  return APIHelper.CallAPI(msalInstance, payLoad, getClientAccessUrlMethod);
}

export function saveNotifications(updatedList: INotification[]) {
  localStorage.setItem("apocNotifications", JSON.stringify(updatedList));
}

export function getNewSockets(
  clientUrlData: any,
  notificationsList: INotification[]
) {
  const newWebSockets: WebSocket[] = [];
  if (clientUrlData) {
    clientUrlData.clientURLs.forEach((client: any) => {
      newWebSockets.push(new WebSocket(client.clientURL));
      notificationsList
        ?.filter((n) => n.hub === client.hubName)
        .forEach((obj) => (obj.clientAccessUrl = client.clientURL));
    });
    saveNotifications(notificationsList);
  }
  return newWebSockets;
}

export function processEvent(
  hubName: string,
  notificationItems: INotificationItem[],
  notificationsList: INotification[],
  message: any
) {
  const isAvailable = notificationItems.find((n) => n.id === message.id);
  let newNotification: INotificationItem = {
    severity: "",
    id: "",
    airportCode: "",
    title: "",
    body: "",
    category: "",
    timestamp: "",
  };
  if (!isAvailable) {
    newNotification = {
      severity: message.severity,
      id: message.id,
      airportCode: message.airportCode,
      title: message.title,
      body: message.body,
      category: message.category,
      timestamp: message.timestamp,
    };
    notificationsList
      ?.filter((n) => n.hub.toLowerCase() === hubName.toLowerCase())
      .forEach((obj) => obj.notificationItems.push(newNotification));
    saveNotifications(notificationsList);
  }
  return newNotification;
}

export const getNewNotification = (
  event: MessageEvent,
  notificationItems: INotificationItem[],
  notificationsList: INotification[]
) => {
  const currentTarget = event.target as WebSocket;
  const hubName = currentTarget.url.split("hubs/")[1].split("?")[0];
  const message = JSON.parse(event.data);
  const newNotification = processEvent(
    hubName,
    notificationItems,
    notificationsList,
    message
  );
  return {
    newNotification,
    hubName,
  };
};
