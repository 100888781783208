enum User {
  Name = "name",
  Email = "email",
  Roles = "roles",
  Organizations = "organizations",
  Responsibilities = "responsibilities",
  Terminals = "terminals",
}

enum RoleComponent {
  Flights = "flights",
  Passengers = "passengers",
  Baggage = "baggage",
  ResourceAllocation = "resourceallocation",
  Weather = "weather",
  EventManagement = "eventmanagement",
}

enum Tabs {
  Users = "users",
  Organizations = "organizations",
  Roles = "roles",
  Airports = "airports",
}

const AdminEnum = {
  User,
  Tabs,
  RoleComponent,
};

export default AdminEnum;
