enum HOPDataType {
  Current = "current",
  Forecast = "forecast",
}
enum HOPDataStatus {
  Good = "good",
  Moderate = "moderate",
  Poor = "poor",
  All = "all",
  Pinned = "pinned",
}
enum HOPKPI {
  All = "all",
  Arrivals = "arrivals",
  Departures = "departures",
}
enum HOPDIMENSIONS {
  OTP = "onTimePerformance",
  TurnaroundSufficiency = "turnaroundSufficiency",
  SlotAdherence = "slotAdherence",
  RunwayDelay = "runwayDelay",
  PassengerFlow = "passengerFlow",
  Baggage = "baggage",
  BuildingFacilities = "buildingFacilities",
  OtherAssets = "otherAssets",
  LoadFactor = "loadFactor",
}
const HOPEnum = {
  HOPDataType,
  HOPDataStatus,
  HOPKPI,
  HOPDIMENSIONS,
};

export default HOPEnum;
