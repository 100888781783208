import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  DialogFooter,
  IStackProps,
  mergeStyleSets,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { DialogBody } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { NotificationContext } from "../../../../../context/NotificationContext";
import EventManagementEnum from "../../../../../enum/EventManagementEnum";
import { ICloseEvent } from "../../../../../models/events/ICloseEvent";
import { MESSAGEBAR_TYPE } from "../../../../../utils/common/Constants";
import { EventManagementHelper } from "../../../../../utils/events/EventManagementHelper";
import { AmdButton } from "../../../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdModal } from "../../../../common/form-controls/uncontrolled/Modal/AmdModal";
import { AmdTextField } from "../../../../common/form-controls/uncontrolled/TextField/AmdTextField";
import useGetEventDetailByIdEffect from "../../../hooks/useGetEventDetailByIdEffect";

interface IEventDetailProps {
  eventId: string;
  canCloseEvent?: boolean;
  onEventClosed?: any;
  airport: string;
}

export const EventDetail: React.FunctionComponent<IEventDetailProps> = (
  props: IEventDetailProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const { eventResponse } = useGetEventDetailByIdEffect(
    msalInstance,
    props.airport,
    props.eventId
  );
  const { addNotification } = React.useContext(NotificationContext);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const [closingRemarks, setClosingRemarks] = useState("");

  useEffect(() => {
    if (props.airport !== "" && eventResponse) {
      const adaptiveCardTemplate = eventResponse.template.details;
      const adaptiveCardData = eventResponse.data;
      // render the card
      const { adaptiveCard } = EventManagementHelper.renderAdaptiveCard(
        "event-detail",
        adaptiveCardTemplate,
        adaptiveCardData
      );

      // disable form if props.canCloseEvent = false
      if (!props.canCloseEvent) {
        EventManagementHelper.disableEventDetailsFormFields();
      }

      adaptiveCard.onExecuteAction = function (action: any) {
        EventManagementHelper.updateEvent(
          msalInstance,
          props.eventId,
          action?.data,
          props.airport
        ).then((response) => {
          if (response.data) {
            addNotification(MESSAGEBAR_TYPE.SUCCESS, response.data);
          }
        });
      };
    }
  }, [props.airport, props.eventId, props.canCloseEvent, eventResponse]);

  const onClosingRemarksUpdate = (data: any) => {
    setClosingRemarks(data?.target?.value);
  };

  const closeEvent = () => {
    if (eventResponse) {
      const payload: ICloseEvent = {
        status: EventManagementEnum.EventStatus.Closed,
        resolution: closingRemarks,
      };

      EventManagementHelper.updateEvent(
        msalInstance,
        props.eventId,
        payload,
        props.airport
      ).then((response) => {
        if (response.data) {
          addNotification(MESSAGEBAR_TYPE.SUCCESS, response.data);
          props.onEventClosed();
        }
      });
    }

    hideModal();
  };

  return (
    <div
      id="eventDetailContainer"
      data-testid="eventDetailContainer"
      className={contentStyles.root}
    >
      {eventResponse?.status === EventManagementEnum.EventStatus.Active &&
        props?.canCloseEvent && (
          <AmdButton
            id="closeEvent"
            testId="closeEvent"
            text="Close event"
            onClick={showModal}
            type={AmdButtonType.PRIMARY}
            prefixImageIconBeforeText={true}
            className="float-r"
          />
        )}
      <div id="event-detail" data-testid="event-detail" className="mt-1">
        Loading...
      </div>

      <AmdModal
        isOpen={isModalOpen}
        isBlocking={true}
        header="Close event"
        onClose={hideModal}
        containerClassName={modalStyles.container}
      >
        <div className={modalStyles.body}>
          <DialogBody>
            <Stack horizontal tokens={stackTokens}>
              <Stack {...columnProps}>
                <AmdTextField
                  id="closingRemarksTextField"
                  label="Remarks"
                  isMandatory={true}
                  isMultiline={true}
                  onChange={onClosingRemarksUpdate}
                />
              </Stack>
            </Stack>

            <DialogFooter>
              <DefaultButton text="Cancel" onClick={hideModal} />
              <PrimaryButton text="Done" type="submit" onClick={closeEvent} />
            </DialogFooter>
          </DialogBody>
        </div>
      </AmdModal>
    </div>
  );
};

const contentStyles = mergeStyleSets({
  root: {
    margin: "0px !important",
    marginLeft: "-10px !important",
  },
});

const modalStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "28vh",
    width: "30vw",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

const stackTokens = { childrenGap: 2, root: { width: "100%" } };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: "100%" } },
};

const formFieldsData = {};
