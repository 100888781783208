import { getTheme, mergeStyleSets } from "@fluentui/react";
import { Button, useId } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";

import {
  bundleIcon,
  CalendarFilled,
  CalendarRegular,
} from "@fluentui/react-icons";
import React from "react";
import { DateTypeEnum } from "../../../enum/DateEnum";
import { getAirportCode } from "../../../store/airport/AirportSelector";
import { useAppSelector } from "../../../store/StoreHooks";
import {
  getApiTime,
  getDisplayTime,
} from "../../../utils/multi-airports/date-filter/dateFilterHelper";
import DateFilterPopUp from "./DateFilterPopUp";

const DateFilter: React.FC = () => {
  const buttonId = useId("callout-button");
  const CalendarIcon = bundleIcon(CalendarFilled, CalendarRegular);
  const airport = useAppSelector((state) => getAirportCode(state));
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [selectedButton, setSelectedButton] = React.useState<string>(
    DateTypeEnum.Default
  );
  const [displayStartTime, setDisplayStartTime] = React.useState<string>(
    getDisplayTime(airport, DateTypeEnum.Today, undefined, true)
  );
  const [displayEndTime, setDisplayEndTime] = React.useState<string>(
    getDisplayTime(airport, DateTypeEnum.Today)
  );
  //Integrate into initial API call
  const [_apiStartTime, setApiStartTime] = React.useState<Date>(
    getApiTime(airport, DateTypeEnum.Today, true)
  );
  const [selectedCalendarDate, setSelectedCalendarDate] =
    React.useState<Date>();
  const [_apiEndTime, setApiEndTime] = React.useState<Date>(
    getApiTime(airport, DateTypeEnum.Today)
  );
  const buttonText = `${displayStartTime} - ${displayEndTime}`;
  return (
    <div data-testid="date-filter" className={`${useStyles.buttonArea}`}>
      <Button
        appearance="transparent"
        className={useStyles.calloutButton}
        onClick={toggleIsCalloutVisible}
        icon={{ className: useStyles.callOutIcon, children: <CalendarIcon /> }}
        id={buttonId}
      >
        <span>{buttonText}</span>
      </Button>
      {isCalloutVisible && (
        <DateFilterPopUp
          onSetApiStartTime={setApiStartTime}
          onSetApiEndTime={setApiEndTime}
          onSetDisplayStartTime={setDisplayStartTime}
          onSetDisplayEndTime={setDisplayEndTime}
          onSetSelectedButton={setSelectedButton}
          onSetSelectedCalendarDate={setSelectedCalendarDate}
          selectedCalendarDate={selectedCalendarDate}
          buttonId={buttonId}
          toggleIsCalloutVisible={toggleIsCalloutVisible}
          isCalloutVisible={isCalloutVisible}
          selectedButton={selectedButton}
        />
      )}
    </div>
  );
};

export default DateFilter;

const theme = getTheme();
const useStyles = mergeStyleSets({
  calloutButton: {
    "&.fui-Button": {
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "4px",
      paddingBottom: "4px",
    },

    ":hover": {
      background: `${theme.palette.blueMid}`,
    },
    flexDirection: "row-reverse",
  },
  callOutIcon: {
    ".fui-Button &": {
      paddingLeft: "2px",
      paddingRight: "0px",
      marginRight: "0px",
    },
  },
  buttonArea: {
    textAlign: "center",
    borderRadius: "4px",
    ":hover": {
      background: `#c5d5fa`,
    },
  },
});
