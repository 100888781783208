import { mergeStyles } from "@fluentui/react";

export const rootContainer: React.CSSProperties = {
  width: "92%",
  margin: "auto",
  position: "relative",
  paddingBottom: "20px",
};

export const homeContainer = mergeStyles({
  width: "100%",
  position: "relative",
  margin: "auto",
  selectors: {
    "@media all and (device-width: 768px)": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
});

export const detailContainer: React.CSSProperties = {
  background: "#FFFFFF",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "20px",
  borderRadius: "5px",
};
