import axios from "axios";
import RESOURCE_SUMMARY from "../../mock/resource-allocation/resource-allocation-summary.json";
import {
  AIRPORT_CODE_PARAMETER,
  CONTENT_TYPE,
} from "../../utils/common/Constants";
import DateHelper from "../../utils/common/DateHelper";

export default class ResourceAllocationService {
  public getResourceSummary = async (_accessToken: string): Promise<any> => {
    return Promise.resolve({ data: RESOURCE_SUMMARY });
  };

  public getResourceAllocation = async (
    accessToken: string,
    resourceType: string,
    airportCode: string,
    resourceSubType?: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    const dateStr = DateHelper.getDefaultDateStrForAirport(airportCode);
    return axios.get(
      `${process.env.REACT_APP_API_RESOURCEALLOCATION?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${resourceType}/${dateStr}?resourceSubType=${resourceSubType}`,
      { headers }
    );
  };

  public getResourceAvailability = async (
    accessToken: string,
    resourceType: string,
    legType = "",
    airportCode: string,
    resourceSubType?: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    const dateStr = DateHelper.getDefaultDateStrForAirport(airportCode);
    return axios.get(
      `${process.env.REACT_APP_API_RESOURCEAVAILABILITY?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${resourceType}/${dateStr}?resourceSubType=${resourceSubType}&direction=${legType}`,
      { headers }
    );
  };

  public getResourceAllocationDetails = async (
    accessToken: string,
    airportCode: string,
    resourceType: string,
    group: string,
    _offset: number,
    legType?: string,
    resourceSubType?: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    const dateStr = DateHelper.getDefaultDateStrForAirport(airportCode);
    return axios.get(
      `${process.env.REACT_APP_API_RESOURCEALLOCATION?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${resourceType}/${group}/${dateStr}?resourceSubType=${resourceSubType}&direction=${legType}`,
      { headers }
    );
  };

  public getResourceGraphData = async (
    accessToken: string,
    payload: any,
    resourceType: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json;charset=UTF-8",
    };
    return axios.post(
      `${process.env.REACT_APP_API_FLIGHTSCHEDULEGRAPH?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${resourceType}`,
      JSON.stringify(payload),
      { headers }
    );
  };
}
