import { mergeStyles } from "@fluentui/react";
import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useApiCall } from "../../hooks/useApiCall";
import INotificationListItem, {
  INotificationListItemData,
} from "../../models/notification/INotificationListItem";
import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import { NoDataCard } from "../common/no-data-card/NoDataCard";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";
import { NotificationFilters } from "./NotificationFilters";
import { NotificationItem } from "./NotificationItem";
import { NotificationList } from "./NotificationList";

export const NotificationsContainer: React.FC = () => {
  const airport = useAppSelector((state) => getAirportCode(state));
  const getNotificationItemsUrl = `${process.env.REACT_APP_API_NOTIFICATION?.replace(
    "{airportCode}",
    airport
  )}`;

  //Fetch Pax Settings Touchpoints using Custom API hook
  const {
    data: notificationItems,
    error,
    isLoading,
  } = useApiCall<INotificationListItemData>({
    url: getNotificationItemsUrl,
    dependencies: [airport],
  });

  const notificationListData = notificationItems?.data
    ? notificationItems.data
    : [];
  const selectedNotification = notificationItems?.data
    ? notificationItems?.data[0]
    : undefined;

  const [selectedNotificationDetails, setSelectedNotificationDetails] =
    useState<INotificationListItem>();

  useEffect(() => {
    setSelectedNotificationDetails(selectedNotification);
  }, [selectedNotification]);

  const onSelectNotification = (notification: INotificationListItem) => {
    setSelectedNotificationDetails(notification);
  };

  const onPillButtonClick = (selectedButtons: []) => {
    console.log(selectedButtons);
  };

  return (
    <>
      <NotificationBanner airport={airport} />
      <div
        className="amd-heading1 mb-3"
        data-testid="notificationsListContainer"
      >
        NOTIFICATIONS
      </div>

      <div className="ms-Grid mb-2" dir="ltr">
        <NotificationFilters onPillButtonClick={onPillButtonClick} />
      </div>

      {error && <NoDataCard message="No Notifications found" />}
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          {isLoading && (
            <Spinner labelPosition="below" label="Loading..." size="huge" />
          )}
          {!error && !isLoading && (
            <>
              <div
                className={`ms-Grid-col ms-md4 ${notificationsListContainer}`}
              >
                <NotificationList
                  notificationItems={notificationListData}
                  onSelectNotification={onSelectNotification}
                  selectedNotificationId={selectedNotificationDetails?.id}
                />
              </div>
              <div className="ms-Grid-col ms-md8">
                <NotificationItem
                  selectedNotification={selectedNotificationDetails}
                  airport={airport}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const notificationsListContainer = mergeStyles({
  height: "calc(100vh - 199px)",
  maxHeight: "calc(100vh - 199px)",
});
