import moment from "moment-timezone";
import { IFilterPanelProps } from "../../components/filters/filter-panel/FilterPanel";
import FlightsEnum from "../../enum/FlightsEnum";
import HOPEnum from "../../enum/HOPEnum";
import FLIGHT_DASHBOARD_FILTERS from "../../mock/filters/filters.json";
import FLIGHT_DASHBOARD_FILTERS_XYZ from "../../mock/filters/filters_XYZ.json";
import IFilters, { IFilterItem } from "../../models/filters/IFilters";
import { IFlightHourFilter } from "../../models/flights/IFlightHour";
import { IPillButton } from "../../models/flights/IFlightPillButton";
import { IFlightScheduleFilters } from "../../models/flights/IFlightScheduleFilters";
import { HOUR_FILTERS } from "../common/Constants";
import DateHelper from "../common/DateHelper";

export default class FlightScheduleHelper {
  public static getSelectedItems(filters: IFilters[]) {
    const selectedItems: IFilterItem[] = [];
    filters.forEach((filter) => {
      filter.filterItems.forEach((item) => {
        if (item.selected && item.selectedValue) {
          if (item.type === "text") {
            const arr = item.selectedValue?.split(",");
            arr?.forEach((val) => {
              const obj = { ...item };
              obj.displayValue = val;
              selectedItems.push(obj);
            });
          } else {
            selectedItems.push(item);
          }
        }
      });
    });
    return selectedItems;
  }

  public static getFlightlistState(selectedItems: IFilterItem[]) {
    const flightListProps: any = {};
    selectedItems.forEach((selectedItem: IFilterItem) => {
      if (selectedItem.name) {
        if (flightListProps[selectedItem.name] === undefined) {
          flightListProps[selectedItem.name] = "";
        }
        if (selectedItem.type === "text") {
          flightListProps[selectedItem.name] = selectedItem.selectedValue;
        } else {
          flightListProps[selectedItem.name] +=
            selectedItem.selectedValue + ";";
        }
      }
    });
    return flightListProps;
  }

  public static getPillButtons(airport: string | undefined) {
    const pillButtons: IPillButton[] = [];
    const pillButtonJson = {
      data: [
        { text: "All", columnName: "", columnValue: "", isSelected: true },
      ],
    };
    const airportFlightFilter =
      airport === "XYZ"
        ? FLIGHT_DASHBOARD_FILTERS_XYZ.data
        : FLIGHT_DASHBOARD_FILTERS.data;

    airportFlightFilter.forEach((filter) => {
      if (["status", "destinationType", "terminal"].includes(filter.key)) {
        filter.filterItems.forEach((filterItem) => {
          pillButtonJson.data.push({
            text: filterItem.value,
            columnName: filterItem.name,
            columnValue: filterItem.key,
            isSelected: filterItem.selected,
          });
        });
      }
    });

    pillButtonJson.data.forEach((f) => {
      const flightPillButton: IPillButton = {
        text: f.text,
        name: f.columnName,
        value: f.columnValue,
      };

      pillButtons.push(flightPillButton);
    });

    return pillButtons;
  }

  public static getHourFilterButtons() {
    const hourFilterButtons: IFlightHourFilter[] = [];
    const hourFilterArray = HOUR_FILTERS;

    hourFilterArray.data.forEach((f) => {
      const hourFilterButton: IFlightHourFilter = {
        text: f.text,
        name: f.columnName,
        value: f.columnValue,
      };

      hourFilterButtons.push(hourFilterButton);
    });

    return hourFilterButtons;
  }

  public static setUpdatedStateObject(selectedButton: string, stateObj: any) {
    const keyValuePair = selectedButton.split("|");
    const _columnName = keyValuePair[0];
    const _columnValue = keyValuePair[1];

    if (_columnName.toLowerCase() === "status") {
      stateObj.status += _columnValue + ";";
    }
    if (_columnName.toLowerCase() === "destinationtype") {
      stateObj.destinationType += _columnValue + ";";
    }
    if (_columnName.toLowerCase() === "terminal") {
      stateObj.terminal += _columnValue + ";";
    }
  }

  public static clearAllSelectedFilters(
    filters: IFilters[],
    selectedItems: IFilterItem[],
    filterPanelProps: IFilterPanelProps
  ) {
    return filters.map((filter) => {
      let filterArray: IFilterItem[] = [];
      filterArray = filter.filterItems.map((item: IFilterItem) => ({
        ...item,
        selected: false,
        selectedValue: "",
        displayValue: "",
      }));
      const flightListProps = this.clearFlightListState(selectedItems);
      filterPanelProps.onFiltersApplied(flightListProps);
      return {
        ...filter,
        filterItems: filterArray,
      };
    });
  }

  public static clearFlightListState(selectedItems: IFilterItem[]) {
    const flightListProps: any = {};
    selectedItems.forEach((selectedItem: IFilterItem) => {
      if (selectedItem.name) {
        flightListProps[selectedItem.name] = "";
      }
    });
    return flightListProps;
  }

  public static getUpdatedFilterArray(
    item: IFilterItem,
    key?: string,
    displayValue?: string,
    selectedItems?: IFilterItem[]
  ) {
    const updatedObj = { ...item };
    if (item.key === key && item.type === "text") {
      let arr = item.selectedValue?.split(",");
      arr = arr?.filter((e) => e !== displayValue);
      updatedObj.selectedValue = arr?.join(",");
      updatedObj.selected = updatedObj.selectedValue ? true : false;
      selectedItems
        ?.filter((s) => s.key === key && s.type === "text")
        .forEach((obj) => (obj.selectedValue = updatedObj.selectedValue));
    } else {
      updatedObj.selected = item.key === key ? !item.selected : item.selected;
      updatedObj.selectedValue = item.key === key ? "" : item.selectedValue;
      updatedObj.displayValue = item.key === key ? "" : item.displayValue;
    }
    return updatedObj;
  }

  public static clearFilterItem(
    filters: IFilters[],
    selectedItems: IFilterItem[],
    filterPanelProps: IFilterPanelProps,
    category?: string,
    key?: string,
    displayValue?: string
  ) {
    return filters.map((filter) => {
      let filterArray: IFilterItem[] = filter.filterItems;
      if (filter.key === category) {
        filterArray = filterArray.map((item: IFilterItem) =>
          this.getUpdatedFilterArray(item, key, displayValue, selectedItems)
        );
      }

      const flightListProps = this.setFilterListProps(
        selectedItems,
        category,
        key
      );
      filterPanelProps.onFiltersApplied(flightListProps);

      return {
        ...filter,
        filterItems: filter.key === category ? filterArray : filter.filterItems,
      };
    });
  }

  public static setFilterListProps(
    selectedItems: IFilterItem[],
    category?: string,
    key?: string
  ) {
    const flightListProps: any = {};
    selectedItems.forEach((selectedItem: IFilterItem) => {
      if (selectedItem.name && selectedItem.type === "text") {
        flightListProps[selectedItem.name] = selectedItem.selectedValue;
      } else {
        if (
          selectedItem.name &&
          !(selectedItem.name === category && selectedItem.key === key)
        ) {
          if (flightListProps[selectedItem.name] === undefined) {
            flightListProps[selectedItem.name] = "";
          }

          flightListProps[selectedItem.name] +=
            selectedItem.selectedValue + ";";
        }
      }
    });

    return flightListProps;
  }

  public static setDisabled(item: IFilterItem, isDisabled: any) {
    let disabledObj = { duration: true, start: true, end: true };
    if (item.key === "customDuration") {
      disabledObj = {
        ...isDisabled,
        duration: false,
      };
    } else if (item.key === "customTimeRange") {
      disabledObj = {
        ...isDisabled,
        start: false,
        end: false,
      };
    }
    return disabledObj;
  }

  public static getUpdatedFilterItems(
    airport: string,
    selectedFilters: any,
    category?: string,
    key?: string,
    type?: string,
    selectedDuration?: string,
    sTime?: Date,
    eTime?: Date
  ) {
    return selectedFilters.map((filter: any) => {
      let filterArray: IFilterItem[] = filter.filterItems;
      if (filter.key === category) {
        if (type === "radio") {
          filterArray = this.clearPreviousSelection(filterArray);
        }
        filterArray = this.setSelected(
          airport,
          filterArray,
          key,
          selectedDuration,
          key === "customTimeRange" ? sTime : undefined,
          key === "customTimeRange" ? eTime : undefined
        );
      }
      return {
        ...filter,
        filterItems: filter.key === category ? filterArray : filter.filterItems,
      };
    });
  }

  public static clearPreviousSelection(filterArray: IFilterItem[]) {
    return filterArray.map((item: IFilterItem) => ({
      ...item,
      selected: false,
      displayValue: "",
      selectedValue: "",
      selectedDuration: "",
    }));
  }

  public static setSelected(
    airport: string,
    filterArray: IFilterItem[],
    key?: string,
    selectedDuration?: string,
    sTime?: Date,
    eTime?: Date
  ) {
    return filterArray.map((item: IFilterItem) => ({
      ...item,
      selected: this.getSelected(item, key),
      selectedValue: this.getSelectedValue(
        airport,
        item,
        sTime,
        eTime,
        selectedDuration
      ),
      displayValue: this.getDisplayValue(item, sTime, eTime, selectedDuration),
      selectedDuration:
        item.key === "customDuration" && selectedDuration
          ? selectedDuration
          : "",
    }));
  }

  public static getSelected(item: IFilterItem, key?: string) {
    if (
      item.key === key &&
      (item.type === "text" || item.type === "tagpicker")
    ) {
      return true;
    } else if (item.key === key) {
      return !item.selected;
    }
    return item.selected;
  }

  public static getSelectedValue(
    airport: string,
    item: IFilterItem,
    sTime?: Date,
    eTime?: Date,
    selectedDuration?: string
  ) {
    if (item.key === "customTimeRange" && sTime && eTime) {
      return `${DateHelper.getFormattedTime(
        sTime
      )}|${DateHelper.getFormattedTime(eTime)}`;
    } else if (item.key === "customDuration" && selectedDuration) {
      return DateHelper.getCustomDuration(airport, selectedDuration);
    } else if (item.key?.includes("custom")) {
      return "";
    } else if (item.type === "text" || item.type === "tagpicker") {
      return selectedDuration;
    }
    return item.key;
  }

  public static getDisplayValue(
    item: IFilterItem,
    sTime?: Date,
    eTime?: Date,
    selectedDuration?: string
  ) {
    if (item.key === "customTimeRange" && sTime && eTime) {
      return `Duration (${moment(sTime).format("HH:mm")}-${moment(eTime).format(
        "HH:mm"
      )})`;
    } else if (item.key === "customDuration" && selectedDuration) {
      return `Duration (${selectedDuration})`;
    } else if (
      (item.type === "text" || item.type === "tagpicker") &&
      selectedDuration
    ) {
      return selectedDuration;
    }
    return item.value;
  }

  public static getDefaultStartTime(airport: string) {
    return DateHelper.getFormattedMomentTime(
      DateHelper.getCurrentDateTimeForAirport(airport).startOf("day")
    );
  }

  public static getDefaultEndTime(airport: string) {
    return DateHelper.getFormattedMomentTime(
      DateHelper.getCurrentDateTimeForAirport(airport).hours(23).minutes(59)
    );
  }

  public static getDefaultFlightListState(
    airport: string,
    hour: number,
    viewType?: string
  ) {

    const currentDate = new Date(
      DateHelper.getCurrentDateTimeStrForAirport(airport)
    );
    const airportTimePlusOne = DateHelper.getFormattedTime(
      moment(currentDate).add(hour, "hours").toDate()
    );
    const currentDateString = DateHelper.getFormattedTime(currentDate);

    const flightListObj: IFlightScheduleFilters = {
      flightType: FlightsEnum.LegType.All,
      start: currentDateString,
      end: airportTimePlusOne,
      status: "",
      terminal: "",
      destinationType: "",
      flightNumbers: "",
      airline: "",
      origins: "",
      destinations: "",
      delayCodes: "",
      atmCategory: "",
      page: "50",
      viewType: viewType ? viewType : FlightsEnum.ViewType.Flight,
      baggageBelt: "",
      baggageChute: "",
      plotFilter: "",
      stand: "",
      gate: "",
      standType: "",
    };

    return flightListObj;
  }

  public static getDefaultOtpFlightListState(
    viewType: string,
    airport: string
  ) {
    const currentDateString = FlightScheduleHelper.getDefaultEndTime(airport);
    const currentDate12AMString =
      FlightScheduleHelper.getDefaultStartTime(airport);

    const flightListObj: IFlightScheduleFilters = {
      flightType: this.getFlightType(viewType),
      start: currentDate12AMString,
      end: currentDateString,
      status: "",
      terminal: "",
      destinationType: "",
      atmCategory: "",
      page: "50",
      viewType: this.getViewType(viewType),
    };

    return flightListObj;
  }

  public static getViewType(viewType: string) {
    return viewType === HOPEnum.HOPDIMENSIONS.OTP
      ? FlightsEnum.ViewType.OTP
      : viewType;
  }
  public static getFlightType(selectedTab: string) {
    let flightType = "";
    if (selectedTab === HOPEnum.HOPDIMENSIONS.RunwayDelay) {
      flightType = FlightsEnum.LegType.Departure;
    } else if (selectedTab === HOPEnum.HOPDIMENSIONS.TurnaroundSufficiency) {
      flightType = FlightsEnum.LegType.Mixed;
    } else {
      flightType = FlightsEnum.LegType.Arrival;
    }
    return flightType;
  }

  public static getDefaultSelectedDimension() {
    let dimensionName = HOPEnum.HOPDIMENSIONS.OTP.toString();
    const selected =
      window.sessionStorage.getItem("selectedDimension")?.toString() ?? "";
    if (selected) {
      dimensionName = selected;
      window.sessionStorage.removeItem("selectedDimension");
    }
    return dimensionName;
  }

  public static setFlightListObj(
    flightType: string,
    stateObj: any,
    responseData: any
  ) {
    switch (flightType.toLowerCase()) {
      case FlightsEnum.LegType.Arrival:
        stateObj = {
          ...stateObj,
          arrivalData: responseData.arrivals,
          departureData: [],
          total: responseData.total,
        };
        break;

      case FlightsEnum.LegType.Departure:
        stateObj = {
          ...stateObj,
          arrivalData: [],
          departureData: responseData.departures,
          total: responseData.total,
        };
        break;

      default:
        stateObj = {
          ...stateObj,
          arrivalData: responseData.arrivals,
          departureData: responseData.departures,
          total: responseData.total,
        };
    }

    return stateObj;
  }

  public static configureScheduleTimeRange(
    stateObj: IFlightScheduleFilters,
    timeRangeValue: string,
    airport: string
  ) {
    if (timeRangeValue.indexOf("|") > 0) {
      if (timeRangeValue?.indexOf(";") > 0) {
        timeRangeValue = timeRangeValue.slice(0, -1);
      }

      const filterDatesArray = timeRangeValue.split("|");
      stateObj.start = filterDatesArray[0];
      stateObj.end = filterDatesArray[1];
    } else if (timeRangeValue.indexOf("hr;") > 0) {
      const hourString = timeRangeValue.split("hr;")[0].trim();
      const currentDate = new Date(
        DateHelper.getCurrentDateTimeStrForAirport(airport)
      );
      const currentDateString = DateHelper.getFormattedTime(currentDate);
      const endDateString = DateHelper.getFormattedTime(
        moment(currentDate)
          .add(parseFloat(hourString ? hourString : "1"), "hours")
          .toDate()
      );
      stateObj.start = currentDateString;
      stateObj.end = endDateString;
    }

    return stateObj;
  }

  public static setFilterValue(
    key: string,
    stateObj: IFlightScheduleFilters,
    filters: IFlightScheduleFilters
  ) {
    if (filters.hasOwnProperty(key)) {
      (stateObj as { [k in string]: any })[key] = (
        filters as { [k in string]: any }
      )[key];
    }
  }

  public static onFlightScheduleFiltersApplied(
    stateObj: IFlightScheduleFilters,
    filters: IFlightScheduleFilters,
    airport: string
  ) {
    this.setFilterValue("status", stateObj, filters);
    this.setFilterValue("terminal", stateObj, filters);
    this.setFilterValue("destinationType", stateObj, filters);
    this.setFilterValue("flightNumbers", stateObj, filters);
    this.setFilterValue("origins", stateObj, filters);
    this.setFilterValue("destinations", stateObj, filters);
    this.setFilterValue("delayCodes", stateObj, filters);
    this.setFilterValue("atmCategory", stateObj, filters);
    this.setFilterValue("baggageBelt", stateObj, filters);
    this.setFilterValue("baggageChute", stateObj, filters);
    this.setFilterValue("gate", stateObj, filters);
    this.setFilterValue("stand", stateObj, filters);
    this.setFilterValue("checkInCounterGroup", stateObj, filters);
    this.setFilterValue("checkInCounter", stateObj, filters);
    this.setFilterValue("paxType", stateObj, filters);

    if (
      filters.hasOwnProperty("scheduleTimeRange") ||
      filters.hasOwnProperty("otpTimeRange")
    ) {
      const timeRange = filters.scheduleTimeRange ?? filters.otpTimeRange;
      const timeRangeValue = timeRange ? timeRange : "1 hr;";
      stateObj = FlightScheduleHelper.configureScheduleTimeRange(
        stateObj,
        timeRangeValue,
        airport
      );
    }

    return stateObj;
  }

  public static scrollToComponent(hash?: string) {
    // if not a hash link, scroll to top
    if (hash === null) {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash?.replace("#", "");
        if (id) {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
            window.sessionStorage.removeItem("scrollId");
          }
        }
      }, 2000);
    }
  }

  public static getDefaultTime(
    airport: string,
    index: number,
    selectedValue?: string,
    selectedTime?: Date
  ) {
    const time = new Date(DateHelper.getCurrentDateTimeStrForAirport(airport));
    const selectedDate = this.getSelectedDateValue(index, selectedValue);
    if (selectedDate) {
      return new Date(selectedDate);
    } else if (selectedTime) {
      return new Date(selectedTime);
    }
    return time;
  }

  public static getSelectedDateValue(index: number, selectedValue?: string) {
    return selectedValue?.split("|")[index] ?? "";
  }

  public static handleLegendClick(
    selectedValue: string,
    flightListState: IFlightScheduleFilters,
    key: string
  ) {
    const stateObj = { ...flightListState };
    (stateObj as { [k in string]: any })[key] = selectedValue;
    return stateObj;
  }

  public static handleDataPointClick(
    x: string,
    _y: string,
    selectedValue: string,
    flightListState: IFlightScheduleFilters,
    key: string
  ) {
    const stateObj = { ...flightListState };
    const selectedDate = new Date(x);
    const startDate = moment(selectedDate).subtract(29, "minutes").toDate();
    stateObj.start = DateHelper.getFormattedTime(startDate);
    stateObj.end = DateHelper.getFormattedTime(selectedDate);
    (stateObj as { [k in string]: any })[key] = selectedValue;
    return stateObj;
  }

  public static handleResetGraphicClick(
    airport: string,
    defaultHourValue: number,
    flightListState: IFlightScheduleFilters,
    key: string,
    viewType: string
  ) {
    const defaultStateObj = FlightScheduleHelper.getDefaultFlightListState(
      airport,
      defaultHourValue,
      viewType
    );
    const stateObj = { ...flightListState };
    stateObj.start = defaultStateObj.start;
    stateObj.end = defaultStateObj.end;
    (stateObj as { [k in string]: any })[key] = "";
    return stateObj;
  }
}
