import { makeStyles, mergeClasses } from "@fluentui/react-components";
import {
  AirplaneLandingFilled,
  AirplaneTakeOffFilled
} from "@fluentui/react-icons";
import { StatusIconSelector } from "../common/icon-selectors/StatusIconSelector";

interface IAirportOTPCardProps {
  className?: string;
  otpData: any;
  isMapView?: boolean;
}

const AirportOTPCard: React.FunctionComponent<IAirportOTPCardProps> = ({
  className,
  otpData,
  isMapView = false,
}) => {
  const classes = useStyles();
  const containerClass = isMapView ? classes.containerMapView : undefined;
  return (
    <div
    className={`${mergeClasses(className, classes.container)} ${containerClass}`}
      data-testid="airport-otp-card"
    >
      <header className={classes.title}>{isMapView ? 'OTP' : 'On-time performance'}</header>
      <div className="d-flex">
      <div className="flex-1">
            <header className="fs-20 pb-sm-1">
              <StatusIconSelector status={otpData.overall.status} />
              <span>{`${otpData.overall.value} %`}</span>
            </header>
            <footer>Overall</footer>
          </div>
        {!isMapView ? (
        <>
            <div className="flex-1">
              <header className="fs-20 pb-sm-1">
                <StatusIconSelector status={otpData.arrival.status} />
                <span>{`${otpData.arrival.value} %`}</span>
              </header>
              <footer>
                <AirplaneLandingFilled
                  style={{ verticalAlign: "middle", paddingRight: "4px" }}
                />
                Arrival
              </footer>
            </div>
            <div className="flex-1">
              <header className="fs-20 pb-sm-1">
                <StatusIconSelector status={otpData.departure.status} />
                <span>{`${otpData.departure.value} %`}</span>
              </header>
              <footer>
                <AirplaneTakeOffFilled
                  style={{ verticalAlign: "middle", paddingRight: "4px" }}
                />
                Departure
              </footer>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AirportOTPCard;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    padding: "6px 12px",
  },
  containerMapView: {
    padding: "0",
    paddingLeft: "12px",
  },
  title: { color: "#666666", fontWeight: 500 },
  icon: {
    fontSize: "18px",
    strokeWidth: "0.5px",
    verticalAlign: "bottom",
    paddingRight: "4px",
  },
  goodIcon: {
    color: "#569643",
    stroke: "#569643",
  },
  errorIcon: {
    color: "#B2271F",
    stroke: "#B2271F",
  },
  warningIcon: {
    color: "#FA6400",
    stroke: "#FA6400",
  },
});
