import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useApiCall } from "../../hooks/useApiCall";
import {
  IPassengerResourceData,
  IPassengerSummaryApiData,
} from "../../models/pax/IPaxTypes";
import {
  AIRPORT_CODE_PARAMETER,
  DATE_PARAMETER,
  EMPTY_STATE_MESSAGE,
} from "../../utils/common/Constants";
import DateHelper from "../../utils/common/DateHelper";
import { PassengerHelper } from "../../utils/pax/PassengerHelper";
import { NoDataCard } from "../common/no-data-card/NoDataCard";
import { PassengerKPISection } from "./PassengerKPISection";

export interface IPassengerKPIProps {
  airport: string;
}

export const PassengerKPI: React.FunctionComponent<IPassengerKPIProps> = ({
  airport,
}) => {
  const [resourceData, setResourceData] = useState<
    IPassengerResourceData | undefined
  >(undefined);
  const dateStr = DateHelper.getDefaultDateStrForAirport(airport);

  const getPassengerSummaryUrl = `${process.env.REACT_APP_API_PAX_SUMMARY?.replace(
    AIRPORT_CODE_PARAMETER,
    airport
  ).replace(DATE_PARAMETER, dateStr)}`;

  const {
    data: passengerSummaryDataResponse,
    error,
    isLoading,
  } = useApiCall<IPassengerSummaryApiData>({
    url: getPassengerSummaryUrl,
    dependencies: [airport],
  });

  useEffect(() => {
    if (passengerSummaryDataResponse) {
      const paxResponse = Object.keys(passengerSummaryDataResponse).map(
        (key: string) => {
          const obj = passengerSummaryDataResponse[key];
          return { type: key, ...obj };
        }
      );
      const passengerSummaryData = paxResponse.filter(
        (element) => element.type !== "total"
      );

      setResourceData({
        total: PassengerHelper.getChartData(
          passengerSummaryData,
          "total",
          true
        ),
        arrival: PassengerHelper.getChartData(passengerSummaryData, "arrivals"),
        departure: PassengerHelper.getChartData(
          passengerSummaryData,
          "departures"
        ),
      });
    }
  }, [passengerSummaryDataResponse]);

  return (
    <>
      <div className="ms-Grid" dir="ltr" data-testid="passenger-kpi">
        {error && <NoDataCard message="No Passenger data found" />}
        {isLoading && (
          <Spinner
            labelPosition="below"
            label="Loading..."
            style={{ height: "25vh" }}
          />
        )}
        {!error && !isLoading && (
          <PassengerKPISection
            resourceData={resourceData}
            emptyStateMessage={EMPTY_STATE_MESSAGE.Passenger}
          />
        )}
      </div>
    </>
  );
};
