import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { INavLink, INavLinkGroup, mergeStyleSets, Nav } from "@fluentui/react";
import React from "react";
import { NotificationContext } from "../../context/NotificationContext";
import DIMENSIONS_DATA from "../../data/app-settings/dimensions.json";
import AADRoleEnum from "../../enum/AADRoleEnum";
import { SelectedDimensionTabEnum } from "../../enum/SelectedDimensionTabEnum";
import { SelectedNavEnum } from "../../enum/SelectedNavEnum";
import IDimension from "../../models/admin/IDimension";
import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import { AdminHelper } from "../../utils/admin/AdminHelper";
import { MESSAGEBAR_TYPE } from "../../utils/common/Constants";
import { AuthZ } from "../common/auth/AuthZ";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";
import { DimensionDetails } from "./DimensionDetails";
import { DimensionTabs } from "./DimensionTabs";
import { PaxSettings } from "./passenger-flow/PaxSettings";
import { AppSettingsProps } from "./types";

const fetchDimensionData = DIMENSIONS_DATA;

const defaultNavLinkGroups = [
  {
    links: [
      {
        name: "Health of operations",
        url: "#",
        key: SelectedNavEnum.HEALTH_OF_OPERATIONS,
        target: "_blank",
      },
      {
        name: "Passenger flow",
        url: "#",
        key: SelectedNavEnum.PASSENGER_FLOW,
        target: "_blank",
      },
    ],
  },
];

export const AppSettings: React.FunctionComponent<AppSettingsProps> = ({
  dimensionData = fetchDimensionData,
  navLinkGroups = defaultNavLinkGroups || [],
}) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const roles = [AADRoleEnum.Admin];
  const { addNotification } = React.useContext(NotificationContext);

  const [navLinks] = React.useState<INavLinkGroup[]>(navLinkGroups);
  const [selectedNavLink, setSelectedNavLink] = React.useState<SelectedNavEnum>(
    SelectedNavEnum.HEALTH_OF_OPERATIONS
  );

  const [selectedDimensionTab, setSelectedDimensionTab] =
    React.useState<string>(SelectedDimensionTabEnum.OTP);
  const [selectedDimensionDetails, setSelectedDimensionDetails] =
    React.useState<IDimension>(dimensionData?.dimensions[0]);

  const airportCode = useAppSelector((state) => getAirportCode(state));

  function onNavClick(
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ): void {
    ev?.preventDefault();
    ev?.stopPropagation();
    if (item?.key) {
      setSelectedNavLink(item.key as SelectedNavEnum);
    }
  }

  const onChangeThresholdValue = (newDimensionDetails: IDimension) => {
    setSelectedDimensionDetails(newDimensionDetails);
  };

  const onSaveDimension = (newDimensionDetails: IDimension) => {
    const payload = AdminHelper.getSaveDimensionPayload(newDimensionDetails);
    AdminHelper.saveThresholdByDimension(
      msalInstance,
      newDimensionDetails.name,
      payload,
      airportCode
    ).then((response: any) => {
      if (response && response.status === 200) {
        addNotification(
          MESSAGEBAR_TYPE.SUCCESS,
          `Application settings saved successfully`
        );
      }
    });
  };

  const onDimensionTabChange = (
    selectedType: SelectedDimensionTabEnum
  ): void => {
    const [...dimensions] = dimensionData?.dimensions;
    const matchedDimension = dimensions?.filter(
      (d: IDimension) => d.name === selectedType
    )[0];
    setSelectedDimensionDetails(matchedDimension);
    setSelectedDimensionTab(matchedDimension.name);
  };

  const dimensionTabList = dimensionData?.dimensions?.map((d: IDimension) => {
    return {
      value: d.name,
      text: d.label,
    };
  });

  return (
    <AuthZ roles={roles}>
      <>
        <NotificationBanner airport={airportCode} />
        <h1 className="amd-heading1">Application settings</h1>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-md2">
              <Nav
                onLinkClick={onNavClick}
                selectedKey={selectedNavLink}
                ariaLabel="Application settings nav"
                data-testId="application-sub-nav"
                styles={navStyles}
                groups={navLinks}
                className={`amd-nav`}
              />
            </div>

            <div className="ms-Grid-col ms-md10">
              {selectedNavLink === SelectedNavEnum.HEALTH_OF_OPERATIONS ? (
                <section className="amd-section-shadow">
                  <h2 className="fs-36 fw-400 fc--gray900 mb-3 mt-3">
                    Health of Operations
                  </h2>
                  <DimensionTabs
                    onDimensionTabChange={onDimensionTabChange}
                    dimensionName={selectedDimensionTab}
                    dimensionTabList={dimensionTabList}
                  />
                  <DimensionDetails
                    onChangeThresholdValue={onChangeThresholdValue}
                    onSaveDimension={onSaveDimension}
                    selectedDimensionDetails={selectedDimensionDetails}
                  />
                </section>
              ) : null}
              {selectedNavLink === SelectedNavEnum.PASSENGER_FLOW ? (
                <PaxSettings airport={airportCode} />
              ) : null}
            </div>
          </div>
        </div>
      </>
    </AuthZ>
  );
};

const navStyles = mergeStyleSets({
  root: {
    width: 193,
    height: 350,
    boxSizing: "border-box",
    border: "1px solid #eee",
    overflowY: "auto",
  },
});
