import { ITag } from "@fluentui/react/lib/Pickers";
import { Airline } from "../../enum/global-filter/GlobalFilterEnum";
import AIRLINES_MOCK from "../../mock/global-filter/airlines-data.json";
import { operatorOptions } from "../query-filter/OueryFilterConstants";

export const getAirlines = () => AIRLINES_MOCK.data.airlines;

export const getTextFromItem = (item: ITag) => item.name;

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const filterSuggestedTags = (
  controlId: string,
  filter: string,
  selectedItems?: ITag[]
): ITag[] | PromiseLike<ITag[]> => {
  //We need to integrate the API call here to get the list of tags based on controlId
  const itemList = controlId === Airline.Values ? getAirlines() : [];
  return filter
    ? itemList.filter(
        (tag) =>
          tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0 &&
          !listContainsTagList(tag, selectedItems)
      )
    : [];
};

export const getOpearatorOptions = (inputType: string) => {
  switch (inputType) {
    case "number":
      return operatorOptions.number;
    case "string":
      return operatorOptions.common;
    default:
      return operatorOptions.common;
  }
};
