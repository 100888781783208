import PageEnum from "../../../../enum/PageEnum";
import { AmdButton } from "../../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { EventKPI } from "../event-kpi/EventKPI";

export interface IEventSummaryCardProps {
  totalCase?: any[];
  inProgressCase?: any[];
}

export const EventSummaryCard: React.FunctionComponent<
  IEventSummaryCardProps
> = (props: IEventSummaryCardProps) => {
  // This function is called when the user clicks on the "Create New Event" button
  const onCreateEventHandler = () => {
    window.sessionStorage.setItem("isNewEventClicked", "true");
    window.location.href = `/${PageEnum.PageUrl.EventManagement}`;
  };

  return (
    <div className="ms-Grid mb-2" dir="ltr">
      <div className="ms-Grid-row" data-testid="event-summary-card">
        {props.totalCase && (
          <div className="ms-Grid-col ms-md4 divider-v-grey--rt">
            <EventKPI eventKPIData={props.totalCase} kpiName="Total Events" />
          </div>
        )}
        {props.inProgressCase && (
          <div className="ms-Grid-col ms-md4 divider-v-grey--rt">
            <EventKPI
              eventKPIData={props.inProgressCase}
              kpiName="Total Inprogress Events"
            />
          </div>
        )}
        <div className="ms-Grid-col ms-md4 amd-container-hcenter">
          <AmdButton
            testId="createEvent"
            text="Create New Event"
            className="mt-3"
            type={AmdButtonType.DASHED}
            onClick={onCreateEventHandler}
          />
        </div>
      </div>
    </div>
  );
};
