import { Spinner } from "@fluentui/react-components";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import FLIGHT_SCHEDULE_FILTERS from "../../../data/filter-panel/flight-schedule.json";
import FLIGHT_SCHEDULE_FILTERS_XYZ from "../../../data/filter-panel/flight-schedule_XYZ.json";
import ResourceEnum from "../../../enum/ResourceEnum";
import { useApiCall } from "../../../hooks/useApiCall";
import IFilters from "../../../models/filters/IFilters";
import { IFlightGraphResponse } from "../../../models/flights/IFlightGraph";
import { IFlightScheduleFilters } from "../../../models/flights/IFlightScheduleFilters";
import { getMemoizedFlightScheduleFilters } from "../../../store/flights-filter/FlightFilterSelector";
import { flightsFilterActions } from "../../../store/flights-filter/FlightFiltersSlice";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import ChartHelper from "../../../utils/common/ChartHelper";
import { AIRPORT_CODE_PARAMETER } from "../../../utils/common/Constants";
import DateHelper from "../../../utils/common/DateHelper";
import { FlightListHelper } from "../../../utils/flights/FlightListHelper";
import FlightScheduleHelper from "../../../utils/flights/FlightScheduleHelper";
import { CustomLineChart } from "../../common/charts/LineChart";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdDismiss } from "../../common/icons/dismiss/dismiss";
import { FlightDetailList } from "../flights-list/FlightDetailList";
import { FlightsFilterSection } from "./FlightsFilterSection";

const defaultHourValue = 12;

export const FlightsMacroCard: React.FunctionComponent<IFlightsMacroCard> = (
  props: IFlightsMacroCard
) => {
  const [flightListState, setFlightListState] =
    useState<IFlightScheduleFilters>(
      FlightScheduleHelper.getDefaultFlightListState(
        props.airport,
        defaultHourValue,
        ResourceEnum.ResourceType.Flight
      )
    );

  const [totalFlights, setTotalFlights] = useState<number>();
  const [selectedHourFilter, setSelectedHourFilter] = useState<string>("1");

  const localStorageFiltersKey = `${props.airport}flightScheduleFilters`;
  const dispatch = useAppDispatch();
  const filterObj = localStorage.getItem(localStorageFiltersKey);
  let filters: IFilters[] = [];
  if (filterObj) {
    filters = JSON.parse(filterObj);
  }

  const setFlightScheduleFilters = () => {
    if (filters && filters.length > 0) {
      dispatch(
        flightsFilterActions.setFlightScheduleFilters({
          airport: props.airport,
          filter: filters,
        })
      );
      const selectedItems = FlightScheduleHelper.getSelectedItems(filters);
      const flightListState =
        FlightScheduleHelper.getFlightlistState(selectedItems);
      onFiltersApplied(flightListState);
    } else {
      const airportFlightScheduleFilter =
        props.airport === "XYZ"
          ? FLIGHT_SCHEDULE_FILTERS_XYZ.data
          : FLIGHT_SCHEDULE_FILTERS.data;
      dispatch(
        flightsFilterActions.setFlightScheduleFilters({
          airport: props.airport,
          filter: airportFlightScheduleFilter,
        })
      );
    }
  };

  const flightSchedulefilters = useAppSelector(getMemoizedFlightScheduleFilters);

  useEffect(() => {
    FlightScheduleHelper.scrollToComponent(
      window.sessionStorage.getItem("scrollId")?.toString()
    );
    window.sessionStorage.removeItem("scrollId");

    setFlightScheduleFilters();
  }, [dispatch]);

  const getFlightGraphDataUrl =
    process.env.REACT_APP_API_FLIGHTSCHEDULEGRAPH?.replace(
      AIRPORT_CODE_PARAMETER,
      props.airport
    ) || "";

  const flightListServicePayload =
    FlightListHelper.getFlightListServicePayload(flightListState);

  const {
    data: flightGraphData,
    error: _error,
    isLoading,
  } = useApiCall<IFlightGraphResponse[]>({
    url: getFlightGraphDataUrl,
    payload: flightListServicePayload,
    dependencies: [props.airport, flightListState],
    requestType: "post",
  });

  const onFlightTypeChange = (_flightType: string) => {
    const stateObj = { ...flightListState };
    stateObj.flightType =
      _flightType.toLowerCase() === "all" ? "arrival;departure" : _flightType;
    setFlightListState(stateObj);
  };

  const onPillButtonClick = (selectedButtons: []) => {
    const stateObj = { ...flightListState };
    stateObj.status = "";
    stateObj.destinationType = "";
    stateObj.terminal = "";
    selectedButtons.forEach((selectedButton: string) => {
      FlightScheduleHelper.setUpdatedStateObject(selectedButton, stateObj);
      setFlightListState(stateObj);
    });
  };

  const onHourFilterClick = (_filterString: string) => {
    setSelectedHourFilter(_filterString);
    const currentDate = new Date(
      DateHelper.getCurrentDateTimeStrForAirport(props.airport)
    );
    const stateObj = { ...flightListState };
    const defaultEndDateString = DateHelper.getFormattedTime(
      moment(currentDate).add(parseFloat(_filterString), "hours").toDate()
    );

    stateObj.start = DateHelper.getFormattedTime(currentDate);
    stateObj.end = defaultEndDateString;
    setFlightListState(stateObj);
  };

  const onPageDropDownChange = (_pageNumber: string) => {
    const stateObj = { ...flightListState };
    stateObj.page = _pageNumber;
    setFlightListState(stateObj);
  };

  const getFlightTotal = (_total: number) => {
    setTotalFlights(_total);
  };

  const onFiltersApplied = (_filters: IFlightScheduleFilters) => {
    let stateObj = FlightScheduleHelper.getDefaultFlightListState(
      props.airport,
      defaultHourValue
    );
    stateObj = FlightScheduleHelper.onFlightScheduleFiltersApplied(
      stateObj,
      _filters,
      props.airport
    );

    setFlightListState(stateObj);
  };

  const onLegendClick = (_selectedLegend: string) => {
    setFlightListState(
      FlightScheduleHelper.handleLegendClick(
        _selectedLegend,
        flightListState,
        "plotFilter"
      )
    );
  };

  const onDataPointClick = (x: string, y: string, legend: string) => {
    setFlightListState(
      FlightScheduleHelper.handleDataPointClick(
        x,
        y,
        legend,
        flightListState,
        "plotFilter"
      )
    );
  };

  const onUpdateFilter = (updatedItems: IFilters[]) => {
    dispatch(
      flightsFilterActions.setFlightScheduleFilters({
        airport: props.airport,
        filter: updatedItems,
      })
    );
  };

  const onResetGraphicClick = () => {
    setFlightListState(
      FlightScheduleHelper.handleResetGraphicClick(
        props.airport,
        defaultHourValue,
        flightListState,
        "plotFilter",
        ResourceEnum.ResourceType.Flight
      )
    );
  };

  return (
    <>
      <FlightsFilterSection
        isDetailsPage={props.isDetailsPage}
        onFlightTypeChange={onFlightTypeChange}
        onHourFilterClick={onHourFilterClick}
        selectedHourFilter={selectedHourFilter}
        onPillButtonClick={onPillButtonClick}
        onPageDropDownChange={onPageDropDownChange}
        totalFlights={totalFlights}
        flightListState={flightListState}
        flightSchedulefilters={flightSchedulefilters}
        onFiltersApplied={onFiltersApplied}
        onUpdateFilter={onUpdateFilter}
        airport={props.airport}
      />
      <FlightDetailList
        flightListState={flightListState}
        selectedType={flightListState.flightType}
        getFlightTotal={getFlightTotal}
        viewType={ResourceEnum.ResourceType.Flight}
        airport={props.airport}
      />
      {props.isDetailsPage && (
        <>
          <div className="amd-container-hend mt-2">
            <AmdButton
              type={AmdButtonType.DEFAULT}
              className="mr-1"
              onClick={onResetGraphicClick}
              testId="reset-graphic"
            >
              <>
                <span>Reset Graphics</span>
                <AmdDismiss className="ml-1" />
              </>
            </AmdButton>
          </div>
          {isLoading && (
            <Spinner
              labelPosition="below"
              label="Loading..."
              style={{ height: "25vh" }}
            />
          )}
          {flightGraphData && (
            <CustomLineChart
              chartData={flightGraphData}
              onDataPointClick={onDataPointClick}
              onLegendClick={onLegendClick}
              resourceType={ResourceEnum.ResourceType.Flight}
              xAxisTickCount={ChartHelper.getxAxisTickCount(flightGraphData)}
              customDateTimeFormatter={(a: any) =>
                `${("0" + a.getHours()).slice(-2).toString()}:${(
                  "0" + a.getMinutes()
                )
                  .slice(-2)
                  .toString()}`
              }
            />
          )}
        </>
      )}
    </>
  );
};

export interface IFlightsMacroCard {
  isDetailsPage: boolean;
  airport: string;
}
