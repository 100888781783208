import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const CalendarWeekIcon: React.FunctionComponent<IAmdIconProps> = ({
  color = "#FA6400",
  ...props
}) => {
  return (
    <span className={props.className}>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="calendar-week-icon"
      >
        <path
          d="M3.50012 9.5C3.21887 9.5 3.00012 9.28125 3.00012 9V7C3.00012 6.75 3.21887 6.5 3.50012 6.5H10.5001C10.7501 6.5 11.0001 6.75 11.0001 7V9C11.0001 9.28125 10.7501 9.5 10.5001 9.5H3.50012ZM12.5001 2C13.3126 2 14.0001 2.6875 14.0001 3.5V14.5C14.0001 15.3438 13.3126 16 12.5001 16H1.50012C0.656372 16 0.00012207 15.3438 0.00012207 14.5V3.5C0.00012207 2.6875 0.656372 2 1.50012 2H3.00012V0.5C3.00012 0.25 3.21887 0 3.50012 0H4.50012C4.75012 0 5.00012 0.25 5.00012 0.5V2H9.00012V0.5C9.00012 0.25 9.21887 0 9.50012 0H10.5001C10.7501 0 11.0001 0.25 11.0001 0.5V2H12.5001ZM12.5001 14.3125V5H1.50012V14.3125C1.50012 14.4375 1.56262 14.5 1.68762 14.5H12.3126C12.4064 14.5 12.5001 14.4375 12.5001 14.3125Z"
          fill={color}
        />
      </svg>
    </span>
  );
};
