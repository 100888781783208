import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import EventManagementEnum from "../../../../enum/EventManagementEnum";
import { CommonHelper } from "../../../../utils/common/CommonHelper";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { AmdButton } from "../../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../../../common/icons/arrow/arrow";
import { ArrowDirection } from "../../../common/icons/arrow/arrowDirection";
import { AmdDelete } from "../../../common/icons/delete/Delete";
import { AmdEdit } from "../../../common/icons/edit/Edit";
import { EventResponseTeam } from "../../create-event/response-team/EventResponseTeam";

export interface IEventViewChannelProps {
  eventId: string;
  channelId: string;
  hasManageChannelAccess: boolean;
  channelType: string;
  toolbarAction: any;
  airport: string;
}

export const EventViewChannel: React.FunctionComponent<
  IEventViewChannelProps
> = (props: IEventViewChannelProps) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [channelInfoApiResponse, setChannelInfoApiResponse] = useState<any>();

  let isMounted = true;
  useEffect(() => {
    if (props.airport !== "" && props.eventId) {
      EventManagementHelper.getEventChannelInfo(
        msalInstance,
        props.eventId,
        props.channelId,
        props.airport
      )
        .then((response: any) => {
          if (isMounted && response && response.data) {
            setChannelInfoApiResponse(response.data);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [props.airport, props.eventId, props.channelId]);

  const onEditChannel = () => {
    props.toolbarAction(EventManagementEnum.EventChannelViewType.Edit);
  };

  const onDeleteChannel = () => {
    props.toolbarAction(EventManagementEnum.EventChannelViewType.Delete);
  };

  const navigateBackToChannelList = () => {
    props.toolbarAction(EventManagementEnum.EventChannelViewType.List);
  };

  const navigateToChannel = () => {
    CommonHelper.navigateToTeamsChannel(
      channelInfoApiResponse.channelId,
      channelInfoApiResponse.channeName
    );
  };

  return (
    <div
      className="mt-3"
      id="viewChannelContainer"
      data-testid="viewChannelContainer"
    >
      <div id="actionToolbar">
        <div className={`${contentStyles.backButton}`}>
          <AmdButton
            testId="backToChannelList"
            text="Back"
            className={`${contentStyles.toolbarButton}`}
            type={AmdButtonType.DEFAULT}
            onClick={navigateBackToChannelList}
            prefixImageIconBeforeText={true}
            imageIcon={
              <AmdArrow
                direction={ArrowDirection.LEFT}
                className={contentStyles.backIcon}
              />
            }
          />
        </div>
        <div className={`amd-container-hend`}>
          {/* Edit and delete supported for adhoc channels only. */}
          {props.hasManageChannelAccess &&
            props?.channelType?.toLowerCase() ===
              EventManagementEnum.EventChannelType.Adhoc && (
              <>
                <AmdButton
                  testId="editChannel"
                  text="Edit"
                  className={`${contentStyles.toolbarButton}`}
                  type={AmdButtonType.DEFAULT}
                  onClick={onEditChannel}
                  prefixImageIconBeforeText={true}
                  imageIcon={<AmdEdit className={contentStyles.backIcon} />}
                />

                <AmdButton
                  testId="deleteChannel"
                  text="Delete"
                  className={`${contentStyles.toolbarButton}`}
                  type={AmdButtonType.DEFAULT}
                  onClick={onDeleteChannel}
                  prefixImageIconBeforeText={true}
                  imageIcon={<AmdDelete className={contentStyles.backIcon} />}
                />
              </>
            )}

          <AmdButton
            testId="goToChannel"
            text="Go to channel"
            className={`${contentStyles.toolbarButton} ${contentStyles.iconClass}`}
            type={AmdButtonType.DEFAULT}
            onClick={navigateToChannel}
            prefixImageIconBeforeText={false}
            imageIcon={
              <AmdArrow
                direction={ArrowDirection.RIGHT}
                className={contentStyles.frontIcon}
              />
            }
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="fc--gray900 mt-3 mb-1 fs-21">
          {channelInfoApiResponse?.channelName}
        </div>

        {channelInfoApiResponse?.responseTeam ? (
          <>
            <div className="fc--gray900 mt-3 mb-1 fs-18">
              Response team in this channel
            </div>
            <div className="mt-3">
              <EventResponseTeam
                eventId={props.eventId}
                templateList={[]}
                customList={channelInfoApiResponse?.responseTeam}
                isNewForm={false}
                isInEditMode={false}
                airport={props.airport}
              />
            </div>
          </>
        ) : (
          <div className="fc--gray900 mt-3 mb-1 fs-18">
            No response team for this channel
          </div>
        )}
      </div>
    </div>
  );
};

const contentStyles = mergeStyleSets({
  backIcon: {
    paddingRight: "10px",
    marginTop: "2px",
  },
  frontIcon: {
    paddingLeft: "10px",
    marginTop: "5px",
  },
  backButton: {
    float: "left",
    width: "30%",
  },
  toolbarButton: {
    width: "100px",
    height: "40px",
  },
  iconClass: {
    fontSize: 20,
    height: 20,
    width: 20,
    margin: "0 5px",
    color: "#0078d4",
  },
});
