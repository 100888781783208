import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import EventManagementEnum from "../../../../enum/EventManagementEnum";
import { CommonHelper } from "../../../../utils/common/CommonHelper";
import {
  EVENT_RESPONSETEAM_SELECTEDGROUPS,
  EVENT_RESPONSETEAM_SELECTEDUSERS
} from "../../../../utils/events/EventConstants";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { AmdTextField } from "../../../common/form-controls/controlled/TextField/AmdTextField";
import { AmdButton } from "../../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../../../common/icons/arrow/arrow";
import { ArrowDirection } from "../../../common/icons/arrow/arrowDirection";
import { EventResponseTeam } from "../../create-event/response-team/EventResponseTeam";

export interface IEventEditChannelProps {
  eventId: string;
  channelId: string;
  onChannelEdited?: any;
  onCancelEditChannel?: any;
  airport: string;
}

export const EventEditChannel: React.FunctionComponent<
  IEventEditChannelProps
> = (props: IEventEditChannelProps) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [channelInfoApiResponse, setChannelInfoApiResponse] = useState<any>();
  useEffect(() => {
    CommonHelper.getCurrentUserEmail(msalInstance).then((userEmail: string) => {
      setCurrentUserEmail(userEmail);
    });
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (props.airport !== "" && props.eventId && props.channelId) {
      EventManagementHelper.getEventChannelInfo(
        msalInstance,
        props.eventId,
        props.channelId,
        props.airport
      )
        .then((response: any) => {
          if (isMounted && response.data) {
            setChannelInfoApiResponse(response.data);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [props.airport, props.eventId, props.channelId]);

  const methods = useForm();
  let responseFormData: any = {};

  const onEditChannel = async (data: any) => {
    data[EVENT_RESPONSETEAM_SELECTEDUSERS] =
      responseFormData[EVENT_RESPONSETEAM_SELECTEDUSERS];
    data[EVENT_RESPONSETEAM_SELECTEDGROUPS] =
      responseFormData[EVENT_RESPONSETEAM_SELECTEDGROUPS];

    const editChannelPayload = EventManagementHelper.getCreateChannelPayload(
      data,
      ["response_team"]
    );
    const response = await EventManagementHelper.editChannel(
      msalInstance,
      props.eventId,
      props.channelId,
      currentUserEmail,
      editChannelPayload,
      props.airport
    );

    // Reset the form field values and close the popup.
    methods.reset(formFieldsData);

    props.onChannelEdited(response);
  };

  const onSaveEventResponseForm = (formData: any) => {
    responseFormData = formData;
    methods.handleSubmit(onEditChannel)(formFieldsData);
  };

  const onCancelEditChannel = () => {
    props.onCancelEditChannel();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onEditChannel)} noValidate>
        <div
          className="mt-3"
          id="editChannelContainer"
          data-testid="editChannelContainer"
        >
          <div className="mt-3">
            <AmdButton
              testId="editChannelCancel"
              text="Back"
              className={`${contentStyles.editChannelStyle}`}
              type={AmdButtonType.DEFAULT}
              onClick={onCancelEditChannel}
              prefixImageIconBeforeText={true}
              imageIcon={
                <AmdArrow
                  direction={ArrowDirection.LEFT}
                  className={contentStyles.backIcon}
                />
              }
            />
          </div>

          <div className="fc--gray900 mt-3 mb-1 fs-21">Edit channel</div>
          {channelInfoApiResponse?.channelName && (
            <div className="pl-0">
              <AmdTextField
                className="mb-2"
                controlId={EventManagementEnum.CreateChannel.ChannelName}
                label={"Channel name"}
                isMandatory={true}
                isMultiline={false}
                defaultValue={channelInfoApiResponse?.channelName}
              />
            </div>
          )}

          <div className="fc--gray900 mt-3 mb-1 fs-18">
            Response team in this channel
          </div>

          <div className="mt-3">
            <EventResponseTeam
              isInEditMode={true}
              templateList={[]}
              customList={channelInfoApiResponse?.responseTeam}
              onSaveForm={onSaveEventResponseForm}
              onCancelForm={onCancelEditChannel}
              airport={props.airport}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const contentStyles = mergeStyleSets({
  editChannelStyle: {
    width: "100px",
    height: "40px",
  },
  backIcon: {
    paddingRight: "10px",
    marginTop: "5px",
  },
});

const formFieldsData: any = {
  ["channelName"]: "",
};
