import { Spinner } from "@fluentui/react-components";
import { useState } from "react";
import WeatherEnum from "../../../enum/WeatherEnum";
import { useApiCall } from "../../../hooks/useApiCall";
import { IRunwayWind } from "../../../models/weather/IRunwayWind";
import {
  RUNWAY_TABS,
  RUNWAY_TABS_XYZ,
} from "../../../utils/weather/WeatherConstants";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdExpand } from "../../common/icons/expand/Expand";
import { NoDataCard } from "../../common/no-data-card/NoDataCard";
import { AmdTabs } from "../../common/tabs/tabs";
import { RunwayWindCard } from "./RunwayWindCard";

export interface IRunwayWindProps {
  airport: string;
}

const RunwayWind: React.FunctionComponent<IRunwayWindProps> = ({ airport }) => {
  let runwayState = "11R_29L";
  if (airport === "XYZ") {
    runwayState = "27L";
  }
  const [selectedRunway, setSelectedRunway] = useState<string>(runwayState);

  const baseUrl =
    process.env.REACT_APP_API_RUNWAY_WIND?.replace("{airportCode}", airport) ??
    "";
  const getRunwayWindDetailsUrl = selectedRunway
    ? `${baseUrl}/${selectedRunway}`
    : baseUrl;

  const {
    data: runwayWindDetails,
    error,
    isLoading,
  } = useApiCall<IRunwayWind>({
    url: getRunwayWindDetailsUrl,
    dependencies: [airport, selectedRunway],
  });

  const onSelectRunway = (_runway: string) => {
    setSelectedRunway(_runway);
  };
  const onClickExtendedRunway = () => {
    window.open("/weather?viewType=extended", "_blank", "noopener,noreferrer");
  };
  return (
    <div className="amd-section-shadow mt-2" data-testid="runway-wind">
      <header className="amd-section-shadow__header amd-section-shadow__header--lg amd-container-hspacebtwn">
        <span>Runway Wind</span>
        <AmdButton
          type={AmdButtonType.ICON_BUTTON}
          onClick={(_event: any) => {
            _event.preventDefault();
            onClickExtendedRunway();
          }}
          testId="extended"
        >
          <AmdExpand />
        </AmdButton>
      </header>
      <AmdTabs
        dataTestId="RunwayWindTab"
        defaultSelectedTab={runwayState}
        tabList={airport === "XYZ" ? RUNWAY_TABS_XYZ.data : RUNWAY_TABS.data}
        onTabClick={onSelectRunway}
        selectedTab={selectedRunway}
      />
      {error && <NoDataCard message="No data found" />}
      {isLoading && <Spinner labelPosition="below" label="Loading..." />}
      {!error && !isLoading && (
        <RunwayWindCard
          viewType={WeatherEnum.RunwayViewType.Runway}
          runwayWindDetails={runwayWindDetails}
        />
      )}
    </div>
  );
};

export default RunwayWind;
