import { configureStore } from "@reduxjs/toolkit";
import AirportCodeReducer from "./airport/AirportCodeSlice";
import FlightFiltersReducer from "./flights-filter/FlightFiltersSlice";
import OTPFlightFiltersReducer from "./flights-filter/OTPFlightFiltersSlice";
import OrganizationFiltersReducer from "./organization-filter/OrganizationFilterSlice";
import PaxFiltersReducer from "./pax-filter/PaxFilterSlice";
import PaxSettingReducer from "./pax-touchpoints/PaxSettingsSlice";
import ResourceAllocationFiltersReducer from "./resource-allocation-filter/ResourceAllocationFilterSlice";
import RoleFiltersReducer from "./role-filter/RoleFilterSlice";
import { weatherDisruptionReducer } from "./weather-disruption/WeatherDisruptionSlice";
import { PassengerFlowReducer } from "./pax-flow/passengerFlowSlice";

//Add the logger middleware only in development mode
const middlewares = [] as any;
// if (process.env.NODE_ENV === "development") {
//   middlewares.push(logger);
// }

const Store = configureStore({
  reducer: {
    flightScheduleFilters: FlightFiltersReducer,
    otpFlightFilters: OTPFlightFiltersReducer,
    resourceAllocationFilters: ResourceAllocationFiltersReducer,
    paxFilters: PaxFiltersReducer,
    roleFilters: RoleFiltersReducer,
    organizationFilters: OrganizationFiltersReducer,
    airportCode: AirportCodeReducer,
    paxFlow: PassengerFlowReducer,
    weatherDisruption: weatherDisruptionReducer,
    paxSettings: PaxSettingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export default Store;
