import { PublicClientApplication } from "@azure/msal-browser";

import {
  IWeatherSummaryItem,
  IWeatherSummaryTableData,
} from "../../models/weather/IWeatherSummary";
import WeatherService from "../../services/weather/weather.service";
import { APIHelper } from "../api/APIHelper";

export abstract class WeatherHelper {
  public static async getCurrentWeatherSummary(
    msalInstance: PublicClientApplication,
    airportCode: string
  ): Promise<any> {
    const weatherService = new WeatherService();
    const getCurrentWeatherSummaryMethod = (accessToken: string) => {
      return weatherService.getCurrentWeatherSummary(accessToken, airportCode);
    };

    return APIHelper.CallAPI(
      msalInstance,
      null,
      getCurrentWeatherSummaryMethod
    );
  }

  public static getSummaryTableData(
    weatherSummaryData: any,
    keys: any[]
  ): IWeatherSummaryTableData[] {
    const weatherSummaryTableData: IWeatherSummaryTableData[] = [];
    keys.forEach((key: any) => {
      const _data: any = weatherSummaryData[key.name];
      if (_data) {
        const weatherSummaryTableDataItem: IWeatherSummaryTableData = {
          name: key.displayName,
          value: `${_data?.body} ${_data?.unit ? _data?.unit : ""}`,
          image: key.image,
        };
        weatherSummaryTableData.push(weatherSummaryTableDataItem);
      }
    });
    return weatherSummaryTableData;
  }

  public static getWeatherCardArray(
    weatherSummaryData: any,
    keys: any[]
  ): Array<Array<IWeatherSummaryItem>> {
    const rowCount: number = keys.length / 4;
    const weatherCardArray = new Array<Array<IWeatherSummaryItem>>();
    let index = 0;
    for (let i = 0; i < rowCount; i++) {
      const _tempWeatherSummaryArray: Array<IWeatherSummaryItem> =
        new Array<IWeatherSummaryItem>();
      for (let j = 0; j < 4; j++) {
        const weatherSummaryItem: IWeatherSummaryItem =
          weatherSummaryData[keys[index].name];
        weatherSummaryItem.displayName = keys[index].displayName;
        _tempWeatherSummaryArray.push(weatherSummaryItem);
        index++;
      }
      weatherCardArray.push(_tempWeatherSummaryArray);
    }
    return weatherCardArray;
  }
  public static async getRunwayWindDetails(
    msalInstance: PublicClientApplication,
    airportCode: string,
    runway?: string
  ): Promise<any> {
    const weatherService = new WeatherService();
    const getRunwayWindDetailsMethod = (accessToken: string) => {
      return weatherService.getRunwayWindDetails(
        accessToken,
        airportCode,
        runway
      );
    };

    return APIHelper.CallAPI(msalInstance, null, getRunwayWindDetailsMethod);
  }
  public static setWindDirection(degrees: any, context: any, img: any) {
    //calculate radians from degree

    const ctx = context;
    const radians = 0.0174533 * (degrees - 90);

    // calculate compass centre
    const x = ctx.canvas.width / 2;
    const y = ctx.canvas.height / 2;
    const r = x * 0.8;

    //clear
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();

    // Draw the compass image onto the canvas
    ctx.drawImage(img, 0, 0);

    // Save the current drawing state
    ctx.save();
    ctx.fillStyle = "rgba(255,0,0,0.5)";
    ctx.lineCap = "round";

    // set compass needle direction
    ctx.lineWidth = 2;
    this.setNeedleDirection(
      ctx,
      { x: x, y: y },
      { x: x + r * Math.cos(radians), y: y + r * Math.sin(radians) },
      { x: x - r * Math.cos(radians), y: y - r * Math.sin(radians) },
      10
    );
  }
  public static setNeedleDirection(
    ctx: any,
    p1: any,
    p2: any,
    p3: any,
    arrowHead: number
  ) {
    //draw arrow lines
    ctx.beginPath();
    ctx.moveTo(p1.x, p1.y); //move to center
    ctx.lineTo(p2.x, p2.y); //draw forward line from center
    ctx.lineTo(p3.x, p3.y); //draw backward line from center

    //draw arrow head
    const lineAngle = Math.atan2(p1.y - p2.y, p1.x - p2.x);
    let delta = Math.PI / 6;
    for (let i = 0; i < 2; i++) {
      ctx.moveTo(p2.x, p2.y);
      const x = p2.x + arrowHead * Math.cos(lineAngle + delta);
      const y = p2.y + arrowHead * Math.sin(lineAngle + delta);
      ctx.lineTo(x, y);
      delta *= -1;
    }
    //draw arrow end
    ctx.moveTo(p3.x, p3.y);
    ctx.arc(p3.x, p3.y, 2, 0, 2 * Math.PI);
    ctx.stroke();
  }
}
