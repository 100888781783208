import React, { useEffect, useRef } from "react";
import { WeatherHelper } from "../../../utils/weather/WeatherHelper";
import compassBig from "../images/compass_big.svg";
import compassSmall from "../images/compass_small.svg";

export interface IDirectionCompassProps {
  degree: number;
  canvasId?: string;
  size?: "small" | "big";
}
export const DirectionCompass: React.FC<IDirectionCompassProps> = ({
  degree,
  canvasId,
  size = "big",
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasCtxRef = React.useRef<CanvasRenderingContext2D | null>(null);
  let ctx: any;
  let img: any;
  useEffect(() => {
    // Initialize
    if (canvasRef.current) {
      canvasCtxRef.current = canvasRef.current.getContext("2d");
      ctx = canvasCtxRef.current;

      // Load the compass image
      img = new Image();
      if (size === "big") {
        img.src = compassBig;
      } else {
        img.src = compassSmall;
      }
      img.onload = onLoadImage;
    }
  }, [degree]);
  const onLoadImage = () => {
    setInterval(drawCompass, 100);
  };
  const drawCompass = () => {
    WeatherHelper.setWindDirection(degree, ctx, img);
  };

  return (
    <div className="pt-2" data-testid="direction-compass">
      <canvas
        id={canvasId}
        ref={canvasRef}
        height={size === "big" ? "266" : "200"}
        width={size === "big" ? "266" : "200"}
      ></canvas>
    </div>
  );
};
