/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import IFilters from "../../models/filters/IFilters";

const initialState = {
  roleFilters: [] as IFilters[],
};

const roleFilterSlice = createSlice({
  name: "roleFilters",
  initialState,
  reducers: {
    setRoleFilters(state, action) {
      localStorage.setItem("roleFilters", JSON.stringify(action.payload));
      state.roleFilters = action.payload;
    },
  },
});

export const roleFilterActions = roleFilterSlice.actions;

export default roleFilterSlice.reducer;
