import React from "react";
import { IThreshold } from "../../../models/app-settings/ITouchPoint";
import { HOP_RANGE_COLORS } from "../../../utils/common/Constants";
import { MultiProgressBar } from "../../common/multi-progress/MultiProgressBar";
import { PaxThresholdBarDetails } from "./PaxThresholdBarDetails";

export const PaxThresholdBar: React.FunctionComponent<IThresholdBarProps> = (
  props: IThresholdBarProps
) => {
  const { amberToRed } = props.threshold;
  const { greenToAmber } = props.threshold;

  //Calculate Threshold bar START and END color as per values based on Poor and Good
  const thresholdBarValues =
    amberToRed < greenToAmber
      ? [
          { colorFormat: "poor", value: amberToRed },
          { colorFormat: "good", value: greenToAmber },
        ]
      : [
          { colorFormat: "good", value: greenToAmber },
          { colorFormat: "poor", value: amberToRed },
        ];

  return (
    <div className={props.className} data-testid="pax-threshold-bar">
      <div className="pr-sm-1">
        <div className="pr-sm-1">
          <MultiProgressBar
            poor={HOP_RANGE_COLORS.poor}
            moderate={HOP_RANGE_COLORS.moderate}
            good={HOP_RANGE_COLORS.good}
            barDivisionValue1={thresholdBarValues[0].value}
            barDivisionValue2={thresholdBarValues[1].value}
            unit={props.unit}
            format={[
              thresholdBarValues[0].colorFormat,
              thresholdBarValues[1].colorFormat,
            ]}
          />
        </div>

        <PaxThresholdBarDetails
          unit={props.unit}
          thresholdBarValues={thresholdBarValues}
        />
      </div>
    </div>
  );
};

export interface IThresholdBarProps {
  threshold: IThreshold;
  unit: string;
  className?: string;
}
