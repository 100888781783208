import { makeStyles } from "@fluentui/react-components";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { IAirportMap } from "../../models/airports/AirportConfig";
import AirportMarkers from "./AirportMarkers";

export interface IMapDisplayAirports {
  airportMapDetails: IAirportMap[];
}

const MapDisplayAirports: React.FC<IMapDisplayAirports> = ({
  airportMapDetails = [],
}) => {
  const classes = useStyles();

  const airportMarkersContent = airportMapDetails.map((airport) => (
    <AirportMarkers
      key={airport.id}
      markerName={airport.name}
      markerPosition={airport.location}
    />
  ));

  return (
    <div className={classes.mapContainer} data-testid="multi-airport-map">
      <MapContainer
        center={[48.9463, 10.3554]}
        zoom={7}
        style={{ height: "100%", width: "100%", borderRadius: "8px" }}
        zoomControl={false}
        minZoom={2}
      >
        <ZoomControl position="bottomright" />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {airportMarkersContent}
      </MapContainer>
    </div>
  );
};

export default MapDisplayAirports;

const useStyles = makeStyles({
  mapContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    zIndex: 0,
  },
});
