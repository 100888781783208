import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const CalendarExclamationIcon: React.FunctionComponent<
  IAmdIconProps
> = ({ color = "#DF3127", ...props }) => {
  return (
    <span className={props.className}>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="calendar-exl-icon"
      >
        <path
          d="M6.20837 6.65625C6.20837 6.4375 6.36462 6.25 6.58337 6.25H8.05212C8.27087 6.25 8.42712 6.4375 8.42712 6.65625L8.20837 9.90625C8.20837 10.125 8.05212 10.25 7.83337 10.25H6.80212C6.58337 10.25 6.42712 10.0938 6.42712 9.90625L6.20837 6.65625ZM8.58337 12C8.58337 12.7188 8.02087 13.25 7.33337 13.25C6.61462 13.25 6.08337 12.7188 6.08337 12C6.08337 11.3125 6.61462 10.75 7.33337 10.75C8.02087 10.75 8.58337 11.3125 8.58337 12ZM12.8334 2C13.6459 2 14.3334 2.6875 14.3334 3.5V14.5C14.3334 15.3438 13.6459 16 12.8334 16H1.83337C0.989624 16 0.333374 15.3438 0.333374 14.5V3.5C0.333374 2.6875 0.989624 2 1.83337 2H3.33337V0.375C3.33337 0.1875 3.48962 0 3.70837 0H4.95837C5.14587 0 5.33337 0.1875 5.33337 0.375V2H9.33337V0.375C9.33337 0.1875 9.48962 0 9.70837 0H10.9584C11.1459 0 11.3334 0.1875 11.3334 0.375V2H12.8334ZM12.6459 14.5C12.7396 14.5 12.8334 14.4375 12.8334 14.3125V5H1.83337V14.3125C1.83337 14.4375 1.89587 14.5 2.02087 14.5H12.6459Z"
          fill={color}
        />
      </svg>
    </span>
  );
};
