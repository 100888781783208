import { mergeStyleSets } from "@fluentui/react";
import {
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
} from "@fluentui/react-tabs";
import * as React from "react";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { HOPAlertIcon } from "../../health-of-operations/HOPAlertIcon";
export interface ITab {
  text: string;
  name?: string;
  value: string;
  image?: string;
  status?: string;
  percent?: string;
  disabled?: boolean;
}
export interface IAmdTabsProps {
  dataTestId?: string;
  defaultSelectedTab: string;
  selectedTab: string;
  tabList: ITab[];
  onTabClick: any;
  size?: "large" | "medium" | "small";
  position?: "right" | "left";
  styleType?: "primary" | "secondary";
}

export const AmdTabs: React.FunctionComponent<IAmdTabsProps> = React.memo(
  ({
    size = "large",
    dataTestId = "",
    tabList = [],
    defaultSelectedTab = "",
    position = "left",
    ...props
  }) => {
    return (
      <div
        data-testid={dataTestId}
        className={
          position === "right"
            ? `amd-container-hend ${CommonHelper.getTabWrapperClass(
                props.styleType
              )}`
            : CommonHelper.getTabWrapperClass(props.styleType)
        }
      >
        <TabList
          selectedValue={props?.selectedTab ?? defaultSelectedTab}
          onTabSelect={(_event: SelectTabEvent, data: SelectTabData) => {
            props.onTabClick(data.value);
          }}
          size={size}
          className={`${dataTestId}tab`}
        >
          {tabList.map((tab: ITab) => {
            return tab.image ? (
              <Tab
                key={CommonHelper.getGuid()}
                value={tab.value}
                className={`${tab.value}button ${tabStyles.tab}`}
                disabled={tab.disabled}
              >
                <img className={tabStyles.tabImage} src={tab.image} />
                <span>{tab.text}</span>
              </Tab>
            ) : tab.status ? (
              <Tab
                key={CommonHelper.getGuid()}
                value={tab.value}
                className={`${tab.value}button ${tabStyles.tab}`}
                disabled={tab.disabled}
              >
                <div className="jc-spacebtwn amd-container-vcenter gap-5">
                  <span>{tab.text}</span>
                  <span>
                    <HOPAlertIcon status={tab.status} />
                  </span>
                  <span>{tab.percent}</span>
                </div>
              </Tab>
            ) : (
              <Tab
                key={CommonHelper.getGuid()}
                value={tab.value}
                className={`${tab.value}button ${
                  props.styleType === "secondary"
                    ? tabStyles.tabSecondary
                    : tabStyles.tab
                }`}
                disabled={tab.disabled}
              >
                <span>{tab.text}</span>
              </Tab>
            );
          })}
        </TabList>
      </div>
    );
  }
);

const tabStyles = mergeStyleSets({
  tab: {
    height: 34,
    selectors: {
      "&:first-of-type": {
        paddingLeft: 0,
      },
      "&:first-of-type::after": {
        left: 0,
      },
    },
  },
  tabSecondary: {
    fontSize: 13,
    height: 16,
    selectors: {
      "&::after": {
        content: "none !important",
      },
      "& .fui-Tab__content": {
        fontWeight: 400,
        color: "#999999 !important",
      },
      '[aria-selected="true"] .fui-Tab__content': {
        color: "#333333 !important",
        fontWeight: 600,
      },
    },
  },
  tabImage: {
    width: "16px",
    height: "14px",
    float: "left",
    margin: 0,
    display: "inline-block",
    marginTop: 5,
    marginRight: 5,
  },
});
