export function sortColumnByAlphaNumeric<T>(
  items: T[],
  key: string,
  isSortedDescending: boolean
) {
  if (!items || !items?.length) {
    return [];
  }

  const regEx = /\d+/;
  const columnKey = key as keyof T;
  return items?.slice(0).sort((a: T, b: T) => {
    const aKey: string = a[columnKey] as string;
    const bKey: string = b[columnKey] as string;
    const aMatch: RegExpMatchArray | null = aKey.match(regEx) ?? [aKey];
    const bMatch: RegExpMatchArray | null = bKey.match(regEx) ?? [bKey];
    const castAtoNum = parseInt(aMatch[0]);
    const castBtoNum = parseInt(bMatch[0]);
    return (
      isSortedDescending ? castAtoNum < castBtoNum : castAtoNum > castBtoNum
    )
      ? 1
      : -1;
  });
}
