import { createSlice } from "@reduxjs/toolkit";
import DateHelper from "../../utils/common/DateHelper";
import { PassengerHelper } from "../../utils/pax/PassengerHelper";
import { PAX_TOUCHPOINTS_REFRESH_INTERVAL } from "../../utils/pax/PAXConstants";
import {
  TouchPointChartDataArray,
  TouchPointChartLegendArray,
  TouchPointTableColumnsArray,
  TouchPointTableDataArray,
} from "./contracts/contracts";

type PaXFlowInitialState = {
  width: number;
  height: number;
  allowMultipleShapes: boolean;
  selectedTouchPoint: string;
  selectedTouchPointFilter: string;
  selectedTouchPointFilterLabel: string;
  selectedTouchPointFilterType: string;
  selectedStartTime: string;
  selectedEndTime: string;
  selectedRefreshInterval: string;
  touchPointChartDataArray: TouchPointChartDataArray[];
  prevTouchPointChartDataArray: TouchPointChartDataArray[];
  touchPointTableDataArray: TouchPointTableDataArray[];
  touchPointTableColumnsArray: TouchPointTableColumnsArray[][];
  touchPointChartLegendArray: Array<TouchPointChartLegendArray[]>;
  showTableRows: boolean;
};

export interface PaxFlowState {
  paxFlow: PaXFlowInitialState;
}

export const PAX_FLOW_INITIAL_STATE: PaxFlowState = {
  paxFlow: {
    width: 1200,
    height: 330,
    allowMultipleShapes: false,
    selectedTouchPoint: "",
    selectedTouchPointFilter: "",
    selectedTouchPointFilterLabel: "",
    selectedTouchPointFilterType: "",
    selectedStartTime:
      DateHelper.getFormattedDateTime(new Date(), "HH:mm") ?? "",
    selectedEndTime:
      PassengerHelper.getUpdatedPAXFlowTimeBasedOnInterval(
        DateHelper.getFormattedDateTime(new Date(), "HH:mm"),
        PAX_TOUCHPOINTS_REFRESH_INTERVAL[0]?.value,
        true
      ) ?? "",
    selectedRefreshInterval: PAX_TOUCHPOINTS_REFRESH_INTERVAL[0]?.value ?? "",
    touchPointChartDataArray: [],
    prevTouchPointChartDataArray: [],
    touchPointTableDataArray: [],
    touchPointTableColumnsArray: [],
    touchPointChartLegendArray: [],
    showTableRows: false,
  },
};
export const passengerFlowSlice = createSlice({
  name: "paxFlow",
  initialState: PAX_FLOW_INITIAL_STATE,
  reducers: {
    setPaxGraphAndTableData: (state, action : { payload: {
      touchPointChartDataArray: TouchPointChartDataArray[],
      touchPointChartLegendArray: TouchPointChartLegendArray[][],
      touchPointTableDataArray: TouchPointTableDataArray[],
      touchPointTableColumnsArray: TouchPointTableColumnsArray[][],
      showTableRows: boolean,
    }}) => {
      const { payload } = action;

      const {
        touchPointChartDataArray,
        touchPointChartLegendArray,
        touchPointTableDataArray,
        touchPointTableColumnsArray,
        showTableRows,
      } = payload;

      state.paxFlow.touchPointChartDataArray = touchPointChartDataArray;
      state.paxFlow.touchPointChartLegendArray = touchPointChartLegendArray;
      state.paxFlow.touchPointTableDataArray = touchPointTableDataArray;
      state.paxFlow.touchPointTableColumnsArray = touchPointTableColumnsArray;
      state.paxFlow.touchPointTableColumnsArray = touchPointTableColumnsArray;
      state.paxFlow.showTableRows = showTableRows;
    },
    setOnRefreshIntervalChange: (state, action) => {
      state.paxFlow = action.payload;
    },
    setOnTouchPointFilterClose: (state, action) => {
      state.paxFlow = action.payload;
    },
    setOnTouchPointFilterClick: (state, action) => {
      state.paxFlow = action.payload;
    },
    setOnTouchPointTimeRange: (state, action) => {
      state.paxFlow = action.payload;
    },
  },
});

export const {
  setPaxGraphAndTableData,
  setOnRefreshIntervalChange,
  setOnTouchPointFilterClose,
  setOnTouchPointFilterClick,
  setOnTouchPointTimeRange,
} = passengerFlowSlice.actions;

export const PassengerFlowReducer = passengerFlowSlice.reducer;
