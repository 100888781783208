import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../StoreHooks";

// Original selector
const selectResourceAllocationFilters = (state: RootState) =>
  state.resourceAllocationFilters?.resourceAllocationFilters || [];

// Memoized selector
export const getMemoizedResourceAllocationFilters = createSelector(
  [selectResourceAllocationFilters],
  (resourceAllocationFilters) => {
    // Perform a transformation to ensure memoization
    return [...resourceAllocationFilters];
  }
);
