import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import USER_FILTERS from "../../../data/filter-panel/user-filters.json";
import IFilters from "../../../models/filters/IFilters";
import { USER_COLUMNS } from "../../../utils/admin/AdminConstants";
import { AdminHelper } from "../../../utils/admin/AdminHelper";
import { AmdDataGrid } from "../../common/data-grid/AmdDataGrid";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdTextField } from "../../common/form-controls/uncontrolled/TextField/AmdTextField";
import { AmdUser } from "../../common/icons/user/User";
import { FilterPanel } from "../../filters/filter-panel/FilterPanel";
import { AddUserPopup } from "./AddUserPopup";

const userColumns = USER_COLUMNS;

export interface IUsersProps {
  airport: string;
}

export const Users: React.FunctionComponent<IUsersProps> = ({ airport }) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [userList, setUserList] = useState<any>([]);
  const [filteredUserList, setFilteredUserList] = useState<any>([]);
  const rowItems = AdminHelper.getUserItems(filteredUserList);

  const [userFilters, setUserFilters] = useState(
    USER_FILTERS.data as IFilters[]
  );

  useEffect(() => {
    let isMounted = true;

    async function fetchUsers() {
      const users = await AdminHelper.getUsers(msalInstance);
      if (isMounted) {
        setUserList(users.data);
        setFilteredUserList(users.data);
      }
    }
    fetchUsers();
    return () => {
      isMounted = false;
    };
  }, []);

  const onSearchTextChange = (e: any) => {
    const searchText = e.target.value;

    if (searchText === "") {
      setFilteredUserList(userList);
      return;
    }
    const filteredItems = userList.filter((item: any) => {
      return (
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.email.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredUserList(filteredItems);
  };

  const onUpdateFilter = (updatedItems: any) => {
    setUserFilters(updatedItems);
  };

  return (
    <div data-testid="users">
      <div className="amd-container-hspacebtwn amd-container-vcenter mb-2">
        <div className="d-flex ai-center">
          <div className="ff-amd fs-36 fw-400">Users</div>
          <AmdTextField
            id="searchUsers"
            label=""
            isMandatory={false}
            isMultiline={false}
            placeholder="Search by name or email"
            className={`ml-2 ${contentStyles.searchBox}`}
            onChange={onSearchTextChange}
          />
        </div>

        <AmdButton
          testId="addUser"
          text="Add user"
          onClick={showModal}
          type={AmdButtonType.DEFAULT}
          prefixImageIconBeforeText={true}
          imageIcon={<AmdUser className="mt-1 mr-1" />}
        />
      </div>

      <FilterPanel
        filters={userFilters}
        onFiltersApplied={() => {
          console.log("onFiltersApplied");
        }}
        onUpdateFilter={onUpdateFilter}
        airport={airport}
      />

      <div className="mt-2">
        {userList && userList.length > 0 && (
          <AmdDataGrid
            showActionButtons={true}
            rowItems={rowItems}
            columns={userColumns}
            infoHeaders={["Name"]}
          />
        )}

        <AddUserPopup
          hideDialog={!isModalOpen}
          onModalPopupClose={hideModal}
          airport={airport}
        ></AddUserPopup>
      </div>
    </div>
  );
};

const contentStyles = mergeStyleSets({
  searchBox: {
    width: "300px",
  },
});
