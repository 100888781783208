import { makeStyles, ProgressBar } from "@fluentui/react-components";
import { $goodStatus } from "../../../cssjs/colors";

export const AmdProgressIndicatorV2: React.FunctionComponent<
  IAmdProgressIndicator
> = (props: IAmdProgressIndicator) => {
  const classes = useStyles();

  return (
    <ProgressBar
      className={`${classes.container}`}
      value={props.value}
      color={props.color}
      shape={props.shape}
      data-testid="progress-bar"
    />
  );
};
const useStyles = makeStyles({
  container: {
    maxWidth: "292px",
    height: "10px !important",
    "& .fui-ProgressBar__bar": {
      backgroundColor: $goodStatus,
    },
  }
});

export interface IAmdProgressIndicator {
  value: number;
  color?: "success" | "warning" | "error";
  size?: "medium" | "large";
  shape?: "rounded" | "square";
}
