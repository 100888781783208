import { IWeatherSummaryItem } from "../../../models/weather/IWeatherSummary";
import { AmdCloudCover } from "../../common/icons/cloud-cover/CloudCover";
import { AmdCloudRain } from "../../common/icons/cloud-rain/CloudRain";
import { AmdDew } from "../../common/icons/dew/Dew";
import { AmdEye } from "../../common/icons/eye/Eye";
import { AmdHumidity } from "../../common/icons/humidity/Humidity";
import { AmdPressure } from "../../common/icons/pressure/Pressure";
import { AmdTemperature } from "../../common/icons/temperature/Temperature";
import { AmdWindy } from "../../common/icons/windy/Windy";

export const WeatherKPICard: React.FunctionComponent<IWeatherSummaryItem> = (
  props: IWeatherSummaryItem
) => {
  return (
    <>
      <div className="w-100 amd-container-center">
        {props.displayName === "Visibility Range" && (
          <AmdEye width="40" height="35" color="#1890F140" />
        )}
        {props.displayName === "Clouds" && (
          <AmdCloudCover width="40" height="30" color="#1890F140" />
        )}
        {props.displayName === "Wind" && (
          <AmdWindy width="40" height="34" color="#1890F140" />
        )}
        {props.displayName === "Humidity" && (
          <AmdHumidity width="29" height="40" color="#1890F140" />
        )}
        {props.displayName === "Temperature" && (
          <AmdTemperature color="#1890F140" />
        )}
        {props.displayName === "Rainfall" && (
          <AmdCloudRain height="35" color="#1890F140" />
        )}
        {props.displayName === "Dew Point" && (
          <AmdDew width="35" height="35" color="#1890F140" />
        )}
        {props.displayName === "Pressure" && (
          <AmdPressure width="40" height="40" color="#1890F140" />
        )}
        <span className="pl-2 fs-28 fw-600 lh-32">{props.body}</span>
        <span className="fs-13 fw-600 ml-sm-1 mt-2">{props.unit}</span>
      </div>
      <div className="w-100 text-c mt-sm-1">{props.subBody}</div>
    </>
  );
};
