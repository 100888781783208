import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../StoreHooks";

// Original selector
const selectFlightScheduleFilters = (state: RootState) => state.flightScheduleFilters?.flightScheduleFilters || [];

// Memoized selector
export const getMemoizedFlightScheduleFilters = createSelector(
  [selectFlightScheduleFilters],
  (flightScheduleFilters) => {
    // Perform a transformation to ensure memoization
    return [...flightScheduleFilters];
  }
);