import { mergeStyleSets } from "@fluentui/react";
import { IAmdDataGridProps } from "./types";
const tableHeaderBorderBottom = "1px solid rgb(237, 235, 233)";
export function paxListStyles(
  props: Readonly<IAmdDataGridProps> & Readonly<{ children?: React.ReactNode }>
) {
  const { showTableRows = false } = props;

  const selectedChevron = showTableRows
    ? `url("images/chevrons/AmdArrowOpen.svg")`
    : `url("images/chevrons/AmdArrowClosed.svg")`;
  const iconContent = selectedChevron;
  const iconPaddingTop = "3px";
  const iconPaddingRight = "10px";
  const borderStyling = "none";
  const activeBorder = "1px solid #005EB8";
  const paxBorder = "1px solid #E3E3E3";
  const paxBorderLeft = borderStyling;
  const paxBorderRight = borderStyling;
  const paxBorderBottom = borderStyling;
  const paxInlineBlock = "none";
  const paxMinWidth = "78px";
  const paxZeroPixels = "0px";
  const borderRight = "1px solid #E3E3E3";
  return mergeStyleSets({
    root: {
      float: "left",
      overflowX: "hidden",
      justifyContent: "center",
      selectors: {
        "& [role=grid]": {
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          minHeight: "2vh",
          border: paxBorder,
          borderLeft: paxBorderLeft,
          borderRight: paxBorderRight,
          borderBottom: paxBorderBottom,
          width: "100%",
          marginBottom: "20px",
        },
      },
    },
    headerWrapper: {
      flex: "0 0 auto",
      selectors: {
        "& .ms-DetailsHeader": {
          paddingTop: 0,
          borderBottom: tableHeaderBorderBottom,
          ".ms-Icon": {
            display: paxInlineBlock,
          },
        },
        ".ms-DetailsHeader-cellName": {
          minWidth: paxMinWidth,
        },
        "& .ms-DetailsHeader-cellname": {
          paddingTop: 0,
          borderBottom: tableHeaderBorderBottom,
          minWidth: paxMinWidth,
          display: "block",
          ".ms-Icon": {
            display: paxInlineBlock,
          },
        },
        ".ms-DetailsHeader-cell:nth-child(13), .ms-DetailsHeader-cell:nth-child(15)":
          {
            borderRight: activeBorder,
          },
        ".ms-DetailsHeader-cell:nth-child(1) .ms-DetailsHeader-cellTitle": {
          ":before": {
            paddingTop: iconPaddingTop,
            paddingRight: iconPaddingRight,
            content: iconContent,
          },
        },
        ".ms-DetailsHeader-cell .ms-DetailsHeader-cellTitle": {
          justifyContent: "center",
          width: "100%",
        },
        ".ms-DetailsHeader-cell:nth-child(1)": {
          ".ms-DetailsHeader-cellTitle": {
            justifyContent: "left",
          },
        },
        ".ms-DetailsHeader-cell:nth-child(3)": {
          borderRight: borderRight,
          visibility: "hidden",
        },
      },
    },
    contentWrapper: {
      width: "100%",
      flex: "1 1 auto",
      overflowY: "auto",
      overflowX: "hidden",
      ".ms-DetailsRow-cellCheck": {
        display: "none",
      },
      display: `${!showTableRows ? "none" : "block"} `,
      ".is-row-header:nth-child(2)": {
        color: "#808080",
        paddingLeft: paxZeroPixels,
        borderRight: borderRight,
      },
      ".is-row-header:nth-child(7)": {
        borderRight: activeBorder,
      },
      ".is-row-header:nth-child(8)": {
        borderRight: activeBorder,
      },
      ".is-row-header:nth-child(1)": {
        paddingLeft: "24px",
        paddingRight: paxZeroPixels,
      },
    },
  });
}
