import { mergeStyleSets } from "@fluentui/react";
export function customListStyles() {
  return mergeStyleSets({
    root: {
      float: "left",
      justifyContent: "center",
      width: "100%",
      selectors: {
        "& [role=grid]": {
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          minHeight: "2vh",
          border: `2px solid #F2F2F2`,
          marginBottom: "20px",
          width: "100%",
        },
      },
    },
    headerWrapper: {
      flex: "0 0 auto",
      width: "100%",
      selectors: {
        "& .ms-DetailsHeader": {
          paddingTop: 0,
          borderBottom: "1px solid rgb(237, 235, 233)",
        },
      },
    },
    contentWrapper: {
      flex: "1 1 auto",
      width: "100%",
      ".ms-DetailsRow-cellCheck": {
        display: "none",
      },
    },
  });
}
