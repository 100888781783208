import { mergeStyleSets } from "@fluentui/react";
import moment from "moment-timezone";
import NotificationEnum from "../../enum/NotificationEnum";
import NOTIFICATION_HOME_SUMMARY_MOCK from "../../mock/notification/home-summary.json";
import { AmdFlightNotification } from "../common/icons/notification/Flight";
import { NoDataCard } from "../common/no-data-card/NoDataCard";

export const NotificationMacroCard: React.FunctionComponent = () => {
  return (
    <div data-testid="notification-macro-card">
      {NOTIFICATION_HOME_SUMMARY_MOCK.length === 0 ? (
        <NoDataCard message="No notifications to display" />
      ) : (
        NOTIFICATION_HOME_SUMMARY_MOCK.map((_val) => (
          <section key={_val?.id} className="mt-4 amd-container-hspacebtwn">
            <div
              className={`d-flex amd-container-vcenter w-100 ${contentStyles.bottomStyles}`}
            >
              {_val.type === NotificationEnum.NotificationType.Flight && (
                <div className={contentStyles.notificationIcon}>
                  <AmdFlightNotification />
                </div>
              )}

              <div className={contentStyles.container}>
                <span className="fw-600 fc--gray900">{_val?.title}</span>
                <span className="fw-400 fc--gray600">{_val?.description}</span>
              </div>
              <div className={contentStyles.timeContent}>
                {moment(_val?.time).format("hh:mm")}
              </div>
            </div>
          </section>
        ))
      )}
    </div>
  );
};

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "80%",
    height: 50,
  },
  timeContent: {
    width: "12%",
    textAlign: "right",
  },
  bottomStyles: {
    paddingBottom: "3rem",
    borderBottom: "1px solid #cccccc",
  },
  notificationIcon: {
    width: 50,
    marginRight: 20,
  },
});
