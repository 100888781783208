import { Separator } from "@fluentui/react";
import React from "react";
import IHOPLegendItem from "../../models/health-of-operations/IHOPLegendItem";
import {
  IHOPKpi,
  IHOPSummaryItem,
} from "../../models/health-of-operations/IHOPSummary";
import { CommonHelper } from "../../utils/common/CommonHelper";
import { HOPHelper } from "../../utils/health-of-operations/HOPHelper";
import { HOPAlertIcon } from "./HOPAlertIcon";

export const HOPLegendContent: React.FunctionComponent<
  IHOPLegendContentProps
> = (props: IHOPLegendContentProps) => {
  return (
    <>
      <span className="fw-600 mb-2" data-testid={props.legend.name}>
        {props.legend.name}
      </span>
      {props.legend.details?.map(
        (hopSummary: IHOPSummaryItem, index: number) => {
          return (
            <React.Fragment key={CommonHelper.getGuid()}>
              {hopSummary.kpis?.map((item: IHOPKpi) => {
                return (
                  <div
                    key={`${props.legend.name}-${item.key}`}
                    className="mb-1 jc-spacebtwn amd-container-vcenter gap-5 hop-legend-content"
                  >
                    <span
                      className={`fs-12 ${HOPHelper.getLegendClass(item.key)}`}
                    >
                      {HOPHelper.getKPIName(
                        item.key,
                        HOPHelper.getKPIKey(index),
                        props.selectedTime,
                        props.airport
                      )}
                    </span>
                    <div className="amd-container-vcenter">
                      <HOPAlertIcon status={item.status} />
                      <div
                        className={`py-sm-1 px-sm-3 ml-1 ${HOPHelper.getLegendBgColor(
                          item.status
                        )} mr-sm-1`}
                      >
                        <span
                          className={`${HOPHelper.getLegendTextColor(
                            item.status
                          )} fw-700`}
                        >
                          {item.performance}%
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              <Separator />
            </React.Fragment>
          );
        }
      )}
    </>
  );
};
export interface IHOPLegendContentProps {
  legend: IHOPLegendItem;
  selectedTime: any;
  airport: string;
}
