import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";
import { ChevronDirection } from "./chevronDirection";

export const AmdChevron: React.FunctionComponent<IAmdIconProps> = ({
  direction = ChevronDirection.RIGHT,
  color = "black",
  className = "",
  width = "5",
  height = "9",
  viewBox = "0 0 5 9",
}) => {
  let arrowClass = { root: "" };
  if (direction === ChevronDirection.LEFT) {
    arrowClass = mergeStyleSets({
      root: {
        transform: "rotate(180deg)",
      },
    });
  } else if (direction === ChevronDirection.DOWN) {
    arrowClass = mergeStyleSets({
      root: {
        transform: "rotate(90deg)",
      },
    });
  } else if (direction === ChevronDirection.UP) {
    arrowClass = mergeStyleSets({
      root: {
        transform: "rotate(270deg)",
      },
    });
  }

  return (
    <svg
      className={`${arrowClass.root} ${className}`}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.646447 0.479943C0.451184 0.675205 0.451184 0.991787 0.646447 1.18705L3.79289 4.3335L0.646447 7.47994C0.451185 7.6752 0.451185 7.99179 0.646447 8.18705C0.841709 8.38231 1.15829 8.38231 1.35355 8.18705L4.85355 4.68705C5.04882 4.49179 5.04882 4.1752 4.85355 3.97994L1.35355 0.479943C1.15829 0.284681 0.841709 0.284681 0.646447 0.479943Z"
        fill={color}
      />
    </svg>
  );
};