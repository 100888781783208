import { Tooltip } from "@fluentui/react-components";
import React from "react";
import IHOPLegendItem from "../../models/health-of-operations/IHOPLegendItem";
import { CommonHelper } from "../../utils/common/CommonHelper";
import { HOPHelper } from "../../utils/health-of-operations/HOPHelper";
import { AmdButton } from "../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../common/icons/arrow/arrow";
import { ArrowDirection } from "../common/icons/arrow/arrowDirection";
import { HOPAlertIcon } from "./HOPAlertIcon";
import { HOPLegendContent } from "./HOPLegendContent";

export const HOPLegend: React.FunctionComponent<IHOPLegend> = (
  props: IHOPLegend
) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = React.useState<number>();

  const getLegendContent = (legend: IHOPLegendItem) => {
    return (
      <HOPLegendContent
        legend={legend}
        selectedTime={props.selectedTime}
        airport={props.airport}
      />
    );
  };

  const container = document.getElementsByClassName("amd-maincontainer")[0];
  if (container) {
    container.addEventListener("scroll", () => {
      setVisible(false);
    });
  }

  return (
    <div className="mt-10 hop-legend" data-testid="hop-legend">
      {props.legends?.map((legend: IHOPLegendItem, i: number) => {
        return (
          <Tooltip
            key={legend.name}
            withArrow
            positioning="before"
            content={getLegendContent(legend)}
            relationship="label"
            visible={HOPHelper.isVisible(visible, i, currentIndex)}
          >
            <div
              className=" cursor-pointer mb-2 amd-container-vcenter hop-legend-item jc-spacebtwn w-100"
              tabIndex={i}
              onClick={() => {
                setVisible(true);
                setCurrentIndex(i);
              }}
              onBlur={() => {
                setVisible(false);
              }}
              data-testid={`${legend.name}-item`}
            >
              <div
                className="amd-container-vcenter hop-kpi-detail"
                style={{ width: "95%" }}
              >
                <div
                  className={`py-sm-2 px-sm-3 ${legend.backgroundColor} mr-sm-1`}
                >
                  <span className={`${legend.textColor} fw-700`}>
                    {legend.value}%
                  </span>
                </div>
                <HOPAlertIcon status={legend.status} />
                <span className="ml-sm-1 mr-sm-1">{legend.name}</span>
              </div>
              <div>
                <AmdButton
                  type={AmdButtonType.ICON_BUTTON}
                  onClick={() => {
                    window.sessionStorage.setItem(
                      "selectedDimension",
                      legend.dimensionKey
                    );
                    CommonHelper.navigateToComponent(
                      `/${legend.redirectToPage}`,
                      "otp",
                      legend.redirectToPage
                    );
                  }}
                  testId={`${legend.name}-arrow`}
                  className="float-right"
                >
                  <AmdArrow direction={ArrowDirection.RIGHT} />
                </AmdButton>
              </div>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export interface IHOPLegend {
  legends: IHOPLegendItem[];
  selectedTime: any;
  airport: string;
}
