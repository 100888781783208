import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdMailRead: React.FunctionComponent<IAmdIconProps> = ({
  width = "14",
  height = "13",
  viewBox = "0 0 14 13",
  color = "#333333",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="mail-read-icon"
    >
      <path
        d="M6.23943 0.0610522C6.09019 -0.0203507 5.90981 -0.0203507 5.76057 0.0610522L0.699522 2.82163C0.268302 3.05684 0 3.5088 0 4V9C0 10.1046 0.895431 11 2 11H10C11.1046 11 12 10.1046 12 9V4C12 3.5088 11.7317 3.05684 11.3005 2.82163L6.23943 0.0610522ZM1.17837 3.69952L6 1.06954L10.8216 3.69952C10.8507 3.71539 10.8769 3.73513 10.8997 3.7579L6 6.43046L1.10031 3.7579C1.12309 3.73513 1.14928 3.71539 1.17837 3.69952ZM6.23943 7.43895L11 4.84227V9C11 9.55229 10.5523 10 10 10H2C1.44772 10 1 9.55229 1 9V4.84227L5.76057 7.43895C5.90981 7.52035 6.09019 7.52035 6.23943 7.43895ZM2.26758 12C2.61339 12.5978 3.25973 13 4.00001 13H10C12.2091 13 14 11.2092 14 9.00001V5.00001C14 4.25973 13.5978 3.61339 13 3.26758V9.00001C13 10.6569 11.6569 12 10 12H2.26758Z"
        fill={color}
      />
    </svg>
  );
};
