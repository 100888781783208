import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { DefaultButton, DialogFooter, PrimaryButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationContext } from "../../../../context/NotificationContext";
import { EventResponseTeamState } from "../../../../models/events/IEventResponseTeam";
import { MESSAGEBAR_TYPE } from "../../../../utils/common/Constants";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { EventResponseTeamGroup } from "./EventResponseTeamGroup";
import { EventResponseTeamMember } from "./EventResponseTeamMember";

export interface IEventResponseTeamProps {
  eventId?: string;
  templateList?: any;
  customList?: any;
  isNewForm?: boolean;
  isInEditMode?: boolean;
  onSaveForm?: any;
  onCancelForm?: any;
  airport: string;
}

export const EventResponseTeam: React.FunctionComponent<
  IEventResponseTeamProps
> = (props: IEventResponseTeamProps) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const { addNotification } = React.useContext(NotificationContext);

  const [eventResponseTeamState, setEventResponseTeamState] =
    useState<EventResponseTeamState>(); // custom added user and group state
  const [mergedTeamState, setMergedTeamState] =
    useState<EventResponseTeamState>(); // merged template and custom user and groups

  const eventResponseFormMethods = useForm();
  const isNewForm = props.isNewForm ?? false;
  const isInEditMode = props.isInEditMode ?? false;
  useEffect(() => {
    const eventResponseTeamStateObj: EventResponseTeamState =
      EventManagementHelper.getResponseTeamStateObjToUpdate(
        props?.customList?.users,
        props?.customList?.groups
      );

    setEventResponseTeamState(eventResponseTeamStateObj);

    // reset all form fields on load.
    eventResponseFormMethods.reset({});
  }, [props?.customList]);

  useEffect(() => {
    const mergedTeamStateObj = EventManagementHelper.getMergedTeamStateObj(
      props.templateList,
      eventResponseTeamState
    );
    setMergedTeamState(mergedTeamStateObj);
  }, [eventResponseTeamState]);

  const manageResponseTeam = (
    resourceType: string,
    action: string,
    data: any
  ) => {
    const responseTeamObj = EventManagementHelper.manageResponseTeam(
      resourceType,
      action,
      data,
      eventResponseTeamState
    );
    setEventResponseTeamState(responseTeamObj);
    return data;
  };

  const onSaveForm = async (data: any) => {
    if (props?.eventId) {
      const response = await EventManagementHelper.onEditEventResponseTeamSave(
        msalInstance,
        props.eventId,
        eventResponseTeamState,
        props.airport
      );
      if (response.status === 200) {
        addNotification(
          MESSAGEBAR_TYPE.SUCCESS,
          `Response team for the event saved successfully!`
        );

        const eventResponseTeamStateObj =
          EventManagementHelper.getResponseTeamStateObjToUpdate(
            eventResponseTeamState?.responseTeam?.users,
            eventResponseTeamState?.responseTeam?.groups
          );

        setEventResponseTeamState(eventResponseTeamStateObj);
      }
    } else if (props.onSaveForm) {
      data = EventManagementHelper.onNewEventResponseTeamSave(
        eventResponseTeamState,
        data
      );
      props.onSaveForm(data);
    }
  };

  const onCancelForm = () => {
    if (props.onCancelForm) {
      props.onCancelForm();
    }
  };

  return (
    <FormProvider {...eventResponseFormMethods}>
      <form noValidate>
        <div
          id="responseTeamContainer"
          data-testid="responseTeamContainer"
          className="mt-2"
        >
          <EventResponseTeamMember
            isNewForm={isNewForm}
            isInEditMode={isInEditMode}
            mergedTeamState={mergedTeamState}
            manageResponseTeam={manageResponseTeam}
            airport={props.airport}
          />

          <EventResponseTeamGroup
            isNewForm={isNewForm}
            isInEditMode={isInEditMode}
            mergedTeamState={mergedTeamState}
            manageResponseTeam={manageResponseTeam}
            airport={props.airport}
          />

          {(isNewForm || isInEditMode) && (
            <DialogFooter>
              <DefaultButton text="Cancel" onClick={onCancelForm} />
              <PrimaryButton text="Save" onClick={onSaveForm} />
            </DialogFooter>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
