import { Spinner } from "@fluentui/react-components";
import { useState } from "react";
import HOPEnum from "../../enum/HOPEnum";
import { useApiCall } from "../../hooks/useApiCall";
import { IHOPSummaryResponse } from "../../models/health-of-operations/IHOPSummary";
import DateHelper from "../../utils/common/DateHelper";
import { HOPHelper } from "../../utils/health-of-operations/HOPHelper";
import { AmdRadarChart } from "../common/charts/RadarChart/RadarChart";
import { AmdAmber } from "../common/icons/amber/Amber";
import { AmdGreen } from "../common/icons/green/Green";
import { AmdRed } from "../common/icons/red/red";
import { NoDataCard } from "../common/no-data-card/NoDataCard";
import { AmdProgressIndicator } from "../common/progress-indicator/ProgressIndicator";
import { TimeRangeSlider } from "../common/time-range-slider/TimeRangeSlider";
import { HOPLegend } from "./HOPLegend";
import { HOPOTPSection } from "./HOPOTPSection";

export const HOPMacroCard: React.FunctionComponent<IHOPMacroCardProps> = ({
  airport,
}) => {
  const [selectedTime, setSelectedTime] = useState<any>({
    start: HOPHelper.getCurrentTime(airport),
    end: "23:30",
  });

  const payload = HOPHelper.getHOPRequestPayload(selectedTime, airport);
  const getHOPSummaryUrl = `${process.env.REACT_APP_API_HOPSUMMARY?.replace(
    "{airportCode}",
    airport
  )}?startCurrentDateTime=${payload.currentStart}&endCurrentDateTime=${
    payload.currentEnd
  }&startForecastDateTime=${payload.forecastStart}&endForecastDateTime=${
    payload.forecastEnd
  }`;

  const {
    data: hopSummaryData,
    error,
    isLoading,
  } = useApiCall<IHOPSummaryResponse>({
    url: getHOPSummaryUrl,
    dependencies: [airport, selectedTime],
  });

  const {
    otp: otpData,
    chartData: hopChartData,
    legendData: hopLegendData,
    performance: otpPerformanceData,
  } = hopSummaryData
    ? HOPHelper.extractHOPSummaryData(hopSummaryData, selectedTime, airport)
    : {
        otp: undefined,
        chartData: undefined,
        legendData: [],
        performance: [],
      };

  const onTimeSliderChange = (start: number, end: number) => {
    setSelectedTime({
      start: DateHelper.minuteToTime(start),
      end: DateHelper.minuteToTime(end),
    });
  };

  return (
    <>
      <header
        className="amd-section-shadow__header amd-container-hspacebtwn"
        data-testid="hop-macro-card"
      >
        <span>Health of operations</span>
        <div className="w-50">
          <TimeRangeSlider
            label="Forecast range"
            maxValue="23:59"
            minValue="00:00"
            value={{
              start: selectedTime.start,
              end: selectedTime.end,
            }}
            onTimeSliderChange={onTimeSliderChange}
          />
        </div>
      </header>
      <section
        className="mt-4 amd-container-hspacebtwn"
        data-testid="hoa-macro-card"
      >
        <div className="d-flex amd-container-vcenter w-50">
          <AmdGreen />
          <span className="mx-1 fw-600">Confidence Factor</span>
          <AmdProgressIndicator
            value={0.85}
            size="large"
            shape="rounded"
            color={HOPHelper.getConfidenceFactorColor(
              HOPEnum.HOPDataStatus.Good
            )}
          />
          <span className="ml-1 fw-600">85%</span>
        </div>
        <div className="d-flex">
          <div className="amd-container-vcenter">
            <AmdGreen />
            <span className="ml-1">Good</span>
          </div>
          <div className="ml-2 amd-container-vcenter">
            <AmdAmber />
            <span className="ml-1">Moderate</span>
          </div>
          <div className="ml-2 amd-container-vcenter">
            <AmdRed />
            <span className="ml-1">Poor</span>
          </div>
        </div>
      </section>
      {error && <NoDataCard message="No data found" />}
      {isLoading && (
        <Spinner
          labelPosition="below"
          label="Loading..."
          style={{ height: "50vh" }}
        />
      )}
      {!error && !isLoading && (
        <section className="mt-sm-4 mb-sm-4 ml-2">
          <div className="d-flex amd-container-haround">
            <div>
              <HOPOTPSection
                otpData={otpData}
                otpPerformance={otpPerformanceData}
              />
              <AmdRadarChart chartData={hopChartData} />
            </div>
            <HOPLegend
              legends={hopLegendData}
              selectedTime={selectedTime}
              airport={airport}
            />
          </div>
        </section>
      )}
    </>
  );
};

export interface IHOPMacroCardProps {
  airport: string;
}
