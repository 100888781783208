import { DateTypeEnum } from "../../../enum/DateEnum";
import { getCurrentDateStartTimeStrForAirport } from "../../common/DateHelperFunctions";

const format = "DD-MM-YY HH:mm";

export const getDisplayTime = (
  airport: string,
  type: DateTypeEnum,
  buttonDateFormat?: string,
  start = false,
  selectedDate?: Date
) => {
  const dateRangeFormat = buttonDateFormat ? buttonDateFormat : format;
  return getCurrentDateStartTimeStrForAirport(
    airport,
    type,
    dateRangeFormat,
    start,
    selectedDate
  );
};

export const getApiTime = (
  airport: string,
  type: DateTypeEnum,
  start = false,
  selectedDate?: Date
) => {
  const format = undefined;
  return new Date(
    getCurrentDateStartTimeStrForAirport(
      airport,
      type,
      format,
      start,
      selectedDate
    )
  );
};
