import { IDropdownOption, Stack, mergeStyleSets } from "@fluentui/react";
import { AmdDropdown } from "../../common/form-controls/uncontrolled/DropDown/AmdDropdown";

const countStyles = mergeStyleSets({
  label: {
    color: "#1a1a1a",
    fontSize: 14,
    lineHeight: 20,
    marginLeft: 10,
  },
});

export const FlightListPagination = ({
  onDropDownChange,
  flightCount,
}: {
  onDropDownChange(pageNumber: string): any;
  flightCount?: number;
}) => {
  return (
    <Stack
      horizontal
      verticalAlign="center"
      data-testid="flight-list-pagination"
      className="pt-sm-2"
    >
      <AmdDropdown
        placeholder="Select an option"
        defaultSelectedKey={"50"}
        options={dropdownOptions}
        onChange={(_e: any, selectedOption: any) => {
          if (selectedOption?.key) {
            onDropDownChange(selectedOption?.key?.toString());
          }
        }}
        className="paginationDropdown"
      />
      <div className={countStyles.label}>Total flights: {flightCount}</div>
    </Stack>
  );
};

const dropdownOptions: IDropdownOption[] = [
  {
    key: "50",
    text: "50 per view",
  },
  { key: "100", text: "100 per view" },
  { key: "200", text: "200 per view" },
];
