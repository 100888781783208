import { Divider, makeStyles } from "@fluentui/react-components";
import * as React from "react";
import AirportEventsCard from "./AirportEventsCard";
import AirportNotificationsCard from "./AirportNotificationsCard";
import AirportOTPCard from "./AirportOTPCard";

interface IAirportCardDetailsProps {
  otpData: any;
  eventData: any;
  notificationData: any;
  isMapView: boolean;
}

const AirportCardDetails: React.FunctionComponent<IAirportCardDetailsProps> = (
  props
) => {
  const { isMapView } = props;
  const classes = useStyles();
  const otpContainerClass = isMapView
    ? classes.otpContainerMapView
    : classes.otpContainer;
  const dividerClass = isMapView ? classes.mapViewDivider : undefined;
  const eventsContainerClass = isMapView
    ? classes.eventContainerMapView
    : classes.eventContainer;
  return (
    <div className="w-100 d-flex" data-testid="airport-card-details">
      <AirportOTPCard
        className={otpContainerClass}
        otpData={props.otpData}
        isMapView={isMapView}
      />

      <Divider vertical className={`px-2 ${dividerClass}`} />

      <AirportEventsCard
        className={eventsContainerClass}
        eventData={props.eventData}
        isMapView={isMapView}
      />

      <Divider
        vertical
        className={`px-2 ${dividerClass}`}
      />

      <AirportNotificationsCard
        className={classes.notificationContainer}
        notificationData={props.notificationData}
        isMapView={isMapView}
      />
    </div>
  );
};

export default AirportCardDetails;

const useStyles = makeStyles({
  otpContainer: {
    width: "45%",
    "@media screen and (max-width: 1400px)": {
      width: "35%",
    },
  },
  otpContainerMapView: {
    width: "28%",
  },
  eventContainer: {
    width: "27%",
    "@media screen and (max-width: 1400px)": {
      width: "31%",
    },
  },
  eventContainerMapView: {
    width: "21%",
  },
  notificationContainer: {
    width: "21%",
    "@media screen and (max-width: 1400px)": {
      width: "24%",
    },
  },
  mapViewDivider: {
    flexGrow: 0,
  },
});
