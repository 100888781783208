import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdDeparture: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill="none"
      data-testid="departure-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.75 6.875C31.875 6.3125 33.125 6 34.375 6H38C39.6875 6 40.8125 7.6875 40.25 9.3125C39.125 12.3125 36.875 14.8125 33.9375 16.3125L16.875 24.8125C16.625 24.9375 16.3125 25 16 25H7.375C6.8125 25 6.25 24.75 5.875 24.3125L1.3125 19C0.875 18.5 1.0625 17.75 1.625 17.4375L3.625 16.4375C4.1875 16.1875 4.8125 16.1875 5.3125 16.4375L8.9375 18L15.125 14.875L5.9375 9C5.3125 8.5625 5.375 7.5625 6.0625 7.25L8.9375 5.8125C9.875 5.3125 11.0625 5.25 12.125 5.625L24.3125 10.1875L30.75 6.875ZM0.5 33C0.5 31.9375 1.375 31 2.5 31H38.5C39.5625 31 40.5 31.9375 40.5 33C40.5 34.125 39.5625 35 38.5 35H2.5C1.375 35 0.5 34.125 0.5 33Z"
        fill={props.color}
      />
    </svg>
  );
};
