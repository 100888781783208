import React from "react";

export const MinusCircleIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.96875C10.7461 0.96875 13.7812 4.00391 13.7812 7.75C13.7812 11.4961 10.7461 14.5312 7 14.5312C3.25391 14.5312 0.21875 11.4961 0.21875 7.75C0.21875 4.00391 3.25391 0.96875 7 0.96875ZM3.39062 8.84375H10.6094C10.7734 8.84375 10.9375 8.70703 10.9375 8.51562V6.98438C10.9375 6.82031 10.7734 6.65625 10.6094 6.65625H3.39062C3.19922 6.65625 3.0625 6.82031 3.0625 6.98438V8.51562C3.0625 8.70703 3.19922 8.84375 3.39062 8.84375Z"
        fill="#DF3127"
      />
    </svg>
  );
};
