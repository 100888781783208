import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationContext } from "../../../../context/NotificationContext";
import { CommonHelper } from "../../../../utils/common/CommonHelper";
import { MESSAGEBAR_TYPE } from "../../../../utils/common/Constants";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { EventResponseTeam } from "../../create-event/response-team/EventResponseTeam";

export interface IEventChannelManagementProps {
  eventId: string;
  airport: string;
}

export const EventChannelManagement: React.FunctionComponent<
  IEventChannelManagementProps
> = (props: IEventChannelManagementProps) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [channelMgmtResponse, setChannelMgmtResponse] = useState<any>();
  const [lastUpdatedRefreshTime, setLastUpdatedRefreshTime] = useState(
    new Date()
  );
  const { addNotification } = React.useContext(NotificationContext);
  useEffect(() => {
    CommonHelper.getCurrentUserEmail(msalInstance).then((userEmail: string) => {
      setCurrentUserEmail(userEmail);
    });
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (props.airport !== "" && props.eventId) {
      EventManagementHelper.getChannelManagement(
        msalInstance,
        props.eventId,
        currentUserEmail,
        props.airport
      )
        .then((response: any) => {
          if (isMounted && response.data) {
            setChannelMgmtResponse(response.data);
          } else {
            setChannelMgmtResponse([]);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [props.airport, props.eventId, lastUpdatedRefreshTime]);

  const eventResponseFormMethods = useForm();

  const onSaveEventResponseForm = async (data: any) => {
    const channelMgmtPayload = EventManagementHelper.getResponseTeamPayload(
      data,
      ["response_team"]
    );

    const response = await EventManagementHelper.updateChannelManagement(
      msalInstance,
      props.eventId,
      currentUserEmail,
      channelMgmtPayload,
      props.airport
    );

    if (response.status === 200) {
      addNotification(
        MESSAGEBAR_TYPE.SUCCESS,
        `Channel management team saved successfully!`
      );
      setLastUpdatedRefreshTime(new Date());
    }

    // Reset the form field values and close the popup.
    eventResponseFormMethods.reset(eventResponseFormFields);
  };

  return (
    <div
      id="channelManagementContainer"
      data-testid="channelManagementContainer"
    >
      <div className="fs-16">
        Select individuals and groups who can create and manage additional Teams
        channels.
      </div>

      <div className="mt-3">
        <EventResponseTeam
          isInEditMode={true}
          templateList={[]}
          customList={channelMgmtResponse}
          onSaveForm={onSaveEventResponseForm}
          airport={props.airport}
        />
      </div>
    </div>
  );
};

const eventResponseFormFields = {};
