import { mergeStyles } from "@fluentui/react";
import * as React from "react";
import { CommonHelper } from "../../utils/common/CommonHelper";
import { ResourceHelper } from "../../utils/resource/ResourceHelper";
import { AmdButton } from "../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdChevron } from "../common/icons/chevron/chevron";
import { ChevronDirection } from "../common/icons/chevron/chevronDirection";
import { ResourcesItem } from "./ResourcesItem";

export interface ICarouselResourceItemProps {
  carouselResourceData?: any[];
}

export const CarouselResourceItem: React.FunctionComponent<
  ICarouselResourceItemProps
> = (props: ICarouselResourceItemProps) => {
  const resourceItems = props?.carouselResourceData?.filter(
    (r) => r.data !== undefined
  );
  return (
    <div className={resourceItemContainer}>
      <div className="amd-container-hcenter" data-testid="CarouselResourceItem">
        {resourceItems?.map((resource: any) => (
          <div key={CommonHelper.getGuid()}>
            {resource?.data && (
              <ResourcesItem
                resourceName={resource.key}
                resourceDetail={ResourceHelper.getResourceData(
                  resource.data ?? [],
                  "percentage"
                )}
              />
            )}
          </div>
        ))}
      </div>
      <AmdButton
        id="resourceAllocationNav"
        testId="resourceAllocationNav"
        type={AmdButtonType.CHIP}
        className={buttonStyle}
        key={CommonHelper.getGuid()}
        onClick={() => CommonHelper.handleRedirects("RESOURCE_ALLOCATION")}
      >
        <span className="text-r d-iblock w-100">
          Read More
          <AmdChevron
            direction={ChevronDirection.RIGHT}
            color="#005EB8"
            className="ml-1"
          />
        </span>
      </AmdButton>
    </div>
  );
};

const buttonStyle = mergeStyles({
  width: 358,
  margin: "10px 0px",
});

const resourceItemContainer = mergeStyles({
  display: "flex",
  flexFlow: "column",
  justifyContent: "space-between",
});
