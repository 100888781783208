export enum Airline {
  Column = "airlineColumn",
  Operator = "airlineOperator",
  Values = "airlineValues",
  Name = "Airlines",
}

const airlineFieldControlDetails = {
  column: {
    label: "Column",
    name: Airline.Name,
    fieldName: Airline.Column,
    isMandatory: false,
  },
  operator: {
    label: "Operator",
    fieldName: Airline.Operator,
    isMandatory: true,
    inputType: "string",
  },
  values: {
    label: "Value(s)",
    fieldName: Airline.Values,
    isMandatory: false,
    inputType: "tagPicker",
  },
};

const GlobalFilterColumnsDetails = {
  [Airline.Name]: {
    fieldControls: Airline,
    fieldControlDetails: airlineFieldControlDetails,
  },
};

const GlobalFilterColumns = [Airline.Name];

const GlobalFilterEnum = {
  filterColumns: GlobalFilterColumns,
  filterColumnsDetails: GlobalFilterColumnsDetails,
};

export default GlobalFilterEnum;
