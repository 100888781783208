import { useEffect } from "react";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";

const useShowNewEventEffect = (methods: any, formFieldsData: any) => {
  useEffect(() => {
    methods.reset(formFieldsData);

    const showNewEventForm = EventManagementHelper.showNewEventForm();
    if (showNewEventForm) {
      window.sessionStorage.removeItem("isNewEventClicked");
    }
  }, []);
};

export default useShowNewEventEffect;
