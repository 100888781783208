import { SelectedGraphDatum, SelectedTablePlanningKPI } from "../../models/events/IWeatherDisruptionSummary";
import { getCurrentTimeMinValue } from "./getCurrentTimeMinValue";
import { getGraphTypeData } from "./getGraphTypeData";

export const reformatTablePopUpDataforGraph = (
  selectedGraphData: SelectedGraphDatum[],
  selectedDisruptionKPI: SelectedTablePlanningKPI
)=> {
  if(selectedGraphData?.length === 0 || !selectedGraphData?.length) {
    return null;
  }
  const capacityData = getGraphTypeData("capacity", selectedGraphData);
  const demandData = getGraphTypeData("demand", selectedGraphData);
  const actualData = getGraphTypeData("actual", selectedGraphData);
  const [currentTimeMinValue, currentTimeMaxValue] = getCurrentTimeMinValue(
    "capacity",
    selectedGraphData
  );

  const weatherDisruptionChartData = {
    lineChartData: [
      {
        legend: "Demand",
        color: "purple",
        data: [...demandData],
      },
      {
        legend: "Capacity",
        color: "DeepPink",
        data: [...capacityData],
      },
      {
        legend: "Actual",
        color: "DodgerBlue",
        data: [...actualData],
      },
    ],
  };

  return {
    ...selectedDisruptionKPI,
    weatherDisruptionChartData: weatherDisruptionChartData,
    currentTimeMinValue,
    currentTimeMaxValue,
  };
};
