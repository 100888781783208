import { mergeStyleSets } from "@fluentui/react";
import {
  IVerticalBarChartDataPoint,
  VerticalBarChart,
} from "@fluentui/react-charting";
import * as React from "react";
import IResourceAllocationDetails from "../../../models/resource-allocation/IResourceAllocationDetails";
import { NoDataCard } from "../no-data-card/NoDataCard";

export class BarChart extends React.Component<IBarChartProps> {
  constructor(props: IBarChartProps) {
    super(props);
  }

  static defaultProps = {
    width: 800,
    testId: "barChart",
  };

  public render(): React.JSX.Element {
    const points: IVerticalBarChartDataPoint[] =
      this.props.resourceDetails?.map((resource) => ({
        x: resource.resourceName,
        y: resource.utilization,
      }));
    const FormatYaxisTick = (data: number) => `${data.toLocaleString()}%`;
    const svgProps: React.SVGProps<SVGSVGElement> = {
      width: this.props.width,
      height: 200,
    };
    const barChartStyles = mergeStyleSets({
      root: {
        width: this.props.width,
        height: 200,
      },
    });
    return (
      <>
        <div className={barChartStyles.root} data-testid={this.props.testId}>
          {this.props.resourceDetails?.length === 0 ? (
            <NoDataCard message={this.props.noResourcesMessage} />
          ) : (
            <VerticalBarChart
              chartTitle="Vertical bar chart"
              data={points ? points : []}
              barWidth={20}
              useSingleColor={true}
              yAxisTickCount={4}
              colors={["#005EB8"]}
              yMaxValue={100}
              yAxisTickFormat={FormatYaxisTick}
              svgProps={svgProps}
              hideLegend={true}
              wrapXAxisLables={true}
            />
          )}
        </div>
      </>
    );
  }
}

export interface IBarChartProps {
  resourceDetails: IResourceAllocationDetails[];
  noResourcesMessage: string;
  width: number;
  testId?: string;
}
