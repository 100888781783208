import { IStackStyles, IStackTokens, Stack } from "@fluentui/react";
import React from "react";

export const PaxTouchPointHeader: React.FunctionComponent = () => {
  return (
    <>
      <Stack horizontal styles={stackStyles} tokens={stackTokens}>
        <div style={columnStyles.column1}>Metric</div>
        <div style={columnStyles.column2}>Thresholds</div>
      </Stack>
    </>
  );
};

// Define the stack styles and tokens
const stackStyles: IStackStyles = {
  root: {
    width: "100%",
    border: "1px solid #E6E6E6",
  },
};
const stackTokens: IStackTokens = { childrenGap: 0 };

// Define the individual column styles
interface ColumnStyles {
  column1: React.CSSProperties;
  column2: React.CSSProperties;
}

// Define the common column styles
const commonColumnStyles: React.CSSProperties = {
  fontSize: 14,
  color: "#1A1A1A",
  fontWeight: 600,
  backgroundColor: "#FFFFFF",
  padding: "15px 0px 15px 20px",
};

const columnStyles: ColumnStyles = {
  column1: {
    ...commonColumnStyles,
    width: "26%",
    borderRight: "1px solid #CCCCCC",
  },
  column2: {
    width: "74%",
    ...commonColumnStyles,
  },
};
