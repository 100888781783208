import { Toggle, mergeStyleSets } from "@fluentui/react";
import { useState } from "react";
import { IPillButton } from "../../models/flights/IFlightPillButton";
import {
  NOTIFICATION_FILTER_PILLS,
  SEVERITY_DROPDOWN_OPTIONS,
} from "../../utils/common/Constants";
import { AmdButton } from "../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdDropdown } from "../common/form-controls/uncontrolled/DropDown/AmdDropdown";
import { AmdAmberLarge } from "../common/icons/amber-large/AmberLarge";
import { AmdMailRead } from "../common/icons/mail-read/MailRead";
import { FlightPillButtonFilter } from "../filters/flight-pillbuttons/FlightPillButtonFilter";

export const NotificationFilters: React.FunctionComponent<
  INotificationFilterProps
> = (props: INotificationFilterProps) => {
  const [_sortOrder, setSortOrder] = useState("Critical");
  const pillButtons: IPillButton[] = NOTIFICATION_FILTER_PILLS;

  return (
    <div className="ms-Grid-row fs-12" data-testid="notification-filter">
      <div className={`ms-Grid-col ms-md6`}>
        <FlightPillButtonFilter
          buttons={pillButtons}
          onPillButtonClick={props.onPillButtonClick}
          noScroll={true}
        />
      </div>
      <div
        className={`ms-Grid-col ms-md6 fs-12 amd-container-hspacebtwn`}
        style={{ alignItems: "center" }}
      >
        <span className={`d-flex amd-container-vcenter`}>
          <AmdAmberLarge />

          <span className="mr-1 ml-1 fw-600">Alerts Only</span>
          <Toggle defaultChecked={false} className="mb-0" />
        </span>
        <span
          className={`d-flex amd-container-vcenter ${contentStyles.pillOnlyLabel}`}
        >
          <label className="fw-600 mr-1">Sort Order</label>
          <AmdDropdown
            placeholder="Select an option"
            defaultSelectedKey={"all"}
            options={SEVERITY_DROPDOWN_OPTIONS}
            onChange={(e: any) => setSortOrder(e.target.value)}
            className={`paginationDropdown ${contentStyles.pillDropWrapper} ${contentStyles.pillOnlyLabel}`}
          />
        </span>
        <AmdButton type={AmdButtonType.ICON_BUTTON}>
          <span
            className={`d-flex amd-container-vcenter ${contentStyles.pillWrapper} ${contentStyles.pillOnlyLabel}`}
          >
            <AmdMailRead />
            <p className="ml-1 fs-14">Mark all read</p>
          </span>
        </AmdButton>
      </div>
    </div>
  );
};

export interface INotificationFilterProps {
  onPillButtonClick: any;
}

const contentStyles = mergeStyleSets({
  pillWrapper: {
    height: 20,
    background: "#F2F2F2",
    border: "1px solid #ccc",
    borderRadius: 4,
    padding: 12,
    display: "inline-block",
    fontFamily:
      "'Segoe UI', ' UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
  },
  pillDropWrapper: {
    background: "#F2F2F2",
    border: "1px solid #ccc",
    borderRadius: 4,
    display: "inline-block",
    width: 100,
  },
  pillOnlyLabel: {
    fontSize: 12,
    lineHeight: 20,
    color: "#1A1A1A",
    fontWeight: 400,
    verticalAlign: "top",
  },
});
