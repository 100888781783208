import { ILineChartPoints } from "@fluentui/react-charting";
import { IWeatherTimeSeries } from "../../models/weather/IWeatherGraph";
import DateHelper from "../common/DateHelper";
import { RESOURCE_COLORS } from "../resource/ResourceConstants";

export abstract class WeatherGraphHelper {
  public static setWeatherGraphData(response: IWeatherTimeSeries[]) {
    let graphData: ILineChartPoints[] = [];
    graphData = response?.map((resource, index) => ({
      color: RESOURCE_COLORS.colors[index],
      legend: resource.attribute,
      lineOptions: {
        strokeWidth: "2",
      },
      hideNonActiveDots: false,
      data: this.getAggregateData(resource),
    }));
    return graphData;
  }

  public static getAggregateData(resource: IWeatherTimeSeries) {
    return resource.timeseriesData.map((data) => ({
      x: new Date(data.time),
      y: data.value,
      xAxisCalloutData: DateHelper.getDayFromDate(data.time),
    }));
  }
}
