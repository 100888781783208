import axios from "axios";
import FLIGHTS_PERFORMANCE_SUMMARY from "../../mock/flights/flights-performance.json";
import OTP_TAB_SUMMARY from "../../mock/flights/otp-tabs-data.json";
import {
  AIRPORT_CODE_PARAMETER,
  DATE_PARAMETER,
} from "../../utils/common/Constants";
import DateHelper from "../../utils/common/DateHelper";
export default class OTPSummaryService {
  public getOTPSummary = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json;charset=UTF-8",
    };
    const dateStr = DateHelper.getDefaultDateStrForAirport(airportCode);

    return axios.get(
      `${process.env.REACT_APP_API_OTPSUMMARY?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      ).replace(DATE_PARAMETER, dateStr)}`,
      { headers }
    );
  };

  public getOTPTabsSummary = async (_accessToken: string): Promise<any> => {
    return Promise.resolve(OTP_TAB_SUMMARY);
  };

  public getFlightsPerformance = async (
    _accessToken: string,
    key: string
  ): Promise<any> => {
    return Promise.resolve(
      (FLIGHTS_PERFORMANCE_SUMMARY as { [k in string]: any })[key]
    );
  };
}
