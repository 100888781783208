import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import { AIRPORT_COLUMNS } from "../../../utils/admin/AdminConstants";
import { AdminHelper } from "../../../utils/admin/AdminHelper";
import { AmdDataGrid } from "../../common/data-grid/AmdDataGrid";
import { AmdTextField } from "../../common/form-controls/uncontrolled/TextField/AmdTextField";

export const Airports: React.FunctionComponent = () => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [airportList, setAirportList] = useState<any>([]);

  useEffect(() => {
    let isMounted = true;

    AdminHelper.getAirports(msalInstance).then((response: any) => {
      if (isMounted) {
        setAirportList(response.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const onSearchTextChange = (e: any) => {
    console.log(e);
  };

  return (
    <div data-testid="airports" className="pt-1">
      <div className="amd-container-hspacebtwn amd-container-vcenter mb-2">
        <div className="d-flex ai-center">
          <div className="ff-amd fs-36 fw-400">Airports</div>
          <AmdTextField
            id="searchAirports"
            label=""
            isMandatory={false}
            isMultiline={false}
            placeholder="Search by IATA Code or name"
            className={`ml-2 ${contentStyles.searchBox}`}
            onChange={onSearchTextChange}
          />
        </div>
      </div>

      <div className="mt-4">
        {airportList && airportList.length > 0 && (
          <AmdDataGrid
            showActionButtons={false}
            rowItems={airportList}
            columns={AIRPORT_COLUMNS}
          />
        )}
      </div>
    </div>
  );
};

const contentStyles = mergeStyleSets({
  searchBox: {
    width: "300px",
  },
});
