import React from "react";
import { AmdAircraft } from "../../common/icons/aircraft/Aircraft";
import { AmdBaggageDisruption } from "../../common/icons/baggage/BaggageDisruption";
import { AmdCloudRain } from "../../common/icons/cloud-rain/CloudRain";
import { AmdDeicing } from "../../common/icons/deicing/Deicing";
import { AmdDeparture } from "../../common/icons/departure/Departure";
import { AmdPassengerDisruption } from "../../common/icons/passenger/passengerDisruption";

export const WeatherDisruptionKPICard: React.FunctionComponent<{
  item: any;
}> = ({ item }: { item: any }) => {
  const { id, body, unit, subBody } = item;

  return (
    <>
      <div
        data-testid="weather-disruption-kpi-card"
        className="w-100 amd-container-center"
      >
        {id === "deIcingCapacity" && (
          <AmdDeicing width="40" height="35" color="#1890F140" />
        )}
        {id === "aircraftOnGround" && (
          <AmdAircraft width="40" height="30" color="#1890F140" />
        )}
        {id === "passengersOnGround" && (
          <AmdPassengerDisruption width="40" height="34" color="#1890F140" />
        )}
        {id === "departureCapacity" && (
          <AmdDeparture width="45" height="34" color="#1890F140" />
        )}
        {id === "baggage" && (
          <AmdBaggageDisruption width="40" height="34" color="#1890F140" />
        )}
        {id === "weather" && <AmdCloudRain height="35" color="#1890F140" />}
        <span className="pl-2 fs-28 fw-600 lh-32">{body}</span>
        <span className="fs-14 fw-400 ml-sm-1 mt-1">{unit}</span>
      </div>
      <div className="w-100 text-c mt-sm-1 fs-13 fw-600">{subBody}</div>
    </>
  );
};
