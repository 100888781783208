import { Dropdown } from "@fluentui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface IDropdownControlProps {
  controlId: string;
  dependentFieldId?: string;
  isMandatory: boolean;
  placeholder?: string;
  options: any[];
  onChange?: any;
  className?: string;
  defaultSelectedKey: string;
  isMultiselect?: boolean;
  dropdownWidth?: any;
  disabled?: boolean;
}

export const DropdownControl: React.FunctionComponent<IDropdownControlProps> = (
  {
    className = "",
    ...props
  }

) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const dependentFieldValue =
    props.dependentFieldId && watch(props.dependentFieldId);

  return (
    <Controller
      name={props.controlId}
      control={control}
      rules={{
        validate: (value) => {
          if (props.dependentFieldId && dependentFieldValue && !value) {
            return "This Field is required";
          }
          return true;
        },
      }}
      render={({ field }) => (
        <Dropdown
          {...field}
          aria-labelledby={props.controlId}
          disabled={props.disabled}
          multiSelect={props.isMultiselect}
          placeholder={props.placeholder}
          defaultSelectedKey={props.defaultSelectedKey}
          options={props.options}
          onChange={(_event, option) => {
            props.onChange(option);
            field.onChange(option);
          }}
          className={className}
          dropdownWidth={props.dropdownWidth}
          errorMessage={
            (errors[props.controlId] as any) &&
            errors[props?.controlId]?.message
          }
        />
      )}
    />
  );
};
