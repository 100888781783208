import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import { CommonHelper } from "../../../../utils/common/CommonHelper";

interface LegendProps {
  legendData: any[];
  keyColor?: string;
  textColor?: string;
  keyFontSize?: number | string;
  textFontSize?: number | string;
  wrapperClassName?: string;
  containerFlow?: "vertical" | "horizontal";
}

export const Legend: React.FunctionComponent<LegendProps> = ({
  legendData = [],
  keyColor = "#666666",
  textColor = "#242424",
  keyFontSize = 13,
  textFontSize = 14,
  wrapperClassName = "",
  containerFlow = "vertical",
}) => {
  return (
    <div
      className={`${
        containerFlow === "vertical"
          ? "amd-container-vflex amd-container-vflex--center"
          : "amd-container-hcenter ai-flex-start"
      }  ${wrapperClassName}`}
    >
      {legendData.map((data: any) => {
        const legendStyles = mergeStyleSets({
          legendSquare: {
            width: 10,
            height: 10,
            background: data.color,
            marginRight: 5,
            display: "inline-block",
          },
          legendKey: {
            fontSize: keyFontSize,
            marginRight: 5,
            color: keyColor,
          },
          legendText: {
            fontSize: textFontSize,
            fontWeight: 600,
            display: "inline-block",
            color: textColor,
          },
        });
        return (
          <div
            data-testid={data.testId}
            key={CommonHelper.getGuid()}
            className={containerFlow === "horizontal" ? "mr-2" : "mb-sm-2"}
          >
            {data.legendKey && (
              <div
                className={legendStyles.legendKey}
                data-testid="square-style"
              >
                {data.legendKey}
              </div>
            )}
            <div>
              <span className={legendStyles.legendSquare}></span>
              <span className={legendStyles.legendText}>{data.legendText}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
