import { useEffect, useState } from "react";
import HOPEnum from "../../../../enum/HOPEnum";
import IFlightsPerformance from "../../../../models/flights/IFlightsPerformance";
import { OTPHelper } from "../../../../utils/flights/OTPHelper";

const useFlightsPerformanceEffect = (
  msalInstance: any,
  selectedTab: string,
  airport: string
) => {
  const [flightsPerformance, setFlightsPerformance] = useState<
    IFlightsPerformance[]
  >([]);

  useEffect(() => {
    let isMounted = true;

    OTPHelper.getFlightsPerformance(msalInstance, selectedTab).then(
      (response: any) => {
        if (isMounted && response && response.data && airport) {
          //To be removed - workaround for PTE
          if (selectedTab === HOPEnum.HOPDIMENSIONS.OTP.toString()) {
            OTPHelper.getOTPSummary(msalInstance, airport).then(
              (otpResponse: any) => {
                if (otpResponse && otpResponse.data) {
                  OTPHelper.updateOTPPerformanceData(
                    otpResponse.data,
                    response.data
                  );
                  setFlightsPerformance(response.data);
                }
              }
            );
          } else {
            setFlightsPerformance(response.data);
          }
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [selectedTab]);

  return flightsPerformance;
};

export default useFlightsPerformanceEffect;
