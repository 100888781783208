import { makeStyles } from "@fluentui/react-components";
import { AddFilled, CalendarFilled } from "@fluentui/react-icons";
import React from "react";
import { Link } from "react-router-dom";
import { $cloud100, $cloud30, $white } from "../../../../cssjs/colors";
import PageEnum from "../../../../enum/PageEnum";
import { AmdButton } from "../../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { CalendarDayIcon } from "../../../common/icons/calendar/CalendarDayIcon";
import { CalendarExclamationIcon } from "../../../common/icons/calendar/CalendarExclamationIcon";
import { CalendarWeekIcon } from "../../../common/icons/calendar/CalendarWeekIcon";

export const EventsCard: React.FC<{ isCarousel?: boolean }> =
  ({ isCarousel = false }): React.JSX.Element => {
    const classes = useStyles();
    const onCreateEventHandler = () => {
      window.sessionStorage.setItem("isNewEventClicked", "true");
    };

    return (
      <div className={`${classes.cardContainer} ${isCarousel ? classes.carouselCardContainer : classes.defaultCardContainer}`}>
        <div className={`${classes.gridItem} ${classes.item1} fs-40`}>
          <span>13</span>
        </div>
        <div className={`${classes.gridItem} ${classes.item2}`}>
          <div className={`fs-18 ${classes.cardIcon}`}>
            <CalendarFilled />
          </div>
          <div className={`fs-14`}>Active Events</div>
        </div>
        <div className={`${classes.gridItem} item3`}></div>
        <div className={`${classes.gridItem} ${classes.item4}`}>
          <div className={`${classes.cardCalendarIconSection} fs-20`}>
            <div>
              <CalendarDayIcon /> <span>05</span>
              <div className="fs-14">Low</div>
            </div>
            <div>
              <CalendarWeekIcon /> <span>07</span>
              <div className="fs-14">Medium</div>
            </div>
            <div>
              <CalendarExclamationIcon /> <span>01</span>
              <div className="fs-14">High</div>
            </div>
          </div>
        </div>
        <div className={`${classes.gridItem} item5`}></div>
        <div className={`${classes.gridItem} ${classes.item6} fs-14`}>
          <Link to={`/${PageEnum.PageUrl.EventManagement}`}>
            <AmdButton
              testId="card-add-event-button"
              type={AmdButtonType.PRIMARY}
              prefixImageIconBeforeText={true}
              imageIcon={<AddFilled className="mr-1" />}
              text="New Event"
              className={`${classes.cardAddEventButton}`}
              onClick={onCreateEventHandler}
            />
          </Link>
        </div>
      </div>
    );
  };
const useStyles = makeStyles({
  gridItem: {},
  cardIcon: {
    lineHeigt: "1",
  },
  defaultCardContainer: {
    borderRadius: "8px",
    padding: "8px",
    border: `1px solid ${$cloud30}`,
  },
  cardContainer: {
    display: "grid",
    gridGap: "4px",
    gridTemplateColumns: "auto 1fr auto",
    "&.card1": {
      gridTemplateRows: "36px 0px 16px 16px",
    },
    boxSizing: "border-box",
    background: $white,
    minHeight: "100%",
  },
  carouselCardContainer: {
    color: $cloud100,
    padding: "16px 36px 32px",
  },
  item1: {
    gridRow: "span 2",
    background: $white,
    display: "flex",
    lineHeight: "1",
    gridAutoFow: "column",
  },
  item2: {
    gridRow: "span 2",
    color: $cloud100,
    alignSelf: "start",
    display: "grid",
    gridTemplateColumns: "1fr",
    lineHeight: "1.2",
  },
  item4: {
    display: "grid",
    gridColumn: "1 / -1",
  },
  item6: {
    display: "grid",
    gridColumn: "1 / -1",
    alignContent: "end",
  },
  cardAddEventButton: {
    width: "100%",
    borderRadius: "4px",
    background: "#005EB8",
    maxHeight: "32px",
    alignItems: "center",
  },
  cardCalendarIconSection: {
    display: "grid",
    gridAutoColumns: "1fr 1fr 1fr",
    gridAutoFlow: "column",
    justifyItems: "flex-start",
    paddingLeft: "12px"
  },
});
