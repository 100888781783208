import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import AADRoleEnum from "../../../enum/AADRoleEnum";
import { AccessDenied } from "../access-denied/AccessDenied";

export interface IAuthZProps {
  roles: AADRoleEnum[];
  children?: React.ReactElement;
}

export const AuthZ: React.FunctionComponent<IAuthZProps> = (
  props: IAuthZProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const userRoles = msalInstance?.getAllAccounts()[0]?.idTokenClaims?.roles;
  const isAuthorizedUser = props.roles?.every((role) =>
    userRoles?.includes(role.toString())
  );

  return isAuthorizedUser ? <div>{props.children}</div> : <AccessDenied />;
};
