import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const CalendarDayIcon: React.FunctionComponent<IAmdIconProps> = ({
  color = "#74691C",
  ...props
}) => {
  return (
    <span className={props.className}>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="calendar-day-icon"
      >
        <path
          d="M4.16675 11.5C3.8855 11.5 3.66675 11.2812 3.66675 11V8C3.66675 7.75 3.8855 7.5 4.16675 7.5H7.16675C7.41675 7.5 7.66675 7.75 7.66675 8V11C7.66675 11.2812 7.41675 11.5 7.16675 11.5H4.16675ZM13.1667 2C13.9792 2 14.6667 2.6875 14.6667 3.5V14.5C14.6667 15.3438 13.9792 16 13.1667 16H2.16675C1.323 16 0.666748 15.3438 0.666748 14.5V3.5C0.666748 2.6875 1.323 2 2.16675 2H3.66675V0.5C3.66675 0.25 3.8855 0 4.16675 0H5.16675C5.41675 0 5.66675 0.25 5.66675 0.5V2H9.66675V0.5C9.66675 0.25 9.8855 0 10.1667 0H11.1667C11.4167 0 11.6667 0.25 11.6667 0.5V2H13.1667ZM13.1667 14.3125V5H2.16675V14.3125C2.16675 14.4375 2.22925 14.5 2.35425 14.5H12.9792C13.073 14.5 13.1667 14.4375 13.1667 14.3125Z"
          fill={color}
        />
      </svg>
    </span>
  );
};
