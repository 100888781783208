import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdWindy: React.FunctionComponent<IAmdIconProps> = ({
  width = "16",
  height = "14",
  color = "#9BCAEB",
  viewBox = "0 0 16 14",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.07282 2.81162C4.39979 1.17678 5.83524 0 7.50245 0C9.43453 0 11 1.56757 11 3.49878C11 5.43134 9.43343 7.00002 7.49999 7.00002H0.75C0.335786 7.00002 0 6.66423 0 6.25002C0 5.8358 0.335786 5.50002 0.75 5.50002H7.49999C8.60413 5.50002 9.5 4.60379 9.5 3.49878C9.5 2.39513 8.60523 1.5 7.50245 1.5C6.55026 1.5 5.73043 2.17209 5.54369 3.1058L5.48544 3.39709C5.4042 3.80326 5.00908 4.06667 4.60291 3.98544C4.19674 3.9042 3.93333 3.50908 4.01456 3.10291L4.07282 2.81162ZM11.3595 6.354C11.7744 5.52418 12.6225 5 13.5503 5C14.9031 5 15.9997 6.09663 15.9997 7.4494V7.49999C15.9997 8.8807 14.8804 9.99999 13.4997 9.99999H12.5836C12.691 10.2708 12.75 10.566 12.75 10.875C12.75 12.1886 11.6816 13.25 10.3719 13.25C9.57868 13.25 8.8349 12.8547 8.39267 12.1942L8.37679 12.1705C8.14634 11.8263 8.23854 11.3605 8.58273 11.13C8.92692 10.8996 9.39276 10.9918 9.62321 11.336L9.63908 11.3597C9.80193 11.6029 10.0775 11.75 10.3719 11.75C10.857 11.75 11.25 11.3564 11.25 10.875C11.25 10.3918 10.8582 10 10.375 10H7L6.99618 9.99999H0.75C0.335786 9.99999 0 9.6642 0 9.24999C0 8.83578 0.335786 8.49999 0.75 8.49999H13.4997C14.052 8.49999 14.4997 8.05228 14.4997 7.49999V7.4494C14.4997 6.92506 14.0746 6.5 13.5503 6.5C13.1907 6.5 12.8619 6.70317 12.7011 7.02482L12.6708 7.08541C12.4856 7.4559 12.0351 7.60606 11.6646 7.42082C11.2941 7.23558 11.1439 6.78507 11.3292 6.41459L11.3595 6.354Z"
        fill={color}
      />
    </svg>
  );
};
