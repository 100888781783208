import {
  IStackStyles,
  IStackTokens,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import React from "react";
import { IMetrics } from "../../../models/app-settings/ITouchPoint";
import { PaxThresholdBar } from "./PaxThresholdBar";
import { PaxTouchPointDescr } from "./PaxTouchPointDescr";

export const PaxTouchPointItem: React.FunctionComponent<
  ITouchPointsItemProps
> = (props: ITouchPointsItemProps) => {
  return (
    <div className={contentStyles.row} data-testid="pax-touch-point-item">
      <Stack horizontal styles={stackStyles} tokens={stackTokens}>
        <PaxTouchPointDescr
          title={props.metric.name}
          description={props.metric.description}
          className={contentStyles.column1}
        />
        <PaxThresholdBar
          unit={props.metric.unit}
          threshold={props.metric.thresholds}
          className={contentStyles.column2}
        />
      </Stack>
    </div>
  );
};

export interface ITouchPointsItemProps {
  metric: IMetrics;
}

// Define the stack styles and tokens
const stackStyles: IStackStyles = {
  root: {
    width: "100%",
    border: "1px solid #E6E6E6",
  },
};
const stackTokens: IStackTokens = { childrenGap: 0 };

// Define the common column styles
const commonColumnStyles: React.CSSProperties = {
  padding: "15px 0px 15px 20px",
};

// Define the individual column styles
const contentStyles = mergeStyleSets({
  column1: {
    ...commonColumnStyles,
    width: "26%",
    borderRight: "1px solid #CCCCCC",
  },
  column2: {
    width: "74%",
    ...commonColumnStyles,
  },
  row: {
    ":nth-child(even)": {
      background: "rgb(242, 242, 242, 0.75)",
    },
  },
});
