import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationContext } from "../../../context/NotificationContext";
import EventManagementEnum from "../../../enum/EventManagementEnum";
import { getAirportCode } from "../../../store/airport/AirportSelector";
import { useAppSelector } from "../../../store/StoreHooks";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { MESSAGEBAR_TYPE } from "../../../utils/common/Constants";
import {
  EVENT_RESPONSETEAM_SELECTEDGROUPS,
  EVENT_RESPONSETEAM_SELECTEDUSERS,
} from "../../../utils/events/EventConstants";
import { EventManagementHelper } from "../../../utils/events/EventManagementHelper";
import EventManagementCard from "../../common/event-management-card/EventManagementCard";
import { LoadingScreen } from "../../common/loading-screen/LoadingScreen";
import { NotificationBanner } from "../../common/notification-banner/NotificationBanner";
import { CreateNewEvent } from "../create-event/CreateNewEvent";
import useShowNewEventEffect from "./hooks/useShowNewEventEffect";

export const EventContainer: React.FC = () => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const { addNotification } = React.useContext(NotificationContext);

  const [createEvent, setCreateEvent] = useState(
    EventManagementHelper.showNewEventForm()
  );
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [_eventListRefreshTimestamp, setEventListRefreshTimestamp] = useState(
    new Date()
  );

  let responseFormData: any = {};

  //Extract Query parameters
  const url: URL = new URL(window.location.href);
  const params: URLSearchParams = url.searchParams;
  const weatherDisruptionEventType = params.get("title")
    ? "Weather Disruption"
    : "";
  const eventName = params.get("title") || "";
  const eventDescr = params.get("descr") || "";

  //Update Form Values - This will pre-fill values the event creation form
  formFieldsData[EventManagementEnum.CreateEvent.EventName] = eventName;
  formFieldsData[EventManagementEnum.CreateEvent.EventDescription] = eventDescr;
  formFieldsData[EventManagementEnum.CreateEvent.TypeOfEvent] = {
    text: weatherDisruptionEventType,
  };

  const methods = useForm();
  useShowNewEventEffect(methods, formFieldsData);

  const airport = useAppSelector((state) => getAirportCode(state));

  useEffect(() => {
    CommonHelper.getCurrentUserEmail(msalInstance).then((userEmail: string) => {
      setCurrentUserEmail(userEmail);
    });
  }, [msalInstance]);

  const onCancelEvent = (errors: any) => {
    console.log(errors);
    setCreateEvent(false);
  };

  const onSaveEvent = async (data: any) => {
    try {
      data[EVENT_RESPONSETEAM_SELECTEDUSERS] =
        responseFormData[EVENT_RESPONSETEAM_SELECTEDUSERS];
      data[EVENT_RESPONSETEAM_SELECTEDGROUPS] =
        responseFormData[EVENT_RESPONSETEAM_SELECTEDGROUPS];

      const eventPayload = EventManagementHelper.getEventPayload(
        data,
        currentUserEmail,
        ["details", "response_team"]
      );

      setShowLoadingScreen(true);

      const createdEventResponse = await EventManagementHelper.createEvent(
        msalInstance,
        eventPayload,
        airport
      );

      if (createdEventResponse?.data?.teamId) {
        addNotification(
          MESSAGEBAR_TYPE.SUCCESS,
          `Created event: ${eventPayload.details?.name} successfully!`
        );
      }
    } catch (error: any) {
      error?.response?.status === 504
        ? addNotification(
            MESSAGEBAR_TYPE.WARNING,
            `Your request timed out, to check if the event was created successfully please refresh the event list.`
          )
        : addNotification(MESSAGEBAR_TYPE.ERROR, `Event creation error!`);
    } finally {
      // Reset the form field values and close the popup.
      methods.reset(formFieldsData);
      setShowLoadingScreen(false);
      refreshEventList();
      setCreateEvent(false);
    }
  };

  const onSaveEventResponseForm = (formData: any) => {
    responseFormData = formData;
    methods.handleSubmit(onSaveEvent)(formData);
  };

  const refreshEventList = () => {
    setEventListRefreshTimestamp(new Date());
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSaveEvent, onCancelEvent)}
        noValidate
      >
        <NotificationBanner airport={airport} />

        {createEvent && (
          <>
            {!showLoadingScreen && (
              <CreateNewEvent
                onCancelEvent={onCancelEvent}
                onSaveEventResponseForm={onSaveEventResponseForm}
                airport={airport}
                eventType={weatherDisruptionEventType}
              />
            )}

            {showLoadingScreen && (
              <LoadingScreen message="Please wait while event is being saved..." />
            )}
          </>
        )}

        {!createEvent && (
          <>
            <div className="ms-Grid" dir="ltr">
              <div className={contentStyles.eventHeaderStylesRoot}>
                <h1
                  className={`amd-heading1 ${contentStyles.eventHeaderStylesHeader}`}
                >
                  EVENT MANAGEMENT
                </h1>
              </div>
            </div>
            <div className="ms-Grid" dir="ltr">
              <EventManagementCard
                airport={airport}
                pathname="/event-management"
              />
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};

const contentStyles = mergeStyleSets({
  eventListContainer: mergeStyles({
    height: "calc(100vh - 199px)",
    maxHeight: "calc(100vh - 199px)",
  }),
  cancelEventStyle: mergeStyles({
    width: "100px",
    height: "40px",
  }),
  eventHeaderStylesRoot: mergeStyles({
    float: "left",
    width: "100%",
  }),
  eventHeaderStylesHeader: mergeStyles({
    width: "80%",
    float: "left",
  }),
  eventHeaderStylesNewEvent: mergeStyles({
    width: "130px",
    float: "right",
  }),
  eventHeaderStylesPlusIcon: mergeStyles({
    paddingRight: "10px",
    marginTop: "2px",
  }),
});

const formFieldsData = {
  [EventManagementEnum.CreateEvent.EventName]: "",
  [EventManagementEnum.CreateEvent.EventDescription]: "",
  [EventManagementEnum.CreateEvent.CallerName]: "",
  [EventManagementEnum.CreateEvent.CallerContactNumber]: "",
  [EventManagementEnum.CreateEvent.ReportedDate]: new Date(),
  [EventManagementEnum.CreateEvent.ReportedTime]: new Date(),
  [EventManagementEnum.CreateEvent.TypeOfEvent]: { text: "" },
  [EventManagementEnum.CreateEvent.IncidentSeverity]: "",
  [EventManagementEnum.CreateEvent.LoggedDate]: new Date(),
  [EventManagementEnum.CreateEvent.LoggedTime]: new Date(),
  [EventManagementEnum.CreateEvent.ClosedDate]: new Date(),
  [EventManagementEnum.CreateEvent.ClosedTime]: new Date(),
  [EVENT_RESPONSETEAM_SELECTEDUSERS]: "",
  [EVENT_RESPONSETEAM_SELECTEDGROUPS]: "",
};
