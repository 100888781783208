import axios from "axios";
import {
  AIRPORT_CODE_PARAMETER,
  APIM_KEY,
  CONTENT_TYPE,
} from "../../utils/common/Constants";

export default class WeatherService {
  public getCurrentWeatherSummary = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };

    return axios.get(
      `${process.env.REACT_APP_API_WEATHER_SUMMARY?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      {
        headers,
      }
    );
  };
  public getRunwayWindDetails = async (
    accessToken: string,
    airportCode: string,
    runway?: string
  ): Promise<any> => {
    const url = runway
      ? `${process.env.REACT_APP_API_RUNWAY_WIND?.replace(
          AIRPORT_CODE_PARAMETER,
          airportCode
        )}/${runway}`
      : `${process.env.REACT_APP_API_RUNWAY_WIND?.replace(
          AIRPORT_CODE_PARAMETER,
          airportCode
        )}`;
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    return axios.get(url, {
      headers,
    });
  };
}
