import { IBasePickerSuggestionsProps, ITag, TagPicker } from "@fluentui/react";
import { Controller, useFormContext } from "react-hook-form";
import {
  filterSuggestedTags,
  getTextFromItem,
} from "../../../utils/tag-picker/TagPickerHelper";

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Airlines found",
  noResultsFoundText: "No Airlines found",
};

export interface IQueryTagPickerControl {
  controlId: string;
  isMandatory: boolean;
  onTagSelected?: any;
  className?: string;
  selectedItems?: ITag[];
  itemLimit?: number;
}

export const QueryTagPickerControl: React.FunctionComponent<
  IQueryTagPickerControl
> = (props: IQueryTagPickerControl) => {
  const { control } = useFormContext();

  const onResolveSuggestions = (filterText: string, tagList?: ITag[]) => {
    return filterSuggestedTags(props.controlId, filterText, tagList);
  };

  return (
    <Controller
      name={props.controlId}
      control={control}
      rules={{ required: props.isMandatory }}
      render={({ field }) => (
        <>
          <TagPicker
            {...field}
            removeButtonAriaLabel="Remove"
            onResolveSuggestions={onResolveSuggestions}
            defaultSelectedItems={props.selectedItems}
            onItemSelected={props.onTagSelected}
            itemLimit={props.itemLimit}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            inputProps={{
              id: props.controlId,
              "aria-labelledby": props.controlId,
            }}
          />
        </>
      )}
    ></Controller>
  );
};
