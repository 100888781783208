import { IconButton } from "@fluentui/react";
import { iconClass } from "./styles";

export function onDelete(item: any, onDeleteItem: any){
  if (onDeleteItem) {
    onDeleteItem(item);
  }
};


export function getDelete(item: any, onDeleteItem: any) {
    return (
      <IconButton
        iconProps={{ iconName: "Delete" }}
        title="Delete"
        ariaLabel="Delete"
        onClick={() => onDelete(item, onDeleteItem)}
        className={iconClass}
      />
    );
  };