import { mergeStyleSets } from "@fluentui/react";

export const getSliderStyles = (width = 386, height = 362) =>
  mergeStyleSets({
    cardDimension: {
      minWidth: width,
      width: width,
      height: height,
    },
    sliderViewBox: {
      maxWidth: "100%",
      overflowX: "auto",
      overflowY: "hidden",
      display: "flex",
      marginTop: 32,
      scrollBehavior: "smooth",
      selectors: {
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
    sliderScrollView: {
      display: "block",
    },
    sliderNext: {
      transform: `translateX(-${width}px)`,
      transition: "transform 0.5s ease-in",
    },
    sliderPrev: {
      transform: `translateX(${width}px)`,
      transition: "transform 0.5s ease-in",
    },
  });
