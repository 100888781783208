import { AmdAmber } from "../../common/icons/amber/Amber";
import { AmdGreen } from "../../common/icons/green/Green";
import { AmdRed } from "../../common/icons/red/red";

export const PAXTouchPointsLegend: React.FunctionComponent = () => {
  return (
    <div className="d-flex" data-testid="pax-touchpoints-legend">
      <div className="amd-container-vcenter">
        <AmdGreen /> <span className="ml-1">Under utilised</span>
      </div>

      <div className="amd-container-vcenter ml-2">
        <AmdGreen /> <span className="ml-1">Optimal operations</span>
      </div>
      <div className="amd-container-vcenter ml-2">
        <AmdAmber />
        <span className="ml-1">Crowded</span>
      </div>
      <div className="amd-container-vcenter ml-2">
        <AmdRed />
        <span className="ml-1">Over utilised</span>
      </div>
    </div>
  );
};
