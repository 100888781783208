enum NotificationType {
  Flight = "flight",
  Weather = "weather",
  EventManagement = "event",
  Resource = "resource",
}

enum Severity {
  None = "none",
  Critical = "critical",
  Warning = "warning",
  Information = "info",
}

enum PubSubHub {
  Alerts = "alerts",
  Notifications = "notifications",
}

const NotificationEnum = {
  NotificationType,
  Severity,
  PubSubHub,
};

export default NotificationEnum;
