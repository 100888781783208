
import React from "react";
import { PAXRowEnum } from "../../../enum/PAXEnum";
import { makeStyles } from "@fluentui/react-components";
const useClasses = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  rootRowLabels: {
    display: "flex",
    justifyContent: "left",
  },
  icon: {
    paddingRight: "5px",
  },
});

export default function Optimal({
  fieldContent,
}: {
  fieldContent: string;
}): React.JSX.Element {
  let isRowLabel = false;

  if (Object.values(PAXRowEnum).includes(fieldContent as PAXRowEnum)) {
    isRowLabel = true;
  }
  const classes = useClasses();
  return (
    <div className={isRowLabel ? classes.rootRowLabels : classes.root}>
      <span>{fieldContent}</span>
    </div>
  );
}
