import { createContext } from "react";
import { DataType } from "../../components/common/DataType";
import { AdminHelper } from "./AdminHelper";
export const AdminContext = createContext(false);

export const ADMIN_TABS = {
  data: [
    { text: "Users", value: "users" },
    { text: "Organizations", value: "organizations" },
    { text: "Roles", value: "roles" },
    { text: "Airports", value: "airports" },
  ],
};

export const USER_COLUMNS = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 90,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "email",
    name: "Email",
    fieldName: "email",
    minWidth: 90,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "organization",
    name: "Organization",
    fieldName: "organization",
    minWidth: 50,
    maxWidth: 100,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "role",
    name: "Role",
    fieldName: "role",
    minWidth: 90,
    maxWidth: 140,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "airport",
    name: "Airport",
    fieldName: "airport",
    minWidth: 90,
    maxWidth: 90,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "userTerminal",
    name: "Terminal",
    fieldName: "userTerminal",
    minWidth: 40,
    maxWidth: 70,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "checkInZone",
    name: "checkin zone",
    fieldName: "checkInZone",
    minWidth: 90,
    maxWidth: 70,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "edit",
    name: "",
    fieldName: "edit",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
  {
    key: "delete",
    name: "",
    fieldName: "delete",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
];

export const ROLES_COLUMNS = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 150,
    maxWidth: 200,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) => <div>{item.name}</div>,
  },
  {
    key: "flights",
    name: "Flights",
    fieldName: "flights",
    minWidth: 60,
    maxWidth: 60,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) =>
      AdminHelper.getRoleComponentCellContent(item?.flights),
  },
  {
    key: "passengers",
    name: "Passengers",
    fieldName: "passengers",
    minWidth: 60,
    maxWidth: 60,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) =>
      AdminHelper.getRoleComponentCellContent(item?.passengers),
  },
  {
    key: "baggage",
    name: "Baggage",
    fieldName: "baggage",
    minWidth: 60,
    maxWidth: 60,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) =>
      AdminHelper.getRoleComponentCellContent(item?.baggage),
  },
  {
    key: "resourceAllocation",
    name: "Resource allocation",
    fieldName: "resourceallocation",
    minWidth: 120,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) =>
      AdminHelper.getRoleComponentCellContent(item?.resourceallocation),
  },
  {
    key: "weather",
    name: "Weather",
    fieldName: "weather",
    minWidth: 80,
    maxWidth: 80,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) =>
      AdminHelper.getRoleComponentCellContent(item?.weather),
  },
  {
    key: "eventManagement",
    name: "Event management",
    fieldName: "eventmanagement",
    minWidth: 100,
    maxWidth: 100,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) =>
      AdminHelper.getRoleComponentCellContent(item?.eventmanagement),
  },
  {
    key: "edit",
    name: "",
    fieldName: "edit",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
  {
    key: "delete",
    name: "",
    fieldName: "delete",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
];

export const AIRPORT_COLUMNS = [
  {
    key: "iataCode",
    name: "IATA code",
    fieldName: "iataCode",
    minWidth: 90,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "airportName",
    name: "Airport name",
    fieldName: "airportName",
    minWidth: 350,
    maxWidth: 400,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "city",
    name: "City",
    fieldName: "city",
    minWidth: 250,
    maxWidth: 400,
    dataType: DataType.String,
    isRowHeader: true,
  },
];

export const ORGANIZATION_COLUMNS = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 90,
    maxWidth: 450,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "roles",
    name: "Roles",
    fieldName: "roles",
    minWidth: 350,
    maxWidth: 450,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) =>
      AdminHelper.getOrganizationComponentCellContent(item?.roles),
  },
  {
    key: "edit",
    name: "",
    fieldName: "edit",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
  {
    key: "delete",
    name: "",
    fieldName: "delete",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
];
