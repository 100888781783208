import { AmdBaggage } from "../../common/icons/baggage/baggage";
import { AmdOptions } from "../../common/icons/options/Options";

function BaggageSummaryCard() {
  return (
    <div data-testid="baggage-summary-card">
      <div className="amd-container-hspacebtwn pa-sm-2">
        <AmdBaggage />
        <span className="fs-14 fw-600 fc--gray800">Baggage</span>
        <AmdOptions />
      </div>
    </div>
  );
}
export default BaggageSummaryCard;
