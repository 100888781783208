import { useEffect, useState } from "react";
import ResourceEnum from "../../enum/ResourceEnum";
import IResourceAvailability from "../../models/resource-allocation/IResourceAvailability";
import { CommonHelper } from "../../utils/common/CommonHelper";
import { ResourceHelper } from "../../utils/resource/ResourceHelper";
import { AmdTabs, ITab } from "../common/tabs/tabs";
import { ResourceAllocationListContainer } from "./ResourceAllocationListContainer";
import { ResourceDetailsCard } from "./ResourceDetailsCard";

export const ResourceAllocationKPICard: React.FunctionComponent<
  IResourceAllocationKPICardProps
> = ({
  resourceAvailabilityData,
  resourceAllocationData,
  showList,
  legType,
  selectedResourceTab,
  onFiltersApplied,
  onUpdateFilter,
  selectedType,
  resourceFilters,
  flightListState,
  onResetGraphicClick,
  onDataPointClick,
  onSelectTerminal,
  onSelectRange,
  onLegendClick,
  range,
  airport,
}) => {
  const [selectedTerminal, setSelectedTerminal] = useState<string>();
  const [selectedRange, setSelectedRange] = useState<string>();
  const [terminalTabs, setTerminalTabs] = useState<ITab[]>([]);
  const [rangeFilterTabs, setRangeFilterTabs] = useState<ITab[]>([]);
  const [donutDataList, setDonutDataList] = useState<any[]>([]);
  const [barGraphData, setBarGraphData] = useState<any[]>([]);

  useEffect(() => {
    setResourceAvailabilityData();
  }, [resourceAvailabilityData]);

  useEffect(() => {
    setResourceAllocationData();
  }, [resourceAllocationData]);

  const setResourceAllocationData = () => {
    const barGraphList: any = [];
    resourceAllocationData?.forEach((res: any) => {
      barGraphList.push(res?.data);
    });
    setBarGraphData(barGraphList);
    if (resourceAllocationData && resourceAllocationData.length > 0) {
      setRangeFilterTabs(resourceAllocationData[0].range);
    }
  };

  const setResourceAvailabilityData = () => {
    const donutList: any = [];
    const resources: IResourceAvailability[] = [];
    resourceAvailabilityData?.forEach((res: any) => {
      resources.push(ResourceHelper.getResponseData(res));
      donutList.push(
        ResourceHelper.getResourceData(
          res?.data?.availability ?? [],
          "operative",
          true
        )
      );
    });
    setDonutDataList(donutList);
    if (airport === "DEL") {
      setTerminalTabs(ResourceHelper.getTerminalFiltersDEL(resources));
    } else {
      setTerminalTabs(ResourceHelper.getTerminalFilters(resources));
    }
  };

  useEffect(() => {
    if (terminalTabs && terminalTabs.length > 0) {
      setSelectedTerminal(terminalTabs[0].value);
      onSelectTerminal(terminalTabs[0].value);
    }
  }, [terminalTabs]);

  useEffect(() => {
    if (rangeFilterTabs && rangeFilterTabs.length > 0) {
      const val = range ? range.toString() : rangeFilterTabs[0].value;
      setSelectedRange(val);
    }
  }, [rangeFilterTabs]);

  const onTerminalTabClick = (_terminal: string) => {
    setSelectedTerminal(_terminal);
    onSelectTerminal(_terminal);
  };

  const onRangeTabClick = (_range: string) => {
    setSelectedRange(_range);
    onSelectRange(_range);
  };

  return (
    <>
      {selectedTerminal && (
        <AmdTabs
          dataTestId="ResourceAllocationTerminalTab"
          defaultSelectedTab={"Remote"}
          selectedTab={selectedTerminal}
          tabList={terminalTabs}
          onTabClick={onTerminalTabClick}
          position="right"
          styleType="secondary"
        />
      )}
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row d-flex flex-flow-rowrev">
          <div className="ms-Grid-col ms-md12 ms-xxl6 overflow-x">
            <div className="amd-container-hend ai-center">
              {selectedRange && (
                <AmdTabs
                  dataTestId="ResourceAllocationRangeTab"
                  defaultSelectedTab={"0"}
                  selectedTab={selectedRange}
                  tabList={rangeFilterTabs}
                  onTabClick={onRangeTabClick}
                  styleType="secondary"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {resourceAvailabilityData?.map((resource: any, index: number) => {
        return (
          <div key={CommonHelper.getGuid()} className="resourceDetails">
            {resource?.data && resource?.showKPI && (
              <ResourceDetailsCard
                key={CommonHelper.getGuid()}
                resourceData={donutDataList[index]}
                resources={resource?.data?.availability}
                resourceDetails={barGraphData[index]}
                resourceName={resource.key}
              />
            )}
          </div>
        );
      })}
      {showList &&
        legType &&
        selectedResourceTab !==
          ResourceEnum.ResourceTabs.SelfServiceCounter && (
          <ResourceAllocationListContainer
            onFiltersApplied={onFiltersApplied}
            onUpdateFilter={onUpdateFilter}
            resourceFilters={resourceFilters}
            flightListState={flightListState}
            selectedType={selectedType}
            onResetGraphicClick={onResetGraphicClick}
            onDataPointClick={onDataPointClick}
            onLegendClick={onLegendClick}
            selectedResourceTab={selectedResourceTab}
            airport={airport}
          />
        )}
    </>
  );
};

export interface IResourceAllocationKPICardProps {
  legType: string;
  selectedResourceTab: string;
  onFiltersApplied: any;
  onUpdateFilter: any;
  resourceFilters: any;
  flightListState: any;
  onResetGraphicClick: any;
  onDataPointClick: any;
  selectedType: string;
  showList?: boolean;
  resourceAvailabilityData?: any[];
  resourceAllocationData?: any[];
  range?: number;
  onSelectTerminal: any;
  onSelectRange: any;
  onLegendClick?: any;
  airport: string;
}
