import { mergeStyleSets, mergeStyles } from "@fluentui/react";
import { LineChart } from "@fluentui/react-charting";
import React from "react";
import { $amadeusGray400, $gray600, $primary } from "../../../cssjs/colors";
import { getPassengerFlowState } from "../../../store/pax-flow/passengerFlowSelector";
import { useAppSelector } from "../../../store/StoreHooks";
import { ILegendExtended } from "../../../utils/pax/PassengerHelper";
import { AmdDataGrid } from "../../common/data-grid/AmdDataGrid";
import PAXTouchPointsGraphLegend from "./PAXTouchPointsGraphLegend";

export const PAXTouchPointsGraphAndTable: React.FunctionComponent<
  IPAXTouchPointsGraphAndTableProps
> = (props: IPAXTouchPointsGraphAndTableProps) => {
  const { chartLegends, chartLegendHeader, chartLegendKey, chartData } = props;
  const lineChartData = chartData?.lineChartData ?? [];

  const updatedChartData = {
    ...chartData,
    lineChartData: [...lineChartData],
  };

  const paxflow = useAppSelector((state: any) => getPassengerFlowState(state));
  const {
    showTableRows,
    selectedTouchPointFilter,
    selectedTouchPointFilterLabel,
  } = paxflow;

  const EventCallOutComponent = React.useCallback(
    () => <div className={calloutItemStyle}></div>,
    []
  );
  return (
    <div
      data-testid="pax-touchpoints-graphandtable"
      className={`ms-Grid ${contentStyles.rootContainer}`}
      dir="ltr"
    >
      <div className={`ms-Grid-row ${contentStyles.gridAndGraphContainer}`}>
        <div className={`ms-Grid-col mt-1 ${contentStyles.legendContainer}`}>
          <div className={`fw-600 mb-1`}data-testid="selected-touchpoint-filter-text">
            {chartLegendHeader}{" "}
            <small>
              {selectedTouchPointFilter && `(${selectedTouchPointFilterLabel})`}
            </small>
          </div>
          <div className={`${contentStyles.innerLegendContainer}`}>
            <PAXTouchPointsGraphLegend
              headerTitle={chartLegendHeader}
              chartLegendKey={chartLegendKey}
              legends={chartLegends}
            />
            <div className={`${contentStyles.yAxisTitle}`}>
              {chartLegendHeader === "Airport Entry"
                ? "Effective utilisation"
                : "Passenger count"}
            </div>
          </div>
        </div>
        <div className={`ms-Grid-col ${contentStyles.barChartContainer}`}>
          <LineChart
            data={updatedChartData}
            strokeWidth={2}
            yMinValue={0}
            yMaxValue={20}
            tickFormat={"%H:%M"}
            allowMultipleShapesForPoints={props.allowMultipleShapesForPoints}
            height={190}
            width={1630}
            hideLegend={true}
            className={`line-chart`}
            eventAnnotationProps={{
              events: [
                {
                  event: "",
                  date: new Date(props.currentTimeMinValue),
                  onRenderCard: EventCallOutComponent,
                },
                {
                  event: "",
                  date: new Date(props.currentTimeMaxValue),
                  onRenderCard: EventCallOutComponent,
                },
              ],
              strokeColor: $primary,
              labelColor: $amadeusGray400,
              labelHeight: 18,
              labelWidth: 50,
              mergedLabel: (count: number) => `${count} events`,
            }}
            xAxisTickCount={props.chartXAxisTickCount}
            yAxisTickCount={props.chartYAxisTickCount}
          />
        </div>
      </div>
      <div
        className="ms-Grid-row"
        style={{ marginTop: "-35px", zIndex: 100, position: "relative" }}
      >
        <AmdDataGrid
          showActionButtons={false}
          showHeader={true}
          rowItems={props.tableData}
          columns={props.tableColumns}
          infoHeaders={["label"]}
          minHeight="65px"
          scrollablePaneWidth="1100px"
          disableSorting={true}
          isHeaderShowHide={true}
          showTableRows={showTableRows}
        />
      </div>
    </div>
  );
};

type ChartData = {
  date: string;
  actualCurbSide?: number;
  predictionCurbSide?: number;
  actualParking?: number;
  predictionParking?: number;
  actual?: number;
  prediction?: number;
  capacity?: number;
};

export type MappedChartData = ChartData[][];

interface IPAXTouchPointsGraphAndTableProps {
  tableColumns: any;
  tableData: any;
  allowMultipleShapesForPoints: boolean;
  chartHeight: number;
  chartWidth: number;
  chartData: any;
  chartLegendHeader: string;
  chartLegends: ILegendExtended[];
  chartXAxisTickCount: number;
  chartYAxisTickCount: number;
  currentTimeMinValue: string;
  currentTimeMaxValue: string;
  chartYAxisTitle?: string;
  chartLegendKey: string;
}

const contentStyles = mergeStyleSets({
  rootContainer: {
    width: "100%",
    overflowX: "auto",
  },
  gridAndGraphContainer: {
    display: "flex",
    width: "2000px",
    height: "200px",
  },
  barChartContainer: {
    width: "2000px",
    whiteSpace: "nowrap",
    //overflowX: "auto",
    ".line-chart g:first-child": {
      display: "none",
    },
    ".line-chart": {
      zIndex: 101,
      position: "relative",
    },
  },
  legendContainer: {
    display: "flex",
    flexDirection: "column",
    width: "210px",
    height: "128px",
    whiteSpace: "nowrap",
  },
  innerLegendContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    height: "100%",
  },
  yAxisTitle: {
    textAlign: "center",
    transform: "rotate(-90deg)",
    width: "100%",
    fontSize: "12px",
    color: $gray600,
  },
  tableContainer: {
    marginTop: "-35px",
    zIndex: 100,
    position: "relative",
  },
});

const calloutItemStyle = mergeStyles({
  borderBottom: "1px solid #D9D9D9",
  padding: "3px",
});

export default React.memo(PAXTouchPointsGraphAndTable);
