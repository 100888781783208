import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import { MessageBarIntentType } from "../../../../enum/NotificationPanelEnum";

interface IConfirmDialogProps {
  id: string;
  onDismissNotification: any;
  title: string;
  intent: MessageBarIntentType;
}

export const ConfirmDialog: React.FunctionComponent<IConfirmDialogProps> = ({
  id,
  onDismissNotification,
  title,
  intent,
}) => {
  const isAlert = intent === MessageBarIntentType.ERROR;
  const alertText = isAlert ? "alert" : "notification";
  return (
    <Dialog modalType="alert">
      <DialogTrigger disableButtonEnhancement>
        <Button
          icon={<Dismiss12Regular />}
          appearance="transparent"
          aria-label="dialog-confirm"
        />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>Do you wish to remove this {alertText}?</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              aria-label="dismiss"
              onClick={() => onDismissNotification(id)}
            >
              Remove {alertText}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
