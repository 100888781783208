import React from "react";

export const WindIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.21875 6.375C1.08203 6.375 1 6.29297 1 6.15625V5.71875C1 5.60938 1.08203 5.5 1.21875 5.5H10.625C11.7188 5.5 12.5664 4.48828 12.3203 3.33984C12.1562 2.71094 11.6641 2.19141 11.0352 2.05469C9.94141 1.80859 8.98438 2.54688 8.875 3.55859C8.84766 3.66797 8.76562 3.75 8.65625 3.75H8.21875C8.08203 3.75 7.97266 3.66797 8 3.53125C8.13672 2 9.55859 0.851562 11.1719 1.20703C12.1562 1.39844 12.9766 2.19141 13.168 3.20312C13.5234 4.89844 12.2383 6.375 10.625 6.375H1.21875ZM5.26562 7.25C6.6875 7.25 7.94531 8.37109 8 9.82031C8.02734 11.2969 6.85156 12.5273 5.375 12.5273C3.98047 12.5273 2.85938 11.4609 2.75 10.1211C2.75 9.98438 2.83203 9.90234 2.96875 9.90234H3.40625C3.51562 9.90234 3.59766 9.98438 3.625 10.0938C3.73438 10.9688 4.47266 11.625 5.34766 11.625C6.38672 11.625 7.20703 10.75 7.09766 9.71094C7.01562 8.80859 6.19531 8.125 5.29297 8.125H1.21875C1.08203 8.125 1 8.04297 1 7.90625V7.46875C1 7.35938 1.08203 7.25 1.21875 7.25H5.26562ZM11.8008 7.25C13.4688 7.25 14.9453 8.5625 15 10.2305C15.0273 11.9531 13.6602 13.375 11.9375 13.375C10.4883 13.375 9.25781 12.3633 8.95703 11.0234C8.92969 10.8867 9.03906 10.75 9.17578 10.75H9.61328C9.72266 10.75 9.80469 10.832 9.83203 10.9141C10.0781 11.8438 10.9258 12.5 11.9375 12.5C13.1953 12.5 14.2344 11.4062 14.0977 10.0938C13.9883 8.97266 12.9766 8.125 11.8281 8.125H8.38281C8.19141 7.79688 7.94531 7.52344 7.64453 7.25H11.8008Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};
