import { PublicClientApplication } from "@azure/msal-browser";
import HOPEnum from "../../enum/HOPEnum";
import { IHOPKpi } from "../../models/health-of-operations/IHOPSummary";
import IAirportSummary from "../../models/multi-airports/IAirportSummary";
import MultiAirportService from "../../services/multi-airport/multiairport.service";
import { APIHelper } from "../api/APIHelper";

export abstract class MultiAirportHelper {
  public static async getAirports(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const multiAirportService = new MultiAirportService();
    const getAirportsMethod = (accessToken: string) => {
      return multiAirportService.getAirports(accessToken);
    };

    return APIHelper.CallAPI(msalInstance, null, getAirportsMethod);
  }

  public static getKPI(airport: IAirportSummary) {
    let kpi = undefined;
    const filteredDimensions = airport?.dimensions?.current?.filter(
      (dimension: any) => {
        return dimension.name === HOPEnum.HOPDIMENSIONS.OTP;
      }
    );

    if (filteredDimensions?.length > 0) {
      const { kpis } = filteredDimensions[0];
      const filteredKpis = kpis.filter((kpi: IHOPKpi) => {
        return kpi.key === "all";
      });

      if (filteredKpis?.length > 0) {
        kpi = filteredKpis[0];
      }
    }

    return kpi;
  }

  public static searchAirportById = (
    selectedAirportId: string,
    airportList?: IAirportSummary[]
  ) => {
    const selectedAirport = airportList?.find(
      (airport: IAirportSummary) => airport.id === selectedAirportId
    );
    window.localStorage.setItem("apocSelectedAirportId", selectedAirportId);
    return selectedAirport;
  };

  public static searchAirports = (
    searchText: string,
    airportList?: IAirportSummary[]
  ) => {
    const query = searchText.toLowerCase();
    return airportList?.filter((airport: IAirportSummary) => {
      return (
        airport.name.toLowerCase().startsWith(query) ||
        airport.code.toLowerCase().startsWith(query) ||
        airport.location.toLowerCase().startsWith(query)
      );
    });
  };
}
