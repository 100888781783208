import { mergeStyleSets } from "@fluentui/react";
import React from "react";

export interface IResourcesLegendProps {
  legendName?: string;
  legendColor?: string;
  legendValue?: string;
  className?: string;
}

export const ResourcesLegend: React.FunctionComponent<IResourcesLegendProps> = ({
  className= "",
  ...props
}) => {
  const legendStyles = mergeStyleSets({
    container: {
      width: "calc(100% - 60px)",
      marginBottom: 5,
      padding: "0 30px",
    },
    legend: {
      width: "10px",
      height: "10px",
      marginRight: "5px",
      background: props.legendColor,
      display: "inline-block",
    },
    text: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#4D4D4D",
    },
  });

  return (
    <div
      data-testid="resource-legend"
      className={`${legendStyles.container} ${className}`}
    >
      <span data-testid="legend-style" className={legendStyles.legend}></span>
      <span className={legendStyles.text}>{props.legendName}</span>
      <span className={`${legendStyles.text} fw-600 ml-sm-1`}>
        {props.legendValue}
      </span>
    </div>
  );
};
