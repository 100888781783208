export enum OTP {
  Column = "otpColumn",
  Operator = "otpOperator",
  Values = "otpValues",
  Name = "OTP",
}

const otpFieldControlDetails = {
  column: {
    label: "Column",
    name: OTP.Name,
    fieldName: OTP.Column,
    isMandatory: false,
  },
  operator: {
    label: "Operator",
    fieldName: OTP.Operator,
    isMandatory: true,
    inputType: "number",
  },
  values: {
    label: "Value(s)",
    fieldName: OTP.Values,
    isMandatory: false,
    inputType: "text",
    suffix: "%",
  },
};

const MultiAirportFilterColumnsDetails = {
  [OTP.Name]: {
    fieldControls: OTP,
    fieldControlDetails: otpFieldControlDetails,
  },
};

const MultiAirportFilterColumns = [OTP.Name];

const MultiAirportFilterEnum = {
  filterColumns: MultiAirportFilterColumns,
  filterColumnsDetails: MultiAirportFilterColumnsDetails,
};

export default MultiAirportFilterEnum;
