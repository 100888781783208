import { PublicClientApplication } from "@azure/msal-browser";
import * as microsoftTeams from "@microsoft/teams-js";
import AIRPORT_CONFIG from "../../mock/airport-data/airport-config.json";
import IAirportConfig from "../../models/airports/AirportConfig";
import { getGMTOffset, getTimeDifference } from "./DateHelperFunctions";

export function getAirportConfig(airportCode: string): IAirportConfig {
  const airportConfiguration = AIRPORT_CONFIG?.data.find(
    (config: { name: string }) => config.name === airportCode
  );
  if (!airportConfiguration) {
    return {
      name: "XYZ",
      hardCodedDates: false,
      timeZone: "Europe/Berlin",
    };
  }
  return airportConfiguration;
}

export function navigateToComponent(
  url: string,
  scrollId: string,
  entityId: string
) {
  if (scrollId) {
    window.sessionStorage.setItem("scrollId", scrollId);
  } else {
    window.sessionStorage.removeItem("scrollId");
  }

  if (window?.location?.ancestorOrigins?.length > 0) {
    const teamsAppId = process.env.REACT_APP_TEAMSAPP_ID;
    microsoftTeams.executeDeepLink(
      `https://teams.microsoft.com/l/entity/${teamsAppId}/${entityId}?webUrl=${url}`
    );
  } else {
    window.location.href = url;
  }
}

function isInTeams() {
  // eslint-disable-next-line dot-notation
  const microsoftTeamsLib = microsoftTeams || (window as any)["microsoftTeams"];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  if (
    (window.parent === window.self && (window as any).nativeInterface) ||
    window.name === "embedded-page-container" ||
    window.name === "extension-tab-frame"
  ) {
    return true;
  }
  return false;
}

export async function getLoggedInUser(
  msTeamsLib: any,
  msalInstance: PublicClientApplication
) {
  const isInTeamsContext = isInTeams();

  if (isInTeamsContext) {
    return new Promise((resolve, _reject) => {
      msTeamsLib.getContext((context: any) => {
        resolve(context.userPrincipalName);
      });
    });
  } else {
    return msalInstance.getActiveAccount()?.username;
  }
}

/**
 * This function is use to add time zone difference property wrt local time in each airport in the airport list
 * @param airportList - List of airports
 * @returns Updated airport list with time zone difference
 */
export function addTimeZoneDiff(airportList: any) {
  const gmtOffset = getGMTOffset(new Date());
  return airportList.map((airport: any) => {
    let timeZoneDiff;
    if (airport.timeZone === gmtOffset) {
      timeZoneDiff = null;
    } else {
      const { hours, minutes, sign } = getTimeDifference(
        gmtOffset,
        airport.timeZone
      );
      timeZoneDiff = { hours, minutes, sign };
    }
    return {
      ...airport,
      timeZoneDiff,
    };
  });
}

export function getIpadLandscapeClass(isLandscapeIpad: boolean) {
  return isLandscapeIpad ? "amd-main-ipad-landscape" : "amd-main";
}
