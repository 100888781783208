import {
  IStackProps,
  Label,
  Spinner,
  SpinnerSize,
  Stack,
  mergeStyleSets,
} from "@fluentui/react";

export interface ILoadingScreenProps {
  message: string;
  spinClassName?: string;
  labelClassName?: string;
  spinSize?: SpinnerSize;
}

export const LoadingScreen: React.FunctionComponent<ILoadingScreenProps> = (
  props: ILoadingScreenProps
) => {
  return (
    <Stack
      className={props.spinClassName ? props.spinClassName : contentStyles.spin}
      tokens={tokens.sectionStack}
    >
      <Stack {...rowProps} tokens={tokens.spinnerStack}>
        <Label
          className={
            props.labelClassName ? props.labelClassName : contentStyles.label
          }
        >
          {props.message}
        </Label>
        <Spinner size={props.spinSize ? props.spinSize : SpinnerSize.large} />
      </Stack>
    </Stack>
  );
};

const rowProps: IStackProps = { horizontal: true, verticalAlign: "center" };
const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  spinnerStack: {
    childrenGap: 0,
  },
};

// Define the Spinner style
const contentStyles = mergeStyleSets({
  spin: {
    display: "flex",
    justifyContent: "center",
    height: "inherit",
    margin: "auto",
    position: "fixed",
    top: "35%",
    left: "45%",
  },
  label: {
    fontSize: "18px",
  },
});
