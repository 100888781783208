import React from "react";

export const AmdEvent: React.FunctionComponent = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.995 6.06558C20.8993 4.35645 19.4831 3 17.7501 3H6.2501L6.055 3.00576C4.76406 3.0822 3.67586 3.91215 3.22383 5.06188C3.39265 5.02142 3.56887 5 3.7501 5C4.9928 5 6.0002 6.0074 6.0002 7.2501C6.0002 8.4928 4.9928 9.5002 3.7501 9.5002C3.48702 9.5002 3.23448 9.45505 2.99981 9.37208L3.0001 17.75L3.00525 17.9344C3.10086 19.6435 4.51707 21 6.2501 21L12.0218 21.0012C11.5929 20.331 11.2835 19.5769 11.1239 18.7689C11.0427 18.3585 11.0001 17.9342 11.0001 17.5C11.0001 16.419 11.264 15.3997 11.7308 14.5029L8.74786 14.5031L8.64609 14.4962C8.28002 14.4465 7.99786 14.1327 7.99786 13.7531C7.99786 13.3734 8.28002 13.0596 8.64609 13.0099L8.74786 13.0031L12.8066 13.0032C13.9897 11.7687 15.6551 11 17.5001 11C18.789 11 19.9903 11.3752 21.0008 12.0223L21.0001 6.25L20.995 6.06558ZM8.74786 9.49623H15.2523C15.6666 9.49623 16.0023 9.83201 16.0023 10.2462C16.0023 10.6259 15.7202 10.9397 15.3541 10.9894L15.2523 10.9962H8.74786L8.64609 10.9894C8.28002 10.9397 7.99786 10.6259 7.99786 10.2462C7.99786 9.86653 8.28002 9.55274 8.64609 9.50307L8.74786 9.49623ZM5.0002 7.2501C5.0002 6.55969 4.44051 6 3.7501 6C3.05969 6 2.5 6.55969 2.5 7.2501C2.5 7.94051 3.05969 8.5002 3.7501 8.5002C4.44051 8.5002 5.0002 7.94051 5.0002 7.2501Z"
        fill="#005EB8"
      />
      <path
        d="M23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12C20.5376 12 23 14.4624 23 17.5ZM18.095 14.2007C17.9077 13.5998 17.0923 13.5998 16.905 14.2007L16.3082 16.1154H14.3769C13.7708 16.1154 13.5188 16.9238 14.0091 17.2952L15.5716 18.4786L14.9748 20.3933C14.7875 20.9942 15.4472 21.4938 15.9375 21.1225L17.5 19.9392L19.0625 21.1225C19.5528 21.4938 20.2125 20.9942 20.0252 20.3933L19.4284 18.4786L20.9909 17.2952C21.4812 16.9238 21.2292 16.1154 20.6231 16.1154H18.6918L18.095 14.2007Z"
        fill="#005EB8"
      />
    </svg>
  );
};
