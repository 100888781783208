import { useAppSelector } from "../../store/StoreHooks";
import { getAirportCode } from "../../store/airport/AirportSelector";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";

export const Baggage: React.FunctionComponent = () => {
  const airport = useAppSelector((state) => getAirportCode(state));
  return (
    <>
      <NotificationBanner airport={airport} />
      <h1 className="amd-heading1">Baggage</h1>
      <section className="amd-section-shadow my-4">Coming soon...</section>
    </>
  );
};
