import IFilters from "../../models/filters/IFilters";
import { IFlightScheduleFilters } from "../../models/flights/IFlightScheduleFilters";
import { AmdButton } from "../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdDismiss } from "../common/icons/dismiss/dismiss";
import { FilterPanel } from "../filters/filter-panel/FilterPanel";
import { FlightDetailList } from "../flights/flights-list/FlightDetailList";
import { ResourceGraph } from "./ResourceGraph";

export const ResourceAllocationListContainer: React.FunctionComponent<
  IResourceAllocationListContainerProps
> = ({
  onFiltersApplied,
  onUpdateFilter,
  resourceFilters,
  flightListState,
  selectedType,
  onResetGraphicClick,
  onDataPointClick,
  onLegendClick,
  selectedResourceTab,
  airport,
}) => {
  return (
    <>
      <div
        className="resourceList"
        data-testid="ResourceAllocationListContainer"
      >
        <FilterPanel
          onFiltersApplied={onFiltersApplied}
          onUpdateFilter={onUpdateFilter}
          filters={resourceFilters}
          airport={airport}
        />
        <FlightDetailList
          flightListState={flightListState}
          selectedType={selectedType}
          viewType={selectedResourceTab ?? ""}
          selectedResource={selectedResourceTab}
          airport={airport}
        />
        <span className="d-iblock float-r mt-2">
          <AmdButton
            type={AmdButtonType.DEFAULT}
            className="mr-1"
            onClick={onResetGraphicClick}
          >
            <>
              <span>Reset Graphics</span>
              <AmdDismiss className="ml-1" />
            </>
          </AmdButton>
        </span>
        <ResourceGraph
          flightGraphState={flightListState}
          resource={selectedResourceTab as string}
          onDataPointClick={onDataPointClick}
          onLegendClick={onLegendClick}
          selectedType={selectedType}
          airport={airport}
        />
      </div>
    </>
  );
};

export interface IResourceAllocationListContainerProps {
  onFiltersApplied: any;
  onUpdateFilter: any;
  resourceFilters: IFilters[];
  flightListState: IFlightScheduleFilters;
  selectedType: string;
  onResetGraphicClick: any;
  onDataPointClick: any;
  onLegendClick?: any;
  selectedResourceTab?: string;
  airport: string;
}
