enum LegType {
  All = "arrival;departure",
  Arrival = "arrival",
  Departure = "departure",
  Mixed = "mixed",
}

enum ViewType {
  Flight = "flight",
  OTP = "otp",
  Baggage = "baggage",
}

enum TrafficType {
  International = "International",
  Domestic = "Domestic",
}

const FlightsEnum = {
  LegType,
  ViewType,
  TrafficType,
};

export default FlightsEnum;
