import { Button, makeStyles } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import MultiAirportFilterEnum from "../../enum/local-filters/MultiAirportFilterEnum";
import { AmdFilterFunnel } from "../common/icons/filter/FilterFunnel";
import QueryFilterPopUp from "../filters/query-type-filter/QueryFilterPopUp";

interface IMultiAirportFilterProps {
  appliedFilters: any;
  onApplyFilter: (data: any) => void;
  isMapView: boolean;
}

const MultiAirportFilter: React.FC<IMultiAirportFilterProps> = (
  props: IMultiAirportFilterProps
) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const classes = useStyles();

  return (
    <div data-testid="multi-airport-filter" className={`${classes.filterButton} ${props.isMapView && classes.filterIconMapView }`}>
      <Button
        className={classes.filterIcon}
        onClick={showModal}
        icon={{
          as: "span",
          children: <AmdFilterFunnel />,
        }}
      >
        Filters
      </Button>

      <QueryFilterPopUp
        label="Local filters"
        hideDialog={!isModalOpen}
        filterSettings={MultiAirportFilterEnum}
        onApplyFilter={props.onApplyFilter}
        appliedFilters={props.appliedFilters}
        onModalPopupClose={hideModal}
      />
    </div>
  );
};

export default MultiAirportFilter;

const useStyles = makeStyles({
  filterIcon: {
    border: "1px solid #0C66E1",
    padding: "5px 0 6px 0",
    margin: "0 0 0 10px",
    color: "#0C66E1",
  },
  filterIconMapView: {
    margin: "0 8px",
  },
  filterButton: {
    alignSelf: "flex-end",
  },
});
