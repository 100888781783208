import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../StoreHooks";

// Original selector
const selectPaxFilters = (state: RootState) => state.paxFilters?.paxFilters || [];

// Memoized selector
export const getMemoizedPaxFilters = createSelector(
  [selectPaxFilters],
  (paxFilters) => {
    // Perform a transformation to ensure memoization
    return [...paxFilters];
  }
);