import { Spinner } from "@fluentui/react-components";
import { Fragment } from "react";
import { useApiCall } from "../../../hooks/useApiCall";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { WEATHER_KPI_CARD_KEYS } from "../../../utils/weather/WeatherConstants";
import { WeatherHelper } from "../../../utils/weather/WeatherHelper";
import Card from "../../common/card/card";
import { NoDataCard } from "../../common/no-data-card/NoDataCard";
import { WeatherKPICard } from "./WeatherKPICard";

export interface IWeatherKPIProps {
  airport: string;
}

const WeatherKPI: React.FunctionComponent<IWeatherKPIProps> = ({ airport }) => {
  const getCurrentWeatherSummaryUrl = `${process.env.REACT_APP_API_WEATHER_SUMMARY?.replace(
    "{airportCode}",
    airport
  )}`;

  const {
    data: currentWeatherSummaryData,
    error,
    isLoading,
  } = useApiCall<any>({
    url: getCurrentWeatherSummaryUrl,
    dependencies: [airport],
  });

  const weatherCardArray = currentWeatherSummaryData
    ? WeatherHelper.getWeatherCardArray(
        currentWeatherSummaryData,
        WEATHER_KPI_CARD_KEYS
      )
    : [];

  const weatherCardContent = weatherCardArray?.map((array) => (
    <Fragment key={`section-key-${CommonHelper.getGuid()}`}>
      {array.map((item, i) => (
        <Card
          key={`card-${item.displayName}`}
          dataTestId={`card-${item.displayName}`}
          width={"auto"}
          height={144}
          className={`mb-2 ${(i + 1) % 4 === 0 ? "" : "mr-2"}`}
          header={<span>{item.displayName}</span>}
          headerType="default_header"
          headerAlignment="center"
          bodyType="default_body"
          footerType="default_footer"
          footer={
            <div className="w-100 amd-container-hspacebtwn">
              <div>
                <div>{item.leftFooterBody}</div>
                <div>{item.leftFooterSubBody}</div>
              </div>
              <div>
                <div>{item.rightFooterBody}</div>
                <div>{item.rightFooterSubBody}</div>
              </div>
            </div>
          }
        >
          <WeatherKPICard
            key={`kpicard-${item.displayName}`}
            displayName={item.displayName}
            body={item.body}
            unit={item.unit}
            subBody={item.subBody}
          />
        </Card>
      ))}
    </Fragment>
  ));

  return (
    <>
      {isLoading && <Spinner labelPosition="below" label="Loading..." />}
      {error && <NoDataCard message="No Weather data found" />}
      <div data-testid="weather-kpi-container" className="amd-grid-col4">
        {!error && !isLoading && weatherCardContent}
      </div>
    </>
  );
};

export default WeatherKPI;
