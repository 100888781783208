import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { MessageBar } from "@fluentui/react";
import * as microsoftTeams from "@microsoft/teams-js";
import React, { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../../context/NotificationContext";
import INotification, {
  INotificationItem,
} from "../../../models/notification/INotification";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { TABLET_MODE } from "../../../utils/common/Constants";
import { NotificationHelper } from "../../../utils/notification/NotificationHelper";
import { TabletViewAlert } from "../tablet-view-alert/TabletViewAlert";
import { AmdToastAlerts } from "../toast-alerts/ToastAlerts";
export const NotificationBanner: React.FunctionComponent<
  INotificationBannerProps
> = ({ airport }) => {
  const { notifications } = useContext(NotificationContext);
  const [notificationItems, setNotificationItems] = useState<
    INotificationItem[]
  >([]);
  const [newNotification, setNewNotificationItem] =
    useState<INotificationItem>();
  const [clientUrlData, setClientUrlData] = useState<any>();
  const [webSockets, setWebSocket] = useState<WebSocket[]>([]);
  const msalInstance = useMsal().instance as PublicClientApplication;
  const notificationsList: INotification[] =
    NotificationHelper.getNotificationList();
  const [isDeviceTablet, setIsDeviceTablet] = useState<boolean>(false);
  const [isDeviceInLandscape, setIsDeviceInLandscape] = useState<boolean>(true);
  const hubs = NotificationHelper.getPubSubHubs(window.location.pathname);

  useEffect(() => {
    let isMounted = true;
    const resultObj = NotificationHelper.getSockets(notificationsList);
    setWebSocket(resultObj.webSocket);
    const notifyItems = NotificationHelper.getAllSavedNotificationItems(
      notificationsList,
      hubs
    );
    setNotificationItems(notifyItems);

    if (resultObj.expiredHubList?.length > 0 && airport !== "") {
      NotificationHelper.getClientAccessUrl(
        microsoftTeams,
        msalInstance,
        resultObj.expiredHubList,
        airport
      )
        .then((response) => {
          if (isMounted && response && response.data) {
            setClientUrlData(response.data);
          }
        })
        .catch((_error) => {
          console.log("error occured");
        });
    }

    if (
      window.matchMedia &&
      window.matchMedia(TABLET_MODE) &&
      window.matchMedia(TABLET_MODE).addEventListener
    ) {
      setIsDeviceInLandscape(window.matchMedia(TABLET_MODE)?.matches);

      const agent = window.navigator.userAgent.toLowerCase();
      setIsDeviceTablet(CommonHelper.isTablet(agent));

      window.matchMedia(TABLET_MODE).addEventListener("change", (e) => {
        const landscape = e.matches;
        setIsDeviceInLandscape(CommonHelper.IsDeviceInLandscape(landscape));
      });
    }

    return () => {
      isMounted = false;
      webSockets.forEach((socket) => {
        socket.close();
      });
    };
  }, [airport]);

  useEffect(() => {
    if (clientUrlData) {
      const newWebSockets = NotificationHelper.getNewSockets(
        clientUrlData,
        notificationsList
      );
      setWebSocket(webSockets.concat(newWebSockets));
    }
  }, [clientUrlData]);

  useEffect(() => {
    if (newNotification) {
      setNotificationItems((notificationItems) => [
        ...notificationItems,
        newNotification,
      ]);
    }
  }, [newNotification]);

  useEffect(() => {
    if (webSockets && webSockets.length > 0) {
      webSockets.forEach((socket) => {
        socket.addEventListener("message", (event) => {
          const currentTarget = event.target as WebSocket;
          const hubName = currentTarget.url.split("hubs/")[1].split("?")[0];
          const message = JSON.parse(event.data);
          const newNotification = NotificationHelper.processEvent(
            hubName,
            notificationItems,
            notificationsList,
            message
          );

          if (hubs.includes(hubName.toLowerCase()) && newNotification.body) {
            setNewNotificationItem(newNotification);
          }
        });
      });
    }
  }, [webSockets]);

  const onSelectNotification = (
    index: number,
    item: INotificationItem,
    isRedirect = false
  ) => {
    NotificationHelper.removeNotifications(notificationsList, item, isRedirect);
    setNotificationItems([
      ...notificationItems.slice(0, index),
      ...notificationItems.slice(index + 1),
    ]);
    window.scroll(0, 0);
  };

  return (
    <div data-testid="notification-container">
      <TabletViewAlert
        isDeviceTablet={isDeviceTablet}
        isDeviceInLandscape={isDeviceInLandscape}
      />
      {notifications.map((notification) => (
        <MessageBar
          key={CommonHelper.getGuid()}
          styles={messageBarStyles}
          messageBarType={CommonHelper.getMessageBarType(notification.type)}
          isMultiline={false}
        >
          {notification.message}
        </MessageBar>
      ))}
      <AmdToastAlerts
        alerts={notificationItems}
        onSelectNotification={onSelectNotification}
      />
    </div>
  );
};

const messageBarStyles = {
  root: {
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: { textAlign: "center", fontSize: "14px" },
};

export interface INotificationBannerProps {
  airport: string;
}
