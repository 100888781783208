import { ComboBox, IComboBoxOption, mergeStyles } from "@fluentui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CommonHelper } from "../../../../../utils/common/CommonHelper";

interface IAmdComboBoxProps {
  controlId: string;
  label: string;
  options: IComboBoxOption[];
  isMandatory: boolean;
  defaultValue?: any;
  className?: string;
  onChange?: any;
}

export const AmdComboBox: React.FunctionComponent<IAmdComboBoxProps> = (
  props: IAmdComboBoxProps
) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const onComboBoxChange = (fieldValue: any) => {
    if (props.onChange) {
      props.onChange(fieldValue);
    }
  };

  return (
    <Controller
      name={props.controlId}
      control={control}
      rules={{ required: props.isMandatory }}
      render={({ field }) => (
        <div className={comboBoxStyles}>
          <ComboBox
            {...field}
            label={props.label}
            required={props.isMandatory}
            options={props.options}
            className={props.className}
            defaultSelectedKey={
              props.defaultValue ? props.defaultValue.key : undefined
            }
            onChange={(_event, checked) => {
              onComboBoxChange(checked);
              field.onChange(checked);
            }}
            errorMessage={CommonHelper.requiredFieldValidation(
              errors,
              props.controlId
            )}
          />
        </div>
      )}
    />
  );
};

const comboBoxStyles = mergeStyles({
  marginTop: "0px !important",
});
