import {
  DefaultButton,
  DialogFooter,
  FontWeights,
  IStackProps,
  PrimaryButton,
  Stack,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";
import * as React from "react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AdminEnum from "../../../enum/AdminEnum";
import TagPickerEnum from "../../../enum/TagPickerEnum";
import { IOrganizationItem } from "../../../models/admin/IOrganizationItem";
import { AdminHelper } from "../../../utils/admin/AdminHelper";
import { AmdTagPickControl } from "../../common/form-controls/controlled/TagPicker/AmdTagPickControl";
import { AmdTextField } from "../../common/form-controls/controlled/TextField/AmdTextField";
import { AmdModal } from "../../common/form-controls/uncontrolled/Modal/AmdModal";

export interface IAddOrganizationPopupProps {
  hideDialog: any;
  onModalPopupClose: any;
  onOrganizationAdded?: any;
  selectedOrganizationId?: string;
  selectedOrganization?: IOrganizationItem;
  airport: string;
}

export const AddOrganizationPopup: React.FunctionComponent<
  IAddOrganizationPopupProps
> = (props: IAddOrganizationPopupProps) => {
  const methods = useForm();

  useEffect(() => {
    // reset default field values
    methods.reset(props?.selectedOrganization);
  }, []);

  const onAddOrganization = (data: any) => {
    console.log(data);
    // Reset the form field values and close the popup.
    methods.reset(formFieldsData);
    props.onModalPopupClose();

    if (props.onOrganizationAdded) {
      props.onOrganizationAdded(props.selectedOrganizationId, data);
    }
  };

  const onErrors = (errors: any, _e: any) => {
    console.log(errors);
  };

  return (
    <div data-testid="addOrganizationPopup">
      <AmdModal
        isOpen={!props.hideDialog}
        header="Add organization"
        onClose={props.onModalPopupClose}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.body}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onAddOrganization, onErrors)}
              noValidate
            >
              <Stack horizontal tokens={stackTokens}>
                <Stack {...columnProps}>
                  <AmdTextField
                    controlId="organizationName"
                    label="Name"
                    isMandatory={true}
                    isMultiline={false}
                    defaultValue={props.selectedOrganization?.name}
                  />

                  <AmdTagPickControl
                    controlId={AdminEnum.User.Roles}
                    tagLabel="Roles"
                    isMandatory={false}
                    suggestionType={TagPickerEnum.TagPickerType.Roles}
                    airport={props.airport}
                    selectedItems={AdminHelper.getRolesTags(
                      props.selectedOrganization
                        ? [
                            {
                              key: props.selectedOrganization?.id,
                              name: props.selectedOrganization?.roles,
                            },
                          ]
                        : []
                    )}
                  />
                </Stack>
              </Stack>
              <DialogFooter>
                <DefaultButton
                  onClick={() => {
                    props.onModalPopupClose();
                  }}
                  text="Cancel"
                />
                <PrimaryButton type="submit" text="Add" />
              </DialogFooter>
            </form>
          </FormProvider>
        </div>
      </AmdModal>
    </div>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "35vh",
    width: "40vw",
  },
  header: [
    theme.fonts.xLarge,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

const stackTokens = { childrenGap: 2, root: { width: "100%" } };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: "90vw" } },
};

const formFieldsData = {};
