import React from "react";
import { FontWeights, getTheme, mergeStyleSets } from "@fluentui/react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
} from "@fluentui/react-components";
import { ChevronDownRegular, ChevronUpRegular } from "@fluentui/react-icons";
import * as microsoftTeams from "@microsoft/teams-js";
import PageEnum from "../../../../enum/PageEnum";
import NOTIFICATIONS_MOCK_DATA from "../../../../mock/notification/notification-panel-list.json";
import INotification, {
  INotificationItem,
} from "../../../../models/notification/INotification";
import { NotificationHelper } from "../../../../utils/notification/NotificationHelper";
import {
  getAllSavedNotificationItems,
  getClientAccessUrl,
  getNewNotification,
  getNewSockets,
  getSockets,
  mappedNotificationsArray,
  numAlerts,
  numNotifications,
  removeObjectWithId,
  sortNotifications,
} from "../../../../utils/notification/NotificationHelperFunctions";

import { MessageBarPanel } from "./MessageBarPanel";
import { INotificationPanelProps } from "./types";
import { navigateToComponent } from "../../../../utils/common/CommonHelperFunctions";
import { BellIcon } from "../../../common/icons/bell/BellIcon";
import { InfoCircleIcon } from "../../../common/icons/info-circle/InfoCircleIcon";
import { $cloud30 } from "../../../../cssjs/colors";

const IconToggle = ({
  direction,
}: {
  direction?: boolean;
}): React.JSX.Element => (
  <span className={`${contentStyles.iconColor} amd-container-vcenter`}>
    {direction ? <ChevronDownRegular /> : <ChevronUpRegular />}
    <div className={`${contentStyles.iconText} fs-14`}>
      {" "}
      {direction ? "View more" : "View less"}
    </div>
  </span>
);

const getNotificationCount = (notificationCount: number, notificationApiCount: number) => {
  const totalNotificationCount = notificationCount + notificationApiCount;
  return totalNotificationCount === 1
    ? `${totalNotificationCount} notification`
    : `${totalNotificationCount} notifications`;
};

const getAlertCount = (alertCount: number, alertApiCount: number) => {
  const totalAlertCount = alertCount + alertApiCount;
  return totalAlertCount === 1
    ? `${totalAlertCount} alert`
    : `${totalAlertCount} alerts`;
};

export const NotificationPanel: React.FunctionComponent<INotificationPanelProps> = ({ airport, home = false }): React.JSX.Element => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const hubs = NotificationHelper.getPubSubHubs(window.location.pathname);
  const [notificationItems, setNotificationItems] = React.useState<INotificationItem[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [mockNotificationItems, setMockNotifications] = React.useState<INotificationItem[]>(NOTIFICATIONS_MOCK_DATA.data);
  const [newNotification, setNewNotificationItem] = React.useState<INotificationItem>();
  const [clientUrlData, setClientUrlData] = React.useState<any>();
  const [webSockets, setWebSocket] = React.useState<WebSocket[]>([]);
  const notificationsList: INotification[] = NotificationHelper.getNotificationList();

  const clickHandler = (): void => setOpen((v) => !v);

  React.useEffect(() => {
    let isMounted = true;

    const initialize = async () => {
      const resultObj = getSockets(notificationsList);
      setWebSocket(resultObj.webSocket);

      setNotificationItems(getAllSavedNotificationItems(notificationsList, hubs));

      if (resultObj.expiredHubList?.length > 0 && airport !== "") {
        try {
          const response = await getClientAccessUrl(microsoftTeams, msalInstance, resultObj.expiredHubList, airport);
          if (isMounted && response?.data) { setClientUrlData(response.data); }
        } catch (_error) {
          console.log("error occurred");
        }
      }
    };

    initialize();

    return () => {
      isMounted = false;
      webSockets.forEach((socket) => socket.close());
    };
  }, [airport]);

  React.useEffect(() => {
    if (clientUrlData) {
      setWebSocket((prev) => prev.concat(getNewSockets(clientUrlData, notificationsList)));
    }
  }, [clientUrlData]);

  React.useEffect(() => {
    if (newNotification) {
      setNotificationItems((prev) => [...prev, newNotification]);
    }
  }, [newNotification]);

  React.useEffect(() => {
    if (webSockets?.length > 0) {
      webSockets.forEach((socket) => {
        socket.addEventListener("message", (event) => {
          const { newNotification, hubName } = getNewNotification(event, notificationItems, notificationsList);
          if (hubs.includes(hubName.toLowerCase()) && newNotification.body) {
            setNewNotificationItem(newNotification);
          }
        });
      });
    }
  }, [webSockets]);

  const updatedMockNotificationsArray = mappedNotificationsArray(mockNotificationItems);
  const updatedApiNotificationsArray = mappedNotificationsArray(notificationItems).map((item) => ({
    ...item,
    actions: { dismiss: true, create: true },
  }));

  const sortedNotifications = sortNotifications(updatedMockNotificationsArray);
  const alertCount = numAlerts(sortedNotifications);
  const alertApiCount = numAlerts(updatedApiNotificationsArray);
  const notificationCount = numNotifications(sortedNotifications);
  const notificationApiCount = numNotifications(updatedApiNotificationsArray);

  const onDismissNotification = (_index: number, _item: INotificationItem, id: string) => {
    setMockNotifications(removeObjectWithId(sortedNotifications, id));
    window.scroll(0, 0);
  };

  const onDismissAPINotification = (index: number, item: INotificationItem, isRedirect = false) => {
    NotificationHelper.removeNotifications(notificationsList, item, isRedirect);
    setNotificationItems((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    window.scroll(0, 0);
  };

  const notificationTextCount = getNotificationCount(notificationCount, notificationApiCount);
  const alertTextCount = getAlertCount(alertCount, alertApiCount);

  return (
    <Accordion collapsible className={`${home ? 'amd-section-shadow mb-2' : '' } br-4 pl-0 pr-0 pt-0 pb-0 ${contentStyles.accordionContainer} ${home && contentStyles.accordionHomeContainer}`}>
      <AccordionItem value={1}>
        <AccordionHeader
          icon={{ children: <InfoCircleIcon />, className: `${contentStyles.infoIcon}` }}
          onClick={clickHandler}
          expandIconPosition="end"
          expandIcon={!open ? <IconToggle direction={true} /> : <IconToggle />}
        >
          <div className={`${contentStyles.bodyText}`}>
            You have <span className={`${contentStyles.alertText}`}> {alertTextCount}</span> and <span className={`${contentStyles.notificationText}`}>{notificationTextCount}</span>
          </div>
        </AccordionHeader>
        <AccordionPanel className={`pb-2 ${contentStyles.accordionPanelContainer}`}>
          {updatedApiNotificationsArray.map((item, index) => (
            <MessageBarPanel key={item.id} index={index} item={item} onDismissNotification={onDismissAPINotification} />
          ))}
          {updatedMockNotificationsArray.map((item) => (
            <MessageBarPanel key={item.id} item={item} onDismissNotification={onDismissNotification} />
          ))}
          <Button
            className={contentStyles.viewAll}
            icon={<BellIcon />}
            iconPosition="before"
            onClick={() => navigateToComponent(`/${PageEnum.PageUrl.Notifications}`, "", PageEnum.PageUrl.Notifications)}
          >
            View all notifications
          </Button>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  accordionContainer: {
    background: `${theme.palette.white}`,
    borderRadius: "8px",
    padding: 0,
    border: `1px solid ${$cloud30}`,
  },
  accordionHomeContainer: {
    borderRadius: "4px",
    border: "none",
  },
  accordionPanelContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  infoIcon: {
    color: `${theme.palette.neutralTertiary}`,
  },
  iconText: {
    marginBottom: "2px",
    color: `${theme.palette.blue}`,
  },
  iconColor: {
    color: `${theme.palette.blue}`,
  },
  bodyText: {
    marginBottom: "2px",
  },
  alertText: {
    color: `${theme.palette.red}`,
    fontWeight: FontWeights.semibold,
  },
  notificationText: {
    color: `${theme.palette.blue}`,
    fontWeight: FontWeights.semibold,
  },
  viewAll: {
    ".fui-AccordionPanel &": {
      color: `${theme.palette.blue}`,
      borderColor: `${theme.palette.blue}`,
      ":hover": {
        color: `${theme.palette.blue}`,
        borderColor: `${theme.palette.blue}`,
        background: "#c5d5fa",
      },
    },
  },
  messageBarTitle: {
    color: theme.palette.black,
  },
});
