import { Spinner } from "@fluentui/react-components";
import { useApiCall } from "../../../hooks/useApiCall";
import IFlightSummary from "../../../models/flights/IFlightSummary";
import {
  AIRPORT_CODE_PARAMETER,
  DATE_PARAMETER,
  EMPTY_STATE_MESSAGE,
} from "../../../utils/common/Constants";
import DateHelper from "../../../utils/common/DateHelper";
import { FlightSummaryHelper } from "../../../utils/flights/FlightSummaryHelper";
import { OTPHelper } from "../../../utils/flights/OTPHelper";
import { NoDataCard } from "../../common/no-data-card/NoDataCard";
import { FlightsKPISection } from "./FlightsKPISection";

const getFlightKpiBaseUrls: string[] = [
  process.env.REACT_APP_API_FLIGHTSUMMARY || "",
  process.env.REACT_APP_API_OTPSUMMARY || "",
];

export const FlightsKPI: React.FunctionComponent<IFlightsKPI> = (
  props: IFlightsKPI
) => {
  const dateStr = DateHelper.getDefaultDateStrForAirport(props.airport);

  const getFlightKpiUrls: string[] = getFlightKpiBaseUrls.map((url) =>
    url
      .replace(AIRPORT_CODE_PARAMETER, props.airport)
      .replace(DATE_PARAMETER, dateStr)
  );

  const {
    data: getFlightKpiResponses,
    error,
    isLoading,
  } = useApiCall<any[]>({
    url: getFlightKpiUrls,
    dependencies: [props.airport],
  });

  let otpSummary: any = [];
  let resourceData = undefined;

  if (getFlightKpiResponses) {
    otpSummary = OTPHelper.getOTPChartData(
      getFlightKpiResponses?.[1].data?.all
    );

    const getFlightSummaryData: IFlightSummary[] =
      getFlightKpiResponses?.[0].data?.data;

    resourceData = {
      total: FlightSummaryHelper.getChartData(getFlightSummaryData, "total"),
      delayed: FlightSummaryHelper.getChartData(
        getFlightSummaryData,
        "delayed"
      ),
      cancelled: FlightSummaryHelper.getChartData(
        getFlightSummaryData,
        "cancelled"
      ),
    };
  }

  return (
    <>
      <div className="ms-Grid flights-kpi" dir="ltr" data-testid="flights-kpi">
        {error && <NoDataCard message="No Flight data found" />}
        {isLoading && (
          <Spinner
            labelPosition="below"
            label="Loading..."
            style={{ height: "20vh" }}
          />
        )}
        {!error && !isLoading && (
          <FlightsKPISection
            resourceData={resourceData}
            emptyStateMessage={EMPTY_STATE_MESSAGE.Flights}
            displayOtp={props.displayOtp}
            otpResourceData={otpSummary}
          />
        )}
      </div>
    </>
  );
};

export interface IFlightsKPI {
  displayOtp: boolean;
  airport: string;
}
