import { PublicClientApplication } from "@azure/msal-browser";
import IFlightSummary from "../../models/flights/IFlightSummary";
import FlightSummaryService from "../../services/flight-summary/flightsummary.service";
import { APIHelper } from "../api/APIHelper";
import { CommonHelper } from "../common/CommonHelper";
import { FLIGHT_COLORS } from "../common/Constants";

export abstract class FlightSummaryHelper {
  public static async getFlightSummary(
    msalInstance: PublicClientApplication,
    airportCode: string
  ): Promise<any> {
    const flightService = new FlightSummaryService();
    const flightSummaryMethod = (accessToken: string) => {
      return flightService.getFlightSummary(accessToken, airportCode);
    };

    return APIHelper.CallAPI(msalInstance, null, flightSummaryMethod);
  }

  public static getTotalFlightCount(data: IFlightSummary[], key: string) {
    return data.reduce((a, b) => a + (b as { [k in string]: any })[key], 0);
  }

  public static getChartData(data: IFlightSummary[], key: string) {
    const chartData: any[] = [];
    const total = this.getTotalFlightCount(data, key);
    data?.forEach((item: any, index: number) => {
      const obj = {
        resourceName: CommonHelper.toTitleCase(key),
        resourceCount: (item as { [k in string]: any })[key],
        resourceTitle: CommonHelper.toTitleCase(item.type),
        resourceColor: (FLIGHT_COLORS as { [k in string]: any })[key][index],
        resourceTotal: total,
      };
      chartData.push(obj);
    });
    return chartData;
  }

  public static getFlightTotalSummary(data: IFlightSummary[]) {
    return {
      total: {
        count: FlightSummaryHelper.getTotalFlightCount(data, "total"),
        color: "fc--darkprimary",
      },
      delayed: {
        count: FlightSummaryHelper.getTotalFlightCount(data, "delayed"),
        color: "fc--cherry",
      },
      cancelled: {
        count: FlightSummaryHelper.getTotalFlightCount(data, "cancelled"),
        color: "fc--brown",
      },
    };
  }

  public static getResourceTitle(key: string) {
    if (key === "Total") {
      return key + " Flights";
    } else if (key === "Delayed") {
      return key + " (In-time)";
    } else {
      return key;
    }
  }

  public static getClassName(isDetailsPage: boolean, key: string) {
    if (key === "total") {
      return isDetailsPage
        ? "ms-md4 ms-xxl3 divider-v-grey divider-v-grey--hiddenXlDown"
        : "ms-md4";
    } else if (key === "delayed") {
      return isDetailsPage ? "ms-md4 ms-xxl3" : "ms-md4 divider-v-grey";
    } else if (key === "cancelled") {
      return isDetailsPage ? "ms-md4 ms-xxl3" : "ms-md4 divider-v-grey";
    }
    return "";
  }
}
