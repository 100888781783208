import { IColumn } from "@fluentui/react";
import { sortColumnByAlphaNumeric } from "./sortColumnByAlphaNumeric";
import { sortColumnByDate } from "./sortColumnByDate";

export interface IColumnExtended extends IColumn {
  key: string;
  isHeaderShowHide?: boolean | undefined;
  className?: string;
}

enum SortationColumnEnum {
  CreationDateTime = "creationDateTime",
  DelayDuration = "delayDuration",
  Terminal = "terminal",
  ArrivalFlightNumber = "arrivalFlightNumber",
  DepartureFlightNumber = "departureFlightNumber",
}

export abstract class CustomListHelper {
  public static getColumns(
    columns: any,
    sortAZText: string,
    sortZAText: string
  ) {
    return columns.map(
      (column: any, index: number) =>
        ({
          key: `column${index + 1}`,
          name: column.name,
          fieldName: column.fieldName,
          minWidth: column.minWidth,
          maxWidth: column.maxWidth,
          isRowHeader: column.isRowHeader,
          isResizable: true,
          isSorted: column.name ? true : false,
          isSortedDescending: false,
          sortAscendingAriaLabel: sortAZText,
          sortDescendingAriaLabel: sortZAText,
          data: column.dataType,
          isPadded: true,
          iconName: column.iconName ? column.iconName : "",
          onRender: column.onRender,
          isHeaderShowHide: column.isHeaderShowHide ? true : false,
          className: column.className,
        } as IColumnExtended)
    );
  }

  public static getInfoReasons(_infoReasons: any[], modalTitle: string) {
    if (modalTitle === "Delay Reasons") {
      _infoReasons = _infoReasons.map((r) => ({
        id: r.code,
        name: r.duration,
      }));
    }
    return _infoReasons;
  }

  public static copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending = false
  ): T[] {
    const key = columnKey as keyof T;
    const creationDateColumn = key === SortationColumnEnum.CreationDateTime;
    const alphaNumericColumns =
      Object.values(SortationColumnEnum).includes(
        columnKey as SortationColumnEnum
      ) && !creationDateColumn;

    if (creationDateColumn) {
      const sortedItems = sortColumnByDate(
        items,
        columnKey,
        isSortedDescending
      );
      return sortedItems?.length ? sortedItems : items;
    }

    if (alphaNumericColumns) {
      const sortedItems = sortColumnByAlphaNumeric(
        items,
        columnKey,
        isSortedDescending
      );
      return sortedItems?.length ? sortedItems : items;
    }

    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }

  public static getUpdatedStateObj({
    selectedColumn,
    columns,
    items,
  }: {
    selectedColumn: any;
    columns: any[];
    items: any[];
  }) {
    const newColumns: IColumn[] = columns?.slice();
    const currColumn: IColumn = newColumns?.filter(
      (currCol) => selectedColumn?.key === currCol?.key
    )[0];

    newColumns?.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn?.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    const newItems = this.copyAndSort(
      items,
      currColumn?.fieldName ? currColumn.fieldName : "",
      currColumn?.isSortedDescending
    );
    return {
      columns: newColumns,
      items: newItems,
    };
  }

  public static getUpdatedDefaultState({
    selectedColumn,
    columns,
    items,
    sortOveride = false,
  }: {
    selectedColumn: any;
    columns: any[];
    items: any[];
    sortOveride: boolean;
  }) {
    const newColumns: IColumn[] = columns?.slice();
    const currColumn: IColumn = newColumns?.filter(
      (currCol) => selectedColumn?.key === currCol?.key
    )[0];
    newColumns?.forEach((newCol) => {
      const isCurrent = newCol === currColumn;
      newCol.isSorted = isCurrent;
      newCol.isSortedDescending = sortOveride;

      if (isCurrent) {
        currColumn.isSorted = true;
      }
    });

    const newItems = this.copyAndSort(
      items,
      currColumn?.fieldName ? currColumn.fieldName : "",
      currColumn?.isSortedDescending
    );
    return {
      columns: newColumns,
      items: newItems,
    };
  }

  public static formatDelayDuration(_delayDuration: string) {
    const totalMinutes = parseInt(_delayDuration);
    if (totalMinutes === 60) {
      return "1 Hr";
    }
    if (totalMinutes < 60) {
      return `${totalMinutes} mins`;
    } else {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${hours === 1 ? hours + " Hr" : hours + " Hrs"} ${
        minutes === 1 ? minutes + " min" : minutes + " mins"
      }`;
    }
  }

  public static formatDateTime = (dateTime?: string) => {
    let formattedDateTime = "";
    if (dateTime) {
      const epocNumber: number = Date.parse(dateTime);
      const date = new Date(epocNumber);
      const hours =
        date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours();
      const minutes =
        date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes();
      formattedDateTime = `${hours}:${minutes}`;
    }

    return formattedDateTime;
  };
}
