import { IDropdownOption, ITag } from "@fluentui/react";
import {
  makeStyles,
  SearchBox,
  SearchBoxChangeEvent,
  TagGroupProps,
} from "@fluentui/react-components";
import React, { useState } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import MultiAirportFilterEnum from "../../enum/local-filters/MultiAirportFilterEnum";
import { DisplayFilterPills } from "../filters/query-type-filter/DisplayFilterPills";
import { AirportStatusTags } from "./AirportStatusTags";
import MultiAirportFilter from "./MultiAirportFilter";

export interface IAirportPerformance {
  filterAirport: (tagStatusType: string) => void;
  setAirportSearch: (matchedAirports: any) => void;
  airportStatusCount: any;
  sideBarActive: boolean;
  searchInputHandler: any;
}

export const AirportPerformance: React.FunctionComponent<
  IAirportPerformance
> = (props: IAirportPerformance) => {
  const { sideBarActive, searchInputHandler } = props;
  const [selectedOperator, setSelectedOperator] = useState<IDropdownOption>();
  const [filteredOtp, setFilteredOtp] = useState<ITag[]>();
  const { isMapView } = React.useContext(LayoutContext);
  const classes = useStyles();
  const isStatusTags = !isMapView || sideBarActive;

  const { filterColumns, filterColumnsDetails } = MultiAirportFilterEnum;

  const { fieldControls, fieldControlDetails } =
    filterColumnsDetails[filterColumns[0] as keyof typeof filterColumnsDetails];

  const appliedFilters = {
    [fieldControls.Name]: {
      operator: selectedOperator,
      values: filteredOtp,
    },
  };

  const filtersPills = filteredOtp
    ? [
        {
          key: fieldControls.Name,
          value: `${fieldControls.Name}: ${selectedOperator?.text} ${filteredOtp}${fieldControlDetails.values.suffix}`,
        },
      ]
    : [];

  const onApplyFilter = (data: any) => {
    setFilteredOtp(data[fieldControls.Values]);
    setSelectedOperator(data[fieldControls.Operator]);
  };

  const onTagClickHandler = (tagStatusType: string) => {
    props.filterAirport(tagStatusType);
  };

  const removeFilter: TagGroupProps["onDismiss"] = (_e, { value }) => {
    //Logic to remove selected filter
    if (value === fieldControls.Name) {
      setFilteredOtp(undefined);
      setSelectedOperator(undefined);
    }
  };

  const resetFilters = () => {
    //Logic to reset all applied filters
    setFilteredOtp(undefined);
    setSelectedOperator(undefined);
  };

  return (
    <div
      className={`${isMapView ? classes.mapView : "mb-2"}`}
      data-testid="airport-performance"
    >
      <section
        className={`amd-container-vcenter ${
          isMapView ? "amd-container-hend" : "jc-spacebtwn mt-2"
        }`}
      >
        <div
          className={`amd-container-vcenter ${classes.performanceFiltersLeft}`}
        >
          {!isMapView && (
            <SearchBox
              placeholder="Search airport"
              onChange={(event: SearchBoxChangeEvent) => {
                searchInputHandler(event);
              }}
              size="medium"
              appearance="filled-lighter"
              aria-label="search"
            />
          )}

          {isStatusTags && (
            <AirportStatusTags
              airportStatusCount={props.airportStatusCount}
              onTagClickHandler={onTagClickHandler}
            />
          )}
        </div>
        <MultiAirportFilter
          appliedFilters={appliedFilters}
          onApplyFilter={onApplyFilter}
          isMapView={isMapView}
        />
      </section>
      {filteredOtp && (
        <DisplayFilterPills
          tags={filtersPills}
          onDismiss={removeFilter}
          resetFilters={resetFilters}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  performanceFiltersLeft: {
    marginLeft: "1px",
  },
  mapView: {
    position: "relative",
    zIndex: 2,
  },
});
