import { Image } from "@fluentui/react";
import NoFlightsIcon from "../../common/images/no-flights.svg";

export const NoDataCard: React.FunctionComponent<IFlightNoDataProps> = (
  props: IFlightNoDataProps
) => {
  return (
    <div data-testid="no-data" className="text-c margin-auto">
      <Image style={noFlightsImageStyles} src={NoFlightsIcon}></Image>
      <div style={noFlightMessageStyles}>{props.message}</div>
    </div>
  );
};

export interface IFlightNoDataProps {
  message: string;
}

const noFlightsImageStyles: React.CSSProperties = {
  paddingTop: "25px",
  margin: "auto",
};

const noFlightMessageStyles: React.CSSProperties = {
  fontSize: "14x",
  fontWeight: "600",
  paddingTop: "10px",
};
