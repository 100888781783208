import { Link } from "@fluentui/react";
import { AmdArrow } from "../../components/common/icons/arrow/arrow";
import { ArrowDirection } from "../../components/common/icons/arrow/arrowDirection";
import AircraftSVG from "../../components/events/images/aircraft.svg";
import BaggageSVG from "../../components/events/images/baggage.svg";
import DeicingSVG from "../../components/events/images/deicing.svg";
import DepartureSVG from "../../components/events/images/departure.svg";
import PassengerSVG from "../../components/events/images/passenger.svg";
import WeatherSVG from "../../components/events/images/weather.svg";
import EventManagementEnum from "../../enum/EventManagementEnum";
import { CommonHelper } from "../common/CommonHelper";

export const EVENT_RESPONSETEAM_SELECTEDUSERS = "responseTeam-selectedUsers";
export const EVENT_RESPONSETEAM_SELECTEDGROUPS = "responseTeam-selectedGroups";
export const SERVICE_EVENT_ID_STRING = "{eventId}";
export const SERVICE_CHANNEL_ID_STRING = "{channelId}";
export const EVENT_TYPE_OPTIONS = [
  { key: "Bomb threat", text: "Bomb Threat" },
  { key: "Medical emergency", text: "Medical Emergency" },
  { key: "Weather Disruption", text: "Weather Disruption" },
  { key: "others", text: "Others" },
];

export const EVENTITEM_TABS = {
  data: [
    {
      text: "Event details",
      name: "details",
      value: "details",
      disabled: false,
    },
    { text: "Tasks", name: "tasks", value: "tasks", disabled: false },
    {
      text: "Response team",
      name: "response_team",
      value: "response_team",
      disabled: false,
    },
    {
      text: "Teams channel",
      name: "teams_channel",
      value: "teams_channel",
      disabled: false,
    },
    {
      text: "Crisis dashboard",
      name: "crisis_dashboard",
      value: "crisis_dashboard",
      disabled: false,
    },
  ],
};
export const WEATHER_DISRUPTION_KPI_CARD_KEYS = [
  {
    name: "deicingCapacity",
    displayName: "DeicingCapacity",
    image: DeicingSVG,
  },
  {
    name: "aircraftOnGround",
    displayName: "AircraftOnGround",
    image: AircraftSVG,
  },
  {
    name: "passengerOnGround",
    displayName: "PassengerOnGround",
    image: PassengerSVG,
  },
  {
    name: "departureCapacity",
    displayName: "DepartureCapacity",
    image: DepartureSVG,
  },
  {
    name: "baggage",
    displayName: "Baggage",
    image: BaggageSVG,
  },
  {
    name: "weather",
    displayName: "Weather",
    image: WeatherSVG,
  },
];

export enum DataType {
  String = "string",
}

export const UPDATE_KPI_COLUMNS = [
  {
    name: "Title",
    fieldName: "title",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "03:30",
    fieldName: "t1",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "04:00",
    fieldName: "t2",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "04:30",
    fieldName: "t3",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "05:00",
    fieldName: "t4",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "05:30",
    fieldName: "t4",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
];
export const UPDATE_PAST_KPI_COLUMNS = [
  {
    name: "Title",
    fieldName: "title",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "03:30",
    fieldName: "t1",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "04:00",
    fieldName: "t2",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "04:30",
    fieldName: "t3",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "05:00",
    fieldName: "t4",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    name: "05:30",
    fieldName: "t4",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: true,
  },
];
export const EVENTLIST_COLUMNS = [
  {
    key: "name",
    name: "Event Name",
    fieldName: "name",
    minWidth: 90,
    maxWidth: 110,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "type",
    name: "Event Type",
    fieldName: "type",
    minWidth: 90,
    maxWidth: 90,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "createdOn",
    name: "Created On",
    fieldName: "creationDateTime",
    minWidth: 90,
    maxWidth: 150,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "creatorName",
    name: "Creator Name",
    fieldName: "creator",
    minWidth: 90,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "Status",
    name: "Status",
    fieldName: "status",
    minWidth: 60,
    maxWidth: 80,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "Resolution",
    name: "Resolution",
    fieldName: "resolution",
    minWidth: 50,
    maxWidth: 70,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "closeTime",
    name: "Close Time",
    fieldName: "closedDateTime",
    minWidth: 90,
    maxWidth: 150,
    dataType: DataType.String,
    isRowHeader: true,
  },
];

export const RESPONSETEAM_GROUPCOLUMNS = [
  {
    key: "roles",
    name: "Roles",
    fieldName: "roles",
    minWidth: 150,
    maxWidth: 190,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "organizations",
    name: "Organizations",
    fieldName: "organizations",
    minWidth: 150,
    maxWidth: 190,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "responsibilities",
    name: "Responsibilities",
    fieldName: "responsibilities",
    minWidth: 150,
    maxWidth: 190,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "terminals",
    name: "Terminals",
    fieldName: "terminals",
    minWidth: 150,
    maxWidth: 190,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "edit",
    name: "",
    fieldName: "edit",
    minWidth: 20,
    maxWidth: 40,
    dataType: DataType.String,
    isRowHeader: false,
  },
  {
    key: "delete",
    name: "",
    fieldName: "delete",
    minWidth: 20,
    maxWidth: 40,
    dataType: DataType.String,
    isRowHeader: false,
  },
];

export const RESPONSETEAM_TAGPICKER_COLUMNS = [
  "id",
  "roles",
  "organization",
  "responsibilities",
  "terminals",
];

export const RESPONSETEAM_MEMBERCOLUMNS = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 80,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (item: any) => <div>{item.name}</div>,
  },
  {
    key: "roles",
    name: "Roles",
    fieldName: "roles",
    minWidth: 80,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "organization",
    name: "Organizations",
    fieldName: "organization",
    minWidth: 80,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "responsibilities",
    name: "Responsibilities",
    fieldName: "responsibilities",
    minWidth: 80,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "terminals",
    name: "Terminals",
    fieldName: "terminals",
    minWidth: 80,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "delete",
    name: "",
    fieldName: "delete",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
];

export const CHANNELLIST_COLUMNS = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 80,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
    onRender: (_item: any) => {},
  },
  {
    key: "responseTeam",
    name: "Response team",
    fieldName: "responseTeam",
    minWidth: 80,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "createdBy",
    name: "Created by",
    fieldName: "createdBy",
    minWidth: 80,
    maxWidth: 120,
    dataType: DataType.String,
    isRowHeader: true,
  },
  {
    key: "edit",
    name: "",
    fieldName: "edit",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
  {
    key: "delete",
    name: "",
    fieldName: "delete",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
  },
  {
    key: "=>",
    name: "",
    fieldName: "=>",
    minWidth: 20,
    maxWidth: 30,
    dataType: DataType.String,
    isRowHeader: false,
    onRender: (item: any) => (
      <Link
        key={item}
        onClick={() => {
          CommonHelper.navigateToTeamsChannel(item.id, item.name);
        }}
      >
        <AmdArrow direction={ArrowDirection.RIGHT} />
      </Link>
    ),
  },
];

export const EVENTITEM_CHANNELTABS = {
  data: [
    {
      text: "Channel list",
      name: "channel_list",
      value: "channel_list",
      disabled: false,
    },
    {
      text: "Channel management",
      name: "channel_management",
      value: "channel_management",
      disabled: false,
    },
  ],
};

export const WEATHER_DISRUPTION_ARRAY = [
  // Important! Keep the order same as how we receive data from API
  EventManagementEnum.DisruptionName.DeicingCapacity,
  EventManagementEnum.DisruptionName.AircraftOnGround,
  EventManagementEnum.DisruptionName.PassengerOnGround,
  EventManagementEnum.DisruptionName.DepartureCapacity,
  EventManagementEnum.DisruptionName.Baggage,
  EventManagementEnum.DisruptionName.Weather,
];
