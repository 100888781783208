import { Dropdown, IDropdownOption, mergeStyleSets } from "@fluentui/react";
import React, { useState } from "react";
import { IMetrics, ITypes } from "../../../models/app-settings/ITouchPoint";
import { PaxTouchPointItems } from "./PaxTouchPointItems";

export const PaxTouchPoint: React.FunctionComponent<ITouchPointProps> = (
  props: ITouchPointProps
) => {
  //Extract DropDown Options
  const dropdownOptions: IDropdownOption[] = props.types.map((type) => {
    return { key: type.name, text: type.name };
  });

  const [selectedDropDown, setSelectedDropDown] = useState<IDropdownOption>(
    dropdownOptions[0]
  );

  //Extract Metric values as per DropDown selected
  const dropDownMetrics: IMetrics[] | undefined = props.types.find(
    (type) => type.name === selectedDropDown.text
  )?.metrics;

  return (
    <section className="py-sm-4 " data-testid="pax-touch-point">
      <div className="fs-24 fw-400 pb-sm-2 fc--gray900">{props.name}</div>
      <div className={`pt-sm-2 pb-sm-4 d-flex ai-center`}>
        <span className={`fc--gray600 fs-14 pr-sm-1`}>Type</span>
        <Dropdown
          styles={dropdownStyles1}
          placeholder="Select an option"
          defaultSelectedKey={selectedDropDown.key}
          data-testid="touch-point-type-dropdown"
          options={dropdownOptions}
          onChange={(_e: any, selectedOption: any) => {
            setSelectedDropDown(selectedOption);
          }}
        />
      </div>
      <PaxTouchPointItems metrics={dropDownMetrics} />
    </section>
  );
};

export interface ITouchPointProps {
  name: string;
  types: ITypes[];
}

const dropdownStyles1 = mergeStyleSets({
  root: {
    color: "#1A1A1A !important",
    fontWeight: 400,
    minWidth: 200,
  },
  dropdown: {
    borderRadius: 5,
  },
  title: {
    border: "1px solid #CCCCCC",
    borderRadius: 5,
    paddingLeft: 10,
  },
  caretDownWrapper: {
    fontWeight: 400,
  },
  caretDown: {
    color: "#1A1A1A !important",
  },
});
