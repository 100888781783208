export enum ResourceType {
  Flight = "flight",
  Belts = "baggageBelt",
  Chutes = "baggageChute",
  Weather = "weather",
  Gates = "gate",
  Stand = "stand",
  Checkin = "checkInCounter",
  Passenger = "passenger",
  Counter = "counter",
  OTP = "onTimePerformance",
  TurnaroundSufficiency = "turnaroundSufficiency",
  SlotAdherence = "slotAdherence",
  RunwayDelay = "runwayDelay",
  Overall = "overall",
  Baggage = "baggage",
  EventManagement = "eventManagement",
  Default = "",
}
enum Resource {
  Flight = "flight",
  Baggage = "baggage",
  Gates = "gates",
}
enum ResourceTabs {
  Gates = "gate",
  BeltsAndChutes = "baggage",
  Stand = "stand",
  CheckInCounters = "checkInCounter",
  SelfServiceCounter = "selfservice",
}
const ResourceEnum = {
  ResourceType,
  Resource,
  ResourceTabs,
};

export default ResourceEnum;
