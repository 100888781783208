import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Link, mergeStyles, mergeStyleSets } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import EventManagementEnum from "../../../../enum/EventManagementEnum";
import PageEnum from "../../../../enum/PageEnum";
import { IEventResponse } from "../../../../models/events/IEventResponse";
import { IEventUserPrivilege } from "../../../../models/events/IEventRole";
import { CommonHelper } from "../../../../utils/common/CommonHelper";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { AmdArrow } from "../../../common/icons/arrow/arrow";
import { ArrowDirection } from "../../../common/icons/arrow/arrowDirection";
import { AmdTabs } from "../../../common/tabs/tabs";
import { EventResponseTeam } from "../../create-event/response-team/EventResponseTeam";
import { EventChannelsList } from "../../teams-channel/channel-list/EventChannelsList";
import { EventChannelManagement } from "../../teams-channel/management/EventChannelManagement";
import { EventDetail } from "./event-detail/EventDetail";
import { EventTasks } from "./event-tasks/EventTasks";
import { CrisisDashboard } from "../../crisis-dashboard/CrisisDashboard";

export const EventItem: React.FunctionComponent<IEventItemProps> = (
  props: IEventItemProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [eventUserPrivilege, setEventUserPrivilege] =
    useState<IEventUserPrivilege>();
  const [eventTabs, setEventTabs] = useState<any>();
  const [eventChannelTabs, setEventChannelTabs] = useState<any>();
  const [eventTypeResponseTemplateData, setEventTypeResponseTemplateData] =
    useState<any>();
  const [eventResponseData, setEventResponseData] = useState<IEventResponse>();
  const [selectedTab, setSelectedTab] = useState<string>(
    EventManagementEnum.EventItemTabs.EventDetails
  );
  const [selectedChannelTab, setSelectedChannelTab] = useState<string>(
    EventManagementEnum.EventItemChannelTabs.ChannelList
  );
  const [canCloseEvent, setCanCloseEvent] = useState<boolean>(false);

  useEffect(() => {
    CommonHelper.getCurrentUserEmail(msalInstance).then((userEmail: string) => {
      setCurrentUserEmail(userEmail);
    });
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (currentUserEmail !== "" && props.airport !== "") {
      EventManagementHelper.getEventUserPrivileges(
        msalInstance,
        props.selectedEventId,
        currentUserEmail,
        props.airport
      ).then((response: any) => {
        if (isMounted && response && response.data) {
          setEventUserPrivilege(response.data);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [props.airport, currentUserEmail, props.selectedEventId]);

  useEffect(() => {
    if (eventUserPrivilege && eventResponseData) {
      const tabs = EventManagementHelper.configureEventItemTabs(
        eventResponseData,
        eventUserPrivilege,
        currentUserEmail
      );
      setEventTabs(tabs.eventTabs);
      setEventChannelTabs(tabs.channelTabs);

      setCanCloseEvent(
        EventManagementHelper.canCloseEvent(
          currentUserEmail,
          eventResponseData?.creatorEmail ?? "",
          eventUserPrivilege
        )
      );
    }
  }, [
    eventResponseData?.roleToEmailsMap,
    eventUserPrivilege?.isAOM,
    eventUserPrivilege?.partOfResponseTeam,
    eventUserPrivilege?.partOfChannelManagementTeam,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (props.airport !== "" && eventResponseData?.type) {
      EventManagementHelper.getTemplateResponseTeam(
        msalInstance,
        eventResponseData?.type,
        props.airport
      )
        .then((response: any) => {
          if (isMounted && response && response.data) {
            setEventTypeResponseTemplateData(response.data);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [props.airport, eventResponseData?.type]);

  useEffect(() => {
    let isMounted = true;

    if (props.airport !== "" && props.selectedEventId) {
      EventManagementHelper.getEventDetailById(
        msalInstance,
        props.selectedEventId,
        props.airport
      )
        .then((response: any) => {
          if (isMounted && response.data) {
            setEventResponseData(response.data);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [props.airport, props.selectedEventId]);

  const onEventItemTabClick = (_selectedtab: string) => {
    setSelectedTab(_selectedtab);
  };

  const onEventItemChannelTabClick = (_selectedtab: string) => {
    setSelectedChannelTab(_selectedtab);
  };

  const onEventClosed = () => {
    props.refreshEventList();
  };

  return (
    <div data-testid="event-item">
      <div
        className={`amd-container-hspacebtwn amd-container-vcenter ${contentStyles.tabStyle}`}
      >
        <AmdTabs
          dataTestId="EventItemTab"
          defaultSelectedTab={EventManagementEnum.EventItemTabs.EventDetails}
          tabList={eventTabs}
          onTabClick={onEventItemTabClick}
          selectedTab={selectedTab}
        />
        <Link
          href={`/${PageEnum.PageUrl.EventManagement}`}
          className={`${CommonHelper.navLinkStyle(
            `/${PageEnum.PageUrl.EventManagement}`
          )} text-d-none amd-container-hspacebtwn`}
        >
          <AmdArrow
            direction={ArrowDirection.LEFT}
            className={contentStyles.eventHeaderStylesPlusIcon}
          />
          Back to Event Management
        </Link>
      </div>
      {selectedTab === EventManagementEnum.EventItemTabs.EventDetails && (
        <EventDetail
          eventId={props.selectedEventId}
          canCloseEvent={canCloseEvent}
          onEventClosed={onEventClosed}
          airport={props.airport}
        />
      )}

      {selectedTab === EventManagementEnum.EventItemTabs.Tasks && (
        <EventTasks
          eventId={props.selectedEventId}
          isAOM={eventUserPrivilege?.isAOM}
        />
      )}

      {selectedTab === EventManagementEnum.EventItemTabs.Response && (
        <>
          <div className="fc--gray900 mt-2 mb-1 fs-21 fw-600">
            Response team
          </div>

          <div>
            These are the individuals and groups who will respond to the event.
            They are added to the team and channels for this event in MS Teams.
          </div>

          <EventResponseTeam
            eventId={props.selectedEventId}
            templateList={eventTypeResponseTemplateData}
            customList={eventResponseData?.responseTeam}
            isNewForm={false}
            isInEditMode={true}
            airport={props.airport}
          />
        </>
      )}

      {selectedTab === EventManagementEnum.EventItemTabs.Channel && (
        <div className="mt-1">
          <AmdTabs
            dataTestId="channelManagementTab"
            defaultSelectedTab={
              EventManagementEnum.EventItemChannelTabs.ChannelList
            }
            tabList={eventChannelTabs}
            onTabClick={onEventItemChannelTabClick}
            selectedTab={selectedChannelTab}
          />

          {selectedChannelTab ===
            EventManagementEnum.EventItemChannelTabs.ChannelList && (
            <EventChannelsList
              eventId={props.selectedEventId}
              airport={props.airport}
            />
          )}

          {selectedChannelTab ===
            EventManagementEnum.EventItemChannelTabs.ChannelManagement && (
            <EventChannelManagement
              eventId={props.selectedEventId}
              airport={props.airport}
            />
          )}
        </div>
      )}

      {selectedTab === EventManagementEnum.EventItemTabs.CrisisDashboard && (
        <CrisisDashboard eventId={props.selectedEventId} />
      )}
    </div>
  );
};

export interface IEventItemProps {
  selectedEventId: string;
  refreshEventList?: any;
  airport: string;
}

const contentStyles = mergeStyleSets({
  tabStyle: {
    borderBottom: "2px solid #cccccc",
    marginButtom: "20px",
  },
  eventHeaderStylesPlusIcon: mergeStyles({
    paddingRight: "6px",
  }),
});
