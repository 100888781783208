import { TextField, mergeStyleSets } from "@fluentui/react";

interface IAmdTextFieldProps {
  id: string;
  label: string;
  isMandatory: boolean;
  isMultiline: boolean;
  defaultValue?: string;
  isReadOnly?: boolean;
  className?: string;
  placeholder?: string;
  onChange?: any;
}

export const AmdTextField: React.FunctionComponent<IAmdTextFieldProps> = (
  props: IAmdTextFieldProps
) => {
  return (
    <TextField
      id={props.id}
      data-testid={props.id}
      styles={textfieldStyles}
      className={props.className}
      label={props.label}
      required={props.isMandatory}
      multiline={props.isMultiline}
      disabled={props.isReadOnly ?? false}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  );
};

const textFieldControlStyle: React.CSSProperties = {
  width: "100%",
};

const textfieldStyles = mergeStyleSets({
  root: {
    selectors: {
      "& .ms-Label": {
        outlineOffset: "2px",
      },
      "&:focus-visible": {
        outlineOffset: "2px",
      },
    },
  },
});
