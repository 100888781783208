import {
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";

function UseGraph(
  msalInstance: PublicClientApplication,
  scopes: string[],
  graphEndpoint: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const loginRequest: SilentRequest = { scopes: scopes };

    msalInstance
      ?.acquireTokenSilent(loginRequest)
      ?.then((tokenResponse) => {
        const bearer = "Bearer " + tokenResponse?.accessToken;
        const headers = new Headers();
        headers.append("Authorization", bearer);

        const options = {
          method: "GET",
          headers: headers,
        };

        return resolve(fetch(graphEndpoint, options));
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return reject(msalInstance.acquireTokenRedirect(loginRequest));
        }

        return null;
      });
  });
}

export default UseGraph;
