import React from "react";

import { getTheme, mergeStyleSets } from "@fluentui/react";

import {
  Button,
  Link,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
} from "@fluentui/react-components";

import {
  bundleIcon,
  CalendarFilled,
  CalendarRegular,
} from "@fluentui/react-icons";

import { MessageBarIntentType } from "../../../../enum/NotificationPanelEnum";
import PageEnum from "../../../../enum/PageEnum";
import { ConfirmDialog } from "./ConfirmDialog";
import { INotificationItemExtended } from "./types";
import { ExclamationTriangleIcon } from "../../../common/icons/exclamation-triangle/ExclamationTriangleIcon";
import { InfoCircleIcon } from "../../../common/icons/info-circle/InfoCircleIcon";
import { MinusCircleIcon } from "../../../common/icons/minus-circle/MinusCircleIcon";

interface IINotificationItemProps {
  item: INotificationItemExtended;
  onDismissNotification: any;
  index?: number;
}

export function getMessageBarIcon(intent: MessageBarIntentType) {
  switch (intent) {
    case MessageBarIntentType.ERROR:
      return <MinusCircleIcon />;
    case MessageBarIntentType.WARNING:
      return <ExclamationTriangleIcon />;
    default:
      return <InfoCircleIcon />;
  }
}

export function getMessageBarStyle(intent: MessageBarIntentType) {
  switch (intent) {
    case MessageBarIntentType.ERROR:
      return contentStyles.messageBarAlert;
    case MessageBarIntentType.INFO:
      return contentStyles.messageBarInfo;
    case MessageBarIntentType.WARNING:
      return contentStyles.messageBarWarning;
    default:
      return contentStyles.messageBarInfo;
  }
}

export const MessageBarPanel: React.FunctionComponent<
  IINotificationItemProps
> = ({ item, onDismissNotification, index = 0 }): React.JSX.Element => {
  const { id, title, intent, actions, body } = item;
  const { create, dismiss } = actions || {};
  const CalendarIcon = bundleIcon(CalendarFilled, CalendarRegular);

  const handleCreateEvent = () => {
    window.sessionStorage.setItem("isNewEventClicked", "true");
  };

  return (
    <MessageBar
      key={id}
      intent={intent}
      layout="singleline"
      icon={{
        children: getMessageBarIcon(intent),
        className: `${getMessageBarStyle(intent)}`,
      }}
      className={`${contentStyles.messageBarContainer} ${getMessageBarStyle(
        intent
      )}`}
    >
      <MessageBarBody>
        <MessageBarTitle className={contentStyles.messageBarTitle}>
          {title}
        </MessageBarTitle>
      </MessageBarBody>
      <MessageBarActions
        containerAction={
          dismiss ? (
            <ConfirmDialog
              id={id}
              onDismissNotification={() =>
                onDismissNotification(index, item, id)
              }
              title={title}
              intent={intent}
            />
          ) : null
        }
      >
        {create ? (
          <Link
            href={`/${PageEnum.PageUrl.EventManagement}?title=${title}&descr=${
              body ? body : ""
            }`}
          >
            <Button
              className={contentStyles.actionColor}
              icon={{
                children: <CalendarIcon />,
                className: contentStyles.iconColor,
              }}
              appearance="transparent"
              aria-label="create-event"
              onClick={handleCreateEvent}
            >
              Create event
            </Button>
          </Link>
        ) : null}
      </MessageBarActions>
    </MessageBar>
  );
};
const theme = getTheme();

const contentStyles = mergeStyleSets({
  messageBarContainer: {
    ".fui-AccordionPanel &": {
      border: "none",
      borderLeft: `4px solid`,
    },
  },
  messageBarAlert: {
    ".fui-AccordionPanel &": {
      color: `${theme.palette.red}`,
    },
  },
  messageBarTitle: {
    color: theme.palette.black,
  },
  messageBarWarning: {
    ".fui-AccordionPanel &": {
      color: `${theme.palette.orangeLighter}`,
    },
  },
  messageBarInfo: {
    ".fui-AccordionPanel &": {
      color: `${theme.palette.neutralTertiary}`,
    },
  },
  iconColor: {
    color: `${theme.palette.blue}`,
  },
  actionColor: {
    ".fui-MessageBarActions &": {
      color: `${theme.palette.blue}`,
    },
  },
});
