import {
  makeStyles,
  SearchBoxChangeEvent,
  Spinner,
} from "@fluentui/react-components";
import React from "react";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../context/LayoutContext";
import { $cloud100, $cloud30, $cloud50, $white } from "../../cssjs/colors";
import PageEnum from "../../enum/PageEnum";
import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import { findMatches } from "../../utils/multi-airports/MultiAirportHelperFunctions";
import MapDisplayAirports from "../osm-maps/MapDisplayAirports";
import { OverviewSidebar } from "../overview-sidebar/OverviewSidebar";
import { BaggageCard } from "../shared/components/airport-cards/BaggageCard";
import { EventsCard } from "../shared/components/airport-cards/EventsCard";
import { FlightCard } from "../shared/components/airport-cards/FlightCard";
import { HealthOfOperationsCard } from "../shared/components/airport-cards/HealthOfOperationsCard";
import { PaxCard } from "../shared/components/airport-cards/PaxCard";
import { ResourcesUtilisationCard } from "../shared/components/airport-cards/ResourcesUtilisationCard";
import { WeatherCard } from "../shared/components/airport-cards/WeatherCard";
import { NotificationPanel } from "../shared/components/notifications/NotificationPanel";

export const cardMinHeight = "174px";

export const Overview: React.FunctionComponent = () => {
  const airport = useAppSelector((state) => getAirportCode(state));
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sideBarActive, setSideBarActive] = React.useState<boolean>(true);
  const { isMapView } = React.useContext(LayoutContext);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  function searchInputHandler(event: SearchBoxChangeEvent): void {
    const { target } = event;
    const { value } = target as HTMLInputElement;
    const matchedAirports = findMatches(value, []);
    console.log("matchedAirports", matchedAirports);
    // set flights state
  }

  return (
    <>
      {isMapView ? (
        <>
          <OverviewSidebar
            sideBarActive={sideBarActive}
            setSideBarActive={setSideBarActive}
            searchInputHandler={searchInputHandler}
          />
          <MapDisplayAirports airportMapDetails={[]} />
        </>
      ) : (
        <div
          className={`${classes.gridContainer}`}
          data-testid="airportCardSection"
        >
          <div
            className={`${classes.notificationPanel}`}
            data-testid="notificationSection"
          >
            <NotificationPanel airport={airport} />
          </div>
          <div className={`card1`} data-testid="weatherCard">
            <Link
              to={`/${PageEnum.PageUrl.Weather}`}
              style={{ textDecoration: "none" }}
            >
              <WeatherCard />
            </Link>
          </div>
          <div className={`${classes.card} card2`} data-testid="flightCard">
            <Link
              to={`/${PageEnum.PageUrl.Flights360}`}
              style={{ textDecoration: "none" }}
            >
              <FlightCard />
            </Link>
          </div>
          <div className={`${classes.card} card3`} data-testid="paxCard">
            <Link
              to={`/${PageEnum.PageUrl.Passenger}`}
              style={{ textDecoration: "none" }}
            >
              <PaxCard />
            </Link>
          </div>
          <div className={`${classes.card} card4`} data-testid="baggageCard">
            <Link
              to={`/${PageEnum.PageUrl.Baggage}`}
              style={{ textDecoration: "none" }}
            >
              <BaggageCard />
            </Link>
          </div>
          <div
            className={`${
              loading ? classes.cardLoader : classes.cardContainer
            } ${classes.card5} card5`}
            data-testid="resourcesUtilisationCard"
          >
            {loading ? (
              <Spinner labelPosition="below" label="Loading..." />
            ) : (
              <Link
                to={`/${PageEnum.PageUrl.ResourceAllocation}`}
                className={classes.linkStyling}
              >
                <ResourcesUtilisationCard />
              </Link>
            )}
          </div>

          <div
            className={`${
              loading ? classes.cardLoader : classes.cardContainer
            } card6`}
            data-testid="eventsCard"
          >
            {loading ? (
              <Spinner labelPosition="below" label="Loading..." />
            ) : (
              <EventsCard />
            )}
          </div>
          <div
            className={`${classes.cardContainer} ${classes.card7} card7`}
            data-testid="healthOfOperationsCard"
          >
            <HealthOfOperationsCard airport={airport} />
          </div>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  cardLoader: {
    display: "grid",
    gridTemplateColumns: "1fr",
    borderRadius: "8px",
    boxSizing: "border-box",
    background: $white,
    padding: "8px",
    border: `1px solid ${$cloud30}`,
    color: $cloud100,
    minHeight: cardMinHeight,
    justifyContent: "center",
    alignContent: "center",
  },
  gridContainer: {
    display: "grid",
    gridGap: "20px",
    gridTemplateColumns: "repeat(4, minmax(214px, 1fr))",
    "@media(max-width: 1024px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    "&.card1": {
      gridTemplateRows: "36px 0px 16px 16px",
    },
    "&.card6": {
      gridTemplateColumns: "1fr",
    },
    "&.card7": {
      gridTemplateColumns: "1fr auto",
    },
    minHeight: cardMinHeight,
    color: $cloud100,
  },
  notificationPanel: {
    gridColumn: "1 / -1",
  },
  card: {
    background: $white,
    borderRadius: "8px",
    minHeight: cardMinHeight,
  },
  card5: {
    gridColumn: "span 3",
    "@media(max-width: 1024px)": {
      gridColumn: "span 1",
    },
  },
  card7: {
    gridColumn: "1 / -1",
  },
  breadcrumbsIcon: {
    flexDirection: "row-reverse",
    display: "flex",
  },
  iconButton: {
    border: "1px solid #0C66E1",
    padding: "5px",
    margin: "0px",
    color: "#0C66E1",
  },
  linkStyling: {
    textDecoration: "none",
  },
  mapViewContainer: {
    background: "#F8F8F8",
    paddingTop: "8px",
    borderRadius: "8px",
    border: `1px solid ${$cloud50}`,
    minHeight: "660px",
  },
});
