import React from "react";
import { IMetrics } from "../../../models/app-settings/ITouchPoint";
import { useAppSelector } from "../../../store/StoreHooks";
import { getAirportCode } from "../../../store/airport/AirportSelector";
import { PaxTouchPointHeader } from "./PaxTouchPointHeader";
import { PaxTouchPointItem } from "./PaxTouchPointItem";

export const PaxTouchPointItems: React.FunctionComponent<
  ITouchPointsItemsProps
> = (props: ITouchPointsItemsProps) => {
  const airportCode = useAppSelector((state) => getAirportCode(state));

  return (
    <div style={containerStyle} data-testid="pax-touch-point-items">
      <PaxTouchPointHeader />
      <div data-testid="pax-touch-point-item-metrics">
        {props.metrics?.map((metric) => (
          <PaxTouchPointItem
            key={`${airportCode}${metric.name}`}
            metric={metric}
          />
        ))}
      </div>
    </div>
  );
};

export interface ITouchPointsItemsProps {
  metrics?: IMetrics[];
}

const containerStyle: React.CSSProperties = {
  border: "1px solid #CCCCCC",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
};
