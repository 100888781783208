import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { CommonHelper } from "../utils/common/CommonHelper";
import { PageLayout } from "./common/pagelayout/PageLayout";
import AmdErrorBoundaryFallback from "./error-boundary/AmdErrorBoundaryFallback";
/**
 * The main app which handles the initialization and routing of the app.
 */
export const App = () => {
  // Refresh local storage & session storage if the REACT_APP_CACHE_REFRESH_TOKEN key in env file does not match with 'CacheRefreshToken' key in local storage.
  CommonHelper.refreshLocalStorage();

  return (
    <ErrorBoundary FallbackComponent={AmdErrorBoundaryFallback}>
      <BrowserRouter>
        <PageLayout />
      </BrowserRouter>
    </ErrorBoundary>
  );
};
