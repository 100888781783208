import React from "react";

export const AmdEventManagement: React.FunctionComponent = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      data-testid="event-management-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 13.5H23.75C24.1642 13.5 24.5 13.8358 24.5 14.25V16H19.5V14.25C19.5 13.8358 19.8358 13.5 20.25 13.5ZM18 14.25V16H16.25C14.4551 16 13 17.4551 13 19.25V26.75C13 28.5449 14.4551 30 16.25 30H27.75C29.5449 30 31 28.5449 31 26.75V19.25C31 17.4551 29.5449 16 27.75 16H26V14.25C26 13.0074 24.9926 12 23.75 12H20.25C19.0074 12 18 13.0074 18 14.25ZM29.5 19.25V20.75C29.5 21.7165 28.7165 22.5 27.75 22.5H24V22C24 21.4477 23.5523 21 23 21H21C20.4477 21 20 21.4477 20 22V22.5H16.25C15.2835 22.5 14.5 21.7165 14.5 20.75V19.25C14.5 18.2835 15.2835 17.5 16.25 17.5H27.75C28.7165 17.5 29.5 18.2835 29.5 19.25ZM29.5 23.4891V26.75C29.5 27.7165 28.7165 28.5 27.75 28.5H16.25C15.2835 28.5 14.5 27.7165 14.5 26.75V23.4891C15.0052 23.8125 15.6057 24 16.25 24H20C20 24.5523 20.4477 25 21 25H23C23.5523 25 24 24.5523 24 24H27.75C28.3943 24 28.9949 23.8125 29.5 23.4891Z"
        fill="#004485"
      />
      <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#004485" />
    </svg>
  );
};
