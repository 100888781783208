import { GroupedVerticalBarChart } from "@fluentui/react-charting";
import { NoDataCard } from "../../no-data-card/NoDataCard";

export const GroupedBarChart: React.FunctionComponent<IGroupedBarChart> = (
  props: IGroupedBarChart
) => {
  const { chartData } = props;
  return (
    <>
      <div data-testid="groupedBarChart">
        {chartData?.length === 0 ? (
          <NoDataCard message="No data found" />
        ) : (
          <GroupedVerticalBarChart
            chartTitle="Grouped Vertical Bar chart"
            data={chartData}
            width={700}
            height={400}
            yAxisTickCount={10}
            barwidth={20}
          />
        )}
      </div>
    </>
  );
};
export interface IGroupedBarChart {
  chartData: any[];
}
