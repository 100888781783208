import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  mergeStyleSets,
} from "@fluentui/react";
import { DialogBody } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { NotificationContext } from "../../../context/NotificationContext";
import ORGANIZATION_FILTERS from "../../../data/filter-panel/organization-filter.json";
import { IOrganizationItem } from "../../../models/admin/IOrganizationItem";
import IFilters from "../../../models/filters/IFilters";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { getOrganizationFilters } from "../../../store/organization-filter/OrganizationFilterSelector";
import { organizationFilterActions } from "../../../store/organization-filter/OrganizationFilterSlice";
import { ORGANIZATION_COLUMNS } from "../../../utils/admin/AdminConstants";
import { AdminHelper } from "../../../utils/admin/AdminHelper";
import { MESSAGEBAR_TYPE } from "../../../utils/common/Constants";
import { AmdDataGrid } from "../../common/data-grid/AmdDataGrid";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdModal } from "../../common/form-controls/uncontrolled/Modal/AmdModal";
import { AmdTextField } from "../../common/form-controls/uncontrolled/TextField/AmdTextField";
import { AmdUser } from "../../common/icons/user/User";
import { FilterPanel } from "../../filters/filter-panel/FilterPanel";
import { AddOrganizationPopup } from "./AddOrganizationPopup";

export interface IOrganizationsProps {
  airport: string;
}

export const Organizations: React.FunctionComponent<IOrganizationsProps> = ({
  airport,
}) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [organizationList, setOrganizationList] = useState<IOrganizationItem[]>(
    []
  );
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [
    isDeleteModalOpen,
    { setTrue: showDeleteModal, setFalse: hideDeleteModal },
  ] = useBoolean(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [selectedOrganization, setSelectedOrganization] =
    useState<IOrganizationItem>();
  const { addNotification } = React.useContext(NotificationContext);

  const dispatch = useAppDispatch();
  const filterObj = localStorage.getItem("organizationFilters");
  let filters: IFilters[] = [];
  if (filterObj) {
    filters = JSON.parse(filterObj);
  }
  const setOrganizationFilters = () => {
    if (filters && filters.length > 0) {
      dispatch(organizationFilterActions.setOrganizationFilters(filters));
    } else {
      dispatch(
        organizationFilterActions.setOrganizationFilters(
          ORGANIZATION_FILTERS.data
        )
      );
    }
  };

  const onUpdateFilter = (updatedItems: IFilters[]) => {
    dispatch(organizationFilterActions.setOrganizationFilters(updatedItems));
  };

  const organizationFilters = useAppSelector((state) =>
    getOrganizationFilters(state)
  );

  useEffect(() => {
    setOrganizationFilters();
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;

    AdminHelper.getOrganizationsForComponents(msalInstance).then(
      (response: any) => {
        if (isMounted) {
          setOrganizationList(response.data);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (selectedOrganizationId) {
      AdminHelper.getOrganizationComponentById(
        msalInstance,
        selectedOrganizationId
      )
        .then((selectedOrganization: IOrganizationItem) => {
          setSelectedOrganization(selectedOrganization);
          showModal();
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }
  }, [selectedOrganizationId]);

  const showOrganizationPopup = () => {
    setSelectedOrganizationId("");
    setSelectedOrganization(undefined);
    showModal();
  };

  const onEditOrganization = (item: any) => {
    setSelectedOrganizationId(item?.id);
  };

  const onDeleteOrganization = (item: any) => {
    setSelectedOrganizationId(item?.id);
    showDeleteModal();
  };

  const deleteOrganization = () => {
    const remainingOrganizations = organizationList.filter(
      (org: IOrganizationItem) => {
        return org.id !== selectedOrganizationId;
      }
    );
    setOrganizationList(remainingOrganizations);
    addNotification(
      MESSAGEBAR_TYPE.SUCCESS,
      `Deleted organization successfully!`
    );
    hideDeleteModal();
  };

  const onOrganizationAdded = (orgId: string, data: any) => {
    const addedOrganizationsList = AdminHelper.getAddedOrganizationDetails(
      organizationList,
      orgId,
      data
    );
    setOrganizationList(addedOrganizationsList);
    addNotification(
      MESSAGEBAR_TYPE.SUCCESS,
      `Added organizataion: ${data.organizationName} successfully!`
    );
  };

  const onSearchTextChange = (e: any) => {
    console.log(e);
  };

  return (
    <div data-testid="organizations">
      <div className="amd-container-hspacebtwn amd-container-vcenter mb-2">
        <div className="d-flex ai-center">
          <div className="ff-amd fs-36 fw-400">Organizations</div>
          <AmdTextField
            id="searchOrganizations"
            label=""
            isMandatory={false}
            isMultiline={false}
            placeholder="Search organization name"
            className={`ml-2 ${contentStyles.searchBox}`}
            onChange={onSearchTextChange}
          />
        </div>

        <div className="pt-sm-2">
          <AmdButton
            id="addOrganization"
            testId="addOrganization"
            text="Add organization"
            onClick={showOrganizationPopup}
            type={AmdButtonType.DEFAULT}
            prefixImageIconBeforeText={true}
            imageIcon={<AmdUser className="mt-1 mr-1" />}
            className="mr-1"
          />
        </div>
      </div>

      <FilterPanel
        filters={organizationFilters}
        onFiltersApplied={() => {
          console.log("onFiltersApplied");
        }}
        onUpdateFilter={onUpdateFilter}
        airport={airport}
      />

      <div className="mt-2">
        {organizationList && organizationList.length > 0 && (
          <AmdDataGrid
            showActionButtons={true}
            rowItems={organizationList}
            columns={ORGANIZATION_COLUMNS}
            onEditItem={onEditOrganization}
            onDeleteItem={onDeleteOrganization}
          />
        )}

        <AddOrganizationPopup
          hideDialog={!isModalOpen}
          onModalPopupClose={hideModal}
          onOrganizationAdded={onOrganizationAdded}
          selectedOrganizationId={selectedOrganizationId}
          selectedOrganization={selectedOrganization}
          airport={airport}
        ></AddOrganizationPopup>

        <AmdModal
          isOpen={isDeleteModalOpen}
          isBlocking={true}
          header="Confirm delete"
          onClose={hideDeleteModal}
          containerClassName={modalStyles.container}
        >
          <div className={modalStyles.body}>
            <DialogBody>
              <div>
                Deleting a organisation will remove all associated permissions
                from any users assigned this organisation.
              </div>
              <div className="mt-1"> Are you sure you want to proceed?</div>
            </DialogBody>
            <DialogFooter>
              <DefaultButton text="Cancel" onClick={hideDeleteModal} />
              <PrimaryButton
                text="Yes, delete organization"
                onClick={deleteOrganization}
              />
            </DialogFooter>
          </div>
        </AmdModal>
      </div>
    </div>
  );
};

const contentStyles = mergeStyleSets({
  searchBox: {
    width: "300px",
  },
});

const modalStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "25vh",
    width: "30vw",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
