import {
  DefaultSortEnum,
  SelectedTypeEnum,
} from "../../../enum/DefaultSortEnum";
import { ResourceType } from "../../../enum/ResourceEnum";
import { sortFlights } from "./getSortFlights";

export default function getSortField(
  viewType: ResourceType | string,
  selectedType?: SelectedTypeEnum | string
) {
  const sortOveride = false;
  switch (viewType) {
    case ResourceType.EventManagement:
      return {
        sortField: DefaultSortEnum.CREATION_START_TIME,
        sortOveride: true,
      };
    case ResourceType.Flight:
      const sortField = sortFlights(selectedType);
      return {
        sortField,
        sortOveride,
      };
    case ResourceType.OTP:
    case ResourceType.TurnaroundSufficiency:
    case ResourceType.SlotAdherence:
    case ResourceType.RunwayDelay:
      return {
        sortField: DefaultSortEnum.DELAY_DURATION,
        sortOveride: true,
      };
    case ResourceType.Passenger:
      const sortFieldPassenger = sortFlights(selectedType);
      return {
        sortField: sortFieldPassenger,
        sortOveride,
      };
    case ResourceType.Baggage:
    case ResourceType.Checkin:
    case ResourceType.Gates:
    case ResourceType.Stand:
      return {
        sortField: DefaultSortEnum.RESOURCE_ALLOCATION_START,
        sortOveride,
      };
    default:
      return {
        sortField: DefaultSortEnum.DEFAULT,
        sortOveride,
      };
  }
}
