import { AnimationClassNames, mergeStyleSets } from "@fluentui/react";
import { LineChart } from "@fluentui/react-charting";
import React from "react";
import { NoDataCard } from "../../../components-v2/common/no-data-card/NoDataCard";
import { useAppSelector } from "../../../store/StoreHooks";
import { getWeatherDisruptionState } from "../../../store/weather-disruption/weatherDisruptionSelector";
import { AmdDeicing } from "../../common/icons/deicing/Deicing";
import { LoadingScreen } from "../../common/loading-screen/LoadingScreen";

export const WeatherDisruptionGraph: React.FunctionComponent =
  (): React.JSX.Element | null => {
    const weatherDisruptionState = useAppSelector((state: any) =>
      getWeatherDisruptionState(state)
    );

    const {
      selectedDisruptionKPI,
      selectedKpi: selectedKpiTitle,
      selectedKpiId,
    } = weatherDisruptionState;

    const { weatherDisruptionChartData, allowMultipleShapes, width, height } =
      selectedDisruptionKPI;

    const hasGraphData = weatherDisruptionChartData?.lineChartData?.length;
    const loading = weatherDisruptionChartData === null;
    const title = `${selectedKpiTitle} trend`;

    return (
      <div
        data-testid="weather-disruption-graph"
        className={`ms-Grid-row pb-1 ${
          selectedKpiId && AnimationClassNames.fadeIn500
        }`}
      >
        <div className="mt-2 ml-1 fw-300 fs-20 mb-1">
          <AmdDeicing
            className="ml-1"
            width="40"
            height="35"
            color="#1890F140"
          />
          {title}
        </div>
        {loading ? (
          <div className="pb-1">
            <LoadingScreen
              message={"Loading..."}
              spinClassName={contentStyles.spinner}
              labelClassName={contentStyles.loadingMessage}
            />
          </div>
        ) : hasGraphData ? (
          <LineChart
            data={weatherDisruptionChartData}
            yMinValue={0}
            yMaxValue={100}
            tickFormat={"%H:%M"}
            allowMultipleShapesForPoints={allowMultipleShapes}
            height={height}
            width={width}
            hideLegend={false}
            xAxisTickCount={7}
            yAxisTickCount={10}
          />
        ) : (
          <div className="pb-1">
            <NoDataCard message="No data found" />
          </div>
        )}
      </div>
    );
  };

export default WeatherDisruptionGraph;

const contentStyles = mergeStyleSets({
  spinner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    minHeight: "310px",
  },
  loadingMessage: {
    fontSize: "24px",
    color: "grey",
  },
  loadingScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
