import { getTimeSeries } from "./getTimeSeries";
import { mapFieldItem } from "./mapFieldItem";

export function getMappedTableData(mappedData: any) {

  if (!mappedData?.length) {
    return [];
  }

  return mappedData?.map((item: any) => {
    const touchpointArray = item?.map((itemInner: any) => {
      const { key, factors, threshold, data } = itemInner;
      const dataArr = data.map((item: any) => {
        const fieldItem = Object.values(item);
        const { value, status } = mapFieldItem(fieldItem) ?? {};

        const [key] = Object.keys(item);

        return {
          [key]: `${value}${status ? "|" : ""}${status}`,
        };
      });

      const timeSeries = getTimeSeries(dataArr);

      return {
        key,
        factors,
        threshold,
        ...timeSeries,
      };
    });
    return {
      data: touchpointArray,
    };
  });
}
