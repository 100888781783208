import { getTheme, IIconProps, mergeStyleSets, Modal } from "@fluentui/react";
import {
  DefaultButton,
  IButtonStyles,
  IconButton,
  PrimaryButton,
} from "@fluentui/react/lib/Button";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import QueryCondition from "../query-type-filter/QueryCondition";

interface IQueryFilterPopUpProps {
  label: string;
  hideDialog: boolean;
  filterSettings: any;
  appliedFilters: any;
  onModalPopupClose: () => void;
  onApplyFilter: (data: any) => void;
}

const QueryFilterPopUp: React.FC<IQueryFilterPopUpProps> = (
  props: IQueryFilterPopUpProps
) => {
  const { filterColumns, filterColumnsDetails } = props.filterSettings;
  const { fieldControls, fieldControlDetails } =
    filterColumnsDetails[filterColumns[0] as keyof typeof filterColumnsDetails];

  const { operator, values } = props.appliedFilters[fieldControls.Name];

  const methods = useForm();

  const onSubmitHandler = (data: any) => {
    props.onApplyFilter(data);
    methods.reset();
    props.onModalPopupClose();
  };

  const onCancelHandler = () => {
    methods.clearErrors();
    methods.setValue(fieldControls.Values, values);
    methods.setValue(fieldControls.Operator, operator);
    props.onModalPopupClose();
  };

  const onErrors = (errors: any, _e: any) => {
    console.log(errors);
  };

  return (
    <Modal
      titleAriaId={`${props.label} Pop Up`}
      isOpen={!props.hideDialog}
      onDismiss={props.onModalPopupClose}
      isBlocking={true}
      containerClassName={`${contentStyles.model} w-70`}
      overlay={{ styles: { root: contentStyles.backdrop } }}
    >
      <header className={`${contentStyles.header} amd-container-vcenter`}>
        <h2 className={`${contentStyles.heading} fs-24 fw-500`}>
          {props.label}
        </h2>
        <IconButton
          data-testid="dismiss-button"
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.onModalPopupClose}
        />
      </header>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmitHandler, onErrors)}
          noValidate
        >
          <section
            className={`${contentStyles.queryBody} amd-container-vcenter`}
          >
            <p className={`${contentStyles.where} fs-16 fw-500`}>WHERE</p>
            <QueryCondition
              selectedOperator={operator}
              selectedValues={values}
              fieldControlDetails={fieldControlDetails}
            />
          </section>

          <footer
            className={`${contentStyles.footer} amd-container-vcenter jc-spacebtwn`}
          >
            <DefaultButton
              className={contentStyles.filterButtons}
              text="Cancel"
              ariaLabel="Cancel"
              onClick={onCancelHandler}
            />
            <PrimaryButton
              text="Apply filter"
              ariaLabel="Apply filter"
              type="submit"
            />
          </footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default QueryFilterPopUp;

const cancelIcon: IIconProps = { iconName: "Cancel" };
const theme = getTheme();

const contentStyles = mergeStyleSets({
  model: {
    paddingTop: "4px",
    borderRadius: "4px",
    maxWidth: "1000px",
    position: "absolute",
    top: "15%",
    boxShadow: "4px 10px 12px 0px rgba(0, 0, 0, 0.25)",
  },
  header: {
    flex: "1 1 auto",
    padding: "0px 12px",
  },
  heading: {
    paddingLeft: "12px",
  },
  where: {
    color: "#808080",
    padding: "6px 12px",
  },
  queryBody: {
    padding: "10px 24px",
    borderBottom: "1px solid #CCCCCC",
    borderTop: "1px solid #CCCCCC",
  },
  footer: {
    padding: "18px 24px",
  },
  filterButtons: {
    color: "#0C66E1",
    borderColor: "#0C66E1",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.16)",
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    alignSelf: "flex-start",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
