import { mergeStyleSets } from "@fluentui/react";
import { AmdTextField } from "../../common/form-controls/uncontrolled/TextField/AmdTextField";
import { PastRecordsType } from "./types";

export const PastRecords: React.FunctionComponent<PastRecordsType> = ({
  record,
}: PastRecordsType): React.JSX.Element => {
  return (
    <span className="amd-container-hspacebtwn amd-container-vcenter mt-1 mb-1">
      <div className="ms-Grid-col ms-md4">
        <AmdTextField
          id="capacity"
          label=""
          isMandatory={false}
          isMultiline={false}
          className={`${contentStyles.inputbox}`}
          isReadOnly={true}
          defaultValue={record?.capacity?.toString()}
        />
      </div>
      <div className="ms-Grid-col ms-md4">
        <AmdTextField
          id="demand"
          label=""
          isMandatory={false}
          isMultiline={false}
          className={`${contentStyles.inputbox}`}
          isReadOnly={true}
          defaultValue={record?.demand?.toString()}
        />
      </div>
      <div className="ms-Grid-col ms-md4">
        <AmdTextField
          id="actual"
          label=""
          isMandatory={false}
          isMultiline={false}
          className={`${contentStyles.inputbox}`}
          isReadOnly={true}
          defaultValue={record?.actual?.toString()}
        />
      </div>
    </span>
  );
};
const contentStyles = mergeStyleSets({
  inputbox: {
    //width: "130px",
    background: "white",
    "div.ms-TextField-fieldGroup": {
      height: "30px !important",
      border: "1px solid rgb(204, 204, 204) !important",
    },
  },
});