export const HOPOTPContent: React.FunctionComponent<IHOPOTPContentProps> = (
  props: IHOPOTPContentProps
) => {
  return (
    <>
      <span className="fw-600 fc--gray800" data-testid="otp-details">
        OTP
      </span>
      {props.otpPerformance?.map((item: any) => {
        return (
          <div
            key={item.name}
            className="mt-1 amd-container-vcenter hop-otp-content jc-spacebtwn"
          >
            <span className="fw-400 fc--gray600">{item.name}</span>
            <div className="py-sm-1 px-sm-3 mr-sm-1">
              <span className="fw-600">{item.value}%</span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export interface IHOPOTPContentProps {
  otpPerformance?: any[];
}
