import { mergeStyleSets } from "@fluentui/react";
import "chart.js/auto";
import React from "react";
import { Chart } from "react-chartjs-2";
import { NoDataCard } from "../../no-data-card/NoDataCard";
import RadarChartPlugin from "./RadarChartPlugin";

export const AmdRadarChart: React.FunctionComponent<IAmdRadarChart> = (
  props: IAmdRadarChart
) => {
  const [options, setOptions] = React.useState({});

  React.useEffect(() => {
    const tmpOptions = {
      scales: {
        r: {
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            count: 5,
            maxTicksLimit: 5,
          },
          pointLabels: {
            font: {
              size: 16,
              family:
                "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        htmlLegend: {
          containerID: "radar-chart-legends",
        },
      },
    };
    setOptions(tmpOptions);
  }, []);

  return (
    <>
      {props.chartData &&
      props.chartData.labels &&
      props.chartData.labels.length > 0 ? (
        <div className={contentStyles.container} data-testid="radar-chart">
          <Chart
            type="radar"
            data={props.chartData}
            options={options}
            plugins={RadarChartPlugin}
          />
        </div>
      ) : (
        <NoDataCard message="No data found" />
      )}
    </>
  );
};

export interface IAmdRadarChart {
  chartData: any;
}

const contentStyles = mergeStyleSets({
  container: {
    width: 600,
    height: 600,
  },
});
