import { Panel, mergeStyleSets } from "@fluentui/react";
import React from "react";
import IFilters from "../../../models/filters/IFilters";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import FlightScheduleHelper from "../../../utils/flights/FlightScheduleHelper";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdDismiss } from "../../common/icons/dismiss/dismiss";
import { DismissType } from "../../common/icons/dismiss/dismissType";
import { AmdFilter } from "../../common/icons/filter/filter";
import { AmdPill } from "../../common/pill/pill";
import { FilterContainer } from "../filter-container/FilterContainer";
import "../filter.scss";

export interface IFilterPanelProps {
  onFiltersApplied: any;
  resourceType?: string;
  filters?: IFilters[];
  onUpdateFilter?: any;
  airport: string;
}

export const FilterPanel: React.FunctionComponent<IFilterPanelProps> = (
  filterPanelProps: IFilterPanelProps
) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const selectedItems = FlightScheduleHelper.getSelectedItems(
    filterPanelProps.filters ?? []
  );
  const onApplyFilter = () => {
    const flightListProps =
      FlightScheduleHelper.getFlightlistState(selectedItems);
    filterPanelProps.onFiltersApplied(flightListProps);
    setIsOpen(false);
  };
  const updateFilter = (updatedItems: IFilters[]) => {
    if (filterPanelProps.onUpdateFilter) {
      filterPanelProps.onUpdateFilter(updatedItems);
    }
  };

  const onClearFilter = () => {
    const updatedArray = FlightScheduleHelper.clearAllSelectedFilters(
      filterPanelProps.filters ?? [],
      selectedItems,
      filterPanelProps
    );
    updateFilter(updatedArray);
  };

  const onClearItem = (
    category?: string,
    key?: string,
    displayValue?: string
  ) => {
    const updatedItems = FlightScheduleHelper.clearFilterItem(
      filterPanelProps.filters ?? [],
      selectedItems,
      filterPanelProps,
      category,
      key,
      displayValue
    );
    updateFilter(updatedItems);
  };

  return (
    <>
      {filterPanelProps.filters && (
        <>
          <section className="filterPanelContainer amd-container-hspacebtwn amd-container-vcenter flex-flow-rowrev mb-sm-2">
            <div>
              <AmdButton
                type={AmdButtonType.DEFAULT}
                className="mr-1"
                onClick={onClearFilter}
              >
                <>
                  <span>Clear Filters</span>
                  <AmdDismiss className="ml-1" />
                </>
              </AmdButton>
              <AmdButton
                type={AmdButtonType.DEFAULT}
                onClick={() => {
                  setIsOpen(true);
                }}
                testId="filterIcon"
              >
                <AmdFilter />
              </AmdButton>
            </div>
            {!isOpen && selectedItems && selectedItems.length > 0 && (
              <div className={`overflow-x ${pillContainer.container}`}>
                {selectedItems.map((item: any) => {
                  return (
                    <span key={CommonHelper.getGuid()}>
                      <AmdPill
                        label={
                          item.type === "radio" ||
                          item.type === "text" ||
                          item.type === "tagpicker"
                            ? item.displayValue
                            : item?.value
                        }
                        className="mr-1"
                      >
                        <AmdButton
                          type={AmdButtonType.ICON_BUTTON}
                          onClick={() => {
                            onClearItem(item.name, item.key, item.displayValue);
                          }}
                          key={CommonHelper.getGuid()}
                        >
                          <AmdDismiss
                            dataTestId="clearItem"
                            type={DismissType.CIRCLE}
                            key={CommonHelper.getGuid()}
                          />
                        </AmdButton>
                      </AmdPill>
                    </span>
                  );
                })}
              </div>
            )}
          </section>
          <Panel
            isLightDismiss
            onDismiss={() => {
              setIsOpen(false);
            }}
            isOpen={isOpen}
            hasCloseButton={false}
            className="filerPanel"
            allowTouchBodyScroll={true}
          >
            <FilterContainer
              onApplyFilter={onApplyFilter}
              onClearFilter={onClearFilter}
              onDismissFilter={() => {
                setIsOpen(false);
              }}
              onUpdateFilter={updateFilter}
              selectedFilters={filterPanelProps.filters}
              airport={filterPanelProps.airport}
            />
          </Panel>
        </>
      )}
    </>
  );
};

const pillContainer = mergeStyleSets({
  container: {
    maxWidth: "75%",
    whiteSpace: "nowrap",
    paddingBottom: 10,
  },
});
