import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { NotificationContext } from "../../../../../context/NotificationContext";
import { IEventResponse } from "../../../../../models/events/IEventResponse";
import { getAirportCode } from "../../../../../store/airport/AirportSelector";
import { useAppSelector } from "../../../../../store/StoreHooks";
import { CommonHelper } from "../../../../../utils/common/CommonHelper";
import { MESSAGEBAR_TYPE } from "../../../../../utils/common/Constants";
import DateHelper from "../../../../../utils/common/DateHelper";
import { EventManagementHelper } from "../../../../../utils/events/EventManagementHelper";

interface IEventTasksProps {
  eventId?: string;
  isAOM?: boolean;
}

export const EventTasks: React.FunctionComponent<IEventTasksProps> = (
  props: IEventTasksProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const eventId = EventManagementHelper.getEventIdFromEventTaskURL(
    props?.eventId ?? ""
  );
  const [eventResponse, setEventResponse] = useState<IEventResponse>();
  const [eventResponseClone, setEventResponseClone] =
    useState<IEventResponse>();

  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [lastSavedTimeStamp, setLastSavedTimeStamp] = useState("");

  const { addNotification } = React.useContext(NotificationContext);
  const airport = useAppSelector((state) => getAirportCode(state));

  useEffect(() => {
    CommonHelper.getCurrentUserEmail(msalInstance).then((userEmail: string) => {
      setCurrentUserEmail(userEmail);
    });

    let isMounted = true;
    if (airport !== "" && eventId) {
      EventManagementHelper.getEventDetailById(msalInstance, eventId, airport)
        .then((response: any) => {
          if (isMounted && response.data) {
            setEventResponse(response.data);
            const eventResponseCloneObj = JSON.parse(
              JSON.stringify(response.data)
            );
            setEventResponseClone(eventResponseCloneObj);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [airport, eventId, lastSavedTimeStamp]);

  useEffect(() => {
    if (
      airport !== "" &&
      eventResponse &&
      eventResponseClone &&
      currentUserEmail !== ""
    ) {
      const adaptiveCardTemplate = eventResponse.template.tasks;
      const adaptiveCardData = eventResponse.data;
      const roles = eventResponse.roleToEmailsMap;
      let userRoles = [];
      let taskFields: any;

      // render the card
      const { adaptiveCard } = EventManagementHelper.renderAdaptiveCard(
        "tasks-section",
        adaptiveCardTemplate,
        adaptiveCardData
      );

      // Check if logged in user is AOM or not
      if (!props?.isAOM) {
        userRoles = EventManagementHelper.getLoggedInUserRoles(
          roles,
          currentUserEmail
        );

        taskFields = EventManagementHelper.getTaskFields(
          userRoles,
          adaptiveCardData,
          true
        );

        // Disable fields if the user is not supposed to edit the field, based on role
        EventManagementHelper.disableAdaptiveCardFields(taskFields);

        adaptiveCard.onInlineCardExpanded = function (_action: any) {
          EventManagementHelper.disableAdaptiveCardFields(taskFields);
        };
      }

      adaptiveCard.onExecuteAction = function (action: any) {
        const currentStepId = action._propertyBag.id.split(".")[0];
        const nextStepId = EventManagementHelper.getNextStepNumber(
          currentStepId,
          eventResponse.workflow,
          action.data
        );
        EventManagementHelper.markStepCompletion(
          currentStepId,
          nextStepId,
          eventResponseClone?.data,
          action
        );

        EventManagementHelper.updateEvent(
          msalInstance,
          EventManagementHelper.getEventIdFromEventTaskURL(
            props?.eventId ?? ""
          ),
          action?.data,
          airport
        ).then((response: any) => {
          if (response.data) {
            addNotification(MESSAGEBAR_TYPE.SUCCESS, response.data);
            setLastSavedTimeStamp(
              DateHelper.getFormattedDateTime(new Date(), "x")
            );
          }
        });
      };
    }
  }, [airport, eventId, eventResponse, eventResponseClone, lastSavedTimeStamp]);

  return (
    <div
      id="tasks-section"
      data-testid="tasks-section"
      className={contentStyles.root}
    >
      Loading...
    </div>
  );
};

const contentStyles = mergeStyleSets({
  root: {
    margin: "0px !important",
    marginLeft: "-10px !important",
  },
});
