import { useEffect, useState } from "react";
import { IEventResponse } from "../../../models/events/IEventResponse";
import { EventManagementHelper } from "../../../utils/events/EventManagementHelper";

const useGetEventDetailByIdEffect = (
  msalInstance: any,
  airport: string,
  eventId?: string
) => {
  const [eventResponse, setEventResponse] = useState<IEventResponse>();

  useEffect(() => {
    let isMounted = true;
    if (airport !== "" && eventId) {
      EventManagementHelper.getEventDetailById(msalInstance, eventId, airport)
        .then((response: any) => {
          if (isMounted && response.data) {
            setEventResponse(response.data);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [airport, eventId]);

  return { eventResponse };
};

export default useGetEventDetailByIdEffect;
