import { Button } from "@fluentui/react-button";
import { makeStyles } from "@fluentui/react-components";
import { ClockRegular, PinFilled, PinRegular } from "@fluentui/react-icons";
import * as React from "react";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../context/LayoutContext";
import { $cloud30, $white } from "../../cssjs/colors";
import { IAirport } from "../../models/airports/AirportConfig";
import AirportCardDetails from "./AirportCardDetails";

interface IAirportCardProps {
  airport: any;
  time: Date;
  onPinClickHandler: (id: string) => void;
  overviewSideBarHandler?: (
    event: Event | undefined,
    airport: IAirport
  ) => void;
}

const AirportCard: React.FunctionComponent<IAirportCardProps> = ({
  airport,
  time,
  onPinClickHandler,
}) => {
  const classes = useStyles();
  const { isMapView } = React.useContext(LayoutContext);
  const airportTime = new Date(time);
  const { hours, minutes, sign } = airport.timeZoneDiff || {
    hours: 0,
    minutes: 0,
  };

  airportTime.setHours(airportTime.getHours() + hours);
  airportTime.setMinutes(airportTime.getMinutes() + minutes);

  const airportTimeStr = airportTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const timeContent =
    hours === 0 && minutes === 0
      ? "Same time zone as you"
      : `Your local time zone ${sign} ${Math.abs(hours)} hr ${Math.abs(
          minutes
        )} mins`;

  const pinIcon = airport.isPinned ? (
    <PinFilled
      data-testid="pinned"
      className={classes.pinIconFilled}
      fontSize={16}
    />
  ) : (
    <PinRegular
      data-testid="unpinned"
      className={classes.pinIconFilled}
      fontSize={16}
    />
  );

  const airportTitle = `${airport.code} - ${airport.name}`;
  const mapViewClass = isMapView ? classes.overflowEllipsis : undefined;
  const timeText = isMapView
    ? airportTimeStr
    : `${airportTimeStr} - ${timeContent}`;
  const sectionContainerClass = `${
    isMapView
      ? `mapContainer ${classes.mapViewCardContainer}`
      : classes.cardContainer
  }`;
  return (
    <section className={sectionContainerClass} data-testid="airport-card">
      <div className="amd-container-hspacebtwn mb-2">
        <header className={`d-flex ${classes.cardTitle}`}>
          <Button
            className={classes.pinIconButton}
            onClick={() => onPinClickHandler(airport.id)}
            icon={pinIcon}
          />
          <p
            className={`d-flex amd-container-vcenter amd-container-vcenter ${
              isMapView ? classes.mapViewTitle : ""
            } ${classes.cardTitle}`}
          >
            <Link
              to={`/overview/${airport.code}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <span className={`${classes.airportFont} ${mapViewClass}`}>
                {airportTitle}
              </span>
            </Link>
            <span
              className={`amd-container-vcenter ${classes.timeZone} ${
                isMapView ? classes.mapViewTimeZone : classes.timeZone
              }`}
            >
              <ClockRegular className={classes.icon} />
              {timeText}
            </span>
          </p>
        </header>
      </div>
      <AirportCardDetails
        otpData={airport.otp}
        eventData={airport.events}
        notificationData={airport.notifications}
        isMapView={isMapView}
      />
    </section>
  );
};

export default AirportCard;

const useStyles = makeStyles({
  cardContainer: {
    padding: "12px 16px",
    borderRadius: "10px",
    border: "0.5px solid rgba(230, 230, 230, 1)",
    backgroundColor: "white",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  mapViewCardContainer: {
    padding: "12px",
    border: `0.5px solid ${$cloud30}`,
    backgroundColor: $white,
    cursor: "pointer",
  },
  pinIconButton: {
    border: "none",
    padding: "0px",
  },
  pinIconFilled: {
    color: "#0C66E1",
  },
  cardTitle: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",

    margin: "0",
  },
  mapViewTitle: {
    maxWidth: "339px",
    margin: "0",
  },
  airportFont: {
    font: "Sorce Sans Pro",
    fontSize: "16px",
    color: "#005eb8",
    fontWeight: "600",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  overflowEllipsis: {
    textOverflow: "ellipsis",
    width: "288px",
    display: "block",
  },
  timeZone: {
    fontSize: "13px",
    fontWeight: "400",
    color: "#1A1A1A",
    width: "clamp(267px, 23%, 267px)",
  },
  mapViewTimeZone: {
    width: "auto",
  },
  icon: {
    position: "relative",
    top: "7%",
    paddingRight: "1px 1px 0",
  },
});
