import { Stack, mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import {
  IPillButton,
  IPillButtonProps,
} from "../../../models/flights/IFlightPillButton";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";

export const FlightPillButtonFilter: React.FunctionComponent<
  IPillButtonProps
> = (pillButtonProps: IPillButtonProps) => {
  const [selectedButtons, setSelectedButtons] = useState<any>(["|All"]);
  const pillButtonContainerStyle: React.CSSProperties = {
    maxWidth: pillButtonProps.noScroll ? "" : "75%",
    overflowX: "auto",
    padding: "10px 0",
  };

  const handleButtonClick = (buttonName: string, buttonText: string) => {
    const buttonValue = `${buttonName}|${buttonText}`;
    if (selectedButtons.includes(buttonValue)) {
      // If the button is already selected, remove it from the list of selected buttons
      setSelectedButtons(
        selectedButtons.filter((value: string) => value !== buttonValue)
      );
    } else {
      // If the button is not already selected, add it to the list of selected buttons
      setSelectedButtons([...selectedButtons, buttonValue]);
    }
  };

  useEffect(() => {
    // callback function for setSelectedButtons hook
    pillButtonProps.onPillButtonClick(selectedButtons);
  }, [selectedButtons]);

  return (
    <Stack
      horizontal
      className="overflow-x"
      style={pillButtonContainerStyle}
      data-testid="flight-pill"
    >
      {pillButtonProps.buttons.map((button: IPillButton) => {
        return (
          <AmdButton
            className={`${
              getPillButtonStyle(selectedButtons, button.name, button.text)
                .selected
            } pillButton`}
            type={AmdButtonType.PILL}
            text={button.text}
            key={CommonHelper.getGuid()}
            onClick={() => handleButtonClick(button.name, button.text)}
          />
        );
      })}
    </Stack>
  );
};

const getPillButtonStyle = (
  selectedButtons: string[],
  buttonName: string,
  buttonText: string
): any => {
  if (buttonName === "all") {
    return mergeStyleSets({
      selected: {
        backgroundColor: "#000 !important",
        color: "#fff !important",
      },
    });
  }

  if (selectedButtons.includes(`${buttonName}|${buttonText}`)) {
    return mergeStyleSets({
      selected: {
        backgroundColor: "#000 !important",
        color: "#fff !important",
      },
    });
  }
  return mergeStyleSets({
    selected: {},
  });
};
