import { HOPHelper } from "../../../../utils/health-of-operations/HOPHelper";

const RadarChartPlugin = [
  {
    id: "radar-chart-legends",
    afterUpdate(chart: any) {
      const ul = HOPHelper.getOrCreateLegendList(chart, "radar-chart-legends");

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: any) => {
        const li = document.createElement("li");
        li.classList.add("amd-container-vcenter");
        li.classList.add("cursor-pointer");
        li.classList.add("ml-sm-2");

        li.onclick = () => {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement("span");
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = `4px 0 0 0`;
        boxSpan.style.display = "inline-block";
        boxSpan.style.flexShrink = "0px";
        boxSpan.style.height = "0px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "20px";
        boxSpan.style.borderStyle = HOPHelper.getBorderStyle(item);

        // Text
        const textContainer = document.createElement("span");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = "0px";
        textContainer.style.padding = "0px";
        textContainer.style.textDecoration = HOPHelper.getTextDecoration(item);

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  },
];
export default RadarChartPlugin;
