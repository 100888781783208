import { IDropdownOption, ITag } from "@fluentui/react";
import { Button, makeStyles } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import { useState } from "react";
import GlobalFilterEnum from "../../../enum/global-filter/GlobalFilterEnum";
import { AmdGlobalFilter } from "../../common/icons/filter/GlobalFilterIcon";
import QueryFilterPopUp from "../query-type-filter/QueryFilterPopUp";

// GlobalFilter field controls
const { filterColumns, filterColumnsDetails } = GlobalFilterEnum;
const { fieldControls } =
  filterColumnsDetails[filterColumns[0] as keyof typeof filterColumnsDetails];

interface IGlobalFilterProps {
  label?: string;
}

const GlobalFilter: React.FC<IGlobalFilterProps> = ({
  label = "",
}: IGlobalFilterProps) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const classes = useStyles();

  const [selectedOperator, setSelectedOperator] = useState<IDropdownOption>();
  const [filteredAirlines, setFilteredAirlines] = useState<ITag[]>();

  const appliedFilters = {
    [fieldControls.Name]: {
      operator: selectedOperator,
      values: filteredAirlines,
    },
  };

  const onApplyFilter = (data: any) => {
    setFilteredAirlines(data[fieldControls.Values]);
    setSelectedOperator(data[fieldControls.Operator]);
  };

  return (
    <div data-testid="global-filter">
      <Button
        className={classes.filterIcon}
        onClick={showModal}
        icon={{
          as: "span",
          children: <AmdGlobalFilter color="#005EB8" />,
        }}
      >
        {label}
      </Button>
      <QueryFilterPopUp
        label="Global Filters"
        hideDialog={!isModalOpen}
        filterSettings={GlobalFilterEnum}
        onApplyFilter={onApplyFilter}
        appliedFilters={appliedFilters}
        onModalPopupClose={hideModal}
      />
    </div>
  );
};

export default GlobalFilter;

const useStyles = makeStyles({
  filterIcon: {
    border: "1px solid #0C66E1",
    padding: "5px",
    margin: "0 0 0 10px",
    color: "#0C66E1",
  },
});
