export type DeDupKPIObjectsType = {
  prevData: UpdateKpiPopUpType[];
  time: string;
  type: string;
  value: string;
  unit: string;
};

export type UpdateKpiPopUpType = {
  time: string;
  type: string;
  value?: number;
  unit: string;
};

export type UpdateKpiPopUpReturnType = {
  time: string;
  capacity: number;
  demand: number;
  actual: number;
  unit: string;
};

export enum KPIUnit {
  PerHour = "per_hour",
}

export function deDupKPIObjects({
  prevData,
  time,
  value,
  type,
  unit,
}: DeDupKPIObjectsType): UpdateKpiPopUpType[] | [] {
  if (!time && !type && !value) {
    return [];
  }
  let typeArray = [];
  if (!prevData) {
    return [];
  }

  const tmpArrData = [...prevData] || [];
  const valueNum = parseInt(value, 10);

  tmpArrData?.push({ time, value: valueNum, type, unit });
  tmpArrData.reverse();
  typeArray =
    Array.from(new Set(tmpArrData?.map((a) => a.time)))?.map((time) => {
      return tmpArrData?.find((a) => a.time === time);
    }) || [];

  return typeArray as UpdateKpiPopUpType[];
}
