import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdTeams: React.FunctionComponent<IAmdIconProps> = ({
  ...props
}) => {
  return (
    <span className={props.className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        data-testid="teams-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.3721 6.4H12.7621L11.2231 7.056V10.312C11.2611 10.8694 11.5369 11.393 11.9944 11.7757C12.4518 12.1585 13.0564 12.3716 13.6846 12.3716C14.3129 12.3716 14.9174 12.1585 15.3749 11.7757C15.8324 11.393 16.1082 10.8694 16.1461 10.312V7.056C16.1463 6.96709 16.1261 6.87909 16.0867 6.79734C16.0474 6.71559 15.9898 6.6418 15.9174 6.58044C15.845 6.51909 15.7594 6.47146 15.6656 6.44044C15.5719 6.40942 15.472 6.39566 15.3721 6.4Z"
          fill="#5059C9"
        />
        <path
          d="M14.4001 5.59999C15.3942 5.59999 16.2001 4.88365 16.2001 3.99999C16.2001 3.11634 15.3942 2.39999 14.4001 2.39999C13.406 2.39999 12.6001 3.11634 12.6001 3.99999C12.6001 4.88365 13.406 5.59999 14.4001 5.59999Z"
          fill="#5059C9"
        />
        <path
          d="M6.1379 6.39999H12.7619C12.9576 6.39999 13.1453 6.46911 13.2837 6.59213C13.4221 6.71516 13.4999 6.88201 13.4999 7.05599V10.8C13.4999 11.7548 13.0732 12.6704 12.3137 13.3456C11.5542 14.0207 10.524 14.4 9.4499 14.4C8.37578 14.4 7.34564 14.0207 6.58612 13.3456C5.8266 12.6704 5.3999 11.7548 5.3999 10.8V7.05599C5.3999 6.88201 5.47766 6.71516 5.61606 6.59213C5.75446 6.46911 5.94217 6.39999 6.1379 6.39999Z"
          fill="#7B83EB"
        />
        <path
          d="M9.9002 6.40001C11.3914 6.40001 12.6002 5.32549 12.6002 4.00001C12.6002 2.67452 11.3914 1.60001 9.9002 1.60001C8.40903 1.60001 7.2002 2.67452 7.2002 4.00001C7.2002 5.32549 8.40903 6.40001 9.9002 6.40001Z"
          fill="#7B83EB"
        />
        <path
          opacity="0.5"
          d="M5.39993 7.05599V10.8C5.39716 11.5114 5.63209 12.2074 6.07493 12.8H9.87293C10.3503 12.8 10.8082 12.6314 11.1457 12.3314C11.4833 12.0313 11.6729 11.6243 11.6729 11.2V6.39999H6.07493C5.89031 6.41405 5.7184 6.48933 5.59335 6.61086C5.4683 6.73239 5.39925 6.89129 5.39993 7.05599Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M10.773 4.8H7.35303C7.49998 5.16908 7.74627 5.5006 8.07043 5.76565C8.3946 6.0307 8.78679 6.22124 9.2128 6.32064C9.63882 6.42004 10.0857 6.4253 10.5145 6.33594C10.9434 6.24659 11.3411 6.06534 11.673 5.808V5.6C11.673 5.38783 11.5782 5.18435 11.4094 5.03432C11.2406 4.88429 11.0117 4.8 10.773 4.8Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M5.39993 7.05599V10.8C5.39716 11.5114 5.63209 12.2074 6.07493 12.8H9.87293C10.3503 12.8 10.8082 12.6314 11.1457 12.3314C11.4833 12.0313 11.6729 11.6243 11.6729 11.2V6.39999H6.07493C5.89031 6.41405 5.7184 6.48933 5.59335 6.61086C5.4683 6.73239 5.39925 6.89129 5.39993 7.05599Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M10.773 4.8H7.35303C7.49998 5.16908 7.74627 5.5006 8.07043 5.76565C8.3946 6.0307 8.78679 6.22124 9.2128 6.32064C9.63882 6.42004 10.0857 6.4253 10.5145 6.33594C10.9434 6.24659 11.3411 6.06534 11.673 5.808V5.6C11.673 5.38783 11.5782 5.18435 11.4094 5.03432C11.2406 4.88429 11.0117 4.8 10.773 4.8Z"
          fill="black"
        />
        <path
          d="M9.8998 4H2.6998C2.20275 4 1.7998 4.35817 1.7998 4.8V11.2C1.7998 11.6418 2.20275 12 2.6998 12H9.8998C10.3969 12 10.7998 11.6418 10.7998 11.2V4.8C10.7998 4.35817 10.3969 4 9.8998 4Z"
          fill="#4B53BC"
        />
        <path
          d="M9 6.40001H7.2V10.4H6.3V6.40001H4.5V5.60001H9V6.40001Z"
          fill="white"
        />
      </svg>
    </span>
  );
};
