import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  DialogFooter,
  FontWeights,
  getTheme,
  mergeStyleSets,
  PrimaryButton,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationContext } from "../../../context/NotificationContext";
import { UpdatePopUpEnum } from "../../../enum/EventEnum";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { getWeatherDisruptionState } from "../../../store/weather-disruption/weatherDisruptionSelector";
import { setDisruptionKpi } from "../../../store/weather-disruption/WeatherDisruptionSlice";
import { MESSAGEBAR_TYPE } from "../../../utils/common/Constants";
import DateHelper from "../../../utils/common/DateHelper";
import {
  deDupKPIObjects,
  UpdateKpiPopUpType,
} from "../../../utils/events/deDupKPIObjects";
import { EventManagementHelper } from "../../../utils/events/EventManagementHelper";
import { formatKPIPayloadData } from "../../../utils/events/formatKPIPayloadData";
import { groupKPIObjects } from "../../../utils/events/groupKPIObjects";
import { AmdCheckbox } from "../../common/form-controls/controlled/Checkbox/AmdCheckbox";
import { AmdModal } from "../../common/form-controls/uncontrolled/Modal/AmdModal";
import { AmdTextField } from "../../common/form-controls/uncontrolled/TextField/AmdTextField";
import { AmdClock } from "../../common/icons/clock/Clock";
import { AmdEdit } from "../../common/icons/edit/Edit";
import { CurrentRecord } from "./CurrentRecord";
import { mappedResourceType } from "./mappedResourceType";
import { PastRecords } from "./PastRecords";
import { FormatedKPI, IUpdateKpiPopupProps, TimeseriesDatum } from "./types";
import { contentStyles, modalStyles } from "./UpdateKpiPopupStyles";

export const Topic: React.FunctionComponent = () => {
  return (
    <span className="amd-container-hspacebtwn ">
      <div className="fw-600 text-c ms-Grid-col ms-md3">Capacity</div>
      <div className="fw-600 text-c ms-Grid-col ms-md3">Demand</div>
      <div className="fw-600 text-c ms-Grid-col ms-md3">Actual</div>
    </span>
  );
};

export const UpdateKpiPopup: React.FunctionComponent<IUpdateKpiPopupProps> = ({
  hideDialog,
  onModalPopupClose,
  selectedKpi,
  airport,
}) => {
  const dispatch = useAppDispatch();

  const weatherDisruptionState = useAppSelector((state: any) =>
    getWeatherDisruptionState(state)
  );

  const {
    selectedKpi: selectedKpiTitle,
    selectedKpiId,
    selectedPlanningData,
  } = weatherDisruptionState;

  const methods = useForm();
  const [sTime] = React.useState<any>({
    start: EventManagementHelper.getCurrentTime(airport),
  });
  const { addNotification } = useContext(NotificationContext);
  const [formData, setFormData] = useState<UpdateKpiPopUpType[] | []>([]);

  const [formNewData, setFormNewData] = useState<FormatedKPI[] | {}[]>([]);
  const [payloadData, setPayloadData] = useState<FormatedKPI[] | {}[]>([]);
  const msalInstance = useMsal().instance as PublicClientApplication;
  const airportCode = airport;
  const dateObj = DateHelper.getCurrentDateTimeForAirport(airportCode);
  const dateFormat = DateHelper.formatDateTime(dateObj, "YYYY-MM-DD");

  useEffect(() => {
    if (formData?.length) {
      const formattedPayloadData = formatKPIPayloadData(formData);
      setFormNewData(formattedPayloadData);
    }
  }, [formData]);

  useEffect(() => {
    // @ts-ignore
    if (formNewData?.some((e) => e.time !== "")) {
      const results = groupKPIObjects(formNewData);
      setPayloadData(results);
    }
  }, [formNewData]);

  const [showPastData, setShowPastData] = useState<boolean>(false);
  const [tmpCapacityData, setTmpCapacityData] = useState<
    UpdateKpiPopUpType[] | []
  >([]);
  const [tmpDemandData, setTmpDemandData] = useState<UpdateKpiPopUpType[] | []>(
    []
  );
  const [tmpActualData, setTmpActualData] = useState<UpdateKpiPopUpType[] | []>(
    []
  );

  useEffect(() => {
    const tmpData = [...tmpCapacityData, ...tmpDemandData, ...tmpActualData];

    const sortedData = [...tmpData]?.sort((a, b) => {
      if (a.time < b.time) {
        return -1;
      }
      return 0;
    });
    setFormData(sortedData);
  }, [
    JSON.stringify(tmpCapacityData),
    JSON.stringify(tmpDemandData),
    JSON.stringify(tmpActualData),
  ]);

  useEffect(() => {
    methods.reset(formData);
    methods.reset(selectedKpi);
  }, []);

  const onCheckboxClick = () => {
    setShowPastData((v) => !v);
  };

  const onChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement>,
    time: string,
    type1: string
  ): void => {
    let uniqueCapacity = [];
    let uniqueDemand = [];
    let uniqueActual = [];
    const { value } = event.target;

    if (!value && !time && !type1) {
      return;
    }
    const unit = "per_hour";
    if (type1 === UpdatePopUpEnum.Capacity) {
      const requestObj = {
        prevData: tmpCapacityData ?? [],
        time,
        value,
        type: type1,
        unit,
      };
      uniqueCapacity = deDupKPIObjects(requestObj);

      setTmpCapacityData(uniqueCapacity as UpdateKpiPopUpType[]);
    }
    if (type1 === UpdatePopUpEnum.Demand) {
      const requestObj = {
        prevData: tmpDemandData ?? [],
        time,
        value,
        type: type1,
        unit,
      };
      uniqueDemand = deDupKPIObjects(requestObj);
      setTmpDemandData(uniqueDemand as UpdateKpiPopUpType[]);
    }
    if (type1 === UpdatePopUpEnum.Actual) {
      const requestObj = {
        prevData: tmpActualData ?? [],
        time,
        value,
        type: type1,
        unit,
      };
      uniqueActual = deDupKPIObjects(requestObj);
      setTmpActualData(uniqueActual as UpdateKpiPopUpType[]);
    }
  };

  const timeseriesData = selectedPlanningData?.timeseriesData ?? [];
  const pastRecordsArr = timeseriesData?.filter((row: { time: any }) => {
    const { time } = row;
    return time < sTime.start;
  });
  const currentRecordArr = timeseriesData?.filter((row: { time: any }) => {
    const { time } = row;
    return time === sTime.start;
  });
  const futureRecordsArr = timeseriesData?.filter((row: { time: any }) => {
    const { time } = row;
    return time > sTime.start;
  });

  const pastInputtedRecords = pastRecordsArr?.map(
    (record: { time: any }) => record?.time
  );
  const futureInputtedRecords = futureRecordsArr?.map(
    (record: { time: any }) => record?.time
  );
  const futureTimeArr = EventManagementHelper.generateAfterTimeList(airport);
  const pastTimeArr = EventManagementHelper.generateBeforeTimeList(airport);
  const futureEmptyRecordsArray = futureTimeArr.filter(
    (val) => !futureInputtedRecords?.includes(val)
  );

  const pastEmptyRecordsArray = pastTimeArr.filter(
    (val) => !pastInputtedRecords?.includes(val)
  );

  const onErrors = (errors: any, _e: any) => {
    console.log("onSubmitHandler", errors);
  };

  const onSubmitHandler = async () => {
    const mergedData = [...timeseriesData, ...payloadData] || [];
    const cleanedData = groupKPIObjects(mergedData);
    const payload = {
      interval: 30,
      timeseriesData: [...cleanedData],
    };

    const resourceType = mappedResourceType(selectedKpiId);

    const response = await EventManagementHelper.setCrisisboardPlanningTableKpi(
      msalInstance,
      airportCode,
      resourceType,
      dateFormat,
      payload
    );
    if (response.status === 200) {
      addNotification(
        MESSAGEBAR_TYPE.SUCCESS,
        `Table records updated successfully!`
      );
    }
    dispatch(
      setDisruptionKpi({
        selectedKpiId: "",
        selectedKpi: "",
        selectedPlanningData: null,
      })
    );
    onModalPopupClose();
  };

  return (
    <div id="updateKpiPopup" data-testid="updateKpiPopup">
      <AmdModal
        isOpen={!hideDialog}
        header={selectedKpiTitle}
        onClose={onModalPopupClose}
        containerClassName={contentStyles.container}
        prefixImageIconBeforeText={true}
        imageIcon={<AmdEdit className="mr-1" />}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={(event: any) => {
              event.stopPropagation();
              methods.handleSubmit(onSubmitHandler, onErrors)(event);
            }}
            noValidate
          >
            <div
              id="updateKPIContainer"
              data-testid="updateKPIContainer"
              className={`${modalStyles.body}`}
            >
              <div className="mt-1">
                <p className="mb-1">
                  Editing values is only enabled for all data since current
                  hour.
                </p>
                <div className={`amd-section-shadow ${contentStyles.bg}`}>
                  <div className="ms-Grid mt-1" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="pl-0 pr-0 ms-Grid-col ms-md4">
                        <AmdCheckbox
                          controlId="pastValues"
                          label="Show past values"
                          defaultValue={showPastData ? true : false}
                          onChange={onCheckboxClick}
                        />
                      </div>
                      <Topic />
                    </div>
                    <div className="ms-Grid-row">
                      {showPastData &&
                        pastRecordsArr?.map((record: TimeseriesDatum) => {
                          return (
                            <div
                              key={`pastRecordskey-${record?.time}`}
                              className="amd-container-vcenter"
                            >
                              <div className="amd-container-vcenter ms-Grid-col ms-md5">
                                <AmdClock className="mt-1" />
                                {record?.time}
                              </div>
                              <PastRecords record={record} />
                            </div>
                          );
                        })}
                    </div>
                    <div className="ms-Grid-row">
                      {currentRecordArr?.length
                        ? currentRecordArr?.map((record: TimeseriesDatum) => {
                            return (
                              <CurrentRecord
                                key={`currentRecordkey-${record.time}`}
                                record={record}
                                onChangeEvent={onChangeEvent}
                              />
                            );
                          })
                        : null}
                    </div>
                    <div className="ms-Grid-row">
                      {futureRecordsArr?.map(
                        (record: TimeseriesDatum, index: number) => (
                          <div
                            key={`futureRecordkey-${record?.time}`}
                            className="amd-container-vcenter"
                          >
                            <div className="amd-container-vcenter ms-Grid-col ms-md5">
                              <AmdClock className="mt-1" />
                              {record?.time}
                            </div>
                            <span className="amd-container-hspacebtwn amd-container-vcenter mt-1 mb-1">
                              <div className="ms-Grid-col ms-md4">
                                <AmdTextField
                                  id={`${UpdatePopUpEnum.Capacity}${index}`}
                                  label=""
                                  isMandatory={false}
                                  isMultiline={false}
                                  className={`${contentStyles.inputbox}`}
                                  defaultValue={record?.capacity?.toString()}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    onChangeEvent(
                                      event,
                                      record?.time,
                                      UpdatePopUpEnum.Capacity
                                    );
                                  }}
                                />
                              </div>
                              <div className="ms-Grid-col ms-md4">
                                <AmdTextField
                                  id={`${UpdatePopUpEnum.Demand}${index}`}
                                  label=""
                                  isMandatory={false}
                                  isMultiline={false}
                                  className={`${contentStyles.inputbox}`}
                                  defaultValue={record?.demand?.toString()}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    onChangeEvent(
                                      event,
                                      record?.time,
                                      UpdatePopUpEnum.Demand
                                    );
                                  }}
                                />
                              </div>
                              <div className="ms-Grid-col ms-md4">
                                <AmdTextField
                                  id={`${UpdatePopUpEnum.Actual}${index}`}
                                  label=""
                                  isMandatory={false}
                                  isMultiline={false}
                                  className={`${contentStyles.inputbox}`}
                                  defaultValue={record?.actual?.toString()}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    onChangeEvent(
                                      event,
                                      record?.time,
                                      UpdatePopUpEnum.Actual
                                    );
                                  }}
                                />
                              </div>
                            </span>
                          </div>
                        )
                      )}
                    </div>
                    <div className="ms-Grid-row">
                      {futureEmptyRecordsArray?.map((time, index) => (
                        <div
                          key={`futureEmptyRecordskey-${time}`}
                          className="amd-container-vcenter"
                        >
                          <div className="amd-container-vcenter ms-Grid-col ms-md5">
                            <AmdClock className="mt-1" />
                            {time}
                          </div>
                          <span className="amd-container-hspacebtwn amd-container-vcenter mt-1 mb-1">
                            <div className="ms-Grid-col ms-md4">
                              <AmdTextField
                                id={`${UpdatePopUpEnum.Capacity}${index}`}
                                label=""
                                isMandatory={false}
                                isMultiline={false}
                                className={`${contentStyles.inputbox}`}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  onChangeEvent(
                                    event,
                                    time,
                                    UpdatePopUpEnum.Capacity
                                  );
                                }}
                              />
                            </div>
                            <div className="ms-Grid-col ms-md4">
                              <AmdTextField
                                id={`${UpdatePopUpEnum.Demand}${index}`}
                                label=""
                                isMandatory={false}
                                isMultiline={false}
                                className={`${contentStyles.inputbox}`}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  onChangeEvent(
                                    event,
                                    time,
                                    UpdatePopUpEnum.Demand
                                  );
                                }}
                              />
                            </div>
                            <div className="ms-Grid-col ms-md4">
                              <AmdTextField
                                id={`${UpdatePopUpEnum.Actual}${index}`}
                                label=""
                                isMandatory={false}
                                isMultiline={false}
                                className={`${contentStyles.inputbox}`}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  onChangeEvent(
                                    event,
                                    time,
                                    UpdatePopUpEnum.Actual
                                  );
                                }}
                              />
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <DialogFooter>
                  <DefaultButton
                    onClick={() => {
                      onModalPopupClose();
                    }}
                    text="Cancel"
                  />
                  <PrimaryButton type="submit" text="Update" />
                </DialogFooter>
              </div>
            </div>
          </form>
        </FormProvider>
      </AmdModal>
    </div>
  );
};
