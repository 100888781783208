import { mergeStyleSets } from "@fluentui/react";
import { InteractionTag, InteractionTagPrimary, TagGroup } from "@fluentui/react-components";
import { PinFilled, PinRegular, StackFilled, StackRegular } from "@fluentui/react-icons";
import React, { useState } from "react";
import {
  $allBgSelected,
  $allBorderSelected,
  $allTextColorSelected,
  $goodBg,
  $goodBgSelected,
  $goodTextColor,
  $goodTextColorHover,
  $moderateBg,
  $moderateBgSelected,
  $moderateTextColor,
  $moderateTextColorSelected,
  $pinnedBg,
  $pinnedBgSelected,
  $pinnedTextColor,
  $pinnedTextColorSelected,
  $poorBg,
  $poorBgSelected,
  $poorTextColor,
  $poorTextColorSelected,
  $primary,
  $white,
} from "../../cssjs/colors";
import HOPEnum from "../../enum/HOPEnum";
import { AmdAmber } from "../common/icons/status/amber/Amber";
import { AmdGreen } from "../common/icons/status/green/Green";
import { AmdRed } from "../common/icons/status/red/red";

interface IAirportStatusTags {
  airportStatusCount: any;
  onTagClickHandler: (type: string) => void;
}

const currentColor = "currentColor";

export const AirportStatusTags: React.FunctionComponent<IAirportStatusTags> = ({
  airportStatusCount,
  onTagClickHandler,
}) => {
  const [selectedTagButton, setSelectedTag] = useState("");

  const isAllTagSelected = selectedTagButton === HOPEnum.HOPDataStatus.All;
  const isPinnedTagSelected = selectedTagButton === HOPEnum.HOPDataStatus.Pinned;
  const isGoodTagSelected = selectedTagButton === HOPEnum.HOPDataStatus.Good;
  const isModerateTagSelected = selectedTagButton === HOPEnum.HOPDataStatus.Moderate;
  const isPoorTagSelected = selectedTagButton === HOPEnum.HOPDataStatus.Poor;

  const allTagStyles = isAllTagSelected ? contentStyles.buttonAllSelected : contentStyles.buttonAll;

  const pinnedTagStyles = isPinnedTagSelected ? contentStyles.buttonPinnedSelected : contentStyles.buttonPinned;

  const goodTagStyles = isGoodTagSelected ? contentStyles.buttonGoodSelected : contentStyles.buttonGood;

  const moderateTagStyles = isModerateTagSelected ? contentStyles.buttonModerateSelected : contentStyles.buttonModerate;

  const poorTagStyles = isPoorTagSelected ? contentStyles.buttonPoorSelected : contentStyles.buttonPoor;

  const allTagIcon = isAllTagSelected ? <StackFilled /> : <StackRegular />;
  const pinnedTagIconFill = isPinnedTagSelected ? <PinFilled /> : <PinRegular />;
  const goodTagIconFill = isGoodTagSelected ? $white : currentColor;
  const moderateTagIconFill = isModerateTagSelected ? $white : currentColor;
  const poorTagIconFill = isPoorTagSelected ? $white : currentColor;
  const goodTagText = `Good (${airportStatusCount.good})`;
  const allTagText = `All (${airportStatusCount.all})`;
  const pinnedText = `Pinned (${airportStatusCount.isPinned})`;
  const moderateText = `Moderate (${airportStatusCount.moderate})`;
  const poorText = `Poor (${airportStatusCount.poor})`;

  return (
    <TagGroup aria-label="airport-status-tags" className={`${contentStyles.tagGroupRoot}`}>
      <InteractionTag
        shape="circular"
        appearance="outline"
        className={`${allTagStyles}`}
        onClick={() => {
          setSelectedTag(HOPEnum.HOPDataStatus.All);
          onTagClickHandler(HOPEnum.HOPDataStatus.All);
        }}
      >
        <InteractionTagPrimary className={`${contentStyles.buttonStyles}`} as="button" icon={allTagIcon}>
          {allTagText}
        </InteractionTagPrimary>
      </InteractionTag>
      <InteractionTag shape="circular" appearance="outline" className={`${pinnedTagStyles}`}>
        <InteractionTagPrimary
          className={`${contentStyles.buttonStyles}`}
          as="button"
          icon={pinnedTagIconFill}
          onClick={() => {
            setSelectedTag(HOPEnum.HOPDataStatus.Pinned);
            onTagClickHandler(HOPEnum.HOPDataStatus.Pinned);
          }}
        >
          {pinnedText}
        </InteractionTagPrimary>
      </InteractionTag>

      <InteractionTag
        shape="circular"
        appearance="outline"
        className={`${goodTagStyles}`}
        onClick={() => {
          setSelectedTag(HOPEnum.HOPDataStatus.Good);
          onTagClickHandler(HOPEnum.HOPDataStatus.Good);
        }}
      >
        <InteractionTagPrimary
          className={`${contentStyles.buttonStyles}`}
          as="button"
          icon={<AmdGreen fill={goodTagIconFill} />}
        >
          {goodTagText}
        </InteractionTagPrimary>
      </InteractionTag>

      <InteractionTag
        shape="circular"
        appearance="outline"
        className={`${moderateTagStyles}`}
        onClick={() => {
          setSelectedTag(HOPEnum.HOPDataStatus.Moderate);
          onTagClickHandler(HOPEnum.HOPDataStatus.Moderate);
        }}
      >
        <InteractionTagPrimary
          className={`${contentStyles.buttonStyles}`}
          as="button"
          icon={<AmdAmber fill={moderateTagIconFill} />}
        >
          {moderateText}
        </InteractionTagPrimary>
      </InteractionTag>
      <InteractionTag
        shape="circular"
        className={`${poorTagStyles}`}
        appearance="outline"
        style={{
          color: "#B2271F",
        }}
      >
        <InteractionTagPrimary
          className={`${contentStyles.buttonStyles}`}
          as="button"
          icon={<AmdRed fill={poorTagIconFill} />}
          onClick={() => {
            setSelectedTag(HOPEnum.HOPDataStatus.Poor);
            onTagClickHandler(HOPEnum.HOPDataStatus.Poor);
          }}
        >
          {poorText}
        </InteractionTagPrimary>
      </InteractionTag>
    </TagGroup>
  );
};

const contentStyles = mergeStyleSets({
  container: {
    columnGap: "10px",
    display: "flex",
  },
  airportPerformance: {
    weight: "400",
    fontSize: "21px",
  },
  buttonBackground: {
    background: "#9BCAEB !important",
    height: "40px",
  },
  svgMargin: {
    marginTop: "14px",
    marginLeft: "5px",
  },
  AmdButton: {},
  buttonAll: {
    ".fui-InteractionTagPrimary": {
      background: `${$allBgSelected}`,
      color: `${$allTextColorSelected}`,
      border: `1px solid ${$allBorderSelected}`,
      outline: "none",
      ":hover": {
        color: `${$allTextColorSelected}`,
        background: `${$allBgSelected}`,
      },
    },
  },
  buttonAllSelected: {
    ".fui-InteractionTagPrimary": {
      background: `${$primary}`,
      color: `${$white}`,
      border: `1px solid ${$white}`,
      outline: "none",
      ":hover": {
        color: `${$allTextColorSelected}`,
        background: `${$allBgSelected}`,
        border: `1px solid ${$allBorderSelected}`,
      },
    },
  },
  buttonPinned: {
    ".fui-InteractionTagPrimary": {
      background: `${$pinnedBg}`,
      color: `${$pinnedTextColor}`,
      border: `1px solid ${$pinnedTextColor}`,
      outline: "none",
      ":hover": {
        color: `${$pinnedTextColorSelected}`,
        background: `${$pinnedBgSelected}`,
      },
    },
  },
  buttonPinnedSelected: {
    ".fui-InteractionTagPrimary": {
      background: `${$pinnedBgSelected}`,
      color: `${$pinnedTextColorSelected}`,
      border: `1px solid ${$pinnedBgSelected}`,
      outline: "none",
      ":hover": {
        color: `${$pinnedTextColor}`,
        background: `${$pinnedBg}`,
      },
    },
  },
  buttonGood: {
    ".fui-InteractionTagPrimary": {
      background: `${$goodBg}`,
      color: `${$goodTextColor}`,
      border: `1px solid ${$goodTextColor}`,
      outline: "none",
      ":hover": {
        color: `${$goodTextColorHover}`,
        background: `${$goodBgSelected}`,
      },
    },
  },
  buttonGoodSelected: {
    ".fui-InteractionTagPrimary": {
      background: `${$goodBgSelected}`,
      color: `${$goodTextColorHover}`,
      border: `1px solid ${$goodBgSelected}`,
      outline: "none",
      ":hover": {
        color: `${$goodTextColorHover}`,
        background: `${$goodBgSelected}`,
      },
    },
  },
  buttonModerate: {
    ".fui-InteractionTagPrimary": {
      background: `${$moderateBg}`,
      color: `${$moderateTextColor}`,
      border: `1px solid ${$moderateTextColor}`,
      outline: "none",
      ":hover": {
        color: `${$moderateTextColorSelected}`,
        background: `${$moderateBgSelected}`,
      },
    },
  },
  buttonModerateSelected: {
    ".fui-InteractionTagPrimary": {
      background: `${$moderateBgSelected}`,
      color: `${$moderateTextColorSelected}`,
      outline: "none",
      ":hover": {
        color: `${$moderateTextColorSelected}`,
        background: `${$moderateBgSelected}`,
      },
    },
  },
  buttonPoor: {
    ".fui-InteractionTagPrimary": {
      background: `${$poorBg}`,
      color: `${$poorTextColor}`,
      border: `1px solid ${$poorTextColor}`,
      outline: "none",
      ":hover": {
        color: `${$poorTextColorSelected}`,
        background: `${$poorBgSelected}`,
      },
    },
  },
  buttonPoorSelected: {
    ".fui-InteractionTagPrimary": {
      background: `${$poorBgSelected}`,
      color: `${$poorTextColorSelected}`,
      border: `1px solid ${$poorBgSelected}`,
      outline: "none",
      ":hover": {
        color: `${$poorTextColorSelected}`,
        background: `${$poorBgSelected}`,
      },
    },
  },
  buttonStyles: {
    span: {
      paddingRight: "0",
    },
  },
  tagGroupRoot: {
    color: `${$white} !important`,
    columnGap: "16px !important",
    marginLeft: "16px",
  },
});
