import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { IPassengerCardSummary } from "../../../models/pax/IPassengerSummary";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { EMPTY_STATE_MESSAGE } from "../../../utils/common/Constants";
import { PassengerHelper } from "../../../utils/pax/PassengerHelper";
import { AmdOptions } from "../../common/icons/options/Options";
import { AmdPassenger } from "../../common/icons/passenger/passenger";
import PassengerSummaryDetails from "./PassengerSummaryDetails";

export interface IPassengersSummaryCardProps {
  airport: string;
}

export const PassengersSummaryCard: React.FunctionComponent<
  IPassengersSummaryCardProps
> = ({ airport }) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [passengerSummaryData, setPassengerSummaryData] = useState<
    IPassengerCardSummary[]
  >([]);

  useEffect(() => {
    let isMounted = true;
    if (airport !== "") {
      PassengerHelper.getPassengerSummary(msalInstance, airport).then(
        (response: any) => {
          if (isMounted && response && response.data) {
            setPassengerSummaryData([response.data]);
          }
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [airport]);

  const renderHeader = () => {
    return (
      <div className="amd-container-hspacebtwn pa-sm-2">
        <AmdPassenger />
        <span className="fs-14 fw-600 fc--gray800">Passengers</span>
        <AmdOptions />
      </div>
    );
  };

  return (
    <div
      data-testid="passengers-summary-card"
      onClick={() => CommonHelper.handleRedirects("PAX")}
      className="cursor-pointer amd-section--br-b"
    >
      <>
        {renderHeader()}
        <PassengerSummaryDetails
          data={passengerSummaryData}
          emptyStateMessage={EMPTY_STATE_MESSAGE.Passenger}
        />
      </>
    </div>
  );
};
