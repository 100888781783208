import { createSlice } from "@reduxjs/toolkit";

interface AirportState {
  airportCode: string;
}

const initialState: AirportState = {
  airportCode: "",
};

const airportCodeSlice = createSlice({
  name: "airport",
  initialState,
  reducers: {
    setAirportCode(state, action) {
      state.airportCode = action.payload.airportCode;
    },
  },
});

export const airportCodeActions = airportCodeSlice.actions;

export default airportCodeSlice.reducer;
