import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdUser: React.FunctionComponent<IAmdIconProps> = ({
  width = "21",
  height = "21",
  color = "#1890F1",
  viewBox = "0 0 21 21",
  ...props
}) => {
  return (
    <span className={props.className}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5004 10.0003C18.5379 10.0003 21.0004 12.4627 21.0004 15.5003C21.0004 18.5378 18.5379 21.0003 15.5004 21.0003C12.4628 21.0003 10.0004 18.5378 10.0004 15.5003C10.0004 12.4627 12.4628 10.0003 15.5004 10.0003ZM10.0226 11.9996C9.72593 12.4629 9.48639 12.9663 9.31398 13.4999L2.25278 13.5002C1.83919 13.5002 1.50391 13.8355 1.50391 14.2491V14.8267C1.50391 15.3624 1.69502 15.8805 2.04287 16.2878C3.29618 17.7555 5.26206 18.5013 8.00036 18.5013C8.5968 18.5013 9.15667 18.4659 9.6806 18.3954C9.92579 18.8903 10.2333 19.3489 10.5921 19.7618C9.79661 19.922 8.93173 20.0013 8.00036 20.0013C4.8545 20.0013 2.46849 19.0962 0.902186 17.2619C0.322425 16.583 0.00390625 15.7195 0.00390625 14.8267V14.2491C0.00390625 13.007 1.01076 12.0002 2.25278 12.0002L10.0226 11.9996ZM15.5004 12.0002L15.4105 12.0083C15.2064 12.0453 15.0455 12.2063 15.0084 12.4104L15.0004 12.5002L14.9994 15.0003H12.5043L12.4144 15.0083C12.2103 15.0454 12.0494 15.2063 12.0123 15.4104L12.0043 15.5003L12.0123 15.5901C12.0494 15.7942 12.2103 15.9552 12.4144 15.9922L12.5043 16.0003H14.9994L15.0004 18.5002L15.0084 18.5901C15.0455 18.7942 15.2064 18.9551 15.4105 18.9922L15.5004 19.0002L15.5902 18.9922C15.7943 18.9551 15.9553 18.7942 15.9923 18.5901L16.0004 18.5002L15.9994 16.0003H18.5043L18.5941 15.9922C18.7982 15.9552 18.9592 15.7942 18.9962 15.5901L19.0043 15.5003L18.9962 15.4104C18.9592 15.2063 18.7982 15.0454 18.5941 15.0083L18.5043 15.0003H15.9994L16.0004 12.5002L15.9923 12.4104C15.9553 12.2063 15.7943 12.0453 15.5902 12.0083L15.5004 12.0002ZM8.00036 0.00488281C10.7618 0.00488281 13.0004 2.24346 13.0004 5.00488C13.0004 7.76631 10.7618 10.0049 8.00036 10.0049C5.23894 10.0049 3.00036 7.76631 3.00036 5.00488C3.00036 2.24346 5.23894 0.00488281 8.00036 0.00488281ZM8.00036 1.50488C6.06737 1.50488 4.50036 3.07189 4.50036 5.00488C4.50036 6.93788 6.06737 8.50488 8.00036 8.50488C9.93336 8.50488 11.5004 6.93788 11.5004 5.00488C11.5004 3.07189 9.93336 1.50488 8.00036 1.50488Z"
          fill={color}
        />
      </svg>
    </span>
  );
};
