import ReactSpeedometer, { Transition } from "react-d3-speedometer";
import {
  SPEED_GAUGE_PROPS,
  SPEEDGAUGE_SEGMENT_COLORS,
} from "../../../utils/weather/WeatherConstants";
export interface ISpeedGaugeProps {
  speed: number;
  minValue: number;
  maxValue: number;
  totalSegments: number;
  segmentStops: number[];
  viewType: string;
  gaugeWidth?: number;
}
export const SpeedGauge: React.FunctionComponent<ISpeedGaugeProps> = ({
  speed,
  minValue,
  maxValue,
  totalSegments,
  segmentStops,
  viewType,
  gaugeWidth = (SPEED_GAUGE_PROPS as { [k in string]: any })[viewType].width,
}) => {
  const gaugeProps = (SPEED_GAUGE_PROPS as { [k in string]: any })[viewType];
  return (
    <ReactSpeedometer
      minValue={minValue}
      maxValue={maxValue}
      segments={totalSegments}
      customSegmentStops={segmentStops}
      maxSegmentLabels={0}
      value={speed ?? 0}
      segmentColors={SPEEDGAUGE_SEGMENT_COLORS}
      needleColor="#1A1A1A"
      textColor={"#1A1A1A"}
      ringWidth={gaugeProps?.ringWidth}
      currentValueText={`${speed ?? 0} kts`}
      width={gaugeWidth}
      height={250}
      needleHeightRatio={gaugeProps?.needleHeight}
      needleTransitionDuration={333}
      needleTransition={Transition.easeElasticIn}
      valueTextFontSize={"28px"}
      paddingVertical={10}
    />
  );
};
