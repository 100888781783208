import { Divider, makeStyles, Spinner } from "@fluentui/react-components";
import {
  AirplaneLandingFilled,
  AirplaneTakeOffFilled,
} from "@fluentui/react-icons";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import React from "react";
import {
  $cloud10,
  $cloud100,
  $cloud20,
  $cloud30,
  $cloud50,
  $cloud90,
  $sky60,
  $white,
} from "../../../../cssjs/colors";
import HOPEnum from "../../../../enum/HOPEnum";
import { useApiCall } from "../../../../hooks/useApiCall";
import RESOURCE_DATA from "../../../../mock/overview/opf-card-data.json";
import { IHOPSummaryResponse } from "../../../../models/health-of-operations/IHOPSummary";
import { HOPHelper } from "../../../../utils/health-of-operations/HOPHelper";
import { AmdRadarChart } from "../../../common/charts/RadarChart/RadarChart";
import { StatusIconSelector } from "../../../common/icon-selectors/StatusIconSelector";
import { NoDataCard } from "../../../common/no-data-card/NoDataCard";
import { AmdProgressIndicatorV2 } from "../../../common/progress-indicator/ProgressIndicatorV2";
initializeIcons();

export interface IHealthOfOperationsCardProps {
  airport: string;
}

export const HealthOfOperationsCard: React.FunctionComponent<
  IHealthOfOperationsCardProps
> = ({ airport }): React.JSX.Element => {
  const [selectedTime, _setSelectedTime] = React.useState<any>({
    start: HOPHelper.getCurrentTime(airport),
    end: "23:30",
  });
  const [opfCardData, _setOpfCardData] = React.useState<any>(
    RESOURCE_DATA?.data
  );

  const payload = HOPHelper.getHOPRequestPayload(selectedTime, airport);
  const getHOPSummaryUrl = `${process.env.REACT_APP_API_HOPSUMMARY?.replace(
    "{airportCode}",
    airport
  )}?startCurrentDateTime=${payload?.currentStart}&endCurrentDateTime=${
    payload?.currentEnd
  }&startForecastDateTime=${payload?.forecastStart}&endForecastDateTime=${
    payload?.forecastEnd
  }`;

  const {
    data: hopSummaryData,
    error,
    isLoading = false,
  } = useApiCall<IHOPSummaryResponse>({
    url: getHOPSummaryUrl,
    dependencies: [airport, selectedTime],
  });

  const { chartData: hopChartData } = hopSummaryData
    ? HOPHelper.extractHOPSummaryData(hopSummaryData, selectedTime, airport)
    : {
        chartData: undefined,
      };
  const classes = useStyles();

  return (
    <>
      <div
        className={`${classes.container}`}
      >
        <div className="pb-6">
          <div
            className={`fs-18 fw-600 amd-container-vstart ${classes.cardTitle}`}
          >
            Health of operations
          </div>
          <div className="amd-container-hcenter amd-container-vcenter">
            {isLoading ? (
              <Spinner
                labelPosition="below"
                label="Loading..."
                className={classes.cardLoader}
              />
            ) : error ? (
              <NoDataCard message="No data found" />
            ) : (
              <AmdRadarChart chartData={hopChartData} />
            )}
          </div>
        </div>
        <div className={`fs-14 fw-600`}>
          <div className={`mb-4 ${classes.progressIndicator}`}>
            <div>
              <StatusIconSelector status={HOPEnum.HOPDataStatus.Good} />
            </div>
            <div className="mx-1 fw-600">Confidence Factor</div>
            <div data-testid="progressIndicator">
              <AmdProgressIndicatorV2
                value={0.85}
                size="large"
                shape="rounded"
                color={HOPHelper.getConfidenceFactorColor(
                  HOPEnum.HOPDataStatus.Good
                )}
              />
            </div>
            <div className="ml-1 fw-600">85%</div>
          </div>

          <div className={`${classes.otpContainer} mb-2`}>
            <div
              className={`${classes.cardSubTitlevariant} fs-16 amd-container-hcenter mb-1`}
            >
              On Time Performance
            </div>
            <div
              className={`amd-container-hspacebtwn ${classes.otpStatusContainer}`}
            >
              <div
                className={`amd-container-vcolumn amd-container-vstart ms-fontSize-14 flex-1 ${classes.otpBox}  ${classes.boxBorderRight}`}
              >
                <div>Overall</div>
                <div className="fs-16">
                  <StatusIconSelector status={HOPEnum.HOPDataStatus.Moderate} />
                  <span>32%</span>
                </div>
              </div>
              <div
                className={`amd-container-vcolumn amd-container-vstart ms-fontSize-14 flex-1 ${classes.otpBox}  ${classes.boxBorderRight}`}
              >
                <div className={`d-flex fs-14 ${classes.cardLandingIcon}`}>
                  <span className="fs-18">
                    <AirplaneLandingFilled />
                  </span>
                  <span className={`${classes.cardText}`}>Arrival</span>
                </div>
                <div className="fs-16">
                  <StatusIconSelector status={HOPEnum.HOPDataStatus.Moderate} />
                  <span>32%</span>
                </div>
              </div>
              <div
                className={`amd-container-vcolumn amd-container-vstart ms-fontSize-14 flex-1 ${classes.otpBox}`}
              >
                <div className={`d-flex fs-14 ${classes.cardTakeOffIcon}`}>
                  <span className="fs-18">
                    <AirplaneTakeOffFilled />
                  </span>
                  <span className={`${classes.cardText}`}>Departure</span>
                </div>
                <div className="fs-16">
                  <StatusIconSelector status={HOPEnum.HOPDataStatus.Moderate} />
                  <span>32%</span>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className={`${classes.opfContainer} mt-2`}>
            <div
              className={`${classes.cardSubTitlevariant} fs-16 amd-container-hcenter mb-2`}
            >
              Other Performance Factors
            </div>
            <div
              className={`amd-container-vcolumn ${classes.cardPerformanceFactorItemContainer}`}
            >
              <div
                className={`d-flex ${classes.cardPerformanceFactorHeaderRow}`}
              >
                <div className="flex-3 fs-14"></div>
                <div className="flex-1">Actual</div>
                <div className="flex-1">Forecasted</div>
              </div>

              {opfCardData?.map((resource: any) => {
                return (
                  <div
                    key={resource.key}
                    className={`d-flex ${classes.cardPerformanceFactorItem}`}
                  >
                    <div className="flex-3">{resource.resourceName}</div>
                    <div className="flex-1 fs-16">
                      <StatusIconSelector status={resource.actualStatus} />
                      <span>{resource.actual}%</span>
                    </div>
                    <div className="flex-1 fs-16">
                      <StatusIconSelector status={resource.forecastedStatus} />
                      <span>{resource.forecasted}%</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridGap: "8px",
    gridAutoFlow: "column",
    borderRadius: "8px",
    boxSizing: "border-box",
    background: $white,
    padding: "8px",
    border: `1px solid ${$cloud30}`,
    minHeight: "100%",
    color: $cloud100,
    gridTemplateColumns: "1fr 406px",
    "@media(max-width: 1366px)": {
      gridTemplateColumns: "1fr 296px",
    },
  },
  cardTitle: {
    color: $sky60,
    paddingLeft: "0px",
  },
  cardLoader: {
    height: "209px",
  },
  otpContainer: {
    background: $cloud10,
    borderRadius: "8px",
    padding: "8px",
  },
  opfContainer: {
    background: $cloud10,
    borderRadius: "8px",
    padding: "8px 0px",
  },
  otpBox: {
    paddingLeft: "8px",
    marginRight: "4px",
  },
  boxBorderRight: {
    borderRight: `1px solid ${$cloud50}`,
  },
  cardSubTitlevariant: {
    color: $sky60,
  },
  otpStatusContainer: {
    paddingBottom: "4px",
  },
  cardLandingIcon: {
    color: $sky60,
  },
  cardTakeOffIcon: {
    color: $sky60,
  },
  cardText: {
    color: $cloud90,
    paddingLeft: "4px",
  },
  cardPerformanceFactorItemContainer: {
    color: $cloud90,
  },
  cardPerformanceFactorHeaderRow: {
    background: "transparent",
    padding: "4px",
    marginBottom: "4px",
  },
  cardPerformanceFactorItem: {
    background: $cloud20,
    padding: "4px",
    marginBottom: "4px",
  },
  progressIndicator: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "auto auto 1fr auto",
    alignItems: "baseline",
  },
});
