import { Spinner } from "@fluentui/react-components";
import moment from "moment-timezone";
import { useState } from "react";
import WeatherEnum from "../../enum/WeatherEnum";
import { useApiCall } from "../../hooks/useApiCall";
import {
  IWeatherTimeSeries,
  IWeatherTimeSeriesPayload,
} from "../../models/weather/IWeatherGraph";
import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import DateHelper from "../../utils/common/DateHelper";
import {
  WEATHER_GRAPH_TABS,
  WEATHER_KPI_TABS,
} from "../../utils/weather/WeatherConstants";
import { NoDataCard } from "../common/no-data-card/NoDataCard";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";
import { AmdTabs } from "../common/tabs/tabs";
import WeatherGraph from "./graph/WeatherGraph";
import WeatherKPI from "./kpi/WeatherKPI";
import { ExtendedRunway } from "./runway/ExtendedRunway";
import RunwayWind from "./runway/RunwayWind";

export const Weather: React.FunctionComponent = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const viewType = queryParams.get("viewType");
  const [selectedKPITab, setSelectedKPITab] = useState("today");
  const [selectedGraphTab, setSelectedGraphTab] = useState("temperature");

  const airport = useAppSelector((state) => getAirportCode(state));

  const getWeatherTimeSeriesUrl = `${process.env.REACT_APP_API_WEATHER_TIMESERIES?.replace(
    "{airportCode}",
    airport
  )}`;

  const onWeatherKPIFilterClick = (changedFilter: string) => {
    setSelectedKPITab(changedFilter);
  };

  const onWeatherGraphFilterClick = (changedFilter: string) => {
    setSelectedGraphTab(changedFilter);
  };

  const currentDate = moment();
  const payload: IWeatherTimeSeriesPayload = {
    attributes: [selectedGraphTab],
    timestep: "1d",
    start: DateHelper.getFormattedTime(currentDate.toDate()),
    end: DateHelper.getFormattedTime(currentDate.add(7, "days").toDate()),
  };

  const {
    data: weatherTimeSeriesData,
    error,
    isLoading,
  } = useApiCall<IWeatherTimeSeries[]>({
    url: getWeatherTimeSeriesUrl,
    payload,
    dependencies: [airport, selectedGraphTab],
    requestType: "post",
  });

  return (
    <>
      <NotificationBanner airport={airport} />
      {viewType && viewType === "extended" ? (
        <ExtendedRunway airport={airport} />
      ) : (
        <>
          <h1 className="amd-heading1">Weather</h1>
          <div data-testid="weatherContainer">
            <AmdTabs
              selectedTab={selectedKPITab}
              defaultSelectedTab={WeatherEnum.WeatherKPITabs.Today}
              tabList={WEATHER_KPI_TABS.data}
              onTabClick={onWeatherKPIFilterClick}
              dataTestId="WeatherKPITabs"
            />
            {selectedKPITab === WeatherEnum.WeatherKPITabs.Today && (
              <WeatherKPI airport={airport} />
            )}
            {selectedKPITab === WeatherEnum.WeatherKPITabs.Today && (
              <RunwayWind airport={airport} />
            )}
            <section className="amd-section-shadow mt-4">
              <header className="amd-section-shadow__header amd-section-shadow__header--lg amd-container-hspacebtwn">
                Graphical View
              </header>
              <AmdTabs
                selectedTab={selectedGraphTab}
                defaultSelectedTab={WeatherEnum.WeatherGraphTabs.Temperature}
                tabList={WEATHER_GRAPH_TABS.data}
                onTabClick={onWeatherGraphFilterClick}
                dataTestId="WeatherGraphTabs"
              />
              {error && <NoDataCard message="No Weather data found" />}

              {isLoading && (
                <Spinner
                  labelPosition="below"
                  label="Loading..."
                  style={{ height: "50vh" }}
                />
              )}
              {!error && !isLoading && weatherTimeSeriesData && (
                <WeatherGraph data={weatherTimeSeriesData} airport={airport} />
              )}
            </section>
          </div>
        </>
      )}
    </>
  );
};
