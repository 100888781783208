import HOPEnum from "../../enum/HOPEnum";
import PageEnum from "../../enum/PageEnum";
import IHOPSummary, {
  IHOPSummaryChart,
  IHOPSummaryItem,
  IHOPSummaryLegend,
  IHOPSummaryPerformance,
  IHOPTimeRange,
} from "../../models/health-of-operations/IHOPSummary";
import { CommonHelper } from "../common/CommonHelper";
import { HOP_COLORS, HOP_OTP_DIMENSIONS } from "../common/Constants";
import DateHelper from "../common/DateHelper";
export abstract class HOPHelper {
  public static getHOPRequestPayload(
    selectedTime: IHOPTimeRange,
    airportCode: string
  ) {
    const currentDate = DateHelper.getDefaultDateStrForAirport(airportCode);
    return {
      currentStart: `${currentDate}T00:00`,
      currentEnd: `${currentDate}T${this.getCurrentTime(airportCode)}`,
      forecastStart: `${currentDate}T${selectedTime.start}`,
      forecastEnd: `${currentDate}T${selectedTime.end}`,
    };
  }

  public static extractHOPSummaryData(
    hopSummaryData: any,
    selectedTime: any,
    airport: string
  ) {
    const otp: IHOPSummaryItem = this.getHOPDetailsByName(
      hopSummaryData,
      "onTimePerformance",
      HOPEnum.HOPDataType.Current
    );
    const hopData = this.getHOPData(hopSummaryData);
    const chartData: IHOPSummaryChart | undefined = this.getRadarChartData(
      hopData,
      selectedTime,
      airport
    );
    const legendData: IHOPSummaryLegend[] = this.getLegendData(hopData) || [];
    const performance: IHOPSummaryPerformance[] =
      this.getOTPPerformance(hopData) || [];

    return { otp, chartData, legendData, performance };
  }

  public static getRadarChartData(
    hopSummaryData: IHOPSummary | undefined,
    selectedTime: any,
    airport: string
  ) {
    if (hopSummaryData) {
      const dimensionLabels = hopSummaryData.current.map(
        (a: IHOPSummaryItem) => a.label
      );
      return {
        labels: dimensionLabels,
        datasets: this.getChartDataset(hopSummaryData, selectedTime, airport),
      };
    }
    return undefined;
  }

  public static getHOPDetailsByName(
    hopSummaryData: IHOPSummary,
    name: string,
    key: string
  ) {
    return (hopSummaryData as { [k in string]: any })[key].filter(
      (d: IHOPSummaryItem) => d.name === name
    )[0];
  }

  public static getHOPData(hopSummaryData: IHOPSummary) {
    return {
      current: hopSummaryData.current.filter(
        (d: IHOPSummaryItem) => d.name !== "onTimePerformance"
      ),
      forecast: hopSummaryData.forecast.filter(
        (d: IHOPSummaryItem) => d.name !== "onTimePerformance"
      ),
    };
  }

  public static getOTPPerformance(data?: IHOPSummary) {
    const otpFactors = data?.current.filter((d: IHOPSummaryItem) =>
      HOP_OTP_DIMENSIONS.includes(d.name)
    );
    return otpFactors?.map((d: IHOPSummaryItem) => ({
      name: d.label,
      value: d.kpis[0].performance,
    }));
  }

  public static getChartDataset(
    data: IHOPSummary,
    selectedTime: any,
    airport: string
  ) {
    return Object.keys(data)?.map((key: string) => ({
      label: this.getLegendLabel(key, selectedTime, airport),
      data: (data as { [k in string]: any })[key].map(
        (a: IHOPSummaryItem) => a.kpis[0].performance
      ),
      borderColor: (HOP_COLORS as { [k in string]: any })[key],
      pointBackgroundColor: (HOP_COLORS as { [k in string]: any })[key],
      pointBorderColor: "#fff",
      fill: false,
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: (HOP_COLORS as { [k in string]: any })[key],
      borderDash: key === "forecast" ? [10, 5] : [],
    }));
  }

  public static getLegendLabel(
    key: string,
    selectedTime: any,
    airport: string
  ) {
    if (key === "current") {
      return `From 00:00 to ${this.getCurrentTime(airport)} (Current)`;
    } else {
      return `From ${selectedTime.start} to ${selectedTime.end} (Forecast)`;
    }
  }

  public static getLegendData(hopSummaryData?: IHOPSummary) {
    return hopSummaryData?.current?.map((legend: IHOPSummaryItem) => ({
      name: legend.label,
      value: legend.kpis[0].performance,
      status: legend.kpis[0].status,
      textColor: this.getLegendTextColor(legend.kpis[0].status),
      backgroundColor: this.getLegendBgColor(legend.kpis[0].status),
      visible: false,
      details: this.getHOPLegendDetails(hopSummaryData, legend.name),
      redirectToPage: this.getRedirectUrl(legend.name),
      dimensionKey: legend.name,
    }));
  }
  public static getRedirectUrl(name: string) {
    switch (name) {
      case HOPEnum.HOPDIMENSIONS.OTP:
      case HOPEnum.HOPDIMENSIONS.TurnaroundSufficiency:
      case HOPEnum.HOPDIMENSIONS.SlotAdherence:
      case HOPEnum.HOPDIMENSIONS.RunwayDelay:
        return PageEnum.PageUrl.Flights360;
      case HOPEnum.HOPDIMENSIONS.Baggage:
        return PageEnum.PageUrl.Baggage;
      case HOPEnum.HOPDIMENSIONS.PassengerFlow:
        return PageEnum.PageUrl.Passenger;
      case HOPEnum.HOPDIMENSIONS.OtherAssets:
        return PageEnum.PageUrl.ResourceAllocation;
      default:
        return PageEnum.PageUrl.Flights360;
    }
  }
  public static getKPIKey(index: number) {
    return index === 0
      ? HOPEnum.HOPDataType.Current
      : HOPEnum.HOPDataType.Forecast;
  }

  public static getKPIName(
    kpiKey: string,
    legendKey: string,
    selectedTime: any,
    airport: string
  ) {
    let name = "";
    if (kpiKey === HOPEnum.HOPKPI.All) {
      name = this.getLegendLabel(legendKey, selectedTime, airport);
    } else {
      name = CommonHelper.toTitleCase(kpiKey);
    }
    return name;
  }

  public static getLegendTextColor(status: string) {
    if (status === HOPEnum.HOPDataStatus.Poor) {
      return "fc--red";
    } else if (status === HOPEnum.HOPDataStatus.Moderate) {
      return "fc--darkorange";
    } else {
      return "fc--green";
    }
  }

  public static getLegendBgColor(status: string) {
    if (status === HOPEnum.HOPDataStatus.Poor) {
      return "bg--danger";
    } else if (status === HOPEnum.HOPDataStatus.Moderate) {
      return "bg--warning";
    } else {
      return "bg--safe";
    }
  }

  public static getConfidenceFactorColor(status: string) {
    if (status === HOPEnum.HOPDataStatus.Poor) {
      return "error";
    } else if (status === HOPEnum.HOPDataStatus.Moderate) {
      return "warning";
    } else {
      return "success";
    }
  }

  public static getCurrentTime(airport: string) {
    return DateHelper.getNearest30Minutes(airport).format("HH:mm");
  }

  public static getBorderStyle(item: any) {
    return item.lineDash.length > 0 ? "dashed" : "solid";
  }

  public static getTextDecoration(item: any) {
    return item.hidden ? "line-through" : "";
  }

  public static getLegendClass(key: string) {
    return key === HOPEnum.HOPKPI.All ? "fw-600" : "fw-400 fc--gray600";
  }

  public static getOrCreateLegendList(chart: any, id: string) {
    let legendContainer = document.getElementById(id);
    if (!legendContainer) {
      legendContainer = document.createElement("div");
      legendContainer.id = id;
      chart.canvas?.parentNode.insertBefore(
        legendContainer,
        chart.canvas.nextSibling
      );
    }
    let listContainer = legendContainer?.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.classList.add("amd-container-hcenter");
      listContainer.classList.add("ma-0");
      listContainer.classList.add("pa-0");
      listContainer.classList.add("li-type-none");
      legendContainer?.appendChild(listContainer);
    }

    return listContainer;
  }

  private static getHOPLegendDetails(
    hopSummaryData: IHOPSummary,
    name: string
  ) {
    return [
      this.getHOPDetailsByName(
        hopSummaryData,
        name,
        HOPEnum.HOPDataType.Current
      ),
      this.getHOPDetailsByName(
        hopSummaryData,
        name,
        HOPEnum.HOPDataType.Forecast
      ),
    ];
  }

  public static isVisible(
    visible: boolean,
    index: number,
    currentIndex?: number
  ) {
    return visible && index === currentIndex;
  }
}
