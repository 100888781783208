import { Image } from "@fluentui/react";
import { IRunwayWind } from "../../../models/weather/IRunwayWind";
import { SPEEDGAUGE_SEGMENT_STOPS } from "../../../utils/weather/WeatherConstants";
import { DirectionCompass } from "../../common/direction-compass/DirectionCompass";
import compassBig from "../../common/images/compass_big.svg";
import compassSmall from "../../common/images/compass_small.svg";
import { SpeedGauge } from "../../common/speed-gauge/SpeedGauge";

export interface IRunwayWindCardProps {
  viewType: string;
  runwayWindDetails?: IRunwayWind;
  orientation?: "vertical" | "horizontal";
  size?: "small" | "big";
}
export const RunwayWindCard: React.FunctionComponent<IRunwayWindCardProps> = ({
  viewType,
  runwayWindDetails,
  orientation = "horizontal",
  size = "big",
}) => {
  return (
    <>
      <div className="ms-Grid" dir="ltr" data-testid="runway-windcard">
        <div className="ms-Grid-row">
          <section className="ms-Grid-col ms-md6">
            <div className="wind-direction text-uppercase fs-18">
              Wind Direction
            </div>
            {runwayWindDetails?.runway ? (
              <div
                className={`${
                  orientation === "horizontal"
                    ? `amd-container-vcenter ${
                        size === "small" ? "jc-spacebtwn" : "jc-start"
                      }`
                    : ""
                }`}
              >
                <DirectionCompass
                  degree={runwayWindDetails?.windDirection ?? 0}
                  canvasId={runwayWindDetails?.runway}
                  size={size}
                />
                <div
                  className={`wind-degree d-iblock fs-36 fw-600 pl-1 ${
                    orientation === "vertical" ? "mt-2" : ""
                  }`}
                >
                  {runwayWindDetails?.windDirection ?? 0}° N
                </div>
              </div>
            ) : (
              <Image
                src={size === "big" ? compassBig : compassSmall}
                data-testid="compass-img"
              />
            )}
          </section>
          <section
            className={`ms-Grid-col ms-md6 ${
              viewType === "runway" ? "divider-v-grey" : ""
            }`}
          >
            <div className="wind-speed text-uppercase fs-18">Wind Speed</div>
            <SpeedGauge
              speed={runwayWindDetails?.windSpeed ?? 0}
              minValue={0}
              maxValue={130}
              totalSegments={5}
              segmentStops={SPEEDGAUGE_SEGMENT_STOPS}
              viewType={viewType}
              gaugeWidth={size === "big" ? 300 : 200}
            />
          </section>
        </div>
      </div>
    </>
  );
};
