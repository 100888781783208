import { SelectedGraphDatum } from "../../models/events/IWeatherDisruptionSummary";

export function getGraphTypeData(
  type: string,
  graphicalState: SelectedGraphDatum[]
): { x: Date | string; y: number }[] | [] {
  const graphicalActualData = graphicalState
    ?.filter((set: SelectedGraphDatum) => set.datasetName === type)
    .map((set: SelectedGraphDatum) => set.timeValues);

  return graphicalActualData?.flat().map(function (row) {
    const dateObject = new Date(row.datetime);
    return {
      x: dateObject,
      y: row.value ?? 0,
    };
  });
}
