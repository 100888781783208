import { PublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import * as microsoftTeams from "@microsoft/teams-js";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "../../../components-v2/common/layout/Layout";
import { MultiAirport } from "../../../components-v2/multi-airport-list/MultiAirport";
import { Overview } from "../../../components-v2/overview/Overview";
import PageEnum from "../../../enum/PageEnum";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { airportCodeActions } from "../../../store/airport/AirportCodeSlice";
import { getAirportCode } from "../../../store/airport/AirportSelector";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { getIpadLandscapeClass } from "../../../utils/common/CommonHelperFunctions";
import { AdminContainer } from "../../admin/AdminContainer";
import { AppSettings } from "../../app-settings/AppSettings";
import { Baggage } from "../../baggage/Baggage";
import { CrisisDashboard } from "../../events/crisis-dashboard/CrisisDashboard";
import { EventTasks } from "../../events/event-container/event-item/event-tasks/EventTasks";
import { EventContainer } from "../../events/event-container/EventContainer";
import { FlightsSchedule } from "../../flights/flights-schedule/FlightsSchedule";
import { Home } from "../../home/Home";
import { NotificationsContainer } from "../../notifications/NotificationsContainer";
import { PAX } from "../../pax/PAX";
import { PAXTouchPointsContainer } from "../../pax/touchpoints/PAXTouchPointsComponent";
import { ResourceAllocation } from "../../resources-allocation/ResourceAllocation";
import { Weather } from "../../weather/Weather";
import { AccessDenied } from "../access-denied/AccessDenied";
import { LoadingScreen } from "../loading-screen/LoadingScreen";
import NavBar from "../nav-bar/NavBar";
import { SignIn } from "../sign-in/SignIn";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout: React.FunctionComponent = () => {
  let isAuthenticated = useIsAuthenticated(); // Used only for Web App, not for Teams.
  const [userTenantId, setUserTenantId] = React.useState<string | undefined>(
    ""
  );
  const allowedTenants = process.env.REACT_APP_ALLOWED_TENANTIDS
    ? process.env.REACT_APP_ALLOWED_TENANTIDS.toString().split(";")
    : [];
  microsoftTeams.initialize();
  const { accounts } = useMsal();
  const isTabletPortrait = useMediaQuery("(max-width: 1024px)");
  const isTablet = useMediaQuery("(max-width: 1366px)");
  const landscapeTablet = isTablet && !isTabletPortrait;

  const location = useLocation() ?? "";
  const isOverview = location?.pathname?.startsWith("/overview");
  const isMultiAirport = location?.pathname?.startsWith("/multi-airports");
  const isLandscapeIpad = landscapeTablet && (isOverview || isMultiAirport);

  const isInTeamsContext = CommonHelper.isInTeams();
  if (userTenantId === "") {
    if (isInTeamsContext) {
      microsoftTeams.getContext((context) => {
        setUserTenantId(context.tid);
      });
    } else if (accounts.length > 0) {
      setUserTenantId(accounts[0].tenantId);
      isAuthenticated = true;
    }
  }

  const isValidTenantId = CommonHelper.isValidTenant(
    userTenantId,
    allowedTenants
  );

  // Fetching airport code
  const msalInstance = useMsal().instance as PublicClientApplication;
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    CommonHelper.getAirportCode(msalInstance).then((airportCode: string) => {
      dispatch(airportCodeActions.setAirportCode({ airportCode }));
    });
  }, []);

  const airport = useAppSelector((state) => getAirportCode(state));

  return (
    <>
      {!airport && <LoadingScreen message="Please wait..." />}
      {!isAuthenticated && !isInTeamsContext && <SignIn />}
      {(isAuthenticated || isInTeamsContext) && (
        <>
          {!isInTeamsContext ? <NavBar /> : <></>}
          <div
            className={`amd-maincontainer ${CommonHelper.getLayoutClass(
              isInTeamsContext
            )}`}
          >
            <main className={`${getIpadLandscapeClass(isLandscapeIpad)}`}>
              {isValidTenantId && (
                <Routes>
                  <Route
                    path={`/${PageEnum.PageUrl.Overview}`}
                    element={
                      <Layout>
                        <Overview />
                      </Layout>
                    }
                  >
                    <Route
                      path={`/${PageEnum.PageUrl.Overview}/:id`}
                      element={
                        <Layout>
                          <Overview />
                        </Layout>
                      }
                    />
                  </Route>
                  <Route
                    path={`/${PageEnum.PageUrl.Home}`}
                    element={<Home />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.Flights360}`}
                    element={<FlightsSchedule />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.Baggage}`}
                    element={<Baggage />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.Passenger}`}
                    element={<PAX />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.Weather}`}
                    element={<Weather />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.EventManagement}`}
                    element={<EventContainer />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.Notifications}`}
                    element={<NotificationsContainer />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.MultiAirports}`}
                    element={
                      <Layout>
                        <MultiAirport />
                      </Layout>
                    }
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.ResourceAllocation}`}
                    element={<ResourceAllocation />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.Admin}`}
                    element={<AdminContainer />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.ApplicationSettings}`}
                    element={<AppSettings />}
                  />
                  <Route
                    path={`/${PageEnum.PageUrl.EventManagement}`}
                    element={<EventContainer />}
                  />
                  <Route path="/event-tasks" element={<EventTasks />} />
                  <Route
                    path="/crisis-dashboard"
                    element={<CrisisDashboard />}
                  />
                  <Route
                    path="/pax-poc"
                    element={<PAXTouchPointsContainer />}
                  />
                </Routes>
              )}
              {!isValidTenantId && userTenantId && <AccessDenied />}
              {!isValidTenantId && !userTenantId && (
                <LoadingScreen message="Please wait.." />
              )}
            </main>
          </div>
        </>
      )}
    </>
  );
};
