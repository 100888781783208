import React from "react";

export const ExclamationTriangleIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6836 12.8086C17.1758 13.6836 16.5469 14.75 15.5352 14.75H2.4375C1.42578 14.75 0.796875 13.6562 1.28906 12.8086L7.85156 1.40625C8.34375 0.53125 9.62891 0.558594 10.1211 1.40625L16.6836 12.8086ZM9 10.4297C8.28906 10.4297 7.74219 11.0039 7.74219 11.6875C7.74219 12.3984 8.28906 12.9453 9 12.9453C9.68359 12.9453 10.2578 12.3984 10.2578 11.6875C10.2578 11.0039 9.68359 10.4297 9 10.4297ZM7.79688 5.91797L7.98828 9.63672C8.01562 9.82812 8.15234 9.9375 8.31641 9.9375H9.65625C9.82031 9.9375 9.95703 9.82812 9.98438 9.63672L10.1758 5.91797C10.2031 5.72656 10.0391 5.5625 9.84766 5.5625H8.125C7.93359 5.5625 7.76953 5.72656 7.79688 5.91797Z"
        fill="#FA6400"
      />
    </svg>
  );
};
