import { IComboBoxStyles, ITimeRange, TimePicker } from "@fluentui/react";
import DateHelper from "../../../utils/common/DateHelper";

export const TimeRangeFilter = ({
  airport,
  onTimePickerChanged,
  isDisabled,
  defaultText,
  value,
}: {
  airport: string;
  onTimePickerChanged(date: Date): any;
  isDisabled?: boolean;
  defaultText?: string;
  value?: Date;
}) => {
  return (
    <div data-testid="time-picker">
      <TimePicker
        styles={timePickerStyles}
        increments={30}
        timeRange={timeRange}
        defaultValue={
          value
            ? value
            : new Date(DateHelper.getCurrentDateTimeStrForAirport(airport))
        }
        onChange={(_e, date) => {
          onTimePickerChanged(date);
        }}
        disabled={isDisabled}
        allowFreeInput={false}
        allowFreeform={false}
        className={`timepicker${defaultText}`}
        placeholder={defaultText}
      />
    </div>
  );
};

const timePickerStyles: Partial<IComboBoxStyles> = {
  optionsContainerWrapper: {
    height: "500px",
  },
  root: {
    width: "100px",
  },
};
const timeRange: ITimeRange = {
  start: 0,
  end: 24,
};
