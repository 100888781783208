import React from "react";

export const BellIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 15C10.0312 15 10.5 14.5625 10.5 14H11.5C11.5 15.125 10.5938 16 9.5 16C8.375 16 7.5 15.125 7.5 14H8.5C8.5 14.5625 8.9375 15 9.5 15ZM16.0312 10.4688C16.4688 10.9062 16.5938 11.5312 16.375 12.0938C16.1562 12.6562 15.5938 13 15 13H4C3.375 13 2.8125 12.6562 2.59375 12.0938C2.375 11.5312 2.5 10.9062 2.9375 10.4688C3.8125 9.65625 4.46875 8.78125 4.46875 5.8125C4.46875 3.34375 6.46875 1.3125 9 1.0625V0.5C9 0.25 9.21875 0 9.5 0C9.75 0 10 0.25 10 0.5V1.0625C12.5 1.3125 14.5 3.34375 14.5 5.8125C14.5 8.78125 15.1562 9.65625 16.0312 10.4688ZM15 12C15.4375 12 15.6562 11.5 15.3438 11.1875C14.25 10.1562 13.5 9 13.5 5.8125C13.5 3.71875 11.6875 2 9.5 2C7.28125 2 5.46875 3.71875 5.46875 5.8125C5.46875 9 4.71875 10.1562 3.625 11.1875C3.3125 11.5 3.53125 12 4 12H15Z"
        fill="#0C66E1"
      />
    </svg>
  );
};
