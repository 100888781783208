import React from "react";

export const AmdRed: React.FunctionComponent<{fill?: string}> = ({ fill: fillColor }) => {
  const fill = fillColor ? 'currentColor' : '#C60000';
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      data-testid="red-alert-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M7.99902 1.03226C11.8255 1.03226 14.9668 4.13145 14.9668 8C14.9668 11.8481 11.8503 14.9677 7.99902 14.9677C4.15244 14.9677 1.03128 11.8528 1.03128 8C1.03128 4.15474 4.14747 1.03226 7.99902 1.03226ZM7.99902 0C3.58106 0 -0.000976562 3.58332 -0.000976562 8C-0.000976562 12.4193 3.58106 16 7.99902 16C12.417 16 15.999 12.4193 15.999 8C15.999 3.58332 12.417 0 7.99902 0ZM7.62838 3.87097H8.36964C8.58973 3.87097 8.76557 4.05426 8.75641 4.27419L8.5306 9.69355C8.52196 9.9009 8.35138 10.0645 8.14383 10.0645H7.85418C7.64667 10.0645 7.47606 9.90087 7.46741 9.69355L7.2416 4.27419C7.23247 4.05426 7.40828 3.87097 7.62838 3.87097ZM7.99902 10.7097C7.50018 10.7097 7.0958 11.1141 7.0958 11.6129C7.0958 12.1117 7.50018 12.5161 7.99902 12.5161C8.49786 12.5161 8.90225 12.1117 8.90225 11.6129C8.90225 11.1141 8.49786 10.7097 7.99902 10.7097Z"
        fill={fill}
      />
    </svg>
  );
};