import { PublicClientApplication } from "@azure/msal-browser";
import ArrivalIcon from "../../components/common/images/arrivals.svg";
import DepartureIcon from "../../components/common/images/departures.svg";
import FlightsEnum from "../../enum/FlightsEnum";
import ResourceEnum from "../../enum/ResourceEnum";
import {
  IFlightAllList,
  IFlightArrival,
  IFlightDeparture,
  IFlightListServicePayload,
  IResourceFilter,
} from "../../models/flights/IFlightList";
import { IFlightScheduleFilters } from "../../models/flights/IFlightScheduleFilters";
import FlightListService from "../../services/flight-list/flightlist.service";
import { APIHelper } from "../api/APIHelper";
import { FLIGHTLIST_COLUMNS } from "../common/Constants";
import {
  MIXED_FLIGHTLIST_COLUMNS,
  OTP_FLIGHTLIST_COLUMNS,
} from "../flights/FlightConstants";
import {
  RESOURCE_COMMON_COLUMNS,
  RESOURCE_SPECIFIC_COLUMNS,
} from "../resource/ResourceConstants";

export abstract class FlightListHelper {
  public static async getFlightList(
    msalInstance: PublicClientApplication,
    flightListState: IFlightScheduleFilters,
    airportCode: string
  ): Promise<any> {
    const flightListServicePayload =
      FlightListHelper.getFlightListServicePayload(flightListState);

    const flightListService = new FlightListService();
    const flightListServiceMethod = (
      accessToken: string,
      flightListServicePayload: IFlightListServicePayload
    ) => {
      return flightListService.getFlightList(
        accessToken,
        flightListServicePayload,
        airportCode
      );
    };

    return APIHelper.CallAPI(
      msalInstance,
      flightListServicePayload,
      flightListServiceMethod
    );
  }
  public static async getTurnaroundSufficiencyList(
    msalInstance: PublicClientApplication,
    flightListState: IFlightScheduleFilters
  ): Promise<any> {
    const flightListServicePayload =
      FlightListHelper.getFlightListServicePayload(flightListState);

    const flightListService = new FlightListService();
    const getTurnaroundSufficiencyListMethod = (
      accessToken: string,
      flightListServicePayload: IFlightListServicePayload
    ) => {
      return flightListService.getTurnaroundSufficiencyList(
        accessToken,
        flightListServicePayload
      );
    };

    return APIHelper.CallAPI(
      msalInstance,
      flightListServicePayload,
      getTurnaroundSufficiencyListMethod
    );
  }

  public static getFlightListServicePayload(
    flightListState: IFlightScheduleFilters
  ) {
    // !todo: ASSB-1885: Temporary code to fetch the 'Run Away delay' and 'Slot adherence' Flight List same as 'OTP' list as per 'viewType'. To be removed once BE API is ready.
    if (
      flightListState.viewType === "runwayDelay" ||
      flightListState.viewType === "slotAdherence"
    ) {
      flightListState.viewType = "otp";
    }

    const flightListServicePayload: IFlightListServicePayload = {
      trafficTypes: flightListState.destinationType
        ? flightListState.destinationType.split(";").filter(Boolean)
        : null,
      legTypes: flightListState.flightType
        ? flightListState.flightType.split(";").filter(Boolean)
        : null,
      statuses: flightListState.status
        ? flightListState.status.split(";").filter(Boolean)
        : null,
      terminals: flightListState.terminal
        ? flightListState.terminal.split(";").filter(Boolean)
        : null,
      flightNumbers: flightListState.flightNumbers ?? null,
      origins: flightListState.origins ?? null,
      destinations: flightListState.destinations ?? null,
      delayCodes: flightListState.delayCodes ?? null,
      atmCategories: flightListState.atmCategory
        ? flightListState.atmCategory.split(";").filter(Boolean)
        : null,
      start: flightListState.start,
      end: flightListState.end,
      limit: flightListState.page ? parseInt(flightListState.page) : 50,
      viewType: flightListState.viewType,
      plotFilter: flightListState.plotFilter ?? "",
      resourceFilter: this.getResourceSearchFilters(flightListState),
    };
    return flightListServicePayload;
  }

  public static getResourceSearchFilters(
    flightListState: IFlightScheduleFilters
  ) {
    const resourceFilterObj: IResourceFilter = {
      baggageBelts: flightListState.baggageBelt ?? "",
      baggageChutes: flightListState.baggageChute ?? "",
      gates: flightListState.gate ?? "",
      stands: flightListState.stand ?? "",
      standType: flightListState.standType ?? "",
      checkInCounters: flightListState.checkInCounter ?? "",
      checkInCounterGroups: flightListState.checkInCounterGroup ?? "",
    };

    return resourceFilterObj;
  }

  public static getFlightListDataObj(
    flightType: string,
    flightListData: any,
    response: any
  ) {
    switch (flightType.toLowerCase()) {
      case FlightsEnum.LegType.Arrival:
        return {
          ...flightListData,
          arrivalData: response.data.arrivals,
          departureData: [],
          total: response.data.total,
        };

      case FlightsEnum.LegType.Departure:
        return {
          ...flightListData,
          arrivalData: [],
          departureData: response.data.departures,
          total: response.data.total,
        };
      case FlightsEnum.LegType.Mixed:
        return {
          ...flightListData,
          arrivalData: [],
          departureData: [],
          mixedData: response.data.mixed,
          total: response.data.total,
        };
      default:
        return {
          ...flightListData,
          arrivalData: response.data.arrivals,
          departureData: response.data.departures,
          total: response.data.total,
        };
    }
  }
  public static getFlightListColumns(
    legType: string,
    viewType: string,
    resource = ""
  ) {
    switch (viewType) {
      case ResourceEnum.ResourceType.Flight:
      case ResourceEnum.ResourceType.Passenger:
        return this.getFlightColumns(legType, viewType);
      case ResourceEnum.ResourceTabs.BeltsAndChutes:
      case ResourceEnum.ResourceTabs.Gates:
      case ResourceEnum.ResourceTabs.Stand:
      case ResourceEnum.ResourceTabs.CheckInCounters:
        return this.getResourceColumns(legType, resource);
      case ResourceEnum.ResourceType.OTP:
      case ResourceEnum.ResourceType.SlotAdherence:
      case ResourceEnum.ResourceType.RunwayDelay:
        return this.getOTPFlightColumns(legType, viewType);
      case ResourceEnum.ResourceType.TurnaroundSufficiency:
        return this.getMixedFlightColumns();
      default:
        return this.getFlightColumns(legType, viewType);
    }
  }

  public static getResourceColumns(legType: string, resource: string) {
    let columns: any[] = [];
    const specificColumns = (
      RESOURCE_SPECIFIC_COLUMNS as { [k in string]: any }
    )[resource];
    const concatArray = specificColumns.concat(
      RESOURCE_COMMON_COLUMNS.slice(1)
    );
    columns = RESOURCE_COMMON_COLUMNS.slice(0, 1).concat(concatArray);
    columns = columns.filter((a) => a.legType?.includes(legType));
    return columns;
  }

  public static getFlightColumns(legType: string, viewType: string) {
    return FLIGHTLIST_COLUMNS.filter(
      (a) => a.legType?.includes(legType) && a.viewType?.includes(viewType)
    );
  }

  public static getOTPFlightColumns(legType: string, viewType: string) {
    return OTP_FLIGHTLIST_COLUMNS.filter(
      (a) =>
        a.legType?.includes(legType) &&
        (a.viewType?.includes("overall") || a.viewType?.includes(viewType))
    );
  }

  public static getMixedFlightColumns() {
    return MIXED_FLIGHTLIST_COLUMNS;
  }
  public static getFlightListRows(
    flightType: string,
    viewType: string,
    props: any
  ) {
    switch (flightType.toLowerCase()) {
      case FlightsEnum.LegType.Arrival:
        return props?.arrivalData;
      case FlightsEnum.LegType.Departure:
        return props.departureData;
      case FlightsEnum.LegType.Mixed:
        return props.mixedData ? props.mixedData : [];
      default:
        return this._getAllListData(
          props.arrivalData ? props.arrivalData : [],
          props.departureData ? props.departureData : [],
          viewType
        );
    }
  }

  private static readonly _getAllListData = (
    arrivalList: IFlightArrival[],
    departureList: IFlightDeparture[],
    viewType: string
  ) => {
    let allListData: IFlightAllList[] = [];

    this.setArrivalData(arrivalList, allListData);
    this.setDepartureData(departureList, allListData);

    if (viewType === FlightsEnum.ViewType.Flight) {
      // Sort data by schedule ascending
      allListData = allListData.sort((a, b) =>
        a.schedule.localeCompare(b.schedule)
      );
    } else if (viewType === FlightsEnum.ViewType.OTP) {
      // Sort data by delay duration descending
      allListData = allListData.sort(
        (a, b) => b.delayDuration - a.delayDuration
      );
    } else {
      // Sort data by resourceAllocationStartTime ascending
      allListData = allListData.sort((a, b) =>
        a.resourceAllocationStart?.localeCompare(b.resourceAllocationStart)
      );
    }

    return allListData;
  };

  private static setArrivalData(
    arrivalList: IFlightArrival[],
    allListData: IFlightAllList[]
  ) {
    arrivalList.forEach((arrivalData) => {
      allListData.push({
        schedule: arrivalData.sibt,
        estimated: arrivalData.eibt,
        actual: arrivalData.aibt,
        origin: arrivalData.origin,
        destination: "",
        terminal: arrivalData.terminal,
        airline: arrivalData.airline,
        trafficType: arrivalData.trafficType,
        flightNumber: arrivalData.flightNumber,
        status: arrivalData.status,
        atmCategory: arrivalData.atmCategory,
        gate: arrivalData.gate,
        stand: arrivalData.stand,
        delayDuration: arrivalData.delayDuration
          ? parseInt(arrivalData.delayDuration)
          : 0,
        delayReasons: arrivalData.delayReasons,
        baggageBelt: arrivalData.baggageBelt,
        resourceAllocationStart: arrivalData.resourceAllocationStart ?? "",
        resourceAllocationEnd: arrivalData.resourceAllocationEnd ?? "",
        paxCount: arrivalData.paxCount ?? "",
        checkInCounterGroup: arrivalData.checkInCounterGroup ?? "",
        checkInCounter: arrivalData.checkInCounter ?? "",
        bookedPaxCount: arrivalData.bookedPaxCount,
        boardedPaxCount: arrivalData.boardedPaxCount,
        transferPaxCount: arrivalData.transferPaxCount,
      });
    });
  }

  private static setDepartureData(
    departureList: IFlightDeparture[],
    allListData: IFlightAllList[]
  ) {
    departureList.forEach((departureData) => {
      allListData.push({
        schedule: departureData.sobt,
        estimated: departureData.eobt,
        actual: departureData.aobt,
        origin: "",
        destination: departureData.destination,
        terminal: departureData.terminal,
        airline: departureData.airline,
        trafficType: departureData.trafficType,
        flightNumber: departureData.flightNumber,
        status: departureData.status,
        atmCategory: departureData.atmCategory,
        gate: departureData.gate,
        stand: departureData.stand,
        delayDuration: departureData.delayDuration
          ? parseInt(departureData.delayDuration)
          : 0,
        delayReasons: departureData.delayReasons,
        baggageChute: departureData.baggageChute,
        resourceAllocationStart: departureData.resourceAllocationStart ?? "",
        resourceAllocationEnd: departureData.resourceAllocationEnd ?? "",
        paxCount: departureData.paxCount ?? "",
        checkInCounterGroup: departureData.checkInCounterGroup ?? "",
        checkInCounter: departureData.checkInCounter ?? "",
        bookedPaxCount: departureData.bookedPaxCount,
        boardedPaxCount: departureData.boardedPaxCount,
        transferPaxCount: departureData.transferPaxCount,
      });
    });
  }

  public static getIcon(key: string) {
    if (key === FlightsEnum.LegType.Arrival) {
      return ArrivalIcon;
    } else if (key === FlightsEnum.LegType.Departure) {
      return DepartureIcon;
    } else {
      return "";
    }
  }
}
