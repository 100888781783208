import { PAXRowEnum, SelectedTouchPointFilterName } from "../../../enum/PAXEnum";

export function getSelectedTouchPointFilterName(selectedTouchpointFilter: string): PAXRowEnum{
    switch (selectedTouchpointFilter) {
        case SelectedTouchPointFilterName.CurbsideDropoff:
            return PAXRowEnum.EffectiveCurbUtilisation;
        case SelectedTouchPointFilterName.CarPark:
            return PAXRowEnum.EffectiveParkingUtilisation;
        case SelectedTouchPointFilterName.PublicTransport:
            return PAXRowEnum.Undefined;
        default:
            return PAXRowEnum.Undefined;
    }
}; 