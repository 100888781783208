import {
  makeStyles,
  SearchBox,
  SearchBoxChangeEvent,
  ToggleButton,
} from "@fluentui/react-components";
import React from "react";
import { useLocation } from "react-router-dom";
import { $cloud20, $primary, $white } from "../../../cssjs/colors";
import { BarsIcon } from "../icons/bars/BarsIcon";

interface IAirportListProps {
  isMapView?: boolean;
  sideBarActive: boolean;
  setSideBarActive: any;
  searchInputHandler: (event: SearchBoxChangeEvent) => void;
  children?: React.ReactElement;
}

const Sidebar: React.FunctionComponent<IAirportListProps> = ({
  sideBarActive,
  setSideBarActive,
  searchInputHandler,
  children,
}) => {
  const classes = useStyles();
  const burgerMenuHandler = React.useCallback(() => {
    setSideBarActive((prev: boolean) => !prev);
  }, [setSideBarActive]);

  const location = useLocation() ?? "";
  const isMultiAirportPage = location?.pathname?.startsWith("/multi-airports");
  const multiAirportStyles =
    sideBarActive && isMultiAirportPage
      ? classes.multiAirportContainerOpen
      : !sideBarActive && isMultiAirportPage
      ? classes.multiAirportContainerClosed
      : undefined;

  const placeholderText = isMultiAirportPage
    ? "Search airport"
    : "Search flight";

  return (
    <>
      <section
        data-testid="sidebar-container"
        className={`${classes.container} ${
          sideBarActive ? classes.containerOpen : undefined
        } ${multiAirportStyles}`}
      >
        <header
          className={`mapview-sidebar ${classes.header} ${
            sideBarActive ? classes.headerOpen : classes.headerClosed
          } ${
            isMultiAirportPage && !sideBarActive
              ? classes.multiAirportHeaderClosed
              : undefined
          }`}
        >
          <ToggleButton
            onClick={burgerMenuHandler}
            className={`${classes.burgerMenu}`}
            icon={<BarsIcon />}
            aria-label="Open the menu"
          />
          <SearchBox
            placeholder={placeholderText}
            onChange={(event: SearchBoxChangeEvent) => {
              searchInputHandler(event);
            }}
            size="medium"
            appearance="outline"
            aria-label="search"
            className={classes.sidebar}
          />
        </header>
        {sideBarActive ? children : null}
      </section>
    </>
  );
};

export default Sidebar;

const useStyles = makeStyles({
  container: {
    position: "relative",
    zIndex: 3,
    maxWidth: "400px",
    borderRadius: "8px",
    marginLeft: "8px",
    marginBottom: "16px",
  },
  containerOpen: {
    background: $cloud20,
    marginLeft: "16px",
  },
  multiAirportContainerOpen: {
    marginTop: "-32px",
  },
  multiAirportContainerClosed: {
    marginTop: "-40px",
  },
  header: {
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    display: "flex",
    justifyContent: "flex-start",
    color: $white,
  },
  headerOpen: {
    background: $white,
    padding: "16px",
  },
  headerClosed: {
    padding: "0px 8px 16px",
  },
  multiAirportHeaderClosed: {
    padding: "8px 8px 16px",
  },
  sidebar: {
    marginTop: "0",
    minWidth: "280px",
    marginRight: "4px",
    width: "100%",
  },
  burgerMenu: {
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${$primary}`,
    padding: "12px",
    minWidth: "42px",
    maxHeight: "36px",
    background: $white,
    color: $primary,
    "&:hover": {
      border: `1px solid ${$primary}`,
      background: $primary,
      color: $white,
      fill: $white,
    },
    marginRight: "16px",
  },
});
