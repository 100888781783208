import {
  Button,
  makeStyles,
  Tag,
  TagGroup,
  TagGroupProps,
} from "@fluentui/react-components";
import { DismissFilled } from "@fluentui/react-icons";
import * as React from "react";

interface IDisplayFilterPillsProps {
  tags: any[];
  onDismiss: TagGroupProps["onDismiss"];
  resetFilters: () => void;
}

export const DisplayFilterPills: React.FunctionComponent<
  IDisplayFilterPillsProps
> = (props: IDisplayFilterPillsProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {props.tags.length !== 0 && (
        <TagGroup
          size="small"
          onDismiss={props.onDismiss}
          aria-label="TagGroup for filters"
        >
          {props.tags.map((tag, _index) => (
            <Tag
              dismissible
              shape="circular"
              dismissIcon={{ "aria-label": "remove", style: { fontSize: 12 } }}
              value={tag.key}
              key={tag.key}
              className={styles.tag}
            >
              <span className={styles.tagText}>{tag.value}</span>
            </Tag>
          ))}
        </TagGroup>
      )}

      <Button
        onClick={props.resetFilters}
        icon={{
          as: "span",
          children: <DismissFilled fontSize={14} />,
        }}
        iconPosition="after"
        size="small"
        className={styles.filterButton}
      >
        Clear filters
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    rowGap: "10px",
    padding: "10px 0px",
  },
  tag: {
    backgroundColor: "white",
  },
  tagText: {
    fontWeight: "700",
  },
  filterButton: {
    border: "1px solid #0C66E1",
    padding: "0px 10px",
    margin: "0 10px",
    color: "#0C66E1",
    fontWeight: "500",
  },
});
