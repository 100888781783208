import matchFilterTouchPoints from "./matchFilterTouchPoints";

type ReturnType = {
  lineChartData: any;
  chartTitle: string;
};

export function filterChartDataByTouchPoint(
  filteredTouchPoint: { lineChartData: any; chartTitle: string }[],
  selectedTouchPointFilter: string
): ReturnType[] {
  const chartTitle = filteredTouchPoint[0]?.chartTitle;
  const filteredLineChartData = filteredTouchPoint
    ? filteredTouchPoint[0]?.lineChartData
    : [];

  const lineChartData = filteredLineChartData?.length
    ? matchFilterTouchPoints(filteredLineChartData, selectedTouchPointFilter)
    : [];

  return [
    {
      ...filteredTouchPoint,
      chartTitle,
      lineChartData: [...lineChartData],
    },
  ];
}
