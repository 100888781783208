import { AmdDialog } from "../dialog-popup/AmdDialogPopup";
import { AmdRotateDevice } from "../icons/rotate-device/RotateDevice";

export const TabletViewAlert: React.FunctionComponent<ITabletViewAlert> = (
  props: ITabletViewAlert
) => {
  return (
    <AmdDialog isOpen={props.isDeviceTablet && !props.isDeviceInLandscape}>
      <div className="text-c tabletViewAlert" data-testid="tabletViewAlert">
        <AmdRotateDevice />
        <p>Please rotate the device and hold it in landscape mode</p>
      </div>
    </AmdDialog>
  );
};

export interface ITabletViewAlert {
  isDeviceTablet: boolean;
  isDeviceInLandscape: boolean;
}
