import axios from "axios";
import TURNAROUND_SUMMARY from "../../mock/flights/turnaround-sufficiency.json";
export default class FlightListService {
  public getFlightList = async (
    accessToken: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    return axios.post(
      `${process.env.REACT_APP_API_FLIGHTLIST?.replace(
        "{airportCode}",
        airportCode
      )}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public getTurnaroundSufficiencyList = async (
    _accessToken: string,
    _payload: any
  ): Promise<any> => {
    return Promise.resolve(TURNAROUND_SUMMARY);
  };
}
