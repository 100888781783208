import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import EventManagementEnum from "../../../../enum/EventManagementEnum";
import { CommonHelper } from "../../../../utils/common/CommonHelper";
import {
  EVENT_RESPONSETEAM_SELECTEDGROUPS,
  EVENT_RESPONSETEAM_SELECTEDUSERS,
} from "../../../../utils/events/EventConstants";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { AmdTextField } from "../../../common/form-controls/controlled/TextField/AmdTextField";
import { AmdButton } from "../../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../../../common/icons/arrow/arrow";
import { ArrowDirection } from "../../../common/icons/arrow/arrowDirection";
import { EventResponseTeam } from "../../create-event/response-team/EventResponseTeam";

export interface IEventAddChannelProps {
  eventId: string;
  onChannelAdded?: any;
  onCancelAddChannel?: any;
  airport: string;
}

export const EventAddChannel: React.FunctionComponent<IEventAddChannelProps> = (
  props: IEventAddChannelProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [currentUserEmail, setCurrentUserEmail] = useState("");

  useEffect(() => {
    CommonHelper.getCurrentUserEmail(msalInstance).then((userEmail: string) => {
      setCurrentUserEmail(userEmail);
    });
  }, []);

  const methods = useForm();
  let responseFormData: any = {};

  const onAddChannel = async (data: any) => {
    data[EVENT_RESPONSETEAM_SELECTEDUSERS] =
      responseFormData[EVENT_RESPONSETEAM_SELECTEDUSERS];
    data[EVENT_RESPONSETEAM_SELECTEDGROUPS] =
      responseFormData[EVENT_RESPONSETEAM_SELECTEDGROUPS];

    const createChannelPayload = EventManagementHelper.getCreateChannelPayload(
      data,
      ["response_team"]
    );
    const response = await EventManagementHelper.createChannel(
      msalInstance,
      props.eventId,
      currentUserEmail,
      createChannelPayload,
      props.airport
    );

    // Reset the form field values and close the popup.
    methods.reset(formFieldsData);

    props.onChannelAdded(response);
  };

  const onSaveEventResponseForm = (formData: any) => {
    responseFormData = formData;
    methods.handleSubmit(onAddChannel)(formFieldsData);
  };

  const onCancelChannelCreation = () => {
    props.onCancelAddChannel();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onAddChannel)} noValidate>
        <div
          className="mt-3"
          id="addChannelContainer"
          data-testid="addChannelContainer"
        >
          <div className="mt-3">
            <AmdButton
              testId="addChannelCancel"
              text="Back"
              className={`${contentStyles.addChannelStyle}`}
              type={AmdButtonType.DEFAULT}
              onClick={onCancelChannelCreation}
              prefixImageIconBeforeText={true}
              imageIcon={
                <AmdArrow
                  direction={ArrowDirection.LEFT}
                  className={contentStyles.backIcon}
                />
              }
            />
          </div>

          <div className="fc--gray900 mt-3 mb-1 fs-21">Add channel</div>
          <div className="pl-0">
            <AmdTextField
              className="mb-2"
              controlId={EventManagementEnum.CreateChannel.ChannelName}
              label={"Channel name"}
              isMandatory={true}
              isMultiline={false}
            />
          </div>

          <div className="fc--gray900 mt-3 mb-1 fs-18">
            Response team in this channel
          </div>

          <div className="mt-3">
            <EventResponseTeam
              isInEditMode={true}
              onSaveForm={onSaveEventResponseForm}
              onCancelForm={onCancelChannelCreation}
              airport={props.airport}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const contentStyles = mergeStyleSets({
  addChannelStyle: {
    width: "100px",
    height: "40px",
  },
  backIcon: {
    paddingRight: "10px",
    marginTop: "5px",
  },
});

const formFieldsData: any = {
  ["channelName"]: "",
};
