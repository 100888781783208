import { makeStyles, Spinner } from "@fluentui/react-components";
import React from "react";
import { $cloud100, $cloud30, $white } from "../../../../cssjs/colors";
import RESOURCE_DATA from "../../../../mock/overview/pax-card-donut-data.json";
import { PersonWalkingLuggageIcon } from "../../../common/icons/person-walking-luggage/personWalkingLuggageIcon";
import { DonutCard } from "./shared/DonutCard";

export const PaxCard: React.FunctionComponent = (): React.JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [paxCardData, _setPaxData] = React.useState<any>(RESOURCE_DATA);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const classes = useStyles();
  return (
    <>
      {loading ? (
        <Spinner
          labelPosition="below"
          label="Loading..."
          className={classes.cardLoader}
        />
      ) : null}
      {!loading ? (
          <div className={`${classes.cardContainer}`}>
            <DonutCard data={paxCardData}>
              <PersonWalkingLuggageIcon />
            </DonutCard>
          </div>
      ) : null}
    </>
  );
};
const useStyles = makeStyles({
  cardLoader: {
    height: "100%",
  },
  cardContainer: {
    display: "grid",
    gridGap: "8px",
    gridTemplateColumns: "auto 1fr",
    gridTemplateRows: "36px 0px auto",
    borderRadius: "8px",
    boxSizing: "border-box",
    background: $white,
    padding: "8px",
    border: `1px solid ${$cloud30}`,
    minHeight: "100%",
    color: $cloud100,
  },
});
