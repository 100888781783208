import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import React from "react";

interface ErrorProps {
  title?: string;
  message?: string;
}

const ErrorMessage: React.FC<ErrorProps> = ({
  title = "Some error occured",
  message = "",
}) => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      className={`${contentStyles.errorMessage} pt-sm-2 ma-2`}
      data-testid="error-screen"
    >
      <Text variant="xxLarge">{`${title}. ${message}!`}</Text>
      <p className={contentStyles.messageBody}>
        Please retry again after some time.
        <br /> If the issue still persists, please contact the support team.
      </p>
    </Stack>
  );
};

export default ErrorMessage;

const contentStyles = mergeStyleSets({
  errorMessage: {
    height: "20vh",
    textAlign: "center",
    borderRadius: 10,
    backgroundColor: "white",
    border: "1px solid #464b93",
    boxShadow: "0 0 2px #0078d7",
  },
  messageBody: { fontSize: "14px", fontWeight: "400", width: "90%" },
});
