import { PublicClientApplication } from "@azure/msal-browser";
import { AuthHelper } from "./AuthHelper";

export abstract class APIHelper {
  public static async CallAPI(
    msalInstance: PublicClientApplication,
    apiPayload: any,
    apiMethod: any
  ) {
    const accessToken = await AuthHelper.getAccessToken(msalInstance);
    if (accessToken !== "") {
      return apiMethod(accessToken, apiPayload);
    }
    return "Access token cannot be empty!";
  }
}
