/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import IFilters from "../../models/filters/IFilters";

const initialState = {
  paxFilters: [] as IFilters[],
};

const paxFilterSlice = createSlice({
  name: "paxFilters",
  initialState,
  reducers: {
    setPaxFilters(state, action) {
      localStorage.setItem(
        `${action.payload.airport}paxFilters`,
        JSON.stringify(action.payload.filter)
      );
      state.paxFilters = action.payload.filter;
    },
  },
});

export const paxFilterActions = paxFilterSlice.actions;

export default paxFilterSlice.reducer;
