import { mergeStyleSets } from "@fluentui/react";
import moment from "moment-timezone";
import NotificationEnum from "../../enum/NotificationEnum";
import INotificationListItem from "../../models/notification/INotificationListItem";
import { AmdAmberLarge } from "../common/icons/amber-large/AmberLarge";
import { NotificationIcon } from "./NotificationIcon";

export const NotificationItem: React.FunctionComponent<
  INotificationItemProps
> = ({ selectedNotification }) => {
  return (
    <div className="amd-section-shadow" data-testid="notification-item">
      <div className="amd-container-hspacebtwn">
        {selectedNotification && (
          <div className="amd-container-hstart pr-1 notification-item">
            <div className="amd-container-vspacebtwn ai-center">
              <NotificationIcon category={selectedNotification.category} />
              {selectedNotification.severity ===
                NotificationEnum.Severity.Critical && <AmdAmberLarge />}
            </div>
            <div className={`ml-2 ${contentStyles.container}`}>
              <span
                className={`fs-18 fw-600 fc--gray900 ${contentStyles.contentWrap}`}
              >
                {selectedNotification.title}
              </span>
              <span className={`fw-400 fc--gray600 mb-1`}>
                {moment(selectedNotification.timestamp).fromNow()}
              </span>
              <span className="fw-400 fc--gray900 mb-1">
                {selectedNotification.body}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export interface INotificationItemProps {
  selectedNotification?: INotificationListItem;
  airport: string;
}

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "85%",
  },
  contentWrap: {
    lineHeight: 26,
    overflow: "hidden",
  },
  timeContent: {
    width: "12%",
    textAlign: "right",
  },
  bottomStyles: {
    paddingBottom: "3rem",
    borderBottom: "1px solid #cccccc",
  },
});
