import { mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import IPassengerTouchPoint from "../../../models/pax/IPassengerTouchPoint";
import { HOPHelper } from "../../../utils/health-of-operations/HOPHelper";
import { PassengerHelper } from "../../../utils/pax/PassengerHelper";
import { AmdClose } from "../../common/icons/close/Close";
import { HOPAlertIcon } from "../../health-of-operations/HOPAlertIcon";

export const PAXTouchPointsCard: React.FunctionComponent<
  IPassengerTouchPointCardProps
> = ({
  touchPoint,
  selectedTouchPoint,
  onRemoveFilterClick,
  selectedTouchPointFilter,
  onFilterClick,
}: IPassengerTouchPointCardProps) => {
  const { key: touchPointKey, label, status, statusText, filters } = touchPoint;
  return (
    <div className={`${contentStyles.card}`}>
      <div className="float-l w-100 mt-1">
        <h2
          className={`fw-600 fs-16 float-l w-80 mx-0 my-0 ${contentStyles.cardTitle}`}
        >
          <HOPAlertIcon status={status} /> <div className="ml-1">{label}</div>
        </h2>
        {touchPointKey === selectedTouchPoint ? (
          <div
            className="w-10 float-r cursor-pointer"
            data-testid="close-filter-icon"
            title="Remove filter"
            onClick={() => {
              onRemoveFilterClick();
            }}
            onKeyDown={() => {
              onRemoveFilterClick();
            }}
          >
            <AmdClose />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={`fs-13 ${HOPHelper.getLegendTextColor(status)}`}>
        {statusText}
      </div>

      {filters?.map((type) => {
        const {
          key: filterKey,
          label: filterLabel,
          status: filterStatus,
          type: filterType,
        } = type;
        const activeFilter = PassengerHelper.enableTouchPointFilter(
          selectedTouchPoint,
          touchPointKey,
          selectedTouchPointFilter,
          filterKey
        );
        return (
          <div
            key={filterKey}
            className={`mt-1 pt-1 pl-1 cursor-pointer ${PassengerHelper.getBorderStyles(
              filterStatus,
              false
            )} ${!activeFilter && contentStyles.disabledFilter} ${
              contentStyles.filter
            } `}
            onClick={() => {
              onFilterClick(filterKey, filterLabel, filterType);
            }}
            onKeyDown={() => {
              onFilterClick(filterKey, filterLabel, filterType);
            }}
          >
            {filterLabel}
          </div>
        );
      })}
    </div>
  );
};
// selectedTouchPoint: string,
// selectedTouchPointFilter: string,
// selectedTouchPointFilterLabel: string,
// selectedTouchPointFilterType: string,
const contentStyles = mergeStyleSets({
  card: {
    maxHeight: "200px",
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingBottom: "12px",
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
  },
  filter: {
    borderRadius: "5px 5px",
    height: "30px",
  },
  disabledFilter: {
    backgroundColor: "lightgray",
    pointerEvents: "none",
    opacity: 0.4,
  },
});

interface IPassengerTouchPointCardProps {
  touchPoint: IPassengerTouchPoint;
  selectedTouchPoint: string;
  selectedTouchPointFilter: string;
  selectedTouchPointFilterLabel: string;
  onFilterClick: any;
  onRemoveFilterClick: any;
}

export default PAXTouchPointsCard;
