import { PublicClientApplication } from "@azure/msal-browser";
import { ITag, MessageBarType } from "@fluentui/react";
import * as microsoftTeams from "@microsoft/teams-js";
import { v4 as uuidv4 } from "uuid";
import PageEnum from "../../enum/PageEnum";
import AIRPORT_CONFIG from "../../mock/airport-data/airport-config.json";
import USER_AIRPORT_MAP from "../../mock/airport-data/user-airport-mapping.json";
import IAirportConfig from "../../models/airports/AirportConfig";
import {
  DEFAULT_AIRPORT,
  MESSAGEBAR_TYPE,
  TEAMS_ENTITYMAPPING_ARRAY,
} from "./Constants";

export abstract class CommonHelper {
  public static getTeamsUrl(entityName: string) {
    return TEAMS_ENTITYMAPPING_ARRAY?.find((a) => a.name === entityName)?.url;
  }

  public static getTeamsEntityId(entityName: string) {
    return TEAMS_ENTITYMAPPING_ARRAY?.find((a) => a.name === entityName)
      ?.entityId;
  }

  public static redirectToHome = () => {
    const pathName = window.location.pathname.toLowerCase();
    if (!(pathName === "/" || pathName === `/${PageEnum.PageUrl.Home}`)) {
      window.location.href = "/";
    }
  };

  public static handleRedirects(entityName: string) {
    const url = this.getTeamsUrl(entityName);
    if (window?.location?.ancestorOrigins?.length > 0) {
      const entityId = this.getTeamsEntityId(entityName);
      const teamsAppId = process.env.REACT_APP_TEAMSAPP_ID;
      microsoftTeams.executeDeepLink(
        `https://teams.microsoft.com/l/entity/${teamsAppId}/${entityId}?webUrl=${url}`
      );
    } else {
      window.location.href = url ?? "/";
    }
  }

  public static readonly navigateToComponent = (
    url: string,
    scrollId: string,
    entityId: string
  ) => {
    if (scrollId) {
      window.sessionStorage.setItem("scrollId", scrollId);
    } else {
      window.sessionStorage.removeItem("scrollId");
    }

    if (window?.location?.ancestorOrigins?.length > 0) {
      const teamsAppId = process.env.REACT_APP_TEAMSAPP_ID;
      microsoftTeams.executeDeepLink(
        `https://teams.microsoft.com/l/entity/${teamsAppId}/${entityId}?webUrl=${url}`
      );
    } else {
      window.location.href = url;
    }
  };

  public static navigateToTeamsChannel = (
    channelId: string,
    channelName: string
  ) => {
    const navigateUrl = `https://teams.microsoft.com/_#/conversations/${channelName}?threadId=${channelId}&ctx=channel`;
    if (window?.location?.ancestorOrigins?.length > 0) {
      microsoftTeams.executeDeepLink(navigateUrl);
    } else {
      window.location.href = navigateUrl;
    }
  };

  public static getCurrentUserEmail = async (
    msalInstance: PublicClientApplication
  ): Promise<string> => {
    const isInTeamsContext = CommonHelper.isInTeams();
    if (isInTeamsContext) {
      return new Promise<string>((resolve, reject) => {
        microsoftTeams.getContext((context: any) => {
          if (context?.userPrincipalName) {
            resolve(context.userPrincipalName);
          } else {
            reject(
              new Error("User principal name not available in Teams context")
            );
          }
        });
      });
    } else {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        return Promise.resolve(accounts[0].username);
      } else {
        return Promise.reject(new Error("No accounts available in MSAL."));
      }
    }
  };

  public static getAirportCode = async (
    msalInstance: PublicClientApplication
  ): Promise<string> => {
    const userEmail = (await CommonHelper.getLoggedInUser(
      microsoftTeams,
      msalInstance
    )) as string;
    const userAirportMap: { [key: string]: string } = USER_AIRPORT_MAP;
    const airportCode = userAirportMap[userEmail?.toLowerCase()]
      ? userAirportMap[userEmail.toLowerCase()]
      : DEFAULT_AIRPORT;
    return Promise.resolve(airportCode);
  };

  public static getAirportConfig = (airportCode: string): IAirportConfig => {
    const airportConfig = AIRPORT_CONFIG.data.find(
      (config) => config.name === airportCode
    );
    if (!airportConfig) {
      return {
        name: "XYZ",
        hardCodedDates: false,
        timeZone: "Europe/Berlin",
      };
    }
    return airportConfig;
  };

  public static scrollIntoView(elementId: string) {
    const stackElement = document.getElementById(elementId);
    if (stackElement) {
      stackElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }

  public static getSuggestionKey = (suggestion: any) => {
    if (suggestion.email) {
      return suggestion.email;
    } else if (suggestion.id) {
      return suggestion.id;
    } else {
      return suggestion;
    }
  };

  public static getFilteredSuggestions = async (
    suggestions: any,
    _filterText: string,
    _tagList?: ITag[],
    _columns?: string[]
  ): Promise<ITag[]> => {
    const filteredSuggestions = suggestions.data.filter((suggestion: any) => {
      const key = CommonHelper.getSuggestionKey(suggestion);
      const name = suggestion.name ? suggestion.name : suggestion;
      return (
        !_tagList?.some((tag) => tag.key === key) &&
        name.toLowerCase().startsWith(_filterText.toLowerCase())
      );
    });

    const promises = filteredSuggestions.map((suggestion: any) => {
      const data: any = {
        name: suggestion.name ? suggestion.name : suggestion,
        key: CommonHelper.getSuggestionKey(suggestion),
      };

      _columns?.forEach((_column: string) => {
        data[_column] = suggestion[_column] ?? null;
      });

      return Promise.resolve(data);
    });

    return Promise.all(promises);
  };

  public static getMessageBarType(notificationType: string) {
    switch (notificationType) {
      case MESSAGEBAR_TYPE.SUCCESS:
        return MessageBarType.success;
      case MESSAGEBAR_TYPE.ERROR:
        return MessageBarType.error;
      case MESSAGEBAR_TYPE.INFORMATION:
        return MessageBarType.info;
      case MESSAGEBAR_TYPE.WARNING:
        return MessageBarType.warning;
      default:
        return MessageBarType.info;
    }
  }

  public static isInTeams = () => {
    // eslint-disable-next-line dot-notation
    const microsoftTeamsLib =
      microsoftTeams || (window as any)["microsoftTeams"];

    if (!microsoftTeamsLib) {
      return false; // the Microsoft Teams library is for some reason not loaded
    }

    if (
      (window.parent === window.self && (window as any).nativeInterface) ||
      window.name === "embedded-page-container" ||
      window.name === "extension-tab-frame"
    ) {
      return true;
    }
    return false;
  };

  public static isValidTenant = (
    userTenantId: string | undefined,
    allowedTenants: any
  ) => {
    let isValid = false;
    if (userTenantId) {
      if (allowedTenants.includes(userTenantId)) {
        isValid = true;
      }
    }

    return isValid;
  };

  public static getLoggedInUser = async (
    msTeamsLib: any,
    msalInstance: PublicClientApplication
  ) => {
    const isInTeamsContext = CommonHelper.isInTeams();

    if (isInTeamsContext) {
      return new Promise((resolve, _reject) => {
        msTeamsLib.getContext((context: any) => {
          resolve(context.userPrincipalName);
        });
      });
    } else {
      return msalInstance.getActiveAccount()?.username;
    }
  };

  public static refreshLocalStorage = () => {
    const cacheKey = "CacheRefreshToken";
    const cacheValue = window.localStorage.getItem(cacheKey);
    const refreshTokenValue = process.env
      .REACT_APP_CACHE_REFRESH_TOKEN as string;
    if (cacheValue === null || cacheValue !== refreshTokenValue) {
      window.localStorage.clear();
      window.sessionStorage.clear();

      // Set the key to local storage.
      window.localStorage.setItem(cacheKey, refreshTokenValue);
    }
  };

  public static getGuid = (): string => {
    return uuidv4();
  };

  public static getLayoutClass = (isInTeamsContext: boolean) => {
    return isInTeamsContext ? "amd-maincontainer--teams" : "";
  };

  public static getCarouselNavCurrentCounter = (
    counter: number,
    currentItem: number,
    carouselItemLength: number
  ) => {
    let currentCounter = currentItem;
    if (counter > 0) {
      currentCounter = (currentCounter + 1) % carouselItemLength;
    } else {
      currentCounter =
        currentCounter === 0 ? carouselItemLength - 1 : currentCounter - 1;
    }

    return currentCounter;
  };

  public static getSliderNavViewIndex = (
    viewBoxRef: any,
    totalCards: number,
    cardCounter: number,
    currentCardViewIndex: number,
    direction: string,
    width: number
  ) => {
    let viewIndex = 0;
    let scrollAmount = 0;
    const incrementFactor = cardCounter * width;
    if (direction === "right") {
      viewIndex = (currentCardViewIndex + cardCounter) % totalCards;
      const slideTimer = setInterval(() => {
        viewBoxRef.current.scrollLeft += incrementFactor;
        scrollAmount += incrementFactor;
        if (scrollAmount >= cardCounter * width) {
          window.clearInterval(slideTimer);
        }
      }, 25);
    } else {
      viewIndex =
        currentCardViewIndex - cardCounter < 0
          ? totalCards - 1
          : currentCardViewIndex - cardCounter;
      const slideTimer = setInterval(() => {
        viewBoxRef.current.scrollLeft -= incrementFactor;
        scrollAmount += incrementFactor;
        if (scrollAmount >= cardCounter * width) {
          window.clearInterval(slideTimer);
        }
      }, 25);
    }
    return viewIndex;
  };

  public static sliderIsScrolledToRight = (
    totalCards: number,
    viewBoxRef: any,
    contentRef: any
  ) => {
    if (totalCards > 0 && viewBoxRef.current && contentRef.current) {
      return (
        viewBoxRef.current.clientWidth === contentRef.current.clientWidth + 2 ||
        Math.round(viewBoxRef.current.scrollLeft) +
          viewBoxRef.current.clientWidth ===
          contentRef.current.clientWidth
      );
    }
    return false;
  };

  public static sliderIsScrolledToLeft = (viewBoxRef: any) => {
    if (viewBoxRef.current) {
      return viewBoxRef.current.scrollLeft === 0;
    }
    return false;
  };

  public static getTabWrapperClass = (
    styleType: "primary" | "secondary" | undefined
  ) => {
    if (styleType === "primary" || !styleType) {
      return "mb-2";
    }
    return "";
  };

  public static toTitleCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public static isTablet(agent: string) {
    //split the regex to reduce the complexity
    const TABLET_REGEX = new RegExp(
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch)))/
    );
    const TABLET_REGEX1 = new RegExp(
      /(kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
    );
    return TABLET_REGEX.test(agent) || TABLET_REGEX1.test(agent);
  }

  public static IsDeviceInLandscape(landscape: any) {
    return !!landscape;
  }

  public static getMultiProgressBarWidth(
    val1: number,
    val2: number,
    unit: string
  ) {
    if (unit === "%") {
      return [val1, val2 - val1, 100 - val2];
    } else {
      //Calculate Bar % from val1 and val2
      const finalValue = val2 + val2 / 4;
      const division1 = (val1 * 100) / finalValue;
      const division2 = ((val2 - val1) / finalValue) * 100;
      return [division1, division2, 100 - division1 - division2];
    }
  }

  public static logoutUser =
    (msalInstance: PublicClientApplication) => (_event: any) => {
      const homeAccountId = msalInstance.getAllAccounts()[0]?.homeAccountId;
      const logoutRequest = {
        account: msalInstance.getAccountByHomeId(homeAccountId),
      };
      msalInstance.logoutRedirect(logoutRequest);
    };

  public static navLinkStyle = (pathName: string) => {
    if (window.location.pathname.toLowerCase() === pathName.toLowerCase()) {
      return "fw-700";
    }
    return "";
  };

  public static navLinkActive = (pathName: string) => {
    if (window.location.pathname.toLowerCase() === pathName.toLowerCase()) {
      return "active";
    }
    return "";
  };

  public static getSelectedListClassName(value: string, selectedValue = "") {
    return value === selectedValue ? "bg--white" : "";
  }

  public static requiredFieldValidation = (errors: any, controlId?: any) => {
    return Object.keys(errors).includes(controlId) ? "Field is required" : "";
  };
}
