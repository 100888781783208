import { SelectedGraphDatum } from "../../models/events/IWeatherDisruptionSummary";

export function getCurrentTimeMinValue(
  type: string,
  graphicalState: SelectedGraphDatum[]
): [minTimeValue: string, maxTimeValue: string] | [] {
  if (!graphicalState.length) {
    return [];
  }
  const graphicalActualData = graphicalState
    ?.filter((set: SelectedGraphDatum) => set.datasetName === type)
    .map((set: SelectedGraphDatum) => set.timeValues);

  const timeValues = graphicalActualData?.flat().map(function (row) {
    return {
      x: row.datetime,
      y: row.value,
    };
  });

  const minTimeValue = timeValues[0].x;
  const maxTimeValue = timeValues[timeValues.length - 1].x;
  return [minTimeValue.toString(), maxTimeValue.toString()];
}
