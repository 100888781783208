import React from "react";

const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = React.useState<boolean>(false);

  React.useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const documentChangeHandler = () => setMatches(mediaQueryList?.matches);

    mediaQueryList?.addEventListener("change", documentChangeHandler);
    documentChangeHandler(); // Set the initial state

    return () => {
      mediaQueryList?.removeEventListener("change", documentChangeHandler);
    };
  }, [query]);

  return matches ?? false;
};

export default useMediaQuery;
