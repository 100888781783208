import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  mergeStyleSets,
} from "@fluentui/react";
import { DialogBody } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { NotificationContext } from "../../../context/NotificationContext";
import ROLE_FILTERS from "../../../data/filter-panel/role-filter.json";
import { IRoleItem } from "../../../models/admin/IRoleItem";
import IFilters from "../../../models/filters/IFilters";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { getRoleFilters } from "../../../store/role-filter/RoleFilterSelector";
import { roleFilterActions } from "../../../store/role-filter/RoleFilterSlice";
import { ROLES_COLUMNS } from "../../../utils/admin/AdminConstants";
import { AdminHelper } from "../../../utils/admin/AdminHelper";
import { MESSAGEBAR_TYPE } from "../../../utils/common/Constants";
import { AmdDataGrid } from "../../common/data-grid/AmdDataGrid";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdModal } from "../../common/form-controls/uncontrolled/Modal/AmdModal";
import { AmdTextField } from "../../common/form-controls/uncontrolled/TextField/AmdTextField";
import { AmdUser } from "../../common/icons/user/User";
import { FilterPanel } from "../../filters/filter-panel/FilterPanel";
import { AddRolePopup } from "./AddRolePopup";

export interface IRolesProps {
  airport: string;
}

export const Roles: React.FunctionComponent<IRolesProps> = ({ airport }) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const { addNotification } = React.useContext(NotificationContext);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [
    isDeleteModalOpen,
    { setTrue: showDeleteModal, setFalse: hideDeleteModal },
  ] = useBoolean(false);
  const [rolesList, setRolesList] = useState<IRoleItem[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRole, setSelectedRole] = useState<IRoleItem>();

  const dispatch = useAppDispatch();
  const filterObj = localStorage.getItem("roleFilters");
  let filters: IFilters[] = [];
  if (filterObj) {
    filters = JSON.parse(filterObj);
  }

  const setRoleFilters = () => {
    if (filters && filters.length > 0) {
      dispatch(roleFilterActions.setRoleFilters(filters));
    } else {
      dispatch(roleFilterActions.setRoleFilters(ROLE_FILTERS.data));
    }
  };

  const roleFilters = useAppSelector((state) => getRoleFilters(state));

  useEffect(() => {
    setRoleFilters();
  }, [dispatch]);

  const onUpdateFilter = (updatedItems: IFilters[]) => {
    dispatch(roleFilterActions.setRoleFilters(updatedItems));
  };

  useEffect(() => {
    let isMounted = true;

    AdminHelper.getRolesForComponents(msalInstance).then((response: any) => {
      if (isMounted) {
        setRolesList(response.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (selectedRoleId) {
      // Replace this code with API call.
      AdminHelper.getRoleComponentById(msalInstance, selectedRoleId)
        .then((selectedRole: IRoleItem) => {
          setSelectedRole(selectedRole);
          showModal();
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }
  }, [selectedRoleId]);

  const showRolePopup = () => {
    setSelectedRoleId("");
    setSelectedRole(undefined);
    showModal();
  };

  const onRoleAdded = (roleId: string, data: any) => {
    const addedRolesList = AdminHelper.getAddedRoleDetails(
      rolesList,
      roleId,
      data
    );
    setRolesList(addedRolesList);

    addNotification(
      MESSAGEBAR_TYPE.SUCCESS,
      `Added role: ${data.name} successfully!`
    );
  };

  const onEditRole = (item: any) => {
    setSelectedRoleId(item?.id);
  };

  const onDeleteRole = (item: any) => {
    setSelectedRoleId(item?.id);
    showDeleteModal();
  };

  const deleteRole = () => {
    const remainingRoles = rolesList.filter((role: IRoleItem) => {
      return role.id !== selectedRoleId;
    });
    setRolesList(remainingRoles);
    addNotification(MESSAGEBAR_TYPE.SUCCESS, `Deleted role successfully!`);
    hideDeleteModal();
  };

  const onSearchTextChange = (e: any) => {
    console.log(e);
  };

  return (
    <div data-testid="roles">
      <div className="amd-container-hspacebtwn amd-container-vcenter mb-2">
        <div className="d-flex ai-center">
          <div className="ff-amd fs-36 fw-400">Roles</div>
          <AmdTextField
            id="searchRoles"
            label=""
            isMandatory={false}
            isMultiline={false}
            placeholder="Search role"
            className={`ml-2 ${contentStyles.searchBox}`}
            onChange={onSearchTextChange}
          />
        </div>

        <div className="pt-sm-2">
          <AmdButton
            testId="addRole"
            text="Add role"
            onClick={showRolePopup}
            type={AmdButtonType.DEFAULT}
            prefixImageIconBeforeText={true}
            imageIcon={<AmdUser className="mt-1 mr-1" />}
            className="mr-1"
          />
        </div>
      </div>

      <FilterPanel
        filters={roleFilters}
        onFiltersApplied={() => {
          console.log("onFiltersApplied");
        }}
        onUpdateFilter={onUpdateFilter}
        airport={airport}
      />

      <div className="mt-2">
        {rolesList && rolesList.length > 0 && (
          <AmdDataGrid
            showActionButtons={true}
            rowItems={rolesList}
            columns={ROLES_COLUMNS}
            onEditItem={onEditRole}
            onDeleteItem={onDeleteRole}
          />
        )}

        <AddRolePopup
          hideDialog={!isModalOpen}
          onModalPopupClose={hideModal}
          onRoleAdded={onRoleAdded}
          selectedRoleId={selectedRoleId}
          selectedRole={selectedRole}
          airport={airport}
        ></AddRolePopup>

        <AmdModal
          isOpen={isDeleteModalOpen}
          isBlocking={true}
          header="Confirm delete"
          onClose={hideDeleteModal}
          containerClassName={modalStyles.container}
        >
          <div className={modalStyles.body}>
            <DialogBody>
              <div>
                Deleting a role will remove all associated permissions from any
                users assigned this organisation.
              </div>
              <div className="mt-1"> Are you sure you want to proceed?</div>
            </DialogBody>
            <DialogFooter>
              <DefaultButton text="Cancel" onClick={hideDeleteModal} />
              <PrimaryButton text="Yes, delete role" onClick={deleteRole} />
            </DialogFooter>
          </div>
        </AmdModal>
      </div>
    </div>
  );
};

const contentStyles = mergeStyleSets({
  searchBox: {
    width: "300px",
  },
});

const modalStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "25vh",
    width: "30vw",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
