import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import ResourceEnum from "../../../../enum/ResourceEnum";
import {
  IFlightArrival,
  IFlightDeparture,
  IFlightMixed,
} from "../../../../models/flights/IFlightList";
import { IFlightScheduleFilters } from "../../../../models/flights/IFlightScheduleFilters";
import { FlightListHelper } from "../../../../utils/flights/FlightListHelper";

const useFlightListSummaryEffect = (
  msalInstance: any,
  flightListState: IFlightScheduleFilters,
  viewType: string,
  airport: string
) => {
  const [flightListData, setFlightListData] = useState<{
    arrivalData: IFlightArrival[];
    departureData: IFlightDeparture[];
    mixedData: IFlightMixed[];
    total: number;
  }>({ arrivalData: [], departureData: [], mixedData: [], total: 0 });

  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    if (airport !== "") {
      setFlightListData({
        arrivalData: [],
        departureData: [],
        mixedData: [],
        total: 0,
      });
      const result =
        viewType === ResourceEnum.ResourceType.TurnaroundSufficiency
          ? FlightListHelper.getTurnaroundSufficiencyList(
              msalInstance,
              flightListState
            )
          : FlightListHelper.getFlightList(
              msalInstance,
              flightListState,
              airport
            );

      result
        .then((response) => {
          if (isMounted && response && response.data) {
            const flightListDataObj = FlightListHelper.getFlightListDataObj(
              flightListState.flightType.toString(),
              flightListData,
              response
            );
            setError(undefined);
            setFlightListData(flightListDataObj);
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted) {
            setError(error.message);
          }
        })
        .finally(() => {
          if (isMounted) {
            setIsLoading(false);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [airport, flightListState]);
  return { isLoading, error, flightListData };
};
export default useFlightListSummaryEffect;
