import FlightsEnum from "../../enum/FlightsEnum";
import ResourceEnum from "../../enum/ResourceEnum";

const securityLevel = "Security level";

export const FLIGHT_LIST_HEADER = {
  onTimePerformance: "Flights delayed > 15 mins",
  turnaroundSufficiency: "Flights with insufficient turnaround time",
  runwayDelay: "Flights delayed due to insufficient runway capacity",
  slotAdherence: "Flights not adhering to slot allocation",
};

export const FLIGHT_LEG_TYPE_TABS = [
  { value: "arrival", text: "Arrival" },
  { value: "departure", text: "Departure" },
];

const isResizable = true;

export const OTP_FLIGHTLIST_COLUMNS = [
  {
    name: "Status",
    fieldName: "status",
    minWidth: 90,
    maxWidth: 120,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "Airlines",
    fieldName: "airline",
    minWidth: 40,
    maxWidth: 60,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [
      ResourceEnum.ResourceType.Overall.toString(),
      FlightsEnum.ViewType.OTP.toString(),
    ],
  },
  {
    name: "Flight",
    fieldName: "flightNumber",
    minWidth: 60,
    maxWidth: 120,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "SIBT",
    fieldName: "sibt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "SOBT",
    fieldName: "sobt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "EIBT",
    fieldName: "eibt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "EOBT",
    fieldName: "eobt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "AIBT",
    fieldName: "aibt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "AOBT",
    fieldName: "aobt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "Insufficiency",
    fieldName: "delayDuration",
    minWidth: 60,
    maxWidth: 100,
    dataType: "number",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.TurnaroundSufficiency.toString()],
  },
  {
    name: "Delay",
    fieldName: "delayDuration",
    minWidth: 60,
    maxWidth: 100,
    dataType: "number",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.OTP.toString()],
  },
  {
    name: "Runway delay",
    fieldName: "delayDuration",
    minWidth: 60,
    maxWidth: 100,
    dataType: "number",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.RunwayDelay.toString()],
  },
  {
    name: "Difference from SIBT",
    fieldName: "delayDuration",
    minWidth: 60,
    maxWidth: 140,
    dataType: "number",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.SlotAdherence.toString()],
  },
  {
    name: "Delay reason",
    fieldName: "delayReasons",
    minWidth: 90,
    maxWidth: 140,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [
      ResourceEnum.ResourceType.OTP.toString(),
      ResourceEnum.ResourceType.SlotAdherence.toString(),
      ResourceEnum.ResourceType.RunwayDelay.toString(),
    ],
  },
  {
    name: securityLevel,
    fieldName: "trafficType",
    minWidth: 60,
    maxWidth: 140,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "Terminal",
    fieldName: "terminal",
    minWidth: 50,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "Aircraft",
    fieldName: "aircraft",
    minWidth: 50,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "Registration",
    fieldName: "registration",
    minWidth: 50,
    maxWidth: 110,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
  {
    name: "Routing",
    fieldName: "routing",
    minWidth: 50,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.Overall.toString()],
  },
];

export const MIXED_FLIGHTLIST_COLUMNS = [
  {
    name: "Status",
    fieldName: "status",
    minWidth: 90,
    maxWidth: 120,
    dataType: "string",
    isRowHeader: true,
    isResizable,
  },
  {
    name: "Flight",
    fieldName: "arrivalFlightNumber",
    minWidth: 60,
    maxWidth: 120,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Arrival.toString(),
  },
  {
    name: "SIBT",
    fieldName: "sibt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Arrival.toString(),
  },
  {
    name: "EIBT",
    fieldName: "eibt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Arrival.toString(),
  },

  {
    name: "AIBT",
    fieldName: "aibt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Arrival.toString(),
  },

  {
    name: securityLevel,
    fieldName: "arrivalTrafficType",
    minWidth: 60,
    maxWidth: 140,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Arrival.toString(),
  },
  {
    name: "Terminal",
    fieldName: "arrivalTerminal",
    minWidth: 50,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Arrival.toString(),
  },
  {
    name: "Aircraft",
    fieldName: "aircraft",
    minWidth: 50,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
  },
  {
    name: "Registration",
    fieldName: "registration",
    minWidth: 50,
    maxWidth: 110,
    dataType: "string",
    isRowHeader: true,
    isResizable,
  },
  {
    name: "Routing",
    fieldName: "routing",
    minWidth: 50,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
  },
  {
    name: "Flight",
    fieldName: "departureFlightNumber",
    minWidth: 60,
    maxWidth: 120,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Departure.toString(),
  },
  {
    name: "SOBT",
    fieldName: "sobt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Departure.toString(),
  },
  {
    name: "EOBT",
    fieldName: "eobt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Departure.toString(),
  },
  {
    name: "AOBT",
    fieldName: "aobt",
    minWidth: 60,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Departure.toString(),
  },
  {
    name: "Insufficiency",
    fieldName: "delayDuration",
    minWidth: 60,
    maxWidth: 100,
    dataType: "number",
    isRowHeader: true,
    isResizable,
  },

  {
    name: securityLevel,
    fieldName: "departureTrafficType",
    minWidth: 60,
    maxWidth: 140,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Departure.toString(),
  },
  {
    name: "Terminal",
    fieldName: "departureTerminal",
    minWidth: 50,
    maxWidth: 100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    iconName: FlightsEnum.LegType.Departure.toString(),
  },
];
