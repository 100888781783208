import React from "react";
import { IPassengerCardSummary } from "../../../models/pax/IPassengerSummary";
import { NoDataCard } from "../../common/no-data-card/NoDataCard";
import PassengerDetails from "./PassengerDetails";

export interface IPassengerSummaryDetails {
  data: IPassengerCardSummary[];
  emptyStateMessage?: string;
}

export const PassengerSummaryDetails: React.FC<IPassengerSummaryDetails> = ({
  data,
  emptyStateMessage,
}) => {
  if (!data || data.length === 0) {
    return <NoDataCard message={emptyStateMessage ?? "No Passenger Data"} />;
  }

  const { arrivals, departures } = data[0];

  return (
    <div
      className="amd-container-hspacebtwn pa-sm-2"
      data-testid="passenger-summary-details"
      style={{ height: 120 }}
    >
      <PassengerDetails title="Arrivals" details={arrivals} />
      <PassengerDetails title="Departures" details={departures} />
    </div>
  );
};

export default PassengerSummaryDetails;
