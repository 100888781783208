import { Legends } from "@fluentui/react-charting";
import { makeStyles } from "@fluentui/react-components";
import * as React from "react";
import { TouchPointName } from "../../../enum/PAXEnum";
import { getPassengerFlowState } from "../../../store/pax-flow/passengerFlowSelector";
import { useAppSelector } from "../../../store/StoreHooks";
import { ILegendExtended } from "../../../utils/pax/PassengerHelper";

const useClasses = makeStyles({
  genericStyles: {
    minWidth: "216px",
  },
  airportStyles: {
    minWidth: "210px",
  },
});

export const PAXTouchPointsGraphLegend: React.FunctionComponent<
  IPAXTouchPointsGraphLegendProps
> = (props: IPAXTouchPointsGraphLegendProps) => {
  const { legends: legendsArr, chartLegendKey } = props;
  const classes = useClasses();
  const paxflow = useAppSelector((state: any) => getPassengerFlowState(state));
  const { selectedTouchPointFilterType } = paxflow;

  const filteredlegendArr = legendsArr.filter(
    (legend: ILegendExtended) => selectedTouchPointFilterType === legend.type
  );

  const pickedLegendArr = filteredlegendArr?.length
    ? filteredlegendArr
    : legendsArr;

  return (
    <div data-testid="pax-touchpoints-graphlegend">
      {pickedLegendArr?.map((legend) => {
        const isAirport = chartLegendKey === TouchPointName.AirportEntry;

        const { key } = legend;

        return (
          <div
            className={
              isAirport ? classes.airportStyles : classes.genericStyles
            }
            key={key}
          >
            <Legends
              legends={[legend]}
              allowFocusOnLegends={false}
              canSelectMultipleLegends={false}
              enabledWrapLines={true}
              key={key}
            />
          </div>
        );
      })}
    </div>
  );
};

interface IPAXTouchPointsGraphLegendProps {
  headerTitle: string;
  chartLegendKey: string;
  legends: ILegendExtended[];
}

export default PAXTouchPointsGraphLegend;
