import {
  FontWeights,
  IIconProps,
  IconButton,
  Modal,
  getTheme,
  mergeStyleSets,
  mergeStyles,
} from "@fluentui/react";
import { useAppDispatch } from "../../../../../store/StoreHooks";
import { setDisruptionKpi } from "../../../../../store/weather-disruption/WeatherDisruptionSlice";
import { AmdEdit } from "../../../../common/icons/edit/Edit";
export interface IAmdModalProps {
  header: string;
  isOpen: boolean;
  onClose: any;
  children?: React.ReactElement;
  isBlocking?: boolean;
  containerClassName?: string;
  imageIcon?: any;
  prefixImageIconBeforeText?: boolean;
}

export const AmdModal: React.FunctionComponent<IAmdModalProps> = (
  props: IAmdModalProps
) => {
  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const dispatch = useAppDispatch();
  const modalStyles = mergeStyles({
    width: "100%",
    selectors: {
      "& .ms-Dialog-main": {
        width: "30vw",
        "@media (max-width: 1365px)": {
          width: "50%",
        },
      },
    },
  });

  return (
    <Modal
      className={modalStyles}
      isOpen={props.isOpen}
      isBlocking={props.isBlocking}
      containerClassName={props.containerClassName}
      allowTouchBodyScroll={true}
    >
      <div className={contentStyles.header}>
        {props.prefixImageIconBeforeText && <AmdEdit className="mr-1" />}
        <h2 className={contentStyles.heading}>{props.header}</h2>
        <IconButton
          className="modalpopup-close"
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close"
          title="Close"
          onClick={() => {
            props.onClose();
            dispatch(
              setDisruptionKpi({
                selectedKpiId: "",
                selectedKpi: "",
                selectedPlanningData: null,
              })
            );
          }}
        />
      </div>
      {props.children}
    </Modal>
  );
};

const theme = getTheme();

const contentStyles = mergeStyleSets({
  header: [
    theme.fonts.xLarge,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0",
  },
});

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
