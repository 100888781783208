import { DetailsRow, IDetailsRowStyles } from "@fluentui/react";
//IDetailsListProps["onRenderRow"]
export function onRenderRow(props: any)  {
    const customStyles: Partial<IDetailsRowStyles> = {};

    if (props?.itemIndex && props?.itemIndex % 2 !== 0) {
      customStyles.root = {
        backgroundColor: "#F2F2F2",
      };
    }

    return props ? <DetailsRow {...props} styles={customStyles} /> : <></>;
  };
