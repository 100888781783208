import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdPassengerDisruption: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill="none"
      data-testid="passengerDisruption-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 3C23 1.375 24.3125 0 26 0C27.625 0 29 1.375 29 3C29 4.6875 27.625 6 26 6C24.3125 6 23 4.6875 23 3ZM20 12.875C19.0625 13.3125 18.3125 14.0625 17.9375 15.0625L17.8125 15.25C17.4375 16.3125 16.25 16.8125 15.25 16.375C14.1875 16 13.6875 14.8125 14.125 13.8125L14.1875 13.5625C15 11.625 16.5 10.0625 18.375 9.1875L19.0625 8.875C20.375 8.3125 21.75 8 23.1875 8C26 8 28.5 9.6875 29.5625 12.25L30.5 14.5625L31.875 15.25C32.875 15.75 33.25 16.9375 32.75 17.9375C32.25 18.9375 31.0625 19.3125 30.0625 18.8125L28.4375 18C27.75 17.6875 27.25 17.125 27 16.4375L26.375 15L25.1875 19.125L28.25 22.5C28.625 22.875 28.875 23.3125 29 23.8125L30.4375 29.5625C30.6875 30.625 30 31.6875 28.9375 31.9375C27.875 32.25 26.8125 31.5625 26.5 30.5L25.125 25L20.75 20.1875C19.8125 19.1875 19.4375 17.75 19.8125 16.4375L20.875 12.5C20.8125 12.5 20.75 12.5625 20.6875 12.5625L20 12.875ZM19.25 21.5625L21.8125 24.3125L20.875 26.5625C20.75 26.9375 20.5 27.3125 20.25 27.5625L16.375 31.4375C15.625 32.25 14.3125 32.25 13.5625 31.4375C12.75 30.6875 12.75 29.375 13.5625 28.625L17.25 24.875L18.8125 21C18.9375 21.1875 19.125 21.375 19.25 21.5625ZM14.9375 17.1875C15.4375 17.4375 15.625 18.0625 15.3125 18.5L12.625 23.25C12.5625 23.4375 12.5 23.625 12.375 23.75L8.375 30.6875C7.875 31.625 6.625 32 5.6875 31.4375L2.1875 29.4375C1.25 28.875 0.9375 27.625 1.4375 26.6875L5.4375 19.75C6 18.8125 7.25 18.5 8.1875 19L11.625 21L13.625 17.5C13.875 17.0625 14.5 16.875 14.9375 17.1875Z"
        fill={props.color}
      />
    </svg>
  );
};
