import BaggageSummaryCard from "../../baggage/summary-card/BaggageSummaryCard";
import AmdSlider from "../../common/slider/AmdSlider";
import { FlightScheduleSummaryCard } from "../../flights/summary-card/FlightScheduleSummaryCard";
import { PassengersSummaryCard } from "../../pax/summary-card/PassengersSummaryCard";
import { ResourcesAllocationCard } from "../../resources-allocation/summary-card/ResourcesAllocationCard";
import { WeatherSummaryCard } from "../../weather/summary-card/WeatherSummaryCard";

export const HomeKPI: React.FunctionComponent<IHomeKPIProps> = ({
  airport,
}) => {
  return (
    <>
      <AmdSlider cardCounter={1}>
        <WeatherSummaryCard airport={airport} />
        <div data-testid="flights-kpi" className="cursor-pointer">
          <FlightScheduleSummaryCard airport={airport} />
          <BaggageSummaryCard />
        </div>
        <div data-testid="resource-allocation">
          <ResourcesAllocationCard airport={airport} />
        </div>
        <div data-testid="pax-kpi" className="cursor-pointer">
          <PassengersSummaryCard airport={airport} />
        </div>
      </AmdSlider>
    </>
  );
};
export default HomeKPI;

export interface IHomeKPIProps {
  airport: string;
}
