export const AccessDenied: React.FunctionComponent = () => {
  return (
    <>
      <h1 className="amd-heading1">Access Denied</h1>
      <section
        className="amd-section-shadow my-4"
        data-testid="accessDeniedContainer"
      >
        <div>Sorry, you dont have access to view the content.</div>
      </section>
    </>
  );
};
