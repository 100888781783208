import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdFilterFunnel: React.FunctionComponent<IAmdIconProps> = ({
  color = "#005EB8",
  dataTestId = "filter-funnel-icon",
  ...props
}) => {
  return (
    <span className={props.className}>
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={dataTestId}
      >
        <path
          d="M15.9688 0C16.875 0 17.3125 1.09375 16.6875 1.71875L11 7.4375V15C11 15.875 9.96875 16.3125 9.3125 15.7812L7.3125 14.0312C7.125 13.8438 7 13.5625 7 13.25V7.4375L1.28125 1.71875C0.65625 1.09375 1.09375 0 2 0H15.9688ZM10 7L16 1H2L8 7V13.25L10 15V7Z"
          fill={color}
        />
      </svg>
    </span>
  );
};
