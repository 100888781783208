import {
  ILineChartDataPoint,
  ILineChartPoints,
} from "@fluentui/react-charting";
import moment from "moment-timezone";
import { IFlightGraphResponse } from "../../models/flights/IFlightGraph";

export abstract class FlightGraphHelper {
  public static setFlightGraphData(
    response: IFlightGraphResponse[],
    handleLegendClick?: any
  ) {
    const graphData: ILineChartPoints[] = [];
    graphData.push(
      this._populateLineData(
        response,
        "orange",
        "Delayed",
        "delayed",
        handleLegendClick
      )
    );
    graphData.push(
      this._populateLineData(
        response,
        "red",
        "Cancelled",
        "cancelled",
        handleLegendClick
      )
    );
    graphData.push(
      this._populateLineData(
        response,
        "blue",
        "Scheduled",
        "scheduled",
        handleLegendClick
      )
    );
    graphData.push(
      this._populateLineData(
        response,
        "green",
        "Estimated",
        "estimated",
        handleLegendClick
      )
    );
    return graphData;
  }

  public static _populateLineData(
    _responseData: IFlightGraphResponse[],
    _color: string,
    _legend: string,
    _filterName: string,
    handleLegendClick?: any
  ) {
    const lineData: ILineChartPoints = {
      color: _color,
      data: [],
      legend: _legend,
      lineOptions: {
        strokeWidth: "2",
      },
      hideNonActiveDots: false,
      onLegendClick: handleLegendClick,
    };
    const dataArray: ILineChartDataPoint[] = [];

    if (_responseData) {
      _responseData.forEach((_data: IFlightGraphResponse) => {
        const _dataJson: ILineChartDataPoint = {
          x: new Date(_data.time),
          y: 0,
          xAxisCalloutData: moment(new Date(_data.time)).format("HH:mm"),
        };

        switch (_filterName) {
          case "scheduled":
            _dataJson.y = _data.scheduled;
            break;
          case "delayed":
            _dataJson.y = _data.delayed;
            break;
          case "cancelled":
            _dataJson.y = _data.cancelled;
            break;
          case "estimated":
            _dataJson.y = _data.estimated;
            break;
        }
        dataArray.push(_dataJson);
      });
    }

    lineData.data = dataArray;
    return lineData;
  }
}
