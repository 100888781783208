/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import IFilters from "../../models/filters/IFilters";

const initialState = {
  otpFlightFilters: [] as IFilters[],
};

const otpFlightsFilterSlice = createSlice({
  name: "otpFlightFilters",
  initialState,
  reducers: {
    setOtpFlightFilters(state, action) {
      localStorage.setItem("otpFlightFilters", JSON.stringify(action.payload));
      state.otpFlightFilters = action.payload;
    },
  },
});

export const flightsFilterActions = otpFlightsFilterSlice.actions;

export default otpFlightsFilterSlice.reducer;
