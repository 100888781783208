/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import IFilters from "../../models/filters/IFilters";

const initialState = {
  organizationFilters: [] as IFilters[],
};

const organizationFilterSlice = createSlice({
  name: "organizationFilters",
  initialState,
  reducers: {
    setOrganizationFilters(state, action) {
      localStorage.setItem(
        "organizationFilters",
        JSON.stringify(action.payload)
      );
      state.organizationFilters = action.payload;
    },
  },
});

export const organizationFilterActions = organizationFilterSlice.actions;

export default organizationFilterSlice.reducer;
