import { LineChartDatum } from "../../../store/pax-flow/contracts/contracts";

function matchFilterTouchPoints(
  touchPointArr: LineChartDatum[],
  selectedTouchPointFilter: string
) {
  return touchPointArr.filter((item) =>
    selectedTouchPointFilter === item?.type
  );
}

export default matchFilterTouchPoints;
