import { mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import IPassengerTouchPoint from "../../../models/pax/IPassengerTouchPoint";
import { PassengerHelper } from "../../../utils/pax/PassengerHelper";
import PAXTouchPointsCard from "./PAXTouchPointsCard";
import HOPEnum from "../../../enum/HOPEnum";

export const PAXTouchPointsFilterContainer: React.FunctionComponent<
  IPassengerTouchPointFilterProps
> = ({
  touchPoint,
  selectedTouchPoint,
  selectedTouchPointFilter,
  selectedTouchPointFilterLabel,
  onFilterClick,
  onRemoveFilterClick,
}: IPassengerTouchPointFilterProps) => {
  const { key: touchPointKey, status } = touchPoint;

  return (
    <div
      className={`ms-Grid-col mr-5 ${
        contentStyles.card
      } ${PassengerHelper.getBorderStyles(status, true)} ${
        selectedTouchPoint !== "" && selectedTouchPoint !== touchPointKey
          ? contentStyles.disabledCard
          : ""
      } ${status === HOPEnum.HOPDataStatus.Good ? contentStyles.cardSuccess : ""}`}
      data-testid="pax-touchpoints-filtercontainer"
    >
      <PAXTouchPointsCard
        touchPoint={touchPoint}
        selectedTouchPoint={selectedTouchPoint}
        selectedTouchPointFilter={selectedTouchPointFilter}
        selectedTouchPointFilterLabel={selectedTouchPointFilterLabel}
        onFilterClick={(
          filterName: string,
          filterLabel: string,
          filterType: string
        ) => {
          onFilterClick(touchPointKey, filterName, filterLabel, filterType);
        }}
        onRemoveFilterClick={onRemoveFilterClick}
      />
    </div>
  );
};

const contentStyles = mergeStyleSets({
  card: {
    width: "20%",
    borderRadius: "6px",
  },
  cardSuccess: {
    borderColor: "#d1d1d1",
  },
  disabledCard: {
    backgroundColor: "lightgray",
    pointerEvents: "none",
    opacity: 0.4,
  },
});

interface IPassengerTouchPointFilterProps {
  touchPoint: IPassengerTouchPoint;
  selectedTouchPoint: string;
  selectedTouchPointFilter: string;
  selectedTouchPointFilterLabel: string;
  onRemoveFilterClick?: any;
  onFilterClick?: any;
}

export default PAXTouchPointsFilterContainer;
