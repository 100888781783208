import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdExpand: React.FunctionComponent<IAmdIconProps> = ({
  width = "22",
  height = "22",
  color = "#212121",
  viewBox = "0 0 22 22"
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.25 0H21.25C21.6297 0 21.9435 0.282154 21.9932 0.648229L22 0.75V8.75C22 9.16421 21.6642 9.5 21.25 9.5C20.8703 9.5 20.5565 9.21785 20.5068 8.85177L20.5 8.75V2.56L2.56 20.5H8.75C9.1297 20.5 9.44349 20.7822 9.49315 21.1482L9.5 21.25C9.5 21.6297 9.21785 21.9435 8.85177 21.9932L8.75 22H0.75C0.370304 22 0.0565091 21.7178 0.00684667 21.3518L0 21.25V13.25C0 12.8358 0.335786 12.5 0.75 12.5C1.1297 12.5 1.44349 12.7822 1.49315 13.1482L1.5 13.25V19.438L19.438 1.5H13.25C12.8703 1.5 12.5565 1.21785 12.5068 0.851771L12.5 0.75C12.5 0.370304 12.7822 0.0565091 13.1482 0.00684667L13.25 0H21.25H13.25Z" fill={color}/>
    </svg>
  );
};
