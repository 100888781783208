import { TimeseriesDatum, FormatedKPI } from "../../components/events/crisis-dashboard/types";

export function groupKPIObjects(data: FormatedKPI[] | {}[]):TimeseriesDatum[] {
    const groupBy = (
      arr: any[],
      getKey: { ({ time }: { time: any }): any; (arg0: any): any }
    ) => {
      return arr.reduce((memo, item) => {
        const key = getKey(item);
        memo[key] ||= [];
        memo[key].push(item);
        return memo;
      }, {});
    };

    const byTimeKey = groupBy(data, ({ time }) => time);
    return Object.values(byTimeKey).map((items) => {
      //@ts-ignore
      return items.reduce((acc, item) => {
        const res = {
          ...acc,
          ...item,
        };
        return res;
      });
    });

  }