import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ITag, Label, mergeStyles, TagPicker } from "@fluentui/react";
import { useId } from "@fluentui/react-components";
import { Controller, useFormContext } from "react-hook-form";
import { AdminHelper } from "../../../../../utils/admin/AdminHelper";
import { CommonHelper } from "../../../../../utils/common/CommonHelper";

export interface IAmdTagPickControl {
  controlId: string;
  tagLabel: string;
  isMandatory: boolean;
  onTagSelected?: any;
  suggestionType: string;
  className?: string;
  columns?: string[];
  selectedItems?: ITag[];
  itemLimit?: number;
  eventId?: string;
  airport: string;
}

export const AmdTagPickControl: React.FunctionComponent<IAmdTagPickControl> = (
  props: IAmdTagPickControl
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const { control } = useFormContext();
  const tagId = useId(props.tagLabel);

  const onResolveSuggestions = async (
    filterText: string,
    tagList?: ITag[]
  ): Promise<ITag[]> => {
    const suggestions = await AdminHelper.getSuggestions(
      props.suggestionType,
      msalInstance,
      props.airport,
      props.eventId
    );
    return CommonHelper.getFilteredSuggestions(
      suggestions,
      filterText,
      tagList,
      props.columns
    );
  };

  return (
    <Controller
      name={props.controlId}
      control={control}
      rules={{ required: props.isMandatory }}
      render={({ field }) => (
        <>
          <Label htmlFor={tagId} className={props.className}>
            {props.tagLabel}
          </Label>
          <div id={tagId} className={`${tagPickerStyles} mb-2`}>
            <TagPicker
              {...field}
              aria-label={`Select ${props.tagLabel}`}
              onResolveSuggestions={onResolveSuggestions}
              defaultSelectedItems={props.selectedItems}
              onItemSelected={props.onTagSelected}
              itemLimit={props.itemLimit}
            />
          </div>
        </>
      )}
    ></Controller>
  );
};

const tagPickerStyles = mergeStyles({
  marginTop: "0px !important",
});
