import React from "react";
import { AmdTabs } from "../common/tabs/tabs";

export const DimensionTabs: React.FunctionComponent<any> = (props: any) => {
  const { onDimensionTabChange, dimensionName, dimensionTabList } = props;
  const onTabSelect = (data: string) => {
    onDimensionTabChange(data);
  };
  return (
    <div
      className="amd-container-hspacebtwn amd-container-vcenter mb-2 filter-details-page"
      data-testid="appSettings-details-page"
    >
      <AmdTabs
        defaultSelectedTab={dimensionName}
        tabList={dimensionTabList}
        dataTestId="dimension-tabs"
        onTabClick={onTabSelect}
        selectedTab={dimensionName}
      />
    </div>
  );
};
