import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdUpIcon: React.FunctionComponent<IAmdIconProps> = ({
  width = "20",
  height = "20",
  viewBox = "0 0 20 20",
  color = "#005EB8",
  ...props
}) => {
  return (
    <span className={props.className}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2071 8.70711C15.8166 9.09763 15.1834 9.09763 14.7929 8.70711L8.5 2.41421L2.20711 8.70711C1.81658 9.09763 1.18342 9.09763 0.792893 8.70711C0.402369 8.31658 0.402369 7.68342 0.792894 7.29289L7.79289 0.292893C8.18342 -0.0976322 8.81658 -0.0976321 9.20711 0.292893L16.2071 7.29289C16.5976 7.68342 16.5976 8.31658 16.2071 8.70711Z"
          fill={color}
        />
      </svg>
    </span>
  );
};
