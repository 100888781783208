import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
} from "@fluentui/react-components";

interface IAmdDialogProps {
  isOpen: boolean;
  children: React.ReactElement;
}

export const AmdDialog: React.FunctionComponent<IAmdDialogProps> = (
  props: IAmdDialogProps
) => {
  return (
    <Dialog open={props.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogContent>{props.children}</DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
