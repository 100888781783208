import { IPAXTouchPointComponentState } from "../../../models/pax/IPassengerTouchPoint";
import DateHelper from "../../../utils/common/DateHelper";
import { PassengerHelper } from "../../../utils/pax/PassengerHelper";
import { AmdTimePicker } from "../../common/form-controls/uncontrolled/TimePicker/AmdTimePicker";
import { TimeRangeSlider } from "../../common/time-range-slider/TimeRangeSlider";

export const PAXTouchPointsTimeRange: React.FunctionComponent<
  IPAXTouchPointsTimeRangeProps
> = (props: IPAXTouchPointsTimeRangeProps) => {
  const onTimeSliderChange = (start: number, _end: number) => {
    const stateObj = { ...props.state };
    stateObj.selectedStartTime = DateHelper.minuteToTime(start);
    stateObj.selectedEndTime =
      PassengerHelper.getUpdatedPAXFlowTimeBasedOnInterval(
        stateObj.selectedStartTime,
        props.state.selectedRefreshInterval,
        true
      );
    props.onStateUpdate(stateObj);
  };

  const onTimePickerChange = (e: any, pickerType: string) => {
    const stateObj = { ...props.state };
    const selectedTime = e.target.value ?? e.target.innerText;
    const updatedStateObj =
      PassengerHelper.getUpdatedStateBasedOnTimePickerChange(
        stateObj,
        pickerType,
        selectedTime
      );
    props.onStateUpdate(updatedStateObj);
  };

  return (
    <div
      className="mb-4 w-100 float-l d-flex-end"
      data-testid="pax-touchpoints-timerange"
    >
      <div className="d-flex-end float-r">
        <span className="mr-1 float-l">Time range</span>
        <div className="w-20 float-l">
          <AmdTimePicker
            label=""
            useHour12={false}
            isMandatory={true}
            defaultValue={DateHelper.getDateTimeFromHHMMFormat(
              props.state.selectedStartTime
            )}
            onChange={(e: any) => {
              onTimePickerChange(e, "start");
            }}
          />
        </div>
        <div className="w-40 float-l">
          <TimeRangeSlider
            label=""
            maxValue="23:59"
            minValue="00:00"
            value={{
              start: props.state.selectedStartTime,
              end: props.state.selectedEndTime,
            }}
            onTimeSliderChange={onTimeSliderChange}
            showValues={false}
          />
        </div>
        <div className="w-20 float-l">
          <AmdTimePicker
            label=""
            useHour12={false}
            isMandatory={true}
            defaultValue={DateHelper.getDateTimeFromHHMMFormat(
              props.state.selectedEndTime
            )}
            onChange={(e: Date) => {
              onTimePickerChange(e, "end");
            }}
          />
        </div>
      </div>
    </div>
  );
};

interface IPAXTouchPointsTimeRangeProps {
  state: IPAXTouchPointComponentState;
  onStateUpdate: any;
}
