import { mergeStyleSets } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { useApiCall } from "../../../hooks/useApiCall";
import IPaxSettings, {
  ITouchPoint,
} from "../../../models/app-settings/ITouchPoint";
import { getPaxSettings } from "../../../store/pax-touchpoints/PaxSettingsSelector";
import { paxSettingActions } from "../../../store/pax-touchpoints/PaxSettingsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { LoadingScreen } from "../../common/loading-screen/LoadingScreen";
import ErrorMessage from "../../common/message/ErrorMessage";
import { AmdTabs, ITab } from "../../common/tabs/tabs";
import { PaxTouchPoints } from "./PaxTouchPoints";

//Define the base URL and the Pax Touchpoints URL
const paxURL = process.env.REACT_APP_API_PAX_TOUCHPOINTS;
const baseURL = process.env.REACT_APP_API_BASE_URL;
const url = `${baseURL}${paxURL}`;
const AIRPORT_CODE_PARAMETER = "{airportCode}";

//PaxSettings Component to display the Passenger Flow Touchpoints
export const PaxSettings: React.FunctionComponent<IPaxSettingsProps> = (
  props: IPaxSettingsProps
) => {
  const dispatch = useAppDispatch();

  //Replace the airport code with Actual value in the base URL
  const urlWithParams = url?.replace(AIRPORT_CODE_PARAMETER, props.airport);

  const { airportTouchPoints, terminalsTouchPoints } = useAppSelector((state) =>
    getPaxSettings(state)
  );

  //Refreah flag to fetch Pax Settings if the Airport and Terminal Touchpoints are unavailable
  const refreshPaxSettings = airportTouchPoints.length === 0;

  //Fetch Pax Settings Touchpoints using Custom API hook
  const {
    data: paxFlowTouchpoints,
    error,
    isLoading,
  } = useApiCall<IPaxSettings>({
    url: urlWithParams,
    refresh: refreshPaxSettings,
    dependencies: [props.airport],
  });

  useEffect(() => {
    if (paxFlowTouchpoints?.airport) {
      dispatch(
        paxSettingActions.setPaxTouchpoints({
          airportTouchPoints: paxFlowTouchpoints?.touchPoints,
          terminalsTouchPoints: paxFlowTouchpoints?.terminals,
        })
      );
    }
  }, [paxFlowTouchpoints, dispatch]);

  //Extract the Terminals
  const terminals = terminalsTouchPoints.map((terminal) => {
    return { text: terminal.name, value: terminal.name };
  });
  const paxTabs: ITab[] = [
    { text: "Airport", value: "Airport" },
    ...(terminals || []),
  ];

  //Set the default selected Tab
  const firstPaxTab = paxTabs[0].value;
  const [selectedTab, setSelectedTab] = useState<string>(firstPaxTab);

  //Drop Down Tab select handler
  const onTabSelect = (e: any) => {
    setSelectedTab(e);
  };

  const onSaveHandler = () => {
    //Implement Save button logic here
    console.log("Save function called");
  };

  //Extract the Touchpoints as per the selected Tab
  const touchPointpaxFlowTouchpoints: ITouchPoint[] | undefined =
    selectedTab === "Airport"
      ? airportTouchPoints ?? []
      : terminalsTouchPoints.find((terminal) => terminal.name === selectedTab)
          ?.touchPoints ?? [];

  return (
    <section data-testid="pax-settings" className="amd-section-shadow">
      <h2 className={`fs-36 fw-400 fc--gray900 ${contentStyles.headerStyles}`}>
        Passenger flow touchpoints
      </h2>
      {
        //Show the error message if the API call fails
        error && (
          <div className="py-sm-1 ma-2" data-testid="pax-error-screen">
            <ErrorMessage message={error} />
          </div>
        )
      }
      {
        //Show the loading screen if the data is still loading
        isLoading && (
          <div
            className={contentStyles.loadingScreen}
            data-testid="pax-loading-screen"
          >
            <LoadingScreen
              message={"Loading..."}
              spinClassName={contentStyles.spinner}
              labelClassName={contentStyles.loadingMessage}
            />
          </div>
        )
      }
      {!error && !isLoading && (
        <div>
          <div className="pt-sm-4" data-testid="pax-settings-tabs-content">
            <AmdTabs
              defaultSelectedTab={firstPaxTab}
              tabList={paxTabs}
              dataTestId="pax-settings-tabs"
              onTabClick={onTabSelect}
              selectedTab={selectedTab}
            />
            {touchPointpaxFlowTouchpoints && (
              <PaxTouchPoints
                touchPoints={touchPointpaxFlowTouchpoints}
                selectedTab={selectedTab}
              />
            )}
          </div>
          <footer className="mt-4 mb-2">
            <Button
              onClick={onSaveHandler}
              appearance="primary"
              className="ml-2"
            >
              Save
            </Button>
          </footer>
        </div>
      )}
    </section>
  );
};

export interface IPaxSettingsProps {
  airport: string;
}

const contentStyles = mergeStyleSets({
  headerStyles: {
    height: "50px",
    borderBottom: "1.5px solid #E6E6E6",
    marginBottom: "-8px",
  },
  spinner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    color: "grey !important",
  },
  loadingMessage: {
    fontSize: "24px",
    color: "grey",
    fontStyle: "italic",
  },
  loadingScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "150px",
  },
});
