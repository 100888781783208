import React from "react";

export const PaxTouchPointDescr: React.FunctionComponent<
  ITouchPointsDescrProps
> = (props: ITouchPointsDescrProps) => {
  return (
    <div className={props.className}>
      <div className="fs-16 fw-400 pb-sm-2">{props.title}</div>
      <div className="fs-12 fw-400 fc--gray600 pr-sm-2">
        {props.description}
      </div>
    </div>
  );
};

export interface ITouchPointsDescrProps {
  title: string;
  description: string;
  className?: string;
}
