import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useApiCall } from "../../hooks/useApiCall";
import { IFlightScheduleFilters } from "../../models/flights/IFlightScheduleFilters";
import { IResourceGraphResponse } from "../../models/resource-allocation/IResourceGraph";
import ChartHelper from "../../utils/common/ChartHelper";
import { FlightListHelper } from "../../utils/flights/FlightListHelper";
import { ResourceGraphHelper } from "../../utils/resource/ResourceGraphHelper";
import { CustomLineChart } from "../common/charts/LineChart";
import { NoDataCard } from "../common/no-data-card/NoDataCard";
import { AmdTabs } from "../common/tabs/tabs";

export interface IResourceGraph {
  flightGraphState: IFlightScheduleFilters;
  resource: string;
  onDataPointClick: any;
  onLegendClick?: any;
  selectedType: string;
  airport: string;
}

export const ResourceGraph: React.FunctionComponent<IResourceGraph> = ({
  flightGraphState,
  resource,
  onDataPointClick,
  selectedType,
  onLegendClick,
  airport,
}) => {
  const [tabList, setTabList] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>("");

  useEffect(() => {
    setSelectedTab("");
    const tabList = ResourceGraphHelper.getResourceGraphTabs(
      selectedType,
      resource
    );
    if (tabList && tabList.length > 0) {
      setSelectedTab(tabList[0].value);
      setTabList(tabList);
    }
  }, [resource, selectedType]);

  const getResourceGraphUrl = `${process.env.REACT_APP_API_FLIGHTSCHEDULEGRAPH?.replace(
    "{airportCode}",
    airport
  )}/${selectedTab}`;

  const payload =
    FlightListHelper.getFlightListServicePayload(flightGraphState);
  const {
    data: resourceGraphData,
    error,
    isLoading,
  } = useApiCall<IResourceGraphResponse[]>({
    url: getResourceGraphUrl,
    payload,
    dependencies: [airport, selectedTab, flightGraphState],
    requestType: "post",
  });

  const onTabClick = (_selectedtab: string) => {
    setSelectedTab(_selectedtab);
  };

  return (
    <div data-testid="resourceGraph" className="pt-sm-2 ma-2">
      <AmdTabs
        dataTestId="ResourceAllocationGraphTab"
        defaultSelectedTab={selectedTab}
        tabList={tabList}
        onTabClick={onTabClick}
        selectedTab={selectedTab}
      />

      {error && <NoDataCard message="No Resource data found" />}

      {isLoading && (
        <Spinner
          labelPosition="below"
          label="Loading..."
          style={{ height: "40vh" }}
        />
      )}
      {!error &&
        !isLoading &&
        (resourceGraphData && resourceGraphData.length > 0 ? (
          <CustomLineChart
            chartData={resourceGraphData}
            onDataPointClick={onDataPointClick}
            onLegendClick={onLegendClick}
            resourceType={selectedTab}
            xAxisTickCount={ChartHelper.getxAxisTickCount(
              resourceGraphData[0].halfHourlyAggregate
            )}
            customDateTimeFormatter={(a: any) =>
              `${("0" + a.getHours()).slice(-2).toString()}:${(
                "0" + a.getMinutes()
              )
                .slice(-2)
                .toString()}`
            }
          ></CustomLineChart>
        ) : (
          <NoDataCard message="No Resource data found" />
        ))}
    </div>
  );
};
