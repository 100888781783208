import HOPEnum from "../../enum/HOPEnum";
import { AmdAmber } from "../common/icons/amber/Amber";
import { AmdGreen } from "../common/icons/green/Green";
import { AmdRed } from "../common/icons/red/red";

export const HOPAlertIcon: React.FunctionComponent<IHOPAlertIconProps> = (
  props: IHOPAlertIconProps
) => {
  return (
    <>
      {props.status === HOPEnum.HOPDataStatus.Poor && <AmdRed />}
      {props.status === HOPEnum.HOPDataStatus.Moderate && <AmdAmber />}
      {props.status === HOPEnum.HOPDataStatus.Good && <AmdGreen />}
    </>
  );
};

export interface IHOPAlertIconProps {
  status: string;
}
