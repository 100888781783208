import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdAmberLarge: React.FunctionComponent<IAmdIconProps> = ({
  width = "28",
  height = "28",
  viewBox = "0 0 28 28",
  color = "#F7A827",
  dataTestId = "amber-large-icon",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestId}
    >
      <path
        d="M15 19.9999C15 20.5522 14.5523 20.9999 14 20.9999C13.4477 20.9999 13 20.5522 13 19.9999C13 19.4476 13.4477 18.9999 14 18.9999C14.5523 18.9999 15 19.4476 15 19.9999ZM13.25 16.7499C13.25 17.1641 13.5858 17.4999 14 17.4999C14.4142 17.4999 14.75 17.1641 14.75 16.7499V10.2499C14.75 9.8357 14.4142 9.49992 14 9.49992C13.5858 9.49992 13.25 9.8357 13.25 10.2499V16.7499ZM11.5925 4.1704C12.6381 2.27558 15.3614 2.27514 16.4076 4.16962L25.658 20.9205C26.6701 22.7534 25.3444 24.9999 23.2506 24.9999H4.75686C2.66351 24.9999 1.33774 22.7541 2.34911 20.9213L11.5925 4.1704ZM15.0945 4.89475C14.6189 4.03362 13.3811 4.03382 12.9058 4.8951L3.66243 21.646C3.20271 22.4791 3.80534 23.4999 4.75686 23.4999H23.2506C24.2023 23.4999 24.8049 22.4788 24.3449 21.6456L15.0945 4.89475Z"
        fill={color}
      />
    </svg>
  );
};
