import { Slider, mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import DateHelper from "../../../utils/common/DateHelper";

export const TimeRangeSlider: React.FC<ITimeRangeSliderProps> = (
  props: ITimeRangeSliderProps
) => {
  const sliderAriaValueText = (value: number) => `${value} time`;
  const sliderValueFormat = (value: number) =>
    `${DateHelper.minuteToTime(value)}`;

  const [sliderObj, setSliderObj] = useState<ITimeRangeSliderState>({
    sliderLowerValue: DateHelper.timeToMinute(props.value.start),
    sliderValue: DateHelper.timeToMinute(props.value.end),
  });

  useEffect(() => {
    const stateObj = { ...sliderObj };
    stateObj.sliderLowerValue = DateHelper.timeToMinute(props.value.start);
    stateObj.sliderValue = DateHelper.timeToMinute(props.value.end);
    setSliderObj(stateObj);
  }, [props.value.start, props.value.end]);

  const sliderOnChange = (_value: number, range?: [number, number]) => {
    if (range) {
      const stateObj = { ...sliderObj };
      stateObj.sliderLowerValue = range[0];
      stateObj.sliderValue = range[1];
      setSliderObj(stateObj);
    }
  };

  const sliderOnChanged = (
    _event: any,
    _value: number,
    range?: [number, number]
  ) => {
    if (range) {
      props.onTimeSliderChange(range[0], range[1]);
    }
  };

  return (
    <div data-testid="time-range-slider">
      <Slider
        styles={mergeStyleSets({
          activeSection: { background: "rgb(0, 120, 212)" },
        })}
        label={props.label}
        min={DateHelper.timeToMinute(props.minValue)}
        max={DateHelper.timeToMinute(props.maxValue)}
        ariaValueText={sliderAriaValueText}
        valueFormat={sliderValueFormat}
        value={sliderObj.sliderValue}
        step={30}
        lowerValue={sliderObj.sliderLowerValue}
        ranged
        showValue={props.showValues ?? true}
        onChange={sliderOnChange}
        onChanged={sliderOnChanged}
      />
    </div>
  );
};

export interface ITimeRangeSliderProps {
  label: string;
  maxValue: string;
  minValue: string;
  value: any;
  onTimeSliderChange: any;
  showValues?: boolean;
}

interface ITimeRangeSliderState {
  sliderLowerValue: number;
  sliderValue: number;
}
