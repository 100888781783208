import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ITag, mergeStyleSets, TagPicker } from "@fluentui/react";
import { AdminHelper } from "../../../../../utils/admin/AdminHelper";
import { CommonHelper } from "../../../../../utils/common/CommonHelper";

interface IAmdTagPickerProps {
  controlId: string;
  tagLabel: string;
  isMandatory: boolean;
  onTagSelected?: any;
  suggestionType: string;
  className?: string;
  columns?: string[];
  selectedItems?: ITag[];
  itemLimit?: number;
  airport: string;
}

export const AmdTagPickControl: React.FunctionComponent<IAmdTagPickerProps> = (
  props: IAmdTagPickerProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;

  const onResolveSuggestions = async (
    filterText: string,
    tagList?: ITag[]
  ): Promise<ITag[]> => {
    const suggestions = await AdminHelper.getSuggestions(
      props.suggestionType,
      msalInstance,
      props.airport,
      ""
    );
    return CommonHelper.getFilteredSuggestions(
      suggestions,
      filterText,
      tagList,
      props.columns
    );
  };

  return (
    <div style={textFieldControlStyle}>
      <TagPicker
        aria-label={`Select ${props.tagLabel}`}
        onResolveSuggestions={onResolveSuggestions}
        defaultSelectedItems={props.selectedItems}
        onItemSelected={props.onTagSelected}
        itemLimit={props.itemLimit}
      />
    </div>
  );
};

const textFieldControlStyle: React.CSSProperties = {
  width: "100%",
  backgroundColor: "white",
};

const textfieldStyles = mergeStyleSets({
  root: {
    selectors: {
      "& .ms-Label": {
        outlineOffset: "2px",
      },
      "&:focus-visible": {
        outlineOffset: "2px",
      },
    },
  },
});
