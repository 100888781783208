import {
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import * as React from "react";
import { IInfoReason } from "../../../models/common/IInfoReason";
import { CommonHelper } from "../../../utils/common/CommonHelper";

export const InfoModalPopup: React.FunctionComponent<{
  infoReasons: IInfoReason[] | undefined;
  hideDialog: boolean;
  onModalPopupClose: any;
  headers: any[];
  modalTitle: string;
}> = ({ infoReasons, hideDialog, onModalPopupClose, headers, modalTitle }) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: modalTitle,
    closeButtonAriaLabel: "Close",
  };

  return infoReasons !== undefined ? (
    <div data-testid="infoReasonPopup">
      <Dialog hidden={hideDialog} dialogContentProps={dialogContentProps}>
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((h: any) => {
                return (
                  <TableHeaderCell key={CommonHelper.getGuid()}>
                    {h}
                  </TableHeaderCell>
                );
              })}
            </TableRow>
          </TableHeader>

          <TableBody>
            {infoReasons.map((_infoReasonItem) => {
              return (
                <TableRow key={CommonHelper.getGuid()}>
                  {modalTitle === "Delay Reasons" && (
                    <TableCell>{_infoReasonItem.id}</TableCell>
                  )}
                  <TableCell>{_infoReasonItem.name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <DialogFooter>
          <PrimaryButton onClick={onModalPopupClose} text="Close" />
        </DialogFooter>
      </Dialog>
    </div>
  ) : (
    <></>
  );
};
