import L from "leaflet";
import airportMarker from "./../../../assests/map-markers/airportMarker.svg";

export const airportIcon = new L.Icon({
  iconUrl: airportMarker,
  iconSize: [30, 40],
  iconAnchor: [15, 20],
  popupAnchor: [1, 1],
  shadowSize: [28, 26],
  tooltipAnchor: [0, -20],
});
