export function findMatches(wordToMatch: string | RegExp, airportList: any[]) {
  return airportList.filter((airport: { code: string; name: string }) => {
    // here we need to figure out if the airport code or name matches what was searched
    const regex = new RegExp(wordToMatch, "gi");
    return airport.code.match(regex) || airport.name.match(regex);
  });
}

export function filterAirportsByStatus(
  airportList: any[],
  status: string | undefined
) {
  if (status === "all") {
    return airportList;
  } else if (status === "pinned") {
    return airportList.filter((airport: any) => airport.isPinned);
  } else {
    return airportList.filter((airport: any) => airport.status === status);
  }
}

export function calculateAirportStatusCount(airportList: any[]) {
  return airportList.reduce(
    (acc: any, airport: any) => {
      acc.all += 1;

      if (airport.status === "good") {
        acc.good += 1;
      } else if (airport.status === "moderate") {
        acc.moderate += 1;
      } else if (airport.status === "poor") {
        acc.poor += 1;
      }

      if (airport.isPinned) {
        acc.isPinned += 1;
      }
      return acc;
    },
    {
      good: 0,
      moderate: 0,
      poor: 0,
      isPinned: 0,
      all: 0,
    }
  );
}

export function getCommonAirports(
  searchData: any[] = [],
  filteredData: any[] = []
) {
  const searchDataIds = searchData.map((airport: any) => airport.id);
  const filteredDataIds = filteredData.map((airport: any) => airport.id);
  const commonIds = searchDataIds.filter((id: string) =>
    filteredDataIds.includes(id)
  );
  return filteredData.filter((airport: any) => commonIds.includes(airport.id));
}

export function splitAirportDataByPin(airportList: any[] = []) {
  const { pinnedAirports, unPinnedAirports } = airportList.reduce(
    (acc: { pinnedAirports: any[]; unPinnedAirports: any[] }, airport: any) => {
      if (airport.isPinned) {
        acc.pinnedAirports.push(airport);
      } else {
        acc.unPinnedAirports.push(airport);
      }
      return acc;
    },
    { pinnedAirports: [], unPinnedAirports: [] }
  );
  return { pinnedAirports, unPinnedAirports };
}

export function extractAirportMapDetails(airportList: any[]) {
  return airportList.map((airport: any) => {
    return {
      id: airport.id,
      name: airport.name,
      location: airport.location,
    };
  });
}
