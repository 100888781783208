import { DayOfWeek, IComboBoxOption, IComboBoxStyles } from "@fluentui/react";
import * as React from "react";
import EventManagementEnum from "../../../../enum/EventManagementEnum";
import { EVENT_TYPE_OPTIONS } from "../../../../utils/events/EventConstants";
import { AmdComboBox } from "../../../common/form-controls/controlled/ComboBox/AmdComboBox";
import { AmdDatePicker } from "../../../common/form-controls/controlled/DatePicker/AmdDatePicker";
import { AmdTextField } from "../../../common/form-controls/controlled/TextField/AmdTextField";
import { AmdTimePicker } from "../../../common/form-controls/controlled/TimePicker/AmdTimePicker";

export interface IEventDetailsFormProps {
  onEventTypeChange?: any;
  eventType?: string;
}

export const EventDetailsForm: React.FunctionComponent<
  IEventDetailsFormProps
> = (props: IEventDetailsFormProps) => {
  const onEventTypeChange = (comboItem: any) => {
    props.onEventTypeChange(comboItem.text);
  };

  return (
    <>
      <header className="amd-section-shadow__header amd-container-hspacebtwn">
        <span>Event Details</span>
      </header>
      <div className="pl-1" data-testid="eventModalBody">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-md6 pl-0">
            <AmdComboBox
              className="mb-2"
              label={"Event type"}
              controlId={EventManagementEnum.CreateEvent.TypeOfEvent}
              options={eventTypeOptions}
              isMandatory={true}
              onChange={(comboItem: any) => onEventTypeChange(comboItem)}
              defaultValue={{ key: props.eventType }}
            />
          </div>
          <div className="ms-Grid-col ms-md6 pl-0">
            <AmdTextField
              className="mb-2"
              controlId={EventManagementEnum.CreateEvent.EventName}
              label={"Event name"}
              isMandatory={true}
              isMultiline={false}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <AmdTextField
            controlId={EventManagementEnum.CreateEvent.EventDescription}
            label={"Event description"}
            isMandatory={true}
            isMultiline={true}
          />
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-md6 pl-0">
            <AmdTextField
              className="mb-2"
              label={"Reporter"}
              controlId={EventManagementEnum.CreateEvent.CallerName}
              isMandatory={true}
              isMultiline={false}
            />
          </div>
          <div className="ms-Grid-col ms-md6 pl-0">
            <AmdTextField
              className="mb-2"
              label={"Reporter's contact number"}
              controlId={EventManagementEnum.CreateEvent.CallerContactNumber}
              isMandatory={true}
              isMultiline={false}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-md6 pl-0">
            <AmdComboBox
              className="mb-2"
              label={"Severity"}
              controlId={EventManagementEnum.CreateEvent.IncidentSeverity}
              options={incidentSeverityOptions}
              isMandatory={true}
            />
          </div>
          <div className="ms-Grid-col ms-md3 pl-0">
            <AmdDatePicker
              controlId={EventManagementEnum.CreateEvent.ReportedDate}
              label={"Date of report"}
              styles={dateTimePickerStyles}
              minDate={new Date()}
              firstDayOfWeek={firstDayOfWeek}
              isMandatory={true}
            />
          </div>
          <div className="ms-Grid-col ms-md3 pl-0">
            <AmdTimePicker
              controlId={EventManagementEnum.CreateEvent.ReportedTime}
              label={"Time of report"}
              styles={dateTimePickerStyles}
              useHour12={false}
              isMandatory={true}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-md3 pl-0">
            <AmdDatePicker
              controlId={EventManagementEnum.CreateEvent.LoggedDate}
              label={"Event logged date"}
              styles={dateTimePickerStyles}
              minDate={new Date()}
              firstDayOfWeek={firstDayOfWeek}
              isMandatory={true}
            />
          </div>
          <div className="ms-Grid-col ms-md3 pl-0">
            <AmdTimePicker
              controlId={EventManagementEnum.CreateEvent.LoggedTime}
              label={"Event logged time"}
              styles={dateTimePickerStyles}
              useHour12={false}
              isMandatory={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const firstDayOfWeek = DayOfWeek.Sunday;

const eventTypeOptions: IComboBoxOption[] = EVENT_TYPE_OPTIONS;
const incidentSeverityOptions: IComboBoxOption[] = [
  { key: "low", text: "Low" },
  { key: "medium", text: "Medium" },
  { key: "high", text: "High" },
];
const dateTimePickerStyles: Partial<IComboBoxStyles> = {
  optionsContainerWrapper: {
    width: "50%",
  },
};
