import { Checkbox, mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface IAmdCheckboxProps {
  controlId: string;
  label: string;
  defaultValue: boolean;
  className?: string;
  onChange?: any;
}

export const AmdCheckbox: React.FunctionComponent<IAmdCheckboxProps> = ({
  className = "",
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.controlId}
      control={control}
      render={({ field }) => (
        <Checkbox
          {...field}
          styles={checkBoxStyles}
          label={props.label}
          defaultChecked={props.defaultValue}
          className={className}
          onChange={props.onChange}
        />
      )}
    />
  );
};

const checkBoxStyles = mergeStyleSets({
  checkbox: {
    border: "1px solid #ccc",
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 400,
    color: "#1A1A1A",
  },
});
