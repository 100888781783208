import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdAircraft: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill="none"
      data-testid="aircraft-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 5.875V11.1875L24.8125 13.9375C21.875 15.875 20 19.25 20 23C20 26.4375 21.5 29.4375 23.9375 31.4375C23.8125 31.8125 23.4375 32 23.0625 32C23 32 22.9375 32 22.875 32L16 30L9.0625 32C9 32 8.9375 32 8.875 32C8.375 32 8 31.625 8 31.125V28.5C8 28.1875 8.125 27.9375 8.375 27.75L12 25V20.625L1.25 23.6875C0.625 23.875 0 23.375 0 22.6875V18.625C0 18.25 0.1875 17.9375 0.5 17.75L12 11.1875V5.875C12 3.75 13.8125 0 16 0C18.25 0 20 3.75 20 5.875ZM22 23C22 18.0625 26 14 31 14C35.9375 14 40 18.0625 40 23C40 28 35.9375 32 31 32C26 32 22 28 22 23ZM31 29C31.8125 29 32.5 28.375 32.5 27.5C32.5 26.6875 31.8125 26 31 26C30.125 26 29.5 26.6875 29.5 27.5C29.5 28.375 30.125 29 31 29ZM29.9375 18V23C29.9375 23.5625 30.4375 24 30.9375 24C31.5 24 31.9375 23.5625 31.9375 23V18C31.9375 17.5 31.5 17 30.9375 17C30.4375 17 29.9375 17.5 29.9375 18Z"
        fill={props.color}
      />
    </svg>
  );
};
