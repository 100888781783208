import axios from "axios";
import PAXEnum from "../../enum/PAXEnum";
import PAX_TOUCHPOINT_AIRPORT_ENTRY_GRAPH_MOCK from "../../mock/pax/airport-entry-graph-data.json";
import PAX_TOUCHPOINT_BOARDING_GRAPH_MOCK from "../../mock/pax/boarding-graph-data.json";
import PAX_TOUCHPOINT_CHECKIN_GRAPH_MOCK from "../../mock/pax/checkin-graph-data.json";
import PAX_TOUCHPOINT_SECURITY_GRAPH_MOCK from "../../mock/pax/security-graph-data.json";

import PAX_TOUCHPOINT_AIRPORT_ENTRY_TABLE_MOCK from "../../mock/pax/airport-entry-table-data.json";
import PAX_TOUCHPOINT_BOARDING_TABLE_MOCK from "../../mock/pax/boarding-table-data.json";
import PAX_TOUCHPOINT_CHECKIN_TABLE_MOCK from "../../mock/pax/checkin-table-data.json";
import PAX_TOUCHPOINT_SECURITY_TABLE_MOCK from "../../mock/pax/security-table-data.json";

import {
  AIRPORT_CODE_PARAMETER,
  DATE_PARAMETER,
} from "../../utils/common/Constants";
import DateHelper from "../../utils/common/DateHelper";
import { PaxDataResultType } from "../../store/pax-flow/contracts/contracts";

export default class PassengerService {
  public getPassengerSummary = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    const dateStr = DateHelper.getDefaultDateStrForAirport(airportCode);

    return axios.get(
      `${process.env.REACT_APP_API_PAX_SUMMARY?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      ).replace(DATE_PARAMETER, dateStr)}`,
      {
        headers,
      }
    );
  };

  public getPassengerGraphData = async (
    accessToken: string,
    airportCode: string,
    payload: any
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    return axios.post(
      `${process.env.REACT_APP_API_PAX_GRAPH?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      JSON.stringify(payload),
      {
        headers,
      }
    );
  };

  public getTouchPointGraphData = async (
    _accessToken: string,
    touchPointName: string,
    _timeInterval: string
  ):Promise<PaxDataResultType | undefined> => {
    // temporary till the api is available
    switch (touchPointName) {
      case PAXEnum.TouchPointName.AirportEntry:
        return Promise.resolve(PAX_TOUCHPOINT_AIRPORT_ENTRY_GRAPH_MOCK);
      case PAXEnum.TouchPointName.CheckIn:
        return Promise.resolve(PAX_TOUCHPOINT_CHECKIN_GRAPH_MOCK);
      case PAXEnum.TouchPointName.Boarding:
        return Promise.resolve(PAX_TOUCHPOINT_BOARDING_GRAPH_MOCK);
      case PAXEnum.TouchPointName.Security:
         return Promise.resolve(PAX_TOUCHPOINT_SECURITY_GRAPH_MOCK);
      default:
        return undefined;
    }
  };


  public getTouchPointTableData = async (
    _accessToken: string,
    touchPointName: string,
    _timeInterval: string
  ): Promise<any> => {
    // temporary till the api is available
    let mockData;
    switch (touchPointName) {
      case PAXEnum.TouchPointName.AirportEntry:
        mockData = PAX_TOUCHPOINT_AIRPORT_ENTRY_TABLE_MOCK;
        break;
      case PAXEnum.TouchPointName.CheckIn:
        mockData = PAX_TOUCHPOINT_CHECKIN_TABLE_MOCK;
        break;
      case PAXEnum.TouchPointName.Boarding:
        mockData = PAX_TOUCHPOINT_BOARDING_TABLE_MOCK;
        break;
      case PAXEnum.TouchPointName.Security:
        mockData = PAX_TOUCHPOINT_SECURITY_TABLE_MOCK;
        break;
    }

    return Promise.resolve(mockData);
  };
}
