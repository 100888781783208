import { PublicClientApplication } from "@azure/msal-browser";
import { ITag } from "@fluentui/react";
import {
  IChartProps,
  ILegend,
  ILineChartDataPoint,
  ILineChartPoints,
} from "@fluentui/react-charting";
import * as AdaptiveCards from "adaptivecards";
import * as ACData from "adaptivecards-templating";
import MarkdownIt from "markdown-it";
import { FetchGraphicalDataReturn } from "../../components/events/crisis-dashboard/types";
import EventManagementEnum from "../../enum/EventManagementEnum";
import PageEnum from "../../enum/PageEnum";
import { ICreateEventPayload } from "../../models/events/ICreateEvent";
import { EventResponseTeamState } from "../../models/events/IEventResponseTeam";
import { IEventResponseTeamGroup } from "../../models/events/IEventResponseTeamGroup";
import { IEventResponseTeamMember } from "../../models/events/IEventResponseTeamMember";
import { IEventUserPrivilege } from "../../models/events/IEventRole";
import { IEventSummary } from "../../models/events/IEventSummary";
import { IEventCreateChannel } from "../../models/events/IEventTeamChannel";
import { IWeatherDisruptionSummaryItem } from "../../models/events/IWeatherDisruptionSummary";
import EventManagementService from "../../services/events/eventmanagement.service";
import {
  EVENTITEM_CHANNELTABS,
  EVENTITEM_TABS,
  EVENT_RESPONSETEAM_SELECTEDGROUPS,
  EVENT_RESPONSETEAM_SELECTEDUSERS,
  WEATHER_DISRUPTION_ARRAY,
} from "../../utils/events/EventConstants";
import { APIHelper } from "../api/APIHelper";
import { CommonHelper } from "../common/CommonHelper";
import DateHelper from "../common/DateHelper";
import { RESOURCE_COLORS } from "../resource/ResourceConstants";

export abstract class EventManagementHelper {
  public static async createEvent(
    msalInstance: PublicClientApplication,
    payload: ICreateEventPayload,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();
    const createEventMethod = (
      accessToken: string,
      payload: ICreateEventPayload
    ) => {
      return eventManagementService.createEvent(
        accessToken,
        payload,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, payload, createEventMethod);
  }

  public static async getPlanningGraphical(
    msalInstance: PublicClientApplication,
    airportCode: string,
    resourceType: string,
    date: string
  ): Promise<FetchGraphicalDataReturn> {
    const eventManagementService = new EventManagementService();

    const eventSummaryMethod = (accessToken: string) => {
      return eventManagementService.getPlanningGraphicalApi(
        accessToken,
        airportCode,
        resourceType,
        date
      );
    };

    return APIHelper.CallAPI(msalInstance, null, eventSummaryMethod);
  }
  public static async getWeatherDisruptionCardDetails(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const eventManagementService = new EventManagementService();
    const eventDisruptionMethod = (accessToken: string) => {
      return eventManagementService.getDisruptionSummary(accessToken);
    };

    return APIHelper.CallAPI(msalInstance, null, eventDisruptionMethod);
  }
  public static getWeatherDisruptionCardArray({
    weatherSummaryData,
    keys,
  }: {
    weatherSummaryData: any;
    keys: any;
  }): Array<Array<IWeatherDisruptionSummaryItem>> {
    const rowCount: number = keys.length / 4;
    const weatherCardArray = new Array<Array<IWeatherDisruptionSummaryItem>>();
    let index = 0;
    for (let i = 0; i < rowCount; i++) {
      const _tempWeatherSummaryArray: Array<IWeatherDisruptionSummaryItem> =
        new Array<IWeatherDisruptionSummaryItem>();
      for (let j = 0; j < 3; j++) {
        if (index < 6) {
          const weatherSummaryItem: IWeatherDisruptionSummaryItem =
            weatherSummaryData[keys[index].name];
          _tempWeatherSummaryArray.push(weatherSummaryItem);
          index++;
        }
      }
      weatherCardArray.push(_tempWeatherSummaryArray);
    }
    return weatherCardArray;
  }

  public static async getEventDetailById(
    msalInstance: PublicClientApplication,
    eventId: string,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();
    const eventDetailByIdMethod = (accessToken: string) => {
      return eventManagementService.getEventDetailById(
        accessToken,
        eventId,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, eventDetailByIdMethod);
  }

  public static async updateEvent(
    msalInstance: PublicClientApplication,
    eventId: string,
    payload: any,
    airportCode: any
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const updateEventMethod = (accessToken: string, payload: any) => {
      return eventManagementService.updateEvent(
        accessToken,
        eventId,
        payload,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, payload, updateEventMethod);
  }

  public static getTemplateResponseTeam = (
    msalInstance: PublicClientApplication,
    eventType: string,
    airportCode: string
  ) => {
    const eventManagementService = new EventManagementService();
    const getEventTemplateMethod = (accessToken: string) => {
      return eventManagementService.getTemplateResponseTeam(
        accessToken,
        eventType,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, getEventTemplateMethod);
  };

  public static async updateEventResponseTeam(
    msalInstance: PublicClientApplication,
    eventId: string,
    payload: any,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const updateEventResponseTeamMethod = (
      accessToken: string,
      payload: any
    ) => {
      return eventManagementService.updateEventResponseTeam(
        accessToken,
        eventId,
        payload,
        airportCode
      );
    };

    return APIHelper.CallAPI(
      msalInstance,
      payload,
      updateEventResponseTeamMethod
    );
  }

  public static async getEventChannels(
    msalInstance: PublicClientApplication,
    currentUserEmail: string,
    airportCode: string,
    eventId?: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const getEventChannelsMethod = (accessToken: string) => {
      return eventManagementService.getEventChannels(
        accessToken,
        currentUserEmail,
        airportCode,
        eventId ? eventId : ""
      );
    };

    return APIHelper.CallAPI(msalInstance, null, getEventChannelsMethod);
  }

  public static async createChannel(
    msalInstance: PublicClientApplication,
    eventId: string,
    currentUserEmail: string,
    payload: IEventCreateChannel,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const createChannelMethod = (accessToken: string) => {
      return eventManagementService.createChannel(
        accessToken,
        eventId,
        currentUserEmail,
        payload,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, payload, createChannelMethod);
  }

  public static async editChannel(
    msalInstance: PublicClientApplication,
    eventId: string,
    channelId: string,
    currentUserEmail: string,
    payload: IEventCreateChannel,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const editChannelMethod = (accessToken: string) => {
      return eventManagementService.editChannel(
        accessToken,
        eventId,
        channelId,
        currentUserEmail,
        payload,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, payload, editChannelMethod);
  }

  public static async deleteChannel(
    msalInstance: PublicClientApplication,
    eventId: string,
    channelId: string,
    currentUserEmail: string,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const deleteChannelMethod = (accessToken: string) => {
      return eventManagementService.deleteChannel(
        accessToken,
        eventId,
        channelId,
        currentUserEmail,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, deleteChannelMethod);
  }

  public static async getEventChannelInfo(
    msalInstance: PublicClientApplication,
    eventId: string,
    channelId: string,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const getEventChannelInfoMethod = (accessToken: string) => {
      return eventManagementService.getEventChannelInfo(
        accessToken,
        eventId,
        channelId,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, getEventChannelInfoMethod);
  }

  public static async getChannelManagement(
    msalInstance: PublicClientApplication,
    eventId: string,
    currentUserEmail: string,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const getChannelManagementMethod = (accessToken: string) => {
      return eventManagementService.getChannelManagement(
        accessToken,
        eventId,
        currentUserEmail,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, getChannelManagementMethod);
  }

  public static async updateChannelManagement(
    msalInstance: PublicClientApplication,
    eventId: string,
    currentUserEmail: string,
    payload: any,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const updateChannelManagementMethod = (accessToken: string) => {
      return eventManagementService.updateChannelManagement(
        accessToken,
        eventId,
        currentUserEmail,
        payload,
        airportCode
      );
    };

    return APIHelper.CallAPI(
      msalInstance,
      payload,
      updateChannelManagementMethod
    );
  }

  public static async getEventUserPrivileges(
    msalInstance: PublicClientApplication,
    eventId: string,
    currentUserEmail: string,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const getUserPrivilegesMethod = (accessToken: string) => {
      return eventManagementService.getUserPrivileges(
        accessToken,
        eventId,
        currentUserEmail,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, getUserPrivilegesMethod);
  }

  public static getEventPayload = (
    formData: any,
    currentUserEmail: string,
    operations: string[]
  ) => {
    const payload: ICreateEventPayload = {
      details: this.getEventDetailsPayload(
        formData,
        currentUserEmail,
        operations
      ),
      responseTeam: this.getEventResponseTeamPayload(formData, operations),
    };

    return payload;
  };

  public static getCreateChannelPayload = (
    formData: any,
    operations: string[]
  ) => {
    const payload: IEventCreateChannel = {
      name: formData.channelName,
      responseTeam: this.getEventResponseTeamPayload(formData, operations),
    };

    return payload;
  };

  public static getResponseTeamPayload = (
    formData: any,
    operations: string[]
  ) => {
    return this.getEventResponseTeamPayload(formData, operations);
  };

  public static mapSelectedTagsToArray = (selectedTags: any) => {
    return selectedTags.map((selectedTag: any) => {
      return selectedTag?.key
        ? {
            id: selectedTag.key,
            name: selectedTag.name,
          }
        : selectedTag;
    });
  };

  public static getChartData = (eventSummaryGroup: IEventSummary) => {
    let chartData: any[] = [];
    if (eventSummaryGroup && eventSummaryGroup?.items?.length > 0) {
      chartData = eventSummaryGroup.items.map((c, index) => ({
        resourceColor: RESOURCE_COLORS.colors[index],
        resourceCount: c.count,
        resourceTitle: c.label,
      }));
    }
    return chartData;
  };

  public static readonly getEventIdFromEventTaskURL = (eventId: string) => {
    return this.getEventId(eventId, "/event-tasks");
  };

  public static readonly getEventIdFromCrisisboardURL = (eventId: string) => {
    return this.getEventId(eventId, "/crisis-dashboard");
  };

  private static readonly getEventId = (eventId: string, urlString: string) => {
    // eslint-disable-next-line prefer-destructuring
    if (window.location.href.toLowerCase().indexOf(urlString) > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("eventId") ?? "";
    }

    return eventId;
  };

  public static isAdminUser = (roles: any, currentUserEmail: string) => {
    // from roles, get the role name of the user whose email is currentUserEmail in the emails array
    const adminRoles = roles?.filter(
      (role: any) =>
        role.isAdmin === true &&
        role?.emails?.some((email: string) => {
          return email.toLowerCase() === currentUserEmail.toLowerCase();
        })
    );
    return adminRoles.length > 0;
  };

  public static getLoggedInUserRoles = (
    roles: any,
    currentUserEmail: string
  ) => {
    return roles?.filter((role: any) =>
      role?.emails?.some((email: string) => {
        return email.toLowerCase() === currentUserEmail.toLowerCase();
      })
    );
  };

  public static renderAdaptiveCard = (
    elementId: string,
    adaptiveCardTemplate: any,
    adaptiveCardData: any
  ) => {
    adaptiveCardData = this.formatCallerDetailsDateTime(adaptiveCardData);

    // Render the card
    const adaptiveCard = new AdaptiveCards.AdaptiveCard();

    AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
      const md = new MarkdownIt();
      result.outputHtml = md.render(text);
      result.didProcess = true;
    };

    // Set its hostConfig property unless you want to use the default Host Config
    // Host Config defines the style and behavior of a card
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: "Segoe UI, Helvetica Neue, sans-serif",
    });

    // Create a Template instance from the template payload
    const template = new ACData.Template(adaptiveCardTemplate);

    const evaluationContext: ACData.IEvaluationContext = {
      $root: {},
    };

    evaluationContext.$root = adaptiveCardData;
    const card = template.expand(evaluationContext);

    adaptiveCard.parse(card);

    // Render the card to an HTML element:
    // eslint-disable-next-line
    const renderedCard = adaptiveCard.render();

    const elementDiv = document.getElementById(elementId);
    if (elementDiv && renderedCard) {
      while (elementDiv.firstChild) {
        if (elementDiv.lastChild) {
          elementDiv.removeChild(elementDiv.lastChild);
        }
      }

      elementDiv.appendChild(renderedCard);
    }

    return { adaptiveCard };
  };

  public static isStepCompleted = (
    currentStepId: string,
    adaptiveCardData: any,
    actionData: any
  ) => {
    const stepFields = Object.keys(adaptiveCardData["tasks"][currentStepId]);
    let isStepCompleted = true;

    stepFields.forEach((stepField: string) => {
      if (stepField !== "status") {
        const stepFieldJson =
          adaptiveCardData["tasks"][currentStepId][stepField];
        const stepFieldId = stepFieldJson["id"];
        const isStepFieldMandatory = JSON.parse(
          stepFieldJson["isRequired"] ?? false
        );

        if (isStepFieldMandatory && actionData[stepFieldId] === "") {
          isStepCompleted = false;
        }
      }
    });

    return isStepCompleted;
  };

  public static disableAdaptiveCardFields = (taskFields: {
    disableIdArray: any[];
    disableSaveArray: any[];
    totalFields: number;
  }) => {
    taskFields.disableIdArray?.forEach((fieldIdToDisable: string) => {
      const divElement = document.getElementById(fieldIdToDisable);

      if (divElement) {
        const divFields = divElement.getElementsByTagName("*");
        Array.from(divFields).forEach((divField: any) => {
          divField.setAttribute("disabled", "true");
        });
      }
    });

    //Disable Save button if any field is disabled
    if (taskFields.disableSaveArray.length > 0) {
      const saveButtons = document.querySelectorAll('[id^="step"][id$="save"]');
      saveButtons.forEach((saveButton: any) => {
        const buttonFields = saveButton.getElementsByTagName("button");
        Array.from(buttonFields).forEach((buttonField: any) => {
          buttonField.setAttribute("disabled", "true");
          buttonField.classList?.add("ac-pushButton-disabled");
          buttonField.classList?.remove("ac-pushButton");
        });
      });
    }
  };

  public static getTaskFields = (
    userRoles: any,
    adaptiveCardData: any,
    makeFieldNonMandatory = false
  ) => {
    const fields: any = {
      disableIdArray: [],
      disableSaveArray: [],
      totalFields: 0,
    };
    const steps = Object.keys(adaptiveCardData["tasks"]);
    steps.forEach((step: string) => {
      let shouldDisableSaveButton = false;
      const stepJson = adaptiveCardData["tasks"][step];
      const stepFields = Object.keys(stepJson);

      stepFields.forEach((stepField: any) => {
        if (stepField !== "status" && stepField !== "metadata") {
          const filteredUserRoles = userRoles.filter((userRole: any) => {
            return userRole.roleId === stepJson[stepField].roleId;
          });

          if (userRoles.length === 0 || filteredUserRoles.length === 0) {
            fields.disableIdArray.push(stepJson[stepField]["id"]);
            shouldDisableSaveButton = true;
          }
          fields.totalFields += 1;
        }
      });

      //add save button id to disableSaveArray
      if (shouldDisableSaveButton) {
        fields.disableSaveArray.push(`${step}.save`);
      }
    });

    if (makeFieldNonMandatory) {
      this.markFieldsNonMandatory(adaptiveCardData, fields.disableIdArray);
    }

    return fields;
  };

  public static markFieldsNonMandatory = (
    adaptiveCardData: any,
    fieldsToDisable: string[]
  ) => {
    fieldsToDisable.forEach((fieldId: string) => {
      const fieldArray = fieldId.split(".");
      if (fieldArray.length === 3) {
        adaptiveCardData[fieldArray[0]][fieldArray[1]][fieldArray[2]][
          "isRequired"
        ] = false;
      }
    });
  };

  public static markStepCompletion = (
    currentStepNumber: string,
    nextStepNumber: string,
    adaptiveCardData: any,
    action: any
  ) => {
    const isStepCompleted = EventManagementHelper.isStepCompleted(
      currentStepNumber,
      adaptiveCardData,
      action?.data
    );

    Object.keys(action?.data).forEach((key) => {
      if (key.indexOf("tasks.") > -1 || key.indexOf("decision.") > -1) {
        const val = action?.data[key];
        delete action?.data[key];
        action.data[`${key}.value`] = val;
      }
    });

    if (isStepCompleted) {
      action.data["completedStepNumber"] = currentStepNumber;
      action.data["nextStepNumber"] = nextStepNumber;
      action.data[`tasks.${currentStepNumber}.metadata.completed`] = true;
    }
  };

  public static showNewEventForm = () => {
    return window.sessionStorage.getItem("isNewEventClicked") === "true";
  };

  public static onNewEventClick = () => {
    window.sessionStorage.setItem("isNewEventClicked", "true");
    CommonHelper.navigateToComponent(
      `/${PageEnum.PageUrl.EventManagement}`,
      "",
      PageEnum.PageUrl.EventManagement
    );
  };

  public static getResponseTeamMembers = (members: any[]) => {
    let items: IEventResponseTeamMember[] = [];
    items = members?.map((member: any) => ({
      id: member?.id,
      name: member.name,
      email: member.email,
      roles: member.roles?.map((usr: any) => usr.name).join(", "),
      organization: member.organization?.name,
      responsibilities: member.responsibilities
        ?.map((resp: any) => resp.name)
        .join(", "),
      terminals: member.terminals?.join(", "),
      isEdit: member.isEdit,
      isDelete: member.isDelete,
    }));
    return items;
  };

  public static getResponseTeamGroups = (groups: any[]) => {
    let items: IEventResponseTeamGroup[] = [];
    items = groups?.map((group: any, index: number) => ({
      key: group.id ? group.id : `teamGroupKey-${index}`,
      id: group.id,
      roles: group.roles?.map((usr: any) => usr.name).join(", "),
      organizations: group.organizations
        ?.map((org: any) => org.name)
        .join(", "),
      responsibilities: group.responsibilities
        ?.map((resp: any) => resp.name)
        .join(", "),
      terminals: group.terminals
        ?.map((terminal: any) => terminal.name)
        .join(", "),
      isEdit: group.isEdit,
      isDelete: group.isDelete,
    }));
    return items;
  };

  public static getChannelListData = (
    channels: any,
    isUserPartOfChannelMgmt: boolean
  ) => {
    const showEditDeleteOption = isUserPartOfChannelMgmt ?? false;
    return channels.map((channel: any) => ({
      id: channel.channelId,
      name: channel.name,
      responseTeam: `${channel.membersCount} members`,
      createdBy: channel.creator,
      isEdit: channel.isEdit && showEditDeleteOption,
      isDelete: channel.isDelete && showEditDeleteOption,
    }));
  };

  public static getResponseTeamGroupTags = (data: any[]) => {
    let itemTags: ITag[] = [];
    if (data) {
      itemTags = data.map((d: any) => ({
        key: d.id,
        name: d.name,
      }));
    }

    return itemTags;
  };

  public static getMergedTeamStateObj = (
    eventTypeResponseTemplateData: any,
    eventResponseTeamState: any
  ) => {
    const templateUserList = eventTypeResponseTemplateData?.users ?? [];
    const templateGroupList = eventTypeResponseTemplateData?.groups ?? [];

    const customUserList = eventResponseTeamState?.responseTeam?.users ?? [];
    const customGroupList = eventResponseTeamState?.responseTeam?.groups ?? [];

    templateUserList?.forEach((user: any) => {
      user.isEdit = true;
      user.isDelete = true;
    });

    templateGroupList?.forEach((group: any) => {
      group.isEdit = false;
      group.isDelete = false;
    });

    customUserList?.forEach((user: any) => {
      user.isEdit = true;
      user.isDelete = true;
    });

    customGroupList?.forEach((group: any) => {
      group.isEdit = !(group.id === undefined || group.id === "");
      group.isDelete = true;
    });

    const finalUserList = templateUserList?.concat(customUserList);

    const finalGroupList = templateGroupList?.concat(customGroupList);

    const mergedTeamStateObj: EventResponseTeamState =
      EventManagementHelper.getResponseTeamStateObjToUpdate(
        finalUserList,
        finalGroupList
      );

    // Map terminal data to array of strings. ["t1", "t2"]. This is only for terminals and not for other tags.
    if (
      mergedTeamStateObj?.responseTeam &&
      mergedTeamStateObj?.responseTeam?.groups?.length > 0
    ) {
      const mergedTeamStateGroups = mergedTeamStateObj?.responseTeam?.groups;
      for (let i = 0; i < mergedTeamStateGroups.length; i++) {
        const group = mergedTeamStateGroups[i];
        if (group) {
          mergedTeamStateObj.responseTeam.groups[i].terminals =
            this.mapTerminalsToKeyValuePair(group?.terminals);
        }
      }
    }

    return mergedTeamStateObj;
  };

  public static getResponseTeamAddedUserObj = (
    user: any,
    eventResponseTeamState: any
  ) => {
    const dataObj: any = {
      id: user.id,
      name: user.name,
      email: user.key,
      roles: user.roles,
      organization: user.organization,
      responsibilities: user.responsibilities,
      terminals: user.terminals,
    };
    const users = eventResponseTeamState?.responseTeam.users ?? [];
    users?.push(dataObj);

    const responseTeamDataObj: EventResponseTeamState =
      EventManagementHelper.getResponseTeamStateObjToUpdate(
        users,
        eventResponseTeamState?.responseTeam.groups ?? []
      );

    return responseTeamDataObj;
  };

  public static getResponseTeamDeletedUserObj = (
    user: IEventResponseTeamMember,
    eventResponseTeamState: any
  ) => {
    const users =
      eventResponseTeamState?.responseTeam?.users?.filter(
        (_user: IEventResponseTeamMember) => _user.id !== user.id
      ) ?? [];
    const groups = eventResponseTeamState?.responseTeam.groups ?? [];

    const responseTeamDataObj: EventResponseTeamState =
      EventManagementHelper.getResponseTeamStateObjToUpdate(users, groups);

    return responseTeamDataObj;
  };

  public static getResponseTeamAddedGroupObj = (
    group: IEventResponseTeamGroup,
    eventResponseTeamState: any
  ) => {
    group.terminals = this.mapTerminalsToArray(group?.terminals);

    const groups = eventResponseTeamState?.responseTeam.groups ?? [];
    if (group.id) {
      const index = groups.findIndex(
        (_group: IEventResponseTeamGroup) => _group.id === group.id
      );
      groups[index] = group;
    } else {
      groups?.push(group);
    }

    const responseTeamDataObj: EventResponseTeamState =
      EventManagementHelper.getResponseTeamStateObjToUpdate(
        eventResponseTeamState?.responseTeam.users ?? [],
        groups ?? []
      );

    return responseTeamDataObj;
  };

  public static getResponseTeamDeletedGroupObj = (
    group: IEventResponseTeamGroup,
    eventResponseTeamState: any
  ) => {
    const users = eventResponseTeamState?.responseTeam.users ?? [];
    const groups =
      eventResponseTeamState?.responseTeam?.groups?.filter(
        (_group: IEventResponseTeamGroup) => _group.id !== group.id
      ) ?? [];

    const responseTeamDataObj: EventResponseTeamState =
      EventManagementHelper.getResponseTeamStateObjToUpdate(users, groups);

    return responseTeamDataObj;
  };

  public static readonly mapAllTerminalsToArray = (payload: any) => {
    // Map terminal data to array of strings. ["t1", "t2"]. This is only for terminals and not for other tags.
    if (payload && payload?.groups?.length > 0) {
      for (let i = 0; i < payload?.groups?.length; i++) {
        const group = payload.groups[i];
        if (group) {
          payload.groups[i].terminals = this.mapTerminalsToArray(
            group?.terminals
          );
        }
      }
    }

    return payload;
  };

  public static readonly mapTerminalsToArray = (terminals: any) => {
    if (Array.isArray(terminals) && terminals.length > 0) {
      if (terminals[0].id) {
        return terminals.map((grp: any) => grp.name);
      }
    }

    return terminals;
  };

  public static readonly mapTerminalsToKeyValuePair = (terminals: any) => {
    if (Array.isArray(terminals) && terminals.length > 0 && !terminals[0].id) {
      return terminals.map((terminalName: any) => ({
        id: terminalName,
        name: terminalName,
      }));
    }
    return terminals;
  };

  public static getResponseTeamStateObjToUpdate = (users: any, groups: any) => {
    return {
      responseTeam: {
        users: users,
        groups: groups,
      },
      lastUpdatedTimestamp: new Date(),
    };
  };

  public static clearEditDeleteFlags = (items: any) => {
    items?.forEach((item: any) => {
      delete item.isEdit;
      delete item.isDelete;
    });
  };

  public static manageResponseTeam = (
    resourceType: string,
    action: string,
    data: any,
    eventResponseTeamState: EventResponseTeamState | undefined
  ) => {
    let responseTeamObj: any = {};
    if (resourceType === EventManagementEnum.ResponseTeamResourceType.User) {
      responseTeamObj = this.manageResponseTeamUser(
        action,
        data,
        eventResponseTeamState
      );
    }

    if (resourceType === EventManagementEnum.ResponseTeamResourceType.Group) {
      responseTeamObj = this.manageResponseTeamGroup(
        action,
        data,
        eventResponseTeamState
      );
    }

    return responseTeamObj;
  };

  public static onNewEventResponseTeamSave = (
    eventResponseTeamState: any,
    data: any
  ) => {
    data[EVENT_RESPONSETEAM_SELECTEDUSERS] =
      eventResponseTeamState?.responseTeam?.users;
    data[EVENT_RESPONSETEAM_SELECTEDGROUPS] =
      eventResponseTeamState?.responseTeam?.groups;

    EventManagementHelper.clearEditDeleteFlags(
      data[EVENT_RESPONSETEAM_SELECTEDUSERS]
    );
    EventManagementHelper.clearEditDeleteFlags(
      data[EVENT_RESPONSETEAM_SELECTEDGROUPS]
    );

    return data;
  };

  public static onEditEventResponseTeamSave = async (
    msalInstance: any,
    eventId: string,
    eventResponseTeamState: any,
    airportCode: string
  ) => {
    EventManagementHelper.clearEditDeleteFlags(
      eventResponseTeamState?.responseTeam?.users
    );
    EventManagementHelper.clearEditDeleteFlags(
      eventResponseTeamState?.responseTeam?.groups
    );

    const payload = EventManagementHelper.mapAllTerminalsToArray(
      eventResponseTeamState?.responseTeam
    );

    return EventManagementHelper.updateEventResponseTeam(
      msalInstance,
      eventId,
      payload,
      airportCode
    );
  };

  public static onCrisisKPIsShare = async (
    msalInstance: any,
    eventId: string,
    shareKPIsState: any,
    airportCode: string
  ) => {
    const payload = {
      channels: shareKPIsState?.channels?.map(
        (channel: { key: { channelId: any } }) => channel?.key?.channelId
      ),
      kpis: shareKPIsState?.kpis?.map((k: { key: any }) => k?.key),
      subject: shareKPIsState?.subject,
      message: shareKPIsState?.message,
    };
    return EventManagementHelper.shareCrisisKPIs(
      msalInstance,
      eventId,
      payload,
      airportCode
    );
  };

  public static async crisisDashboardKPIsAPIArray(
    msalInstance: PublicClientApplication,
    eventId: string,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const shareCrisisKPIsMethod = (accessToken: string) => {
      return eventManagementService.crisisDashboardKPIs(
        accessToken,
        eventId,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, shareCrisisKPIsMethod);
  }

  public static async shareCrisisKPIs(
    msalInstance: PublicClientApplication,
    eventId: string,
    payload: any,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const shareCrisisKPIsMethod = (accessToken: string, payload: any) => {
      return eventManagementService.shareCrisisKPIs(
        accessToken,
        eventId,
        payload,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, payload, shareCrisisKPIsMethod);
  }

  public static async setCrisisboardPlanningTableKpi(
    msalInstance: PublicClientApplication,
    airportCode: string,
    resourceType: string,
    date: string,
    payload: any
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const request = (msalInstance: string, payload: any) => {
      return eventManagementService.setCrisisboardPlanningKpi(
        msalInstance,
        airportCode,
        resourceType,
        date,
        payload
      );
    };

    return APIHelper.CallAPI(msalInstance, payload, request);
  }

  public static getAddedGroupDetails = (selectedGroupId: any, data: any) => {
    const groupData = {
      id: selectedGroupId,
      roles: EventManagementHelper.mapSelectedTagsToArray(data.roles ?? []),
      organizations: EventManagementHelper.mapSelectedTagsToArray(
        data.organizations ?? []
      ),
      responsibilities: EventManagementHelper.mapSelectedTagsToArray(
        data.responsibilities ?? []
      ),
      terminals: EventManagementHelper.mapSelectedTagsToArray(
        data.terminals ?? []
      ),
    };

    if (groupData.id === "") {
      delete groupData.id;
    }

    return groupData;
  };

  public static getGroupFormDefaultValues = (
    selectedGroupId: any,
    selectedGroup: any
  ) => {
    return {
      roles: selectedGroupId ? selectedGroup?.roles : [],
      responsibilities: selectedGroupId ? selectedGroup?.responsibilities : [],
      organizations: selectedGroupId ? selectedGroup?.organizations : [],
      terminals: selectedGroupId ? selectedGroup?.terminals : [],
    };
  };

  public static formatEventChannelResponse = (eventChannelsResponse: any) => {
    eventChannelsResponse.forEach((d: any) => {
      if (
        d.channelType.toLowerCase() ===
        EventManagementEnum.EventChannelType.Adhoc
      ) {
        d.isEdit = true;
        d.isDelete = true;
      } else {
        d.isEdit = false;
        d.isDelete = false;
      }
    });

    return eventChannelsResponse;
  };

  public static isUserPartOfResponseTeam = (
    userPrivilege: IEventUserPrivilege
  ) => {
    return userPrivilege.isAOM || userPrivilege.partOfResponseTeam;
  };

  public static isUserPartOfChannelManagementTeam = (
    userPrivilege: IEventUserPrivilege
  ) => {
    return userPrivilege.isAOM || userPrivilege.partOfChannelManagementTeam;
  };

  public static canCloseEvent(
    currentUserEmail: string,
    eventCreatorEmail: string,
    userPrivilege: IEventUserPrivilege
  ) {
    return (
      currentUserEmail.toLowerCase() === eventCreatorEmail.toLowerCase() ||
      userPrivilege.isAOM
    );
  }

  public static disableEventDetailsFormFields() {
    const querySelector =
      "#eventDetailContainer input,  #eventDetailContainer button, #eventDetailContainer textarea, #eventDetailContainer select";
    document.querySelectorAll(querySelector).forEach((ele: any) => {
      ele.disabled = true;
    });

    const buttons = document.getElementsByClassName("ac-pushButton");
    for (const element of Array.from(buttons)) {
      element.classList.add("ac-pushButton-disabled");
    }
  }

  public static configureEventItemTabs(
    eventResponseData: any,
    eventUserPrivilege: IEventUserPrivilege,
    currentUserEmail: string
  ) {
    const eventTabs = EVENTITEM_TABS.data;
    const userRoles = EventManagementHelper.getLoggedInUserRoles(
      eventResponseData?.roleToEmailsMap,
      currentUserEmail
    );

    const taskFields = EventManagementHelper.getTaskFields(
      userRoles,
      eventResponseData?.data,
      true
    );

    const shouldDisableTasksTab =
      taskFields.totalFields === taskFields.disableIdArray.length;
    const shouldDisableResponseTeam =
      !EventManagementHelper.isUserPartOfResponseTeam(eventUserPrivilege);

    eventTabs[1].disabled = shouldDisableTasksTab;
    eventTabs[2].disabled = shouldDisableResponseTeam;
    eventTabs[3].disabled = shouldDisableTasksTab;

    const channelTabs = EVENTITEM_CHANNELTABS.data;
    channelTabs[1].disabled =
      !EventManagementHelper.isUserPartOfChannelManagementTeam(
        eventUserPrivilege
      );

    return {
      eventTabs: eventTabs,
      channelTabs: channelTabs,
    };
  }

  public static getNextStepNumber = (
    currentStepNumber: string,
    workflowJSON: any,
    actionData: any
  ) => {
    let nextStepNumber = "";
    const nextStepJson = workflowJSON?.filter(
      (data: any) => data.name.toLowerCase() === currentStepNumber.toLowerCase()
    );

    if (nextStepJson?.length > 0) {
      // Execute all conditions from next step json for the step.
      // Also, this works for checks against single field (example: field1=="a", ).
      // Does not check for multiple fields (example: field1=="a" && field2=="b")
      if (nextStepJson[0]?.nextStepNumber) {
        nextStepNumber = nextStepJson[0]?.nextStepNumber;
      } else {
        let filteredCondition: any = null;
        const conditions = nextStepJson[0]?.conditions;
        conditions.forEach((condition: any) => {
          const field = condition?.fields[0];
          if (
            actionData[field.fieldName].toLowerCase() ===
            field.fieldValue.toLowerCase()
          ) {
            filteredCondition = condition;
          }
        });

        if (filteredCondition !== null) {
          nextStepNumber = filteredCondition?.nextStepNumber;
        }
      }
    }

    return nextStepNumber;
  };

  public static getWeatherDisruptionDefaultState(airportCode: string) {
    return {
      width: 1200,
      height: 330,
      allowMultipleShapes: false,
      selectedTouchPoint: "",
      selectedTouchPointFilter: "",
      selectedStartTime:
        DateHelper.getCurrentDateTimeForAirport(airportCode).format("HH:mm"),
      // selectedEndTime: PassengerHelper.getUpdatedPAXFlowTimeBasedOnInterval(
      //   DateHelper.getFormattedDateTime(new Date(), "HH:mm"),
      //   PAX_TOUCHPOINTS_REFRESH_INTERVAL[0].value,
      //   true
      // ),
      // selectedRefreshInterval: PAX_TOUCHPOINTS_REFRESH_INTERVAL[0].value,
      weatherDisruptionChartDataArray: [],
      //touchPointTableDataArray: [],
      //touchPointTableColumnsArray: [],
      weatherDisruptionChartLegendArray: [],
    };
  }
  public static getCurrentTime(airport: string) {
    return DateHelper.getNearest30Minutes(airport).format("HH:mm");
  }

  public static generateBeforeTimeList = (airport: string) => {
    const selectedTime = {
      start: EventManagementHelper.getCurrentTime(airport),
      end: "23:30",
    };
    const times = [];
    let current = selectedTime.start;

    while (current !== "00:00") {
      const [hours, minutes] = current.split(":");
      let newMinutes = parseInt(minutes, 10) - 30;
      let newHours = parseInt(hours, 10);

      if (newMinutes < 0) {
        newMinutes += 60;
        newHours -= 1;
      }

      // Format the new time
      current = `${newHours.toString().padStart(2, "0")}:${newMinutes
        .toString()
        .padStart(2, "0")}`;
      if (current !== "00:00") {
        // Start of the day
        times.push(current);
      }
    }

    return times.reverse(); // Reverse the list to start from the earliest time
  };

  public static generateAfterTimeList = (airport: string) => {
    const selectedTime = {
      start: EventManagementHelper.getCurrentTime(airport),
      end: "23:30",
    };
    const times = [];
    let currentTime = selectedTime.start;
    while (currentTime !== "24:00") {
      const [hours, minutes] = currentTime.split(":");
      let newMinutes = parseInt(minutes, 10) + 30;
      let newHours = parseInt(hours, 10);

      if (newMinutes >= 60) {
        newMinutes -= 60;
        newHours += 1;
      }

      // Format the new time
      currentTime = `${newHours.toString().padStart(2, "0")}:${newMinutes
        .toString()
        .padStart(2, "0")}`;

      if (currentTime !== "24:00") {
        // End of the day
        times.push(currentTime);
      }
    }

    return times;
  };
  public static async getWeatherDisruptionGraphData(
    msalInstance: PublicClientApplication,
    airportCode: string
  ) {
    const graphDataPromiseArray: Promise<any>[] = [];

    WEATHER_DISRUPTION_ARRAY.forEach((disruptionName: string) => {
      graphDataPromiseArray.push(
        EventManagementHelper.getDisruptionGraphData(
          msalInstance,
          disruptionName,
          airportCode
        )
      );
    });

    const graphDataResponse = await Promise.all(graphDataPromiseArray);
    return [graphDataResponse];
  }

  public static async getDisruptionGraphData(
    msalInstance: PublicClientApplication,
    disruptionName: string,
    airportCode: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const disruptionGraphDataMethod = (accessToken: string) => {
      return eventManagementService.getDisruptionGraphData(
        accessToken,
        disruptionName,
        airportCode
      );
    };

    return APIHelper.CallAPI(msalInstance, null, disruptionGraphDataMethod);
  }
  public static async getDisruptionPlanningKpi(
    msalInstance: PublicClientApplication,
    airportCode: string,
    resourceType: string,
    date: string
  ): Promise<any> {
    const eventManagementService = new EventManagementService();

    const disruptionGraphDataMethod = (msalInstance: string) => {
      return eventManagementService.getEventPlanningKpi(
        msalInstance,
        airportCode,
        resourceType,
        date
      );
    };

    return APIHelper.CallAPI(msalInstance, null, disruptionGraphDataMethod);
  }

  public static getDisruptionLineChartPoints(data: any) {
    const lineChartPoints: ILineChartPoints = {
      legend: data.datasetName,
      color: data.color,
      data: EventManagementHelper.getDisruptionLineChartPointsData(data.points),
    };

    if (data.showDottedLine) {
      lineChartPoints.lineOptions = {
        strokeDasharray: "5",
        strokeLinecap: "butt",
        strokeWidth: "2",
        lineBorderWidth: "4",
      };
    }
    return lineChartPoints;
  }

  public static getDisruptionLineChartPointsData(dataPoints: any) {
    const lineChartPointsData: ILineChartDataPoint[] = [];
    dataPoints.forEach((dataPoint: any) => {
      lineChartPointsData.push({
        x: new Date(dataPoint.x),
        y: dataPoint.y,
      });
    });
    return lineChartPointsData;
  }

  public static getDisruptionChartData(chartTitle: string, graphData: any) {
    const chartData: IChartProps = {
      chartTitle: chartTitle,
    };
    const lineChartPoints: ILineChartPoints[] = [];
    graphData.forEach((data: any) => {
      lineChartPoints.push(
        EventManagementHelper.getDisruptionLineChartPoints(data)
      );
    });
    chartData.lineChartData = lineChartPoints;
    return chartData;
  }
  public static getDisruptionGraphLegends = (graphData: any) => {
    const legends: ILegend[] = [];
    graphData?.lineChartData?.forEach((data: any) => {
      legends.push({
        title: data.legend,
        color: data.color,
        shape: data.showDottedLine ? "dottedLine" : "default",
      });
    });
    return legends;
  };

  public static getUpdatedStateBasedOnGraphData(
    disruptionGraphTableResponse: any,
    stateObj: any
  ) {
    const graphData = disruptionGraphTableResponse[0];

    const weatherDisruptionChartDataArray: any[] = [];
    const weatherDisruptionChartLegendArray: any[] = [];

    graphData.forEach((disruptionChartData: any) => {
      const chartData = EventManagementHelper.getDisruptionChartData(
        disruptionChartData.chartTitle,
        disruptionChartData.data
      );
      weatherDisruptionChartDataArray.push(chartData);
      weatherDisruptionChartLegendArray.push(
        EventManagementHelper.getDisruptionGraphLegends(chartData)
      );
    });

    stateObj.weatherDisruptionChartDataArray = weatherDisruptionChartDataArray;
    stateObj.weatherDisruptionChartLegendArray =
      weatherDisruptionChartLegendArray;

    return stateObj;
  }

  public static async getKpi(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const evantManagementService = new EventManagementService();
    const getKpiMethod = (accessToken: string, _airportCode: string) => {
      return evantManagementService.getKpis(accessToken);
    };

    return APIHelper.CallAPI(msalInstance, null, getKpiMethod);
  }

  private static readonly getEventDetailsPayload = (
    formData: any,
    currentUserEmail: string,
    operations: string[]
  ) => {
    return operations.includes("details")
      ? {
          name: formData.eventName,
          description: formData.eventDescription,
          type: formData.typeOfEvent.text,
          severity: formData.incidentSeverity.text,
          callerName: formData.callerName,
          callerNumber: formData.callerNumber,
          reportedDate: formData.reportedDate,
          reportedTime: formData.reportedTime,
          loggedDate: formData.loggedDate,
          loggedTime: formData.loggedTime,
          creatorEmail: currentUserEmail,
        }
      : null;
  };

  private static readonly getEventResponseTeamPayload = (
    formData: any,
    operations: string[]
  ) => {
    let payload = operations.includes("response_team")
      ? {
          users: formData[EVENT_RESPONSETEAM_SELECTEDUSERS] ?? [],
          groups: formData[EVENT_RESPONSETEAM_SELECTEDGROUPS] ?? [],
        }
      : null;
    payload = EventManagementHelper.mapAllTerminalsToArray(payload);

    return payload;
  };

  private static readonly manageResponseTeamUser = (
    action: string,
    data: any,
    eventResponseTeamState: EventResponseTeamState | undefined
  ) => {
    let responseTeamObj: any = {};
    if (action === EventManagementEnum.ResponseTeamResourceAction.Add) {
      responseTeamObj = EventManagementHelper.getResponseTeamAddedUserObj(
        data,
        eventResponseTeamState
      );
    }

    if (action === EventManagementEnum.ResponseTeamResourceAction.Delete) {
      responseTeamObj = EventManagementHelper.getResponseTeamDeletedUserObj(
        data,
        eventResponseTeamState
      );
    }

    return responseTeamObj;
  };

  private static readonly manageResponseTeamGroup = (
    action: string,
    data: any,
    eventResponseTeamState: EventResponseTeamState | undefined
  ) => {
    let responseTeamObj: any = {};
    if (action === EventManagementEnum.ResponseTeamResourceAction.Add) {
      responseTeamObj = EventManagementHelper.getResponseTeamAddedGroupObj(
        data,
        eventResponseTeamState
      );
    }

    if (action === EventManagementEnum.ResponseTeamResourceAction.Delete) {
      responseTeamObj = EventManagementHelper.getResponseTeamDeletedGroupObj(
        data,
        eventResponseTeamState
      );
    }

    return responseTeamObj;
  };

  private static readonly formatCallerDetailsDateTime = (
    adaptiveCardData: any
  ) => {
    adaptiveCardData.details.caller_details.date =
      DateHelper.getFormattedDateTime(
        new Date(adaptiveCardData.details.caller_details.date),
        "YYYY-MM-DD"
      );

    const callerDetailsTime = adaptiveCardData.details.caller_details.time;
    adaptiveCardData.details.caller_details.time = DateHelper.isValidDate(
      callerDetailsTime
    )
      ? DateHelper.getFormattedDateTime(new Date(callerDetailsTime), "HH:mm")
      : callerDetailsTime;

    return adaptiveCardData;
  };
}
