import { mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { NotificationHelper } from "../../../utils/notification/NotificationHelper";
import { AmdToastItem } from "./ToastItem";

interface AmdToastAlertsProps {
  alerts: any[];
  onSelectNotification?: any;
}

const alertWidth = "calc(100% - 80px)";
const alertTransition = "top 0.5s ease-out, width 0.5s ease-out";

export const AmdToastAlerts: React.FunctionComponent<AmdToastAlertsProps> =
  React.memo(({
    alerts = [],
    ...props
  }) => {
    const [expanded, setExpanded] = React.useState(false);
    const [alertCount, setAlertCount] = React.useState(alerts.length);

    React.useEffect(() => {
      setAlertCount(alerts.length);
    }, [alerts]);

    const handleAlertClick = (
      index: number,
      item: any,
      isRedirect: boolean
    ) => {
      props.onSelectNotification(index, item, isRedirect);
    };
    const toastStyles = getToastStyles(alertCount);

    const handleExpansion = (e: any) => {
      if (e.target?.id?.includes("toast_alert")) {
        setExpanded(!expanded);
      }
    };

    const renderedAlerts: any[] = [];
    let positionCounter = NotificationHelper.getPositionCounter(alertCount);
    alerts.forEach((alertItem: any, index: number) => {
      if (index === alertCount - 1) {
        renderedAlerts.push(
          <AmdToastItem
            key={CommonHelper.getGuid()}
            handleAlertClick={handleAlertClick}
            index={index}
            handleExpansion={handleExpansion}
            alertItem={alertItem}
            isFirstItem={alerts.length === 1}
            expanded={expanded}
            className={`${toastStyles.alert} top`}
            topValue={"0px"}
          />
        );
        positionCounter -= 47;
      } else if (index === alertCount - 2) {
        renderedAlerts.push(
          <AmdToastItem
            key={CommonHelper.getGuid()}
            handleAlertClick={handleAlertClick}
            index={index}
            handleExpansion={handleExpansion}
            alertItem={alertItem}
            isFirstItem={false}
            expanded={expanded}
            className={`${
              toastStyles.alert
            } behind1 ${NotificationHelper.getExpansionClass(expanded, true)}`}
            topValue={NotificationHelper.getTopValue(
              expanded,
              positionCounter,
              6
            )}
          />
        );
        positionCounter -= 47;
      } else if (index === alertCount - 3) {
        renderedAlerts.push(
          <AmdToastItem
            key={CommonHelper.getGuid()}
            handleAlertClick={handleAlertClick}
            index={index}
            handleExpansion={handleExpansion}
            alertItem={alertItem}
            isFirstItem={false}
            expanded={expanded}
            className={`${
              toastStyles.alert
            } behind2 ${NotificationHelper.getExpansionClass(expanded, true)}`}
            topValue={NotificationHelper.getTopValue(
              expanded,
              positionCounter,
              12
            )}
          />
        );
        positionCounter -= 47;
      } else {
        renderedAlerts.push(
          <AmdToastItem
            key={CommonHelper.getGuid()}
            handleAlertClick={handleAlertClick}
            index={index}
            handleExpansion={handleExpansion}
            alertItem={alertItem}
            isFirstItem={false}
            expanded={expanded}
            className={`${
              toastStyles.alert
            } ${NotificationHelper.getHiddenClass(
              expanded
            )} ${NotificationHelper.getExpansionClass(expanded, true)}`}
            topValue={NotificationHelper.getTopValue(
              expanded,
              positionCounter,
              0
            )}
          />
        );
        positionCounter -= 47;
      }
    });
    return alerts?.length > 0 ? (
      <div className={toastStyles.container}>
        <div className={toastStyles.alertsContainer}>{renderedAlerts}</div>
      </div>
    ) : (
      <></>
    );
  });


const getToastStyles = (alertCount: number) => {
  return mergeStyleSets({
    container: {
      width: "100%",
      minHeight: NotificationHelper.getAlertHeight(alertCount),
      height: "auto",
      zIndex: 1,
    },
    alertsContainer: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    alert: {
      height: 20,
      padding: "12px 40px",
      color: "#C4314B",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 20,
      background: "white",
      border: "1px solid #F3D6D8",
      width: alertWidth,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: 2,
      selectors: {
        "&.top": {
          position: "absolute",
          top: 0,
        },
        "&.behind1": {
          position: "absolute",
          top: 6,
          width: "calc(100% - 88px)",
          transition: alertTransition,
        },
        "&.behind2": {
          position: "absolute",
          top: 12,
          width: "calc(100% - 96px)",
          transition: alertTransition,
        },
        "&.expanded": {
          position: "absolute",
          width: alertWidth,
          transition: alertTransition,
        },
        "&.hidden": {
          opacity: 0,
          top: 0,
          position: "absolute",
          transition: "opacity 0.2s linear, top 0.5s ease-out",
        },
        "&.expanded_last": {
          opacity: 1,
          position: "absolute",
          width: alertWidth,
          transition: "opacity 0.2s linear, top 0.5s ease-out",
        },
      },
    },
  });
};
