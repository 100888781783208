function matchedTouchPoint(
  touchPointChartArr: any[],
  selectedTouchPoint: string
  ) {
  return touchPointChartArr?.filter((touchPoint) => {
    return touchPoint?.chartTitle === selectedTouchPoint;
  });
}

export default matchedTouchPoint;
