import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  mergeStyleSets,
  ScrollablePane,
  ScrollbarVisibility,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import { CounterBadge, Image } from "@fluentui/react-components";
import * as React from "react";
import { ResourceType } from "../../../enum/ResourceEnum";
import {
  CustomListHelper,
  IColumnExtended,
} from "../../../utils/common/CustomListHelper";
import ArrivalIcon from "../../common/images/arrival.svg";
import DepartureIcon from "../../common/images/departure.svg";
import { InfoModalPopup } from "../info-modal-popup/InfoModalPopup";
import getSortField from "./getSortField";
import { PaxFlowLegendIcons } from "./PaxFlowLegendIcons";
import { sortAZText, sortZAText } from "./utils/constants";
import { customListStyles } from "./utils/customListStyles";
import { getDelete } from "./utils/getDelete";
import { getEdit } from "./utils/getEdit";
import { getSelectedColumn } from "./utils/getSelectedColumn";
import { hasData } from "./utils/hasData";
import { isEventManagementPage } from "./utils/isEventManagementPage";
import { onRenderRow } from "./utils/onRenderRow";
import { onSelect } from "./utils/onSelect";
import { paxListStyles } from "./utils/paxListStyles";
import { renderFixedHeader } from "./utils/renderFixedHeader";
import { circleStyle } from "./utils/styles";
import { IAmdDataGridProps } from "./utils/types";

const classNames = mergeStyleSets({
  root: {
    position: "relative",
    minHeight: "400px",
    maxHeight: "100%",
  },
  scrollablePane: {
    height: "100%",
    overflowX: "hidden",
  },
});

export function AmdDataGrid(props: IAmdDataGridProps) {
  const {
    columns: columnsProp,
    rowItems: rowItemsProp,
    isHeaderShowHide: isPaxTable,
    showHeader,
    infoHeaders,
    showActionButtons,
    onEditItem,
    onDeleteItem,
    onSelectEvent,
  } = props;
  const [state, setState] = React.useState({
    items: rowItemsProp,
    columns: columnsProp,
    isCompactMode: false,
    eventId: "",
    hideModalDialog: true,
    modalTitle: "",
    infoReasons: [],
  });

  const [selection] = React.useState(
    new Selection({
      onSelectionChanged: () => selectionChangedHandler(),
    })
  );

  const selectionChangedHandler = () => {
    const currentSelectedKeys = selection.getSelection().map(({ key }) => key);
    const selectedKey = currentSelectedKeys[0] as string;
    setState((prevState) => ({ ...prevState, eventId: selectedKey }));
  };

  function handleColumnClick(
    _ev: React.MouseEvent<HTMLElement>,
    column: IColumnExtended
  ): void {
    const { columns, items } = state;
    const updateStateObj = CustomListHelper.getUpdatedStateObj({
      selectedColumn: column,
      columns,
      items,
    });
    setState((prevState) => ({ ...prevState, ...updateStateObj }));
  }
  const columnsMemo = React.useMemo(
    () => CustomListHelper.getColumns(columnsProp, sortAZText, sortZAText),
    [columnsProp, sortAZText, sortZAText]
  );

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      items: rowItemsProp,
      columns: columnsMemo,
      isCompactMode: false,
      eventId: "",
    }));
  }, [columnsMemo, rowItemsProp]);

  function sortColumns(
    selectedColumn: IColumnExtended,
    sortOveride: boolean
  ): void {
    if (!selectedColumn) {
      return;
    }

    const updateStateObj = CustomListHelper.getUpdatedDefaultState({
      selectedColumn,
      columns: state.columns,
      items: state.items,
      sortOveride,
    });
    setState((prevState) => ({ ...prevState, ...updateStateObj }));
  }

  React.useEffect(() => {
    window.dispatchEvent(new Event("resize"));
    const { columns = [] } = state;
    const { selectedType, viewType = ResourceType.Default } = props;
    const { sortField, sortOveride } = getSortField(viewType, selectedType);
    const result = hasData(columns);
    if (columns?.length && result && sortField) {
      const selectedColumn = getSelectedColumn(columns, sortField);
      if (!selectedColumn?.length) {
        return;
      }
      sortColumns(selectedColumn[0], sortOveride);
    }
  }, []);

  function _onModalPopupClose() {
    setState((prevState) => ({
      ...prevState,
      hideModalDialog: true,
      infoReasons: [],
    }));
  }

  const formatInfoReasonClickHandle = (reason: any[], title: string) => {
    const response = CustomListHelper.getInfoReasons(reason, title);
    setState((prevState) => ({
      ...prevState,
      ...response,
    }));
  };

  function formatInfoReason(
    _infoReasons: any[],
    key: string,
    modalTitle: string
  ) {
    if (_infoReasons?.length === 1 && modalTitle !== "Delay Reasons") {
      return (_infoReasons[0] as { [k in string]: any })[key];
    } else if (_infoReasons?.length >= 1) {
      return (
        <div data-testid="info-icon">
          {(_infoReasons[0] as { [k in string]: any })[key]}
          <span
            onClick={() =>
              formatInfoReasonClickHandle(_infoReasons, modalTitle)
            }
            style={circleStyle}
          >
            <CounterBadge count={_infoReasons.length} />
          </span>
        </div>
      );
    }
    return "-";
  }

  function renderItemColumn(item: any, _index: any, column: any) {
    const { isHeaderShowHide } = props;
    const isPaxTable = isHeaderShowHide ?? false;
    const columnName = column?.name?.toLowerCase();
    const fieldContent = isPaxTable ? item[columnName] : item[column.fieldName];
    const { fieldName } = column;

    switch (fieldName) {
      case "timeSeries":
        return (
          <PaxFlowLegendIcons
            isPaxTable={isPaxTable}
            fieldContent={fieldContent}
          />
        );
      case "sobt":
      case "eobt":
      case "aobt":
      case "sibt":
      case "eibt":
      case "aibt":
      case "schedule":
      case "estimated":
      case "actual":
      case "resourceAllocationStart":
      case "resourceAllocationEnd":
        return CustomListHelper.formatDateTime(fieldContent);
      case "delayDuration":
        return CustomListHelper.formatDelayDuration(fieldContent);
      case "role":
        return formatInfoReason(fieldContent, "name", "Role");
      case "responsibleArea":
        return formatInfoReason(fieldContent, "name", "Responsible Area");
      case "userTerminal":
        return formatInfoReason(fieldContent, "name", "Terminal");
      case "delayReasons":
        return formatInfoReason(fieldContent, "code", "Delay Reasons");
      case "flightType":
        return item["destination"] ? (
          <Image src={DepartureIcon}></Image>
        ) : (
          <Image src={ArrivalIcon}></Image>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  }

  const { eventId, items, columns, hideModalDialog, modalTitle, infoReasons } =
    state;
  const className = `${isPaxTable ? "paxTouchPoints" : ""}`;

  Object.values(rowItemsProp)?.forEach((item) => {
    const showEditButton = showActionButtons && item.isEdit !== false;
    const showDeleteButton = showActionButtons && item.isDelete !== false;
    if (showEditButton) {
      item.edit = getEdit(item, onEditItem);
    }
    if (showDeleteButton) {
      item.delete = getDelete(item, onDeleteItem);
    }
  });

  let selectionMode = SelectionMode.none;
  const isEventManagement = isEventManagementPage(location);

  if (isEventManagement) {
    selectionMode = SelectionMode.single;
  }

  const onSelectHandle = () =>
    isEventManagement && onSelect(eventId, onSelectEvent);

  if (!isPaxTable) {
    columns.forEach((c) => (c.onColumnClick = handleColumnClick));
  }

  const detailsListTable = (
    <DetailsList
      className={className}
      items={items}
      columns={columns}
      selectionMode={selectionMode}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      isHeaderVisible={showHeader ?? true}
      styles={isPaxTable ? paxListStyles(props) : customListStyles()}
      onRenderRow={onRenderRow}
      onRenderItemColumn={renderItemColumn}
      constrainMode={ConstrainMode.unconstrained}
      onRenderDetailsHeader={renderFixedHeader}
      onActiveItemChanged={onSelectHandle}
      selection={selection}
    />
  );

  if (isPaxTable) {
    return detailsListTable;
  }

  return (
    <div className={classNames.root}>
      <ScrollablePane
        scrollbarVisibility={ScrollbarVisibility.auto}
        className={classNames.scrollablePane}
      >
        <div data-testid="customListControl">{detailsListTable}</div>
        <InfoModalPopup
          infoReasons={infoReasons}
          hideDialog={hideModalDialog}
          onModalPopupClose={_onModalPopupClose}
          headers={infoHeaders ?? []}
          modalTitle={modalTitle}
        ></InfoModalPopup>
      </ScrollablePane>
    </div>
  );
}
