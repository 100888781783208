import { mergeStyleSets } from "@fluentui/react";
import { AmdButtonType } from "./AmdButtonType";

export const getButtonStyles = (type: string = AmdButtonType.DEFAULT) => {
  if (type === AmdButtonType.CHIP) {
    return mergeStyleSets({
      root: {
        background: "rgba(155, 202, 235, 0.25)",
        padding: 8,
        height: 32,
        border: "none",
        borderRadius: 0,
        color: "#005EB8",
        cursor: "pointer",
        fontWeight: 600,
      },
    });
  } else if (type === AmdButtonType.PILL) {
    return mergeStyleSets({
      root: {
        background: "#FFF",
        padding: "10px 20px !important",
        borderRadius: "20px !important",
        height: 40,
        border: "1px solid #ccc !important",
        minWidth: "fit-content !important",
      },
    });
  } else if (type === AmdButtonType.DASHED) {
    return mergeStyleSets({
      root: {
        height: 88,
        width: 280,
        border: "1px dashed #005EB8 !important",
        textAlign: "center",
        color: "#005EB8",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.14)",
        selectors: {
          "&:hover": {
            color: "#005EB8",
          },
        },
      },
    });
  } else if (type === AmdButtonType.PRIMARY) {
    return mergeStyleSets({
      root: {
        height: 40,
        border: "1px #005EB8 !important",
        textAlign: "center",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.14)",
      },
    });
  }

  return mergeStyleSets({
    root: {
      background: "#fff",
      padding: "12px 10px !important",
      height: 44,
      borderRadius: "5px !important",
      border: "1px solid #ccc !important",
      minWidth: "fit-content !important",
      fontSize: 14,
      color: "#4d4d4d",
    },
  });
};
