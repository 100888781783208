import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdBaggageDisruption: React.FunctionComponent<IAmdIconProps> = (
  props: IAmdIconProps
) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill="none"
      data-testid="baggageDisruption-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8125 28C33.875 28.3125 34 28.6875 34 29C34 30.6875 32.625 32 31 32C29.3125 32 28 30.6875 28 29C28 28.6875 28.0625 28.3125 28.125 28H15.8125C15.875 28.3125 16 28.6875 16 29C16 30.6875 14.625 32 13 32C11.3125 32 10 30.6875 10 29C10 28.6875 10.0625 28.3125 10.125 28H6C4.875 28 4 27.125 4 26V5C4 4.5 3.5 4 3 4H2C0.875 4 0 3.125 0 2C0 0.9375 0.875 0 2 0H4C6.1875 0 8 1.8125 8 4V24H38C39.0625 24 40 24.9375 40 26C40 27.125 39.0625 28 38 28H33.8125ZM27 0C28.625 0 30 1.375 30 3V20H18V3C18 1.375 19.3125 0 21 0H27ZM21 6H27V3H21V6ZM16 20H14C12.875 20 12 19.125 12 18V8C12 6.9375 12.875 6 14 6H16V20ZM36 8V18C36 19.125 35.0625 20 34 20H32V6H34C35.0625 6 36 6.9375 36 8Z"
        fill={props.color}
      />
    </svg>
  );
};
