import * as React from "react";
import { getPillStyles } from "./pillStyles";

const pillStyles = getPillStyles();

export interface IAmdPillProps {
  children?: React.ReactElement;
  label: string;
  className?: string;
  key?: string;
  isPillOnly?: boolean;
}

export const AmdPill: React.FunctionComponent<IAmdPillProps> = (
  props: IAmdPillProps
) => {
  return (
    <span
      key={props.key}
      className={`${pillStyles.pillWrapper} ${props.className}`}
    >
      <span
        className={
          props.isPillOnly ? pillStyles.pillOnlyLabel : pillStyles.pillLabel
        }
      >
        {props.label}
      </span>
      {props.children && <>{props.children}</>}
    </span>
  );
};
