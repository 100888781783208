import { mergeStyleSets } from "@fluentui/react";
import { UpdatePopUpEnum } from "../../../enum/EventEnum";
import { AmdTextField } from "../../common/form-controls/uncontrolled/TextField/AmdTextField";
import { AmdClock } from "../../common/icons/clock/Clock";
import { CurrentRecordsType } from "./types";

export const CurrentRecord: React.FunctionComponent<CurrentRecordsType> = ({
  record,
  onChangeEvent,
}: CurrentRecordsType): React.JSX.Element => {

const onActualChange = (event: any) => {
 onChangeEvent(event, currentTime, UpdatePopUpEnum.Actual)
}
const onDemandChange = (event: any) => {
 onChangeEvent(event, currentTime, UpdatePopUpEnum.Demand)
}
const onCapacityChange = (event: any) => {
 onChangeEvent(event, currentTime, UpdatePopUpEnum.Capacity)
}

const currentTime = record?.time;
  return (
    <div
      className="amd-container-vcenter"
      key={`currentRecordkey-${currentTime}`}
    >
      <div className="amd-container-vcenter ms-Grid-col ms-md5 fw-600">
        <AmdClock className="mt-1" />
        {currentTime}
        {" (Now)"}
      </div>
      <span className="amd-container-hspacebtwn amd-container-vcenter mt-1 mb-1">
        <div className="ms-Grid-col ms-md4">
          <AmdTextField
            id="capacity"
            data-testid="capacity"
            label=""
            isMandatory={false}
            isMultiline={false}
            className={`${contentStyles.inputbox}`}
            defaultValue={record?.capacity?.toString()}
            onChange={onCapacityChange}
          />
        </div>
        <div className="ms-Grid-col ms-md4">
          <AmdTextField
            id="demand"
            label=""
            isMandatory={false}
            isMultiline={false}
            className={`${contentStyles.inputbox}`}
            defaultValue={record?.demand?.toString()}
            onChange={onDemandChange}
          />
        </div>
        <div className="ms-Grid-col ms-md4">
          <AmdTextField
            id="actual"
            label=""
            isMandatory={false}
            isMultiline={false}
            className={`${contentStyles.inputbox}`}
            defaultValue={record?.actual?.toString()}
            onChange={onActualChange}
          />
        </div>
      </span>
    </div>
  );
};
const contentStyles = mergeStyleSets({
  inputbox: {
    //width: "130px",
    background: "white",
    "div.ms-TextField-fieldGroup": {
      height: "30px !important",
      border: "1px solid rgb(204, 204, 204) !important",
    },
  },
});