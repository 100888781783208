import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdEye: React.FunctionComponent<IAmdIconProps> = ({
  height = "10",
  width = "16",
  viewBox = "0 0 16 10",
  color = "#9BCAEB",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25909 6.60214C1.94254 3.32689 4.79437 1 8.00002 1C11.2057 1 14.0574 3.32688 14.7409 6.60215C14.7974 6.87246 15.0622 7.04587 15.3325 6.98946C15.6029 6.93304 15.7763 6.66817 15.7199 6.39785C14.9425 2.67312 11.6934 0 8.00002 0C4.3066 0 1.05742 2.67311 0.280175 6.39786C0.223767 6.66818 0.397177 6.93305 0.667497 6.98946C0.937817 7.04587 1.20268 6.87246 1.25909 6.60214ZM7.98953 3C9.92253 3 11.4895 4.567 11.4895 6.5C11.4895 8.43299 9.92253 10 7.98953 10C6.05653 10 4.48953 8.43299 4.48953 6.5C4.48953 4.567 6.05653 3 7.98953 3Z"
        fill={color}
      />
    </svg>
  );
};
