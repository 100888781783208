import moment, { Moment } from "moment-timezone";
import { DateTypeEnum } from "../../enum/DateEnum";
import IAirportConfig from "../../models/airports/AirportConfig";
import { getAirportConfig } from "./CommonHelperFunctions";

const format = "YYYY-MM-DDTHH:mm";

function getTimeZone(
  airportCode: string,
  type: DateTypeEnum,
  start: boolean,
  selectedDate?: Date
) {
  const config: IAirportConfig = getAirportConfig(airportCode);
  const { timeZone } = config;
  const calendarTimeZone = moment.tz.guess();
  switch (type) {
    case DateTypeEnum.Today:
      if (start) {
        return moment.tz(timeZone).startOf("day");
      }
      return moment.tz(timeZone).endOf("day");
    case DateTypeEnum.Now:
      if (start) {
        return moment.tz(timeZone).subtract(moment.duration(3, "hours"));
      }
      return moment.tz(timeZone).add(moment.duration(7, "hours"));
    case DateTypeEnum.Tomorrow:
      if (start) {
        return moment.tz(timeZone).startOf("day").add(1, "day");
      }
      return moment.tz(timeZone).endOf("day").add(1, "day");
    case DateTypeEnum.Calendar:
      if (start) {
        return moment.tz(selectedDate, calendarTimeZone).startOf("day");
      }
      return moment.tz(selectedDate, calendarTimeZone).endOf("day");
    default:
      return moment.tz(timeZone);
  }
}

export function getCurrentDateStartTimeStrForAirport(
  airportCode: string,
  type: DateTypeEnum,
  dateFormat: string = "",
  start: boolean,
  selectedDate?: Date
): string {
  return getTimeZone(airportCode, type, start, selectedDate).format(
    dateFormat ? dateFormat : format
  );
}

/** Get the current GMT offset */
export const getGMTOffset = (date: Date) => {
  const dateString = date.toString();
  const gmtMatch = dateString.match(/GMT[+-]\d{4}/);
  return gmtMatch ? gmtMatch[0] : "GMT offset not found";
};

/** Parse the GMT offset from a string and return the total minutes */
export function parseGmtOffset(offset: string): number {
  const sign = offset[3] === "+" ? 1 : -1;
  const hours = parseInt(offset.slice(4, 6), 10);
  const minutes = parseInt(offset.slice(6, 8), 10);
  return sign * (hours * 60 + minutes);
}

/**
 * Get the time difference between two GMT offsets
 * @param {string} offset1 - Time zone Offset1
 * @param {string} offset2 - Time zone Offset2
 * @returns { hours: number; minutes: number; sign: string } Time zone difference in hours and minutes
 */
export function getTimeDifference(
  offset1: string,
  offset2: string
): { hours: number; minutes: number; sign: string } {
  const difference = parseGmtOffset(offset2) - parseGmtOffset(offset1);
  const hours = Math.trunc(difference / 60);
  const minutes = Math.trunc(difference % 60);
  const sign = difference >= 0 ? "+" : "-";
  return { hours, minutes, sign };
}

export function getCurrentDateTimeForAirport(airportCode: string): Moment {
  const config: IAirportConfig = getAirportConfig(airportCode);
  if (config.hardCodedDates && config.defaultStart) {
    return moment.tz(config.defaultStart, config.timeZone);
  } else {
    return moment.tz(config.timeZone);
  }
}

export function getTime(airport: string, formatted: string): string {
  const date = getCurrentDateTimeForAirport(airport);
  return date.format(formatted);
}
