import { useMsal } from "@azure/msal-react";
import { DefaultPalette, Stack } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { useState } from "react";

import { CommonHelper } from "../../../utils/common/CommonHelper";
import { homeContainer } from "../AppStyles";
import { loginRequest } from "../auth/authConfig";

export const SignIn: React.FunctionComponent = () => {
  const { instance } = useMsal();
  const [showSignInButton, setShowSignInButton] = useState<boolean>(
    instance.getAllAccounts().length === 0 ? true : false
  );
  const handleLogin = () => {
    instance.handleRedirectPromise().catch((_e) => {
      instance.acquireTokenSilent(loginRequest).catch((_error) => {
        console.error(`ERR! Exception in SignIn module. StackTrace: ${_error}`);
        setShowSignInButton(true);
        CommonHelper.redirectToHome();
      });
    });

    instance.loginRedirect(loginRequest);
  };

  return (
    <Stack enableScopedSelectors className={homeContainer}>
      <Button
        appearance="primary"
        hidden={!showSignInButton}
        style={loginButtonStyle}
        onClick={() => handleLogin()}
        className="signinButton"
      >
        Sign in to APOC Portal
      </Button>
    </Stack>
  );
};

const loginButtonStyle = {
  width: "200px",
  marginTop: "20px",
  height: "35px",
  background: DefaultPalette.themePrimary,
  color: "#FFFFFF",
  fontFamily:
    "'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
  fontSize: "15px",
  fontWeight: "500",
  border: "none",
  cursor: "pointer",
};
