import React from "react";

export const AmdAmber: React.FunctionComponent<{ fill?: string }> = ({
  fill: fillColor,
}) => {
  const fill = fillColor ? "currentColor" : "#F7A827";

  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      data-testid="amber-alert-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M8.44291 5H9.55229C9.65854 5 9.74291 5.0875 9.73979 5.19375L9.50541 11.3188C9.50229 11.4188 9.41791 11.5 9.31791 11.5H8.67729C8.57728 11.5 8.49291 11.4219 8.48979 11.3188L8.25541 5.19375C8.25229 5.0875 8.33666 5 8.44291 5ZM8.99916 12.125C8.51478 12.125 8.12416 12.5156 8.12416 13C8.12416 13.4844 8.51478 13.875 8.99916 13.875C9.48353 13.875 9.87416 13.4844 9.87416 13C9.87416 12.5156 9.48353 12.125 8.99916 12.125ZM17.796 13.75L10.2992 0.75C9.72416 -0.25 8.27728 -0.25 7.69916 0.75L0.202284 13.75C-0.372716 14.7469 0.346034 16 1.50228 16H16.4992C17.6492 16 18.3742 14.75 17.796 13.75ZM16.4992 15H1.49916C1.11478 15 0.874159 14.5844 1.06478 14.25L8.56479 1.25C8.75541 0.91875 9.23978 0.915625 9.43041 1.25L16.9304 14.25C17.1242 14.5813 16.8835 15 16.4992 15Z"
        fill={fill}
      />
    </svg>
  );
};
