import { makeStyles } from "@fluentui/react-components";
import { Marker, MarkerProps, Tooltip } from "react-leaflet";
import { airportIcon } from "./marker-icons/airportIcon";

export interface IAirportMarkersProps {
  markerName: string;
  markerPosition: MarkerProps["position"];
}

const AirportMarkers: React.FC<IAirportMarkersProps> = ({
  markerName,
  markerPosition,
}: IAirportMarkersProps) => {
  const classes = useStyles();
  return (
    <Marker position={markerPosition} icon={airportIcon}>
      <Tooltip direction="top" opacity={1} className={classes.tooltip}>
        {markerName}
      </Tooltip>
    </Marker>
  );
};

export default AirportMarkers;

const useStyles = makeStyles({
  tooltip: {
    fontWeight: 600,
    fontSize: "12px",
  },
});
