import { makeStyles } from "@fluentui/react-components";
import {
  AirplaneLandingFilled,
  AirplaneTakeOffFilled,
} from "@fluentui/react-icons";
import React from "react";
import { $sky60 } from "../../../../../cssjs/colors";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

export const DonutTitleSection: React.FunctionComponent =
  (): React.JSX.Element => {
    const isTabletPortrait = useMediaQuery("(max-width: 1024px)");
    const isTablet = useMediaQuery("(max-width: 1366px)");
    const landscapeTablet = isTablet && !isTabletPortrait;
    const classes = useStyles();
    return (
      <div className={`${landscapeTablet ? "fs-12" : "fs-14"} ${classes.container}`}>
        <div className={`${classes.title}`}>
          <span className="fs-20">
            <AirplaneLandingFilled />
          </span>
          <span>Arrival</span>
        </div>
        <div className={`${classes.title}`}>
          <span className={`fs-20 pl-2`}>
            <AirplaneTakeOffFilled />
          </span>
          Departure
        </div>
      </div>
    );
  };

const useStyles = makeStyles({
  container: {
    display: "grid",
    color: $sky60,
    gridAutoFlow: "column",
    justifyContent: "space-around",
  },
  title: {
    display: "flex",
    // justifyContent: "center"
  },
  cardArrivalDepartureSection: {
    color: $sky60,
    marginBottom: "-14px",
    paddingBottom: "4px",
  },
  cardArrivalSection: {
    marginLeft: "-20px",
  },
  cardArrivalIcon: {
    "&.ms-Grid-col": {
      width: "auto",
    },
  },
  cardDepartureIcon: {
    "&.ms-Grid-col": {
      width: "auto",
    },
  },
  cardArrivalText: {
    paddingLeft: "4px",
  },
});
