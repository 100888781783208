import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdDew: React.FunctionComponent<IAmdIconProps> = ({
  width = "48",
  height = "48",
  color = "#9BCAEB",
  viewBox = "0 0 48 48",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.1376 4.99775C33.3932 4.73415 33.8076 4.73415 34.0632 4.99775C34.7593 5.71563 36.4259 7.78329 37.9202 10.266C39.3993 12.7234 40.8004 15.7331 40.8004 18.3C40.8004 20.7892 40.1092 22.8254 38.8244 24.245C37.5353 25.6693 35.7143 26.4 33.6004 26.4C31.4865 26.4 29.6655 25.6693 28.3764 24.245C27.0916 22.8254 26.4004 20.7892 26.4004 18.3C26.4004 15.7331 27.8015 12.7234 29.2806 10.266C30.7749 7.78329 32.4414 5.71563 33.1376 4.99775Z"
        fill={color}
      />
      <path
        d="M13.9364 21.7977C14.192 21.5341 14.6064 21.5341 14.8621 21.7977C15.5582 22.5156 17.2247 24.5832 18.719 27.0659C20.1981 29.5234 21.5992 32.533 21.5992 35.1C21.5992 37.5892 20.908 39.6253 19.6232 41.0449C18.3341 42.4692 16.5131 43.2 14.3992 43.2C12.2854 43.2 10.4643 42.4692 9.17526 41.0449C7.89043 39.6253 7.19922 37.5892 7.19922 35.1C7.19922 32.533 8.60034 29.5234 10.0795 27.0659C11.5737 24.5832 13.2403 22.5156 13.9364 21.7977Z"
        fill={color}
      />
    </svg>
  );
};
