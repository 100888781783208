import React from "react";

export const AmdGreen: React.FunctionComponent<{ fill?: string }> = ({
  fill: fillColor,
}) => {
  const fill = fillColor ? "currentColor" : "#008540";
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      data-testid="green-alert-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7725_7256)">
        <path
          d="M8 0C3.58171 0 0 3.58171 0 8C0 12.4183 3.58171 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58171 12.4183 0 8 0ZM8 14.9677C4.17213 14.9677 1.03226 11.8692 1.03226 8C1.03226 4.17216 4.13081 1.03226 8 1.03226C11.8279 1.03226 14.9677 4.13081 14.9677 8C14.9677 11.8278 11.8692 14.9677 8 14.9677ZM12.5687 6.09803L6.74677 11.8733C6.595 12.0238 6.3499 12.0228 6.19935 11.8711L3.4291 9.07842C3.27855 8.92665 3.27952 8.68155 3.43129 8.531L3.70613 8.25839C3.8579 8.10784 4.103 8.10881 4.25355 8.26058L6.47858 10.5036L11.7509 5.27358C11.9026 5.12303 12.1477 5.124 12.2983 5.27577L12.5709 5.55061C12.7215 5.70239 12.7205 5.94748 12.5687 6.09803Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7725_7256">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
