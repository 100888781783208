import { mergeStyleSets } from "@fluentui/react";

import React from "react";

type IProps = {
  resourceTitle: string;
  resourceColor: string;
};

export const DonutLegendSection: React.FunctionComponent<IProps> = (
  props
): React.JSX.Element => {
  const background = props.resourceColor ? props.resourceColor : undefined;
  return (
    <div className={`ms-fontSize-10`}>
      <span style={{ background }} className={`${styles.cardlegendSquare}`} />
      <span>{props.resourceTitle}</span>
    </div>
  );
};

const styles = mergeStyleSets({
  container: {
    display: "grid",
    alignContent: "center"
  },
  cardlegendSquare: {
    width: 5,
    height: 5,
    marginRight: 4,
    display: "inline-block",
    lineHeight: "1.5"
  },
});
