import { Tooltip } from "@fluentui/react-components";
import { useState } from "react";
import {
  IHOPKpi,
  IHOPSummaryItem,
} from "../../models/health-of-operations/IHOPSummary";
import { HOPHelper } from "../../utils/health-of-operations/HOPHelper";
import { HOPAlertIcon } from "./HOPAlertIcon";
import { HOPOTPContent } from "./HOPOTPContent";
import { HOPOTPKPIIcon } from "./HOPOTPKPIIcon";
export const HOPOTPSection: React.FunctionComponent<IHOPOTPSectionProps> = (
  props: IHOPOTPSectionProps
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const getOTPContent = () => {
    return (
      <>
        <HOPOTPContent otpPerformance={props.otpPerformance} />
      </>
    );
  };

  const container = document.getElementsByClassName("amd-maincontainer")[0];
  if (container) {
    container.addEventListener("scroll", () => {
      setVisible(false);
    });
  }

  return (
    <Tooltip
      withArrow
      positioning="below"
      content={getOTPContent()}
      relationship="label"
      visible={visible}
    >
      <div
        className="d-flex cursor-pointer mt-sm-1 mb-sm-2 hop-kpi-detail jc-spacebtwn pr-1 pt-1 pb-1"
        data-testid="hop-otp-section"
        tabIndex={0}
        onClick={() => {
          setVisible(true);
        }}
        onBlur={() => {
          setVisible(false);
        }}
      >
        {props.otpData?.kpis.map((kpi: IHOPKpi) => {
          return (
            <div
              className="amd-container-vcenter ml-2 hop-otp-kpi"
              key={kpi.key}
            >
              <HOPAlertIcon status={kpi.status} />
              <HOPOTPKPIIcon kpiKey={kpi.key} kpiName={props.otpData?.label} />
              <span
                className={`${HOPHelper.getLegendTextColor(
                  kpi.status
                )} fw-700 ml-2`}
              >
                {kpi.performance}%
              </span>
            </div>
          );
        })}
      </div>
    </Tooltip>
  );
};
export interface IHOPOTPSectionProps {
  otpData?: IHOPSummaryItem;
  otpPerformance?: any[];
}
