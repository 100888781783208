import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import React, { useState } from "react";

import { ArrowSyncRegular, DismissCircleFilled } from "@fluentui/react-icons";

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const AmdErrorBoundaryFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const [isDialogHidden, setIsDialogHidden] = useState(true);
  return (
    <Dialog
      open={isDialogHidden}
      onOpenChange={(_event, data) => setIsDialogHidden(data.open)}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            Something went wrong!
            <p style={{ fontSize: "14px", fontWeight: "400", width: "90%" }}>
              Please retry again after some time. <br /> If the issue still
              persists, please contact the support team.
            </p>
          </DialogTitle>
          <DialogContent>{`Error Message: ${error.message}`}</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button icon={{ as: "span", children: <DismissCircleFilled /> }}>
                Close
              </Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              onClick={resetErrorBoundary}
              icon={{ as: "span", children: <ArrowSyncRegular /> }}
            >
              Retry
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default AmdErrorBoundaryFallback;
