import React from "react";

export const AmdFlightTransparent: React.FunctionComponent = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="flight-transparent-icon"
    >
      <path
        d="M19.4691 19.6433L18.7689 13.7685L18.767 13.6949C18.767 12.8063 19.4874 12.0859 20.376 12.0859C21.1788 12.0859 21.9217 12.5056 22.3543 13.2204L22.4407 13.381L25.3856 19.47L29.3427 19.3542C30.7763 19.3121 31.9727 20.4402 32.015 21.8848L32.0159 21.9501C32.0159 23.3844 30.8531 24.5472 29.3968 24.5469L25.4326 24.4308L22.4407 30.6191C22.0579 31.4111 21.2557 31.9142 20.376 31.9142C19.4874 31.9142 18.767 31.1939 18.7673 30.2829L18.7738 30.1649L19.4807 24.2567L17.3486 24.1944L17.0797 24.9301C16.7992 25.6974 16.0692 26.2078 15.2523 26.2078C14.3763 26.2078 13.6662 25.4977 13.6662 24.6214L13.6666 23.8618L13.5116 23.8295C12.6216 23.6438 11.9839 22.8592 11.9839 21.9501C11.9839 21.0408 12.622 20.2563 13.5121 20.0712L13.6666 20.0391L13.6662 19.2784C13.6662 18.4763 14.2644 17.8035 15.1009 17.7003L15.2523 17.6923C15.9972 17.6923 16.6751 18.1175 17.0167 18.8194L17.0795 18.9695L17.3492 19.7054L19.4691 19.6433ZM20.376 13.5859C20.3158 13.5859 20.267 13.6347 20.2667 13.6726L21.1531 21.0946L16.3126 21.2365L15.6852 19.5212L15.6527 19.4421C15.5783 19.2898 15.4229 19.1923 15.2987 19.1909L15.2405 19.1931C15.1989 19.1983 15.1662 19.235 15.1662 19.278L15.1672 21.2588L13.8176 21.5398C13.6232 21.5802 13.4839 21.7515 13.4839 21.9501C13.4839 22.1489 13.6234 22.3206 13.8177 22.3611L15.1672 22.642L15.1662 24.6217C15.1662 24.6693 15.2047 24.7078 15.2523 24.7078C15.4394 24.7078 15.6067 24.5908 15.6709 24.4151L16.3111 22.6635L21.1651 22.8052L20.2682 30.2764L20.267 30.3053C20.267 30.3655 20.3158 30.4142 20.376 30.4142C20.6803 30.4142 20.9578 30.2402 21.0903 29.9662L24.5052 22.903L29.4187 23.0472C30.0247 23.0472 30.516 22.556 30.516 21.9611L30.5154 21.9178C30.4976 21.3123 29.9923 20.8358 29.3867 20.8536L24.4582 20.9978L21.1065 14.0657L21.0541 13.9675C20.9108 13.7312 20.6537 13.5859 20.376 13.5859Z"
        fill="#004485"
      />
      <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#004485" />
    </svg>
  );
};
