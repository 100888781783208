import {
  IDropdownOption,
  Label,
  mergeStyleSets,
  TextField,
} from "@fluentui/react";
import { FC, useState } from "react";
import { getOpearatorOptions } from "../../../utils/tag-picker/TagPickerHelper";
import { DropdownControl } from "../../common/form-controls/controlled/DropDown/DropDownControl";
import { QueryTagPickerControl } from "./QueryTagPickerControl";
import { QueryTextControl } from "./QueryTextControl";

interface IQueryConditionProps {
  selectedOperator: IDropdownOption | undefined;
  selectedValues: any;
  fieldControlDetails: any;
}

const QueryCondition: FC<IQueryConditionProps> = (
  props: IQueryConditionProps
) => {
  const [itemLimit, setItemLimit] = useState(
    props.selectedOperator?.key === "equal" ? 1 : undefined
  );

  const { column, operator, values } = props.fieldControlDetails;
  const operatorOptions = getOpearatorOptions(operator.inputType);

  const onOperatorChangeHandler = (item?: IDropdownOption): void => {
    setItemLimit(item?.key === "equal" ? 1 : undefined);
  };

  return (
    <div
      className={`${contentStyles.queryCondition} amd-container-vstart d-flex--wrap w-100`}
      data-testid="query-condition"
    >
      <TextField
        disabled
        label="Column"
        defaultValue={column.name}
        aria-label="Filter Column"
        className={contentStyles.filterColumn}
      />

      <div>
        <Label id={operator.fieldName} required>
          Operator
        </Label>
        <DropdownControl
          controlId={operator.fieldName}
          isMandatory={operator.isMandatory}
          placeholder="Select operator"
          options={operatorOptions}
          defaultSelectedKey={
            props.selectedOperator ? props.selectedOperator.key.toString() : ""
          }
          className={contentStyles.filterOperator}
          onChange={onOperatorChangeHandler}
          dependentFieldId={values.fieldName}
        />
      </div>

      <div className={contentStyles.filterValue}>
        {values.inputType === "tagPicker" && (
          <>
            <Label id={values.fieldName} required>
              Value(s)
            </Label>
            <QueryTagPickerControl
              selectedItems={props.selectedValues}
              controlId={values.fieldName}
              isMandatory={values.isMandatory}
              itemLimit={itemLimit}
            />
          </>
        )}
        {values.inputType === "text" && (
          <QueryTextControl
            controlId={values.fieldName}
            label={values.label}
            defaultValue={props.selectedValues}
            isMandatory={false}
            isMultiline={false}
            suffix={values.suffix}
          />
        )}
      </div>
    </div>
  );
};

export default QueryCondition;

const contentStyles = mergeStyleSets({
  queryCondition: {
    padding: "0px 0px 20px 24px",
    columnGap: "24px",
  },

  filterColumn: {
    width: "clamp(120px, 220px, 220px)",
  },
  filterOperator: {
    width: "clamp(100px, 160px, 160px)",
  },
  filterValue: {
    flexGrow: 2,
    maxWidth: "420px",
  },
});
