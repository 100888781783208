enum WeatherKPITabs {
  Today = "today",
  Forecast = "forecast",
}
enum RunwayViewType {
  Runway = "runway",
  Extended = "extended",
}
enum WeatherGraphTabs {
  Temperature = "temperature",
  Humidity = "humidity",
  Rainfall = "rainfall",
  Wind = "wind",
  VisibilityRange = "visibilityRange",
  Clouds = "clouds",
  DewPoint = "dewPoint",
}

const WeatherEnum = {
  WeatherKPITabs,
  WeatherGraphTabs,
  RunwayViewType,
};

export default WeatherEnum;
