import IFlightsPerformance from "../../../models/flights/IFlightsPerformance";
import { FlightsPerformanceItem } from "./FlightsPerformanceItem";

export interface IFlightsPerformanceSummaryProps {
  flightsPerformance: IFlightsPerformance[];
  selectedTab: string;
}

export const FlightsPerformanceSummary: React.FunctionComponent<
  IFlightsPerformanceSummaryProps
> = (props: IFlightsPerformanceSummaryProps) => {
  return (
    <>
      <div className="ms-Grid mb-5" data-testid="flight-performance-summary">
        {props.flightsPerformance && props.flightsPerformance.length > 0 && (
          <div className="ms-Grid-row amd-container-vcenter">
            {props.flightsPerformance.map(
              (item: IFlightsPerformance, index: number) => {
                return (
                  <div
                    data-testid="performance-item"
                    key={item.name}
                    className={`ms-Grid-col ms-md4 pl-4 ${
                      index > 0 ? "divider-v-ltgrey" : ""
                    }`}
                  >
                    <FlightsPerformanceItem
                      performanceItem={item}
                      selectedTab={props.selectedTab}
                    />
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    </>
  );
};
