import { getAirportCode } from "../../../store/airport/AirportSelector";
import { useAppSelector } from "../../../store/StoreHooks";
import FlightScheduleHelper from "../../../utils/flights/FlightScheduleHelper";
import { NotificationBanner } from "../../common/notification-banner/NotificationBanner";
import { FlightsKPI } from "../flights-360/FlightsKPI";
import { FlightsMacroCard } from "../flights-360/FlightsMacroCard";
import { OTPMacroCard } from "../otp-flights/OTPMacroCard";

export const FlightsSchedule: React.FunctionComponent = () => {
  const airport = useAppSelector((state) => getAirportCode(state));

  return (
    <>
      <NotificationBanner airport={airport} />
      <h1 className="amd-heading1">Flight 360</h1>
      <FlightsKPI displayOtp={false} airport={airport} />
      <section className="amd-section-shadow my-4" id="flight">
        <FlightsMacroCard isDetailsPage={true} airport={airport} />
      </section>
      <section className="amd-section-shadow" id="otp">
        <OTPMacroCard
          dimensionName={FlightScheduleHelper.getDefaultSelectedDimension()}
          airport={airport}
        />
      </section>
    </>
  );
};
