import { IComboBoxStyles, TimePicker } from "@fluentui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface IAmdTimePickerProps {
  controlId: string;
  label: string;
  styles?: Partial<IComboBoxStyles>;
  useHour12: boolean;
  isMandatory: boolean;
  defaultValue?: Date;
  isReadOnly?: boolean;
}

export const AmdTimePicker: React.FunctionComponent<IAmdTimePickerProps> = (
  props: IAmdTimePickerProps
) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.controlId}
      control={control}
      rules={{ required: props.isMandatory }}
      render={({ field }) => (
        <TimePicker
          {...field}
          required={props.isMandatory}
          styles={props.styles ?? undefined}
          useHour12={props.useHour12}
          allowFreeform
          autoComplete="on"
          label={props.label}
          useComboBoxAsMenuWidth
          defaultValue={props.defaultValue ?? undefined}
          disabled={props.isReadOnly ?? false}
          onChange={(_e, date) => field.onChange(date)}
        />
      )}
    />
  );
};
