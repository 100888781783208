import { IconButton } from "@fluentui/react";
import { iconClass } from "./styles";


export function onEdit(item: any, onEditItem: any) {
    if (onEditItem) {
      onEditItem(item);
    }
  };

export function getEdit(item: any, onEditItem: any) {
    return (
      <IconButton
        iconProps={{ iconName: "Edit" }}
        title="Edit"
        ariaLabel="Edit"
        onClick={() => onEdit(item, onEditItem)}
        className={iconClass}
      />
    );
  };
