import { useState } from "react";
import AADRoleEnum from "../../enum/AADRoleEnum";
import AdminEnum from "../../enum/AdminEnum";
import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import { ADMIN_TABS } from "../../utils/admin/AdminConstants";
import { AuthZ } from "../common/auth/AuthZ";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";
import { AmdTabs } from "../common/tabs/tabs";
import { Airports } from "./airports/Airports";
import { Organizations } from "./organizations/Organizations";
import { Roles } from "./roles/Roles";
import { Users } from "./users/Users";

export const AdminContainer: React.FunctionComponent = () => {
  const [selectedTab, setSelectedTab] = useState<string>(AdminEnum.Tabs.Users);
  const roles = [AADRoleEnum.Admin];
  const onTabSelect = (e: any) => {
    setSelectedTab(e);
  };

  const airport = useAppSelector((state) => getAirportCode(state));

  return (
    <AuthZ roles={roles}>
      <>
        <NotificationBanner airport={airport} />
        <h1 className="amd-heading1">User Access Control</h1>
        <section
          className="amd-section-shadow my-4"
          data-testid="adminContainer"
        >
          <AmdTabs
            defaultSelectedTab={AdminEnum.Tabs.Users}
            tabList={ADMIN_TABS.data}
            dataTestId="admincontainer-tabs"
            onTabClick={onTabSelect}
            selectedTab={selectedTab}
          />

          {selectedTab === AdminEnum.Tabs.Users && <Users airport={airport} />}
          {selectedTab === AdminEnum.Tabs.Organizations && (
            <Organizations airport={airport} />
          )}
          {selectedTab === AdminEnum.Tabs.Roles && <Roles airport={airport} />}
          {selectedTab === AdminEnum.Tabs.Airports && <Airports />}
        </section>
      </>
    </AuthZ>
  );
};
