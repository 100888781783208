import { Link, makeStyles, mergeClasses } from "@fluentui/react-components";
import {
  AlertFilled,
  CalendarAddFilled,
  CalendarFilled,
  SettingsCogMultipleFilled,
} from "@fluentui/react-icons";
import PageEnum from "../../../enum/PageEnum";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { MapSignsIcon } from "../icons/map-signs/MapSignsIcon";

const QuickNavBar: React.FunctionComponent = () => {
  const isInTeams = CommonHelper.isInTeams();
  const classes = useStyles();

  const onCreateEventHandler = () => {
    window.sessionStorage.setItem("isNewEventClicked", "true");
  };

  return (
    <div
      className={mergeClasses(
        "amd-container-center",
        `${isInTeams ? classes.qNavTeams : classes.qNavApp}`,
        classes.qNav
      )}
      data-testid="quick-menu"
    >
      <div className="amd-container-vspacebtwn ai-center">
        <MapSignsIcon /> <span>Q-Nav</span>
      </div>
      <Link href={`/${PageEnum.PageUrl.ApplicationSettings}`}>
        <SettingsCogMultipleFilled fontSize={24} />
      </Link>
      <Link href={`/${PageEnum.PageUrl.Notifications}`}>
        <AlertFilled fontSize={24} />
      </Link>
      <Link href={`/${PageEnum.PageUrl.EventManagement}`}>
        <CalendarFilled fontSize={24} />
      </Link>
      <Link
        href={`/${PageEnum.PageUrl.EventManagement}`}
        onClick={onCreateEventHandler}
      >
        <CalendarAddFilled className={classes.icon} />
      </Link>
    </div>
  );
};

export default QuickNavBar;

const useStyles = makeStyles({
  qNav: {
    flexDirection: "column",
    rowGap: "25px",
    padding: "18px 0px",
    width: "auto",
    backgroundColor: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    position: "fixed",
    right: "17px",
  },
  qNavTeams: {
    top: "5rem",
  },
  qNavApp: {
    top: "9rem",
  },
  icon: {
    fontSize: "24px",
    padding: "0px 18px",
  },
});
