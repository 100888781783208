import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import { WeatherDisruptionKPIEnum } from "../../../enum/EventManagementEnum";
import { useAppSelector } from "../../../store/StoreHooks";
import { getWeatherDisruptionState } from "../../../store/weather-disruption/weatherDisruptionSelector";
import Card from "../../common/card/card";
import { AmdDots } from "../../common/icons/dots/Dots";
import { AmdGraph } from "../../common/icons/graph/Graph";
import { WeatherDisruptionCardType } from "./types";
import { WeatherDisruptionKPICard } from "./WeatherDisruptionKPICard";

type WeatherDisruptionCardProps = {
  card: WeatherDisruptionCardType;
  onShowGraph: (card: WeatherDisruptionCardType) => void;
  onHideGraph: () => void;
  onShowPopup: (card: WeatherDisruptionCardType) => void;
};

export const WeatherDisruptionCard: React.FunctionComponent<any> = ({
  card,
  onShowGraph,
  onHideGraph,
  onShowPopup,
}: WeatherDisruptionCardProps) => {
  const {
    id: cardId,
    kpi: kpiTitle,
    leftFooterTitle,
    leftFooterBody,
    rightFooterTitle,
    rightFooterBody,
  } = card;
  const weatherDisruptionState = useAppSelector((state: any) =>
    getWeatherDisruptionState(state)
  );
  const { selectedKpiId } = weatherDisruptionState;
  const [showGraph, setShowGraph] = React.useState<boolean>(false);

  const isEnabled = cardId && selectedKpiId && selectedKpiId !== cardId;
  const isSelectedCard = cardId && selectedKpiId && selectedKpiId === cardId;

  const isMarginRight =
    cardId !== WeatherDisruptionKPIEnum.PassengerOnGround &&
    cardId !== WeatherDisruptionKPIEnum.Weather;

  const showGraphHandler = () => {
    onShowGraph(card);
    setShowGraph((visible) => !visible);
  };
  const hideGraphHandler = () => {
    onHideGraph();
    setShowGraph((visible) => !visible);
  };
  const showPopupHandler = () => {
    onShowPopup(card);
  };

  const disablePointEvents = isEnabled
    ? contentStyles.cardLinkDisabled
    : contentStyles.cardActive;
  const selectedBackground = isSelectedCard
    ? contentStyles.cardSelected
    : undefined;

  const marginRightClass = isMarginRight ? "mr-2" : ""

  const isShowGraphLabel = showGraph && cardId === selectedKpiId;
  const showHideText = isShowGraphLabel ? "Hide" : "Show";
  return (
    <Card
      dataTestId={`card-${cardId}`}
      width={"auto"}
      height={180}
      className={`mb-2 ${marginRightClass} ${contentStyles.cardBorder} ${
        contentStyles.cardHover
      } ${selectedBackground}`}
      header={
        <section className="pt-0 amd-container-vcenter d-flex w-100">
          <div className="amd-container-vcenter fw-400 fs-20 w-70">
            {kpiTitle}
          </div>
          {cardId !== WeatherDisruptionKPIEnum.Weather ? (
            <>
              <div
                data-testid={`handleShowGraph-${cardId}`}
                onClick={!showGraph ? showGraphHandler : hideGraphHandler}
                className={`amd-container-vcenter float-r pl-1 fs-14 fw-600 fc--primary ${disablePointEvents}`}
              >
                {showHideText}
                <AmdGraph className="pl-1 pt-2" />
              </div>
              <div
                data-testid={`handleShowUpdatePopup-${cardId}`}
                onClick={showPopupHandler}
              >
                <AmdDots className="d-flex pt-2 ml-2" />
              </div>
            </>
          ) : null}
        </section>
      }
      headerType="default_header"
      bodyType="default_body"
      footerType="default_footer"
      footer={
        <div className="mt-2 w-100 amd-container-hspacebtwn mb-2 fs-13">
          <div>
            <div className="fw-400">{leftFooterTitle}</div>
            <div className="fw-600">{leftFooterBody}</div>
          </div>
          <div>
            <div className="fw-400">{rightFooterTitle}</div>
            <div className="fw-600">{rightFooterBody}</div>
          </div>
        </div>
      }
    >
      <WeatherDisruptionKPICard key={`kpicard-${cardId}`} item={card} />
    </Card>
  );
};

const contentStyles = mergeStyleSets({
  cardHover: {
    selectors: {
      "&:hover": {
        background: "#ebf3fe",
      },
    },
  },
  cardSelected: {
    ".ms-Grid-col &": {
      background: "#ebf3fe",
    },
  },
  cardBorder: {
    border: "1px solid rgba(0, 0, 0, 0.15)",
  },
  cardLinkDisabled: {
    pointerEvents: "none",
    opacity: "50%",
  },
  cardActive: {
    zIndex: "100"
  },
});
