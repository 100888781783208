import { PrimaryButton } from "@fluentui/react";
import IDimension from "../../models/admin/IDimension";
import { ThresholdInput } from "./ThresholdInput";
export const DimensionDetails: React.FunctionComponent<
  IDimensionDetailsProps
> = (props: IDimensionDetailsProps) => {
  return (
    <>
      {props.selectedDimensionDetails && (
        <div data-testid="dimension-details">
          <ThresholdInput
            onChangeThresholdValue={props.onChangeThresholdValue}
            selectedDimension={props.selectedDimensionDetails}
          />
          <PrimaryButton
            text="Save"
            onClick={(_e) => {
              props.onSaveDimension(props.selectedDimensionDetails);
            }}
            className="mt-1"
            data-testid="save-dimension"
          />
        </div>
      )}
    </>
  );
};
export interface IDimensionDetailsProps {
  selectedDimensionDetails?: IDimension;
  onSaveDimension: any;
  onChangeThresholdValue: any;
}
