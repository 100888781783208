import EventManagementEnum from "../../../../enum/EventManagementEnum";
import TagPickerEnum from "../../../../enum/TagPickerEnum";
import { IEventResponseTeamMember } from "../../../../models/events/IEventResponseTeamMember";
import {
  RESPONSETEAM_MEMBERCOLUMNS,
  RESPONSETEAM_TAGPICKER_COLUMNS,
} from "../../../../utils/events/EventConstants";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { AmdDataGrid } from "../../../common/data-grid/AmdDataGrid";
import { AmdTagPickControl } from "../../../common/form-controls/controlled/TagPicker/AmdTagPickControl";

export interface IEventResponseTeamMemberProps {
  isNewForm?: boolean;
  isInEditMode?: boolean;
  mergedTeamState: any;
  manageResponseTeam: any;
  airport: string;
}

export const EventResponseTeamMember: React.FunctionComponent<
  IEventResponseTeamMemberProps
> = (props: IEventResponseTeamMemberProps) => {
  const onUserAdded = (data: any) => {
    const users = props?.mergedTeamState?.responseTeam?.users;
    const userAlreadyPresent = users?.find((user: IEventResponseTeamMember) => {
      return user?.email?.toLowerCase() === data?.key?.toLowerCase();
    });

    if (userAlreadyPresent) {
      return data;
    } else {
      return props.manageResponseTeam(
        EventManagementEnum.ResponseTeamResourceType.User,
        EventManagementEnum.ResponseTeamResourceAction.Add,
        data
      );
    }
  };

  const onUserDelete = (data: any) => {
    return props.manageResponseTeam(
      EventManagementEnum.ResponseTeamResourceType.User,
      EventManagementEnum.ResponseTeamResourceAction.Delete,
      data
    );
  };

  RESPONSETEAM_MEMBERCOLUMNS[0].onRender = (item: any) => (
    <div>
      {item.name} <br /> {item.email}
    </div>
  );

  return (
    <div data-testid="response-teammember-container">
      <div className="fc--gray900 mt-2 fs-18">Individuals</div>
      {(props.isNewForm || props.isInEditMode) && (
        <AmdTagPickControl
          controlId="responseTeam-individualUsers"
          tagLabel=""
          isMandatory={false}
          suggestionType={TagPickerEnum.TagPickerType.Users}
          onTagSelected={onUserAdded}
          columns={RESPONSETEAM_TAGPICKER_COLUMNS}
          selectedItems={[]}
          airport={props.airport}
        />
      )}

      {props?.mergedTeamState?.responseTeam?.users &&
        props?.mergedTeamState?.responseTeam?.users?.length > 0 && (
          <div className="mt-3">
            <AmdDataGrid
              showActionButtons={true}
              rowItems={EventManagementHelper.getResponseTeamMembers(
                props?.mergedTeamState?.responseTeam?.users ?? []
              )}
              columns={RESPONSETEAM_MEMBERCOLUMNS}
              minHeight="200px"
              onDeleteItem={onUserDelete}
            />
          </div>
        )}
    </div>
  );
};
