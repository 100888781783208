import { PublicClientApplication } from "@azure/msal-browser";
import { EventManagementHelper } from "../../../utils/events";
import { SelectedGraphDatum } from "../../../models/events/IWeatherDisruptionSummary";
export async function getGraphicalData(msalInstance: PublicClientApplication, airportCode: string, resourceType: string, dateFormat: string): Promise<SelectedGraphDatum[]| null>  {
      try {
        const { data:selectedGraphResponse } = await EventManagementHelper.getPlanningGraphical(
          msalInstance,
          airportCode,
          resourceType,
          dateFormat
        );
        return selectedGraphResponse;
      } catch (error) {
        console.log("graphical api error", error);
        return null;
      }
    };