import { SearchBoxChangeEvent } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { addTimeZoneDiff } from "../../utils/common/CommonHelperFunctions";
import {
  calculateAirportStatusCount,
  extractAirportMapDetails,
  filterAirportsByStatus,
  findMatches,
  getCommonAirports,
} from "../../utils/multi-airports/MultiAirportHelperFunctions";
import MapDisplayAirports from "../osm-maps/MapDisplayAirports";
import AIRPORT_LIST from "./../../mock/multi-airports/new-multi-airport.json";
import AirportList from "./AirportList";
import { AirportPerformance } from "./AirportPerformance";
import MultiAirportSidebar from "./MultiAirportSidebar";

export const MultiAirport: React.FunctionComponent = () => {
  const [airportList, setAirportList] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [airportSearch, setAirportSearch] = useState<any[]>([]);
  const { isMapView } = React.useContext(LayoutContext);
  const [sideBarActive, setSideBarActive] = React.useState<boolean>(true);

  // Logic to update time every minute
  const [time, setTime] = useState<Date>(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 60000); // Refresh every minute - 60000 milliseconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let isMounted = true;
    const airportsNewList = Promise.resolve(AIRPORT_LIST.data);
    airportsNewList.then((response: any) => {
      if (isMounted) {
        const updatedAirportList = addTimeZoneDiff(response);
        setAirportList(updatedAirportList);
        setFilterData(updatedAirportList);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const filterAirportList = (tagStatusType: any) => {
    const filteredData = filterAirportsByStatus(airportList, tagStatusType);
    setFilterData(filteredData);
  };

  const updatePinnedStatus = (id: string) => {
    const updatedData = airportList.map((item: any) =>
      item.id === id ? { ...item, isPinned: !item.isPinned } : item
    );
    const updatedFilterData = filterData.map((item: any) =>
      item.id === id ? { ...item, isPinned: !item.isPinned } : item
    );
    setFilterData(updatedFilterData);
    setAirportList(updatedData);
  };

  function searchInputHandler(event: SearchBoxChangeEvent): void {
    const { target } = event;
    const { value } = target as HTMLInputElement;
    const matchedAirports = findMatches(value, airportList);
    setAirportSearch(matchedAirports);
  }

  const airports = airportSearch?.length ? airportSearch : airportList;
  const airportStatusCount = calculateAirportStatusCount(airportList);
  const commonAirports = getCommonAirports(airports, filterData);
  const airportMapDetails = extractAirportMapDetails(commonAirports);

  return (
    <div data-testid="multi-airport">
      <AirportPerformance
        filterAirport={filterAirportList}
        setAirportSearch={setAirportSearch}
        airportStatusCount={airportStatusCount}
        sideBarActive={sideBarActive}
        searchInputHandler={searchInputHandler}
      />
      {isMapView && (
        <MapDisplayAirports airportMapDetails={airportMapDetails} />
      )}
      {isMapView ? (
        <MultiAirportSidebar
          airportList={commonAirports}
          onPinClickHandler={updatePinnedStatus}
          currentTime={time}
          sideBarActive={sideBarActive}
          setSideBarActive={setSideBarActive}
          searchInputHandler={searchInputHandler}
        />
      ) : (
        <AirportList
          airportList={commonAirports}
          onPinClickHandler={updatePinnedStatus}
          currentTime={time}
        />
      )}
    </div>
  );
};
