import { IChartDataPoint } from "@fluentui/react-charting";
import ResourceEnum from "../../enum/ResourceEnum";
import { FlightGraphHelper } from "../flights/FlightGraphHelper";
import { ResourceGraphHelper } from "../resource/ResourceGraphHelper";
import { WeatherGraphHelper } from "../weather/WeatherGraphHelper";

export default class ChartHelper {
  public static getChartPoints(resourceData?: any[]) {
    let chartPoints: IChartDataPoint[] = [];
    let centerValue = "";
    if (resourceData && resourceData.length > 0) {
      chartPoints = resourceData.map((item) => ({
        data: item?.resourceCount,
        color: item?.resourceColor,
        xAxisCalloutData: item?.resourceTitle,
        legend: item?.resourceTitle,
      }));
      centerValue = resourceData[0].resourceTotal?.toString() ?? "";
    }
    return { chartPoints: chartPoints, centerValue: centerValue };
  }

  public static getxAxisTickCount(graphData: any[]) {
    return graphData.length > 24 ? 24 : graphData.length;
  }

  public static getLineChartData(
    response: any[],
    resourceType: string,
    handleLegendClick?: any
  ) {
    switch (resourceType) {
      case ResourceEnum.ResourceType.Flight:
        return FlightGraphHelper.setFlightGraphData(
          response,
          handleLegendClick
        );
      case ResourceEnum.ResourceType.Belts:
      case ResourceEnum.ResourceType.Chutes:
      case ResourceEnum.ResourceType.Gates:
      case ResourceEnum.ResourceType.Stand:
      case ResourceEnum.ResourceType.Counter:
        return ResourceGraphHelper.setResourceGraphData(
          response,
          resourceType,
          handleLegendClick
        );
      case ResourceEnum.ResourceType.Weather:
        return WeatherGraphHelper.setWeatherGraphData(response);
      default:
        return FlightGraphHelper.setFlightGraphData(response);
    }
  }

  public static setDataPointClick(data: any, props: any) {
    data.lineChartData?.forEach((_data: any) => {
      if (_data.data.length > 0) {
        _data.data.forEach((_point: any) => {
          _point.onDataPointClick = () =>
            props.onDataPointClick(_point.x, _point.y, _data.legend);
        });
      }
    });
  }

  public static getValueInsideDonut(
    result: any,
    showPercent = false,
    hideValueInsideDonut = false
  ) {
    return hideValueInsideDonut
      ? ""
      : showPercent
      ? parseFloat(result?.centerValue).toFixed(2) + "%"
      : result?.centerValue;
  }

  public static getLegendData(legendData: any[], addLabel = false) {
    return legendData?.map((data: any) => {
      return {
        color: data.resourceColor,
        legendText: addLabel
          ? `${data.resourceCount} flights`
          : data.resourceCount,
        legendKey: data.resourceTitle,
        testId: data.resourceTitle,
      };
    });
  }
}
