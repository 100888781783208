import { TextField, mergeStyleSets } from "@fluentui/react";
import { Controller, useFormContext } from "react-hook-form";
import { CommonHelper } from "../../../../../utils/common/CommonHelper";

interface IAmdTextFieldProps {
  controlId: string;
  label: string;
  isMandatory: boolean;
  isMultiline: boolean;
  defaultValue?: string;
  isReadOnly?: boolean;
  className?: string;
}

export const AmdTextField: React.FunctionComponent<IAmdTextFieldProps> = (
  props: IAmdTextFieldProps
) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div style={textFieldControlStyle}>
      <Controller
        name={props.controlId}
        control={control}
        rules={{ required: props.isMandatory }}
        render={({ field }) => (
          <TextField
            styles={textfieldStyles}
            className={props.className}
            {...field}
            label={props.label}
            required={props.isMandatory}
            multiline={props.isMultiline}
            disabled={props.isReadOnly ?? false}
            defaultValue={props.defaultValue}
            onChange={(e) => field.onChange(e)}
            errorMessage={CommonHelper.requiredFieldValidation(
              errors,
              props.controlId
            )}
          />
        )}
      />
    </div>
  );
};

const textFieldControlStyle: React.CSSProperties = {
  width: "100%",
};

const textfieldStyles = mergeStyleSets({
  root: {
    selectors: {
      "& .ms-Label": {
        outlineOffset: "2px",
      },
      "&:focus-visible": {
        outlineOffset: "2px",
      },
    },
  },
});
