import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import OTP_FILTERS from "../../../data/filter-panel/otp-flights.json";
import IFilters from "../../../models/filters/IFilters";
import { IFlightScheduleFilters } from "../../../models/flights/IFlightScheduleFilters";
import { getMemoizedOTPFlightFilters } from "../../../store/flights-filter/OTPFlightFilterSelector";
import { flightsFilterActions } from "../../../store/flights-filter/OTPFlightFiltersSlice";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import FlightScheduleHelper from "../../../utils/flights/FlightScheduleHelper";
import { AmdTabs } from "../../common/tabs/tabs";
import { FlightListContainer } from "./FlightListContainer";
import { FlightsPerformanceSummary } from "./FlightsPerformanceSummary";
import useFlightsPerformanceEffect from "./hooks/useFlightsPerformanceEffect";
import useOTPTabsEffect from "./hooks/useOTPTabsEffect";

export const OTPMacroCard: React.FunctionComponent<IOTPMacroCardProps> = (
  props: IOTPMacroCardProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [selectedTab, setSelectedTab] = useState<string>(props.dimensionName);
  const { otpTabsSummary, otpTabs } = useOTPTabsEffect(
    msalInstance,
    props.airport
  );
  const flightsPerformance = useFlightsPerformanceEffect(
    msalInstance,
    selectedTab,
    props.airport
  );
  const [totalFlights, setTotalFlights] = useState<number>();
  const [otpFlightListState, setOtpFlightListState] =
    useState<IFlightScheduleFilters>(
      FlightScheduleHelper.getDefaultOtpFlightListState(
        selectedTab,
        props.airport
      )
    );

  const dispatch = useAppDispatch();
  const filterObj = localStorage.getItem("otpFlightFilters");
  let filters: IFilters[] = [];
  if (filterObj) {
    filters = JSON.parse(filterObj);
  }

  const setOtpFlightFilters = () => {
    if (filters && filters.length > 0) {
      dispatch(flightsFilterActions.setOtpFlightFilters(filters));
      const selectedItems = FlightScheduleHelper.getSelectedItems(filters);
      const flightListState =
        FlightScheduleHelper.getFlightlistState(selectedItems);
      onFiltersApplied(flightListState);
    } else {
      dispatch(flightsFilterActions.setOtpFlightFilters(OTP_FILTERS.data));
    }
  };

  const flightSchedulefilters = useAppSelector(getMemoizedOTPFlightFilters);

  useEffect(() => {
    FlightScheduleHelper.scrollToComponent(
      window.sessionStorage.getItem("scrollId")?.toString()
    );
    window.sessionStorage.removeItem("scrollId");

    setOtpFlightFilters();
  }, [dispatch]);

  const onOtpPageDropDownChange = (_pageNumber: string) => {
    const stateObj = { ...otpFlightListState, page: _pageNumber };
    stateObj.page = _pageNumber;
    setOtpFlightListState(stateObj);
  };

  const onOtpFlightTypeChange = (_flightType: string) => {
    const stateObj = { ...otpFlightListState };
    stateObj.flightType = _flightType;
    setOtpFlightListState(stateObj);
  };

  const onFiltersApplied = (_filters: IFlightScheduleFilters) => {
    let stateObj = FlightScheduleHelper.getDefaultOtpFlightListState(
      selectedTab,
      props.airport
    );
    stateObj = FlightScheduleHelper.onFlightScheduleFiltersApplied(
      stateObj,
      _filters,
      props.airport
    );

    setOtpFlightListState(stateObj);
  };

  const onUpdateFilter = (updatedItems: IFilters[]) => {
    dispatch(flightsFilterActions.setOtpFlightFilters(updatedItems));
  };

  const getFlightTotal = (_total: number) => {
    setTotalFlights(_total);
  };

  const onOTPTabClick = (_selectedtab: string) => {
    setSelectedTab(_selectedtab);
    const stateObj = { ...otpFlightListState };
    stateObj.viewType = FlightScheduleHelper.getViewType(_selectedtab);
    stateObj.flightType = FlightScheduleHelper.getFlightType(_selectedtab);
    setOtpFlightListState(stateObj);
  };
  return (
    <div data-testid="otp-macro-card">
      <div className="amd-container-hspacebtwn amd-container-vcenter mb-2">
        {otpTabsSummary && (
          <AmdTabs
            dataTestId="OTPTab"
            defaultSelectedTab={props.dimensionName}
            tabList={otpTabs}
            onTabClick={onOTPTabClick}
            selectedTab={selectedTab}
          />
        )}
      </div>
      <FlightsPerformanceSummary
        flightsPerformance={flightsPerformance}
        selectedTab={selectedTab}
      />

      <FlightListContainer
        onOtpFlightTypeChange={onOtpFlightTypeChange}
        otpFlightListState={otpFlightListState}
        onOtpPageDropDownChange={onOtpPageDropDownChange}
        totalFlights={totalFlights}
        flightSchedulefilters={flightSchedulefilters}
        onFiltersApplied={onFiltersApplied}
        onUpdateFilter={onUpdateFilter}
        getFlightTotal={getFlightTotal}
        selectedTab={selectedTab}
        airport={props.airport}
      />
    </div>
  );
};

export interface IOTPMacroCardProps {
  dimensionName: string;
  airport: string;
}
