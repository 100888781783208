import { makeStyles, mergeClasses } from "@fluentui/react-components";
import {
  CheckmarkCircleRegular,
  ErrorCircleRegular,
  WarningRegular,
} from "@fluentui/react-icons";
import HOPEnum from "../../../enum/HOPEnum";

export const StatusIconSelector: React.FunctionComponent<
  IStatusIconSelectorProps
> = ({ status }) => {
  const classes = useStyles();

  const statusIcon = (() => {
    switch (status) {
      case HOPEnum.HOPDataStatus.Poor:
        return (
          <ErrorCircleRegular
            className={mergeClasses(classes.icon, classes.errorIcon)}
            data-testid="airport-otp-poor"
          />
        );
      case HOPEnum.HOPDataStatus.Moderate:
        return (
          <WarningRegular
            className={mergeClasses(classes.icon, classes.warningIcon)}
            data-testid="airport-otp-moderate"
          />
        );
      case HOPEnum.HOPDataStatus.Good:
        return (
          <CheckmarkCircleRegular
            className={mergeClasses(classes.icon, classes.goodIcon)}
            data-testid="airport-otp-good"
          />
        );
      default:
        return null;
    }
  })();

  return <>{statusIcon}</>;
};

export interface IStatusIconSelectorProps {
  status: string;
}

const useStyles = makeStyles({
  icon: {
    fontSize: "18px",
    strokeWidth: "0.5px",
    verticalAlign: "bottom",
    paddingRight: "4px",
  },
  goodIcon: {
    color: "#569643",
    stroke: "#569643",
  },
  errorIcon: {
    color: "#B2271F",
    stroke: "#B2271F",
  },
  warningIcon: {
    color: "#FA6400",
    stroke: "#FA6400",
  },
});
