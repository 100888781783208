import { PublicClientApplication } from "@azure/msal-browser";
import * as microsoftTeams from "@microsoft/teams-js";
import { loginRequest } from "../../components/common/auth/authConfig";

export abstract class AuthHelper {
  public static async getAccessToken(
    msalInstance: PublicClientApplication
  ): Promise<string> {
    return new Promise<string>((_resolve, _reject) => {
      const isInTeams =
        window?.location?.ancestorOrigins?.length === 0 ? false : true;

      if (isInTeams) {
        microsoftTeams.initialize();

        var authTokenRequest = {
          successCallback: function (_accessToken: any) {
            return _resolve(_accessToken);
          },
          failureCallback: function (_error: any) {
            console.error(`Error getting token: ${_error}`);
            return _reject("");
          },
        };
        microsoftTeams.authentication.getAuthToken(authTokenRequest);
      } else {
        msalInstance
          .acquireTokenSilent(loginRequest)
          .then((response) => {
            return _resolve(response.accessToken);
          })
          .catch((_error) => {
            console.error(`Error getting token: ${_error}`);
            return msalInstance.acquireTokenRedirect(loginRequest);
          });
      }
    });
  }
}
