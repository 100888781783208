import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  DialogFooter,
  Link,
  PrimaryButton,
  mergeStyleSets,
} from "@fluentui/react";
import { DialogBody } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { NotificationContext } from "../../../../context/NotificationContext";
import EventManagementEnum from "../../../../enum/EventManagementEnum";
import { IEventUserPrivilege } from "../../../../models/events/IEventRole";
import { CommonHelper } from "../../../../utils/common/CommonHelper";
import { MESSAGEBAR_TYPE } from "../../../../utils/common/Constants";
import { CHANNELLIST_COLUMNS } from "../../../../utils/events/EventConstants";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { AmdDataGrid } from "../../../common/data-grid/AmdDataGrid";
import { AmdButton } from "../../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdModal } from "../../../common/form-controls/uncontrolled/Modal/AmdModal";
import { AmdPlus } from "../../../common/icons/plus/Plus";
import { NoDataCard } from "../../../common/no-data-card/NoDataCard";
import { EventAddChannel } from "../add-channel/EventAddChannel";
import { EventEditChannel } from "../edit-channel/EventEditChannel";
import { EventViewChannel } from "../view-channel/EventViewChannel";

export interface IEventChannelsListProps {
  eventId: string;
  airport: string;
}

export const EventChannelsList: React.FunctionComponent<
  IEventChannelsListProps
> = (props: IEventChannelsListProps) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const { addNotification } = React.useContext(NotificationContext);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [eventUserPrivilege, setEventUserPrivilege] =
    useState<IEventUserPrivilege>();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [channelListApiResponse, setChannelListApiResponse] = useState<any>();
  const [selectedChannel, setSelectedChannel] = useState<any>();
  const [viewType, setViewType] = useState(
    EventManagementEnum.EventChannelViewType.List
  );
  const [
    isUserPartOfChannelManagementTeam,
    setIsUserPartOfChannelManagementTeam,
  ] = useState(false);
  const [channelListRefreshTimestamp, setChannelListRefreshTimestamp] =
    useState(new Date());

  useEffect(() => {
    CommonHelper.getCurrentUserEmail(msalInstance).then((userEmail: string) => {
      setCurrentUserEmail(userEmail);
    });
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (props.airport !== "" && currentUserEmail !== "") {
      EventManagementHelper.getEventUserPrivileges(
        msalInstance,
        props.eventId,
        currentUserEmail,
        props.airport
      ).then((response: any) => {
        if (isMounted && response && response.data) {
          setEventUserPrivilege(response.data);
          const isUserPartOfChannelMgmt =
            EventManagementHelper.isUserPartOfChannelManagementTeam(
              response.data
            );
          setIsUserPartOfChannelManagementTeam(isUserPartOfChannelMgmt);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [props.airport, currentUserEmail]);

  let isMounted = true;
  useEffect(() => {
    if (props.airport !== "" && props.eventId) {
      EventManagementHelper.getEventChannels(
        msalInstance,
        currentUserEmail,
        props.airport,
        props.eventId
      )
        .then((response: any) => {
          if (isMounted && response && response.data) {
            const eventChannelsResponse =
              EventManagementHelper.formatEventChannelResponse(response.data);
            setChannelListApiResponse(eventChannelsResponse);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [
    props.airport,
    currentUserEmail,
    props.eventId,
    channelListRefreshTimestamp,
  ]);

  const onAddNewChannel = () => {
    setViewType(EventManagementEnum.EventChannelViewType.Add);
  };

  const onCancelNewChannel = () => {
    setViewType(EventManagementEnum.EventChannelViewType.List);
  };

  const onChannelAdded = (response: any) => {
    if (response.status === 201) {
      setChannelListRefreshTimestamp(new Date());
      setViewType(EventManagementEnum.EventChannelViewType.List);
      addNotification(MESSAGEBAR_TYPE.SUCCESS, `Channel created successfully!`);
    }
  };

  const onChannelEdit = (item: any) => {
    setSelectedChannel(item);
    setViewType(EventManagementEnum.EventChannelViewType.Edit);
  };

  const onChannelEdited = (response: any) => {
    if (response.status === 200) {
      setChannelListRefreshTimestamp(new Date());
      setViewType(EventManagementEnum.EventChannelViewType.List);
      addNotification(MESSAGEBAR_TYPE.SUCCESS, `Channel updated successfully!`);
    }
  };

  const onChannelDelete = (item: any) => {
    setSelectedChannel(item);
    setViewType(EventManagementEnum.EventChannelViewType.Delete);
    showModal();
  };

  const onChannelDeleteConfirm = async () => {
    const response = await EventManagementHelper.deleteChannel(
      msalInstance,
      props.eventId,
      selectedChannel?.id ?? selectedChannel?.channelId,
      currentUserEmail,
      props.airport
    );

    if (response.status === 200) {
      setChannelListRefreshTimestamp(new Date());
      setViewType(EventManagementEnum.EventChannelViewType.List);
      addNotification(MESSAGEBAR_TYPE.SUCCESS, `Channel deleted successfully!`);
    }
  };

  const onChannelDeleteClose = () => {
    setViewType(EventManagementEnum.EventChannelViewType.List);
    hideModal();
  };

  CHANNELLIST_COLUMNS[0].onRender = (item: any) => (
    <Link
      key={item}
      onClick={(e: any) => {
        const clickedChannel = channelListApiResponse?.filter(
          (channel: any) => {
            return channel.name === e.target.innerText;
          }
        )[0];
        setSelectedChannel(clickedChannel);
        setViewType(EventManagementEnum.EventChannelViewType.View);
      }}
    >
      {item.name}
    </Link>
  );

  return (
    <div
      className="mt-3"
      id="channelListContainer"
      data-testid="channelListContainer"
    >
      {viewType === EventManagementEnum.EventChannelViewType.List && (
        <>
          <div className="mt-3">
            {channelListApiResponse?.length > 0 ? (
              <AmdDataGrid
                showActionButtons={true}
                rowItems={EventManagementHelper.getChannelListData(
                  channelListApiResponse,
                  isUserPartOfChannelManagementTeam
                )}
                columns={CHANNELLIST_COLUMNS}
                minHeight="250px"
                onEditItem={onChannelEdit}
                onDeleteItem={onChannelDelete}
              />
            ) : (
              <NoDataCard message="No channels found" />
            )}
          </div>
          <div className="mt-3">
            {eventUserPrivilege && isUserPartOfChannelManagementTeam && (
              <AmdButton
                testId="addChannel"
                onClick={onAddNewChannel}
                text="Add MS Teams channel"
                className="mt-3"
                type={AmdButtonType.DEFAULT}
                prefixImageIconBeforeText={true}
                imageIcon={<AmdPlus className={contentStyles.plusIcon} />}
              />
            )}
          </div>
        </>
      )}

      {viewType === EventManagementEnum.EventChannelViewType.Add &&
        props.eventId && (
          <EventAddChannel
            eventId={props.eventId}
            onChannelAdded={onChannelAdded}
            onCancelAddChannel={onCancelNewChannel}
            airport={props.airport}
          />
        )}

      {viewType === EventManagementEnum.EventChannelViewType.Edit &&
        props.eventId && (
          <EventEditChannel
            eventId={props.eventId}
            channelId={selectedChannel?.id}
            onChannelEdited={onChannelEdited}
            onCancelEditChannel={onCancelNewChannel}
            airport={props.airport}
          />
        )}

      {viewType === EventManagementEnum.EventChannelViewType.View &&
        props.eventId && (
          <EventViewChannel
            eventId={props.eventId}
            channelId={selectedChannel?.channelId}
            hasManageChannelAccess={
              eventUserPrivilege?.partOfChannelManagementTeam ?? false
            }
            channelType={selectedChannel?.channelType}
            airport={props.airport}
            toolbarAction={(action: any) => {
              setViewType(action);
              showModal();
            }}
          />
        )}

      {viewType === EventManagementEnum.EventChannelViewType.Delete && (
        <AmdModal
          isOpen={isModalOpen}
          isBlocking={true}
          header="Delete channel"
          onClose={onChannelDeleteClose}
          containerClassName={modalStyles.container}
        >
          <div className={modalStyles.body}>
            <DialogBody>
              Are you sure you want to delete channel:{" "}
              {selectedChannel?.channelName ?? selectedChannel?.name}?
            </DialogBody>
            <DialogFooter>
              <DefaultButton text="Cancel" onClick={onChannelDeleteClose} />
              <PrimaryButton text="Confirm" onClick={onChannelDeleteConfirm} />
            </DialogFooter>
          </div>
        </AmdModal>
      )}
    </div>
  );
};

const contentStyles = mergeStyleSets({
  plusIcon: {
    paddingRight: "10px",
    marginTop: "2px",
  },
});

const modalStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "40vh",
    width: "30vw",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
