import React from "react";

export const UtilisationCardIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="37"
      height="32"
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 16C6.4375 16 6 15.5625 6 15V1C6 0.5 6.4375 0 7 0H14V8L18 6L22 8V0H29C29.5 0 30 0.5 30 1V15C30 15.5625 29.5 16 29 16H7ZM35.3125 20.5625C36.25 21.375 36.1875 22.8125 35.25 23.5625L25.75 31.125C25.0625 31.75 24.1875 32 23.25 32H1C0.4375 32 0 31.5625 0 31V25C0 24.5 0.4375 24 1 24H4.4375L7.3125 21.6875C8.625 20.625 10.3125 20 12 20H22C23.1875 20 24.125 21.125 23.9375 22.375C23.8125 23.375 22.875 24 21.875 24H17C16.4375 24 16 24.5 16 25C16 25.5625 16.4375 26 17 26H24.375C25.25 26 26.1875 25.75 26.875 25.125L32.625 20.5625C33.4375 19.9375 34.5625 19.875 35.3125 20.5625Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};
