import { mergeStyles } from "@fluentui/react";
import { Image, Spinner } from "@fluentui/react-components";
import moment from "moment-timezone";
import * as React from "react";
import ResourceEnum from "../../../enum/ResourceEnum";
import { useApiCall } from "../../../hooks/useApiCall";
import {
  IWeatherTimeSeries,
  IWeatherTimeSeriesDailySummary,
} from "../../../models/weather/IWeatherGraph";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import DateHelper from "../../../utils/common/DateHelper";
import { CustomLineChart } from "../../common/charts/LineChart";
import { NoDataCard } from "../../common/no-data-card/NoDataCard";

export interface WeatherGraphProps {
  data: IWeatherTimeSeries[];
  airport: string;
}

const WeatherGraph: React.FunctionComponent<WeatherGraphProps> = (
  props: WeatherGraphProps
) => {
  const futureDayCount: moment.DurationInputArg1 = 7;
  const fromDate = moment().format("YYYY-MM-DD");
  const toDate = moment().add(futureDayCount, "days").format("YYYY-MM-DD");

  const getWeatherTimeSeriesDailySummaryUrl = `${process.env.REACT_APP_API_WEATHER_TIMESERIES_DAILYSUMMARY?.replace(
    "{airportCode}",
    props.airport
  )}/${fromDate}/${toDate}`;

  const {
    data: weatherTimeSeriesDailySummaryData,
    error,
    isLoading,
  } = useApiCall<Array<IWeatherTimeSeriesDailySummary>>({
    url: getWeatherTimeSeriesDailySummaryUrl,
    dependencies: [props.airport],
  });

  return (
    <div data-testid="weather-graph-container">
      <CustomLineChart
        chartData={props.data}
        resourceType={ResourceEnum.ResourceType.Weather}
        xAxisTickCount={
          props.data.length > 0 ? props.data[0].timeseriesData.length : 0
        }
        customDateTimeFormatter={(a: any) => DateHelper.getDayFromDate(a)}
      ></CustomLineChart>

      <div className="amd-container-haround mt-2">
        {error && <NoDataCard message="No Weather data found" />}
        {isLoading && <Spinner labelPosition="below" label="Loading..." />}
        {!error &&
          !isLoading &&
          weatherTimeSeriesDailySummaryData?.map(
            (item: IWeatherTimeSeriesDailySummary) => (
              <div
                className={forecastContainerStyle}
                key={CommonHelper.getGuid()}
                data-testid="weather-series-daily"
              >
                <div className="fc-13 fw-600 text-c">
                  {DateHelper.getDayFromDate(item.date)}
                </div>
                <Image
                  className={weatherForecastIconStyle}
                  src={`${window.location.origin}/images/weatherIcons/${item.weatherCode}.svg`}
                />
                <div className="fc-14 fw-600 text-c">{item.weatherSummary}</div>
              </div>
            )
          )}
      </div>
    </div>
  );
};

const forecastContainerStyle = mergeStyles({
  width: 74,
  textAlign: "center",
});

const weatherForecastIconStyle = mergeStyles({
  width: 20,
  height: 20,
});

export default WeatherGraph;
