import { AmdDonutChart } from "../../../common/charts/DonutChart/DonutChart";

export interface IEventKPIProps {
  eventKPIData: any[];
  kpiName: string;
}

export const EventKPI: React.FunctionComponent<IEventKPIProps> = (
  props: IEventKPIProps
) => {
  return (
    <div data-testid="event-kpi">
      <AmdDonutChart
        resourceData={props.eventKPIData}
        noFlightsMessage="No events found"
        hideLegend={true}
        hideValueInsideDonut={true}
        donutContainerWidth="165px"
        chartAlignment="left"
        title={props.kpiName}
        showCustomLegend={true}
        legendData={props.eventKPIData?.map((data: any) => {
          return {
            color: data.resourceColor,
            legendText: `${
              data.resourceTitle
            } ${data.resourceCount?.toString()}`,
            legendValue: data.resourceTitle,
            testId: data.resourceTitle,
          };
        })}
      />
    </div>
  );
};
