import React from "react";
import { AmdRedSmall } from "../icons/red/redSmall";
import { makeStyles } from "@fluentui/react-components";
const useClasses = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    paddingRight: "5px",
  },

});

export default function OverUtilised({
  fieldContent,
}: {
  fieldContent: string;
}): React.JSX.Element {
  const classes = useClasses();
  return (
    <div className={classes.root}>
      <span className={classes.icon}>
        <AmdRedSmall />
      </span>
      <span>{fieldContent}</span>
    </div>
  );
}
