import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import {
  PAXRowEnum,
  SelectedTouchPointFilterName,
  TouchPointName,
} from "../../../enum/PAXEnum";
import PAX_TOUCHPOINTS from "../../../mock/pax/touchpoints.json";
import { IPAXTouchPointComponentState } from "../../../models/pax/IPassengerTouchPoint";
import {
  LineChartDatum,
  TouchPointChartDataArray,
} from "../../../store/pax-flow/contracts/contracts";
import {
  setOnRefreshIntervalChange,
  setOnTouchPointFilterClick,
  setOnTouchPointFilterClose,
  setOnTouchPointTimeRange,
  setPaxGraphAndTableData,
} from "../../../store/pax-flow/passengerFlowSlice";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { PassengerHelper } from "../../../utils/pax/PassengerHelper";
import {
  PAX_TOUCHPOINTS_ARRAY,
  PAX_TOUCHPOINTS_GRAPH_INFO,
  PAX_TOUCHPOINTS_REFRESH_INTERVAL,
} from "../../../utils/pax/PAXConstants";
import { AmdTabs } from "../../common/tabs/tabs";
import { filterChartDataByTouchPoint } from "./filterChartByTouchPoint";
import { getSelectedTouchPointFilterName } from "./getSelectedTouchPointFilterName";
import getUpdatedSlotTime from "./getUpdatedSlotTime";
import matchedTouchPoint from "./matchedTouchpoint";
import PAXTouchPointsFilterContainer from "./PAXTouchPointsFilterContainer";
import { PAXTouchPointsGraphAndTable } from "./PAXTouchPointsGraphAndTable";
import { PAXTouchPointsLegend } from "./PAXTouchPointsLegend";
import { PAXTouchPointsTimeRange } from "./PAXTouchPointsTimeRange";

export const PAXTouchPointsContainer: React.FunctionComponent = () => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const currentPaxFlow = useAppSelector((state) => state.paxFlow);
  const { paxFlow: paxFlowState } = currentPaxFlow;
  const { selectedTouchPointFilter, selectedTouchPoint } = paxFlowState;
  const selectedFilter = getSelectedTouchPointFilterName(
    selectedTouchPointFilter
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!paxFlowState?.selectedRefreshInterval) {
      return;
    }
    PassengerHelper.getPAXGraphAndTableData(
      msalInstance,
      paxFlowState.selectedRefreshInterval
    ).then((paxGraphTableResponse) => {
      const stateObj = { ...paxFlowState };
      const updatedStateObj =
        PassengerHelper.getUpdatedStateBasedOnGraphTableData(
          paxGraphTableResponse,
          stateObj,
          contentStyles.tableItemStyle
        );
      dispatch(setPaxGraphAndTableData(updatedStateObj));
    });
  }, [paxFlowState?.selectedRefreshInterval]);

  const onRefreshIntervalChange = (refreshInterval: string) => {
    const stateObj = { ...paxFlowState };
    stateObj.selectedRefreshInterval = refreshInterval;
    stateObj.selectedEndTime =
      PassengerHelper.getUpdatedPAXFlowTimeBasedOnInterval(
        stateObj.selectedStartTime,
        refreshInterval,
        true
      );
    dispatch(setOnRefreshIntervalChange(stateObj));
  };

  const onTouchPointFilterClose = (
    prevTouchPointChartDataArray: TouchPointChartDataArray[],
    e: React.MouseEvent<HTMLInputElement>
  ) => {
    e?.preventDefault();
    const stateObj = { ...paxFlowState };
    const updatedStateObj = {
      ...stateObj,
      touchPointChartDataArray: prevTouchPointChartDataArray,
      selectedTouchPoint: "",
      selectedTouchPointFilter: "",
      selectedTouchPointFilterLabel: "",
      selectedTouchPointFilterType: "",
      prevTouchPointChartDataArray: [],
      showTableRows: false,
    };
    dispatch(setOnTouchPointFilterClose(updatedStateObj));
  };
  const onTouchPointFilterClick = (
    selectedTouchPoint: string,
    selectedTouchPointFilter: string,
    selectedTouchPointFilterLabel: string,
    selectedTouchPointFilterType: string,
    touchPointChartDataArray: TouchPointChartDataArray[],
    e: React.MouseEvent<HTMLInputElement>
  ) => {
    e?.preventDefault();
    const stateObj = { ...paxFlowState };
    // caching previous array state
    const prevState: {
      chartTitle: string;
      lineChartData: LineChartDatum[];
    }[] = touchPointChartDataArray;

    const isSelectedTouchPoint =
      selectedTouchPoint === TouchPointName.AirportEntry;
    // public transport not integrated yet.
    const publicTransportFilterSelected =
      selectedTouchPointFilter === SelectedTouchPointFilterName.PublicTransport;

    const filteredTouchPoint = matchedTouchPoint(
      touchPointChartDataArray,
      selectedTouchPoint
    );

    const touchPointChartData =
      isSelectedTouchPoint && !publicTransportFilterSelected
        ? filterChartDataByTouchPoint(
            filteredTouchPoint,
            selectedTouchPointFilterType
          )
        : touchPointChartDataArray;

    const updatedStateObj = {
      ...stateObj,
      touchPointChartDataArray: touchPointChartData,
      selectedTouchPoint,
      selectedTouchPointFilter,
      selectedTouchPointFilterLabel,
      selectedTouchPointFilterType,
      prevTouchPointChartDataArray: prevState,
      showTableRows: true,
    };
    dispatch(setOnTouchPointFilterClick(updatedStateObj));
  };

  if (!paxFlowState?.touchPointChartDataArray?.length) {
    return null;
  }

  return (
    <div data-testid="pax-touchpoints-component">
      <div className="mb-4">
        <div className="mt-4 fs-18 fw-600">Passenger Flow</div>
        <div className="w-90 float-l d-flex-end">
          <div className="d-flex-end float-r">
            <span className="mt-1 mr-2 float-l">Refresh interval</span>
            <AmdTabs
              defaultSelectedTab="5"
              tabList={PAX_TOUCHPOINTS_REFRESH_INTERVAL}
              dataTestId="refresh-interval"
              onTabClick={onRefreshIntervalChange}
              selectedTab={paxFlowState.selectedRefreshInterval}
            />
          </div>
        </div>
        <PAXTouchPointsTimeRange
          state={paxFlowState}
          onStateUpdate={(updatedStateObj: IPAXTouchPointComponentState) => {
            dispatch(setOnTouchPointTimeRange(updatedStateObj));
          }}
        />
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            {PAX_TOUCHPOINTS?.data?.map((touchpoint) => (
              <PAXTouchPointsFilterContainer
                touchPoint={touchpoint}
                key={touchpoint.key}
                selectedTouchPoint={paxFlowState.selectedTouchPoint}
                selectedTouchPointFilter={paxFlowState.selectedTouchPointFilter}
                selectedTouchPointFilterLabel={
                  paxFlowState.selectedTouchPointFilterLabel
                }
                onFilterClick={(
                  selectedTouchPoint: string,
                  selectedTouchPointFilter: string,
                  selectedTouchPointFilterLabel: string,
                  selectedTouchPointFilterType: string,
                  e: React.MouseEvent<HTMLInputElement>
                ) =>
                  onTouchPointFilterClick(
                    selectedTouchPoint,
                    selectedTouchPointFilter,
                    selectedTouchPointFilterLabel,
                    selectedTouchPointFilterType,
                    paxFlowState.touchPointChartDataArray,
                    e
                  )
                }
                onRemoveFilterClick={(
                  e: React.MouseEvent<HTMLInputElement>
                ) => {
                  onTouchPointFilterClose(
                    paxFlowState.prevTouchPointChartDataArray,
                    e
                  );
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="float-r mr-2">
        <PAXTouchPointsLegend />
      </div>

      {PAX_TOUCHPOINTS_ARRAY.map((touchPointName: string, index: number) => {
        const {
          touchPointTableDataArray,
          touchPointTableColumnsArray,
          touchPointChartDataArray,
        } = paxFlowState;
        const touchPointData = touchPointTableDataArray[index]?.data;
        const touchPointColumns = touchPointTableColumnsArray[index];
        const touchPointChartData = touchPointChartDataArray[index];
        const updatedTouchPointColumns = touchPointColumns?.map((column) => {
          const isHeaderShowHide = true;
          const { key, name, maxWidth, minWidth, fieldName } = column;
          let updatedName = name;
          let updatedMaxWidth = maxWidth;
          let updatedMinWidth = minWidth;
          const className = `column-${name}`;
          if (name === "factors") {
            updatedName = "Factors";
            updatedMaxWidth = 149;
          }

          if (name !== "factors" && name !== "threshold") {
            updatedMaxWidth = 99;
            updatedMinWidth = 99;
            updatedName = getUpdatedSlotTime(name);
          }

          return {
            key: key,
            fieldName,
            name: updatedName,
            maxWidth: updatedMaxWidth,
            minWidth: updatedMinWidth,
            isHeaderShowHide,
            className,
          };
        });

        const filteredTouchPointColumns = updatedTouchPointColumns.filter(
          (col) => col.key !== "key"
        );

        const updatedTouchPointItems = touchPointData?.map((tpc) => {
          const isHeaderShowHide = true;
          const { name, maxWidth } = tpc;
          let updatedMaxWidth = maxWidth;
          if (name === "factors") {
            updatedMaxWidth = 0;
          }

          return {
            ...tpc,
            isHeaderShowHide,
            updatedMaxWidth,
          };
        });

        const isAirportEntryTouchPoint =
          selectedTouchPoint === TouchPointName.AirportEntry;
        const isParkingTouchPointFilter =
          selectedTouchPointFilter ===
          SelectedTouchPointFilterName.PublicTransport;
        const filteredTouchPointItems =
          isAirportEntryTouchPoint && !isParkingTouchPointFilter
            ? updatedTouchPointItems.filter(
                (rowItem) =>
                  rowItem.factors === selectedFilter ||
                  rowItem.factors === PAXRowEnum.QueueLength
              )
            : updatedTouchPointItems;

        const { chartLegendHeader, key } = PAX_TOUCHPOINTS_GRAPH_INFO[index];
        return (
          (paxFlowState.selectedTouchPoint === "" ||
            paxFlowState.selectedTouchPoint === touchPointName) && (
            <div key={CommonHelper.getGuid()} className="mb-4">
              <PAXTouchPointsGraphAndTable
                allowMultipleShapesForPoints={false}
                chartData={touchPointChartData}
                chartHeight={paxFlowState.height}
                chartWidth={paxFlowState.width}
                chartXAxisTickCount={10}
                chartYAxisTickCount={4}
                chartYAxisTitle={
                  PAX_TOUCHPOINTS_GRAPH_INFO[index].chartYAxisTitle
                }
                chartLegendHeader={chartLegendHeader}
                chartLegendKey={key}
                chartLegends={paxFlowState.touchPointChartLegendArray[index]}
                tableColumns={filteredTouchPointColumns}
                tableData={filteredTouchPointItems}
                currentTimeMinValue="2023-12-11T09:15:00.000Z"
                currentTimeMaxValue="2023-12-11T09:30:00.000Z"
              />
            </div>
          )
        );
      })}
    </div>
  );
};

const contentStyles = mergeStyleSets({
  barChartContainer: {
    width: "auto",
    whiteSpace: "nowrap",
    overflowX: "auto",
    scrollbarWidth: "thin",
  },
  barChartContainerTablet: {
    width: "auto",
    whiteSpace: "nowrap",
    overflowX: "auto",
  },
  tableItemStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
});
