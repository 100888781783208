import { PublicClientApplication } from "@azure/msal-browser";
import { EventManagementHelper } from "../../../utils/events";

export async function defaultGetWeatherDisruptionCards(
    msalInstance: PublicClientApplication,
    eventId: string,
    airportCode: string
  ) {
    const response = await EventManagementHelper.crisisDashboardKPIsAPIArray(
      msalInstance,
      eventId,
      airportCode
    );
    return response?.data;
  }