import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { useEffect, useState } from "react";
import { EventManagementHelper } from "../../../utils/events/EventManagementHelper";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../../common/icons/arrow/arrow";
import { ArrowDirection } from "../../common/icons/arrow/arrowDirection";
import { EventDetailsForm } from "./event-details/EventDetailsForm";
import { EventResponseTeam } from "./response-team/EventResponseTeam";

export interface ICreateEventProps {
  onCancelEvent?: any;
  onSaveEventResponseForm?: any;
  airport: string;
  eventType?: string;
}

export const CreateNewEvent: React.FunctionComponent<ICreateEventProps> = (
  props: ICreateEventProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [eventType, setEventType] = useState(props.eventType || "");
  const [eventTypeResponseTemplateData, setEventTypeResponseTemplateData] =
    useState<any>();

  useEffect(() => {
    let isMounted = true;
    if (eventType && props.airport !== "") {
      EventManagementHelper.getTemplateResponseTeam(
        msalInstance,
        eventType,
        props.airport
      )
        .then((response: any) => {
          if (isMounted && response && response.data) {
            setEventTypeResponseTemplateData(response.data);
          }
        })
        .catch((_error: any) => {
          console.log("error occured");
        });
    }

    return () => {
      isMounted = false;
    };
  }, [props.airport, eventType]);

  const onEventTypeChange = (eventType: string) => {
    setEventType(eventType);
  };

  const onCancelEvent = (errors: any) => {
    console.log(errors);
    props.onCancelEvent();
  };

  return (
    <div data-testid="createNewEvent">
      <AmdButton
        testId="cancelNewEvent"
        text="Back"
        className={`${contentStyles.cancelEventStyle}`}
        type={AmdButtonType.DEFAULT}
        onClick={onCancelEvent}
        prefixImageIconBeforeText={true}
        imageIcon={
          <AmdArrow
            direction={ArrowDirection.LEFT}
            className={contentStyles.eventHeaderStylesPlusIcon}
          />
        }
      />
      <h1 className="amd-heading1">NEW EVENT</h1>

      <div className="amd-section-shadow mb-3">
        <div id="eventDetailsContainer">
          <EventDetailsForm
            onEventTypeChange={onEventTypeChange}
            eventType={props.eventType}
          />
        </div>

        {eventType !== "" && (
          <div id="eventResponseContainer" className="mt-2">
            <div className="fc--gray900 mb-1 fs-21 fw-600">Response team</div>

            <div>
              These are the individuals and groups who will respond to the
              event. They are added to the team and channels for this event in
              MS Teams.
            </div>

            <EventResponseTeam
              isNewForm={true}
              isInEditMode={true}
              templateList={eventTypeResponseTemplateData}
              customList={[]}
              onSaveForm={props.onSaveEventResponseForm}
              onCancelForm={onCancelEvent}
              airport={props.airport}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const contentStyles = mergeStyleSets({
  eventListContainer: mergeStyles({
    height: "calc(100vh - 199px)",
    maxHeight: "calc(100vh - 199px)",
  }),
  cancelEventStyle: mergeStyles({
    width: "100px",
    height: "40px",
  }),
  eventHeaderStylesRoot: mergeStyles({
    float: "left",
    width: "100%",
  }),
  eventHeaderStylesHeader: mergeStyles({
    width: "80%",
    float: "left",
  }),
  eventHeaderStylesNewEvent: mergeStyles({
    width: "150px",
    float: "right",
  }),
  eventHeaderStylesPlusIcon: mergeStyles({
    paddingRight: "10px",
    marginTop: "2px",
  }),
});
