import axios from "axios";
import AIRPORTS_MOCK from "../../mock/admin/airports.json";
import ORGANIZATIONS_MOCK from "../../mock/admin/organizations/organizations-component.json";
import ROLES_COMPONENTS_MOCK from "../../mock/admin/roles/roles-components.json";
import {
  AIRPORT_CODE_PARAMETER,
  APIM_KEY,
  CONTENT_TYPE,
} from "../../utils/common/Constants";

export default class AdminService {
  public getTerminals = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    return axios.get(
      `${process.env.REACT_APP_API_ADMIN_TERMINALS?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      {
        headers,
      }
    );
  };

  public getOrganizations = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    return axios.get(
      `${process.env.REACT_APP_API_ADMIN_ORGANIZATIONS?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      {
        headers,
      }
    );
  };

  public getResponsibilities = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    return axios.get(
      `${process.env.REACT_APP_API_ADMIN_RESPONSIBILITIES?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      {
        headers,
      }
    );
  };

  public getRoles = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    return axios.get(
      `${process.env.REACT_APP_API_ADMIN_ROLES?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      {
        headers,
      }
    );
  };

  public getUsers = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    return axios.get(
      `${process.env.REACT_APP_API_ADMIN_USERS?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      {
        headers,
      }
    );
  };

  public getAirports = async (_accessToken: string): Promise<any> => {
    return Promise.resolve(AIRPORTS_MOCK);
  };

  public getThresholdByDimension = async (
    accessToken: string,
    dimension: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    return axios.get(
      `${process.env.REACT_APP_API_APPSETTINGS?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${dimension}`,
      {
        headers,
      }
    );
  };

  public saveThresholdByDimension = async (
    accessToken: string,
    dimension: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };

    return axios.put(
      `${process.env.REACT_APP_API_APPSETTINGS?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${dimension}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public getRolesForComponents = async (_accessToken: string): Promise<any> => {
    return Promise.resolve(ROLES_COMPONENTS_MOCK);
  };

  public getRoleComponentById = (
    _accessToken: string,
    roleId: string
  ): Promise<any> => {
    const selectedRole = ROLES_COMPONENTS_MOCK.data.find((roleItem: any) => {
      return roleItem.id === roleId;
    });

    return Promise.resolve(selectedRole);
  };
  public getOrganizationsForComponents = async (
    _accessToken: string
  ): Promise<any> => {
    return Promise.resolve(ORGANIZATIONS_MOCK);
  };

  public getOrganizationComponentById = (
    _accessToken: string,
    roleId: string
  ): Promise<any> => {
    const selectedOrganization = ORGANIZATIONS_MOCK.data.find(
      (organizationItem: any) => {
        return organizationItem.id === roleId;
      }
    );

    return Promise.resolve(selectedOrganization);
  };
}
