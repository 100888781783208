export enum TouchPointName {
  AirportEntry = "airportEntry",
  CheckIn = "checkIn",
  Boarding = "boarding",
  Security = "security",
}

const PAXEnum = {
  TouchPointName,
};

export enum PAXRowEnum {
  PassengerCount = "Passenger count",
  WaitTime = "Wait time",
  QueueLength = "Queue length",
  EffectiveCurbUtilisation = "Effective curb utilisation",
  EffectiveParkingUtilisation = "Effective parking utilisation",
  Undefined = "undefined",
}

export enum SelectedTouchPointFilterName {
  CurbsideDropoff = "effectiveCurbUtilisation",
  CarPark = "effectiveParkingUtilisation",
  PublicTransport = "publicTransport",
}

export default PAXEnum;
