import { Image } from "@fluentui/react";
import React from "react";
import HOPEnum from "../../../enum/HOPEnum";
import IFlightsPerformance, {
  IPerformance,
} from "../../../models/flights/IFlightsPerformance";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { OTPHelper } from "../../../utils/flights/OTPHelper";
import { AmdDonutChart } from "../../common/charts/DonutChart/DonutChart";
import { HOPAlertIcon } from "../../health-of-operations/HOPAlertIcon";

export interface IFlightsPerformanceItemProps {
  performanceItem: IFlightsPerformance;
  selectedTab: string;
}

export const FlightsPerformanceItem: React.FunctionComponent<
  IFlightsPerformanceItemProps
> = (props: IFlightsPerformanceItemProps) => {
  const isOTP = props.selectedTab === HOPEnum.HOPDIMENSIONS.OTP.toString();

  return (
    <React.Fragment key={CommonHelper.getGuid()}>
      <div
        className="d-flex w-100 ml-n2"
        data-testid={props.performanceItem.name}
      >
        <Image src={OTPHelper.getIcon(props.performanceItem.name)} />
        <span className="fs-14 fw-600 fc--gray600 ml-1">
          {props.performanceItem.label}
        </span>
      </div>
      <div className="ms-Grid-row amd-container-vcenter">
        <div className="ms-Grid-col ms-md4">
          <div className="amd-container-center">
            <AmdDonutChart
              resourceData={OTPHelper.getFlightPerformanceChartData(
                props.performanceItem
              )}
              donutContainerWidth="60px"
              donutContainerHeight="70px"
              noFlightsMessage="No Delayed Flights"
              hideLegend={true}
              hideValueInsideDonut={true}
              radius={15}
              width={40}
              height={50}
              testId={`${props.performanceItem.name}-donutChart`}
            />
            <div className="jc-spacebtwn amd-container-vcenter gap-5">
              <HOPAlertIcon
                status={props.performanceItem.performance[0].status}
              />
              <span className="fs-24 fc--gray900 fw-600">
                {" "}
                {props.performanceItem.performance[0].value}%
              </span>
            </div>
          </div>
        </div>
        <div className="ms-Grid-col ms-md9 ml-2">
          <div className={`amd-container-vflex amd-container-vflex--center`}>
            {props.performanceItem.performance.map((p: IPerformance) => {
              return (
                <React.Fragment key={`container-${p.key}`}>
                  {p.key !== "overall" && (
                    <div
                      className="d-flex ms-Grid-col ms-md12 px-0"
                      data-testid="performance-value"
                      key={p.key}
                    >
                      <div className="ms-md7 fs-14 fc--gray900 fw-400">
                        {CommonHelper.toTitleCase(p.key)}
                      </div>
                      <div className="ms-md5">
                        <div className="jc-spacebtwn amd-container-vcenter gap-5 ml-1">
                          {!isOTP && <HOPAlertIcon status={p.status} />}
                          <span className="fs-18 fc--gray900 fw-600">
                            {p.value}
                            {!isOTP && "%"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
