import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdCloudCover: React.FunctionComponent<IAmdIconProps> = ({
  width = "16",
  height = "12",
  color = "#9BCAEB",
  viewBox = "0 0 16 12",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3C11.4646 3 12.8627 4.5736 13.066 6.47399L13.1282 6.47399C14.7143 6.47399 16 7.71103 16 9.23699C16 10.763 14.7143 12 13.1282 12H4.87179C3.28575 12 2 10.763 2 9.23699C2 7.71103 3.28575 6.47399 4.87181 6.47399L4.93399 6.47399C5.13851 4.56111 6.53544 3 9 3ZM6.3919 0C7.84845 0 9.11779 0.82808 9.74489 2.0449C9.50438 2.01509 9.25589 2 9 2C6.60927 2 4.86815 3.30697 4.24647 5.28569L4.18416 5.50024L4.13789 5.68717L3.97261 5.7174C2.73074 5.97315 1.71272 6.83435 1.25733 7.9754C0.503238 7.51515 0 6.6845 0 5.73632C0 4.34376 1.08548 3.20472 2.45649 3.11916L2.67912 3.11401C2.99007 1.33919 4.53961 0 6.3919 0Z"
        fill={color}
      />
    </svg>
  );
};
