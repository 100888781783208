import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdHumidity: React.FunctionComponent<IAmdIconProps> = ({
  width = "12",
  height = "16",
  viewBox = "0 0 12 16",
  color = "#9BCAEB",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.64645 0.146447C5.84171 -0.0488156 6.15829 -0.0488156 6.35355 0.146447C6.88531 0.678205 8.15837 2.20981 9.29982 4.04882C10.4297 5.86917 11.5 8.09853 11.5 10C11.5 11.8438 10.972 13.3521 9.99053 14.4037C9.00585 15.4587 7.61476 16 6 16C4.38524 16 2.99415 15.4587 2.00947 14.4037C1.02801 13.3521 0.5 11.8438 0.5 10C0.5 8.09853 1.5703 5.86917 2.70018 4.04882C3.84163 2.20981 5.11469 0.678205 5.64645 0.146447Z"
        fill={color}
      />
    </svg>
  );
};
