import { makeStyles, Spinner } from "@fluentui/react-components";
import { AirplaneFilled } from "@fluentui/react-icons";
import React from "react";
import { $cloud100, $cloud30, $white } from "../../../../cssjs/colors";
import RESOURCE_DATA from "../../../../mock/overview/flight-card-donut-data.json";
import { DonutCard } from "./shared/DonutCard";

interface IProps {
  isCarousel?: boolean;
}

export const FlightCard: React.FunctionComponent<IProps> = ({
  isCarousel = false,
}): React.JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [flightCardData, _setFlightData] = React.useState<any>(RESOURCE_DATA);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const classes = useStyles();
  return (
    <>
      {loading ? (
        <Spinner
          labelPosition="below"
          label="Loading..."
          className={`${isCarousel ? classes.carouselCardLoader : classes.cardLoader}`}
        />
      ) : null}
      {!loading ? (
        <div className={`${classes.cardContainer} ${isCarousel ? classes.carouselCardContainer : classes.defaultCardContainer}`}>
          <DonutCard data={flightCardData} isCarousel={isCarousel}>
            <AirplaneFilled />
          </DonutCard>
        </div>
      ) : null}
    </>
  );
};
const useStyles = makeStyles({
  cardLoader: {
    height: "100%",
    background: $white,
  },
  carouselCardLoader: {
    height: "100%",
    padding: "16px 40px",
    background: $white,
  },
  cardContainer: {
    display: "grid",
    gridGap: "8px",
    gridTemplateColumns: "auto 1fr ",
    gridTemplateRows: "32px 0px auto",
    boxSizing: "border-box",
    background: $white,
    minHeight: "100%",
    color: $cloud100,
  },
  carouselCardContainer: {
    border: "none",
    borderRadius: "none",
    padding: "16px 40px 31px",
  },
  defaultCardContainer: {
    borderRadius: "8px",
    padding: "8px",
    border: `1px solid ${$cloud30}`,
  },
});
