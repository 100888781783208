import { keyframes, mergeStyleSets } from "@fluentui/react";

const slideFromRight = keyframes({
  "100%": {
    transform: "translateX(0%)",
    opacity: 1,
  },
});

const slideFromLeft = keyframes({
  "0%": {
    transform: "translateX(-100%)",
    opacity: 0,
  },
  "100%": {
    transform: "translateX(0%)",
    opacity: 1,
  },
});

export const getCarouselStyles = (height = "334px", width = "386px") =>
  mergeStyleSets({
    carousel: {
      width: `calc(${width} - 26px)`,
      height: `calc(${height} - 26px)`,
      padding: "5px 13px",
    },
    carouselBody: {
      height: "calc(100% - 12px)",
      position: "relative",
    },
    carouselControls: {
      height: 12,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    carouselItemActive: {
      opacity: "1 !important",
      transition: "opacity 0.5s ease-in !important",
      zIndex: 1,
    },
    carouselItem: {
      opacity: 0,
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      transition: "opacity 0.5s ease-in",
      display: "flex",
      justifyContent: "center",
    },
  });
