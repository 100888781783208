import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { WeatherDisruptionKPIEnum } from "../../../enum/EventManagementEnum";
import { IWeatherDisruptionSummaryItem } from "../../../models/events/IWeatherDisruptionSummary";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { getWeatherDisruptionState } from "../../../store/weather-disruption/weatherDisruptionSelector";
import {
  setDisruptionKpi,
  setGraphicalData,
  setPlanningData
} from "../../../store/weather-disruption/WeatherDisruptionSlice";
import DateHelper from "../../../utils/common/DateHelper";
import { sliceArray } from "../../../utils/common/sliceArray";
import { EventManagementHelper } from "../../../utils/events/EventManagementHelper";
import { getGraphicalData } from "./getGraphicalData";
import { mappedResourceType } from "./mappedResourceType";
import { WeatherDisruptionCardType } from "./types";
import { UpdateKpiPopup } from "./UpdateKpiPopup";
import { WeatherDisruptionCard } from "./weatherDisruptionCard";
import WeatherDisruptionGraph from "./WeatherDisruptionGraph";

export const WeatherDisruptionContainer: React.FunctionComponent<any> = ({
  getFormattedGraphData,
  airport,
  eventId,
}) => {
  const dispatch = useAppDispatch();
  const weatherDisruptionState = useAppSelector((state: any) =>
    getWeatherDisruptionState(state)
  );

  const {
    crisisWeatherDisruptionKPICard,
    selectedKpiId,
    weatherDisruptionCards,
  } = weatherDisruptionState;

  const isDeicingCapacity =
    selectedKpiId === WeatherDisruptionKPIEnum.DeicingCapacity;
  const isAircraftOnGround =
    selectedKpiId === WeatherDisruptionKPIEnum.AircraftOnGround;
  const isPassengerOnGround =
    selectedKpiId === WeatherDisruptionKPIEnum.PassengerOnGround;
  const isBaggage = selectedKpiId === WeatherDisruptionKPIEnum.Baggage;
  const isDepartureCapacity =
    selectedKpiId === WeatherDisruptionKPIEnum.DepartureCapacity;
  const showFirstRowGraph =
    isDeicingCapacity || isAircraftOnGround || isPassengerOnGround;
  const showSecondRowGraph = isBaggage || isDepartureCapacity;
  const [showGraph, setShowGraph] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const msalInstance = useMsal().instance as PublicClientApplication;

  React.useEffect(() => {
    if (showGraph && crisisWeatherDisruptionKPICard) {
      getFormattedGraphData(crisisWeatherDisruptionKPICard);
    }
  }, [showGraph, crisisWeatherDisruptionKPICard]);

  const date = DateHelper.getCurrentDateTimeForAirport(airport);
  const dateFormat = DateHelper.formatDateTime(date, "YYYY-MM-DD");

  const getPlanningData = React.useCallback(
    async (airport: string, resourceType: string, dateFormat: string) => {
      const airportCode = airport;
      try {
        const { data: selectedPlanningData } =
          await EventManagementHelper.getDisruptionPlanningKpi(
            msalInstance,
            airportCode,
            resourceType,
            dateFormat
          );
        if (selectedPlanningData) {
          dispatch(
            setPlanningData({
              selectedPlanningData,
            })
          );
        }
      } catch (error) {
        console.log("graphical api error", error);
      }
    },
    []
  );

  const selectedKPIInfo = (item: WeatherDisruptionCardType): void => {
    const selectedId = item.id;
    const selectedTitle = item.kpi;
    dispatch(
      setDisruptionKpi({
        selectedKpiId: selectedId,
        selectedKpi: selectedTitle,
      })
    );
  };

  const clearPaxState = (): void => {
    dispatch(
      setDisruptionKpi({
        selectedKpiId: "",
        selectedKpi: "",
        selectedDisruptionKPI: {
          currentTimeMaxValue: "",
          currentTimeMinValue: "",
          weatherDisruptionChartData: null,
          weatherDisruptionChartLegendArray: [],
        },
        selectedGraphData: [],
      })
    );
  };

  const showGraphHandler = async (cardItem: WeatherDisruptionCardType) => {
    if (!showGraph) {
      clearPaxState();
    }
    if (cardItem?.id) {
      const selectedCard = {
        ...cardItem,
      };
      const { id: selectedId } = selectedCard;
      selectedKPIInfo(selectedCard);
      const resourceType = mappedResourceType(selectedId);
      const response = await getGraphicalData(
        msalInstance,
        airport,
        resourceType,
        dateFormat
      );
      if (response) {
        dispatch(
          setGraphicalData({
            selectedGraphData: [...response],
          })
        );
      }
    }
    setShowGraph((visible) => !visible);
  };
  const hideGraphHandler = () => {
    clearPaxState();
    setShowGraph((visible) => !visible);
  };

  const showUpdatePopup = (cardItem: WeatherDisruptionCardType) => {
    const selectedCard = {
      ...cardItem,
    };
    const { id: selectedId } = selectedCard;
    selectedKPIInfo(selectedCard);
    const resourceType = mappedResourceType(selectedId);
    getPlanningData(airport, resourceType, dateFormat);
    setShowModal((visible) => !visible);
  };

  const onKPIUpdate = (kpiId: string, _data: any) => {
    console.log("kpi id onKPI Update is:", kpiId);
  };

  const firstRow = sliceArray(weatherDisruptionCards, 0, 3);
  const secondRow = sliceArray(weatherDisruptionCards, 3);
  const displayFirstRowGraph = showFirstRowGraph;
  const displaySecondRowGraph = showGraph && showSecondRowGraph;

  return (
    <>
      <div data-testid="weather-disruption-kpi-container">
        {firstRow?.length
          ? firstRow?.map((card: IWeatherDisruptionSummaryItem) => {
              return (
                <div
                  className="ms-Grid-col ms-sm4"
                  key={card.id}
                  data-testid="weather-disruption-card-firstrow"
                >
                  <WeatherDisruptionCard
                    card={card}
                    selectedCardId={selectedKpiId}
                    onShowGraph={showGraphHandler}
                    onHideGraph={hideGraphHandler}
                    onShowPopup={showUpdatePopup}
                  />
                </div>
              );
            })
          : null}
        {displayFirstRowGraph && <WeatherDisruptionGraph />}
        {secondRow?.length
          ? secondRow.map((card: IWeatherDisruptionSummaryItem) => {
              return (
                <div
                  className="ms-Grid-col ms-sm4"
                  key={card.id}
                  data-testid="weather-disruption-card-secondrow"
                >
                  <WeatherDisruptionCard
                    card={card}
                    onShowGraph={showGraphHandler}
                    onHideGraph={hideGraphHandler}
                    onShowPopup={showUpdatePopup}
                  />
                </div>
              );
            })
          : null}
        {displaySecondRowGraph && <WeatherDisruptionGraph />}
        <UpdateKpiPopup
          hideDialog={!showModal}
          onModalPopupClose={setShowModal}
          onKpiUpdate={onKPIUpdate}
          airport={airport}
          eventId={eventId}
        ></UpdateKpiPopup>
      </div>
    </>
  );
};
