import { TimeseriesDatum } from "../../components/events/crisis-dashboard/types";
import { UpdateKpiPopUpType } from "./deDupKPIObjects";

export function formatKPIPayloadData(
  data: UpdateKpiPopUpType[]
): TimeseriesDatum[] | {}[] {
  const result = data?.map((tmpArr) => {
    let newCapacityObj = {};
    let newDemandObj = {};
    let newActualObj = {};
    let newObj = {};
    if (tmpArr.type === "Capacity") {
      newCapacityObj = {
        ...newObj,
        time: tmpArr.time,
        capacity: tmpArr.value,
        unit: tmpArr.unit,
      };
    }
    if (tmpArr.type === "Actual") {
      newActualObj = {
        ...newObj,
        time: tmpArr.time,
        actual: tmpArr.value,
        unit: tmpArr.unit,
      };
    }
    if (tmpArr.type === "Demand") {
      newDemandObj = {
        ...newObj,
        time: tmpArr.time,
        demand: tmpArr.value,
        unit: tmpArr.unit,
      };
    }
    newObj = {
      ...newCapacityObj,
      ...newDemandObj,
      ...newActualObj,
    };

    return newObj;
  });
  return [...result];
}
