enum EventItemTabs {
  EventDetails = "details",
  Tasks = "tasks",
  Response = "response_team",
  Channel = "teams_channel",
  CrisisDashboard = "crisis_dashboard",
}

enum EventItemChannelTabs {
  ChannelList = "channel_list",
  ChannelManagement = "channel_management",
}

enum CreateEvent {
  EventName = "eventName",
  EventDescription = "eventDescription",
  TypeOfEvent = "typeOfEvent",
  IncidentSeverity = "incidentSeverity",
  CallerName = "callerName",
  CallerContactNumber = "callerNumber",
  DateofReport = "dateOfReport",
  TimeofReport = "timeOfReport",
  ReportedDate = "reportedDate",
  ReportedTime = "reportedTime",
  LoggedDate = "loggedDate",
  LoggedTime = "loggedTime",
  ClosedDate = "closedDate",
  ClosedTime = "closedTime",
}

enum EventStatus {
  Active = "active",
  Closed = "closed",
}

enum CreateChannel {
  ChannelName = "channelName",
}

enum Channels {
  Name = "name",
  ResponseTeam = "responseTeam",
  CreatedBy = "createdBy",
}

enum EventSummaryItemType {
  ByStatus = "ByStatus",
  ActiveByType = "ActiveByType",
}

enum EventChannelViewType {
  Add = "add",
  Edit = "edit",
  View = "view",
  Delete = "delete",
  List = "list",
}

enum EventChannelType {
  Automatic = "automatic",
  Adhoc = "adhoc",
}

enum ResponseTeamResourceType {
  User = "user",
  Group = "group",
}

enum ResponseTeamResourceAction {
  Add = "add",
  Delete = "delete",
}

export enum DisruptionName {
  DeicingCapacity = "deIcing",
  AircraftOnGround = "aircraft_on_ground",
  PassengerOnGround = "passenger_On_Ground",
  DepartureCapacity = "departureSlots",
  Baggage = "baggage",
  Weather = "weather",
  Blank = "",
}

export enum WeatherDisruptionKPIEnum {
  DeicingCapacity = "deIcingCapacity",
  AircraftOnGround = "aircraftOnGround",
  PassengerOnGround = "passengersOnGround",
  DepartureCapacity = "departureCapacity",
  Baggage = "baggage",
  Weather = "weather",
}

enum Share {
  Channel = "channels",
  Kpi = "kpis",
  subject = "subject",
  message = "message",
}

const EventManagementEnum = {
  EventStatus,
  EventItemTabs,
  EventItemChannelTabs,
  CreateEvent,
  Channels,
  CreateChannel,
  EventSummaryItemType,
  EventChannelViewType,
  EventChannelType,
  ResponseTeamResourceType,
  ResponseTeamResourceAction,
  DisruptionName,
  Share,
};

export default EventManagementEnum;
