import axios from "axios";
import {
  AIRPORT_CODE_PARAMETER,
  DATE_PARAMETER,
} from "../../utils/common/Constants";
import DateHelper from "../../utils/common/DateHelper";

export default class FlightSummaryService {
  public getFlightSummary = async (
    accessToken: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    const dateStr = DateHelper.getDefaultDateStrForAirport(airportCode);

    return axios.get(
      `${process.env.REACT_APP_API_FLIGHTSUMMARY?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      ).replace(DATE_PARAMETER, dateStr)}`,
      { headers }
    );
  };
}
