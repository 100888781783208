import React from "react";

export const AmdBaggage: React.FunctionComponent = () => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 0C3.33579 0 3 0.335786 3 0.75C3 1.16421 3.33579 1.5 3.75 1.5H4V3H3.25C1.45507 3 0 4.45507 0 6.25V15.25C0 16.602 0.825524 17.7611 2 18.2509V19.25C2 19.6642 2.33579 20 2.75 20C3.16421 20 3.5 19.6642 3.5 19.25V18.5H10.5V19.25C10.5 19.6642 10.8358 20 11.25 20C11.6642 20 12 19.6642 12 19.25V18.2509C13.1745 17.7611 14 16.602 14 15.25V6.25C14 4.45507 12.5449 3 10.75 3H10V1.5H10.25C10.6642 1.5 11 1.16421 11 0.75C11 0.335786 10.6642 0 10.25 0H3.75ZM8.5 1.5V3H5.5V1.5H8.5ZM3 7.75C3 7.33579 3.33579 7 3.75 7H10.25C10.6642 7 11 7.33579 11 7.75C11 8.16421 10.6642 8.5 10.25 8.5H3.75C3.33579 8.5 3 8.16421 3 7.75Z"
        fill="#005EB8"
      />
    </svg>
  );
};
