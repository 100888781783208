import React from "react";

export const PersonWalkingLuggageIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.4375 1.34375C9.4375 0.683594 9.9707 0.125 10.6562 0.125C11.3164 0.125 11.875 0.683594 11.875 1.34375C11.875 2.0293 11.3164 2.5625 10.6562 2.5625C9.9707 2.5625 9.4375 2.0293 9.4375 1.34375ZM8.21875 5.35547C7.83789 5.5332 7.5332 5.83789 7.38086 6.24414L7.33008 6.32031C7.17773 6.75195 6.69531 6.95508 6.28906 6.77734C5.85742 6.625 5.6543 6.14258 5.83203 5.73633L5.85742 5.63477C6.1875 4.84766 6.79688 4.21289 7.55859 3.85742L7.83789 3.73047C8.37109 3.50195 8.92969 3.375 9.51367 3.375C10.6562 3.375 11.6719 4.06055 12.1035 5.10156L12.4844 6.04102L13.043 6.32031C13.4492 6.52344 13.6016 7.00586 13.3984 7.41211C13.1953 7.81836 12.7129 7.9707 12.3066 7.76758L11.6465 7.4375C11.3672 7.31055 11.1641 7.08203 11.0625 6.80273L10.8086 6.21875L10.3262 7.89453L11.5703 9.26562C11.7227 9.41797 11.8242 9.5957 11.875 9.79883L12.459 12.1348C12.5605 12.5664 12.2812 12.998 11.8496 13.0996C11.418 13.2266 10.9863 12.9473 10.8594 12.5156L10.3008 10.2812L8.52344 8.32617C8.14258 7.91992 7.99023 7.33594 8.14258 6.80273L8.57422 5.20312C8.54883 5.20312 8.52344 5.22852 8.49805 5.22852L8.21875 5.35547ZM7.91406 8.88477L8.95508 10.002L8.57422 10.916C8.52344 11.0684 8.42188 11.2207 8.32031 11.3223L6.74609 12.8965C6.44141 13.2266 5.9082 13.2266 5.60352 12.8965C5.27344 12.5918 5.27344 12.0586 5.60352 11.7539L7.10156 10.2305L7.73633 8.65625C7.78711 8.73242 7.86328 8.80859 7.91406 8.88477ZM6.16211 7.10742C6.36523 7.20898 6.44141 7.46289 6.31445 7.64062L5.22266 9.57031C5.19727 9.64648 5.17188 9.72266 5.12109 9.77344L3.49609 12.5918C3.29297 12.9727 2.78516 13.125 2.4043 12.8965L0.982422 12.084C0.601562 11.8555 0.474609 11.3477 0.677734 10.9668L2.30273 8.14844C2.53125 7.76758 3.03906 7.64062 3.41992 7.84375L4.81641 8.65625L5.62891 7.23438C5.73047 7.05664 5.98438 6.98047 6.16211 7.10742Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};
