import ChartHelper from "../../utils/common/ChartHelper";
import { AmdDonutChart } from "../common/charts/DonutChart/DonutChart";
import { NoDataCard } from "../common/no-data-card/NoDataCard";

export const PassengerKPISection: React.FunctionComponent<
  IPassengerKPISection
> = ({ resourceData, emptyStateMessage }) => {
  return resourceData ? (
    <div className="ms-Grid-row" data-testid="passenger-kpi-section">
      {resourceData && (
        <>
          {Object.keys(resourceData)?.map((key: string, index: number) => {
            return (
              <div
                key={key}
                className={`ms-Grid-col ms-md4 ${
                  index !== 0 ? "divider-v-grey" : ""
                }`}
              >
                <AmdDonutChart
                  resourceData={resourceData[key]}
                  noFlightsMessage={`No ${resourceData[key][0].resourceName} Pax found`}
                  hideLegend={true}
                  donutContainerWidth="160px"
                  legendData={ChartHelper.getLegendData(resourceData[key])}
                  showCustomLegend={true}
                  title={`${resourceData[key][0].resourceName} Pax`}
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  ) : (
    <NoDataCard message={emptyStateMessage} />
  );
};
export interface IPassengerKPISection {
  resourceData: any;
  emptyStateMessage: string;
}
