import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdCloud: React.FunctionComponent<IAmdIconProps> = ({
  width = "22",
  height = "18",
  viewBox = "0 0 22 18",
  color = "#005EB8",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0023 5.00689C15.1708 5.00689 16.9683 7.10418 17.2297 9.63702L17.3096 9.63701C19.3487 9.63701 21.0017 11.2857 21.0017 13.3195C21.0017 15.3533 19.3487 17.0021 17.3096 17.0021H6.69492C4.65584 17.0021 3.00284 15.3533 3.00284 13.3195C3.00284 11.2857 4.65584 9.63701 6.69494 9.63701L6.77488 9.63702C7.03782 7.08753 8.83375 5.00689 12.0023 5.00689ZM3.36791 9.16233C3.51321 9.51312 3.37262 9.911 3.05341 10.097L2.96201 10.1422L2.03574 10.5259C1.65306 10.6844 1.21433 10.5027 1.05582 10.12C0.910519 9.76923 1.05111 9.37135 1.37032 9.18537L1.46172 9.1401L2.38798 8.75643C2.77067 8.59792 3.20939 8.77964 3.36791 9.16233ZM9.8549 4.32912L9.68332 4.3867C7.89893 5.01707 6.62092 6.39215 6.06838 8.20966L5.99755 8.46021L5.93986 8.69804L5.73377 8.7365C5.12548 8.86432 4.56026 9.11102 4.06373 9.45148C3.97491 9.30131 3.89572 9.14182 3.82665 8.97507C2.9936 6.96391 3.94864 4.65823 5.9598 3.82518C7.3021 3.26918 8.77559 3.5097 9.8549 4.32912ZM1.94116 4.3602L2.04666 4.3951L2.97293 4.77877C3.35561 4.93729 3.53734 5.37601 3.37883 5.7587C3.23472 6.10659 2.85904 6.2884 2.50441 6.19949L2.3989 6.16459L1.47264 5.78092C1.08996 5.62241 0.908229 5.18368 1.06674 4.801C1.21084 4.4531 1.58653 4.27129 1.94116 4.3602ZM5.75638 1.37378L5.80165 1.46518L6.18533 2.39145C6.34384 2.77413 6.16211 3.21286 5.77943 3.37137C5.42864 3.51667 5.03075 3.37608 4.84478 3.05688L4.79951 2.96547L4.41584 2.03921C4.25732 1.65652 4.43905 1.2178 4.82173 1.05928C5.17253 0.913981 5.57041 1.05457 5.75638 1.37378ZM10.1097 1.05527C10.4576 1.19937 10.6394 1.57506 10.5505 1.92969L10.5156 2.03519L10.1319 2.96146C9.97344 3.34414 9.53471 3.52587 9.15203 3.36736C8.80413 3.22326 8.62232 2.84757 8.71123 2.49294L8.74613 2.38744L9.1298 1.46117C9.28831 1.07849 9.72704 0.89676 10.1097 1.05527Z"
        fill={color}
      />
    </svg>
  );
};
