export enum MESSAGEBAR_INTENT_TYPE {
    CRITICAL = "CRITICAL",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
    INFORMATION = "INFO",
  }

export enum MessageBarIntentType {
    INFO = "info",
    ERROR = "error",
    WARNING = "warning",
    SUCCESS = "success",
  }

export enum MessageBarType {
    error = 0,
    warning = 1,
    info = 2,
  }
