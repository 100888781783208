import { makeStyles } from "@fluentui/react-components";
import * as React from "react";
import { CommonHelper } from "../../../utils/common/CommonHelper";

export const MultiProgressBar: React.FunctionComponent<IProgressBar> = (
  props: IProgressBar
) => {
  const styles = useStyles();
  const hideBarValLimit = 6;

  //Calculate the Progress Bar width for all three divisions
  const progressBarWidth: number[] | undefined =
    CommonHelper.getMultiProgressBarWidth(
      props?.barDivisionValue1,
      props?.barDivisionValue2,
      props?.unit
    );
  const startBarColor: string = props[props.format[0] as keyof IProgressBar];
  const endBarColor: string = props[props.format[1] as keyof typeof props];

  return (
    <div className={styles.container} data-testid="multi-progress-bar">
      <div
        data-testid={`${props.format[0]}-bar-width`}
        style={{
          width: `${progressBarWidth[0]}%`,
        }}
      >
        <span>{`0${props.unit}`}</span>

        <div className="progressbar-border">
          <div
            data-testid="poor-background"
            style={{ height: "2px", backgroundColor: startBarColor }}
          ></div>
        </div>
      </div>
      <div
        data-testid="moderate-bar-width"
        style={{
          width: `${progressBarWidth[1]}%`,
        }}
      >
        <span>{`${props?.barDivisionValue1}${props.unit}`}</span>
        <div className="progressbar-border">
          <div
            data-testid="moderate-background"
            style={{ height: "2px", backgroundColor: props?.moderate }}
          ></div>
        </div>
      </div>
      <div
        data-testid="good-bar-width"
        style={{
          width: `${progressBarWidth[2]}%`,
        }}
      >
        <div className="amd-container-hspacebtwn">
          <span>{`${props?.barDivisionValue2}${props.unit}`}</span>
          {
            //Hide 100% label if the width of last threshold bar is less than 6%
            progressBarWidth[2] > hideBarValLimit && props.unit === "%" && (
              <span>{`100${props.unit}`}</span>
            )
          }
        </div>
        <div
          className="progressbar-border"
          style={{
            borderRight: "1px solid #000",
          }}
        >
          <div
            data-testid="good-background"
            style={{ height: "2px", backgroundColor: endBarColor }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    rowGap: "20px",
  },
});

export interface IProgressBar {
  [key: string]: any;
  poor: string;
  moderate: string;
  good: string;
  barDivisionValue1: number;
  barDivisionValue2: number;
  unit: string;
  format: string[];
}
