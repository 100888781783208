import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdAirplane: React.FunctionComponent<IAmdIconProps> = ({
  width = "20",
  height = "20",
  viewBox = "0 0 20 20",
  color = "#005EB8",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9892 9.94583C19.9892 11.0456 19.0976 11.9371 17.9979 11.9371C17.9785 11.9371 17.9591 11.9368 17.9396 11.9363L13.2008 11.7967L9.74752 18.9393C9.46975 19.5139 8.88779 19.8789 8.24959 19.8789C7.73817 19.8789 7.32358 19.4644 7.32358 18.9529C7.32358 18.9161 7.32578 18.8793 7.33016 18.8427L8.19235 11.6501L4.42681 11.5401L3.93678 12.8808C3.75094 13.3891 3.26734 13.7272 2.72609 13.7272C2.22834 13.7272 1.82484 13.3237 1.82484 12.826L1.82555 11.3493L1.01479 11.1805C0.430134 11.0585 0.0112305 10.5431 0.0112305 9.94583C0.0112305 9.34868 0.430311 8.83351 1.01496 8.71195L1.82555 8.5432L1.82484 7.06568C1.82484 6.60623 2.16865 6.22707 2.61304 6.17146L2.72609 6.16444C3.26734 6.16444 3.75094 6.50255 3.93678 7.0109L4.42789 8.35131L8.17941 8.24135L7.33016 1.15686C7.32797 1.13857 7.32632 1.12022 7.32523 1.10184L7.32358 1.04662C7.32358 0.535195 7.73817 0.120605 8.24959 0.120605C8.83461 0.120605 9.37236 0.427354 9.67189 0.921051L9.74752 1.06024L13.1502 8.09582L17.9397 7.95565C19.0388 7.92336 19.956 8.78823 19.9883 9.88738L19.9892 9.94583Z"
        fill={color}
      />
    </svg>
  );
};
