import { MultiStackedBarChart } from "@fluentui/react-charting";
import { makeStyles } from "@fluentui/react-components";
import React from "react";
import {
  $cloud100,
  $cloud30,
  $crimson60,
  $forest70,
  $sky60,
  $white,
} from "../../../../cssjs/colors";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import RESOURCE_DATA from "../../../../mock/overview/resource-utilisation-card-data.json";
import { UtilisationCardIcon } from "../../../common/icons/resourcesUtilisation/UtilisationCardIcon";
import { TerminalIcon } from "../../../common/icons/terminal/TerminalIcon";
const cardMinHeight =  "174px";

export const ResourcesUtilisationCard: React.FunctionComponent =
  (): React.JSX.Element => {
    const [resourceCardData, _setResourceData] = React.useState<any>(
      RESOURCE_DATA?.data
    );

    const isTabletPortrait = useMediaQuery("(max-width: 1024px)");

    const resourceLabels =
      RESOURCE_DATA?.data
        .map((item) => item.data)[0]
        ?.map((filteredItem) => filteredItem?.chartTitle) ?? [];
    const hideRatio: boolean[] = [false, false];

    const classes = useStyles();
    return (
        <div className={`${classes.cardContainer}`}>
          <UtilisationCardIcon />
          <div className={`${classes.gridItem}`}>
            <div className="fw-500">Resources utilisation</div>
            <div className={`ms-fontSize-10`}>
              <span className={`${classes.cardSubTitleItalic}`}>
                last refreshed
              </span>
              <span className="fw-600">@ 10:43 (4 mins ago)</span>
            </div>
          </div>
          <div
            className={`${classes.gridItem} ${
              isTabletPortrait ? classes.displayNone : undefined
            }`}
          >
            <div className={`fs-45`}>
              <div
                className={`fs-12 fw-500 amd-container-vcenter ${classes.cardLegendContainer} pt-2`}
              >
                <div className={`pb-0 ${classes.cardLegendBulletContainer}`}>
                  <span
                    className={`${classes.cardlegendSquare} ${classes.cardlegendSquareInOperation}`}
                  />
                  <span>In-Operation</span>
                </div>
                <div
                  className={`amd-container-vcenter pb-0 ${classes.cardLegendBulletContainer}`}
                >
                  <span
                    className={`${classes.cardlegendSquare} ${classes.cardlegendSquareAvailable}`}
                  />
                  <span>Available</span>
                </div>
                <div className={`pb-0`}>
                  <span
                    className={`${classes.cardlegendSquare} ${classes.cardlegendSquareInOperable}`}
                  />
                  <span>In-Operable</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${classes.gridItem} ${classes.resourcesContainer}`}>
            <ul className={`ms-fontSize-10 ${classes.resourceLabels}`}>
              {resourceLabels?.map((label: string) => (
                <li key={label}>{label}</li>
              ))}
            </ul>
            {resourceCardData?.map((terminal: any, index: number) => {
              const cardItemKey = `terminal_item_${terminal.key}`;
              return (
                <div
                  className={`fs-20 ${
                    index !== 4 && !isTabletPortrait
                      ? classes.customLineStyle
                      : undefined
                  } ${
                    isTabletPortrait && index > 0
                      ? classes.displayNone
                      : undefined
                  } ${index === 0 ? classes.customLineStyleLeft : undefined}`}
                  key={cardItemKey}
                >
                  <div className={`${classes.terminalIcon}`}>
                    <TerminalIcon />
                    <span className="fs-14">{terminal.name}</span>
                  </div>
                  <div className={classes.chartContainer}>
                    <div
                      className={`ms-fontSize-10 ${classes.cardLegendPercentContainer}`}
                    >
                      <div className="">
                        <span
                          className={`${classes.cardlegendPercentSquare}`}
                        />
                        <span>32 %</span>
                      </div>
                      <div className="">
                        <span
                          className={`${classes.cardlegendPercentSquare}`}
                        />
                        <span>17 %</span>
                      </div>
                      <div className="">
                        <span
                          className={`${classes.cardlegendPercentSquare}`}
                        />
                        <span>62 %</span>
                      </div>
                      <div className="">
                        <span
                          className={`${classes.cardlegendPercentSquare}`}
                        />
                        <span>23 %</span>
                      </div>
                      <div className="">
                        <span
                          className={`${classes.cardlegendPercentSquare}`}
                        />
                        <span>67 %</span>
                      </div>
                      <div className="">
                        <span
                          className={`${classes.cardlegendPercentSquare}`}
                        />
                        <span>46 %</span>
                      </div>
                    </div>

                    <MultiStackedBarChart
                      data={terminal.data}
                      hideLabels={true}
                      hideRatio={hideRatio}
                      hideLegend={true}
                      hideTooltip={true}
                      styles={{
                        singleChartRoot: {
                          marginBottom: "4px",
                        },
                        opacityChangeOnHover: {
                          stroke: "none",
                        },
                        chartTitle: {
                          display: "none",
                        },
                      }}
                      barHeight={6}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
    );
  };

const useStyles = makeStyles({
  gridItem: {},
  cardContainer: {
    display: "grid",
    gridGap: "4px",
    gridTemplateColumns: "auto 1fr auto",
    borderRadius: "8px",
    boxSizing: "border-box",
    background: $white,
    padding: "8px",
    border: `1px solid ${$cloud30}`,
    minHeight: "100%",
    color: $cloud100,
  },
  card: {
    background: $white,
    borderRadius: "8px",
    minHeight: cardMinHeight,
  },
  cardSubTitleItalic: {
    fontStyle: "italic",
  },
  cardLegendContainer: {
    display: "grid",
    gridAutoFlow: "column",
    alignItems: "center",
  },
  cardLegendBulletContainer: {
    paddingRight: "20px",
  },
  cardlegendSquare: {
    width: "10px",
    height: "10px",
    marginRight: "4px",
    marginleft: "4px",
    display: "inline-block",
    borderRadius: "2px",
  },
  cardlegendSquareInOperation: {
    background: $forest70,
  },
  cardlegendSquareAvailable: {
    background: $sky60,
  },
  cardlegendSquareInOperable: {
    background: $crimson60,
  },
  customLineStyle: {
    borderRight: `2px solid ${$cloud30}`,
    maxHeight: "110px",
  },
  customLineStyleLeft: {
    borderLeft: `2px solid ${$cloud30}`,
    maxHeight: "110px",
    marginLeft: "20px",
  },
  displayNone: {
    display: "none",
  },
  cardLegendPercentContainer: {
    flexDirection: "column",
    lineHeight: "1.6",
    width: "auto",
    paddingRight: "0px",
    marginTop: "-5px",
    paddingLeft: "5px",
  },
  cardlegendPercentSquare: {
    width: "5px",
    height: "5px",
    marginRight: "4px",
    display: "inline-block",
    background: $forest70,
  },
  resourcesContainer: {
    gridColumn: "1 / -1",
    display: "grid",
    gridAutoColumns: "auto 1fr 1fr 1fr 1fr 1fr",
    gridAutoFlow: "column",
  },
  chartContainer: {
    display: "grid",
    gridGap: "4px",
    gridAutoColumns: "auto auto",
    gridAutoFlow: "column",
    padding: "0 8px",
  },
  resourceLabels: {
    margin: "0px",
    padding: "0px",
    listStyle: "none",
    lineHeight: "1.6",
    display: "grid",
    alignItems: "end",
    paddingBottom: "5px",
    alignContent: "end",
  },
  terminalIcon: {
    paddingLeft: "12px",
  },
});
