enum PageUrl {
  Home = "home",
  Flights360 = "flights360",
  Baggage = "baggage",
  Passenger = "pax",
  ResourceAllocation = "resource-allocation",
  Weather = "weather",
  EventManagement = "event-management",
  ApplicationSettings = "application-settings",
  Notifications = "notifications",
  Admin = "admin",
  MultiAirports = "multi-airports",
  Overview = "overview",
}

const PageEnum = {
  PageUrl,
};

export default PageEnum;
