import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdPressure: React.FunctionComponent<IAmdIconProps> = ({
  width = "48",
  height = "48",
  color = "#9BCAEB",
  viewBox = "0 0 48 48",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.9992" cy="36" r="4.8" fill={color} />
      <circle cx="23.9992" cy="36" r="4.8" fill={color} />
      <circle cx="35.9992" cy="36" r="4.8" fill={color} />
      <circle cx="11.9996" cy="21.6" r="2.4" fill={color} />
      <circle cx="23.9996" cy="21.6" r="2.4" fill={color} />
      <circle cx="35.9996" cy="21.6" r="2.4" fill={color} />
      <circle opacity="0.3" cx="11.9996" cy="9.59995" r="2.4" fill={color} />
      <circle opacity="0.3" cx="23.9996" cy="9.59995" r="2.4" fill={color} />
      <circle opacity="0.3" cx="35.9996" cy="9.59995" r="2.4" fill={color} />
    </svg>
  );
};
