import { IComboBoxStyles, TimePicker } from "@fluentui/react";
import React from "react";

interface IAmdTimePickerProps {
  label: string;
  styles?: Partial<IComboBoxStyles>;
  useHour12: boolean;
  isMandatory: boolean;
  defaultValue?: Date;
  isReadOnly?: boolean;
  onChange?: any;
}

export const AmdTimePicker: React.FunctionComponent<IAmdTimePickerProps> = (
  props: IAmdTimePickerProps
) => {
  return (
    <TimePicker
      required={props.isMandatory}
      styles={props.styles ?? undefined}
      useHour12={props.useHour12}
      allowFreeform
      autoComplete="on"
      label={props.label}
      useComboBoxAsMenuWidth
      defaultValue={props.defaultValue ?? undefined}
      dateAnchor={props.defaultValue ?? undefined}
      value={props.defaultValue ?? undefined}
      disabled={props.isReadOnly ?? false}
      onChange={props.onChange}
    />
  );
};
