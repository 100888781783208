import FlightsEnum from "../../enum/FlightsEnum";
import PageEnum from "../../enum/PageEnum";
import ResourceEnum from "../../enum/ResourceEnum";

export const FLIGHT_COLORS = {
  total: ["#009DD1", "#004485"],
  delayed: ["#6F2B8D", "#CE0058"],
  cancelled: ["#9E6900", "#F7A827"],
  otp: ["#CE0058", "#008540"],
};

export const EMPTY_STATE_MESSAGE = {
  Resource: "No resource allocation data found",
  Passenger: "No passenger data found",
  Flights: "No flights found",
};

export const TABLET_MODE = "(orientation: landscape)";

export const HOUR_FILTERS = {
  data: [
    { text: "1 HR", columnName: "schedule", columnValue: "1" },
    { text: "2 HRS", columnName: "schedule", columnValue: "2" },
    { text: "6 HRS", columnName: "schedule", columnValue: "6" },
    { text: "12 HRS", columnName: "schedule", columnValue: "12" },
    { text: "24 HRS", columnName: "schedule", columnValue: "24" },
  ],
};

export const DEFAULT_AIRPORT = "XYZ";
export const DEFAULT_START_DATE = "2023-02-08T10:00:00";
export const AIRPORT_CODE_PARAMETER = "{airportCode}";
export const DATE_PARAMETER = "{date}";
export const TIME_PARAMETER = "{time}";
export const RESOURCE_TYPE_PARAMETER = "{resourceType}";
export const HOP_COLORS = {
  current: "#005EB8",
  forecast: "#009DD1",
};

export const HOP_RANGE_COLORS = {
  poor: "#C60000",
  moderate: "#F7A827",
  good: "#008540",
};

export const TERMINAL_TABS = {
  data: [
    { text: "TC", value: "C" },
    { text: "T2", value: "2" },
    { text: "T3", value: "3" },
  ],
};

const minWidth20 = 20;
const minWidth40 = 40;
const minWidth50 = 50;
const minWidth60 = 60;
const minWidth90 = 90;
const minWidth120 = 120;

const maxWidth30 = 30;
const maxWidth70 = 70;
const maxWidth60 = 60;
const maxWidth90 = 90;
const maxWidth94 = 94;
const maxWidth100 = 100;
const maxWidth134 = 134;
const maxWidth104 = 104;
const maxWidth110 = 110;
const maxWidth114 = 114;
const maxWidth180 = 180;
const maxWidth204 = 204;
const maxWidth224 = 224;


export const TEAMS_ENTITYMAPPING_ARRAY = [
  { name: "HOME", entityId: "index", url: `/${PageEnum.PageUrl.Home}` },
  {
    name: "FLIGHTS360",
    entityId: "flights360",
    url: `/${PageEnum.PageUrl.Flights360}`,
  },
  { name: "BAGGAGE", entityId: "baggage", url: `/${PageEnum.PageUrl.Baggage}` },
  { name: "PAX", entityId: "pax", url: `/${PageEnum.PageUrl.Passenger}` },
  { name: "WEATHER", entityId: "weather", url: `/${PageEnum.PageUrl.Weather}` },
  {
    name: "RESOURCE_ALLOCATION",
    entityId: "resource-allocation",
    url: `/${PageEnum.PageUrl.ResourceAllocation}`,
  },
  { name: "ADMIN", entityId: "admin", url: `/${PageEnum.PageUrl.Admin}` },
  {
    name: "EVENT_MANAGEMENT",
    entityId: "event-management",
    url: `/${PageEnum.PageUrl.EventManagement}`,
  },
  {
    name: "NOTIFICATION",
    entityId: "notifications",
    url: `/${PageEnum.PageUrl.Notifications}`,
  },
];

export const CONTENT_TYPE = "application/json;charset=UTF-8";

export const APIM_KEY = process.env.REACT_APP_APIM_KEY;

export const PAX_CHART_SERIES = [
  { key: "booked", text: "Booked" },
  //{ key: "actual", text: "Boarded" },
  //{ key: "transfer", text: "Transfer" },
];

export const HOP_OTP_DIMENSIONS = [
  "slotAdherence",
  "turnaroundSufficiency",
  "runwayDelay",
];

export const NOTIFICATION_FILTER_PILLS = [
  {
    text: "All",
    name: "All",
    value: "all",
  },
  {
    text: "Weather",
    name: "Weather",
    value: "weather",
  },
  {
    text: "Flights 360",
    name: "Flights 360",
    value: "flights360",
  },
  {
    text: "Resources",
    name: "Resources",
    value: "resources",
  },
  {
    text: "Event Management",
    name: "Event Management",
    value: "eventManagement",
  },
];

export const SEVERITY_DROPDOWN_OPTIONS = [
  { text: "All", key: "all" },
  { text: "Critical", key: "Critical" },
  { text: "High", key: "High" },
  { text: "Medium", key: "Medium" },
  { text: "Low", key: "Low" },
];
export const AIRPORT_SORT_DROPDOWN_OPTIONS = [
  {
    text: "On-Time performance low to high",
    key: "On-Timeperformancelowtohigh",
  },
  {
    text: "On-Time performance high to low",
    key: "On-Timeperformancehightolow",
  },
];
export const KPI_DROPDOWN_OPTIONS = [
  { text: "All", key: "all" },
  { text: "Turnaround Sufficiency", key: "TurnaroundSufficiency" },
  { text: "Runway Capacity", key: "RunwayCapacity" },
  { text: "Slot Adherence", key: "SlotAdherence" },
  { text: "Load Factor", key: "LoadFactor" },
  { text: "Passenger Flow", key: "PassengerFlow" },
  { text: "Baggage", key: "Baggage" },
  { text: "Building Facilities", key: "BuildingFacilities" },
  { text: "Other assets", key: "Otherassets" },
];
export const USER_COLUMNS = [
  {
    name: "Name",
    fieldName: "name",
    minWidth: minWidth90,
    maxWidth: minWidth120,
    dataType: "string",
    isRowHeader: true,
  },
  {
    name: "Email",
    fieldName: "email",
    minWidth: minWidth90,
    maxWidth: minWidth120,
    dataType: "string",
    isRowHeader: true,
  },
  {
    name: "Role",
    fieldName: "role",
    minWidth: minWidth90,
    maxWidth: minWidth120,
    dataType: "string",
    isRowHeader: true,
  },
  {
    name: "Organization",
    fieldName: "organization",
    minWidth: minWidth90,
    maxWidth: maxWidth180,
    dataType: "string",
    isRowHeader: true,
  },
  {
    name: "Responsible Area",
    fieldName: "responsibleArea",
    minWidth: minWidth90,
    maxWidth: minWidth120,
    dataType: "string",
    isRowHeader: true,
  },
  {
    name: "Terminal",
    fieldName: "userTerminal",
    minWidth: minWidth40,
    maxWidth: maxWidth70,
    dataType: "string",
    isRowHeader: true,
  },
  {
    name: "",
    fieldName: "edit",
    minWidth: minWidth20,
    maxWidth: maxWidth30,
    dataType: "string",
    isRowHeader: false,
  },
  {
    name: "",
    fieldName: "delete",
    minWidth: minWidth20,
    maxWidth: maxWidth30,
    dataType: "string",
    isRowHeader: false,
  },
];

export const MESSAGEBAR_TYPE = {
  SUCCESS: "Success",
  ERROR: "Error",
  INFORMATION: "Information",
  WARNING: "Warning",
};

const actualTimeText = "Actual Time";
const isResizable = true;

export const FLIGHTLIST_COLUMNS = [
  {
    name: "Flight",
    fieldName: "flightType",
    minWidth: minWidth20,
    maxWidth: maxWidth60,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.All.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Delayed",
    fieldName: "delayDuration",
    minWidth: minWidth60,
    maxWidth: maxWidth100,
    dataType: "number",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [FlightsEnum.ViewType.OTP.toString()],
  },
  {
    name: "Booked Pax",
    fieldName: "bookedPaxCount",
    minWidth: minWidth50,
    maxWidth: maxWidth104,
    dataType: "number",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [ResourceEnum.ResourceType.Passenger.toString()],
  },
  {
    name: "Flight No",
    fieldName: "flightNumber",
    minWidth: minWidth60,
    maxWidth: maxWidth90,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [FlightsEnum.ViewType.OTP.toString()],
  },
  {
    name: "Flight Type",
    fieldName: "trafficType",
    minWidth: minWidth60,
    maxWidth: maxWidth100,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [FlightsEnum.ViewType.OTP.toString()],
  },
  {
    name: "Schedule",
    fieldName: "schedule",
    minWidth: minWidth60,
    maxWidth: maxWidth94,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.All.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Schedule",
    fieldName: "sibt",
    minWidth: minWidth60,
    maxWidth: maxWidth94,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Schedule",
    fieldName: "sobt",
    minWidth: minWidth60,
    maxWidth: maxWidth94,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Estimated Time",
    fieldName: "estimated",
    minWidth: minWidth60,
    maxWidth: maxWidth134,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.All.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Estimated Time of Arrival",
    fieldName: "eibt",
    minWidth: minWidth60,
    maxWidth: maxWidth224,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Estimated Time of Departure",
    fieldName: "eobt",
    minWidth: minWidth60,
    maxWidth: maxWidth224,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: actualTimeText,
    fieldName: "actual",
    minWidth: minWidth40,
    maxWidth: maxWidth114,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.All.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: actualTimeText,
    fieldName: "aibt",
    minWidth: minWidth40,
    maxWidth: maxWidth204,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
    viewType: [FlightsEnum.ViewType.OTP.toString()],
  },
  {
    name: "Actual Time of Arrival",
    fieldName: "aibt",
    minWidth: minWidth60,
    maxWidth: maxWidth204,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Arrival.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: actualTimeText,
    fieldName: "aobt",
    minWidth: minWidth40,
    maxWidth: maxWidth70,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
    viewType: [FlightsEnum.ViewType.OTP.toString()],
  },
  {
    name: "Actual Time of Departure",
    fieldName: "eobt",
    minWidth: minWidth60,
    maxWidth: maxWidth204,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [FlightsEnum.LegType.Departure.toString()],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Origin",
    fieldName: "origin",
    minWidth: minWidth40,
    maxWidth: maxWidth94,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Destination",
    fieldName: "destination",
    minWidth: minWidth60,
    maxWidth: maxWidth114,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Terminal",
    fieldName: "terminal",
    minWidth: minWidth50,
    maxWidth: maxWidth114,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Gate",
    fieldName: "gate",
    minWidth: minWidth40,
    maxWidth: maxWidth60,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [FlightsEnum.ViewType.Flight.toString()],
  },
  {
    name: "Gate",
    fieldName: "gate",
    minWidth: minWidth40,
    maxWidth: maxWidth60,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Arrival.toString(),
      FlightsEnum.LegType.Departure.toString(),
    ],
    viewType: [FlightsEnum.ViewType.OTP.toString()],
  },
  {
    name: "Airlines",
    fieldName: "airline",
    minWidth: minWidth40,
    maxWidth: maxWidth104,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      FlightsEnum.ViewType.OTP.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Flight No",
    fieldName: "flightNumber",
    minWidth: minWidth60,
    maxWidth: maxWidth134,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Status",
    fieldName: "status",
    minWidth: minWidth90,
    maxWidth: maxWidth134,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [
      FlightsEnum.ViewType.Flight.toString(),
      ResourceEnum.ResourceType.Passenger.toString(),
    ],
  },
  {
    name: "Delay Reason",
    fieldName: "delayReasons",
    minWidth: minWidth90,
    maxWidth: maxWidth110,
    dataType: "string",
    isRowHeader: true,
    isResizable,
    legType: [
      FlightsEnum.LegType.All.toString(),
      FlightsEnum.LegType.Departure.toString(),
      FlightsEnum.LegType.Arrival.toString(),
    ],
    viewType: [FlightsEnum.ViewType.OTP.toString()],
  },
];

export const ADMIN_TABS = {
  data: [
    { text: "Users", value: "users" },
    { text: "Organizations", value: "organizations" },
    { text: "Roles", value: "roles" },
    { text: "Customers", value: "customers" },
  ],
};
