import { AmdTabs } from "../../common/tabs/tabs";

export const FlightTypeFilter = ({
  onChange,
  selectedType
}: {
  onChange(tabName: string): any;
  selectedType: string;
}) => {
  const onTabSelect = (data: string) => {
    onChange(data);
  };
  return (
    <AmdTabs
      defaultSelectedTab="arrival;departure"
      tabList={[
        { value: "arrival;departure", text: "ALL" },
        { value: "arrival", text: "ARRIVAL" },
        { value: "departure", text: "DEPARTURES" },
      ]}
      dataTestId="flight-type"
      onTabClick={onTabSelect}
      selectedTab={selectedType}
    />
  );
};
