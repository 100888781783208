import { PublicClientApplication } from "@azure/msal-browser";
import { APIHelper } from "../api/APIHelper";
import WeatherService from "../../services/weather/weather.service";
export async function getCurrentWeatherSummary(
  msalInstance: PublicClientApplication,
  airportCode: string
): Promise<any> {
  const weatherService = new WeatherService();
  const getCurrentWeatherSummaryMethod = (accessToken: string) => {
    return weatherService.getCurrentWeatherSummary(accessToken, airportCode);
  };

  return APIHelper.CallAPI(
    msalInstance,
    null,
    getCurrentWeatherSummaryMethod
  );
}