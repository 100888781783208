import React from "react";
import { AmdAmberSmall } from "../icons/amber/AmberSmall";
import { makeStyles } from "@fluentui/react-components";
const useClasses = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    paddingRight: "5px",
  },
});

export default function Crowded({
  fieldContent,
}: {
  fieldContent: string;
}): React.JSX.Element {
  const classes = useClasses();
  return (
    <div className={classes.root}>
      <span className={classes.icon}>
        <AmdAmberSmall />
      </span>
      <span>{fieldContent}</span>
    </div>
  );
}
