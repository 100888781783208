import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import WeatherEnum from "../../../enum/WeatherEnum";
import { IRunwayWind } from "../../../models/weather/IRunwayWind";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { WeatherHelper } from "../../../utils/weather/WeatherHelper";
import Card from "../../common/card/card";
import { RunwayWindCard } from "./RunwayWindCard";

export interface IExtendedRunwayProps {
  airport: string;
}
export const ExtendedRunway: React.FunctionComponent<IExtendedRunwayProps> = ({
  airport,
}) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [runwayWindDetails, setRunwayWindDetails] = useState<IRunwayWind[]>();

  useEffect(() => {
    let isMounted = true;
    if (airport !== "") {
      WeatherHelper.getRunwayWindDetails(msalInstance, airport).then(
        (response) => {
          if (isMounted && response && response.data) {
            setRunwayWindDetails(response.data);
          }
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [airport]);

  return (
    <>
      <h1 className="amd-heading1">Runway</h1>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div
            className="ms-Grid-col ms-md12 amd-grid-col2 amd-grid--gap px-0 ms-hiddenXxlUp"
            data-testid="extended-runway"
          >
            {runwayWindDetails?.map((r: any) => {
              return (
                <Card
                  className="mb-2"
                  height={330}
                  width={"auto"}
                  key={CommonHelper.getGuid()}
                  headerType="default_header"
                  header={<span>{r.runway}</span>}
                >
                  <RunwayWindCard
                    viewType={WeatherEnum.RunwayViewType.Extended}
                    runwayWindDetails={r}
                    orientation="vertical"
                    size="small"
                  />
                </Card>
              );
            })}
          </div>
          <div
            className="ms-Grid-col ms-md12 amd-grid-col2 amd-grid--gap px-0 ms-hiddenXlDown"
            data-testid="extended-runway-tablet"
          >
            {runwayWindDetails?.map((r: any) => {
              return (
                <Card
                  className="mb-2"
                  height={377}
                  width={"auto"}
                  key={CommonHelper.getGuid()}
                  headerType="default_header"
                  header={<span>{r.runway}</span>}
                >
                  <RunwayWindCard
                    viewType={WeatherEnum.RunwayViewType.Extended}
                    runwayWindDetails={r}
                    orientation="vertical"
                    size="big"
                  />
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
