import { mergeStyleSets } from "@fluentui/react";

export const getTableStyles = (
  tableType = "stripped_columns",
  tableWidth = "280px",
  tableLayout = "default"
) =>
  mergeStyleSets({
    table: {
      borderSpacing: 0,
      tableLayout: tableLayout === "fixed" ? "fixed" : "auto",
      width: tableWidth,
    },
    headerCell: {
      fontSize: 13,
      lineHeight: 16,
      color: "#4d4d4d",
      padding: "10px 11.5px",
      textAlign: tableType === "stripped_columns" ? "center" : "left",
      selectors: {
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:nth-child(odd):not(:first-child)": {
          background: tableType === "stripped_columns" ? "#f2f2f2" : "#ffffff",
        },
      },
    },
    cell: {
      fontSize: 13,
      padding: 5,
      textAlign: tableType === "stripped_columns" ? "center" : "left",
      fontWeight: 600,
      selectors: {
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:nth-child(odd):not(:first-child)": {
          background: tableType === "stripped_columns" ? "#f2f2f2" : "inherit",
        },
      },
    },
    row: {
      height: tableType === "stripped_rows" ? 50 : "auto",
      selectors: {
        "&:nth-child(even)": {
          background: tableType === "stripped_rows" ? "#f2f2f2" : "inherit",
        },
      },
    },
  });
