import { mergeStyleSets } from "@fluentui/react";
import moment from "moment-timezone";
import NotificationEnum from "../../enum/NotificationEnum";
import INotificationListItem from "../../models/notification/INotificationListItem";
import { CommonHelper } from "../../utils/common/CommonHelper";
import { NotificationHelper } from "../../utils/notification/NotificationHelper";
import { AmdAmberLarge } from "../common/icons/amber-large/AmberLarge";
import { NotificationIcon } from "./NotificationIcon";

export const NotificationList: React.FunctionComponent<
  INotificationListProps
> = (props: INotificationListProps) => {
  return (
    <>
      <ul className="amd-list overflow-scroll" data-testid="notification-list">
        {props.notificationItems?.map((n: INotificationListItem) => {
          return (
            <li
              className={`amd-list__item ${CommonHelper.getSelectedListClassName(
                n.id,
                props.selectedNotificationId
              )}`}
              style={{
                boxShadow: NotificationHelper.getReadClass(n.read),
                cursor: "pointer",
              }}
              key={n.id}
              onClick={(_e) => {
                n.read = true;
                props.onSelectNotification(n);
              }}
              data-testid={`item_${n.id}`}
            >
              <div className="amd-container-hstart px-2">
                <div className="amd-container-vspacebtwn ai-center">
                  <NotificationIcon category={n.category} />
                  {n.severity === NotificationEnum.Severity.Critical && (
                    <AmdAmberLarge dataTestId={`alert_${n.id}`} />
                  )}
                </div>
                <div className={`ml-1 ${contentStyles.container}`}>
                  <span
                    className={`fw-600 fc--gray900 ${contentStyles.contentWrap} kit-2v`}
                  >
                    {n.title}
                  </span>
                  <span
                    className={`fw-400 fc--gray600 ${contentStyles.contentWrap} kit-2v`}
                  >
                    {n.body}
                  </span>
                  <span className="fw-400 fc--gray600">
                    {moment(n.timestamp).fromNow()}
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export interface INotificationListProps {
  notificationItems: INotificationListItem[];
  onSelectNotification: any;
  selectedNotificationId?: string;
}

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "80%",
  },
  contentWrap: {
    minHeight: "25px",
    overflow: "hidden",
    marginBottom: 4,
  },
  timeContent: {
    width: "12%",
    textAlign: "right",
  },
  bottomStyles: {
    paddingBottom: "3rem",
    borderBottom: "1px solid #cccccc",
  },
});
