import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import IFlightSummary from "../../../models/flights/IFlightSummary";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { FlightSummaryHelper } from "../../../utils/flights/FlightSummaryHelper";
import { AmdAirplane } from "../../common/icons/airplane/airplane";
import { AmdOptions } from "../../common/icons/options/Options";

export interface IFlightScheduleSummaryCardProps {
  airport: string;
}

export const FlightScheduleSummaryCard: React.FunctionComponent<
  IFlightScheduleSummaryCardProps
> = ({ airport }) => {
  const msalInstance = useMsal().instance as PublicClientApplication;

  const [summaryData, setSummaryData] = useState<IFlightSummary[]>([]);
  const [flightSummary, setFlightSummary] = useState<any>();

  const setFlightTotalSummary = (data: IFlightSummary[]) => {
    setFlightSummary(FlightSummaryHelper.getFlightTotalSummary(data));
  };

  useEffect(() => {
    let isMounted = true;
    if (airport !== "") {
      FlightSummaryHelper.getFlightSummary(msalInstance, airport).then(
        (response: any) => {
          if (isMounted && response && response.data.data) {
            setSummaryData(response.data.data);
          }
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [airport]);

  useEffect(() => {
    if (summaryData?.length > 0) {
      setFlightTotalSummary(summaryData);
    }
  }, [summaryData]);

  return (
    <>
      <div
        className="flight-summary-card-container"
        onClick={() => CommonHelper.handleRedirects("FLIGHTS360")}
      >
        {flightSummary && (
          <>
            <div className="amd-section--br-b">
              <div className="amd-container-hspacebtwn pa-sm-2">
                <AmdAirplane />
                <span className="fs-14 fw-600 fc--gray800">
                  Flights Schedule
                </span>
                <AmdOptions />
              </div>
              <div
                className="amd-container-haround ai-center"
                style={{ height: 140 }}
              >
                {Object.keys(flightSummary)?.map((key: string) => {
                  return (
                    <div className="text-c" key={key}>
                      <div
                        className={`fs-26 fw-700 ${flightSummary[key].color} mb-sm-1`}
                      >
                        {flightSummary[key].count}
                      </div>
                      <label
                        className={`d-block fs-14 ${flightSummary[key].color}`}
                      >
                        {CommonHelper.toTitleCase(key)}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
