import React from "react";

export const BarsIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="bars-icon"
    >
      <path
        d="M15.625 3.375H2.375C2.15625 3.375 2 3.21875 2 3V2C2 1.8125 2.15625 1.625 2.375 1.625H15.625C15.8125 1.625 16 1.8125 16 2V3C16 3.21875 15.8125 3.375 15.625 3.375ZM15.625 8.375H2.375C2.15625 8.375 2 8.21875 2 8V7C2 6.8125 2.15625 6.625 2.375 6.625H15.625C15.8125 6.625 16 6.8125 16 7V8C16 8.21875 15.8125 8.375 15.625 8.375ZM15.625 13.375H2.375C2.15625 13.375 2 13.2188 2 13V12C2 11.8125 2.15625 11.625 2.375 11.625H15.625C15.8125 11.625 16 11.8125 16 12V13C16 13.2188 15.8125 13.375 15.625 13.375Z"
        fill="currentColor"
      />
    </svg>
  );
};
