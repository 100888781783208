/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import IFilters from "../../models/filters/IFilters";

const initialState = {
  flightScheduleFilters: [] as IFilters[],
};

const flightsFilterSlice = createSlice({
  name: "flightScheduleFilters",
  initialState,
  reducers: {
    setFlightScheduleFilters(state, action) {
      localStorage.setItem(
        `${action.payload.airport}flightScheduleFilters`,
        JSON.stringify(action.payload.filter)
      );
      state.flightScheduleFilters = action.payload.filter;
    },
  },
});

export const flightsFilterActions = flightsFilterSlice.actions;

export default flightsFilterSlice.reducer;
