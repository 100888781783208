import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";
import { PassengerKPI } from "./PassengerKPI";
import { PassengerMacroCard } from "./PassengerMacroCard";
import { PAXTouchPointsContainer } from "./touchpoints/PAXTouchPointsComponent";

export const PAX: React.FunctionComponent = () => {
  const airport = useAppSelector((state) => getAirportCode(state));

  return (
    <>
      <NotificationBanner airport={airport} />
      <h1 className="amd-heading1">Passengers</h1>
      <PassengerKPI airport={airport} />
      <section className="amd-section-shadow my-4">
        <PassengerMacroCard isDetailsPage={true} airport={airport} />
        <PAXTouchPointsContainer />
      </section>
    </>
  );
};
