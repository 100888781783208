import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../StoreHooks";

const selectOtpFlightFilters = (state: RootState) => state.otpFlightFilters?.otpFlightFilters || [];

// Memoized selector
export const getMemoizedOTPFlightFilters = createSelector(
  [selectOtpFlightFilters],
  (otpFlightFilters) => {
    // Perform a transformation to ensure memoization
    return [...otpFlightFilters];
  }
);