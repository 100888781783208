import { IChartProps, LineChart } from "@fluentui/react-charting";
import ChartHelper from "../../../utils/common/ChartHelper";

export interface CustomLineChartProps {
  chartData: any[];
  onDataPointClick?: any;
  resourceType: string;
  xAxisTickCount?: number;
  customDateTimeFormatter?: any;
  hideLegend?: boolean;
  onLegendClick?: any;
}

export const CustomLineChart: React.FunctionComponent<CustomLineChartProps> = (
  props: CustomLineChartProps
) => {
  const data: IChartProps = {
    chartTitle: "Line Chart",
    lineChartData: ChartHelper.getLineChartData(
      props.chartData,
      props.resourceType,
      props.onLegendClick
    ),
  };

  ChartHelper.setDataPointClick(data, props);
  const margins = { top: 40, bottom: 35, left: 40, right: 30 };

  return (
    <div data-testid="lineChart" className="lineChart">
      {props.chartData.length > 0 && (
        <LineChart
          culture={window.navigator.language}
          data={data}
          height={200}
          margins={margins}
          xAxisTickCount={props.xAxisTickCount}
          customDateTimeFormatter={props.customDateTimeFormatter}
          hideLegend={props.hideLegend ? props.hideLegend : false}
        />
      )}
    </div>
  );
};
