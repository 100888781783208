import React from "react";

export const AmdRotateDevice: React.FunctionComponent = () => {
  return (
    <svg
      width="136"
      height="136"
      viewBox="0 0 136 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="136" height="136" fill="white" />
      <path
        d="M15 111H72.2381L72.247 114C72.247 114.001 72.247 114.001 72.247 114.002C72.2466 115.673 71.5134 117.444 70.319 118.8C69.1241 120.157 67.5598 121 65.9698 121H20.9698C19.3896 121 17.9089 120.167 16.7933 118.823C15.676 117.476 15 115.699 15 114V111ZM72.2321 109H15V25H71.9817L72.2321 109ZM15 23V21.0938C15 17.7267 17.6486 15 20.9698 15H65.9698C69.3138 15 71.9698 17.656 71.9698 21L71.9698 21.003L71.9757 23H15Z"
        stroke="#005EB8"
        strokeWidth="2"
      />
      <path
        d="M96.9242 53.4322C97.1195 53.6275 97.436 53.6275 97.6313 53.4322L100.813 50.2502C101.009 50.055 101.009 49.7384 100.813 49.5431C100.618 49.3479 100.301 49.3479 100.106 49.5431L97.2777 52.3715L94.4493 49.5431C94.2541 49.3479 93.9375 49.3479 93.7422 49.5431C93.547 49.7384 93.547 50.055 93.7422 50.2502L96.9242 53.4322ZM77.8768 34.1777H90.2229V33.1777H77.8768V34.1777ZM96.7777 40.7326V53.0786H97.7777V40.7326H96.7777ZM90.2229 34.1777C93.843 34.1777 96.7777 37.1125 96.7777 40.7326H97.7777C97.7777 36.5602 94.3953 33.1777 90.2229 33.1777V34.1777Z"
        fill="#005EB8"
      />
      <path
        d="M79 63H115C118.866 63 122 66.134 122 70V115C122 118.866 118.866 122 115 122H79V63Z"
        fill="#DFF0FA"
      />
      <path
        d="M112 121L112 120L112 65L112 64L115 64C118.344 64 121 66.656 121 70L121 115C121 118.344 118.344 121 115 121L112 121Z"
        stroke="#005EB8"
        strokeWidth="2"
      />
    </svg>
  );
};
