import { makeStyles, mergeClasses } from "@fluentui/react-components";
import { AlertBadgeRegular, MailRegular } from "@fluentui/react-icons";
import * as React from "react";

interface IAirportNotificationsCardProps {
  className?: string;
  notificationData: any;
  isMapView?: boolean;
}

const AirportNotificationsCard: React.FunctionComponent<
  IAirportNotificationsCardProps
> = ({ className, notificationData, isMapView }) => {
  const classes = useStyles();
  const containerClass = isMapView ? classes.containerMapView : undefined;
  return (
    <div
    className={`${mergeClasses(className, classes.container)} ${containerClass}`}
      data-testid="airport-notification-card"
    >

      <header className={classes.title}>Notifications</header>
      <div className="d-flex">
        <div className="flex-1">
          <header className="fs-20 pb-sm-1">
            <AlertBadgeRegular
              className={classes.icon}
              data-testid="notification-alert-icon"
            />
            <span>{`${notificationData.alert}`}</span>
          </header>
          <footer>Alerts</footer>
        </div>
        {!isMapView ? (
          <div className="flex-1">
            <header className="fs-20 pb-sm-1">
              <MailRegular
                className={classes.icon}
                data-testid="notification-mail-icon"
              />
              <span>{`${notificationData.others}`}</span>
            </header>
            <footer>Others</footer>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AirportNotificationsCard;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    paddingLeft: "6px 12px",
  },
  containerMapView: {
    padding: "0",
  },
  title: { color: "#666666", fontWeight: 500 },
  icon: {
    fontSize: "18px",
    strokeWidth: "0.5px",
    verticalAlign: "bottom",
    paddingRight: "4px",
    color: "#0C66E1",
    stroke: "#0C66E1",
  },
});
