import PAXEnum from "../../enum/PAXEnum";

export const PAX_TOUCHPOINTS_REFRESH_INTERVAL = [
  { value: "5", text: "5 mins" },
  { value: "10", text: "10 mins" },
  { value: "15", text: "15 mins" },
  { value: "30", text: "30 mins" },
  { value: "45", text: "45 mins" },
  { value: "60", text: "1 hr" },
];

export const PAX_TOUCHPOINTS_ARRAY = [
  // Important! Keep the order same as how we receive data from API
  PAXEnum.TouchPointName.AirportEntry,
  PAXEnum.TouchPointName.CheckIn,
  PAXEnum.TouchPointName.Security,
  PAXEnum.TouchPointName.Boarding,
];

const PassengerCountTitle = "Passenger count";
export const PAX_TOUCHPOINTS_GRAPH_INFO = [
  {
    chartYAxisTitle: "Effective utilisation",
    chartLegendHeader: "Airport Entry",
    key: "airportEntry",
  },
  {
    chartYAxisTitle: PassengerCountTitle,
    chartLegendHeader: "Check-in",
    key: "checkIn",
  },
  {
    chartYAxisTitle: PassengerCountTitle,
    chartLegendHeader: "Security",
    key: "security",
  },
  {
    chartYAxisTitle: PassengerCountTitle,
    chartLegendHeader: "Boarding",
    key: "boarding",
  },
];
