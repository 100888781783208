import NotificationEnum from "../../enum/NotificationEnum";
import { AmdEventManagement } from "../common/icons/notification/EventManagement";
import { AmdFlightTransparent } from "../common/icons/notification/FlightTransparent";
import { AmdWeather } from "../common/icons/notification/Weather";

export const NotificationIcon: React.FunctionComponent<
  INotificationIconProps
> = (props: INotificationIconProps) => {
  return (
    <>
      {props.category.toLowerCase() ===
        NotificationEnum.NotificationType.Flight && <AmdFlightTransparent />}

      {props.category.toLowerCase() ===
        NotificationEnum.NotificationType.Weather && <AmdWeather />}

      {props.category.toLowerCase() ===
        NotificationEnum.NotificationType.EventManagement && (
        <AmdEventManagement />
      )}

      {props.category.toLowerCase() ===
        NotificationEnum.NotificationType.Resource && <AmdFlightTransparent />}
    </>
  );
};

export interface INotificationIconProps {
  category: string;
}
