import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdTemperature: React.FunctionComponent<IAmdIconProps> = ({
  width = "20",
  height = "37",
  viewBox = "0 0 20 37",
  color = "#1890F1",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3C7.92893 3 6.25 4.67893 6.25 6.75V21.9566L5.7664 22.4022C4.52463 23.5463 3.75 25.1816 3.75 27C3.75 30.4518 6.54822 33.25 10 33.25C13.4518 33.25 16.25 30.4518 16.25 27C16.25 25.1816 15.4754 23.5463 14.2336 22.4022L13.75 21.9566V6.75C13.75 4.67893 12.0711 3 10 3ZM3.25 6.75C3.25 3.02208 6.27208 0 10 0C13.7279 0 16.75 3.02208 16.75 6.75V20.6754C18.299 22.3283 19.25 24.554 19.25 27C19.25 32.1086 15.1086 36.25 10 36.25C4.89137 36.25 0.75 32.1086 0.75 27C0.75 24.554 1.70098 22.3283 3.25 20.6754V6.75ZM14 27C14 29.2091 12.2091 31 10 31C7.79086 31 6 29.2091 6 27C6 25.3213 7.03408 23.8841 8.5 23.2908V12.5C8.5 11.6716 9.17157 11 10 11C10.8284 11 11.5 11.6716 11.5 12.5V23.2908C12.9659 23.8841 14 25.3213 14 27Z"
        fill={color}
      />
    </svg>
  );
};
