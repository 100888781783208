import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import IResourceAllocationDetails from "../../models/resource-allocation/IResourceAllocationDetails";
import { IAvailability } from "../../models/resource-allocation/IResourceAvailability";
import { IResourceData } from "../../models/resource-allocation/IResourceData";
import { RESOURCE_COLORS } from "../../utils/resource/ResourceConstants";
import { ResourceHelper } from "../../utils/resource/ResourceHelper";
import { BarChart } from "../common/charts/BarChart";
import { AmdDonutChart } from "../common/charts/DonutChart/DonutChart";
import { AmdTable } from "../common/form-controls/uncontrolled/Table/AmdTable";

export interface IResourceDetailsCard {
  resourceData: IResourceData[];
  resources?: IAvailability[];
  resourceDetails: IResourceAllocationDetails[];
  resourceName?: string;
}

export const ResourceDetailsCard: React.FunctionComponent<
  IResourceDetailsCard
> = (props: IResourceDetailsCard) => {
  let legendTableData = props.resources?.map(
    (resource: IAvailability, index: number) => {
      const colorKey = RESOURCE_COLORS.colors[index];
      return Object.assign({
        legend: {
          content: (
            <span
              className={getLegendSquareStyle(colorKey).legendSquare}
            ></span>
          ),
        },
        terminal: { text: resource.group },
        operative: { text: `${resource.operative} ${props.resourceName}` },
        inOperative: { text: `${resource.inOperative} ${props.resourceName}` },
      });
    }
  );
  legendTableData = !legendTableData ? [] : legendTableData;
  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-xxl6 ms-hiddenXlDown">
          <span
            className="fs-13 fw-600 ml-2 fc--gray800"
            data-testid={`resource-${props.resourceName}`}
          >
            {props.resourceName}
          </span>
          <div className="amd-container-center ml-2">
            <AmdDonutChart
              resourceData={props.resourceData}
              noFlightsMessage={`No ${props.resourceName}`}
              hideLegend={true}
            />
            <AmdTable
              className="ml-4"
              tableLayout="fixed"
              tableWidth="280px"
              columns={[
                { name: "legend", columnLabel: null },
                { name: "terminal", columnLabel: "Terminal", width: "80px" },
                { name: "operative", columnLabel: "Operative", width: "90px" },
                { name: "inOperative", columnLabel: "INOP", width: "80px" },
              ]}
              data={legendTableData}
            />
          </div>
        </div>
        <div className="ms-Grid-col ms-xxl6 ms-hiddenXlDown">
          <div className={contentStyles.barChartContainer}>
            <BarChart
              width={ResourceHelper.getBarChartWidth(props.resourceDetails)}
              resourceDetails={props.resourceDetails}
              noResourcesMessage={`No ${props.resourceName} available`}
            />
          </div>
        </div>
        <div className="ms-Grid-col ms-md6 mt-2 ms-hiddenXxlUp">
          <span
            className="fs-13 fw-600 ml-2 fc--gray800"
            data-testid={`resource-${props.resourceName}-tablet`}
          >
            {props.resourceName}
          </span>
          <div className="amd-container-center ml-2">
            <AmdDonutChart
              resourceData={props.resourceData}
              noFlightsMessage={`No ${props.resourceName}`}
              hideLegend={true}
              testId="donutChart-tablet"
            />
            <AmdTable
              className="ml-4"
              tableLayout="fixed"
              tableWidth="280px"
              columns={[
                { name: "legend", columnLabel: null },
                { name: "terminal", columnLabel: "Terminal", width: "80px" },
                { name: "operative", columnLabel: "Operative", width: "90px" },
                { name: "inOperative", columnLabel: "INOP", width: "80px" },
              ]}
              data={legendTableData}
            />
          </div>
        </div>
        <div className="ms-Grid-col ms-md7 ms-hiddenXxlUp">
          <div className={contentStyles.barChartContainerTablet}>
            <BarChart
              width={ResourceHelper.getBarChartWidth(props.resourceDetails)}
              testId="barChart-tablet"
              resourceDetails={props.resourceDetails}
              noResourcesMessage={`No ${props.resourceName} available`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getLegendSquareStyle = (bgColor = "") =>
  mergeStyleSets({
    legendSquare: {
      width: 10,
      height: 10,
      display: "inline-block",
      marginRight: 5,
      backgroundColor: bgColor,
    },
  });

const contentStyles = mergeStyleSets({
  barChartContainer: {
    width: "auto",
    whiteSpace: "nowrap",
    overflowX: "auto",
    scrollbarWidth: "thin",
  },
  barChartContainerTablet: {
    width: "auto",
    whiteSpace: "nowrap",
    overflowX: "auto",
  },
});
