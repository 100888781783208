import { IColumnsType } from "./types";

export function getSelectedColumn(columns: IColumnsType[], fieldName: string) {
  if (!columns) {
    return [];
  }
  return columns?.filter(
    (col) => col?.fieldName?.toLowerCase() === fieldName?.toLowerCase()
  );
}
