import {
  Calendar,
  Callout,
  defaultCalendarStrings,
  DirectionalHint,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";
import { Button, useId } from "@fluentui/react-components";
import React from "react";
import { DateTypeEnum } from "../../../enum/DateEnum";
import { getAirportCode } from "../../../store/airport/AirportSelector";
import { useAppSelector } from "../../../store/StoreHooks";
import {
  getApiTime,
  getDisplayTime,
} from "../../../utils/multi-airports/date-filter/dateFilterHelper";

interface IDateFilterPopUpProps {
  buttonId: string;
  toggleIsCalloutVisible: () => void;
  isCalloutVisible: boolean;
  onSetApiStartTime: any;
  onSetApiEndTime: any;
  onSetDisplayStartTime: any;
  onSetDisplayEndTime: any;
  onSetSelectedButton: any;
  selectedButton: string;
  onSetSelectedCalendarDate: any;
  selectedCalendarDate: any;
}

const DateFilterPopUp: React.FC<IDateFilterPopUpProps> = (
  props: IDateFilterPopUpProps
) => {
  const {
    buttonId,
    toggleIsCalloutVisible,
    onSetApiStartTime,
    onSetApiEndTime,
    onSetDisplayStartTime,
    onSetDisplayEndTime,
    onSetSelectedButton,
    selectedButton,
    onSetSelectedCalendarDate,
    selectedCalendarDate,
  } = props;
  const airport = useAppSelector((state) => getAirportCode(state));
  const [isCalendarVisible, setIsCalendarVisible] = React.useState(false);

  const onSelectDate = React.useCallback((date: Date): void => {
    onSetSelectedCalendarDate(date);
    const buttonType = DateTypeEnum.Calendar;
    updateDateTime(buttonType, date);
  }, []);

  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");
  const buttonDateFormat = "DD MMM YY";
  // displayed button text
  const todaysDate = getApiTime(
    airport,
    DateTypeEnum.Default
  );

  const todaysButtonDisplayDate = getDisplayTime(
    airport,
    DateTypeEnum.Now,
    buttonDateFormat,
    true
  );

  const tomorrowsButtonDisplayDate = getDisplayTime(
    airport,
    DateTypeEnum.Tomorrow,
    buttonDateFormat
  );

  function updateDateTime(buttonType: DateTypeEnum, selectedCalendarDate?: Date): void {
    const updatedDisplayStartTime = getDisplayTime(
      airport,
      buttonType,
      undefined,
      true,
      selectedCalendarDate
    );

    const updatedDisplayEndTime = getDisplayTime(
      airport,
      buttonType,
      undefined,
      false,
      selectedCalendarDate
    );

    //for Callout display
    onSetDisplayStartTime(updatedDisplayStartTime);
    onSetDisplayEndTime(updatedDisplayEndTime);

    // for the API
    const startTime = getApiTime(
      airport,
      buttonType,
      true,
      selectedCalendarDate
    );

    const endTime = getApiTime(
      airport,
      buttonType,
      false,
      selectedCalendarDate
    );

    onSetApiStartTime(startTime);
    onSetApiEndTime(endTime);
    onSetSelectedButton(buttonType);
  }

  const onClickHandler = (
    buttonType: DateTypeEnum,
    selectedCalendarDate?: Date
  ): void => {
    // button values, now, today, tomorrow, other
    if (buttonType === DateTypeEnum.Calendar) {
      setIsCalendarVisible(true);
      onSetSelectedButton(buttonType);
      return;
    }
    setIsCalendarVisible(false);
    updateDateTime(buttonType, selectedCalendarDate);
  };
  return (
    <>
      <Callout
        ariaLabelledBy={labelId}
        ariaDescribedBy={descriptionId}
        role="dialog"
        className={contentStyles.callout}
        gapSpace={2}
        target={`#${buttonId}`}
        isBeakVisible={false}
        onDismiss={toggleIsCalloutVisible}
        directionalHint={DirectionalHint.bottomRightEdge}
        setInitialFocus
        data-testid="date-filter-popup"
      >
        <section className={`${contentStyles.container} amd-container-vcenter`}>
          <Button
            appearance="transparent"
            className={
              selectedButton === DateTypeEnum.Now
                ? contentStyles.selectedButton
                : contentStyles.button
            }
            onClick={() => onClickHandler(DateTypeEnum.Now)}
          >
            <div>Now</div>
            <div>-3hrs to +7hrs</div>
          </Button>
          <Button
            appearance="transparent"
            className={
              selectedButton === DateTypeEnum.Today
                ? contentStyles.selectedButton
                : contentStyles.button
            }
            onClick={() => onClickHandler(DateTypeEnum.Today)}
          >
            <div>Today</div>
            <div>{todaysButtonDisplayDate} (24 hrs)</div>
          </Button>
          <Button
            appearance="transparent"
            className={
              selectedButton === DateTypeEnum.Tomorrow
                ? contentStyles.selectedButton
                : contentStyles.button
            }
            onClick={() => onClickHandler(DateTypeEnum.Tomorrow)}
          >
            <div>Tomorrow</div>
            <div>{tomorrowsButtonDisplayDate} (24 hrs)</div>
          </Button>
          <Button
            appearance="transparent"
            onClick={() => {
              setIsCalendarVisible((v) => !v);
              onClickHandler(DateTypeEnum.Calendar);
            }}
            className={
              selectedButton === DateTypeEnum.Calendar
                ? contentStyles.selectedButton
                : contentStyles.calendarButton
            }
          >
            <div>Other</div>
            <div>(24 hrs)</div>
          </Button>
        </section>
        <span className={contentStyles.calloutDatePicker}>
          {(isCalendarVisible || selectedButton === DateTypeEnum.Calendar) && (
            <Calendar
              showMonthPickerAsOverlay
              highlightSelectedMonth
              showGoToToday={false}
              onSelectDate={(date) => onSelectDate(date)}
              value={selectedCalendarDate}
              // Calendar uses English strings by default. For localized apps, you must override this prop.
              strings={defaultCalendarStrings}
              className={contentStyles.calloutDatePicker}
              minDate={todaysDate}
            />
          )}
        </span>
      </Callout>
    </>
  );
};

export default DateFilterPopUp;

const theme = getTheme();

const buttonStyles = {
  dispay: "flex",
  flexDirection: "column",
  padding: "4px 12px",
  ":hover": {
    background: theme.palette.blueDark,
    color: theme.palette.white,
  },
};

const contentStyles = mergeStyleSets({
  callout: {
    width: "auto",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.neutralQuaternary}`,
    padding: "12px",
    background: "#FFFFFF",
  },
  container: {
    borderRadius: "4px",
    border: `1px solid ${theme.palette.blue}`,
    color: theme.palette.blue,
  },
  button: {
    ".ms-Callout-main &": {
      borderRight: `1px solid ${theme.palette.blue}`,
      ...buttonStyles,
    },
  },
  calendarButton: {
    ".ms-Callout-main &": {
      borderRight: "none",
      ...buttonStyles,
    },
  },
  selectedButton: {
    ".ms-Callout-main &": {
      ...buttonStyles,
      background: theme.palette.blueDark,
      color: theme.palette.white,
    },
  },
  calloutDatePicker: {
    display: "flex",
    justifyContent: "right",
  },
});
