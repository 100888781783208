import CloudsSVG from "../../components/weather/images/clouds.svg";
import DewPointSVG from "../../components/weather/images/dew-point.svg";
import GustSVG from "../../components/weather/images/gust.svg";
import HumiditySVG from "../../components/weather/images/humidity.svg";
import PressureSVG from "../../components/weather/images/pressure.svg";
import RainfallSVG from "../../components/weather/images/rainfall.svg";
import TemperatureSVG from "../../components/weather/images/temperature.svg";
import VisibilitySVG from "../../components/weather/images/visibility-range.svg";
import WindSVG from "../../components/weather/images/wind.svg";

export const WEATHER_SUMMARY_TABLE_COLUMNS = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "value",
    name: "Value",
    fieldName: "value",
    minWidth: 100,
    maxWidth: 100,
  },
];

export const WEATHER_SUMMARY_LEFT_TABLE_KEYS = [
  {
    name: "clouds",
    displayName: "Cloud Cover",
    image: CloudsSVG,
  },
  {
    name: "visibility",
    displayName: "Visibility",
    image: VisibilitySVG,
  },
  {
    name: "wind",
    displayName: "Wind",
    image: WindSVG,
  },
  {
    name: "humidity",
    displayName: "Humidity",
    image: HumiditySVG,
  },
  {
    name: "rainfall",
    displayName: "Rainfall",
    image: RainfallSVG,
  },
];

export const WEATHER_SUMMARY_RIGHT_TABLE_KEYS = [
  {
    name: "pressure",
    displayName: "Pressure",
    image: PressureSVG,
  },
  {
    name: "gust",
    displayName: "Gust",
    image: GustSVG,
  },
  {
    name: "dewPoint",
    displayName: "Dew Point",
    image: DewPointSVG,
  },
];

export const WEATHER_KPI_CARD_KEYS = [
  {
    name: "temperature",
    displayName: "Temperature",
    image: TemperatureSVG,
  },
  {
    name: "humidity",
    displayName: "Humidity",
    image: HumiditySVG,
  },
  {
    name: "rainfall",
    displayName: "Rainfall",
    image: RainfallSVG,
  },
  {
    name: "wind",
    displayName: "Wind",
    image: WindSVG,
  },
  {
    name: "visibility",
    displayName: "Visibility Range",
    image: VisibilitySVG,
  },
  {
    name: "clouds",
    displayName: "Clouds",
    image: CloudsSVG,
  },
  {
    name: "dewPoint",
    displayName: "Dew Point",
    image: DewPointSVG,
  },
  {
    name: "pressure",
    displayName: "Pressure",
    image: PressureSVG,
  },
];

export const WEATHER_KPI_TABS = {
  data: [{ text: "TODAYS WEATHER", name: "today", value: "today" }],
};

export const WEATHER_GRAPH_TABS = {
  data: [
    {
      text: "Temperature",
      name: "temperature",
      value: "temperature",
      image: TemperatureSVG,
    },
    { text: "Humidity", name: "humidity", value: "humidity", image: CloudsSVG },
    {
      text: "Rainfall",
      name: "rainfall",
      value: "rainfall",
      image: RainfallSVG,
    },
    { text: "Wind", name: "windSpeed", value: "windSpeed", image: WindSVG },
    {
      text: "Visibility Range",
      name: "visibility",
      value: "visibility",
      image: VisibilitySVG,
    },
    {
      text: "Clouds",
      name: "cloudCover",
      value: "cloudCover",
      image: RainfallSVG,
    },
    {
      text: "Dew Point",
      name: "dewPoint",
      value: "dewPoint",
      image: DewPointSVG,
    },
  ],
};

export const RUNWAY_TABS = {
  data: [
    { text: "11R/29L", name: "11R_29L", value: "11R_29L" },
    { text: "R10/28", name: "10_28", value: "10_28" },
    { text: "R09/27", name: "09_27", value: "09_27" },
  ],
};

export const RUNWAY_TABS_XYZ = {
  data: [
    { text: "27L", name: "27L", value: "27L" },
    { text: "09L", name: "09L", value: "09L" },
  ],
};

export const SPEED_GAUGE_PROPS = {
  extended: { ringWidth: 13, width: 200, needleHeight: 0.7 },
  runway: { ringWidth: 17, width: 300, needleHeight: 0.8 },
};

export const SPEEDGAUGE_SEGMENT_COLORS = [
  "#008540",
  "#FEEB3D",
  "#F7A827",
  "#E95326",
  "#CE0058",
];

export const SPEEDGAUGE_SEGMENT_STOPS = [0, 32.1, 40.2, 62.7, 91.7, 130];
