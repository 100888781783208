import { makeStyles } from "@fluentui/react-components";
import * as React from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { splitAirportDataByPin } from "../../utils/multi-airports/MultiAirportHelperFunctions";
import AirportCard from "./AirportCard";

interface IAirportListProps {
  airportList: any;
  currentTime: Date;
  onPinClickHandler: (id: string) => void;
  sideBarActive?: boolean;
}

const AirportList: React.FunctionComponent<IAirportListProps> = ({
  airportList,
  currentTime,
  onPinClickHandler,
}) => {
  const classes = useStyles();
  const { isMapView } = React.useContext(LayoutContext);
  const { pinnedAirports, unPinnedAirports } =
    splitAirportDataByPin(airportList);

  const updatedAirportList = [...pinnedAirports, ...unPinnedAirports];
  const content = updatedAirportList.map((airport: any) => (
    <AirportCard
      key={airport.id}
      airport={airport}
      time={currentTime}
      onPinClickHandler={onPinClickHandler}
    />
  ));

  return (
    <div data-testid="multi-airport-container" className={`${classes.airportLists} ${isMapView && classes.airportListsMapView}`}>
      {content}
    </div>
  );
};

export default AirportList;

const useStyles = makeStyles({
  airportLists: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  airportListsMapView: {
    rowGap: "12px",
  },
});

