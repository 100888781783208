import ChartHelper from "../../../utils/common/ChartHelper";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { FlightSummaryHelper } from "../../../utils/flights/FlightSummaryHelper";
import { AmdDonutChart } from "../../common/charts/DonutChart/DonutChart";
import { AmdButton } from "../../common/form-controls/uncontrolled/Button/AmdButton";
import { AmdButtonType } from "../../common/form-controls/uncontrolled/Button/AmdButtonType";
import { AmdArrow } from "../../common/icons/arrow/arrow";
import { ArrowDirection } from "../../common/icons/arrow/arrowDirection";
import { NoDataCard } from "../../common/no-data-card/NoDataCard";
const flight360Url = "/flights360";
const flight360EntityId = "flights360";

const chartHeader = (title: string, urlScrollId = "", displayOtp = true) => {
  return (
    <div className="flightInformationContainer">
      <span className="fs-13 fw-600">{title}</span>
      {displayOtp && (
        <AmdButton
          type={AmdButtonType.ICON_BUTTON}
          onClick={() =>
            CommonHelper.navigateToComponent(
              flight360Url,
              urlScrollId,
              flight360EntityId
            )
          }
          testId="otp-right-arrow"
          className="float-right otp-right-arrow"
        >
          <AmdArrow direction={ArrowDirection.RIGHT} />
        </AmdButton>
      )}
    </div>
  );
};
export const FlightsKPISection: React.FunctionComponent<IFlightsKPISection> = (
  props: IFlightsKPISection
) => {
  return props.resourceData ? (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row" data-testid="flights-kpi-section">
        <>
          {props.displayOtp && props.otpResourceData && (
            <>
              <div
                className="ms-Grid-col ms-md12 ms-hiddenXxlUp d-flex flights-kpi-otp"
                data-testid="flights-kpi-otp-tab"
              >
                <AmdDonutChart
                  resourceData={props.otpResourceData}
                  noFlightsMessage="No otp Flights found"
                  hideLegend={true}
                  showPercent={true}
                  legendData={ChartHelper.getLegendData(props.otpResourceData)}
                  showCustomLegend={true}
                  containerWidth="280px"
                  donutContainerWidth="164px"
                  chartAlignment="center"
                  chartHeader={chartHeader("On-Time Performance", "otp")}
                />
              </div>
              <div
                className="ms-Grid-col ms-xxl3 ms-hiddenXlDown"
                data-testid="flights-kpi-otp"
              >
                <AmdDonutChart
                  resourceData={props.otpResourceData}
                  noFlightsMessage="No otp Flights found"
                  hideLegend={true}
                  showPercent={true}
                  legendData={ChartHelper.getLegendData(props.otpResourceData)}
                  showCustomLegend={true}
                  donutContainerWidth="164px"
                  chartHeader={chartHeader("On-Time Performance", "otp")}
                />
              </div>
            </>
          )}
          {Object.keys(props.resourceData)?.map((key: string) => {
            return (
              <div
                key={key}
                className={`ms-Grid-col ${FlightSummaryHelper.getClassName(
                  props.displayOtp,
                  key
                )}`}
              >
                <AmdDonutChart
                  resourceData={props.resourceData[key]}
                  noFlightsMessage={`No ${props.resourceData[key][0].resourceName} Flights found`}
                  hideLegend={true}
                  donutContainerWidth="160px"
                  legendData={ChartHelper.getLegendData(
                    props.resourceData[key]
                  )}
                  showCustomLegend={true}
                  title={FlightSummaryHelper.getResourceTitle(
                    props.resourceData[key][0].resourceName
                  )}
                />
              </div>
            );
          })}
        </>
      </div>
    </div>
  ) : (
    <NoDataCard message={props.emptyStateMessage} />
  );
};
export interface IFlightsKPISection {
  emptyStateMessage: string;
  displayOtp: boolean;
  resourceData?: any;
  otpResourceData?: any;
}
