import {
  IDetailsColumnRenderTooltipProps,
  IRenderFunction,
  mergeStyles,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import { FlightListHelper } from "../../../../utils/flights/FlightListHelper";

import { Image } from "@fluentui/react-components";

export function renderFixedHeader(props: any, defaultRender: any) {
  if (!props) {
    return <></>;
  }
  const { columns } = props;
  const hideSortIcon = columns.some(
    (col: { isHeaderShowHide: boolean }) => (col.isHeaderShowHide = true)
  );

  const stickyHeaderStyles = mergeStyles({
    selectors: {
      "& .ms-DetailsHeader": {
        border: "1px solid rgb(237, 235, 233)",
        paddingTop: 0,
        "> .ms-Icon": {
          display: `${hideSortIcon === true ? "none" : "inline-block"}`,
        },
      },
    },
  });

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => (
    <div className="table-header">
      {tooltipHostProps?.column?.iconName && (
        <Image
          src={FlightListHelper.getIcon(tooltipHostProps?.column?.iconName)}
        ></Image>
      )}

      {tooltipHostProps?.children}
    </div>
  );
  return (
    <Sticky
      stickyClassName={stickyHeaderStyles}
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
    >
      {defaultRender({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );
}
