export enum DefaultSortEnum {
  RESOURCE_ALLOCATION_START = "resourceAllocationStart",
  CREATION_START_TIME = "creationDateTime",
  SCHEDULE = "schedule",
  DELAY_DURATION = "delayDuration",
  FLIGHT_ARRIVAL = "sibt",
  FLIGHT_DEPARTURE = "sobt",
  DEFAULT = "",
}

export enum SelectedTypeEnum {
  ARRIVAL = "arrival",
  DEPARTURE = "departure",
  ALL = "arrival;departure",
}
