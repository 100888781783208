import axios from "axios";
import EventManagementEnum from "../../enum/EventManagementEnum";
import WEATHERDISRUPTION_AIRPORT_GRAPH_MOCK from "../../mock/events/airport-graph-data.json";
import WEATHERDISRUPTION_BAGGAGE_GRAPH_MOCK from "../../mock/events/baggage-graph-data.json";
import WEATHERDISRUPTION_DEICING_GRAPH_MOCK from "../../mock/events/deicing-graph-data.json";
import WEATHERDISRUPTION_DEPARTURE_GRAPH_MOCK from "../../mock/events/departure-graph-data.json";
import WEATHERDISRUPTION_PASSENGER_GRAPH_MOCK from "../../mock/events/passenger-ground-graph-data.json";
import WEATHER_DISRUPTION_KPI_SUMMARY from "../../mock/events/weather-disruption-kpi-summary.json";
import WEATHER_DISRUPTION_KPI from "../../mock/events/weather-disruption-kpi.json";

import WEATHERDISRUPTION_WEATHER_GRAPH_MOCK from "../../mock/events/weather-graph-data.json";
import { SelectedGraphDatum } from "../../models/events/IWeatherDisruptionSummary";
import {
  AIRPORT_CODE_PARAMETER,
  DATE_PARAMETER,
  RESOURCE_TYPE_PARAMETER,
} from "../../utils/common/Constants";
import {
  SERVICE_CHANNEL_ID_STRING,
  SERVICE_EVENT_ID_STRING,
} from "../../utils/events/EventConstants";
const contentType = "application/json;charset=UTF-8";

export default class EventManagementService {
  public createEvent = async (
    accessToken: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      "x-user-email": payload?.details?.creatorEmail,
    };
    return axios.post(
      `${process.env.REACT_APP_API_EVENTS?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public getDisruptionSummary = async (_accessToken: string): Promise<any> => {
    return Promise.resolve({ data: WEATHER_DISRUPTION_KPI_SUMMARY });
  };

  public getPlanningGraphicalApi = async (
    accessToken: string,
    airportCode: string,
    resourceType: string,
    date: string
  ): Promise<SelectedGraphDatum> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };
    return axios.get(
      `${process.env.REACT_APP_API_RESOURCE_CAPACITY_PLANNING_GRAPHICAL?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )
        .replace(RESOURCE_TYPE_PARAMETER, resourceType)
        .replace(DATE_PARAMETER, date)}`,
      {
        headers,
      }
    );
  };

  public getEventDetailById = async (
    accessToken: string,
    eventId: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };
    return axios.get(
      `${process.env.REACT_APP_API_EVENTS?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${eventId}`,
      {
        headers,
      }
    );
  };

  // used to update adaptive cards
  public updateEvent = async (
    accessToken: string,
    eventId: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };

    return axios.patch(
      `${process.env.REACT_APP_API_EVENTS?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${eventId}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  // used to update response team
  public updateEventResponseTeam = async (
    accessToken: string,
    eventId: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };

    return axios.put(
      `${process.env.REACT_APP_API_EVENT_UPDATERESPONSETEAM?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public shareCrisisKPIs = async (
    accessToken: string,
    eventId: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };

    return axios.post(
      `${process.env.REACT_APP_API_SHARE_CRISIS_KPIS?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public crisisDashboardKPIs = async (
    accessToken: string,
    eventId: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };
    return axios.get(
      `${process.env.REACT_APP_API_SHARE_CRISIS_KPIS?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      { headers }
    );
  };

  public getEventPlanningKpi = async (
    accessToken: string,
    airportCode: string,
    resourceType: string,
    date: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json;charset=UTF-8",
    };
    return axios.get(
      `${process.env.REACT_APP_API_RESOURCE_CAPACITY_PLANNING?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )
        .replace(RESOURCE_TYPE_PARAMETER, resourceType)
        .replace(DATE_PARAMETER, date)}
      `,
      { headers }
    );
  };

  public setCrisisboardPlanningKpi = async (
    accessToken: string,
    airportCode: string,
    resourceType: string,
    date: string,
    payload: any
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };

    return axios.put(
      `${process.env.REACT_APP_API_RESOURCE_CAPACITY_PLANNING?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )
        .replace(RESOURCE_TYPE_PARAMETER, resourceType)
        .replace(DATE_PARAMETER, date)}
      `,
      JSON.stringify(payload),
      { headers }
    );
  };
  public getTemplateResponseTeam = async (
    accessToken: string,
    eventType: string,
    airportCode: string
  ) => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };

    return axios.get(
      `${process.env.REACT_APP_API_EVENTS_TEMPLATES_EVENTTYPE?.replace(
        "{eventType}",
        eventType.replace(/\s+/g, "")
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      { headers }
    );
  };

  public getEventChannels = async (
    accessToken: string,
    currentUserEmail: string,
    airportCode: string,
    eventId: string
  ) => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      "x-user-email": currentUserEmail,
    };

    return axios.get(
      `${process.env.REACT_APP_API_EVENT_CHANNELS?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId?.replace(/\s+/g, "")
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      { headers }
    );
  };
  public getKpis = async (_accessToken: string) => {
    return Promise.resolve({ data: WEATHER_DISRUPTION_KPI });
  };
  public getEventChannelInfo = async (
    accessToken: string,
    eventId: string,
    channelId: string,
    airportCode: string
  ) => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    };

    return axios.get(
      `${process.env.REACT_APP_API_EVENT_CHANNEL_INFO?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId.replace(/\s+/g, "")
      )
        .replace(SERVICE_CHANNEL_ID_STRING, channelId.replace(/\s+/g, ""))
        .replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      { headers }
    );
  };

  public createChannel = async (
    accessToken: string,
    eventId: string,
    currentUserEmail: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      "x-user-email": currentUserEmail,
    };

    return axios.post(
      `${process.env.REACT_APP_API_EVENT_CHANNELS?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId.replace(/\s+/g, "")
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public editChannel = async (
    accessToken: string,
    eventId: string,
    channelId: string,
    currentUserEmail: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      "x-user-email": currentUserEmail,
    };

    return axios.put(
      `${process.env.REACT_APP_API_EVENT_CHANNEL_INFO?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId.replace(/\s+/g, "")
      )
        ?.replace(SERVICE_CHANNEL_ID_STRING, channelId.replace(/\s+/g, ""))
        .replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public deleteChannel = async (
    accessToken: string,
    eventId: string,
    channelId: string,
    currentUserEmail: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      "x-user-email": currentUserEmail,
    };

    return axios.delete(
      `${process.env.REACT_APP_API_EVENT_CHANNEL_INFO?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId.replace(/\s+/g, "")
      )
        ?.replace(SERVICE_CHANNEL_ID_STRING, channelId.replace(/\s+/g, ""))
        .replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      { headers }
    );
  };

  public getChannelManagement = async (
    accessToken: string,
    eventId: string,
    currentUserEmail: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      "x-user-email": currentUserEmail,
    };

    return axios.get(
      `${process.env.REACT_APP_API_EVENT_CHANNEL_MGMT?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId.replace(/\s+/g, "")
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      { headers }
    );
  };

  public updateChannelManagement = async (
    accessToken: string,
    eventId: string,
    currentUserEmail: string,
    payload: any,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      "x-user-email": currentUserEmail,
    };

    return axios.put(
      `${process.env.REACT_APP_API_EVENT_CHANNEL_MGMT?.replace(
        SERVICE_EVENT_ID_STRING,
        eventId.replace(/\s+/g, "")
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public getUserPrivileges = async (
    accessToken: string,
    eventId: string,
    currentUserEmail: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": process.env.REACT_APP_APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      "x-user-email": currentUserEmail,
    };

    return axios.get(
      `${process.env.REACT_APP_API_EVENTS_USER_PRIVILEGES?.replace(
        "{event-id}",
        eventId.replace(/\s+/g, "")
      ).replace(AIRPORT_CODE_PARAMETER, airportCode)}`,
      { headers }
    );
  };

  public getDisruptionGraphData = async (
    _accessToken: string,
    disruptionName: string,
    _airportCode: string
  ): Promise<any> => {
    let mockData;
    switch (disruptionName) {
      //Todo Plug in API
      case EventManagementEnum.DisruptionName.DeicingCapacity:
        mockData = WEATHERDISRUPTION_DEICING_GRAPH_MOCK;
        break;
      case EventManagementEnum.DisruptionName.AircraftOnGround:
        mockData = WEATHERDISRUPTION_AIRPORT_GRAPH_MOCK;
        break;
      case EventManagementEnum.DisruptionName.PassengerOnGround:
        mockData = WEATHERDISRUPTION_PASSENGER_GRAPH_MOCK;
        break;
      case EventManagementEnum.DisruptionName.DepartureCapacity:
        mockData = WEATHERDISRUPTION_DEPARTURE_GRAPH_MOCK;
        break;
      case EventManagementEnum.DisruptionName.Baggage:
        mockData = WEATHERDISRUPTION_BAGGAGE_GRAPH_MOCK;
        break;
      case EventManagementEnum.DisruptionName.Weather:
        mockData = WEATHERDISRUPTION_WEATHER_GRAPH_MOCK;
        break;
    }

    return Promise.resolve(mockData);
  };
}
