import HOPEnum from "../../../enum/HOPEnum";
import Crowded from "./Crowded";
import Optimal from "./Optimal";
import OverUtilised from "./OverUtilised";

export function PaxFlowLegendIcons({
  fieldContent,
  isPaxTable,
}: {
  fieldContent: string;
  isPaxTable: boolean;
}): React.JSX.Element | null {
  if(!fieldContent) {
    return null
  }
  const [value = '', status = ''] = fieldContent.split("|") ?? '';


  if (status === HOPEnum.HOPDataStatus.Moderate && isPaxTable) {
    return <Crowded fieldContent={value} />;
  }
  if (status === HOPEnum.HOPDataStatus.Poor && isPaxTable) {
    return <OverUtilised fieldContent={value} />;
  }
  return <Optimal fieldContent={value} />;
}
