import { Image } from "@fluentui/react-components";
import HOPEnum from "../../enum/HOPEnum";
import ArrivalIcon from "../common/images/arrival.svg";
import DepartureIcon from "../common/images/departure.svg";
export const HOPOTPKPIIcon: React.FunctionComponent<IHOPOTPKPIIconProps> = (
  props: IHOPOTPKPIIconProps
) => {
  return (
    <>
      {props.kpiKey === HOPEnum.HOPKPI.All && (
        <span className="ml-1">{props?.kpiName}</span>
      )}
      {props.kpiKey === HOPEnum.HOPKPI.Arrivals && (
        <Image className="ml-1 arrival-icon" src={ArrivalIcon}></Image>
      )}
      {props.kpiKey === HOPEnum.HOPKPI.Departures && (
        <Image className="ml-1 departure-icon" src={DepartureIcon}></Image>
      )}
    </>
  );
};

export interface IHOPOTPKPIIconProps {
  kpiKey: string;
  kpiName?: string;
}
