/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ITerminals, ITouchPoint } from "../../models/app-settings/ITouchPoint";

const initialState = {
  airportTouchPoints: [] as ITouchPoint[],
  terminalsTouchPoints: [] as ITerminals[],
};

//PaxSettings Slice to manage the Passenger Flow Touchpoints in 'Application Settings' and 'Pax' Pages.
const paxSettingsSlice = createSlice({
  name: "paxSettingsTouchPoints",
  initialState,
  reducers: {
    setPaxTouchpoints(state, action) {
      state.airportTouchPoints = action.payload.airportTouchPoints;
      state.terminalsTouchPoints = action.payload.terminalsTouchPoints;
    },
  },
});

export const paxSettingActions = paxSettingsSlice.actions;

export default paxSettingsSlice.reducer;
