import { PublicClientApplication } from "@azure/msal-browser";
import ArrivalIcon from "../../components/common/images/arrivals.svg";
import DepartureIcon from "../../components/common/images/departures.svg";
import HOPEnum from "../../enum/HOPEnum";
import IFlightsPerformance, {
  IPerformance,
} from "../../models/flights/IFlightsPerformance";
import IOTPSummary, {
  IOTPItem,
  IOTPTabSummary,
} from "../../models/flights/IOTPSummary";
import OTPSummaryService from "../../services/otp-summary/otpsummary.service";
import { APIHelper } from "../api/APIHelper";
import { FLIGHT_COLORS, HOP_RANGE_COLORS } from "../common/Constants";

export abstract class OTPHelper {
  public static async getOTPSummary(
    msalInstance: PublicClientApplication,
    airportCode: string
  ): Promise<any> {
    const otpService = new OTPSummaryService();
    const getOTPSummaryMethod = (accessToken: string) => {
      return otpService.getOTPSummary(accessToken, airportCode);
    };

    return APIHelper.CallAPI(msalInstance, null, getOTPSummaryMethod);
  }
  public static async getOTPTabsSummary(
    msalInstance: PublicClientApplication
  ): Promise<any> {
    const otpService = new OTPSummaryService();
    const getOTPTabsSummaryMethod = (accessToken: string) => {
      return otpService.getOTPTabsSummary(accessToken);
    };

    return APIHelper.CallAPI(msalInstance, null, getOTPTabsSummaryMethod);
  }
  public static async getFlightsPerformance(
    msalInstance: PublicClientApplication,
    key: string
  ): Promise<any> {
    const otpService = new OTPSummaryService();
    const getFlightsPerformanceMethod = (accessToken: string) => {
      return otpService.getFlightsPerformance(accessToken, key);
    };

    return APIHelper.CallAPI(msalInstance, null, getFlightsPerformanceMethod);
  }

  public static getOTPChartData(data?: IOTPItem) {
    const chartData: any[] = [];
    if (data) {
      const arr = [">15 mins", "On Time"];
      arr.forEach((item: string, index: number) => {
        const obj = {
          resourceName: item,
          resourceCount: index === 0 ? data.delayed : data.onTime,
          resourceTitle: item,
          resourceColor: FLIGHT_COLORS.otp[index],
          resourceTotal: data.performance,
        };
        chartData.push(obj);
      });
    }
    return chartData;
  }

  //To be removed - workaround for PTE
  public static updateOTPPerformanceData(
    data: IOTPSummary,
    template: IFlightsPerformance[]
  ) {
    if (data) {
      template.forEach((item: IFlightsPerformance) => {
        switch (item.name) {
          case "overall":
            this.updateOTPDataItem(data.all, item.performance);
            break;
          case "arrivals":
            this.updateOTPDataItem(data.arrivals, item.performance);
            break;
          case "departures":
            this.updateOTPDataItem(data.departures, item.performance);
            break;
        }
      });
    }
  }

  //To be removed - workaround for PTE
  public static updateOTPDataItem(item: IOTPItem, performance: IPerformance[]) {
    performance.forEach((element) => {
      element.status = this.getStatus(item.performance);
      switch (element.key) {
        case "overall":
          element.value = item.performance;
          break;
        case "domestic":
          element.value = item.delayedDomestic;
          break;
        case "international":
          element.value = item.delayedInternational;
          break;
      }
    });
  }

  public static getStatus(value: number) {
    if (value > 69) {
      return "good";
    } else if (value > 23 && value <= 69) {
      return "moderate";
    }
    return "poor";
  }

  public static getFlightPerformanceChartData(
    performanceItem: IFlightsPerformance
  ) {
    const chartData: any[] = [];
    const arr = [">15 mins", "On Time"];
    arr.forEach((item: string, index: number) => {
      const overallPerformance = performanceItem.performance.filter(
        (p) => p.key === "overall"
      )[0];
      const obj = {
        resourceName: item,
        resourceCount:
          index === 0
            ? 100 - overallPerformance.value
            : overallPerformance.value,
        resourceTitle: item,
        resourceColor:
          index === 0
            ? "#CCCCCC"
            : (HOP_RANGE_COLORS as { [k in string]: any })[
                overallPerformance.status
              ],
        resourceTotal: overallPerformance.value,
      };
      chartData.push(obj);
    });
    return chartData;
  }

  public static getOTPTabs(result: IOTPTabSummary[]) {
    return result.map((r) => ({
      text: r.label,
      name: r.name,
      value: r.name,
      status: r.status,
      percent: `${r.performance}%`,
    }));
  }

  //To be removed - workaround for PTE
  public static updateTabSummary(
    result: IOTPTabSummary[],
    otpSummary: IOTPSummary
  ) {
    result.forEach((tabSummary) => {
      if (tabSummary.name === "onTimePerformance") {
        tabSummary.performance = otpSummary.all.performance;
        tabSummary.status = this.getStatus(otpSummary.all.performance);
      }
    });
  }

  public static getIcon(key: string) {
    if (key === HOPEnum.HOPKPI.Arrivals) {
      return ArrivalIcon;
    } else if (key === HOPEnum.HOPKPI.Departures) {
      return DepartureIcon;
    } else {
      return "";
    }
  }
}
