import React from "react";
import { ITouchPoint } from "../../../models/app-settings/ITouchPoint";
import { PaxTouchPoint } from "./PaxTouchPoint";

export const PaxTouchPoints: React.FunctionComponent<ITouchPointsProps> = (
  props: ITouchPointsProps
) => {
  const touchPointsContent = props.touchPoints.map((tp) => (
    <PaxTouchPoint
      key={`${props.selectedTab}${tp.name}`}
      name={tp.name}
      types={tp.types}
    />
  ));

  return <div data-testid="pax-touch-points">{touchPointsContent}</div>;
};

export interface ITouchPointsProps {
  touchPoints: ITouchPoint[];
  selectedTab: string;
}
