import { getAirportCode } from "../../store/airport/AirportSelector";
import { useAppSelector } from "../../store/StoreHooks";
import { NotificationBanner } from "../common/notification-banner/NotificationBanner";
import { ResourceAllocationMacroCard } from "./ResourceAllocationMacroCard";

export const ResourceAllocation: React.FunctionComponent = () => {
  const airport = useAppSelector((state) => getAirportCode(state));

  return (
    <>
      <NotificationBanner airport={airport} />
      <h1 className="amd-heading1">Resource Allocation</h1>
      <section
        className="amd-section-shadow"
        data-testid="resourceAllocationContainer"
      >
        <h3 className="amd-section-shadow__header mt-0">Resource Allocation</h3>

        <ResourceAllocationMacroCard showList={true} airport={airport} />
      </section>
    </>
  );
};
