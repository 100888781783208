import axios from "axios";
import {
  AIRPORT_CODE_PARAMETER,
  APIM_KEY,
  CONTENT_TYPE,
} from "../../utils/common/Constants";

export default class NotificationService {
  public getClientAccessUrl = async (
    accessToken: string,
    payload: any,
    userEmail: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
      "x-user-email": userEmail,
    };

    return axios.post(
      `${process.env.REACT_APP_API_NOTIFICATION_CLIENTURL?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}`,
      JSON.stringify(payload),
      { headers }
    );
  };

  public getNotificationById = async (
    accessToken: string,
    notificationId: string,
    airportCode: string
  ): Promise<any> => {
    const headers = {
      "x-api-key": APIM_KEY,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": CONTENT_TYPE,
    };
    return axios.get(
      `${process.env.REACT_APP_API_NOTIFICATION?.replace(
        AIRPORT_CODE_PARAMETER,
        airportCode
      )}/${notificationId}`,
      {
        headers,
      }
    );
  };
}
