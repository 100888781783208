import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { makeStyles, Spinner } from "@fluentui/react-components";
import {
  ClockRegular,
  EyeOffFilled,
  LocationFilled,
  WeatherPartlyCloudyDayFilled,
} from "@fluentui/react-icons";
import React from "react";
import { $cloud100, $cloud30, $white } from "../../../../cssjs/colors";
import { getAirportCode } from "../../../../store/airport/AirportSelector";
import { useAppSelector } from "../../../../store/StoreHooks";
import { getTime } from "../../../../utils/common/DateHelperFunctions";
import { getCurrentWeatherSummary } from "../../../../utils/weather/WeatherHelperFunctions";
import { WindIcon } from "../../../common/icons/wind/WindIcon";
import WeatherCardSvg from "./../../../../assests/weathercard.svg";

interface IProps {
  isCarousel?: boolean;
}

export const WeatherCard: React.FunctionComponent<IProps> = ({
  isCarousel,
}): React.JSX.Element => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const airport = useAppSelector((state: any) => getAirportCode(state));
  const [weatherSummary, setWeatherSummary] = React.useState<any>({});
  const temperature = weatherSummary?.temperature?.body ?? "";
  const currentTemp = temperature?.split(".")[0] ?? "";
  const tempUnit = weatherSummary?.temperature?.unit ?? "";
  const wind = weatherSummary?.wind?.body ?? "";
  const clouds = weatherSummary?.clouds?.body ?? "";
  const visibility = weatherSummary?.visibility?.body ?? "";
  const timeFormat = "HH:mm A";
  const currentTimeStr = getTime(airport, timeFormat);
  const currentTimeArr = currentTimeStr.split(" ");
  const currentTime = currentTimeArr[0];
  const current12Hour = currentTimeArr[1];

  React.useEffect(() => {
    const isMounted = true;
    async function requestWeatherSummary(): Promise<void> {
      const res = await getCurrentWeatherSummary(msalInstance, airport);
      if (isMounted && res?.data) {
        setWeatherSummary(res?.data);
      }
    }
    if (airport !== "") {
      requestWeatherSummary();
    }
  }, [airport]);

  const classes = useStyles();

  return (
    <>
      {!temperature ? (
        <Spinner
          labelPosition="below"
          label="Loading..."
          className={classes.cardLoader}
        />
      ) : null}
      {temperature ? (
        <div
          className={`${classes.weatherCardContainer} ${isCarousel ? classes.carouselWeatherCardContainer : classes.defaultWeatherCardContainer}`}
        >
          <div
            className={`${classes.cardContainer} ${isCarousel ? classes.carouselCardContainer: classes.defaultCardContainer} card1`}
            data-testid="weatherCard"
          >
            <div className={`${classes.cardTitle} fs-40`}>
              <span>{currentTemp}</span>
              <sup className={`fs-24 ${classes.cardTempUnit}`}>{tempUnit}</sup>
            </div>
            <div className={`${classes.cardSubTitle}`}>
              <div className={`fs-18 ${classes.cardsubTitleIcon}`}>
                <WeatherPartlyCloudyDayFilled />
              </div>
              <div className="fs-14">{clouds}</div>
            </div>
            <div className={` ${classes.rightInfoSection}`}>
              <div className={`${classes.timezoneText}`}>
                <span>
                  {currentTime} <small className="fs-10">{current12Hour}</small>
                </span>
                <span className={`${classes.clockIcon}`}>
                  <ClockRegular />
                </span>
              </div>
              <div className={`${classes.locationText}`}>
                New Delhi <LocationFilled />
              </div>
            </div>

            <div className={`${classes.windSection}`}>
              <span className={`${classes.weatherIcon}`}>
                <WindIcon />
              </span>{" "}
              <span>{wind}</span>
            </div>
            <div className={`${classes.visibility}`}>
              <span className={`${classes.visibilityIcon}`}>
                <EyeOffFilled />
              </span>{" "}
              <span>{visibility}</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const useStyles = makeStyles({
  cardLoader: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
  },
  cardsubTitleIcon: {
    lineHeigt: "1",
  },
  weatherCardContainer: {
    background:
      "linear-gradient(0deg, rgba(58,139,255,1) 0%, rgba(197,213,249,1) 20%, rgba(255,255,255,1) 60%)",
    height: "100%",
  },
  carouselWeatherCardContainer: {
    borderRadius: "none",
  },
  defaultWeatherCardContainer: {
    borderRadius: "8px",
  },
  cardContainer: {
    display: "grid",
    gridGap: "4px",
    gridTemplateColumns: "auto 1fr auto",
    boxSizing: "border-box",
    minHeight: "100%",
    color: $cloud100,
    background: `transparent url(${WeatherCardSvg}) no-repeat`,
    backgroundSize: "contain",
    backgroundPosition: "bottom",
  },
  carouselCardContainer: {
    "&.card1": {
      gridTemplateRows: "40px 0px 16px 16px",
    },
    padding: "16px 36px 32px",
  },
  defaultCardContainer: {
    "&.card1": {
      gridTemplateRows: "36px 0px 16px 16px",
    },
    color: $cloud100,
    border: `1px solid ${$cloud30}`,
    padding: "8px",
    borderRadius: "8px",
  },
  cardTitle: {
    gridRow: "span 2",
    background: $white,
    display: "flex",
    lineHeight: "1",
    gridAutoFow: "column",
  },
  cardSubTitle: {
    gridRow: "span 2",
    color: $cloud100,
    alignSelf: "start",
    display: "grid",
    gridTemplateColumns: "1fr",
    lineHeight: "1.2",
  },
  rightInfoSection: {
    gridRow: "span 2",
    color: $cloud100,
    display: "grid",
    gridAutoColumns: "1fr",
    alignContent: "end",
    justifyContent: "end",
    justifyItems: "end",
  },
  windSection: {
    gridColumn: "1 / -1",
    display: "flex",
    alignItems: "center",
    gridGap: "4px",
  },
  weatherIcon: {
    paddingTop: "4px",
  },
  visibilityIcon: {
    paddingTop: "2px",
  },
  visibility: {
    gridColumn: "1 / -1",
    display: "flex",
    alignItems: "center",
    gridGap: "4px",
    paddingTop: "2px",
  },
  locationText: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "2px",
  },
  timezoneText: {
    display: "grid",
    gridAutoColumns: "auto",
    gridAutoFlow: "column",
  },
  clockIcon: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px",
  },
  cardTempUnit: {
    lineHeight: "1.3",
  },
});
