import { DefaultPalette, mergeStyles } from "@fluentui/react";

export const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: "0 5px",
  color: "#0078d4",
});

export const circleStyle: React.CSSProperties = {
  width: "25px",
  minWidth: "25px",
  borderRadius: "50%",
  textAlign: "center",
  height: "25px",
  verticalAlign: "middle",
  lineHeight: "20px",
  border: "2px solid",
  display: "inline-block",
  marginLeft: "5px",
  cursor: "pointer",
  borderColor: DefaultPalette.white,
  backgroundColor: DefaultPalette.themePrimary,
  color: DefaultPalette.white,
};
