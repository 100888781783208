import { TextField } from "@fluentui/react";
import { Controller, useFormContext } from "react-hook-form";

interface IQueryTextControlProps {
  controlId: string;
  label: string;
  isMandatory?: boolean;
  isMultiline?: boolean;
  defaultValue?: string;
  isReadOnly?: boolean;
  className?: string;
  suffix?: string;
}

export const QueryTextControl: React.FunctionComponent<
  IQueryTextControlProps
> = (props: IQueryTextControlProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Controller
        name={props.controlId}
        control={control}
        rules={{
          required: props.isMandatory && "This field is required",
          min: { value: 0, message: "Minimum value is 0" },
          max: { value: 100, message: "Maximum value is 100" },
        }}
        render={({ field }) => (
          <TextField
            type="number"
            label={props.label}
            required={props.isMandatory}
            multiline={props.isMultiline}
            className={props.className}
            disabled={props.isReadOnly ?? false}
            defaultValue={props.defaultValue || ""}
            onChange={(_e, newValue) => {
              field.onChange(newValue);
            }}
            step={1}
            suffix={props.suffix}
            errorMessage={
              (errors[props.controlId] as any) &&
              errors[props.controlId]?.message
            }
          />
        )}
      />
    </div>
  );
};
