import { mergeStyleSets } from "@fluentui/react";
import { Input, makeStyles } from "@fluentui/react-components";
import React from "react";
import { AmdAmber } from "../../common/icons/amber/Amber";
import { AmdGreen } from "../../common/icons/green/Green";
import { AmdRed } from "../../common/icons/red/red";

export const PaxThresholdBarDetails: React.FunctionComponent<
  IThresholdBarDetailsProps
> = (props: IThresholdBarDetailsProps) => {
  const classes = useStyles();
  //Icon list based on the color format
  const iconList =
    props.thresholdBarValues[0].colorFormat === "poor"
      ? {
          first: [<AmdRed key="1" />, "Over utilized"],
          second: [<AmdGreen key="2" />, "Optimal"],
        }
      : {
          first: [<AmdGreen key="1" />, "Optimal"],
          second: [<AmdRed key="2" />, "Over utilized"],
        };

  return (
    <div className="d-flex mb-2 pt-sm-3" data-testid="threshold-bar-details">
      <div className={`${columnStyles.column1} flex-1 pl-sm-2`}>
        <div className="pb-sm-2">
          {iconList.first[0]}
          <span className={`pl-1 ${columnStyles.desc}`}>
            {iconList.first[1]}
          </span>
        </div>
        <div>
          <span className="pr-1 fs-16">Less than</span>
          <Input
            type="number"
            className={classes.customInput}
            placeholder=""
            data-testid="threshold-input-1"
            defaultValue={props.thresholdBarValues[0].value.toString()}
            aria-label="inline"
          />
          <span className="ml-1">{props.unit}</span>
        </div>
      </div>

      <div
        className={`${columnStyles.column1} flex-1 divider-v-grey--rt divider-v-grey pl-sm-2`}
      >
        <div className="pb-sm-2">
          <AmdAmber />
          <span className={`ml-1 ${columnStyles.desc}`}>Crowded</span>
        </div>
        <div>
          <Input
            placeholder=""
            type="number"
            disabled
            value={props.thresholdBarValues[0].value.toString()}
            className={classes.customInput}
            aria-label="inline"
          />

          <span className="pl-1 pr-1">to</span>
          <Input
            placeholder=""
            className={classes.customInput}
            type="number"
            data-testid="threshold-input-2"
            defaultValue={props.thresholdBarValues[1].value.toString()}
            aria-label="inline"
          />
          <span className="pl-1">{props.unit}</span>
        </div>
      </div>

      <div className={`${columnStyles.column1} flex-1 pl-sm-2`}>
        <div className="pb-sm-2">
          <span>{iconList.second[0]}</span>
          <span className={`pl-1 ${columnStyles.desc}`}>
            {iconList.second[1]}
          </span>
        </div>
        <div>
          <span className="pr-1 fs-16">Greater than</span>
          <Input
            type="number"
            disabled
            className={classes.customInput}
            placeholder=""
            value={props.thresholdBarValues[1].value.toString()}
            aria-label="inline"
          />
          <span className="ml-1">{props.unit}</span>
        </div>
      </div>
    </div>
  );
};

export interface ThresholdBarValue {
  colorFormat: string;
  value: number;
}

export interface IThresholdBarDetailsProps {
  thresholdBarValues: ThresholdBarValue[];
  unit: string;
}

const commonColumnStyles: React.CSSProperties = {
  fontSize: 14,
  color: "#1A1A1A",
  fontWeight: 400,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const columnStyles = mergeStyleSets({
  column1: {
    ...commonColumnStyles,
  },
  icons: {
    marginTop: 20,
  },
  desc: {
    position: "relative",
    top: -2,
  },
});

const useStyles = makeStyles({
  customInput: {
    width: "45px",
    paddingLeft: "5px",
    "& input": {
      padding: "0",
    },
  },
});
