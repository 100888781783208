import { DefaultSortEnum, SelectedTypeEnum } from "../../../enum/DefaultSortEnum";

export const sortFlights = (selectedType: string | undefined) => {
    if (selectedType === SelectedTypeEnum.ARRIVAL) {
      return DefaultSortEnum.FLIGHT_ARRIVAL;
    }
    if (selectedType === SelectedTypeEnum.DEPARTURE) {
      return DefaultSortEnum.FLIGHT_DEPARTURE;
    }
    return DefaultSortEnum.SCHEDULE;
  };