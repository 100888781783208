import { ITag, mergeStyleSets } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useState } from "react";
import EventManagementEnum from "../../../../enum/EventManagementEnum";
import { RESPONSETEAM_GROUPCOLUMNS } from "../../../../utils/events/EventConstants";
import { EventManagementHelper } from "../../../../utils/events/EventManagementHelper";
import { AmdDataGrid } from "../../../common/data-grid/AmdDataGrid";
import { AmdGroups } from "../../../common/icons/groups/Groups";
import { EventAddMembersByGroup } from "../add-members-by-group/EventAddMembersByGroup";

export interface IEventResponseTeamGroupProps {
  isNewForm: boolean;
  isInEditMode: boolean;
  mergedTeamState: any;
  manageResponseTeam: any;
  airport: string;
}

export const EventResponseTeamGroup: React.FunctionComponent<
  IEventResponseTeamGroupProps
> = (props: IEventResponseTeamGroupProps) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<any>();

  const onGroupAdded = (data: any) => {
    props.manageResponseTeam(
      EventManagementEnum.ResponseTeamResourceType.Group,
      EventManagementEnum.ResponseTeamResourceAction.Add,
      data
    );
    onModalPopupClose();
  };
  const onEditGroup = (item: any) => {
    setSelectedGroupId(item.id);
    const filteredGroups = props.mergedTeamState?.responseTeam?.groups.filter(
      (group: any) => group.id === item.id
    );

    if (filteredGroups && filteredGroups?.length > 0) {
      setSelectedGroup(filteredGroups[0]);
      showModal();
    }
  };

  const onDeleteGroup = (data: any) => {
    props.manageResponseTeam(
      EventManagementEnum.ResponseTeamResourceType.Group,
      EventManagementEnum.ResponseTeamResourceAction.Delete,
      data
    );
  };

  const onModalPopupClose = () => {
    setSelectedGroupId("");
    setSelectedGroup([] as ITag[]);
    hideModal();
  };

  const responseTeamGroup = props?.mergedTeamState?.responseTeam?.groups;
  const responseTeamGroupArray = EventManagementHelper.getResponseTeamGroups(
    responseTeamGroup ?? []
    )
  return (
    <div data-testid="response-teamgroup-container">
      <div className="mt-2">
        <div className="fc--gray900 mb-1 fs-18">Groups</div>
        {(props.isNewForm || props.isInEditMode) && (
          <>
            <div
              onClick={showModal}
              onKeyDown={showModal}
              className={`mt-3 ${contentStyles.addGroup} mb-2`}
            >
              <AmdGroups />
              <span className={`${contentStyles.addGroupText}`}>Add group</span>
            </div>
            <EventAddMembersByGroup
              data-testid="eventAddMembersByGroup"
              hideDialog={!isModalOpen}
              selectedGroup={props.isInEditMode ? selectedGroup : null}
              selectedGroupId={props.isInEditMode ? selectedGroupId : ""}
              onModalPopupClose={onModalPopupClose}
              onGroupAdded={onGroupAdded}
              airport={props.airport}
            ></EventAddMembersByGroup>
          </>
        )}
      </div>

      {responseTeamGroupArray?.length > 0 && (
        <div className="mt-1">
          <AmdDataGrid
            showActionButtons={true}
            rowItems={responseTeamGroupArray}
            columns={RESPONSETEAM_GROUPCOLUMNS}
            minHeight="250px"
            onEditItem={onEditGroup}
            onDeleteItem={onDeleteGroup}
          />
        </div>
      )}
    </div>
  );
};

const contentStyles = mergeStyleSets({
  addGroup: {
    color: "#005EB8",
    cursor: "pointer",
    width: "115px",
  },
  addGroupText: {
    float: "right",
    marginRight: "10px",
  },
  addGroupIcon: {
    float: "left !important",
    paddingRight: "10px",
  },
});
