import ResourceEnum from "../../../enum/ResourceEnum";
import IFilters from "../../../models/filters/IFilters";
import { IFlightScheduleFilters } from "../../../models/flights/IFlightScheduleFilters";
import {
  FLIGHT_LEG_TYPE_TABS,
  FLIGHT_LIST_HEADER,
} from "../../../utils/flights/FlightConstants";
import { AmdTabs } from "../../common/tabs/tabs";
import { FilterPanel } from "../../filters/filter-panel/FilterPanel";
import { FlightListPagination } from "../../filters/pagination/FlightListPagination";
import { FlightDetailList } from "../flights-list/FlightDetailList";

export const FlightListContainer: React.FunctionComponent<
  IFlightListContainerProps
> = (props: IFlightListContainerProps) => {
  return (
    <>
      <div
        data-testid="flight-list-container"
        className="amd-container-hspacebtwn amd-container-vcenter mb-2"
      >
        <div className="d-flex ai-center">
          <span className="fs-24 fw-400 fc--gray900 mr-4">
            {(FLIGHT_LIST_HEADER as { [k in string]: any })[props.selectedTab]}
          </span>
          {(props.selectedTab === ResourceEnum.ResourceType.OTP ||
            props.selectedTab === ResourceEnum.ResourceType.SlotAdherence) && (
            <AmdTabs
              defaultSelectedTab="arrival"
              tabList={FLIGHT_LEG_TYPE_TABS}
              dataTestId="flight-type"
              styleType="secondary"
              onTabClick={props.onOtpFlightTypeChange}
              selectedTab={props.otpFlightListState.flightType}
            />
          )}
        </div>
        <FlightListPagination
          onDropDownChange={props.onOtpPageDropDownChange}
          flightCount={props.totalFlights}
        ></FlightListPagination>
      </div>
      <FilterPanel
        filters={props.flightSchedulefilters}
        onFiltersApplied={props.onFiltersApplied}
        onUpdateFilter={props.onUpdateFilter}
        airport={props.airport}
      />
      <FlightDetailList
        flightListState={props.otpFlightListState}
        selectedType={props.otpFlightListState.flightType}
        getFlightTotal={props.getFlightTotal}
        viewType={props.selectedTab}
        isOTPView={true}
        airport={props.airport}
      />
    </>
  );
};

export interface IFlightListContainerProps {
  onOtpFlightTypeChange: any;
  otpFlightListState: IFlightScheduleFilters;
  onOtpPageDropDownChange: any;
  flightSchedulefilters: IFilters[];
  onFiltersApplied: any;
  onUpdateFilter: any;
  getFlightTotal: any;
  selectedTab: string;
  totalFlights?: number;
  airport: string;
}
