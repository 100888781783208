import React from "react";

export const TerminalIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.42855 0.84375C6.42855 0.654687 6.26783 0.5 6.0714 0.5H4.99998H3.92855C3.73212 0.5 3.5714 0.654687 3.5714 0.84375C3.5714 1.03281 3.73212 1.1875 3.92855 1.1875H4.64283V1.875H3.5714C3.17631 1.875 2.85712 2.18223 2.85712 2.5625V3.25H1.05132C0.466494 3.25 0.0401545 3.78281 0.187476 4.32637L1.01337 7.3793C0.843726 7.40508 0.714262 7.54688 0.714262 7.71875C0.714262 7.90781 0.874976 8.0625 1.0714 8.0625H2.14283V11.1562C2.14283 11.3453 2.30355 11.5 2.49998 11.5C2.6964 11.5 2.85712 11.3453 2.85712 11.1562V8.0625H7.14283V11.1562C7.14283 11.3453 7.30355 11.5 7.49998 11.5C7.6964 11.5 7.85712 11.3453 7.85712 11.1562V8.0625H8.92855C9.12498 8.0625 9.28569 7.90781 9.28569 7.71875C9.28569 7.54688 9.15623 7.40508 8.98658 7.3793L9.81248 4.32637C9.9598 3.78281 9.53346 3.25 8.94864 3.25H7.14283V2.5625C7.14283 2.18223 6.82364 1.875 6.42855 1.875H5.35712V1.1875H6.0714C6.26783 1.1875 6.42855 1.03281 6.42855 0.84375ZM7.14283 3.9418C7.14283 3.93965 7.14283 3.93965 7.14283 3.9375H8.94864C9.06471 3.9375 9.15176 4.04492 9.12051 4.15234L8.24774 7.375H7.14283V3.9418ZM6.42855 7.375H3.5714V3.9418C3.5714 3.93965 3.5714 3.93965 3.5714 3.9375H6.42855V7.375ZM2.85712 3.9375V7.375H1.74998L0.877208 4.15234C0.84819 4.04277 0.933012 3.9375 1.04908 3.9375H2.85712ZM3.5714 3.25V2.5625H6.42855V3.25H3.5714Z"
        fill="black"
      />
    </svg>
  );
};
