/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import IFilters from "../../models/filters/IFilters";

const initialState = {
  resourceAllocationFilters: [] as IFilters[],
};

const resourceAllocationFilterSlice = createSlice({
  name: "resourceAllocationFilters",
  initialState,
  reducers: {
    setResourceAllocationFilters(state, action) {
      localStorage.setItem(
        `${action.payload.airport}resourceAllocationFilters`,
        JSON.stringify(action.payload.filter)
      );
      state.resourceAllocationFilters = action.payload.filter;
    },
  },
});

export const resourceAllocationFilterActions =
  resourceAllocationFilterSlice.actions;

export default resourceAllocationFilterSlice.reducer;
