import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const AmdResource: React.FunctionComponent<IAmdIconProps> = ({
  width = "48",
  height = "48",
  color = "#9BCAEB",
  viewBox = "0 0 48 48",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.43887 14.6552L7.43886 14.6552L7.4366 14.6671C7.4366 14.6671 7.4366 14.6671 7.4366 14.6671C7.38537 14.9328 7.30406 15.1878 7.19629 15.4285L2.57148 10.8037C2.81578 10.6943 3.07482 10.6122 3.34479 10.5611L3.25183 10.0699L3.34479 10.5611C3.36242 10.5578 3.38009 10.5546 3.39782 10.5515L3.31248 10.0589L3.39782 10.5515C3.59313 10.5177 3.7943 10.5 4 10.5C5.933 10.5 7.5 12.067 7.5 14C7.5 14.2244 7.47895 14.4433 7.43887 14.6552ZM0.561134 13.3448L0.0700515 13.2519L0.561136 13.3448L0.563401 13.3329C0.614627 13.0672 0.695935 12.8122 0.803709 12.5715L5.42852 17.1963C5.18422 17.3057 4.92519 17.3878 4.65523 17.4389C4.63757 17.4422 4.61989 17.4454 4.60219 17.4485C4.40687 17.4823 4.2057 17.5 4 17.5C2.067 17.5 0.5 15.933 0.5 14C0.5 13.7756 0.521046 13.5567 0.561134 13.3448ZM10.5 3C10.5 1.61929 11.6193 0.5 13 0.5H15C16.3807 0.5 17.5 1.61929 17.5 3V5C17.5 6.38071 16.3807 7.5 15 7.5H13C11.6193 7.5 10.5 6.38071 10.5 5V3ZM2.99699 1.1231C3.41485 0.292302 4.58515 0.292301 5.003 1.1231L7.37489 5.83896C7.76072 6.60606 7.20484 7.5 6.37189 7.5L1.62811 7.5C0.795165 7.5 0.239285 6.60606 0.625108 5.83896L2.99699 1.1231ZM13.7493 10.5606C13.9057 10.4798 14.0943 10.4798 14.2507 10.5606L17.2243 12.0963C17.3983 12.1862 17.5 12.3579 17.5 12.5373V15.4627C17.5 15.6421 17.3983 15.8138 17.2243 15.9037L14.2507 17.4394C14.0943 17.5202 13.9057 17.5202 13.7493 17.4394L10.7757 15.9037C10.6017 15.8138 10.5 15.6421 10.5 15.4627V12.5373C10.5 12.3579 10.6017 12.1862 10.7757 12.0963L13.7493 10.5606Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
