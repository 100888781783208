import {
  DatePicker,
  DayOfWeek,
  defaultDatePickerStrings,
  IComboBoxStyles,
} from "@fluentui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface IAmdDatePickerProps {
  controlId: string;
  label: string;
  styles?: Partial<IComboBoxStyles>;
  minDate?: Date;
  firstDayOfWeek: DayOfWeek;
  isMandatory: boolean;
  defaultValue?: Date;
  isReadOnly?: boolean;
}

export const AmdDatePicker: React.FunctionComponent<IAmdDatePickerProps> = (
  props: IAmdDatePickerProps
) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.controlId}
      control={control}
      rules={{ required: props.isMandatory }}
      render={({ field }) => (
        <DatePicker
          {...field}
          styles={props.styles ?? undefined}
          firstDayOfWeek={props.firstDayOfWeek}
          placeholder={props.label}
          ariaLabel={props.label}
          label={props.label}
          strings={defaultDatePickerStrings}
          isRequired={props.isMandatory}
          minDate={props.minDate ?? undefined}
          value={props.defaultValue ?? undefined}
          disabled={props.isReadOnly ?? false}
          onSelectDate={(e: any) => field.onChange(e)}
        />
      )}
    />
  );
};
