import {
  DisruptionName,
  WeatherDisruptionKPIEnum,
} from "../../../enum/EventManagementEnum";

export function mappedResourceType(selectedKpiId: string) {
  switch (selectedKpiId) {
    case WeatherDisruptionKPIEnum.DeicingCapacity:
      return DisruptionName.DeicingCapacity;
    case WeatherDisruptionKPIEnum.AircraftOnGround:
      return WeatherDisruptionKPIEnum.AircraftOnGround;
    case WeatherDisruptionKPIEnum.PassengerOnGround:
      return WeatherDisruptionKPIEnum.PassengerOnGround;
    case WeatherDisruptionKPIEnum.DepartureCapacity:
      return DisruptionName.DepartureCapacity;
    case WeatherDisruptionKPIEnum.Baggage:
      return DisruptionName.Baggage;
    case WeatherDisruptionKPIEnum.Weather:
      return DisruptionName.Weather;
    default:
      return DisruptionName.Blank;
  }
}
