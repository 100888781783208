import moment from "moment-timezone";

function getUpdatedSlotTime(name: string) {
  const slotTimeBandArr = name?.split("|");
  const [start] = slotTimeBandArr;
  const slotStartTime = moment(start, moment.ISO_8601);
  const slotStartTimeFormatted = slotStartTime.format("hh:mm");
  const [, end] = slotTimeBandArr;
  const slotEndTime = moment(end, moment.ISO_8601);
  const slotEndTimeFormatted = slotEndTime.format("hh:mm");

  return slotStartTime.isValid() && slotEndTime.isValid()
    ? `${slotStartTimeFormatted}-${slotEndTimeFormatted}`
    : name;
}

export default getUpdatedSlotTime;
