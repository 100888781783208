import { createSlice } from "@reduxjs/toolkit";
import {
  SelectedGraphDatum,
  SelectedTablePlanningKPI,
} from "../../models/events/IWeatherDisruptionSummary";

export interface WeatherDisruption {
  weatherDisruption: WeatherDisruptionState;
}

export interface WeatherDisruptionKPICardType {
  id: string;
  kpi: string;
  body: string;
  unit: string;
  subBody: string;
  leftFooterTitle: string;
  leftFooterBody: string;
  rightFooterTitle: string;
  rightFooterBody: string;
}

export interface WeatherDisruptionState {
  selectedKpiId: string;
  selectedKpi: string;
  crisisWeatherDisruptionKPICard: WeatherDisruptionKPICardType;
  selectedDisruptionKPI: SelectedTablePlanningKPI;
  selectedGraphData: SelectedGraphDatum[];
  selectedPlanningData: any;
  weatherDisruptionCards: WeatherDisruptionKPICardType[];
}

export const WEATHER_DISRUPTION_INITIAL_STATE: WeatherDisruption = {
  weatherDisruption: {
    selectedKpiId: "",
    selectedKpi: "",
    crisisWeatherDisruptionKPICard: {
      id: "",
      kpi: "",
      body: "",
      unit: "",
      subBody: "",
      leftFooterTitle: "",
      leftFooterBody: "",
      rightFooterTitle: "",
      rightFooterBody: "",
    },
    weatherDisruptionCards: [],
    selectedDisruptionKPI: {
      width: 1200,
      height: 330,
      allowMultipleShapes: false,
      //selectedTouchPoint: "",
      //selectedTouchPointFilter: "",
      //selectedStartTime: DateHelper.getFormattedDateTime(new Date(), "HH:mm"),
      // selectedEndTime: PassengerHelper.getUpdatedPAXFlowTimeBasedOnInterval(
      //   DateHelper.getFormattedDateTime(new Date(), "HH:mm"),
      //   PAX_TOUCHPOINTS_REFRESH_INTERVAL[0].value,
      //   true
      // ),
      // selectedRefreshInterval: PAX_TOUCHPOINTS_REFRESH_INTERVAL[0].value,
      weatherDisruptionChartData: {
        lineChartData: [],
      },
      //touchPointTableDataArray: [],
      //touchPointTableColumnsArray: [],
      weatherDisruptionChartLegendArray: [],
      currentTimeMinValue: "",
      currentTimeMaxValue: "",
      chartTitle: "",
    },
    selectedGraphData: [],
    selectedPlanningData: null,
  },
};

export const weatherDisruptionSlice = createSlice({
  name: "weatherDisruption",
  initialState: WEATHER_DISRUPTION_INITIAL_STATE,
  reducers: {
    setDisruptionKpi: (state, action) => {
      const {
        selectedKpiId,
        selectedKpi,
        selectedGraphData,
        selectedDisruptionKPI,
        selectedPlanningData,
      } = action.payload;
      const { selectedDisruptionKPI: prevSelectedDisruptionKPI } =
        state.weatherDisruption;
      state.weatherDisruption.selectedKpi = selectedKpi;
      state.weatherDisruption.selectedPlanningData = selectedPlanningData;
      state.weatherDisruption.selectedKpiId = selectedKpiId;
      state.weatherDisruption.selectedGraphData = selectedGraphData || [];
      state.weatherDisruption.selectedDisruptionKPI = {
        ...prevSelectedDisruptionKPI,
        ...selectedDisruptionKPI,
      };
    },
    setWeatherDisruptionTableKPI: (state, action) => {
      const { selectedDisruptionKPI } = action.payload;
      state.weatherDisruption.selectedDisruptionKPI = selectedDisruptionKPI;
    },
    setGraphicalData: (state, action) => {
      const { selectedGraphData } = action.payload;
      state.weatherDisruption.selectedGraphData = selectedGraphData;
    },
    setPlanningData: (state, action) => {
      const { selectedPlanningData } = action.payload;
      state.weatherDisruption.selectedPlanningData = selectedPlanningData;
    },
    setWeatherDisruptionCards: (state, action) => {
      const { weatherDisruptionCards } = action.payload;
      state.weatherDisruption.weatherDisruptionCards = weatherDisruptionCards;
    },
  },
});

export const {
  setDisruptionKpi,
  setWeatherDisruptionTableKPI,
  setGraphicalData,
  setPlanningData,
  setWeatherDisruptionCards,
} = weatherDisruptionSlice.actions;

export const weatherDisruptionReducer = weatherDisruptionSlice.reducer;
