import { IFlightHourFilter } from "../../../models/flights/IFlightHour";
import { IPillButton } from "../../../models/flights/IFlightPillButton";
import { IFlightScheduleFilters } from "../../../models/flights/IFlightScheduleFilters";
import FlightScheduleHelper from "../../../utils/flights/FlightScheduleHelper";
import { AmdTabs } from "../../common/tabs/tabs";
import { FilterPanel } from "../../filters/filter-panel/FilterPanel";
import { FlightPillButtonFilter } from "../../filters/flight-pillbuttons/FlightPillButtonFilter";
import { FlightTypeFilter } from "../../filters/flight-type/FlightTypeFilter";
import { FlightListPagination } from "../../filters/pagination/FlightListPagination";
import { FlightsKPI } from "../../flights/flights-360/FlightsKPI";

const hourFilterButtons: IFlightHourFilter[] =
  FlightScheduleHelper.getHourFilterButtons();
export const FlightsFilterSection: React.FunctionComponent<
  IFlightsFilterSection
> = (props: IFlightsFilterSection) => {
  const pillButtons: IPillButton[] = FlightScheduleHelper.getPillButtons(
    props.airport
  );
  return (
    <>
      {!props.isDetailsPage && (
        <>
          <FlightsKPI
            airport={props.airport}
            displayOtp={props.isDetailsPage ? false : true}
          />
          <section
            className="mt-4 amd-container-hspacebtwn"
            data-testid="flights-macro-card"
          >
            <FlightTypeFilter
              onChange={(changedFlightType: string) => {
                props.onFlightTypeChange(changedFlightType);
              }}
              selectedType={props.flightListState.flightType}
            />
            <AmdTabs
              position="right"
              defaultSelectedTab="1"
              tabList={hourFilterButtons}
              dataTestId="HourFilterTab"
              onTabClick={props.onHourFilterClick}
              selectedTab={props.selectedHourFilter}
            />
          </section>
          <section className="mt-sm-1 mb-sm-2 amd-container-hspacebtwn">
            <FlightPillButtonFilter
              buttons={pillButtons}
              onPillButtonClick={props.onPillButtonClick}
            />
            <FlightListPagination
              onDropDownChange={props.onPageDropDownChange}
              flightCount={props.totalFlights}
            ></FlightListPagination>
          </section>
        </>
      )}
      {props.isDetailsPage && (
        <>
          <div
            className="amd-container-hspacebtwn amd-container-vcenter mb-2 filter-details-page"
            data-testid="filter-details-page"
          >
            <FlightTypeFilter
              onChange={(changedFlightType: string) => {
                props.onFlightTypeChange(changedFlightType);
              }}
              selectedType={props.flightListState.flightType}
            />
            <FlightListPagination
              onDropDownChange={props.onPageDropDownChange}
              flightCount={props.totalFlights}
            />
          </div>
          <FilterPanel
            filters={props.flightSchedulefilters}
            onFiltersApplied={props.onFiltersApplied}
            onUpdateFilter={props.onUpdateFilter}
            airport={props.airport}
          />
        </>
      )}
    </>
  );
};

export interface IFlightsFilterSection {
  isDetailsPage: boolean;
  onFlightTypeChange: any;
  onHourFilterClick: any;
  selectedHourFilter: string;
  onPillButtonClick: any;
  onPageDropDownChange: any;
  totalFlights?: number;
  flightListState: IFlightScheduleFilters;
  flightSchedulefilters: any;
  onFiltersApplied: any;
  onUpdateFilter: any;
  airport: string;
}
