import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  DialogFooter,
  FontWeights,
  getTheme,
  mergeStyleSets,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationContext } from "../../../context/NotificationContext";
import EventManagementEnum from "../../../enum/EventManagementEnum";
import TagPickerEnum from "../../../enum/TagPickerEnum";
import { MESSAGEBAR_TYPE } from "../../../utils/common/Constants";
import { EventManagementHelper } from "../../../utils/events/EventManagementHelper";
import { AmdTagPickControl } from "../../common/form-controls/controlled/TagPicker/AmdTagPickControl";
import { AmdTextField } from "../../common/form-controls/controlled/TextField/AmdTextField";
import { AmdModal } from "../../common/form-controls/uncontrolled/Modal/AmdModal";

export interface IUpdateKpiPopupProps {
  hideDialog: any;
  onModalPopupClose: any;
  onKpiUpdate?: any;
  selectedKpiId?: string;
  selectedKpi?: any;
  eventId: string;
  airport: string;
}

export const SharePopup: React.FunctionComponent<IUpdateKpiPopupProps> = (
  props: IUpdateKpiPopupProps
) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const { addNotification } = React.useContext(NotificationContext);

  const methods = useForm();

  const onErrors = (errors: any, _e: any) => {
    console.log(errors);
  };

  const onShare = async (data: any) => {
    const response = await EventManagementHelper.onCrisisKPIsShare(
      msalInstance,
      props.eventId,
      data,
      props.airport
    );
    if (response.status === 200) {
      addNotification(MESSAGEBAR_TYPE.SUCCESS, `KPIs shared successfully!`);
    }
    methods.reset(formFieldsData);
    props.onModalPopupClose();
  };

  return (
    <div id="sharePopup" data-testid="sharePopup">
      <AmdModal
        isOpen={!props.hideDialog}
        header={"Share post in teams channel"}
        onClose={props.onModalPopupClose}
        containerClassName={contentStyles.container}
        prefixImageIconBeforeText={false}
      >
        <div>
          <FormProvider {...methods}>
            <form
              onSubmit={(event: any) => {
                event.stopPropagation();
                methods.handleSubmit(onShare, onErrors)(event);
              }}
              noValidate
            >
              <div
                id="shareContainer"
                data-testid="shareContainer"
                className={`${modalStyles.body}`}
              >
                <div className="mt-1">
                  Write a message and select the KPI that you want to share.
                  <AmdTagPickControl
                    controlId={EventManagementEnum.Share.Channel}
                    tagLabel="Channels"
                    isMandatory={true}
                    suggestionType={TagPickerEnum.TagPickerType.Channel}
                    className={`${contentStyles.shareDialogbox}`}
                    eventId={props.eventId}
                    airport={props.airport}
                  />
                  <AmdTextField
                    label="Subject"
                    controlId={EventManagementEnum.Share.subject}
                    isMandatory={true}
                    isMultiline={false}
                    className={`${contentStyles.subjectbox}`}
                    isReadOnly={false}
                  />
                  <AmdTextField
                    label="Message"
                    controlId={EventManagementEnum.Share.message}
                    isMandatory={true}
                    isMultiline={true}
                    className={`${contentStyles.messagebox}`}
                    isReadOnly={false}
                  />
                  <AmdTagPickControl
                    controlId={EventManagementEnum.Share.Kpi}
                    tagLabel="KPIs to be shared"
                    isMandatory={true}
                    suggestionType={TagPickerEnum.TagPickerType.Kpi}
                    className={`${contentStyles.shareDialogbox}`}
                    airport={props.airport}
                  />
                  <DialogFooter>
                    <DefaultButton
                      onClick={() => {
                        props.onModalPopupClose();
                        methods.reset(formFieldsData);
                      }}
                      text="Cancel"
                    />
                    <PrimaryButton type="submit" text="Share" />
                  </DialogFooter>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </AmdModal>
    </div>
  );
};

const theme = getTheme();
const borderColor = "1px solid rgb(204, 204, 204) !important";
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "85vh",
    width: "40vw !important",
  },
  header: [
    theme.fonts.xLarge,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0",
  },
  bg: {
    backgroundColor: "#666666",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  subjectbox: {
    //width: "130px",
    background: "white",
    "div.ms-TextField-fieldGroup": {
      height: "30px !important",
      border: borderColor,
    },
  },
  messagebox: {
    //width: "130px",
    background: "white",
    "div.ms-TextField-fieldGroup": {
      height: "30px !important",
      border: borderColor,
    },
  },
  shareDialogbox: {
    "div.ms-BasePicker-text": {
      border: borderColor,
    },
  },
});

const modalStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "25vh",
    width: "30vw",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const formFieldsData = {
  [TagPickerEnum.TagPickerType.Kpi]: "",
  [TagPickerEnum.TagPickerType.Channel]: "",
  [EventManagementEnum.Share.subject]: "",
  [EventManagementEnum.Share.message]: "",
};
