import React from "react";
import { IAmdIconProps } from "../IAmdIconProps";

export const MapSignsIcon: React.FunctionComponent<IAmdIconProps> = (
  {
    color = "#000521",
    dataTestId = "map-signs-icon",
    ...props
  }
) => {
  return (
    <span className={props.className}>
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={dataTestId}
      >
        <path
          d="M16.8438 3.15625C17.0312 3.34375 17.0312 3.6875 16.8438 3.875L15.5 5.21875C15.2812 5.40625 15.0312 5.5 14.7812 5.5H2.75C2.3125 5.5 2 5.1875 2 4.75V2.25C2 1.84375 2.3125 1.5 2.75 1.5H8V1C8 0.75 8.21875 0.5 8.5 0.5H9.5C9.75 0.5 10 0.75 10 1V1.5H14.7812C15.0312 1.5 15.3125 1.625 15.5 1.8125L16.8438 3.15625ZM8 16V12.5H10V16C10 16.2812 9.75 16.5 9.5 16.5H8.5C8.21875 16.5 8 16.2812 8 16ZM15.25 7.5C15.6562 7.5 16 7.84375 16 8.25V10.75C16 11.1875 15.6562 11.5 15.25 11.5H3.1875C2.9375 11.5 2.6875 11.4062 2.5 11.2188L1.125 9.875C0.9375 9.6875 0.9375 9.34375 1.125 9.15625L2.5 7.8125C2.6875 7.625 2.9375 7.5 3.1875 7.5H8V6.5H10V7.5H15.25Z"
          fill={color}
        />
      </svg>
    </span>
  );
};