import { mergeStyles } from "@fluentui/react";
import { ProgressBar } from "@fluentui/react-components";

export const AmdProgressIndicator: React.FunctionComponent<
  IAmdProgressIndicator
> = (props: IAmdProgressIndicator) => {
  const progressBarStyles = mergeStyles({
    width: "185px !important",
    height: "10px !important",
  });

  return (
    <ProgressBar
      className={progressBarStyles}
      value={props.value}
      color={props.color}
      shape={props.shape}
      data-testid="progress-bar"
    />
  );
};

export interface IAmdProgressIndicator {
  value: number;
  color?: "success" | "warning" | "error";
  size?: "medium" | "large";
  shape?: "rounded" | "square";
}
