import MULTI_AIRPORTS_MOCK from "../../mock/multi-airports/multi-airports.json";
import IAirportSummary from "../../models/multi-airports/IAirportSummary";

export default class MultiAirportService {
  public getAirports = async (
    _accessToken: string
  ): Promise<IAirportSummary[]> => {
    return Promise.resolve(MULTI_AIRPORTS_MOCK as IAirportSummary[]);
  };
}
