import {
  DefaultButton,
  DialogFooter,
  FontWeights,
  IStackProps,
  PrimaryButton,
  Stack,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationContext } from "../../../context/NotificationContext";
import AdminEnum from "../../../enum/AdminEnum";
import TagPickerEnum from "../../../enum/TagPickerEnum";
import { MESSAGEBAR_TYPE } from "../../../utils/common/Constants";
import { AmdTagPickControl } from "../../common/form-controls/controlled/TagPicker/AmdTagPickControl";
import { AmdTextField } from "../../common/form-controls/controlled/TextField/AmdTextField";
import { AmdModal } from "../../common/form-controls/uncontrolled/Modal/AmdModal";

export const AddUserPopup: React.FunctionComponent<{
  hideDialog: boolean;
  onModalPopupClose: any;
  airport: string;
}> = ({ hideDialog, onModalPopupClose, airport }) => {
  const methods = useForm();
  const { addNotification } = React.useContext(NotificationContext);

  const onCreateUser = (data: any) => {
    console.log(data);
    addNotification(
      MESSAGEBAR_TYPE.SUCCESS,
      `Added user: ${data.name} successfully!`
    );

    // Reset the form field values and close the popup.
    methods.reset(formFieldsData);
    onModalPopupClose();
  };

  const onErrors = (errors: any, _e: any) => {
    console.log(errors);
  };

  return (
    <div data-testid="addUserPopup">
      <AmdModal
        isOpen={!hideDialog}
        header="Add a new User"
        onClose={onModalPopupClose}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.body}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onCreateUser, onErrors)}
              noValidate
            >
              <Stack horizontal tokens={stackTokens}>
                <Stack {...columnProps}>
                  {textFieldControlsJson().map((json: any) => {
                    return (
                      <AmdTextField
                        key={json.label}
                        controlId={json.fieldName}
                        label={json.label}
                        isMandatory={json.isMandatory}
                        isMultiline={json.multiLine}
                      />
                    );
                  })}

                  {tagPickerControlsJson().map((json: any) => {
                    return (
                      <AmdTagPickControl
                        key={json.label}
                        controlId={json.fieldName}
                        tagLabel={json.label}
                        isMandatory={json.isMandatory}
                        suggestionType={json.suggestionType}
                        airport={airport}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <DialogFooter>
                <DefaultButton
                  onClick={() => {
                    onModalPopupClose();
                  }}
                  text="Cancel"
                />
                <PrimaryButton type="submit" text="Add" />
              </DialogFooter>
            </form>
          </FormProvider>
        </div>
      </AmdModal>
    </div>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "60vh",
    width: "30vw",
  },
  header: [
    theme.fonts.xLarge,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

const stackTokens = { childrenGap: 2, root: { width: "100%" } };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: "90vw" } },
};

const textFieldControlsJson = () => {
  return [
    {
      label: "Name",
      fieldName: AdminEnum.User.Name,
      multiLine: false,
      isMandatory: true,
    },
    {
      label: "Email",
      fieldName: AdminEnum.User.Email,
      multiLine: false,
      isMandatory: true,
    },
  ];
};

const tagPickerControlsJson = () => {
  return [
    {
      label: "Role",
      fieldName: AdminEnum.User.Roles,
      suggestionType: TagPickerEnum.TagPickerType.Roles,
      isMandatory: false,
    },
    {
      label: "Organization",
      fieldName: AdminEnum.User.Organizations,
      suggestionType: TagPickerEnum.TagPickerType.Organizations,
      isMandatory: false,
    },
    {
      label: "Responsible Areas",
      fieldName: AdminEnum.User.Responsibilities,
      suggestionType: TagPickerEnum.TagPickerType.Responsibilities,
      isMandatory: false,
    },
    {
      label: "Terminal(s)",
      fieldName: AdminEnum.User.Terminals,
      suggestionType: TagPickerEnum.TagPickerType.Terminals,
      isMandatory: false,
    },
  ];
};

const formFieldsData = {
  [AdminEnum.User.Name]: "",
  [AdminEnum.User.Email]: "",
  [AdminEnum.User.Roles]: "",
  [AdminEnum.User.Organizations]: "",
  [AdminEnum.User.Responsibilities]: "",
  [AdminEnum.User.Terminals]: "",
};
