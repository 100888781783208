export function sortColumnByDate<T>(
  items: T[],
  key: string,
  isSortedDescending: boolean
) {
  if (!items || !items?.length) {
    return [];
  }
  const columnKey = key as keyof T;
  const sortedItems = items.slice(0).sort((a: T, b: T) => {
    const aKey = a[columnKey];
    const bKey = b[columnKey];
    const aDateKey = new Date(aKey as string);
    const bDateKey = new Date(bKey as string);

    return (isSortedDescending ? aDateKey < bDateKey : aDateKey > bDateKey)
      ? 1
      : -1;
  });
  return sortedItems;
}
