import React from "react";

export const SuitcaseRollingIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.65625 3.375C9.31641 3.375 9.875 3.93359 9.875 4.59375V11.0938C9.875 11.7793 9.31641 12.3125 8.65625 12.3125H8.25V12.7188C8.25 12.9473 8.04688 13.125 7.84375 13.125H7.03125C6.80273 13.125 6.625 12.9473 6.625 12.7188V12.3125H3.375V12.7188C3.375 12.9473 3.17188 13.125 2.96875 13.125H2.15625C1.92773 13.125 1.75 12.9473 1.75 12.7188V12.3125H1.34375C0.658203 12.3125 0.125 11.7793 0.125 11.0938V4.59375C0.125 3.93359 0.658203 3.375 1.34375 3.375H2.5625V1.34375C2.5625 0.683594 3.0957 0.125 3.78125 0.125H6.21875C6.87891 0.125 7.4375 0.683594 7.4375 1.34375V3.375H8.65625ZM3.78125 1.34375V3.375H6.21875V1.34375H3.78125ZM7.84375 9.875C8.04688 9.875 8.25 9.69727 8.25 9.46875C8.25 9.26562 8.04688 9.0625 7.84375 9.0625H2.15625C1.92773 9.0625 1.75 9.26562 1.75 9.46875C1.75 9.69727 1.92773 9.875 2.15625 9.875H7.84375ZM7.84375 6.625C8.04688 6.625 8.25 6.44727 8.25 6.21875C8.25 6.01562 8.04688 5.8125 7.84375 5.8125H2.15625C1.92773 5.8125 1.75 6.01562 1.75 6.21875C1.75 6.44727 1.92773 6.625 2.15625 6.625H7.84375Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};
