import { Spinner } from "@fluentui/react-components";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import EventManagementEnum from "../../enum/EventManagementEnum";
import { useApiCall } from "../../hooks/useApiCall";
import IEventListItem from "../../models/events/IEventListItem";
import { IEventSummary } from "../../models/events/IEventSummary";
import IEventSummaryList from "../../models/events/IEventSummaryList";
import { AIRPORT_CODE_PARAMETER } from "../../utils/common/Constants";
import { EVENTLIST_COLUMNS } from "../../utils/events/EventConstants";
import { EventManagementHelper } from "../../utils/events/EventManagementHelper";
import { AmdDataGrid } from "../common/data-grid/AmdDataGrid";
import { NoDataCard } from "../common/no-data-card/NoDataCard";
import { EventItem } from "./event-container/event-item/EventItem";
import { EventSummaryCard } from "./home/summary-card/EventSummaryCard";

export interface IEventManagementProps {
  airport: string;
}

export const EventManagement: React.FunctionComponent<
  IEventManagementProps
> = ({ airport }) => {
  const [totalCase, setTotalCase] = useState<any[]>([]);
  const [inProgressCase, setInProgressCase] = useState<any[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<IEventListItem | null>();
  const [, setEventListRefreshTimestamp] = useState(new Date());

  const getEventListUrl = `${process.env.REACT_APP_API_EVENTS?.replace(
    AIRPORT_CODE_PARAMETER,
    airport
  )}`;

  const getEventSummaryUrl = `${process.env.REACT_APP_API_EVENTS_OVERVIEW?.replace(
    AIRPORT_CODE_PARAMETER,
    airport
  )}`;

  const {
    data: eventList,
    error,
    isLoading,
  } = useApiCall<IEventListItem[]>({
    url: getEventListUrl,
    dependencies: [airport],
  });

  const {
    data: eventSummaryData,
    error: eventSummaryerror,
    isLoading: eventSummaryIsLoading,
  } = useApiCall<IEventSummary[]>({
    url: getEventSummaryUrl,
    dependencies: [airport],
  });

  const eventSummaryList: IEventSummaryList[] | undefined = eventList?.map(
    (data: any) => {
      return {
        ...data,
        key: data.eventId,
        creationDateTime: moment(data.creationDateTime).format("lll"),
        closedDateTime:
          data.closedDateTime != null
            ? moment(data.closedDateTime).format("lll")
            : "",
      };
    }
  );

  useEffect(() => {
    const totalEvents = eventSummaryData?.find(
      (summaryItem: any) =>
        summaryItem.group === EventManagementEnum.EventSummaryItemType.ByStatus
    );

    const totalEventsChartData = totalEvents
      ? EventManagementHelper.getChartData(totalEvents)
      : [];
    const inProgressEvents = eventSummaryData?.find(
      (summaryItem: any) =>
        summaryItem.group ===
        EventManagementEnum.EventSummaryItemType.ActiveByType
    );
    const inProgressEventsChartData = inProgressEvents
      ? EventManagementHelper.getChartData(inProgressEvents)
      : [];

    setTotalCase(totalEventsChartData);
    setInProgressCase(inProgressEventsChartData);
  }, [eventSummaryData]);

  const onSelectEvent = (eventId: string): void => {
    const selectedEvent = eventList?.filter((event: any): boolean => {
      return event.eventId === eventId;
    })?.[0];
    setSelectedEvent(selectedEvent);
  };

  const refreshEventList = () => {
    setEventListRefreshTimestamp(new Date());
  };

  const showEventSummaryCard = !selectedEvent?.eventId;

  return (
    <div data-testid="eventManagementContainer">
      {eventSummaryIsLoading && (
        <Spinner
          labelPosition="below"
          label="Loading..."
          style={{ height: "25vh" }}
        />
      )}
      {isLoading && (
        <Spinner
          labelPosition="below"
          label="Loading..."
          style={{ height: "35vh" }}
        />
      )}

      {showEventSummaryCard && !eventSummaryIsLoading && !eventSummaryerror && (
        <EventSummaryCard
          totalCase={totalCase}
          inProgressCase={inProgressCase}
        />
      )}
      {showEventSummaryCard &&
      eventSummaryList &&
      !isLoading &&
      !error &&
      eventSummaryList.length > 0 ? (
        <AmdDataGrid
          showActionButtons={false}
          rowItems={eventSummaryList}
          columns={EVENTLIST_COLUMNS}
          onSelectEvent={onSelectEvent}
          eventId={selectedEvent?.eventId}
          viewType="eventManagement"
        />
      ) : null}
      {!isLoading &&
      showEventSummaryCard &&
      (!eventSummaryList || eventSummaryList?.length === 0) ? (
        <NoDataCard message="No events found" />
      ) : null}
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          {selectedEvent?.eventId && (
            <EventItem
              selectedEventId={selectedEvent?.eventId}
              refreshEventList={refreshEventList}
              airport={airport}
            />
          )}
        </div>
      </div>
    </div>
  );
};
