import { mergeStyleSets } from "@fluentui/react";

export const getPillStyles = () =>
  mergeStyleSets({
    pillWrapper: {
      height: 20,
      background: "#F2F2F2",
      borderRadius: 20,
      border: "1px solid #ccc",
      padding: "12px 22px",
      display: "inline-block",
    },
    pillLabel: {
      fontSize: 14,
      lineHeight: 20,
      color: "#1A1A1A",
      fontWeight: 400,
      verticalAlign: "top",
      marginRight: 8,
    },
    pillOnlyLabel: {
      fontSize: 14,
      lineHeight: 20,
      color: "#1A1A1A",
      fontWeight: 400,
      verticalAlign: "top",
    },
  });
