import { PublicClientApplication } from "@azure/msal-browser";
import { ILineChartPoints } from "@fluentui/react-charting";
import moment from "moment-timezone";
import FlightsEnum from "../../enum/FlightsEnum";
import ResourceEnum from "../../enum/ResourceEnum";
import { IFlightListServicePayload } from "../../models/flights/IFlightList";
import { IFlightScheduleFilters } from "../../models/flights/IFlightScheduleFilters";
import { IResourceGraphResponse } from "../../models/resource-allocation/IResourceGraph";
import ResourceAllocationService from "../../services/resource-allocation/resourceallocation.service";
import { APIHelper } from "../api/APIHelper";
import { FlightListHelper } from "../flights/FlightListHelper";
import {
  RESOURCE_ALLOCATION_TABS,
  RESOURCE_COLORS,
} from "../resource/ResourceConstants";

export abstract class ResourceGraphHelper {
  public static async getResourceGraphData(
    msalInstance: PublicClientApplication,
    flightListState: IFlightScheduleFilters,
    resourceType: string,
    airportCode: string
  ): Promise<any> {
    const flightListServicePayload =
      FlightListHelper.getFlightListServicePayload(flightListState);
    const resourceAllocationService = new ResourceAllocationService();
    const flightGraphServiceMethod = (
      accessToken: string,
      flightListServicePayload: IFlightListServicePayload
    ) => {
      return resourceAllocationService.getResourceGraphData(
        accessToken,
        flightListServicePayload,
        resourceType,
        airportCode
      );
    };

    return APIHelper.CallAPI(
      msalInstance,
      flightListServicePayload,
      flightGraphServiceMethod
    );
  }

  public static setResourceGraphData(
    response: IResourceGraphResponse[],
    resourceType: string,
    handleLegendClick?: any
  ) {
    let graphData: ILineChartPoints[] = [];
    graphData = response?.map((resource, index) => ({
      color: RESOURCE_COLORS.colors[index],
      legend: this.getLegendName(resourceType, resource.group),
      lineOptions: {
        strokeWidth: "2",
      },
      hideNonActiveDots: false,
      data: this.getAggregateData(resource),
      onLegendClick: handleLegendClick,
    }));
    return graphData;
  }
  public static getLegendName(resourceType: string, terminal: string) {
    return resourceType === ResourceEnum.ResourceType.Stand
      ? terminal
      : `Terminal ${terminal}`;
  }
  public static getAggregateData(resource: IResourceGraphResponse) {
    return resource.halfHourlyAggregate.map((data) => ({
      x: new Date(data.time),
      y: data.count,
      xAxisCalloutData: moment(new Date(data.time)).format("HH:mm"),
    }));
  }

  public static getResourceGraphTabs(legType: string, resource: string) {
    let tabs = [];
    if (resource) {
      tabs = (RESOURCE_ALLOCATION_TABS as { [k in string]: any })[resource];
      if (resource === ResourceEnum.Resource.Baggage) {
        if (legType === FlightsEnum.LegType.Arrival) {
          tabs = tabs.filter(
            (t: any) => t.value === ResourceEnum.ResourceType.Belts
          );
        } else if (legType === FlightsEnum.LegType.Departure) {
          tabs = tabs.filter(
            (t: any) => t.value === ResourceEnum.ResourceType.Chutes
          );
        }
      }
    }
    return tabs;
  }
}
