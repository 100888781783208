import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Callout, Link, mergeStyleSets, SearchBox } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React from "react";
import PageEnum from "../../../enum/PageEnum";
import IAirportSummary from "../../../models/multi-airports/IAirportSummary";
import { CommonHelper } from "../../../utils/common/CommonHelper";
import { HOPHelper } from "../../../utils/health-of-operations/HOPHelper";
import { MultiAirportHelper } from "../../../utils/multi-airports/MultiAirportHelper";
import { HOPAlertIcon } from "../../health-of-operations/HOPAlertIcon";
import { AmdAirplane } from "../icons/airplane/airplane";
import { AmdDownIcon } from "../icons/down/Down";
import { AmdUpIcon } from "../icons/up/Up";

export interface IMultiAirportPanelProps {
  airport: string;
}

export const MultiAirportPanel: React.FunctionComponent<IMultiAirportPanelProps> = ({ airport }) => {
  const msalInstance = useMsal().instance as PublicClientApplication;
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const searchBoxId = useId("searchBox");
  const [airportList, setAirportList] = React.useState<IAirportSummary[]>();
  const [airportListClone, setAirportListClone] = React.useState<IAirportSummary[]>();
  const [selectedAirport, setSelectedAirport] = React.useState<IAirportSummary>();

  React.useEffect(() => {
    if (airportList && airportListClone) {
      selectAirportByCode(airport);
    }
  }, [airportList, airportListClone, airport]);

  React.useEffect(() => {
    let isMounted = true;
    fetchAirports(msalInstance, isMounted);
    return () => { isMounted = false; };
  }, [airport, msalInstance]);

  const fetchAirports = async (msalInstance: PublicClientApplication, isMounted: boolean) => {
    const response = await MultiAirportHelper.getAirports(msalInstance);
    if (isMounted) {
      setAirportList(response);
      setAirportListClone([...response]);
      selectAirportByCode(airport, response);
    }
  };

  const selectAirportByCode = (airportCode: string, airports = airportListClone) => {
    const index = airportCode === "DEL" ? 2 : 4;
    if(airports?.length) {
      setSelectedAirport(airports[index]);
    }
  };

  const selectAirport = (selectedAirportId: string) => {
    const _selectedAirport = MultiAirportHelper.searchAirportById(selectedAirportId, airportListClone);
    setSelectedAirport(_selectedAirport);
  };

  const searchAirport = (searchText: string) => {
    const filteredAirports = MultiAirportHelper.searchAirports(searchText, airportListClone);
    setAirportList(filteredAirports);
  };

  const clearSearch = () => {
    setAirportList(airportListClone);
  };

  const hrefClassName = CommonHelper.navLinkStyle(`/${PageEnum.PageUrl.MultiAirports}`);

  return selectedAirport ? (
    <section className="amd-container-vcenter" data-testid="multi-airport-panel">
      <div className="w-80 amd-container-vcenter">
        <div id={searchBoxId} onClick={toggleIsCalloutVisible} onKeyDown={toggleIsCalloutVisible} className="cursor-pointer">
          <div className="float-l">{selectedAirport.code} - {selectedAirport.name}</div>
          {isCalloutVisible ? <AmdUpIcon className="float-l pl-1 pr-1 mt-1" /> : <AmdDownIcon className="float-l pl-1 pr-1" />}
        </div>
        <div className="float-l w-10 divider-v-grey w-10 text-c">{selectedAirport.location}</div>
      </div>
      <div className="float-r amd-container-vcenter w-20 jc-end">
        <AmdAirplane />
        <span className="ml-1">
          <Link href={`/${PageEnum.PageUrl.MultiAirports}`} className={`${hrefClassName} text-d-none`}>
            Go to airports
          </Link>
        </span>
      </div>

      {isCalloutVisible && (
        <Callout
          className={contentStyles.callout}
          gapSpace={0}
          target={`#${searchBoxId}`}
          onDismiss={() => {
            toggleIsCalloutVisible();
            setAirportList(airportListClone);
          }}
          setInitialFocus
        >
          <SearchBox placeholder="Search airport" onSearch={searchAirport} onClear={clearSearch} />
          <div id="airportList">
            {airportList?.map((airport: IAirportSummary) => (
              <div
                className="cursor-pointer mt-1 br-b-1"
                style={{ height: "75px" }}
                key={CommonHelper.getGuid()}
                onClick={() => selectAirport(airport.id)}
                onKeyDown={() => selectAirport(airport.id)}
              >
                <div className="fw-600 mb-1">{airport.code} - {airport.name}</div>
                <div className="w-90">
                  <div className="w-20 float-l amd-container-vcenter hop-otp-kpi">
                    <HOPAlertIcon status={MultiAirportHelper.getKPI(airport)?.status as string} />
                    <span className={`${HOPHelper.getLegendTextColor(MultiAirportHelper.getKPI(airport)?.status as string)} fw-700 pl-1`}>
                      {MultiAirportHelper.getKPI(airport)?.performance} %
                    </span>
                  </div>
                  <div className="w-50 float-l pl-2">
                    {airport.weatherSummary} {airport.weatherValue}
                    <span className="pl-1">{airport.weatherUnit}</span>
                  </div>
                  <div className="w-20 float-r">{airport.location}</div>
                </div>
              </div>
            ))}
          </div>
        </Callout>
      )}
    </section>
  ) : (
    <>No airport selected</>
  );
};

const contentStyles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 480,
    maxWidth: "90%",
    padding: "20px 24px",
  },
});
