import React from "react";

interface PassengerDetailsProps {
  title: string;
  details: {
    total: number;
    remote: number;
    contact: number;
  };
}

const PassengerDetails: React.FC<PassengerDetailsProps> = ({ title, details }) => (
  <dl className={`amd-list-desc amd-list-desc--w138 mt-0`}>
    <div className="amd-list-desc__heading">{title}</div>
    <div>
      <dt className="amd-list-desc__dt">Pax</dt>
      <dd className="amd-list-desc__dd">{details.total}</dd>
    </div>
    <div>
      <dt className="amd-list-desc__dt">Remote</dt>
      <dd className="amd-list-desc__dd">{details.remote}</dd>
    </div>
    <div>
      <dt className="amd-list-desc__dt">Contact</dt>
      <dd className="amd-list-desc__dd">{details.contact}</dd>
    </div>
  </dl>
);

export default PassengerDetails;