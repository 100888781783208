import {
  DefaultPalette,
  IComboBoxStyles,
  IStackItemStyles,
  ITag,
  ITimeRange,
  Stack,
  TextField,
  TimePicker,
} from "@fluentui/react";
import React from "react";
import AdminEnum from "../../../enum/AdminEnum";
import TagPickerEnum from "../../../enum/TagPickerEnum";
import IFilters, { IFilterItem } from "../../../models/filters/IFilters";
import DateHelper from "../../../utils/common/DateHelper";
import FlightScheduleHelper from "../../../utils/flights/FlightScheduleHelper";
import { AmdTagPickControl } from "../../common/form-controls/uncontrolled/TagPicker/AmdTagPickControl";
import { TimeRangeFilter } from "../time-range/TimeRangeFilter";

export const FilterItem: React.FunctionComponent<IFilterItemProps> = ({
  _onFilterItemClick,
  _onCustomInputClick,
  filter,
  onFilterSelection,
  isDisabled,
  airport,
}) => {
  const [startTime, setStartTime] = React.useState<Date>(
    new Date(DateHelper.getCurrentDateTimeStrForAirport(airport))
  );
  const [endTime, setEndTime] = React.useState<Date>(
    new Date(DateHelper.getCurrentDateTimeStrForAirport(airport))
  );
  const onFormatDate = (date: Date) =>
    `${date.getHours() > 0 ? date.getHours() + " hr" : ""} ${
      date.getMinutes() > 0 ? date.getMinutes() + " mins" : ""
    }`;

  const onTagSelected = (tag: ITag, category: string) => {
    const filterItem: IFilterItem = {
      key: category,
      name: category,
      selectedValue: tag?.name,
      type: "tagpicker",
    };

    _onFilterItemClick(filterItem);
    return tag;
  };

  return (
    <Stack key={filter.key} data-testid="filterItemContainer">
      {!filter.hide && (
        <>
          <Stack.Item style={filterHeaderStyles}>
            <span>{filter.category}</span>
          </Stack.Item>
          <Stack.Item styles={filterItemStyles}>
            <Stack tokens={filterTokens}>
              {filter.filterItems.map((item: IFilterItem) => (
                <div key={item.key} className="filterItem">
                  {item.type === "text" && (
                    <TextField
                      label=""
                      value={item.selectedValue}
                      onChange={(e) => {
                        _onCustomInputClick(e.target as HTMLInputElement, item);
                      }}
                      className="filterTextInput"
                      data-testid="filterTextField"
                    />
                  )}

                  {(item.type === "radio" || item.type === "checkbox") && (
                    <input
                      type={item.type}
                      name={item.name}
                      value={item.key}
                      checked={item.selected}
                      onChange={(_e) => {
                        _onFilterItemClick(item);
                      }}
                      className="filterCheckbox"
                    />
                  )}

                  {item.type === "tagpicker" &&
                    item.key === TagPickerEnum.TagPickerType.Organizations && (
                      <AmdTagPickControl
                        controlId={AdminEnum.User.Organizations}
                        tagLabel="Organization"
                        isMandatory={false}
                        airport={airport}
                        suggestionType={
                          TagPickerEnum.TagPickerType.Organizations
                        }
                        onTagSelected={(tag: ITag) =>
                          onTagSelected(
                            tag,
                            TagPickerEnum.TagPickerType.Organizations
                          )
                        }
                        itemLimit={1}
                      />
                    )}

                  {item.type === "tagpicker" &&
                    item.key === TagPickerEnum.TagPickerType.Roles && (
                      <AmdTagPickControl
                        controlId={AdminEnum.User.Roles}
                        tagLabel="Roles"
                        isMandatory={false}
                        suggestionType={TagPickerEnum.TagPickerType.Roles}
                        onTagSelected={(tag: ITag) =>
                          onTagSelected(tag, TagPickerEnum.TagPickerType.Roles)
                        }
                        itemLimit={1}
                        airport={airport}
                      />
                    )}

                  {!item.key?.includes("custom") && (
                    <label className="filterLabel">{item.value}</label>
                  )}
                  {item.key === "customDuration" && (
                    <TimePicker
                      styles={timePickerStyles}
                      useHour12
                      placeholder="Select Duration"
                      increments={30}
                      timeRange={timeDuration}
                      onFormatDate={onFormatDate}
                      disabled={isDisabled?.duration}
                      required={item.selected}
                      onChange={(e) => {
                        _onCustomInputClick(e.target as HTMLInputElement, item);
                      }}
                      className="durationTimepicker"
                      value={
                        item.selectedValue
                          ? DateHelper.getTimePickerValue(
                              new Date(item.selectedValue.split("|")[0]),
                              new Date(item.selectedValue.split("|")[1]),
                              airport
                            )
                          : undefined
                      }
                    />
                  )}
                  {item.key === "customTimeRange" && (
                    <div
                      style={{ float: "left", width: "100%" }}
                      className="timeRange"
                    >
                      <div style={{ float: "left" }}>
                        <TimeRangeFilter
                          airport={airport}
                          defaultText="Start"
                          value={
                            item.selectedValue
                              ? new Date(item.selectedValue.split("|")[0])
                              : undefined
                          }
                          isDisabled={isDisabled?.start}
                          onTimePickerChanged={(start) => {
                            setStartTime(start);
                            onFilterSelection(
                              item.name,
                              item.key,
                              item.type,
                              "",
                              start,
                              FlightScheduleHelper.getDefaultTime(
                                airport,
                                1,
                                item.selectedValue,
                                endTime
                              )
                            );
                          }}
                        />
                      </div>
                      <div style={{ float: "left", paddingLeft: "5px" }}>
                        <TimeRangeFilter
                          airport={airport}
                          defaultText="End"
                          value={
                            item.selectedValue
                              ? new Date(item.selectedValue.split("|")[1])
                              : undefined
                          }
                          isDisabled={isDisabled?.end}
                          onTimePickerChanged={(end) => {
                            setEndTime(end);
                            if (startTime && end < startTime) {
                              return;
                            }
                            onFilterSelection(
                              item.name,
                              item.key,
                              item.type,
                              "",
                              FlightScheduleHelper.getDefaultTime(
                                airport,
                                0,
                                item.selectedValue,
                                startTime
                              ),
                              end
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </Stack>
          </Stack.Item>
        </>
      )}
    </Stack>
  );
};

export interface IFilterItemProps {
  _onFilterItemClick: any;
  _onCustomInputClick: any;
  filter: IFilters;
  onFilterSelection: any;
  isDisabled: any;
  airport: string;
}

const filterHeaderStyles: React.CSSProperties = {
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  textTransform: "uppercase",
  color: "#9BCAEB",
  padding: "5px 32px 5px 32px",
};

const filterTokens = { childrenGap: 10 };

const timePickerStyles: Partial<IComboBoxStyles> = {
  optionsContainerWrapper: {
    height: "500px",
  },
  root: {
    width: "50%",
  },
};

const timeDuration: ITimeRange = {
  start: 0,
  end: 12,
};

const filterItemStyles: IStackItemStyles = {
  root: {
    padding: "5px 32px 5px 32px",
    color: DefaultPalette.white,
  },
};
